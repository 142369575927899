import { overload } from "../../../shared/src/codecs/types/overload";
import { required } from "../../../shared/src/codecs/types/required";
import { dateTime } from "../../../shared/src/codecs/types/dateTime";
import { boolean } from "../../../shared/src/codecs/types/boolean";
import { DateTime } from "luxon";

export const UsersIdCheckHasExpired = overload(
    boolean(),
    required({
        expires_at: dateTime(),
    }),
    ({expires_at}) => DateTime.fromISO(expires_at).toSeconds() <= DateTime.utc().toSeconds(),
);
