import { overload } from "../../../shared/src/codecs/types/overload";
import { required } from "../../../shared/src/codecs/types/required";
import { boolean } from "../../../shared/src/codecs/types/boolean";
import { dateTimeOrNullAsBoolean } from "../../../shared/src/codecs/types/dateTimeOrNullAsBoolean";

export const CasesClientBankAccountChangesLocked = overload(
    boolean(),
    required({
        client_changes_to_bank_accounts_locked: dateTimeOrNullAsBoolean(),
    }),
    ({client_changes_to_bank_accounts_locked}) => client_changes_to_bank_accounts_locked ? true : false,
);
