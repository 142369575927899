import { TTypeOfCodec } from "../../shared/src/codecs/codec";
import { union } from "../../shared/src/codecs/types/union";
import { literal } from "../../shared/src/codecs/types/literal";
import { required } from "../../shared/src/codecs/types/required";
import { uuid } from "../../shared/src/codecs/types/uuid";
import { string } from "../../shared/src/codecs/types/string";

export const UsersIdCheckImageType = union([
    literal("document"),
    literal("selfie"),
]);
export type TUsersIdCheckImageType = TTypeOfCodec<typeof UsersIdCheckImageType>;

export const UsersIdCheckImage1 = required({
    id: uuid(),
    file_extension: string(),
});
