import * as Function from "../Function";
import * as Error from "../Error";
import { array, option, either, nonEmptyArray } from "fp-ts/lib";
import { pipe } from "fp-ts/lib/function";

export const or = (...validators: Array<Function.TValidationFunction>): Function.TValidationFunction =>
    (value: unknown): option.Option<Error.TValidationError> =>
         pipe(
            validators,
            array.reduce<Function.TValidationFunction, either.Either<undefined, option.Option<Error.TValidationError>>>(either.right(option.none), (lastResult, f) =>
                 pipe(
                    lastResult,
                    either.fold(
                        () => either.left(undefined), // If the either is left a validator passed earlier so just pass through
                        (lastValidationResult) =>
                             pipe(
                                f(value),
                                option.fold(
                                    () => either.left(undefined), // If the validator passes return left to indicate that the whole set passes
                                    (currentValidatorResult) => // If it doesn't continue building up the set of validation fails
                                     pipe(
                                        lastValidationResult,
                                        option.fold(
                                            () => either.right(option.some(currentValidatorResult)),
                                            (unpackedLastValidationResult) => either.right(
                                                option.some(
                                                    nonEmptyArray.flatten([
                                                        currentValidatorResult,
                                                        unpackedLastValidationResult,
                                                    ])
                                                )
                                            )
                                        )
                                    ),
                                ),
                            ),
                    )
                )
            ),
            either.fold( // If left then at least one of the validators pass so all pass, else passthrough all errors
                () => option.none,
                (v) => v,
            )
        );
