import * as t from "io-ts";
import * as util from "../../shared/src/util";

export const codec = t.union([
    t.literal("to_rent"),
    t.literal("to_live"),
    t.literal("to_live_sometimes"),
    t.literal("to_sell"),
    t.literal("for_someone_else"),
    t.literal("unknown"),
]);

export type T = t.TypeOf<typeof codec>;

export const values = util.typeValuesFromCodec(codec.types as Array<t.LiteralC<T>>);
