import { required } from "../../shared/src/codecs/types/required";
import { uuid } from "../../shared/src/codecs/types/uuid";
import { string } from "../../shared/src/codecs/types/string";
import { union } from "../../shared/src/codecs/types/union";
import { nullCodec } from "../../shared/src/codecs/types/nullCodec";

export const TwoFactorAuthCode1 = required({
    user_phone_number_id: union([uuid(), nullCodec()]),
    auth_code: string(),
});
export type TTwoFactorAuthCode1Codec = typeof TwoFactorAuthCode1;
