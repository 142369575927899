import React from "react";

export default (props: React.SVGAttributes<SVGElement>) => <svg style={{ display: "block" }} viewBox="0 -4 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
    {/* <!-- Generator: Sketch 61.2 (89653) - https://sketch.com --> */}
    <title>Expand_More</title>
    <desc>Created with Sketch.</desc>
    <g id="Symbols" stroke="none" strokeWidth="1">
        <g id="Dropdown-/-Dropdown" transform="translate(-171.000000, -16.000000)">
            <path d="M185.623119,16.2426637 L179.000764,23.0078641 L172.378409,16.2418494 C172.062676,15.9193835 171.551935,15.9193835 171.236202,16.2418494 C170.921266,16.5643152 170.921266,17.0867615 171.236202,17.4092273 L178.429295,24.7581506 L178.429295,24.7581506 L178.429295,24.7581506 C178.744231,25.0806165 179.255769,25.0806165 179.570705,24.7581506 L186.763798,17.4092952 C187.078734,17.0868293 187.078734,16.5635688 186.763798,16.2411029 C186.44966,15.9201978 185.938055,15.9201978 185.623119,16.2426637 Z" id="Expand_More"></path>
        </g>
    </g>
</svg>;
