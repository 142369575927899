import { overload } from "../../shared/src/codecs/types/overload";
import { User9 } from "./User9";
import { array as fptsArray } from "fp-ts/lib";
import { array } from "../../shared/src/codecs/types/array";
import { string } from "../../shared/src/codecs/types/string";
import { pipe } from "fp-ts/lib/function";

export const User10 = overload(
    string(),
    array(User9),
    (users) => pipe(
        users,
        fptsArray.map((user) =>
            pipe(
                [
                    ...user.first_name ? [user.first_name] : [],
                    ...user.last_name ? [user.last_name] : [],
                ],
                (a) => a.join(" "),
            )
        ),
        (a) => a.join(" & "),
        (usersString) => usersString || "Names not given",
    )
);
export type TUser10Codec = typeof User10;