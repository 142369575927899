import * as t from "io-ts";
import { integerPositive } from "../../shared/src/validation/basic/integerPositive";
import { nullable } from "../../shared/src/validation/basic/nullable";
import { string } from "../../shared/src/validation/basic/string";
import { or } from "../../shared/src/validation/compose/or";
import { object } from "../../shared/src/validation/create/object";

export const codec = t.partial({
    search_criteria_area: t.string,
    search_criteria_min_beds: t.union([t.number, t.null]),
    search_criteria_max_price: t.union([t.number, t.null]),
    search_criteria_preferences: t.string,
});

export const validator = object({
    search_criteria_area: string,
    search_criteria_min_beds: or(integerPositive, nullable),
    search_criteria_max_price: or(integerPositive, nullable),
    search_criteria_preferences: string,
});

export type T = t.TypeOf<typeof codec>;

export const newDefault = (): Required<T> => ({
    search_criteria_area: "",
    search_criteria_min_beds: null,
    search_criteria_max_price: null,
    search_criteria_preferences: "",
});
