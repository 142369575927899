import React from "react";

export default (props: React.SVGAttributes<SVGElement>) => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g clipPath="url(#clip0_7409_35582)">
<path d="M19 19V4H15V3H5V19H3V21H15V6H17V21H21V19H19ZM13 19H7V5H13V19ZM10 11H12V13H10V11Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_7409_35582">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>
;
