import { TTypeOfCodec } from "../../shared/src/codecs/codec";
import { literal } from "../../shared/src/codecs/types/literal";
import { union } from "../../shared/src/codecs/types/union";

export const CasesPurchasePaymentMethod1 = union([
    literal("unknown"),
    literal("cash"),
    literal("mortgage"),
    literal("no_payment"),
]);
export type TCasesPurchasePaymentMethod1Codec = typeof CasesPurchasePaymentMethod1;
export type TCasesPurchasePaymentMethod1 = TTypeOfCodec<typeof CasesPurchasePaymentMethod1>;