import React from "react";

export default (props: React.SVGAttributes<SVGElement>) => 
<svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M9.62475 29.75H8.74975V14H9.62475C10.108 14 10.4998 13.6082 10.4998 13.125C10.4998 12.6418 10.108 12.25 9.62475 12.25H2.62476C2.14154 12.25 1.74976 12.6418 1.74976 13.125C1.74976 13.6082 2.14154 14 2.62476 14H3.49976V29.75H2.62476C2.14154 29.75 1.74976 30.1417 1.74976 30.625C1.74976 31.1083 2.14154 31.5 2.62476 31.5H9.62475C10.108 31.5 10.4998 31.1083 10.4998 30.625C10.4998 30.1417 10.108 29.75 9.62475 29.75ZM6.99975 29.75H5.24975V14H6.99975V29.75Z" fill="white"/>
<path d="M20.1249 29.75H19.2499V14H20.1249C20.6082 14 20.9999 13.6082 20.9999 13.125C20.9999 12.6418 20.6082 12.25 20.1249 12.25H13.1249C12.6417 12.25 12.2499 12.6418 12.2499 13.125C12.2499 13.6082 12.6417 14 13.1249 14H13.9999V29.75H13.1249C12.6417 29.75 12.2499 30.1417 12.2499 30.625C12.2499 31.1083 12.6417 31.5 13.1249 31.5H20.1249C20.6082 31.5 20.9999 31.1083 20.9999 30.625C20.9999 30.1417 20.6082 29.75 20.1249 29.75ZM17.4999 29.75H15.7499V14H17.4999V29.75Z" fill="white"/>
<path d="M30.6251 29.75H29.7501V14H30.6251C31.1084 14 31.5001 13.6082 31.5001 13.125C31.5001 12.6418 31.1084 12.25 30.6251 12.25H23.6251C23.1418 12.25 22.7501 12.6418 22.7501 13.125C22.7501 13.6082 23.1418 14 23.6251 14H24.5001V29.75H23.6251C23.1418 29.75 22.7501 30.1417 22.7501 30.625C22.7501 31.1083 23.1418 31.5 23.6251 31.5H30.6251C31.1084 31.5 31.5001 31.1083 31.5001 30.625C31.5001 30.1417 31.1084 29.75 30.6251 29.75ZM28.0001 29.75H26.2501V14H28.0001V29.75Z" fill="white"/>
<path d="M32.375 35H0.875C0.391781 35 0 34.6083 0 34.125C0 33.6417 0.391781 33.25 0.875 33.25H32.375C32.8583 33.25 33.25 33.6417 33.25 34.125C33.25 34.6083 32.8583 35 32.375 35Z" fill="white"/>
<path d="M30.6252 10.5H2.62523C2.23487 10.5 1.89176 10.2415 1.78414 9.86624C1.67651 9.49103 1.83046 9.0899 2.16148 8.88302L16.1615 0.133014C16.4452 -0.0443379 16.8052 -0.0443379 17.089 0.133014L31.089 8.88302C31.42 9.0899 31.5739 9.49103 31.4663 9.86624C31.3587 10.2415 31.0156 10.5 30.6252 10.5ZM5.6762 8.75002H27.5743L16.6252 1.90686L5.6762 8.75002Z" fill="white"/>
</svg>
;
