import { TTypeOfCodec } from "../../shared/src/codecs/codec";
import { array } from "../../shared/src/codecs/types/array";
import { boolean } from "../../shared/src/codecs/types/boolean";
import { intersection } from "../../shared/src/codecs/types/intersection";
import { literal } from "../../shared/src/codecs/types/literal";
import { nullCodec } from "../../shared/src/codecs/types/nullCodec";
import { required } from "../../shared/src/codecs/types/required";
import { union } from "../../shared/src/codecs/types/union";
import { uuid } from "../../shared/src/codecs/types/uuid";
import { requireExhaustive } from "../../shared/src/util";
import { CaseLedgerType } from "./CaseLedger";
import { Tenure3 } from "./Tenure";
import { TTransactionType1 } from "./TransactionType";

export const QuoteRateTransactionType = union([
    literal("purchase"),
    literal("sale"),
    literal("transfer"),
    literal("remortgage"),
    literal("transfer_and_remortgage"),
]);
export type TQuoteRateTransactionType = TTypeOfCodec<typeof QuoteRateTransactionType>;
export const QuoteRateTransactionType_displayString = (p: TQuoteRateTransactionType): string =>
    p === "purchase" ? "Purchase"
    : p === "sale" ? "Sale"
    : p === "transfer" ? "Transfer"
    : p === "remortgage" ? "Remortgage"
    : p === "transfer_and_remortgage" ? "Transfer & Remortgage"
    : requireExhaustive(p);

export const QuoteRateTransactionType_TransactionType1 = (p: TQuoteRateTransactionType): TTransactionType1 =>
    p === "purchase" ? "purchase"
    : p === "sale" ? "sale"
    : (
        p === "transfer"
        || p === "transfer_and_remortgage"
    ) ? "transfer"
    : p === "remortgage" ? "remortgage"
    : requireExhaustive(p);

export const QuoteRateAttribute = union([
    literal("unregistered_property"),
    literal("new_build_property"),
    literal("requires_search_pack"),
    literal("has_gifted_deposit"),
    literal("mortgage_involved"),
    literal("has_company"),
    literal("client_only_using_cash"),
]);
export type TQuoteRateAttribute = TTypeOfCodec<typeof QuoteRateAttribute>;
export const QuoteRateAttribute_displayString = (p: TQuoteRateAttribute): string =>
    p === "unregistered_property" ? "Unregistered"
    : p === "has_gifted_deposit" ? "Gifted deposit"
    : p === "new_build_property" ? "New build"
    : p === "requires_search_pack" ? "Search pack"
    : p === "mortgage_involved" ? "Mortgage involved"
    : p === "has_company" ? "Client includes a company"
    : p === "client_only_using_cash" ? "Client only using cash"
    : requireExhaustive(p);
export const QuoteRateAttribute_question = (p: TQuoteRateAttribute): string =>
    p === "unregistered_property" ? "Is the property unregistered?"
    : p === "has_gifted_deposit" ? "If there is a deposit to be paid is any part of it going to be gifted?"
    : p === "new_build_property" ? "Is this a new build property?"
    : p === "requires_search_pack" ? "Is a search pack required?"
    : p === "mortgage_involved" ? "Is there a mortgage involved?"
    : p === "has_company" ? "Will the transaction be in the name of a company?"
    : p === "client_only_using_cash" ? "Is the client only paying in cash?"
    : requireExhaustive(p);

export const QuoteRatePropertyResidentialCommercial = union([
    literal("residential"),
    literal("commercial"),
]);
export type TQuoteRatePropertyResidentialCommercial = TTypeOfCodec<typeof QuoteRatePropertyResidentialCommercial>;
export const QuoteRatePropertyResidentialCommercial_displayString = (p: TQuoteRatePropertyResidentialCommercial): string =>
    p === "commercial" ? "Commercial property"
    : p === "residential" ? "Residential property"
    : requireExhaustive(p);

export const QuoteRateClientsOwnership = union([
    literal("first_time_buyers"),
    literal("not_first_time_buyers"),
]);
export type TQuoteRateClientsOwnership = TTypeOfCodec<typeof QuoteRateClientsOwnership>;
export const QuoteRateClientsOwnership_displayString = (p: TQuoteRateClientsOwnership): string =>
    p === "first_time_buyers" ? "First time buyers"
    : p === "not_first_time_buyers" ? "Not first time buyers"
    : requireExhaustive(p);

export const QuoteRateClientsPropertyCount = union([
    literal("one_property"),
    literal("more_than_one_property"),
]);
export type TQuoteRateClientsPropertyCount = TTypeOfCodec<typeof QuoteRateClientsPropertyCount>;
export const QuoteRateClientsPropertyCount_displayString = (p: TQuoteRateClientsPropertyCount): string =>
    p === "more_than_one_property" ? "Will own more than one property"
    : p === "one_property" ? "Will own one property"
    : requireExhaustive(p);

export const QuoteRateTaxGroupSDLT = union([
    literal("sdlt_residential_first_time_buyers"),
    literal("sdlt_residential_single_property"),
    literal("sdlt_residential_multiple_property"),
    literal("sdlt_commercial"),
]);
export type TQuoteRateTaxGroupSDLT = TTypeOfCodec<typeof QuoteRateTaxGroupSDLT>;

export const QuoteRateTaxGroupLTT = union([
    literal("ltt_residential_single_property"),
    literal("ltt_residential_multiple_property"),
    literal("ltt_commercial"),
]);
export type TQuoteRateTaxGroupLTT = TTypeOfCodec<typeof QuoteRateTaxGroupLTT>;

export const QuoteRateTaxGroup = union([
    ...QuoteRateTaxGroupSDLT.payload,
    ...QuoteRateTaxGroupLTT.payload,
]);
export type TQuoteRateTaxGroup = TTypeOfCodec<typeof QuoteRateTaxGroup>;
export const QuoteRateTaxGroup_displayString = (p: TQuoteRateTaxGroup): string =>
    (p === "sdlt_residential_single_property" || p === "ltt_residential_single_property") ? "Single property (residential)"
    : (p === "sdlt_residential_multiple_property" || p === "ltt_residential_multiple_property") ? "Multiple property (residential)"
    : p === "sdlt_residential_first_time_buyers" ? "First time buyers (residential)"
    : (p === "sdlt_commercial" || p === "ltt_commercial") ? "Commercial/Non-residential/Mixed use"
    : requireExhaustive(p);

export const QuoteRate1 = required({
    id: uuid(),
    rate_type: union([nullCodec(), CaseLedgerType]),
    applies_when_any_of_transaction_types: array(QuoteRateTransactionType),
    applies_when_any_of_attributes: array(QuoteRateAttribute),
    applies_when_tenure_is: union([nullCodec(), Tenure3]),
    applies_when_property_residential_commercial_is: union([nullCodec(), QuoteRatePropertyResidentialCommercial]),
    applies_when_clients_ownership_is: union([nullCodec(), QuoteRateClientsOwnership]),
    applies_when_clients_property_count_is: union([nullCodec(), QuoteRateClientsPropertyCount]),
    applies_when_tax_group_is: union([nullCodec(), QuoteRateTaxGroup]),
    has_vat: boolean(),
    is_multiplied_per_client: boolean(),
});
export type TQuoteRate1Codec = typeof QuoteRate1;
export type TQuoteRate1 = TTypeOfCodec<TQuoteRate1Codec>;

export const QuoteRate2 = required({
    is_published: boolean(),
    will_be_deleted_on_next_publish: boolean(),
});
export type TQuoteRate2Codec = typeof QuoteRate2;
export type TQuoteRate2 = TTypeOfCodec<TQuoteRate2Codec>;

export const QuoteRate3 = intersection([
    QuoteRate1,
    required({
        introducer_id: union([nullCodec(), uuid()]),
    }),
]);
export type TQuoteRate3 = TTypeOfCodec<typeof QuoteRate3>;
