import { array, nonEmptyArray, record } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import { TTypeOfCodec, TCodec, TTypeOfNewDefault } from "../../shared/src/codecs/codec";
import { literal } from "../../shared/src/codecs/types/literal";
import { positiveInteger } from "../../shared/src/codecs/types/positiveInteger";
import { required } from "../../shared/src/codecs/types/required";
import { union } from "../../shared/src/codecs/types/union";
import { uuid } from "../../shared/src/codecs/types/uuid";
import { boolean } from "../../shared/src/codecs/types/boolean";
import { dateTime } from "../../shared/src/codecs/types/dateTime";
import { nullCodec } from "../../shared/src/codecs/types/nullCodec";
import { intersection } from "../../shared/src/codecs/types/intersection";
import { integer } from "../../shared/src/codecs/types/integer";
import { string } from "../../shared/src/codecs/types/string";
import { array as arrayCodec } from "../../shared/src/codecs/types/array";
import { requiredFlatOverloaded } from "../../shared/src/codecs/types/requiredFlatOverloaded";
import { CaseLedgerPaidStatus } from "./overloads/CaseLedgerPaidStatus";
import { requireExhaustive } from "../../shared/src/util";
import { overload } from "../../shared/src/codecs/types/overload";

export const CaseCreditDebit = union([
    literal("credit"),
    literal("debit"),
]);
export type TCaseCreditDebit = TTypeOfCodec<typeof CaseCreditDebit>;

export const CaseLedgerCategory = union([
    literal("transfer_in_client"),
    literal("transfer_in_third_party"),
    literal("transfer_out"),
    literal("transfer_out_third_party"),
    literal("fee"),
    literal("disbursement"),
]);
export type TCaseLedgerCategory = TTypeOfCodec<typeof CaseLedgerCategory>;
export const CaseLedgerCategory_displayString = (p: TCaseLedgerCategory): string =>
    p === "disbursement" ? "Disbursement"
    : p === "fee" ? "Professional fee"
    : p === "transfer_in_client" ? "Transfer in (from client)"
    : p === "transfer_in_third_party" ? "Transfer in (from third party)"
    : p === "transfer_out" ? "Transfer out (to client)"
    : p === "transfer_out_third_party" ? "Transfer out (to third party)"
    : requireExhaustive(p);

export type ledgerItemDetails = {
    displayName: string,
    creditOrDebit: TCaseCreditDebit,
    prefilledAmountGbpPence: number | null,
    vatStatus: boolean,
    includedInFeeReport: "no" | "profit" | "loss",
    category: TCaseLedgerCategory, 
    money_not_transferred_into_client_account: boolean;
    is_a_type_of_search: boolean;
    DEPRECATED?: true, // We don't want to remove because items may be in old ledgers which are required for record keeping purposes
}

function createLedgerTypeDictionaryMap<T extends { [name: string]: ledgerItemDetails }>(r: T)  {
    return r;
};
export const ledgerTypeDictionary = createLedgerTypeDictionaryMap({
    sale_price: {
        displayName: "Sale price",
        creditOrDebit: "credit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_in_third_party", 
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    mortgage_advance: {
        displayName: "Mortgage Advance",
        creditOrDebit: "credit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_in_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    money_from_client_initial_payment: {
        displayName: "Money from client (initial payment)",
        creditOrDebit: "credit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_in_client",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    money_from_client_deposit: {
        displayName: "Money from client (deposit)",
        creditOrDebit: "credit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_in_client",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    money_from_client_shortfall: {
        displayName: "Money from client (shortfall)",
        creditOrDebit: "credit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_in_client",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    purchase_price: {
        displayName: "Purchase price",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    redemption_of_mortgage_admin_fee: {
        displayName: "Redemption of Mortgage Admin Fee",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: 6000,
        includedInFeeReport: "profit",
        category: "fee",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    mortgage_redemption: {
        displayName: "Mortgage Redemption",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    redemption_of_htb_mortgage: {
        displayName: "Redemption of HTB Mortgage",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    legal_fee: {
        displayName: "Legal fee",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "profit",
        category: "fee",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    land_reg_fee: {
        displayName: "Land Registry Fee",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "disbursement",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    official_copy: {
        displayName: "Title Register (Official Copy)",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: 300,
        includedInFeeReport: "no",
        category: "disbursement",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    title_plan: {
        displayName: "Title plan",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: 300,
        includedInFeeReport: "no",
        category: "disbursement",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    stamp_duty_submission: {
        displayName: "Stamp Duty Submission",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: 9500,
        includedInFeeReport: "profit",
        category: "fee",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    id_check_and_aml: {
        displayName: "ID Check & AML",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: 2000,
        includedInFeeReport: "profit", // This is not the true profit as there is a cost to this service
        category: "fee",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    bankruptcy_search: {
        displayName: "Bankruptcy Search",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: 200,
        includedInFeeReport: "no",
        category: "disbursement",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: true,
    },
    official_search: {
        displayName: "Official Search",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: 300,
        includedInFeeReport: "no",
        category: "disbursement",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: true,
    },
    electronic_transfer: {
        displayName: "Electronic Transfer",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: 4000,
        includedInFeeReport: "profit",
        category: "fee",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    leasehold_fee: {
        displayName: "Leasehold Fee",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: 25000,
        includedInFeeReport: "profit",
        category: "fee",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    search_pack: {
        displayName: "Search Pack",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "disbursement",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: true,
    },
    gifted_deposit_legal_fee: {
        displayName: "Gifted Deposit Legal Fee",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: 10000,
        includedInFeeReport: "profit",
        category: "fee",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    new_build_legal_fee: {
        displayName: "New Build Legal Fee",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: 35000,
        includedInFeeReport: "profit",
        category: "fee",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    coal_search: {
        displayName: "Coal Search",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: 3200,
        includedInFeeReport: "no",
        category: "disbursement",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: true,
    },
    stamp_duty_land_tax: {
        displayName: "Stamp Duty Land Tax",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    land_transaction_tax: { // This is like stamp duty land tax except for properties in wales
        displayName: "Land Transaction Tax",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    redeem_htb_mortgage_admin_fee: {
        displayName: "Redeem HTB Mortgage Admin Fee",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: 10000,
        includedInFeeReport: "profit",
        category: "fee",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    indemnity_policy_admin_fee: {
        displayName: "Indemnity Policy Admin Fee",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: 6000,
        includedInFeeReport: "profit",
        category: "fee",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    indemnity_policy: {
        displayName: "Indemnity Policy",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    unregistered_legal_fee: {
        displayName: "Unregistered Title Legal Fee",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: 30000,
        includedInFeeReport: "profit",
        category: "fee",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    voluntary_registration_legal_fee: {
        displayName: "Voluntary Registration Legal Fee",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: 30000,
        includedInFeeReport: "profit",
        category: "fee",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    declaration_of_trust_legal_fee: {
        displayName: "Declaration of Trust Legal Fee",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: 29500,
        includedInFeeReport: "profit",
        category: "fee",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    deed_of_covenant_fee: {
        displayName: "Deed of Covenant Fee",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: 10000,
        includedInFeeReport: "profit",
        category: "fee",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    deed_of_covenant_disbursement: {
        displayName: "Deed of Covenant Disbursement",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: 10000,
        includedInFeeReport: "no",
        category: "disbursement",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    htb_isa: {
        displayName: "HTB ISA",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: 5000,
        includedInFeeReport: "profit",
        category: "fee",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    htb_property: {
        displayName: "HTB Property",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: 25000,
        includedInFeeReport: "profit",
        category: "fee",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    lms_mortgage_service: {
        displayName: "LMS Mortgage Service",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: 3500,
        includedInFeeReport: "no",
        category: "disbursement",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    lawyer_check: {
        displayName: "Lawyer check",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: 1800,
        includedInFeeReport: "no",
        category: "disbursement",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    no_search_insurance: {
        displayName: "No search insurance",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "disbursement",
        DEPRECATED: true,
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: true,
    },
    staircasing_legal_fee: {
        displayName: "Staircasing Legal Fee",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: 30000,
        includedInFeeReport: "profit",
        category: "fee",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    money_returned_to_client: {
        displayName: "Money returned to client",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    estate_agent_fee: {
        displayName: "Estate agent fee",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    index_map_search_fee: {
        displayName: "Index Map Search Fee",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: 400,
        includedInFeeReport: "no",
        category: "disbursement",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: true,
    },
    sellers_engrossment_fee: {
        displayName: "Seller's Engrossment Fee",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    write_off_legal_fee: {
        displayName: "Write off - Legal Fee",
        creditOrDebit: "credit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "loss",
        category: "transfer_in_third_party",
        money_not_transferred_into_client_account: true,
        is_a_type_of_search: false,
    },
    write_off_disbursements: {
        displayName: "Write off - Disbursements",
        creditOrDebit: "credit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "loss",
        category: "transfer_in_third_party",
        money_not_transferred_into_client_account: true,
        is_a_type_of_search: false,
    },
    additional_title: {
        displayName: "Additional title",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: 9500,
        includedInFeeReport: "profit",
        category: "fee",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    dealing_with_an_auction: {
        displayName: "Dealing with an auction",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: 125000,
        includedInFeeReport: "profit",
        category: "fee",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    bankruptcy_or_other_land_charge_entry: {
        displayName: "Bankruptcy or other Land Charge entry",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: 7500,
        includedInFeeReport: "profit",
        category: "fee",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    cash_purchase: {
        displayName: "Cash Purchase",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "profit",
        category: "fee",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    change_of_name: {
        displayName: "Change of name",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "profit",
        category: "fee",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    completion_searches: {
        displayName: "Completion Searches",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "fee",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: true,
    },
    concessionary_fee: {
        displayName: "Concessionary Fee",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "profit",
        category: "fee",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    dealing_with_a_non_standard_restriction: {
        displayName: "Dealing with a non standard Restriction",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: 7500,
        includedInFeeReport: "profit",
        category: "fee",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    deed_of_variation: {
        displayName: "Deed of Variation",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: 25000,
        includedInFeeReport: "profit",
        category: "fee",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    discharge_of_subsequent_charges: {
        displayName: "Discharge of 2nd or subsequent charges",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: 5000,
        includedInFeeReport: "profit",
        category: "fee",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    drafting_tenancy_agreement: {
        displayName: "Drafting tenancy agreement",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: 17500,
        includedInFeeReport: "profit",
        category: "fee",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    flying_freehold: {
        displayName: "Flying freehold",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: 15000,
        includedInFeeReport: "profit",
        category: "fee",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    hmo: {
        displayName: "HMO",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: 10000,
        includedInFeeReport: "profit",
        category: "fee",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    lease_extension_agreed: {
        displayName: "Lease extension - agreed",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: 65000,
        includedInFeeReport: "profit",
        category: "fee",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    lease_extension_statutory_process: {
        displayName: "Lease extension - statutory process",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: 95000,
        includedInFeeReport: "profit",
        category: "fee",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    leasehold_title_including_compliance_with_restriction: {
        displayName: "Leasehold title (to include compliance with restriction on title)",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: 25000,
        includedInFeeReport: "profit",
        category: "fee",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    merger_of_titles_required: {
        displayName: "Merger of titles required",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: 15000,
        includedInFeeReport: "profit",
        category: "fee",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    lender_mortgage_fee: {
        displayName: "Lender Mortgage Fee",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: 10000,
        includedInFeeReport: "profit",
        category: "fee",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    open_bank_search: {
        displayName: "Open Bank Search",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: 2100,
        includedInFeeReport: "no",
        category: "disbursement",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: true,
    },
    rearrangement_of_charges: {
        displayName: "Postponement/rearrangement of charges",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: 15000,
        includedInFeeReport: "profit",
        category: "fee",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    remortgage_for_transfer: {
        displayName: "Remortgage for Transfer",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: 35000,
        includedInFeeReport: "profit",
        category: "fee",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    freehold_restriction_or_entry_on_title: {
        displayName: "Freehold restriction/entry on title",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: 7500,
        includedInFeeReport: "profit",
        category: "fee",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    shared_ownership_purchase: {
        displayName: "Shared Ownership (Purchase)",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: 35000,
        includedInFeeReport: "profit",
        category: "fee",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    shared_ownership_sale: {
        displayName: "Shared Ownership (Sale)",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: 20000,
        includedInFeeReport: "profit",
        category: "fee",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    solar_panel_lease: {
        displayName: "Solar Panel lease",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: 20000,
        includedInFeeReport: "profit",
        category: "fee",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    borehole_lease: {
        displayName: "Borehole lease",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: 20000,
        includedInFeeReport: "profit",
        category: "fee",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    title_split_or_transfer_of_part_required: {
        displayName: "Title split or transfer of part required",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "profit",
        category: "fee",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    certificate_of_consent: {
        displayName: "Certificate of Consent",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    notice_fee_landlord: {
        displayName: "Notice Fee Landlord",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    service_charge_apportionment_outgoing: {
        displayName: "Service Charge Apportionment - outgoing",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    service_charge_apportionment_incoming: {
        displayName: "Service Charge Apportionment - incoming",
        creditOrDebit: "credit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_in_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    ground_rent_apportionment_outgoing: {
        displayName: "Ground Rent Apportionment - outgoing",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    ground_rent_apportionment_incoming: {
        displayName: "Ground Rent Apportionment - incoming",
        creditOrDebit: "credit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_in_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    sellers_solicitors_costs: {
        displayName: "Sellers' Solicitors Costs",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    sitting_tenant_admin_fee: {
        displayName: "Sitting Tenant Admin Fee",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: 15000,
        includedInFeeReport: "profit",
        category: "fee",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    shortfall_transferred_to_other_matter: {
        displayName: "Shortfall Transferred to Other Matter",
        creditOrDebit: "credit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_in_third_party",
        money_not_transferred_into_client_account: true,
        is_a_type_of_search: false,
    },
    shortfall_transferred_from_other_matter: {
        displayName: "Shortfall Transferred from Other Matter",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    surplus_transferred_to_other_matter: {
        displayName: "Surplus Transferred to Other Matter",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    surplus_transferred_from_other_matter: {
        displayName: "Surplus Transferred from Other Matter",
        creditOrDebit: "credit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_in_third_party",
        money_not_transferred_into_client_account: true,
        is_a_type_of_search: false,
    },
    service_charge_legal_fee: {
        displayName: "Service Charge Legal Fee",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "profit",
        category: "fee",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    local_authority_search: {
        displayName: "Local Authority Search",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "disbursement",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: true,
    },
    environmental_search: {
        displayName: "Environmental Search",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "disbursement",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: true,
    },
    drainage_search: {
        displayName: "Drainage Search",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "disbursement",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: true,
    },
    chancel_search: {
        displayName: "Chancel Search",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "disbursement",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: true,
    },
    chancel_insurance: {
        displayName: "Chancel Insurance",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "disbursement",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: true,
    },
    energy_performance_certificate: {
        displayName: "Energy Performance Certificate",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: 10000,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    i_insur_absence_of_easement_access_services: {
        displayName: "Indemnity Insurance - Absence of Easement (Access/Services)",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: true,
    },
    i_insur_absent_and_or_unresponsive_landlord: {
        displayName: "Indemnity Insurance - Absent and/or Unresponsive Landlord",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: true,
    },
    i_insur_adverse_possession_garden_land_only: {
        displayName: "Indemnity Insurance - Adverse Possession (Garden Land Only)",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: true,
    },
    i_insur_balcony_rights: {
        displayName: "Indemnity Insurance - Balcony Rights",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: true,
    },
    i_insur_build_over_sewer: {
        displayName: "Indemnity Insurance - Build Over Sewer",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: true,
    },
    i_insur_building_standards_lender: {
        displayName: "Indemnity Insurance - Building Standards (Lender)",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: true,
    },
    i_insur_contaminated_land_pass_further_action_no_search: {
        displayName: "Indemnity Insurance - Contaminated Land (Pass, Further Action, No Search)",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: true,
    },
    i_insur_contingent_buildings: {
        displayName: "Indemnity Insurance - Contingent Buildings",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: true,
    },
    i_insur_defective_lease: {
        displayName: "Indemnity Insurance - Defective Lease",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: true,
    },
    i_insur_enforcement_of_rights_known_unknown: {
        displayName: "Indemnity Insurance - Enforcement of Rights (Known/Unknown)",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: true,
    },
    i_insur_enlargement_of_lease: {
        displayName: "Indemnity Insurance - Enlargement of Lease",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: true,
    },
    i_insur_flat_maisonette_maintenance_and_repair_rights: {
        displayName: "Indemnity Insurance - Flat/Maisonette Maintenance and Repair Rights",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: true,
    },
    i_insur_flying_and_or_creeping_freehold: {
        displayName: "Indemnity Insurance - Flying and/or Creeping Freehold",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: true,
    },
    i_insur_forfeiture_of_lease_bankruptcy_insolvency_lender: {
        displayName: "Indemnity Insurance - Forfeiture of Lease (Bankruptcy/Insolvency) (Lender)",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: true,
    },
    i_insur_forfeiture_of_lease_breach_of_covenant_lender: {
        displayName: "Indemnity Insurance - Forfeiture of Lease (Breach of Covenant) (Lender)",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: true,
    },
    i_insur_forfeiture_of_lease_breach_of_covenant_prchsr_and_lndr: {
        displayName: "Indemnity Insurance - Forfeiture of Lease (Breach of Covenant) (Purchaser and Lender)",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: true,
    },
    i_insur_forfeiture_of_lease_housing_act_repossession_lender: {
        displayName: "Indemnity Insurance - Forfeiture of Lease (Housing Act Repossession) (Lender)",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: true,
    },
    i_insur_freehold_rent_charge: {
        displayName: "Indemnity Insurance - Freehold Rent Charge",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: true,
    },
    i_insur_good_leasehold_title: {
        displayName: "Indemnity Insurance - Good Leasehold Title",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: true,
    },
    i_insur_insolvency_act_current_transaction_lender: {
        displayName: "Indemnity Insurance - Insolvency Act (Current Transaction) (Lender)",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: true,
    },
    i_insur_insolvency_act_previous_transaction: {
        displayName: "Indemnity Insurance - Insolvency Act (Previous Transaction)",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: true,
    },
    i_insur_japanese_knotweed: {
        displayName: "Indemnity Insurance - Japanese Knotweed",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: true,
    },
    i_insur_lack_of_conservation_area_consent: {
        displayName: "Indemnity Insurance - Lack of Conservation Area Consent",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: true,
    },
    i_insur_lack_of_listed_building_consent: {
        displayName: "Indemnity Insurance - Lack of Listed Building Consent",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: true,
    },
    i_insur_lack_of_party_wall_agreement: {
        displayName: "Indemnity Insurance - Lack of Party Wall Agreement",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: true,
    },
    i_insur_lack_of_prmsion_to_cnstrct_a_vhcl_crossing_drpd_kerb: {
        displayName: "Indemnity Insurance - Lack of Permission to Construct a Vehicle Crossing or Dropped Kerb",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: true,
    },
    i_insur_lack_of_planning_and_or_building_regulations: {
        displayName: "Indemnity Insurance - Lack of Planning and/or Building Regulations",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: true,
    },
    i_insur_limited_or_no_title_guarantee: {
        displayName: "Indemnity Insurance - Limited or No Title Guarantee",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: true,
    },
    i_insur_lost_title_deeds_unknown_rights_easements_covenants: {
        displayName: "Indemnity Insurance - Lost Title Deeds (Unknown Rights, Easements, Covenants)",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: true,
    },
    i_insur_manorial_rights: {
        displayName: "Indemnity Insurance - Manorial Rights",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: true,
    },
    i_insur_mineral_rights: {
        displayName: "Indemnity Insurance - Mineral Rights",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: true,
    },
    i_insur_missing_information_unknown_rights_easements_covenants: {
        displayName: "Indemnity Insurance - Missing Information (Unknown Rights, Easements, Covenants)",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: true,
    },
    i_insur_possessory_title_inc_unkwn_rights_easements_covenants: {
        displayName: "Indemnity Insurance - Possessory Title (including Unknown Rights, Easements, Covenants)",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: true,
    },
    i_insur_restrictive_covenants_known_unknown: {
        displayName: "Indemnity Insurance - Restrictive Covenants (Known/Unknown)",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: true,
    },
    i_insur_right_to_park_leasehold_lender: {
        displayName: "Indemnity Insurance - Right to Park (Leasehold) (Lender)",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: true,
    },
    i_insur_search_auction_validation_delay: {
        displayName: "Indemnity Insurance - Search Auction (Validation/Delay)",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: true,
    },
    i_insur_search_insur_new_build_wrks_carried_out_in_last_12_mnth: {
        displayName: "Indemnity Insurance - Search Insurance (New Build/Works Carried out in Last 12 Months)",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: true,
    },
    i_insur_search_insurance_no_search_validation_delay: {
        displayName: "Indemnity Insurance - Search Insurance (No Search, Validation, Delay)",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: true,
    },
    acting_for_a_company: {
        displayName: "Acting for a Company",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: 19500,
        includedInFeeReport: "profit",
        category: "fee",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    companies_house_registration_fee: {
        displayName: "Companies House Registration Fee",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: 2300,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    land_charges_search: {
        displayName: "Land Charges Search",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: 200,
        includedInFeeReport: "no",
        category: "disbursement",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: true,
    },
    notice_of_transfer_fee: {
        displayName: "Notice of Transfer Fee",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    notice_of_charge_fee: {
        displayName: "Notice of Charge Fee",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    notice_of_transfer_fee_with_vat: {
        displayName: "Notice of Transfer Fee (with VAT)",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    notice_of_charge_fee_with_vat: {
        displayName: "Notice of Charge Fee (with VAT)",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    reservation_fee: {
        displayName: "Reservation Fee",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        DEPRECATED: true, // REASON: This was not the correct model the reservation fee has actually already been paid by the client as so instead should a be a credit on the ledger
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    mortgage_cashback: {
        displayName: "Mortgage Cashback",
        creditOrDebit: "credit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_in_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    paid_reservation_fee: {
        displayName: "Paid Reservation Fee",
        creditOrDebit: "credit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_in_third_party",
        money_not_transferred_into_client_account: true,
        is_a_type_of_search: false,
    },
    allowance_given: {
        displayName: "Allowance Given",
        creditOrDebit: "credit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_in_third_party",
        money_not_transferred_into_client_account: true,
        is_a_type_of_search: false,
    },
    allowance_made: {
        displayName: "Allowance Made",
        creditOrDebit: "debit", 
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: true,
        is_a_type_of_search: false,
    },
    auction_deposit: {
        displayName: "Auction Deposit",
        creditOrDebit: "credit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_in_third_party",
        money_not_transferred_into_client_account: true,
        is_a_type_of_search: false,
    },
    leasehold_pack: {
        displayName: "Leasehold pack",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    leasehold_pack_with_vat: {
        displayName: "Leasehold pack (with VAT)",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    company_search: {
        displayName: "Company Search",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: 1500,
        includedInFeeReport: "no",
        category: "disbursement",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: true,
    },
    deposit_previously_paid: {
        displayName: "Deposit Previously Paid",
        creditOrDebit: "credit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_in_third_party",
        money_not_transferred_into_client_account: true,
        is_a_type_of_search: false,
    },
    client_bank_check: {
        displayName: "Client Bank Check",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: 450,
        includedInFeeReport: "no",
        category: "disbursement",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    beneficial_interest_in_the_estate: {
        displayName: "Beneficial interest in the estate",
        creditOrDebit: "credit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_in_third_party",
        money_not_transferred_into_client_account: true,
        is_a_type_of_search: false,
    },
    help_to_buy_isa_bonus: {
        displayName: "Help to buy ISA bonus",
        creditOrDebit: "credit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_in_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    auctioneer_fees: {
        displayName: "Auctioneer fees",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    transfer_contingency_fee: {
        displayName: "Transfer contingency fee",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    archive_fee: {
        displayName: "Archive Fee",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "profit",
        category: "fee",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    cost_to_comply_with_restriction: {
        displayName: "Cost To Comply With Restriction",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    money_retained_by_us_credit: {
        displayName: "Money retained by us (credit)",
        creditOrDebit: "credit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_in_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    money_retained_by_us_debit: {
        displayName: "Money retained by us (debit)",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    money_retained_by_other_side_credit: {
        displayName: "Money retained by other side (credit)",
        creditOrDebit: "credit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_in_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    money_retained_by_other_side_debit: {
        displayName: "Money retained by other side (debit)",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    cheshire_salt_search: {
        displayName: "Cheshire Salt Search",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "disbursement",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: true,
    },
    fixtures_price: {
        displayName: "Fixtures price",
        creditOrDebit: "credit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_in_third_party", 
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    fixtures_cost: {
        displayName: "Fixtures cost",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    grant_of_probate: {
        displayName: "Grant of Probate",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "disbursement",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    death_certificate: {
        displayName: "Death Certificate",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "disbursement",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    sellers_document_entry_fee: {
        displayName: "Sellers' Document Entry Fee",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    sellers_becoming_member_of_management_company_fee: {
        displayName: "Sellers' Becoming a Member of Management Company Fee",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    repayment_of_grant: {
        displayName: "Repayment of Grant",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no" ,
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    pof_sow_check_fee: {
        displayName: "Proof of funds/source of wealth fee",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: 7000,
        includedInFeeReport: "profit" ,
        category: "fee",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    additional_pof_sow_check_fee: {
        displayName: "Additional proof of funds/source of wealth fee",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: 5000,
        includedInFeeReport: "profit" ,
        category: "fee",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    compass_sdlt_review: {
        displayName: "Compass SDLT Review",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: 5000,
        includedInFeeReport: "no" ,
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    compass_referred_sdlt_advice: {
        displayName: "Compass Referred SDLT Advice",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no" ,
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    key_undertaking: {
        displayName: "Key Undertaking",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: 10000,
        includedInFeeReport: "profit",
        category: "fee",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    license_to_assign: {
        displayName: "License to Assign",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: 10000,
        includedInFeeReport: "profit",
        category: "fee",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    council_tax_payment: {
        displayName: "Council Tax Payment",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no" ,
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    provira_loan: {
        displayName: "Provira Loan",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    payment_to_affinion: {
        displayName: "Payment to Affinion",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    payment_to_new_law: {
        displayName: "Payment to New Law",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    house_clearance_incl_vat: {
        displayName: "House Clearance - incl. VAT",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    house_clearance_excl_vat: {
        displayName: "House Clearance - excl. VAT",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    third_party_payment_incl_vat: {
        displayName: "Third Party Payment - incl. VAT",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    third_party_payment_excl_vat: {
        displayName: "Third Party Payment - excl. VAT",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    infotrack_fee_incl_vat: {
        displayName: "InfoTrack Fee (post-completion) - incl. VAT",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    infotrack_fee_excl_vat: {
        displayName: "InfoTrack Fee (post-completion) - excl. VAT",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    staircasing_purchase_price: {
        displayName: "Staircasing Purchase Price",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    deed_of_covenant_incl_vat: {
        displayName: "Deed of Covenant - incl. VAT",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    deed_of_covenant_excl_vat: {
        displayName: "Deed of Covenant - excl. VAT",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    case_management_fee: {
        displayName: "Case Management Fee",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "profit",
        category: "fee",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    conveyance_transfer_official_copy: {
        displayName: "Conveyance/Transfer (Official Copy)",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "disbursement",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    ground_rent_arrears: {
        displayName: "Ground Rent Arrears - excl. VAT",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    service_charge_arrears: {
        displayName: "Service Charge Arrears - excl. VAT",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    lisa_payment: {
        displayName: "LISA Payment",
        creditOrDebit: "credit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_in_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    preparation_of_auction_pack: {
        displayName: "Preparation of Auction Pack",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "profit",
        category: "fee",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    notice_to_complete_incoming: {
        displayName: "Notice to Complete - incoming",
        creditOrDebit: "credit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "no",
        category: "transfer_in_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    notice_to_complete_outgoing: {
        displayName: "Notice to Complete - outgoing",
        creditOrDebit: "debit",
        vatStatus: false,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "profit",
        category: "transfer_out_third_party",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    notice_to_complete_fee: {
        displayName: "Notice to Complete Fee",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: null,
        includedInFeeReport: "profit",
        category: "fee",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
    expedition_of_transaction_fee: {
        displayName: "Expedition of Transaction Fee",
        creditOrDebit: "debit",
        vatStatus: true,
        prefilledAmountGbpPence: 12500,
        includedInFeeReport: "profit",
        category: "fee",
        money_not_transferred_into_client_account: false,
        is_a_type_of_search: false,
    },
});

const enumValues = pipe(
    ledgerTypeDictionary,
    record.keys,
    array.map((v) => literal(v))
) as nonEmptyArray.NonEmptyArray< // Couldn't figure out how to do this without an as, if someone is smarter go for it
    TCodec<
        "LiteralCodec",
        keyof typeof ledgerTypeDictionary,
        keyof typeof ledgerTypeDictionary,
        keyof typeof ledgerTypeDictionary
    >
>;

export const CaseLedgerType = union(
    enumValues
);
export type TCaseLedgerType = TTypeOfCodec<typeof CaseLedgerType>;

export const CaseLedgerType_displayString = (type: TCaseLedgerType): string => ledgerTypeDictionary[type].displayName;

export const CaseLedgerEntry1 = required({
    case_id: uuid(),
    id: uuid(),
    type: CaseLedgerType,
    credit_or_debit: CaseCreditDebit,
    vat_excluded_amount_gbp_pence: positiveInteger(),
    vat_amount_gbp_pence: positiveInteger(),
    vat_status: boolean(),
    paid_at: union([dateTime(), nullCodec()]),
    user_says_paid: union([dateTime(), nullCodec()]),
    slip_added_to_cashroom: union([dateTime(), nullCodec()]),
    created_at: dateTime(),
    category: CaseLedgerCategory,
    display_name: string(),
});
export type TCaseLedgerEntry1 = TTypeOfCodec<typeof CaseLedgerEntry1>;
export type TCaseLedgerEntry1Codec = typeof CaseLedgerEntry1;

export const CaseLedgerFinal1 = required({
    id: uuid(),
    ledger_finalised_at: union([dateTime(), nullCodec()]),
});
export type TCaseLedgerFinal1 = TTypeOfCodec<typeof CaseLedgerFinal1>;
export type TCaseLedgerFinal1NewDefault = TTypeOfNewDefault<typeof CaseLedgerFinal1>;
export type TCaseLedgerFinal1Codec = typeof CaseLedgerFinal1;

export const CaseLedgerAmountAndPaidStatus = intersection([
    required({
        id: uuid(),
        amount: integer(),
        type: CaseLedgerType,
    }),
    requiredFlatOverloaded({
        status: CaseLedgerPaidStatus,
    })
]);
export type TCaseLedgerAmountAndPaidStatusCodec = typeof CaseLedgerAmountAndPaidStatus;
export type TCaseLedgerAmountAndPaidStatus = TTypeOfCodec<TCaseLedgerAmountAndPaidStatusCodec>;

export const CaseLedgerAmountsAndPaidStatuses = overload(
    required({
        items: arrayCodec(CaseLedgerAmountAndPaidStatus),
        totalAmountDuePence: positiveInteger(),
        unpaidCaseLedgerEntriesIds: arrayCodec(string()),
    }),
    arrayCodec(CaseLedgerAmountAndPaidStatus),
    (items) => ({
        items,
        totalAmountDuePence:
            pipe(
                items,
                array.filter(({status}) => status === "not_paid"),
                array.map(({amount}) => amount),
                array.reduce(0, (a, b) => a + b),
            ),
        unpaidCaseLedgerEntriesIds:
            pipe(
                items,
                array.filter(({status}) => status === "not_paid"),
                array.map(({id}) => id),
            ),
    }),
);
export type TCaseLedgerAmountsAndPaidStatusesCodec = typeof CaseLedgerAmountsAndPaidStatuses;
export type TCaseLedgerAmountsAndPaidStatuses = TTypeOfCodec<TCaseLedgerAmountsAndPaidStatusesCodec>;

export const CaseLedgerEntry2 = required({
    type: CaseLedgerType,
    credit_or_debit: CaseCreditDebit,
    vat_excluded_amount_gbp_pence: positiveInteger(),
    vat_amount_gbp_pence: positiveInteger(),
    vat_status: boolean(),
    category: CaseLedgerCategory,
    display_name: string(),
});
export type TCaseLedgerEntry2Codec = typeof CaseLedgerEntry2;
export type TCaseLedgerEntry2 = TTypeOfCodec<TCaseLedgerEntry2Codec>;