import { overload } from "../../../shared/src/codecs/types/overload";
import { required } from "../../../shared/src/codecs/types/required";
import { string } from "../../../shared/src/codecs/types/string";

export const CreateLegalFileDownloadUrl = (fileName: string) => overload(
    string(),
    required({
        download_token: string(),
    }),
    ({download_token}) =>
        `https://api.${process.env.DOMAIN_NAME}/web/legal-file-download?fileToken=${encodeURIComponent(download_token)}&fileName=${encodeURIComponent(fileName)}`
);
