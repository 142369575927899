import { TTypeOfCodec } from "../../../shared/src/codecs/codec";
import { array, TArrayCodec } from "../../../shared/src/codecs/types/array";
import { form, TFormCodec } from "../../../shared/src/codecs/types/form";
import { intersection, TIntersectionCodec } from "../../../shared/src/codecs/types/intersection";
import { literal } from "../../../shared/src/codecs/types/literal";
import { nullCodec, TNullCodec } from "../../../shared/src/codecs/types/nullCodec";
import { positiveInteger } from "../../../shared/src/codecs/types/positiveInteger";
import { required, TRequiredCodec } from "../../../shared/src/codecs/types/required";
import { string, TAnyStringCodec } from "../../../shared/src/codecs/types/string";
import { TUnionCodec, union } from "../../../shared/src/codecs/types/union";
import { LandRegistryOfficialCopyWithSummaryResponse, LandRegistrySearchPropertiesResponseError, LandRegistrySearchPropertiesResponseOutOfHours, TLandRegistryOfficialCopyWithSummaryResponseCodec } from "../LandRegistry";
import { PublicPropertyReportAddressSearch, PublicPropertyReportCreate, PublicPropertyReportTitleSearch, TPublicPropertyReportAddressSearchCodec, TPublicPropertyReportCreateCodec, TPublicPropertyReportTitleSearchCodec } from "../PublicPropertyReport";
import { Quotes, TQuotesCodec } from "../Quote";
import { Tenure1, TTenure1Codec } from "../Tenure";

export const PublicPropertyReportCreateForm: TFormCodec<
    TIntersectionCodec<[
        TPublicPropertyReportCreateCodec,
        TRequiredCodec<{
            recaptcha_token: TAnyStringCodec
        }>
    ]>,
    TRequiredCodec<{
        read_only: TRequiredCodec<{
            tenure: TTenure1Codec,
            sub_building_name: TAnyStringCodec,
            building_name: TAnyStringCodec,
            building_number: TAnyStringCodec,
            street_name: TAnyStringCodec,
            city: TAnyStringCodec,
            postcode: TAnyStringCodec,
            full_address: TAnyStringCodec,
        }>
        response: TUnionCodec<[
            TNullCodec,
            TLandRegistryOfficialCopyWithSummaryResponseCodec,
        ]>,
        title_register_url: TAnyStringCodec,
        quotes: TQuotesCodec
    }>
> = form(
    intersection([
        PublicPropertyReportCreate,
        required({
            recaptcha_token: string(),
        }),
    ]),
    required({
        read_only: required({
            tenure: Tenure1,
            sub_building_name: string(),
            building_name: string(),
            building_number: string(),
            street_name: string(),
            city: string(),
            postcode: string(),
            full_address: string(),
        }),
        response: union([
            nullCodec(),
            LandRegistryOfficialCopyWithSummaryResponse,
        ]),
        title_register_url: string(),
        quotes: Quotes,
    }),
);
export type TPublicPropertyReportCreateFormCodec = typeof PublicPropertyReportCreateForm;
export type TPublicPropertyReportCreateForm = TTypeOfCodec<TPublicPropertyReportCreateFormCodec>;

const LandRegistrySearchPropertiesResponseSuccessOverride =
    required({
        _tag: literal("success"),
        results: array(PublicPropertyReportCreateForm),
    });
export type TLandRegistrySearchPropertiesResponseSuccessOverride = TTypeOfCodec<typeof LandRegistrySearchPropertiesResponseSuccessOverride>;

const PublicPropertyReportTitleSearchResponse = union([
    LandRegistrySearchPropertiesResponseSuccessOverride,
    LandRegistrySearchPropertiesResponseError,
    LandRegistrySearchPropertiesResponseOutOfHours,
]);
type TPublicPropertyReportTitleSearchResponseCodec = typeof PublicPropertyReportTitleSearchResponse;
export type TPublicPropertyReportTitleSearchResponse = TTypeOfCodec<TPublicPropertyReportTitleSearchResponseCodec>;

export const PublicPropertyReportTitleSearchForm: TFormCodec<
    TPublicPropertyReportTitleSearchCodec,
    TRequiredCodec<{
        response: TUnionCodec<[
            TNullCodec,
            TPublicPropertyReportTitleSearchResponseCodec
        ]>,
        address_string: TAnyStringCodec
    }>
> = form(
    PublicPropertyReportTitleSearch,
    required({
        response: union([
            nullCodec(),
            PublicPropertyReportTitleSearchResponse,
        ]),
        address_string: string(),
    }),
);
export type TPublicPropertyReportTitleSearchFormCodec = typeof PublicPropertyReportTitleSearchForm;
export type TPublicPropertyReportTitleSearchForm = TTypeOfCodec<TPublicPropertyReportTitleSearchFormCodec>;

export const PublicPropertyReportAddressSearchForm: TFormCodec<
    TPublicPropertyReportAddressSearchCodec,
    TRequiredCodec<{
        results: TArrayCodec<TPublicPropertyReportTitleSearchFormCodec>
    }>
> = form(
    PublicPropertyReportAddressSearch,
    required({
        results: array(PublicPropertyReportTitleSearchForm),
    })
);
export type TPublicPropertyReportAddressSearchFormCodec = typeof PublicPropertyReportAddressSearchForm;
export type TPublicPropertyReportAddressSearchForm = TTypeOfCodec<TPublicPropertyReportAddressSearchFormCodec>;

export const PublicPropertyReportPageView = union([
    literal("form_initial"),
    literal("form_transaction_type"),
    literal("form_wants_valuation"),
    literal("form_executor_or_beneficiary"),
    literal("address_select"),
    literal("title_select"),
    literal("results"),
]);
export type TPublicPropertyReportPageView = TTypeOfCodec<typeof PublicPropertyReportPageView>;

export const PublicPropertyReportPage = required({
    selected_address_id: union([nullCodec(), positiveInteger()]),
    visible_title_number: union([nullCodec(), string()]),
    form: PublicPropertyReportAddressSearchForm,
    view: PublicPropertyReportPageView,
});
export type TPublicPropertyReportPageCodec = typeof PublicPropertyReportPage;
