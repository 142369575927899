import { TTypeOfCodec } from "../../shared/src/codecs/codec";
import { union } from "../../shared/src/codecs/types/union";
import { literal } from "../../shared/src/codecs/types/literal";

export const TitleEntrySection = union([
    literal("unknown"),
    literal("A"),
    literal("B"),
    literal("C"),
    literal("D"),
]);
export type TTitleEntrySection = TTypeOfCodec<typeof TitleEntrySection>;
