import * as t from "io-ts";

export const codec = t.type({
    legal_file_id: t.string,
    cases_document_id: t.string,
    name: t.string,
    file_extension: t.string,
    created_at: t.string,
    id: t.string,
});

export type T = t.TypeOf<typeof codec>;

export const newDefault = (): T => ({
    legal_file_id: "",
    cases_document_id: "",
    name: "",
    file_extension: "",
    created_at: "",
    id: "",
});
