import React from "react";

export default (props: React.SVGAttributes<SVGElement>) => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M8 19H21V17H8V19ZM8 13H21V11H8V13ZM8 5V7H21V5H8Z" fill="black"/>
<path d="M3 19L3 5L5 5L5 19L3 19Z" fill="black"/>
<path d="M2 6C2 4.89333 2.89333 4 4 4C5.10667 4 6 4.89333 6 6C6 7.10667 5.10667 8 4 8C2.89333 8 2 7.10667 2 6Z" fill="black"/>
<path d="M2 12C2 10.8933 2.89333 10 4 10C5.10667 10 6 10.8933 6 12C6 13.1067 5.10667 14 4 14C2.89333 14 2 13.1067 2 12Z" fill="black"/>
<path d="M2 18C2 16.8933 2.89333 16 4 16C5.10667 16 6 16.8933 6 18C6 19.1067 5.10667 20 4 20C2.89333 20 2 19.1067 2 18Z" fill="black"/>
</svg>
;
