import { TTypeOfCodec } from "../../../shared/src/codecs/codec";
import { TArrayCodec, array } from "../../../shared/src/codecs/types/array";
import { TBooleanCodec, boolean } from "../../../shared/src/codecs/types/boolean";
import { TAnyDateCodec, TDateCodec, date } from "../../../shared/src/codecs/types/date";
import { dateTime } from "../../../shared/src/codecs/types/dateTime";
import { TFormCodec, form } from "../../../shared/src/codecs/types/form";
import { TIntersectionCodec, intersection } from "../../../shared/src/codecs/types/intersection";
import { TLiteralCodec, literal } from "../../../shared/src/codecs/types/literal";
import { TNullCodec, nullCodec } from "../../../shared/src/codecs/types/nullCodec";
import { overload } from "../../../shared/src/codecs/types/overload";
import { TAnyPositiveIntegerCodec, positiveInteger } from "../../../shared/src/codecs/types/positiveInteger";
import { TRequiredCodec, required } from "../../../shared/src/codecs/types/required";
import { TRequiredFlatOverloadedCodec, requiredFlatOverloaded } from "../../../shared/src/codecs/types/requiredFlatOverloaded";
import { TAnyStringCodec, string } from "../../../shared/src/codecs/types/string";
import { TUnionCodec, union } from "../../../shared/src/codecs/types/union";
import { TUuidCodec, uuid } from "../../../shared/src/codecs/types/uuid";
import { requireExhaustive } from "../../../shared/src/util";
import { CaseAuctionStatusType } from "../CaseAuctionStatusType";
import { CaseSellerReasonForSale1 } from "../CaseSellerReasonForSale";
import { CaseStatus, ChaseReasons, TCaseStatusCodec } from "../Cases";
import { EmptyObject, TEmptyObjectCodec } from "../EmptyObject";
import { Introducer4, TIntroducer4Codec } from "../Introducer";
import { TransactionType1 } from "../TransactionType";
import { PlainUser, TPlainUserCodec } from "../User";
import { AddressFirstLines, TAddressFirstLinesCodec } from "../overloads/AddressFirstLine";
import { TUserInitialsCodec, UserInitials } from "../overloads/UserInitials";
import { TUserLastNamesCodec, UserLastNames } from "../overloads/UserLastName";
import { ChaseStatus } from "./CasesChaseTransitionForm";
import { TrueRoadblockType } from "./RoadblockForm";

// EXPECTED EXCHANGE DATE TASK
const CasesCaseTaskFormExpectedExchangeDateEdited = required({
    tag: literal("expected_exchange_date"),
    id: uuid(),
    date: date(),
});
const CasesCaseTaskFormExpectedExchangeDateChildren = required({
    category: literal("key_date"),
    is_done: boolean(),
    case_id: uuid(),
});

export const CasesCaseTaskFormExpectedExchangeDateForCasesView = form(
    CasesCaseTaskFormExpectedExchangeDateEdited,
    CasesCaseTaskFormExpectedExchangeDateChildren,
);
export type TCasesCaseTaskFormExpectedExchangeDateForCasesView = TTypeOfCodec<typeof CasesCaseTaskFormExpectedExchangeDateForCasesView>;

export const CasesCaseTaskFormExpectedExchangeDateForDayView = form(
    CasesCaseTaskFormExpectedExchangeDateEdited,
    intersection([
        CasesCaseTaskFormExpectedExchangeDateChildren,
        requiredFlatOverloaded({
            address: AddressFirstLines,
        }),
    ]),
);
export type TCasesCaseTaskFormExpectedExchangeDateForDayView = TTypeOfCodec<typeof CasesCaseTaskFormExpectedExchangeDateForDayView>;


// EXPECTED COMPLETION DATE TASK
const CasesCaseTaskFormExpectedCompletionDateEdited = required({
    tag: literal("expected_completion_date"),
    id: uuid(),
    date: date(),
});
const CasesCaseTaskFormExpectedCompletionDateChildren = required({
    category: literal("key_date"),
    is_done: boolean(),
    case_id: uuid(),
});

export const CasesCaseTaskFormExpectedCompletionDateForCasesView = form(
    CasesCaseTaskFormExpectedCompletionDateEdited,
    CasesCaseTaskFormExpectedCompletionDateChildren,
);
export type TCasesCaseTaskFormExpectedCompletionDateForCasesView = TTypeOfCodec<typeof CasesCaseTaskFormExpectedCompletionDateForCasesView>;

export const CasesCaseTaskFormExpectedCompletionDateForDayView = form(
    CasesCaseTaskFormExpectedCompletionDateEdited,
    intersection([
        CasesCaseTaskFormExpectedCompletionDateChildren,
        requiredFlatOverloaded({
            address: AddressFirstLines,
        }),
    ]),
);
export type TCasesCaseTaskFormExpectedCompletionDateForDayView = TTypeOfCodec<typeof CasesCaseTaskFormExpectedCompletionDateForDayView>;


// EXCHANGE DEADLINE DATE TASK
const CasesCaseTaskFormExchangeDeadlineDateEdited = required({
    tag: literal("exchange_deadline_date"),
    id: uuid(),
    date: date(),
});
const CasesCaseTaskFormExchangeDeadlineDateChildren = required({
    category: literal("key_date"),
    is_done: boolean(),
    case_id: uuid(),
});

export const CasesCaseTaskFormExchangeDeadlineDateForCasesView = form(
    CasesCaseTaskFormExchangeDeadlineDateEdited,
    CasesCaseTaskFormExchangeDeadlineDateChildren,
);
export type TCasesCaseTaskFormExchangeDeadlineDateForCasesView = TTypeOfCodec<typeof CasesCaseTaskFormExchangeDeadlineDateForCasesView>;

export const CasesCaseTaskFormExchangeDeadlineDateForDayView = form(
    CasesCaseTaskFormExchangeDeadlineDateEdited,
    intersection([
        CasesCaseTaskFormExchangeDeadlineDateChildren,
        requiredFlatOverloaded({
            address: AddressFirstLines,
        }),
    ]),
);
export type TCasesCaseTaskFormExchangeDeadlineDateForDayView = TTypeOfCodec<typeof CasesCaseTaskFormExchangeDeadlineDateForDayView>;


// COMPLETION DEADLINE DATE TASK
const CasesCaseTaskFormCompletionDeadlineDateEdited = required({
    tag: literal("completion_deadline_date"),
    id: uuid(),
    date: date(),
});
const CasesCaseTaskFormCompletionDeadlineDateChildren = required({
    category: literal("key_date"),
    is_done: boolean(),
    case_id: uuid(),
});
export const CasesCaseTaskFormCompletionDeadlineDateForCasesView = form(
    CasesCaseTaskFormCompletionDeadlineDateEdited,
    CasesCaseTaskFormCompletionDeadlineDateChildren,
);
export type TCasesCaseTaskFormCompletionDeadlineDateForCasesView = TTypeOfCodec<typeof CasesCaseTaskFormCompletionDeadlineDateForCasesView>;

export const CasesCaseTaskFormCompletionDeadlineDateForDayView = form(
    CasesCaseTaskFormCompletionDeadlineDateEdited,
    intersection([
        CasesCaseTaskFormCompletionDeadlineDateChildren,
        requiredFlatOverloaded({
            address: AddressFirstLines,
        }),
    ]),
);
export type TCasesCaseTaskFormCompletionDeadlineDateForDayView = TTypeOfCodec<typeof CasesCaseTaskFormCompletionDeadlineDateForDayView>;


// AD HOC TASK
const CasesCaseTaskFormAdhocEdited = required({
    tag: literal("adhoc"),
    id: uuid(),
    date: date(),
    assigned_to_user_id: uuid(),
    job: string(),
    is_done: union([
        nullCodec(),
        dateTime(),
    ]),
});
const CasesCaseTaskFormAdhocChildren = required({
    category: literal("task"),
    assigned_to_initials: UserInitials,
    case_id: uuid(),
});
export const CasesCaseTaskFormAdhocForCasesView = form(
    CasesCaseTaskFormAdhocEdited,
    CasesCaseTaskFormAdhocChildren,
);
export type TCasesCaseTaskFormAdhocCodec = typeof CasesCaseTaskFormAdhocForCasesView;
export type TCasesCaseTaskFormAdhoc = TTypeOfCodec<TCasesCaseTaskFormAdhocCodec>;

export const CasesCaseTaskFormAdhocForDayView = form(
    CasesCaseTaskFormAdhocEdited,
    intersection([
        CasesCaseTaskFormAdhocChildren,
        requiredFlatOverloaded({
            address: AddressFirstLines,
        }),
    ]),
);
export type TCasesCaseTaskFormAdhocForDayViewCodec = typeof CasesCaseTaskFormAdhocForDayView;
export type TCasesCaseTaskFormAdhocForDayView = TTypeOfCodec<TCasesCaseTaskFormAdhocForDayViewCodec>;


// TASK FORM
export const CasesCaseTaskFormRoadblock = form(
    required({
        tag: literal("roadblock"),
        roadblock: TrueRoadblockType,
        id: uuid(),
        date: date(),
        assigned_to_user_id: uuid(),
    }),
    required({
        category: literal("task"),
        assigned_to_initials: UserInitials,
        is_done: boolean(),
    }),
);

export const CasesCaseTaskFormUpdatableForCasesView = union([
    CasesCaseTaskFormAdhocForCasesView,
]);
export type TCasesCaseTaskFormUpdatableCodec = typeof CasesCaseTaskFormUpdatableForCasesView;
export type TCasesCaseTaskFormUpdatable = TTypeOfCodec<TCasesCaseTaskFormUpdatableCodec>;
export const CasesCaseTaskFormUpdatable_fold =
    <A>(fp: {
        onCasesCaseTaskFormAdhoc: (v: TCasesCaseTaskFormAdhoc) => A,
    }) =>
        (p: TCasesCaseTaskFormUpdatable) =>
            fp.onCasesCaseTaskFormAdhoc(p);

export const CasesCaseTaskFormUpdatableForDayView = union([
    CasesCaseTaskFormAdhocForDayView,
]);

export const CasesCaseTaskFormForCasesView = union([
    ...CasesCaseTaskFormUpdatableForCasesView.payload,
    CasesCaseTaskFormExpectedExchangeDateForCasesView,
    CasesCaseTaskFormExpectedCompletionDateForCasesView,
    CasesCaseTaskFormExchangeDeadlineDateForCasesView,
    CasesCaseTaskFormCompletionDeadlineDateForCasesView,
]);
export type TCasesCaseTaskFormForCasesViewCodec = typeof CasesCaseTaskFormForCasesView;
export type TCasesCaseTaskFormForCasesView = TTypeOfCodec<TCasesCaseTaskFormForCasesViewCodec>;

export const CasesCaseTaskFormForDayView = union([
    ...CasesCaseTaskFormUpdatableForDayView.payload,
    CasesCaseTaskFormExpectedExchangeDateForDayView,
    CasesCaseTaskFormExpectedCompletionDateForDayView,
    CasesCaseTaskFormExchangeDeadlineDateForDayView,
    CasesCaseTaskFormCompletionDeadlineDateForDayView,
]);
export type TCasesCaseTaskFormForDayViewCodec = typeof CasesCaseTaskFormForDayView;
export type TCasesCaseTaskFormForDayView = TTypeOfCodec<TCasesCaseTaskFormForDayViewCodec>;


export type TAdhocTaskForms = TCasesCaseTaskFormAdhoc | TCasesCaseTaskFormAdhocForDayView;

export type TKeyDateTaskForms =
    TCasesCaseTaskFormExpectedExchangeDateForCasesView
    | TCasesCaseTaskFormExpectedCompletionDateForCasesView
    | TCasesCaseTaskFormExchangeDeadlineDateForCasesView
    | TCasesCaseTaskFormCompletionDeadlineDateForCasesView
    | TCasesCaseTaskFormExpectedExchangeDateForDayView
    | TCasesCaseTaskFormExpectedCompletionDateForDayView
    | TCasesCaseTaskFormExchangeDeadlineDateForDayView
    | TCasesCaseTaskFormCompletionDeadlineDateForDayView
;

const CasesCaseType = union([
    ...TransactionType1.payload,
    literal("probate_sale"),
    literal("probate_transfer"),
    literal("repossession_purchase"),
    literal("repossession_sale"),
    literal("auction_purchase"),
    literal("auction_sale"),
]);

const CasesCaseTypeOverload = overload(
    CasesCaseType,
    required({
        transaction_type: TransactionType1,
        seller_reason_for_sale: CaseSellerReasonForSale1,
        auction_status: CaseAuctionStatusType,
    }),
    (p) =>
        p.auction_status === "yes" && p.transaction_type === "purchase" ? "auction_purchase"
        : p.auction_status === "yes" && p.transaction_type === "sale" ? "auction_sale"
        : p.seller_reason_for_sale === "repossession" && p.transaction_type === "purchase" ? "repossession_purchase"
        : p.seller_reason_for_sale === "repossession" && p.transaction_type === "sale" ? "repossession_sale"
        : p.seller_reason_for_sale === "probate" && p.transaction_type === "sale" ? "probate_sale"
        : p.seller_reason_for_sale === "probate" && p.transaction_type === "transfer" ? "probate_transfer"
        : p.transaction_type,
);
type TCasesCaseTypeOverloadCodec = typeof CasesCaseTypeOverload;
export type TCasesCaseTypeOverload = TTypeOfCodec<TCasesCaseTypeOverloadCodec>;

const CasesCaseMilestone = union([
    literal("not_applicable"),
    literal("in_progress"),
    literal("complete"),
]);
type TCasesCaseMilestoneCodec = typeof CasesCaseMilestone;
export type TCasesCaseMilestone = TTypeOfCodec<TCasesCaseMilestoneCodec>;

export const CasesCaseCreateAdhocForm: TFormCodec<
    TRequiredCodec<{
        id: TUuidCodec,
        job: TAnyStringCodec,
        date: TUnionCodec<[
            TNullCodec,
            TDateCodec
        ]>,
        assigned_to: TUuidCodec
    }>,
    TEmptyObjectCodec
> = form(
    required({
        id: uuid(),
        job: string(),
        date: union([
            nullCodec(),
            date(),
        ]),
        assigned_to: uuid(),
    }),
    EmptyObject,
);
export type TCasesCaseCreateAdhocFormCodec = typeof CasesCaseCreateAdhocForm;
export type TCasesCaseCreateAdhocForm = TTypeOfCodec<TCasesCaseCreateAdhocFormCodec>;

const ClientsCompanyName = overload(
    string(),
    required({
        company_name: string(),
    }),
    ({company_name}) => company_name,
);
type TClientsCompanyNameCodec = typeof ClientsCompanyName;

export const CasesCaseViewFilterChaseStatus = union([
    literal("any"),
    ...ChaseStatus.payload,
    literal("timeout"),
]);
type TCasesCaseViewFilterChaseStatusCodec = typeof CasesCaseViewFilterChaseStatus;
export type TCasesCaseViewFilterChaseStatus = TTypeOfCodec<TCasesCaseViewFilterChaseStatusCodec>;

const CasesCaseFormNextDateType = union([
    literal("normal"),
    literal("deadline"),
    literal("sim"),
    literal("exchange"),
    literal("completion"),
]);
type TCasesCaseFormNextDateTypeCodec = typeof CasesCaseFormNextDateType;
type TCasesCaseFormNextDateType = TTypeOfCodec<TCasesCaseFormNextDateTypeCodec>;
export const CasesCaseFormNextDateType_displayString = (p: TCasesCaseFormNextDateType): string =>
    p === "completion" ? "Comp."
    : p === "exchange" ? "Exch."
    : p === "sim" ? "SIM"
    : p === "deadline" ? "Deadline"
    : p === "normal" ? ""
    : requireExhaustive(p);

const CasesCaseLastChaseTransition = required({
    status: union([
        ...ChaseStatus.payload,
        literal("timeout"),
    ]),
    acknowledged: boolean(),
    chase_reasons: array(ChaseReasons),
});
type TCasesCaseLastChaseTransitionCodec = typeof CasesCaseLastChaseTransition;

export const CasesCaseForm: TFormCodec<
    TRequiredCodec<{
        id: TUuidCodec,
        comment: TAnyStringCodec,
        is_pinned: TBooleanCodec
    }>,
    TIntersectionCodec<[
        TRequiredFlatOverloadedCodec<{
            address: TAddressFirstLinesCodec,
            clients: TUnionCodec<[
                TUserLastNamesCodec,
                TClientsCompanyNameCodec
            ]>,
            type: TCasesCaseTypeOverloadCodec
        }>,
        TRequiredCodec<{
            status: TCaseStatusCodec,
            next_date: TRequiredCodec<{
                type: TCasesCaseFormNextDateTypeCodec,
                date: TUnionCodec<[
                    TNullCodec,
                    TDateCodec
                ]>
            }>,
            dead_time_days: TAnyPositiveIntegerCodec,
            case_handler_initials: TUnionCodec<[
                TNullCodec,
                TUserInitialsCodec
            ]>,
            milestones: TRequiredCodec<{
                dcp: TCasesCaseMilestoneCodec,
                enquiries_raised: TCasesCaseMilestoneCodec,
                enquiries_resolved: TCasesCaseMilestoneCodec,
                gop_loa: TCasesCaseMilestoneCodec,
                lpe1: TCasesCaseMilestoneCodec,
                redemption_statement: TCasesCaseMilestoneCodec,
                signed_docs: TCasesCaseMilestoneCodec,
                exchanged: TCasesCaseMilestoneCodec,
                completed: TCasesCaseMilestoneCodec,
            }>,
            has_sail_homes_listing: TBooleanCodec,
            last_chase_transition: TCasesCaseLastChaseTransitionCodec,
            task_forms: TArrayCodec<TCasesCaseTaskFormForCasesViewCodec>,
            create_adhoc_form: TCasesCaseCreateAdhocFormCodec
        }>
    ]>
> = form(
    required({
        id: uuid(),
        comment: string(),
        is_pinned: boolean(),
    }),
    intersection([
        requiredFlatOverloaded({
            address: AddressFirstLines,
            clients: union([
                UserLastNames,
                ClientsCompanyName,
            ]),
            type: CasesCaseTypeOverload,
        }),
        required({
            status: CaseStatus,
            next_date: required({
                type: CasesCaseFormNextDateType,
                date: union([
                    nullCodec(),
                    date(),
                ]),
            }),
            dead_time_days: positiveInteger(),
            case_handler_initials: union([
                nullCodec(),
                UserInitials,
            ]),
            milestones: required({
                dcp: CasesCaseMilestone,
                enquiries_raised: CasesCaseMilestone,
                enquiries_resolved: CasesCaseMilestone,
                gop_loa: CasesCaseMilestone,
                lpe1: CasesCaseMilestone,
                redemption_statement: CasesCaseMilestone,
                signed_docs: CasesCaseMilestone,
                exchanged: CasesCaseMilestone,
                completed: CasesCaseMilestone,
            }),
            has_sail_homes_listing: boolean(),
            last_chase_transition: CasesCaseLastChaseTransition,
            task_forms: array(CasesCaseTaskFormForCasesView),
            create_adhoc_form: CasesCaseCreateAdhocForm,
        }),
    ]),
);
export type TCasesCaseFormCodec = typeof CasesCaseForm;
export type TCasesCaseForm = TTypeOfCodec<TCasesCaseFormCodec>;

export const CasesCaseViewFilterStatus = union([
    literal("any_instructed"),
    literal("any_instructed_excluding_completed"),
    literal("any_instructed_excluding_abeyance_and_completed"),
    literal("property_report"),
    literal("quote"),
    literal("only_completed"),
]);
export type TCasesCaseViewFilterStatusCodec = typeof CasesCaseViewFilterStatus;
export type TCasesCaseViewFilterStatus = TTypeOfCodec<TCasesCaseViewFilterStatusCodec>;

export const TableSortingDirection = union([
    literal("ascending"),
    literal("descending"),
]);
export type TTableSortingDirectionCodec = typeof TableSortingDirection;
export type TTableSortingDirection = TTypeOfCodec<TTableSortingDirectionCodec>;

export const CasesCaseViewSortableColumns = union([
    literal("next_date"),
    literal("dead_time"),
    literal("is_pinned"),
    literal("has_sail_homes_listing"),
    literal("chase_mode"),
]);
export type TCasesCaseViewSortableColumnsCodec = typeof CasesCaseViewSortableColumns;
export type TCasesCaseViewSortableColumns = TTypeOfCodec<TCasesCaseViewSortableColumnsCodec>;

export const CasesKeyDate = intersection([
    required({
        id: uuid(),
        date: date(),
        type: union([
            literal("expected_exchange_date"),
            literal("expected_completion_date"),
            literal("exchange_deadline_date"),
            literal("completion_deadline_date"),
        ]),
    }),
    requiredFlatOverloaded({
        address: AddressFirstLines,
    }),
]);
export type TCasesKeyDateCodec = typeof CasesKeyDate;
export type TCasesKeyDate = TTypeOfCodec<TCasesKeyDateCodec>;

export const CasesCaseViewForm: TFormCodec<
    TRequiredCodec<{
        filters: TRequiredCodec<{
            status: TCasesCaseViewFilterStatusCodec,
            assigned_to: TUnionCodec<[
                TNullCodec,
                TUuidCodec
            ]>,
            introduced_by: TUnionCodec<[
                TNullCodec,
                TUuidCodec
            ]>,
            chase: TCasesCaseViewFilterChaseStatusCodec
        }>,
        order: TUnionCodec<[
            TNullCodec,
            TRequiredCodec<{
                column: TCasesCaseViewSortableColumnsCodec,
                direction: TTableSortingDirectionCodec
            }>
        ]>,
        pagination: TRequiredCodec<{
            offset: TAnyPositiveIntegerCodec,
            limit: TAnyPositiveIntegerCodec,
        }>
    }>,
    TRequiredCodec<{
        case_forms: TArrayCodec<TCasesCaseFormCodec>,
        adhoc_assignable_users: TArrayCodec<TPlainUserCodec>,
        case_handler_users: TArrayCodec<TPlainUserCodec>,
        introducers: TArrayCodec<TIntroducer4Codec>,
        available_count: TAnyPositiveIntegerCodec,
        key_dates: TArrayCodec<TCasesKeyDateCodec>
    }>
> = form(
    required({
        filters: required({
            status: CasesCaseViewFilterStatus,
            assigned_to: union([
                nullCodec(),
                uuid(),
            ]),
            introduced_by: union([
                nullCodec(),
                uuid(),
            ]),
            chase: CasesCaseViewFilterChaseStatus,
        }),
        order: union([
            nullCodec(),
            required({
                column: CasesCaseViewSortableColumns,
                direction: TableSortingDirection
            }),
        ]),
        pagination: required({
            offset: positiveInteger(),
            limit: positiveInteger(),
        }),
    }),
    required({
        case_forms: array(CasesCaseForm),
        adhoc_assignable_users: array(PlainUser),
        case_handler_users: array(PlainUser),
        introducers: array(Introducer4),
        available_count: positiveInteger(),
        key_dates: array(CasesKeyDate),
    }),
);
export type TCasesCaseViewFormCodec = typeof CasesCaseViewForm;
export type TCasesCaseViewForm = TTypeOfCodec<TCasesCaseViewFormCodec>;

export const CasesDayViewForm: TFormCodec<
    TRequiredCodec<{
        filters: TRequiredCodec<{
            date: TUnionCodec<[
                TRequiredCodec<{
                    type: TLiteralCodec<"iso">,
                    date: TAnyDateCodec
                }>,
                TRequiredCodec<{
                    type: TLiteralCodec<"overdue">
                }>
            ]>,
            assigned_to: TUnionCodec<[
                TNullCodec,
                TUuidCodec
            ]>
        }>,
        pagination: TRequiredCodec<{
            offset: TAnyPositiveIntegerCodec,
            limit: TAnyPositiveIntegerCodec,
        }>
    }>,
    TRequiredCodec<{
        task_forms: TArrayCodec<TCasesCaseTaskFormForDayViewCodec>,
        overdue_count: TAnyPositiveIntegerCodec,
        available_count: TAnyPositiveIntegerCodec,
        assignable_users: TArrayCodec<TPlainUserCodec>,
        key_dates: TArrayCodec<TCasesKeyDateCodec>
    }>
> = form(
    required({
        filters: required({
            date: union([
                required({
                    type: literal("iso"),
                    date: date(),
                }),
                required({
                    type: literal("overdue"),
                }),
            ]),
            assigned_to: union([
                nullCodec(),
                uuid(),
            ]),
        }),
        pagination: required({
            offset: positiveInteger(),
            limit: positiveInteger(),
        }),
    }),
    required({
        task_forms: array(CasesCaseTaskFormForDayView),
        overdue_count: positiveInteger(),
        available_count: positiveInteger(),
        assignable_users: array(PlainUser),
        key_dates: array(CasesKeyDate),
    }),
);
export type TCasesDayViewFormCodec = typeof CasesDayViewForm;
export type TCasesDayViewForm = TTypeOfCodec<TCasesDayViewFormCodec>;

const CasesPageView = union([
    literal("case"),
    literal("day"),
]);
export type TCasesPageView = TTypeOfCodec<typeof CasesPageView>;

export const CasesPage = required({
    view: CasesPageView,
    cases_view_form: CasesCaseViewForm,
    day_view_form: CasesDayViewForm,
    cases_view_open_case_id: union([nullCodec(), uuid()]),
});
export type TCasesPageCodec = typeof CasesPage;
