import { TTypeOfCodec } from "../../../shared/src/codecs/codec";
import { formIO, TFormIOCodec } from "../../../shared/src/codecs/types/formIO";
import { ListingAdHocTaskEditable, TListingAdHocTaskEditableCodec } from "../formEditable/ListingAdHocTaskEditable";
import { ListingAdHocTask2, TListingAdHocTask2Codec } from "../ListingAdHocTask";

export const NewListingAdHocTaskFormIO: TFormIOCodec<TListingAdHocTask2Codec, TListingAdHocTaskEditableCodec> = formIO(
    ListingAdHocTask2,
    ListingAdHocTaskEditable,
);
export type TNewListingAdHocTaskFormIOCodec = typeof NewListingAdHocTaskFormIO;
export type TNewListingAdHocTaskFormIO = TTypeOfCodec<TNewListingAdHocTaskFormIOCodec>;