import React from "react";
import { array } from "fp-ts/lib";
import { ICRMButtonIcon, CRMButtonIcon } from "../CRMButtonIcon/CRMButtonIcon";
import { CRMFormButtonIcon } from "../CRMFormButtonIcon/CRMFormButtonIcon";
import * as TFormStatus from "./../../models/TFormStatus";

export interface ICRMInputButtonIconWrapButton extends ICRMButtonIcon {
    formStatus?: TFormStatus.T;
}

interface ICRMInputButtonIconWrap {
    disabled?: boolean;
    buttonIcons: Array<ICRMInputButtonIconWrapButton>;
}

export class CRMInputButtonIconWrap extends React.Component<React.PropsWithChildren<ICRMInputButtonIconWrap>> {
    public render (): JSX.Element {
        return (
            <div className="crm-input-button-icon-wrap">
                <div className="crm-input-button-icon-wrap__input">
                    {this.props.children}
                </div>
                <div className="crm-input-button-icon-wrap__button-icons">
                    {array.mapWithIndex<ICRMInputButtonIconWrapButton, JSX.Element>((i, buttonIcon) => (
                        <div className="crm-input-button-icon-wrap__button-icon" key={i}>
                            {/* FORM STATUS BUTTON ICON */}
                            {typeof buttonIcon.formStatus === "string" &&
                                <CRMFormButtonIcon
                                    formStatus={buttonIcon.formStatus}
                                    ButtonElement={(props) =>
                                        <CRMButtonIcon
                                            variant={buttonIcon.variant}
                                            disabled={buttonIcon.disabled || this.props.disabled}
                                            {...props}
                                        />
                                    }
                                    title={buttonIcon.title}
                                    icon={buttonIcon.icon}
                                    onClick={buttonIcon.onClick}
                                />
                            }
                            {/* BUTTON ICON */}
                            {!buttonIcon.formStatus &&
                                <CRMButtonIcon
                                    icon={buttonIcon.icon}
                                    onClick={buttonIcon.onClick}
                                    variant={buttonIcon.variant}
                                    title={buttonIcon.title}
                                    disabled={buttonIcon.disabled || this.props.disabled}
                                />
                            }
                        </div>
                    ))(this.props.buttonIcons)}
                </div>
            </div>
        );
    }
}

export default CRMInputButtonIconWrap;
