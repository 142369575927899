import { TCodec } from "../codec";
import { either } from "fp-ts/lib";
import { TError, errorConstants } from "../errors";

export type TAnyStringCappedCodec = TCodec<
    "StringCappedCodec",
    undefined,
    string ,
    string
>;

export const stringCapped = (characterCap: number): TAnyStringCappedCodec => ({
    type: "StringCappedCodec",
    payload: undefined,
    decode: decode(characterCap),
    decodeNewDefault: decode(characterCap),
    newDefault: () => "",
});

const decode = (characterCap: number) =>
    (input: unknown): either.Either<TError, string> =>
        typeof input === "string" && input.length <= characterCap
            ? either.right(input)
            : either.left([[errorConstants.STRING_VALIDATION, ""]])
;