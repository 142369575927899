import { TTypeOfCodec } from "../../../shared/src/codecs/codec";
import { array, TArrayCodec } from "../../../shared/src/codecs/types/array";
import { dateTime, TAnyDateTimeCodec } from "../../../shared/src/codecs/types/dateTime";
import { form, TFormCodec } from "../../../shared/src/codecs/types/form";
import { nullCodec, TNullCodec } from "../../../shared/src/codecs/types/nullCodec";
import { required, TRequiredCodec } from "../../../shared/src/codecs/types/required";
import { TUnionCodec, union } from "../../../shared/src/codecs/types/union";
import { TUuidCodec, uuid } from "../../../shared/src/codecs/types/uuid";
import { TCaseCharge1Codec, CaseCharge1 } from "../CaseCharge";
import { CasePropertyIdAddressAndTitleNumber, TCasePropertyIdAddressAndTitleNumberCodec } from "../CaseProperty";
import { CaseTitleRestriction1, CaseTitleRestriction2, TCaseTitleRestriction1Codec, TCaseTitleRestriction2Codec } from "../CaseTitleRestriction";
import { EmptyObject, TEmptyObjectCodec } from "../EmptyObject";
import { TUuidObjectCodec, UuidObject } from "../Id";
import { CaseChargeRedemptionCreateForm, CaseChargeRedemptionForm, TCaseChargeRedemptionCreateFormCodec, TCaseChargeRedemptionFormCodec } from "./CaseChargeRedemptionForm";

export const CaseChargeForm: TFormCodec<
    TCaseCharge1Codec,
    TRequiredCodec<{
        case_property: TUnionCodec<[TNullCodec, TCasePropertyIdAddressAndTitleNumberCodec]>,
        client_submitted_account_numbers_at: TUnionCodec<[
            TAnyDateTimeCodec,
            TNullCodec,
        ]>,
        redemption_statement_forms: TArrayCodec<TCaseChargeRedemptionFormCodec>,
        redemption_statement_create_form: TCaseChargeRedemptionCreateFormCodec,
    }>
> = 
    form(
        CaseCharge1,
        required({
            case_property: union([nullCodec(), CasePropertyIdAddressAndTitleNumber]),
            client_submitted_account_numbers_at: union([dateTime(), nullCodec()]),
            redemption_statement_forms: array(CaseChargeRedemptionForm),
            redemption_statement_create_form: CaseChargeRedemptionCreateForm,
        }),
    );

export type TCaseChargeFormCodec = typeof CaseChargeForm;
export type TCaseChargeForm = TTypeOfCodec<TCaseChargeFormCodec>;

export const CreateCaseChargeForm: TFormCodec<
    TCaseTitleRestriction2Codec,
    TEmptyObjectCodec
> = 
    form(
        CaseTitleRestriction2,
        EmptyObject,
    );

export type TCreateCaseChargeFormCodec = typeof CreateCaseChargeForm;
export type TCreateCaseChargeForm = TTypeOfCodec<TCreateCaseChargeFormCodec>;

export const CaseTitleRestrictionForm: TFormCodec<
    TCaseTitleRestriction1Codec,
    TRequiredCodec<{
        case_property: TUnionCodec<[TNullCodec, TCasePropertyIdAddressAndTitleNumberCodec]>,
    }>
> = 
    form(
        CaseTitleRestriction1,
        required({
            case_property: union([nullCodec(), CasePropertyIdAddressAndTitleNumber]),
        }),
    );

export type TCaseTitleRestrictionFormCodec = typeof CaseTitleRestrictionForm;
export type TCaseTitleRestrictionForm = TTypeOfCodec<TCaseTitleRestrictionFormCodec>;

export const CreateCaseTitleRestrictionForm: TFormCodec<
    TCaseTitleRestriction2Codec,
    TEmptyObjectCodec
> = 
    form(
        CaseTitleRestriction2,
        EmptyObject,
    );

export type TCreateCaseTitleRestrictionFormCodec = typeof CreateCaseTitleRestrictionForm;
export type TCreateCaseTitleRestrictionForm = TTypeOfCodec<TCreateCaseTitleRestrictionFormCodec>;

export const CaseChargesAndTitleRestrictionsCaseForm: TFormCodec<
    TRequiredCodec<{
        id: TUuidCodec,
        add_charges_and_title_restrictions_job_done_at: TUnionCodec<[TNullCodec, TAnyDateTimeCodec]>,
        add_charges_and_title_restrictions_job_defer: TUnionCodec<[TNullCodec, TAnyDateTimeCodec]>,
    }>,
    TEmptyObjectCodec
> = form(
    required({
        id: uuid(),
        add_charges_and_title_restrictions_job_done_at: union([nullCodec(), dateTime()]),
        add_charges_and_title_restrictions_job_defer: union([nullCodec(), dateTime()]),
    }),
    EmptyObject,
);
export type TCaseChargesAndTitleRestrictionsCaseFormCodec = typeof CaseChargesAndTitleRestrictionsCaseForm;
export type TCaseChargesAndTitleRestrictionsCaseForm = TTypeOfCodec<TCaseChargesAndTitleRestrictionsCaseFormCodec>;

export const CaseChargesAndRestrictionsForm: TFormCodec<
    TUuidObjectCodec,
    TRequiredCodec<{
        create_title_restriction: TCreateCaseTitleRestrictionFormCodec,
        restrictions: TArrayCodec<TCaseTitleRestrictionFormCodec>,
        create_charge: TCreateCaseChargeFormCodec,
        charges: TArrayCodec<TCaseChargeFormCodec>,
        properties_available_for_association: TArrayCodec<TCasePropertyIdAddressAndTitleNumberCodec>,
        expected_completion_date: TUnionCodec<[TNullCodec, TAnyDateTimeCodec]>,
        case_form: TCaseChargesAndTitleRestrictionsCaseFormCodec
    }>
> = form(
    UuidObject,
    required({
        create_title_restriction: CreateCaseTitleRestrictionForm,
        restrictions: array(CaseTitleRestrictionForm),
        create_charge: CreateCaseChargeForm,
        charges: array(CaseChargeForm),
        properties_available_for_association: array(CasePropertyIdAddressAndTitleNumber),
        expected_completion_date: union([nullCodec(), dateTime()]),
        case_form: CaseChargesAndTitleRestrictionsCaseForm,
    }),
);
export type TCaseChargesAndRestrictionsFormCodec = typeof CaseChargesAndRestrictionsForm;
export type TCaseChargesAndRestrictionsForm = TTypeOfCodec<TCaseChargesAndRestrictionsFormCodec>;
