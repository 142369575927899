import * as t from "io-ts";
import * as regexes from "../../shared/src/regexes";
import { boolean } from "../../shared/src/validation/basic/boolean";
import { nonEmptyString } from "../../shared/src/validation/basic/nonEmptyString";
import { inOrder } from "../../shared/src/validation/compose/inOrder";
import { object } from "../../shared/src/validation/create/object";
import { regex } from "../../shared/src/validation/create/regex";
import { TValidationFunction } from "../../shared/src/validation/Function";

export const codec = t.partial({
    phone_number: t.string,
    primary_number: t.boolean,
});

export type T = t.TypeOf<typeof codec>;

export const validator: TValidationFunction = object({
    phone_number: inOrder(
        nonEmptyString,
        regex(regexes.constants.MOBILE_PHONE_REGEX)
    ),
    primary_number: boolean,
});

export const newDefault = (): T => ({
    phone_number: "",
    primary_number: true,
});
