import React from "react";
import { CRMIcon } from "../CRMIcon/CRMIcon";
import CRMInputButtonIconWrap from "../CRMInputButtonIconWrap/CRMInputButtonIconWrap";
import { IOption, useDropdown } from "./../../hooks/UseDropdown";
import { ICRMButtonIcon } from "../CRMButtonIcon/CRMButtonIcon";
import { WeightBold } from "../WeightBold/WeightBold";
import { CRMDropdownOption } from "../Simple/CRMDropdownOption/CRMDropdownOption";
import { CRMDropdownBase } from "../CRMDropdownBase/CRMDropdownBase";
import { CRMDropdownOptionBox } from "../Simple/CRMDropdownOptionBox/CRMDropdownOptionBox";
import { CRMDropdownFace } from "../Simple/CRMDropdownFace/CRMDropdownFace";
import { TextColor } from "../BuildingBlocks/TextColor";
import { CRMColors } from "../../models/CRMColors";

interface ICRMDropdownComponent<A extends string, B extends A> {
    options: Array<IOption<A>>;
    valuePrefix?: string;
    value: B;
    displayError: boolean;
    name?: string;
    disabled?: boolean;
    optionOpensForm?: boolean;
    placeholder?: string;
    formOptionIsSelected?: boolean;
    onChange: (value: A) => void;
}

export const CRMDropdownComponent = <A extends string, B extends A>(props: ICRMDropdownComponent<A, B>): JSX.Element => {

    const getButtonIcons = (): Array<ICRMButtonIcon> => {
        if (props.optionOpensForm) {
            return [
                {
                    variant: "tertiary",
                    icon: "form-icon",
                    onClick: () => null,
                },
            ];
        }
        return [];
    }

    return (
        <>
            <CRMInputButtonIconWrap
                disabled={props.disabled}
                buttonIcons={getButtonIcons()}
            >
                <CRMDropdownBase
                    FaceComponent={(faceProps) => (
                        <CRMDropdownFace
                            isOpen={faceProps.isOpen}
                            toggleOpen={faceProps.toggleOpen}
                            disabled={faceProps.disabled}
                            displayError={faceProps.displayError}
                        >
                            {faceProps.value.length === 0 &&
                                <TextColor color={CRMColors.NEUTRAL_2}>
                                    {faceProps.placeholder || "Select Value"}
                                </TextColor>
                            }

                            {faceProps.value.length > 0 &&
                                <div>
                                    <WeightBold>{faceProps.valuePrefix}</WeightBold> {faceProps.faceText}
                                </div>
                            }
                        </CRMDropdownFace>
                    )}
                    OptionsComponent={(optionProps) => (
                        <CRMDropdownOptionBox>
                            {optionProps
                                .options
                                // Hide dropdown items if isHidden is specified and is true
                                .filter((o) => typeof o.isHidden === "undefined" || o.isHidden === false)
                                .map((selectOption, key) => (
                                <CRMDropdownOption
                                    key={key}
                                    isSelected={optionProps.isOptionSelected(selectOption.value)}
                                    label={selectOption.label}
                                    onClick={() => {
                                        props.onChange(selectOption.value);
                                        optionProps.onCloseDropdown();
                                    }}
                                />
                            ))}
                        </CRMDropdownOptionBox>
                    )}
                    {...props}
                />
            </CRMInputButtonIconWrap>

            {props.formOptionIsSelected &&
                <div className="crm-dropdown__form-border-wrap">
                    <div className="crm-dropdown__form-border-arrow">
                        <CRMIcon
                            iconName="full-arrow-up"
                            colour="neutral-ink"
                        />
                    </div>
                </div>
            }
        </>
    );
}
