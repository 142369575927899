import { overload } from "../../../shared/src/codecs/types/overload";
import { string } from "../../../shared/src/codecs/types/string";
import { dateTime } from "../../../shared/src/codecs/types/dateTime";
import { DateTime } from "luxon";

export const DateTimeToSimpleUiDate = overload(
    string(),
    dateTime(),
    (iso) => DateTime.fromISO(iso).setZone("Europe/London").toFormat("d LLLL yyyy"),
);
