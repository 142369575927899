import { TTypeOfCodec } from "../../../shared/src/codecs/codec";
import { array, TArrayCodec } from "../../../shared/src/codecs/types/array";
import { formIO, TFormIOCodec } from "../../../shared/src/codecs/types/formIO";
import { required, TRequiredCodec } from "../../../shared/src/codecs/types/required";
import { Cases1, TCases1Codec } from "../Cases";
import { TEmptyObjectCodec } from "../EmptyObject";

export const TitleChecksPageFormIO: TFormIOCodec<TEmptyObjectCodec, TRequiredCodec<{
    title_checks: TArrayCodec<TCases1Codec>;
}>> = formIO(
    required({}),
    required({
        title_checks: array(Cases1),
    }),
);

export type TTitleChecksPageFormIOCodec = typeof TitleChecksPageFormIO;

export type TTitleChecksPageFormIO = TTypeOfCodec<TTitleChecksPageFormIOCodec>;
