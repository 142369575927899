import React from "react";

export default (props: React.SVGAttributes<SVGElement>) => <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g clipPath="url(#clip0_4062:18040)">
<path d="M12.1 38.5C11.8185 38.5 11.537 38.3926 11.3222 38.1778L0.322162 27.1778C-0.107387 26.7482 -0.107387 26.0517 0.322162 25.6222C0.751712 25.1926 1.44822 25.1926 1.87784 25.6222L12.1 35.8443L42.1222 5.82216C42.5518 5.39261 43.2483 5.39261 43.6778 5.82216C44.1074 6.25171 44.1074 6.94822 43.6778 7.37784L12.8778 38.1778C12.663 38.3926 12.3815 38.5 12.1 38.5Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_4062:18040">
<rect width="44" height="44" fill="white"/>
</clipPath>
</defs>
</svg>
;
