import * as t from "io-ts";
import * as JsonResponse1 from "./JsonResponse1";
import * as UserPhoneNumber5 from "./UserPhoneNumber5";

export const codec = JsonResponse1.createCodec(
    UserPhoneNumber5.codec,
    t.type({}),
);

export type T = t.TypeOf<typeof codec>;

export const newDefault = (): T => ({
    data: UserPhoneNumber5.newDefault(),
    meta: {},
});
