import * as t from "io-ts";
import * as util from "../../shared/src/util";

type TDisplayName = "Unknown"
    | "Property to sell - not on the market yet"
    | "Property to sell - on the market"
    | "Property to sell - under offer"
    | "Property to sell - already exchanged"
    | "No property to sell"
    | "Property to let";

export const codec = t.union([
    t.literal("unknown"),
    t.literal("property_to_sell_not_on_the_market"),
    t.literal("property_to_sell_on_the_market"),
    t.literal("property_to_sell_under_offer"),
    t.literal("property_to_sell_already_exchanged"),
    t.literal("no_property_to_sell"),
    t.literal("property_to_let"),
]);

export const values = util.typeValuesFromCodec(codec.types as Array<t.LiteralC<T>>);

export type T = t.TypeOf<typeof codec>;

export const fromValueToDisplayName = (value: T): TDisplayName => {
    switch (value) {
        case "unknown":
            return "Unknown";
        case "property_to_sell_not_on_the_market":
            return "Property to sell - not on the market yet";
        case "property_to_sell_on_the_market":
            return "Property to sell - on the market";
        case "property_to_sell_under_offer":
            return "Property to sell - under offer";
        case "property_to_sell_already_exchanged":
            return "Property to sell - already exchanged";
        case "no_property_to_sell":
            return "No property to sell";
        case "property_to_let":
            return "Property to let";
        default:
            return util.requireExhaustive(value);
    }
};
