import { required } from "../../shared/src/codecs/types/required";
import { uuid } from "../../shared/src/codecs/types/uuid";
import { string } from "../../shared/src/codecs/types/string";
import { dateTime } from "../../shared/src/codecs/types/dateTime";
import { TTypeOfCodec } from "../../shared/src/codecs/codec";
import { union } from "../../shared/src/codecs/types/union";
import { nullCodec } from "../../shared/src/codecs/types/nullCodec";
import { boolean } from "../../shared/src/codecs/types/boolean";
import { positiveInteger } from "../../shared/src/codecs/types/positiveInteger";
import { TitleEntrySection } from "./TitleEntrySection";
import { literal } from "../../shared/src/codecs/types/literal";

export const CaseChargeType = union([
    literal("unknown"),
    literal("charge"),
    literal("discount_charge"),
    literal("noted_charge"),
    literal("equitable_charge"),
]);

export const CaseCharge1 = required({
    id: uuid(),
    type: CaseChargeType,
    cases_id: uuid(),
    beneficiary: string(),
    account_number: string(),
    has_early_repayment_charge: union([boolean(), nullCodec()]),
    client_notified_of_early_repayment_charge: boolean(),
    get_redemption_statement_defer: union([dateTime(), nullCodec()]),
    cases_properties_id: union([nullCodec(), uuid()]),
    section: TitleEntrySection,
    charge_order: union([nullCodec(), positiveInteger()]),
    charge_text: string(),
});
export type TCaseCharge1Codec = typeof CaseCharge1;
export type TCaseCharge1 = TTypeOfCodec<TCaseCharge1Codec>;

export const CaseCharge2 = required({
    cases_id: uuid(),
});
export type TCaseCharge2Codec = typeof CaseCharge2;
export type TCaseCharge2 = TTypeOfCodec<TCaseCharge2Codec>;

export const ClientCaseCharge = required({
    id: uuid(),
    account_number: string(),
});
export type TClientCaseChargeCodec = typeof ClientCaseCharge;
export type TClientCaseCharge = TTypeOfCodec<TClientCaseChargeCodec>;

export const ClientCaseChargeBeneficiary = required({
    beneficiary: string(),
});
export type TClientCaseChargeBeneficiaryCodec = typeof ClientCaseChargeBeneficiary;
export type TClientCaseChargeBeneficiary = TTypeOfCodec<TClientCaseChargeBeneficiaryCodec>;
