import { overload } from "../../../shared/src/codecs/types/overload";
import { required } from "../../../shared/src/codecs/types/required";
import { union } from "../../../shared/src/codecs/types/union";
import { literal } from "../../../shared/src/codecs/types/literal";
import { dateTime } from "../../../shared/src/codecs/types/dateTime";
import { nullCodec } from "../../../shared/src/codecs/types/nullCodec";
import { boolean } from "../../../shared/src/codecs/types/boolean";

export const CaseSdltAnswersSubmissionStatus = overload(
    union([
        literal("client_not_submitted"),
        literal("client_submitted"),
        literal("needs_resubmission_due_to_client_change"),
    ]),
    required({
        sdlt_answered_at: union([dateTime(), nullCodec()]),
        sdlt_all_clients_have_agreed: boolean(),
    }),
    ({sdlt_answered_at, sdlt_all_clients_have_agreed}) =>
        sdlt_answered_at && sdlt_all_clients_have_agreed ? "client_submitted"
        : sdlt_answered_at && !sdlt_all_clients_have_agreed ? "needs_resubmission_due_to_client_change"
        : "client_not_submitted",
);
export type TCaseSdltAnswersSubmissionStatusCodec = typeof CaseSdltAnswersSubmissionStatus;
