import React from "react";
import { CRMCardOutside } from "../../components/CRMCardOutside/CRMCardOutside";
import { CRMColumns } from "../../components/CRMColumns/CRMColumns";
import { CRMPadding } from "../../components/Simple/CRMPadding/CRMPadding";
import { CRMPageBackground } from "../../components/Simple/CRMPageBackground/CRMPageBackground";
import { CRMSpacer } from "../../components/CRMSpacer/CRMSpacer";
import { CRMTitleSection } from "../../components/CRMTitleSection/CRMTitleSection";
import { CRMUniversalBulletPoints } from "../../components/CRMUniversalBulletPoints/CRMUniversalBulletPoints";
import { CRMParagraphInline } from "../../components/Simple/CRMParagraphInline/CRMParagraphInline";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import TagManager from "react-gtm-module";

class ProbatePanelTnCsPage extends React.Component<React.PropsWithChildren<TContainerStateProps>> {
    public componentDidMount() {
        TagManager.initialize({
            gtmId: "GTM-5J4P8R5",
        });
    }
    
    public render(): JSX.Element {
        return (
            <div className="probate-panel-enquiry-page">
                <CRMPageBackground>
                    <CRMPadding size="large">
                        <CRMColumns
                            padding="large"
                            columns={[
                                {
                                    flex: 0.5,
                                    content: (<div></div>),
                                },
                                {
                                    flex: 1.1887,
                                    content: (
                                        <CRMCardOutside borderRounding="right" shadow={true}>
                                            <CRMPadding size="medium">
                                                <img style={{display: "block", height: "100px"}} src="/static/img/SAIL_PROBATE_LOGO_2021.png" />
                                                <CRMSpacer size="medium" />
                                                <CRMTitleSection>Terms and Conditions 2021-06-25</CRMTitleSection>
                                                <CRMSpacer size="medium" />
                                                <CRMUniversalBulletPoints
                                                    points={[
                                                        <CRMParagraphInline key="1">We may contact you as part of our service.</CRMParagraphInline>,
                                                        <CRMParagraphInline key="2">We may share some of your details with solictors as part of our service.</CRMParagraphInline>,
                                                        <CRMParagraphInline key="3">We retain and use all information strictly under the GDPR/Privacy Policy.</CRMParagraphInline>,
                                                        <CRMParagraphInline key="3">The price displayed here is a quote and while we endevour to have an accurate quote the price may change, this may include but is not limited to, discoving changes in the details you provided in this form.</CRMParagraphInline>,
                                                        <CRMParagraphInline key="3">Your legal advisor will send you a separate definitive Terms and Conditions of Business upon instruction.</CRMParagraphInline>,
                                                        <CRMParagraphInline key="3">We select the legal advisor best placed to act on your behalf at the time you ask us to instruct them. If your instructions change a different member of our panel may need to be selected. When you instruct your legal advisor in writing you will have the reassurance of becoming their client and knowing that they will take direct responsibility for the legal work they conduct for you. You will be able to contact your legal advisor direct during the transaction, but our specialist staff will be available to you and your legal advisor to help where we can during the process.</CRMParagraphInline>,
                                                        <CRMParagraphInline key="2">We can make changes to the Services which are necessary to comply with any applicable law or safety requirement. We will notify you of these changes.</CRMParagraphInline>,
                                                        <CRMParagraphInline key="3">Our service and any dispute arising out of such use of the website is subject to the laws of England, Northern Ireland, Scotland and Wales.</CRMParagraphInline>,
                                                    ]}
                                                />
                                            </CRMPadding>
                                        </CRMCardOutside>
                                    ),
                                },
                                {
                                    flex: 0.5,
                                    content: (<div></div>),
                                },
                            ]}
                        />
                    </CRMPadding>
                </CRMPageBackground>
            </div>
        );
    }
}

export default ProbatePanelTnCsPage;
