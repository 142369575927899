import { requireExhaustive } from "../../../shared/src/util";

type TCRMFontSizes =
    "x-large"
    | "large"
    | "medium"
    | "small-plus"
    | "small"
    | "x-small"
;

export const CRMFontSizes = {
    XX_LARGE: 22,
    X_LARGE: 20,
    LARGE: 18,
    MED_PLUS: 17,
    MED: 16,
    SMALL_PLUS: 15,
    SMALL: 14,
    X_SMALL: 12,
};

export const CRMFontSizeEnumToNumber = (fontSize: TCRMFontSizes): number => {
    switch (fontSize) {
        case "x-large":
            return CRMFontSizes.X_LARGE;
        case "large":
            return CRMFontSizes.LARGE;
        case "medium":
            return CRMFontSizes.MED;
        case "small-plus":
            return CRMFontSizes.SMALL_PLUS;
        case "small":
            return CRMFontSizes.SMALL;
        case "x-small":
            return CRMFontSizes.X_SMALL;
        default:
            return requireExhaustive(fontSize);
    }
};
