import { TTypeOfCodec } from "../../../shared/src/codecs/codec";
import { dateTime } from "../../../shared/src/codecs/types/dateTime";
import { form, TFormCodec } from "../../../shared/src/codecs/types/form";
import { longString } from "../../../shared/src/codecs/types/longString";
import { nullCodec } from "../../../shared/src/codecs/types/nullCodec";
import { required } from "../../../shared/src/codecs/types/required";
import { string } from "../../../shared/src/codecs/types/string";
import { union } from "../../../shared/src/codecs/types/union";
import { uuid } from "../../../shared/src/codecs/types/uuid";
import { CaseConflictOfInterestStatus, CoiSourceTarget } from "../CaseConflictsOfInterest";

export const CaseConflictOfInterestFormEditablePart = required({
    status: CaseConflictOfInterestStatus,
    notes: longString(),
});
export type TCaseConflictOfInterestFormEditablePartCodec = typeof CaseConflictOfInterestFormEditablePart;
export type TCaseConflictOfInterestFormEditablePart = TTypeOfCodec<TCaseConflictOfInterestFormEditablePartCodec>;

export const CaseConflictOfInterestFormReadOnlyPart = required({
    id: uuid(),
    case_id: uuid(),
    property_id: union([uuid(), nullCodec()]),
    user_id: union([uuid(), nullCodec()]),
    flagged_against_case_id: uuid(),
    flagged_against_property_id: union([uuid(), nullCodec()]),
    flagged_against_user_id: union([uuid(), nullCodec()]),
    source: CoiSourceTarget,
    target: CoiSourceTarget,
    data_at_time_of_check: string(),
    created_at: dateTime(),
    updated_at: union([dateTime(), nullCodec()]),
    status_updated_by: union([uuid(), nullCodec()]),
});
export type TCaseConflictOfInterestFormReadOnlyPartCodec = typeof CaseConflictOfInterestFormReadOnlyPart;

export const CaseConflictOfInterestForm: TFormCodec<TCaseConflictOfInterestFormEditablePartCodec, TCaseConflictOfInterestFormReadOnlyPartCodec> = form(
    CaseConflictOfInterestFormEditablePart,
    CaseConflictOfInterestFormReadOnlyPart,
);
export type TCaseConflictOfInterestFormCodec = typeof CaseConflictOfInterestForm;
export type TCaseConflictOfInterestForm = TTypeOfCodec<TCaseConflictOfInterestFormCodec>;