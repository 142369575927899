import { array } from "../../shared/src/codecs/types/array";
import { dateTime } from "../../shared/src/codecs/types/dateTime";
import { intersection } from "../../shared/src/codecs/types/intersection";
import { nullCodec } from "../../shared/src/codecs/types/nullCodec";
import { required } from "../../shared/src/codecs/types/required";
import { union } from "../../shared/src/codecs/types/union";
import { uuid } from "../../shared/src/codecs/types/uuid";
import { UserVideoVerificationCall4 } from "./UserVideoVerificationCall";

export const VideoVerificationCall3 = required({
    scheduled_time: union([dateTime(), nullCodec()]),
});

export const VideoVerificationCall1 = intersection([
    required({
        id: uuid(),
    }),
    VideoVerificationCall3,
]);
export type TVideoVerificationCall1Codec = typeof VideoVerificationCall1;

export const VideoVerificationCall2 = required({
    created_for_case_id: uuid(),
});
export type TVideoVerificationCall2Codec = typeof VideoVerificationCall2;

export const VideoVerificationCall4 = intersection([
    required({
        case_id: uuid(),
        scheduled_time: union([dateTime(), nullCodec()]),
    }),
    UserVideoVerificationCall4,
]);
export type TVideoVerificationCall4Codec = typeof VideoVerificationCall4;

export const VideoVerificationCall5 = required({
    case_id: uuid(),
    available_times: array(dateTime()),
});
export type TVideoVerificationCall5Codec = typeof VideoVerificationCall5;
