import { TCodec } from "../codec";
import { either } from "fp-ts/lib";
import { TError, errorConstants } from "../errors";

export type TAnyBooleanFromStringCodec = TCodec<
    "BooleanFromStringCodec",
    undefined,
    boolean,
    boolean
>;

const decode = (input: unknown): either.Either<TError, boolean> =>
    typeof input === "string" && input.toLowerCase() === "true" ? either.right(true)
        : typeof input === "string" && input.toLowerCase() === "false" ? either.right(false)
        : either.left([[errorConstants.BOOLEAN_VALIDATION, ""]]);

export const booleanFromString = (): TAnyBooleanFromStringCodec => ({
    type: "BooleanFromStringCodec",
    payload: undefined,
    decode,
    decodeNewDefault: decode,
    newDefault: () => false,
});
