import { TTypeOfCodec } from "../../../shared/src/codecs/codec";
import { date, TDateCodec } from "../../../shared/src/codecs/types/date";
import { fileIO, TFileIOCodec } from "../../../shared/src/codecs/types/fileIO";
import { form, TFormCodec } from "../../../shared/src/codecs/types/form";
import { required, TRequiredCodec } from "../../../shared/src/codecs/types/required";
import { string, TAnyStringCodec } from "../../../shared/src/codecs/types/string";
import { TUuidCodec, uuid } from "../../../shared/src/codecs/types/uuid";
import { CaseChargeLegalFile1, TCaseChargeLegalFile1Codec } from "../CaseChargeLegalFile";
import { EmptyObject, TEmptyObjectCodec } from "../EmptyObject";

export const CaseChargeRedemptionUploadForm: TFileIOCodec<TRequiredCodec<{
    id: TUuidCodec,
    cases_charges_id: TUuidCodec,
    file_extension: TAnyStringCodec,
    mime_type: TAnyStringCodec,
}>, TEmptyObjectCodec> = fileIO(
    required({
        id: uuid(),
        cases_charges_id: uuid(),
        file_extension: string(),
        mime_type: string(),
    }),
    EmptyObject,
);
export type TCaseChargeRedemptionUploadFormCodec = typeof CaseChargeRedemptionUploadForm;
export type TCaseChargeRedemptionUploadForm = TTypeOfCodec<TCaseChargeRedemptionUploadFormCodec>;

export const CaseChargeRedemptionCreateForm: TFormCodec<TRequiredCodec<{
    cases_charges_id: TUuidCodec,
    expires_at: TDateCodec,
}>, TEmptyObjectCodec> = form(
    required({
        cases_charges_id: uuid(),
        expires_at: date(),
    }),
    EmptyObject,
);
export type TCaseChargeRedemptionCreateFormCodec = typeof CaseChargeRedemptionCreateForm;
export type TCaseChargeRedemptionCreateForm = TTypeOfCodec<TCaseChargeRedemptionCreateFormCodec>;

export const CaseChargeRedemptionForm: TFormCodec<TCaseChargeLegalFile1Codec, TRequiredCodec<{
    upload_form: TCaseChargeRedemptionUploadFormCodec,
}>> = form(
    CaseChargeLegalFile1,
    required({
        upload_form: CaseChargeRedemptionUploadForm,
    }),
);
export type TCaseChargeRedemptionFormCodec = typeof CaseChargeRedemptionForm;
export type TCaseChargeRedemptionForm = TTypeOfCodec<TCaseChargeRedemptionFormCodec>;
