
import { form, TFormCodec } from "../../../shared/src/codecs/types/form";
import { TTypeOfCodec, TTypeOfNewDefault } from "../../../shared/src/codecs/codec";
import { UuidObject, TUuidObjectCodec } from "../Id";
import { EmptyObject, TEmptyObjectCodec } from "../EmptyObject";

export const CaseLedgerMarkLedgerAsFinalForm: TFormCodec<TUuidObjectCodec, TEmptyObjectCodec> = 
    form(
        UuidObject,
        EmptyObject,
    );

export type TCaseLedgerMarkLedgerAsFinalFormCodec = typeof CaseLedgerMarkLedgerAsFinalForm;
export type TCaseLedgerMarkLedgerAsFinalForm = TTypeOfCodec<TCaseLedgerMarkLedgerAsFinalFormCodec>;
export type TCaseLedgerMarkLedgerAsFinalFormNewDefault = TTypeOfNewDefault<typeof CaseLedgerMarkLedgerAsFinalForm>; 

export const CaseLedgerRollbackForm: TFormCodec<TUuidObjectCodec, TEmptyObjectCodec> = 
    form(
        UuidObject,
        EmptyObject,
    );

export type TCaseLedgerRollbackFormCodec = typeof CaseLedgerRollbackForm;
export type TCaseLedgerRollbackForm = TTypeOfCodec<TCaseLedgerRollbackFormCodec>;
