import { TTypeOfCodec } from "../../../shared/src/codecs/codec";
import { fileIO, TFileIOCodec } from "../../../shared/src/codecs/types/fileIO";
import { ListingImage2, ListingImage3, ListingImage3Codec, TListingImage2Codec } from "../ListingImage";
import { intersection, TIntersectionCodec } from "../../../shared/src/codecs/types/intersection";
import { partial, TPartialCodec } from "../../../shared/src/codecs/types/partial";
import { string, TAnyStringCodec } from "../../../shared/src/codecs/types/string";

export const ListingPhotoFileIO: TFileIOCodec<
    TIntersectionCodec<[
        ListingImage3Codec,
        TPartialCodec<{
            request_ref: TAnyStringCodec;
        }>
    ]>,
    TListingImage2Codec
>  = fileIO(
    intersection([
        ListingImage3,
        partial({
            request_ref: string(),
        }),
    ]),
    ListingImage2,
);

export type TListingPhotoFileIOCodec = typeof ListingPhotoFileIO;

export type TListingPhotoFileIO = TTypeOfCodec<TListingPhotoFileIOCodec>;