import { TTypeOfCodec } from "../../../shared/src/codecs/codec";
import { array, TArrayCodec } from "../../../shared/src/codecs/types/array";
import { dateTime } from "../../../shared/src/codecs/types/dateTime";
import { boolean, TBooleanCodec } from "../../../shared/src/codecs/types/boolean";
import { form, TFormCodec } from "../../../shared/src/codecs/types/form";
import { intersection, TIntersectionCodec } from "../../../shared/src/codecs/types/intersection";
import { literal, TLiteralCodec } from "../../../shared/src/codecs/types/literal";
import { nullCodec, TNullCodec } from "../../../shared/src/codecs/types/nullCodec";
import { required, TRequiredCodec } from "../../../shared/src/codecs/types/required";
import { requiredFlatOverloaded, TRequiredFlatOverloadedCodec } from "../../../shared/src/codecs/types/requiredFlatOverloaded";
import { string, TAnyStringCodec } from "../../../shared/src/codecs/types/string";
import { TUnionCodec, union } from "../../../shared/src/codecs/types/union";
import { TUuidCodec, uuid } from "../../../shared/src/codecs/types/uuid";
import { Address2, Address5, TAddress2Codec, TAddress5Codec } from "../Address";
import { CaseId, TCaseIdCodec } from "../CaseId";
import { CaseEditableFull, Cases14, TCaseEditableFullCodec, TCases14Codec, CaseRootReadOnlyProps, TCaseRootReadOnlyPropsCodec, TCaseEnquiryTimestampsCodec,
    CaseEnquiryTimestamps, 
    CasesSdlt,
    TCasesSdltCodec,
    TCaseStatusCodec,
    CaseStatus,
} from "../Cases";
import { CasesBankAccount3, TCasesBankAccount3Codec, CasesBankAccount4, TCasesBankAccount4Codec } from "../CasesBankAccount";
import { TCasesRelatedTransaction2Codec, CasesRelatedTransaction2 } from "../CasesRelatedTransaction";
import { CasesRiskAssessment1, CasesRiskAssessment2, TCasesRiskAssessment1Codec, TCasesRiskAssessment2Codec } from "../CasesRiskAssessment";
import { EmptyObject, TEmptyObjectCodec } from "../EmptyObject";
import { CaseClientNames, TCaseClientNames } from "../overloads/CaseClientNames";
import { CaseSdltAnswersSubmissionStatus, TCaseSdltAnswersSubmissionStatusCodec } from "../overloads/CaseSdltAnswersSubmissionStatus";
import { TUserNameCodec, TUserNamesCodec, UserName, UserNames } from "../overloads/UserName";
import { TUsersVideoVerificationCallHasExpiredCodec, UsersVideoVerificationCallHasExpired } from "../overloads/UsersVideoVerificationCallHasExpired";
import { UserBookableForVideoCall, TUserBookableForVideoCallCodec, TUserBankruptcySearchCodec, UserBankruptcySearch } from "../User";
import { TUserVideoVerificationCall1Codec, TUserVideoVerificationCall2Codec, UserVideoVerificationCall1, UserVideoVerificationCall2 } from "../UserVideoVerificationCall";
import { TVideoVerificationCall1Codec, TVideoVerificationCall2Codec, VideoVerificationCall1, VideoVerificationCall2 } from "../VideoVerificationCall";
import { CaseMemberForm, TCaseMemberFormCodec } from "./CaseMemberForm";
import { longString } from "../../../shared/src/codecs/types/longString";
import { TBankruptcySearchWithUsersCodec, BankruptcySearchWithUsers} from "../BankruptcySearch";
import { Introducer1, TIntroducer1Codec } from "../Introducer";
import { TUuidObjectCodec, UuidObject } from "../Id";
import { ReadyForExchangeChecklist, TReadyForExchangeChecklistCodec } from "../ReadyForExchangeChecklist";
import { CaseQuote, CaseCreateQuoteFilled, CaseCreateQuoteUnfilled, TCaseQuoteCodec, TCaseCreateQuoteFilledCodec, TCaseCreateQuoteUnfilledCodec, TCaseQuoteSentToClientCodec, CaseQuoteSentToClient } from "../Quote";
import { positiveInteger } from "../../../shared/src/codecs/types/positiveInteger";

export const CaseBankAccountForm: TFormCodec<TCasesBankAccount3Codec, TCasesBankAccount4Codec> = form(
    CasesBankAccount3,
    CasesBankAccount4,
);
export type TCaseBankAccountFormCodec = typeof CaseBankAccountForm;
export type TCaseBankAccountForm = TTypeOfCodec<TCaseBankAccountFormCodec>;

export const CaseSignOffAsReadyForExchangeForm: TFormCodec<TCases14Codec, TEmptyObjectCodec> = form(
    Cases14,
    EmptyObject,
);
export type TCaseSignOffAsReadyForExchangeFormCodec = typeof CaseSignOffAsReadyForExchangeForm;
export type TCaseSignOffAsReadyForExchangeForm = TTypeOfCodec<TCaseSignOffAsReadyForExchangeFormCodec>;

export const CaseInstructForm: TFormCodec<TCases14Codec, TEmptyObjectCodec> = form(
    Cases14,
    EmptyObject,
);
export type TCaseInstructFormCodec = typeof CaseInstructForm;
export type TCaseInstructForm = TTypeOfCodec<TCaseInstructFormCodec>;

export const CaseRelatedTransactionCreateSearchResultsForm: TFormCodec<TRequiredCodec<{from_case_id: TUuidCodec, to_case_id: TUuidCodec}>, TIntersectionCodec<[
    TRequiredFlatOverloadedCodec<{addresses: TAddress5Codec}>,
    TRequiredFlatOverloadedCodec<{client_names: TCaseClientNames}>,
    TRequiredCodec<{status: TCaseStatusCodec}>,
]>> = form(
    required({
        from_case_id: uuid(),
        to_case_id: uuid(),
    }),
    intersection([
        requiredFlatOverloaded({
            addresses: Address5,
        }),
        requiredFlatOverloaded({
            client_names: CaseClientNames,
        }),
        required({
            status: CaseStatus,
        })
    ])
);
export type TCaseRelatedTransactionCreateSearchResultsFormCodec = typeof CaseRelatedTransactionCreateSearchResultsForm;
export type TCaseRelatedTransactionCreateSearchResultsForm = TTypeOfCodec<TCaseRelatedTransactionCreateSearchResultsFormCodec>;

export const CaseDualRepSearchResultsForm: TFormCodec<TRequiredCodec<{from_case_id: TUuidCodec, to_case_id: TUuidCodec}>, TIntersectionCodec<[
    TRequiredFlatOverloadedCodec<{addresses: TAddress5Codec}>,
    TRequiredCodec<{
        klyant_matter_id: TAnyStringCodec,
        status: TCaseStatusCodec,
    }>,
]>> = form(
    required({
        from_case_id: uuid(),
        to_case_id: uuid(),
    }),
    intersection([
        requiredFlatOverloaded({
            addresses: Address5,
        }),
        required({
            klyant_matter_id: string(),
            status: CaseStatus,
        })
    ])
);
export type TCaseDualRepSearchResultsFormCodec = typeof CaseDualRepSearchResultsForm;
export type TCaseDualRepSearchResultsForm = TTypeOfCodec<TCaseDualRepSearchResultsFormCodec>;

export const CaseRelatedTransactionCreateForm: TFormCodec<TRequiredCodec<{case_id: TUuidCodec, search_term: TAnyStringCodec}>, TArrayCodec<TCaseRelatedTransactionCreateSearchResultsFormCodec>> = form(
    required({
        case_id: uuid(),
        search_term: string(),
    }),
    array(CaseRelatedTransactionCreateSearchResultsForm),
);
export type TCaseRelatedTransactionCreateFormCodec = typeof CaseRelatedTransactionCreateForm;
export type TCaseRelatedTransactionCreateForm = TTypeOfCodec<TCaseRelatedTransactionCreateFormCodec>;

export const CaseDualRepCreateForm: TFormCodec<
    TRequiredCodec<{
        case_id: TUuidCodec, search_term: TAnyStringCodec
    }>,
    TArrayCodec<TCaseDualRepSearchResultsFormCodec>
> = form(
    required({
        case_id: uuid(),
        search_term: string(),
    }),
    array(CaseDualRepSearchResultsForm),
);
export type TCaseDualRepCreateFormCodec = typeof CaseDualRepCreateForm;
export type TCaseDualRepCreateForm = TTypeOfCodec<TCaseDualRepCreateFormCodec>;

const CaseRelatedTransactionInternalOrExternal = required({
    internal_or_external: union([
        literal("internal"),
        literal("external"),
    ]),
});
type CaseRelatedTransactionInternalOrExternalCodec = typeof CaseRelatedTransactionInternalOrExternal;

export const CaseRelatedTransactionForm1: TFormCodec<TRequiredCodec<{id: TUuidCodec}>, TIntersectionCodec<[
    TRequiredFlatOverloadedCodec<{address: TAddress2Codec}>,
    TRequiredCodec<{related_case_id: TUnionCodec<[TUuidCodec, TNullCodec]>}>,
    CaseRelatedTransactionInternalOrExternalCodec,
]>> = form(
    required({
        id: uuid(),
    }),
    intersection([
        requiredFlatOverloaded({
            address: Address2,
        }),
        required({
            related_case_id: union([uuid(), nullCodec()]),
        }),
        CaseRelatedTransactionInternalOrExternal,
    ]),
);
export type TCaseRelatedTransactionForm1Codec = typeof CaseRelatedTransactionForm1;
export type TCaseRelatedTransactionForm1 = TTypeOfCodec<TCaseRelatedTransactionForm1Codec>;

export const CaseRelatedTransactionForm2: TFormCodec<TRequiredCodec<{id: TUuidCodec}>, TIntersectionCodec<[
    TRequiredFlatOverloadedCodec<{address: TAddress5Codec}>,
    TRequiredCodec<{related_case_id: TUnionCodec<[TUuidCodec, TNullCodec]>}>,
    CaseRelatedTransactionInternalOrExternalCodec,
]>> = form(
    required({
        id: uuid(),
    }),
    intersection([
        requiredFlatOverloaded({
            address: Address5,
        }),
        required({
            related_case_id: union([uuid(), nullCodec()]),
        }),
        CaseRelatedTransactionInternalOrExternal,
    ])
);
export type TCaseRelatedTransactionForm2Codec = typeof CaseRelatedTransactionForm2;
export type TCaseRelatedTransactionForm2 = TTypeOfCodec<TCaseRelatedTransactionForm2Codec>;

export const CaseRelatedTransactionDataForm: TFormCodec<TCasesRelatedTransaction2Codec, TRequiredCodec<{
    linked_to: TUnionCodec<[TCaseRelatedTransactionForm1Codec, TNullCodec]>
}>> = form(CasesRelatedTransaction2, required({
    linked_to: union([CaseRelatedTransactionForm1, nullCodec()]),
}));
export type TCaseRelatedTransactionDataFormCodec = typeof CaseRelatedTransactionDataForm;
export type TCaseRelatedTransactionDataForm = TTypeOfCodec<TCaseRelatedTransactionDataFormCodec>;

export const CaseDualRepOurSideConsent = intersection([
    requiredFlatOverloaded({
        name: UserName,
    }),
    required({
        has_consented: boolean()
    }),
]);
export type TCaseDualRepOurSideConsentCodec = typeof CaseDualRepOurSideConsent;
export type TCaseDualRepOurSideConsent = TTypeOfCodec<TCaseDualRepOurSideConsentCodec>;

export const CaseDualRepLinkedForm: TFormCodec<
    TUuidObjectCodec,
    TIntersectionCodec<[
        TRequiredFlatOverloadedCodec<{
            address: TAddress5Codec,
        }>,
        TRequiredCodec<{
            type: TUnionCodec<[TLiteralCodec<"original">, TLiteralCodec<"dual_rep">]>,
            klyant_matter_id: TAnyStringCodec,
            other_case_id: TUuidCodec,
            other_case_status: TCaseStatusCodec,
            at_least_one_our_side_clients_consent: TBooleanCodec,
            our_side_client_consents: TArrayCodec<TCaseDualRepOurSideConsentCodec>
            at_least_one_other_side_clients_consent: TBooleanCodec,
            our_case_can_be_worked_on: TBooleanCodec,
            other_case_purchasing_searches: TBooleanCodec,
            can_send_instruct_emails: TBooleanCodec,
        }>,
        TRequiredFlatOverloadedCodec<{
            other_case_handler_name: TUnionCodec<[
                TNullCodec,
                TUserNameCodec,
            ]>,
        }>,
        TRequiredFlatOverloadedCodec<{
            other_case_assistant_name: TUnionCodec<[
                TNullCodec,
                TUserNameCodec,
            ]>,
        }>,
    ]>
> = form(
    UuidObject,
    intersection([
        requiredFlatOverloaded({
            address: Address5,
        }),
        required({
            type: union([literal("original"), literal("dual_rep")]),
            klyant_matter_id: string(),
            other_case_id: uuid(),
            other_case_status: CaseStatus,
            at_least_one_our_side_clients_consent: boolean(),
            our_side_client_consents: array(CaseDualRepOurSideConsent),
            at_least_one_other_side_clients_consent: boolean(),
            our_case_can_be_worked_on: boolean(),
            other_case_purchasing_searches: boolean(),
            can_send_instruct_emails: boolean(),
        }),
        requiredFlatOverloaded({
            other_case_handler_name: union([nullCodec(), UserName]),
        }),
        requiredFlatOverloaded({
            other_case_assistant_name: union([nullCodec(), UserName]),
        }),
    ]),
);
export type TCaseDualRepLinkedFormCodec = typeof CaseDualRepLinkedForm;
export type TCaseDualRepLinkedForm = TTypeOfCodec<TCaseDualRepLinkedFormCodec>;

export const CaseBankruptcySearchesListForm: TFormCodec<
    TCaseIdCodec,
    TRequiredCodec<{
        bankruptcy_searches: TArrayCodec<TBankruptcySearchWithUsersCodec>
    }>
> = form(
    CaseId,
    required({
        bankruptcy_searches: array(BankruptcySearchWithUsers)
    })
);
export type TCaseBankruptcySearchesListFormCodec = typeof CaseBankruptcySearchesListForm;
export type TCaseBankruptcySearchesListForm = TTypeOfCodec<TCaseBankruptcySearchesListFormCodec>;

export const CaseVideoVerificationCallCreateForm: TFormCodec<TCaseIdCodec, TEmptyObjectCodec> = form(
    CaseId,
    EmptyObject,
);
export type TCaseVideoVerificationCallCreateFormCodec = typeof CaseVideoVerificationCallCreateForm;
export type TCaseVideoVerificationCallCreateForm = TTypeOfCodec<TCaseVideoVerificationCallCreateFormCodec>;

export const CaseVideoVerificationCallAssociateWithUserForm: TFormCodec<TUserVideoVerificationCall1Codec, TEmptyObjectCodec> = form(
    UserVideoVerificationCall1,
    EmptyObject,
);
export type TCaseVideoVerificationCallAssociateWithUserFormCodec = typeof CaseVideoVerificationCallAssociateWithUserForm;
export type TCaseVideoVerificationCallAssociateWithUserForm = TTypeOfCodec<TCaseVideoVerificationCallAssociateWithUserFormCodec>;

export const CaseVideoVerificationCallUserForm: TFormCodec<TUserVideoVerificationCall2Codec, TIntersectionCodec<[
    TRequiredFlatOverloadedCodec<{
        full_name: TUserNameCodec,
    }>,
    TRequiredFlatOverloadedCodec<{
        has_expired: TUsersVideoVerificationCallHasExpiredCodec,
    }>,
    TRequiredCodec<{
        video_verification_call_id: TUuidCodec,
    }>,
    TRequiredCodec<{
        user_id: TUuidCodec,
    }>,
]>> = form(
    UserVideoVerificationCall2,
    intersection([
        requiredFlatOverloaded({
            full_name: UserName,
        }),
        requiredFlatOverloaded({
            has_expired: UsersVideoVerificationCallHasExpired,
        }),
        required({
            video_verification_call_id: uuid(),
        }),
        required({
            user_id: uuid(),
        }),
    ]),
);
export type TCaseVideoVerificationCallUserFormCodec = typeof CaseVideoVerificationCallUserForm;
export type TCaseVideoVerificationCallUserForm = TTypeOfCodec<TCaseVideoVerificationCallUserFormCodec>;

export const CaseVideoVerificationCallListForm: TFormCodec<
    TVideoVerificationCall1Codec,
    TIntersectionCodec<[
        TVideoVerificationCall2Codec,
        TRequiredCodec<{
            associate_with_user: TCaseVideoVerificationCallAssociateWithUserFormCodec,
            users: TArrayCodec<TCaseVideoVerificationCallUserFormCodec>,
        }>
    ]>
> = form(
    VideoVerificationCall1,
    intersection([
        VideoVerificationCall2,
        required({
            associate_with_user: CaseVideoVerificationCallAssociateWithUserForm,
            users: array(CaseVideoVerificationCallUserForm),
        })
    ]),
);
export type TCaseVideoVerificationCallListFormCodec = typeof CaseVideoVerificationCallListForm;
export type TCaseVideoVerificationCallListForm = TTypeOfCodec<TCaseVideoVerificationCallListFormCodec>;

export const CaseSdltDeclarationForm: TFormCodec<TCaseIdCodec, TEmptyObjectCodec> = form(
    CaseId,
    EmptyObject,
);
export type TCaseSdltDeclarationFormCodec = typeof CaseSdltDeclarationForm;
export type TCaseSdltDeclarationForm = TTypeOfCodec<TCaseSdltDeclarationFormCodec>;

export const CaseSdltForm: TFormCodec<TCasesSdltCodec, TIntersectionCodec<[
    TRequiredFlatOverloadedCodec<{
        status: TCaseSdltAnswersSubmissionStatusCodec,
    }>,
    TRequiredFlatOverloadedCodec<{
        client_names_for_declaration: TUserNamesCodec,
    }>,
    TRequiredFlatOverloadedCodec<{
        submit_declaration: TCaseSdltDeclarationFormCodec,
    }>
]>> = form(
    CasesSdlt,
    intersection([
        requiredFlatOverloaded({
            status: CaseSdltAnswersSubmissionStatus,
        }),
        requiredFlatOverloaded({
            client_names_for_declaration: UserNames,
        }),
        requiredFlatOverloaded({
            submit_declaration: CaseSdltDeclarationForm,
        }),
    ]),
);
export type TCaseSdltFormCodec = typeof CaseSdltForm;
export type TCaseSdltForm = TTypeOfCodec<TCaseSdltFormCodec>;

export const CaseNewRiskAssessmentForm: TFormCodec<TCasesRiskAssessment1Codec, TEmptyObjectCodec> = form(
    CasesRiskAssessment1,
    EmptyObject,
);
export type TCaseNewRiskAssessmentFormCodec = typeof CaseNewRiskAssessmentForm;
export type TCaseNewRiskAssessmentForm = TTypeOfCodec<TCaseNewRiskAssessmentFormCodec>;

export const CaseAuditComments = required({
    audit_comments: longString(),
});
export type TCaseAuditComments = TTypeOfCodec<typeof CaseAuditComments>;
export type TCaseAuditCommentsCodec = typeof CaseAuditComments;

export const CaseAuditChildren = required({
    selected_for_audit: boolean(),
    audit_completed_at: union([nullCodec(), dateTime()]),
    audit_completed_by_user_id: union([nullCodec(), uuid()]),
});
export type TCaseAuditChildren = TTypeOfCodec<typeof CaseAuditChildren>;
export type TCaseAuditChildrenCodec = typeof CaseAuditChildren;

export const SubmitCaseAuditAsCompleteForm: TFormCodec<TCaseIdCodec, TEmptyObjectCodec> = form(
    CaseId,
    EmptyObject
);
export type TSubmitCaseAuditAsCompleteFormCodec = typeof SubmitCaseAuditAsCompleteForm;
export type TSubmitCaseAuditAsCompleteForm = TTypeOfCodec<TSubmitCaseAuditAsCompleteFormCodec>;

export const CaseAuditForm: TFormCodec<
    TIntersectionCodec<[
        TCaseIdCodec,
        TCaseAuditCommentsCodec,
    ]>,
    TIntersectionCodec<[
        TCaseAuditChildrenCodec,
        TRequiredCodec<{
            submit_audit_as_complete: TSubmitCaseAuditAsCompleteFormCodec
        }>
    ]>
> = form(
    intersection([
        CaseId,
        CaseAuditComments,
    ]),
    intersection([
        CaseAuditChildren,
        required({
            submit_audit_as_complete: SubmitCaseAuditAsCompleteForm
        })
    ]),
);
export type TCaseAuditFormCodec = typeof CaseAuditForm;
export type TCaseAuditForm = TTypeOfCodec<typeof CaseAuditForm>;

const CreateQuoteChildrenReferralFees = required({
    purchase: positiveInteger(),
    sale: positiveInteger(),
    transfer: positiveInteger(),
    remortgage: positiveInteger(),
    transfer_and_remortgage: positiveInteger(),
});
export type TCreateQuoteChildrenReferralFees = TTypeOfCodec<typeof CreateQuoteChildrenReferralFees>;

const CreateQuoteChildren = required({
    introducer_name: string(),
    referral_fees: CreateQuoteChildrenReferralFees,
});
export type TCreateQuoteChildrenCodec = typeof CreateQuoteChildren;

export const CreateCaseQuoteUnfilledForm: TFormCodec<TCaseCreateQuoteUnfilledCodec, TCreateQuoteChildrenCodec> = form(
    CaseCreateQuoteUnfilled,
    CreateQuoteChildren,
);
export type TCreateCaseQuoteUnfilledFormCodec = typeof CreateCaseQuoteUnfilledForm;
export type TCreateCaseQuoteUnfilledForm = TTypeOfCodec<TCreateCaseQuoteUnfilledFormCodec>;

export const CreateCaseQuoteFilledForm: TFormCodec<TCaseCreateQuoteFilledCodec, TCreateQuoteChildrenCodec> = form(
    CaseCreateQuoteFilled,
    CreateQuoteChildren,
);
export type TCreateCaseQuoteFilledFormCodec = typeof CreateCaseQuoteFilledForm;
export type TCreateCaseQuoteFilledForm = TTypeOfCodec<TCreateCaseQuoteFilledFormCodec>;

export const CaseQuoteSentToClientForm: TFormCodec<TCaseQuoteSentToClientCodec, TEmptyObjectCodec> = form(
    CaseQuoteSentToClient,
    EmptyObject,
);
export type TCaseQuoteSentToClientFormCodec = typeof CaseQuoteSentToClientForm;
export type TCaseQuoteSentToClientForm = TTypeOfCodec<TCaseQuoteSentToClientFormCodec>;

const CaseFormChildren: TIntersectionCodec<[
    TRequiredCodec<{
        members: TArrayCodec<TCaseMemberFormCodec>,
        bank_accounts: TArrayCodec<TCaseBankAccountFormCodec>,
        sign_off_as_ready_for_exchange: TCaseSignOffAsReadyForExchangeFormCodec,
        instruct: TCaseInstructFormCodec,
        related_transaction: TRequiredCodec<{
            create_link: TCaseRelatedTransactionCreateFormCodec,
            related_data: TCaseRelatedTransactionDataFormCodec,
        }>,
        case_users_bankruptcy_searches: TRequiredCodec<{
            list: TCaseBankruptcySearchesListFormCodec,
            case_users_requiring_bankruptcy_searches: TArrayCodec<TUserBankruptcySearchCodec>
        }>,
        video_verification_call: TRequiredCodec<{
            create: TCaseVideoVerificationCallCreateFormCodec,
            list: TArrayCodec<TCaseVideoVerificationCallListFormCodec>,
            users_that_can_be_booked_for_video_call: TArrayCodec<TUserBookableForVideoCallCodec>,
        }>,
        sdlt: TCaseSdltFormCodec,
        risk_assessments: TRequiredCodec<{
            new_form: TCaseNewRiskAssessmentFormCodec,
            assessments: TArrayCodec<TCasesRiskAssessment2Codec>,
            new_assessment_required: TBooleanCodec,
        }>,
        audit: TCaseAuditFormCodec,
        introducers: TArrayCodec<TIntroducer1Codec>,
        dual_rep: TRequiredCodec<{
            create_link: TCaseDualRepCreateFormCodec,
            linked_case_form: TUnionCodec<[
                TCaseDualRepLinkedFormCodec,
                TNullCodec,
            ]>,
        }>,
        ready_for_exchange_checklist: TReadyForExchangeChecklistCodec,
        quotes: TArrayCodec<TCaseQuoteCodec>,
        create_quote_form: TCreateCaseQuoteUnfilledFormCodec,
        quote_sent_to_client_form: TCaseQuoteSentToClientFormCodec,
    }>,
    TCaseRootReadOnlyPropsCodec,
    TCaseEnquiryTimestampsCodec,
]> = intersection([
    required({
        members: array(CaseMemberForm),
        bank_accounts: array(CaseBankAccountForm),
        sign_off_as_ready_for_exchange: CaseSignOffAsReadyForExchangeForm,
        instruct: CaseInstructForm,
        related_transaction: required({
            create_link: CaseRelatedTransactionCreateForm,
            related_data: CaseRelatedTransactionDataForm,
        }),
        case_users_bankruptcy_searches: required({
            list: CaseBankruptcySearchesListForm,
            case_users_requiring_bankruptcy_searches: array(UserBankruptcySearch)
        }),
        video_verification_call: required({
            create: CaseVideoVerificationCallCreateForm,
            list: array(CaseVideoVerificationCallListForm),
            users_that_can_be_booked_for_video_call: array(UserBookableForVideoCall),
        }),
        sdlt: CaseSdltForm,
        risk_assessments: required({
            new_form: CaseNewRiskAssessmentForm,
            assessments: array(CasesRiskAssessment2),
            new_assessment_required: boolean(),
        }),
        audit: CaseAuditForm,
        introducers: array(Introducer1),
        dual_rep: required({
            create_link: CaseDualRepCreateForm,
            linked_case_form: union([CaseDualRepLinkedForm, nullCodec()]),
        }),
        ready_for_exchange_checklist: ReadyForExchangeChecklist,
        quotes: array(CaseQuote),
        create_quote_form: CreateCaseQuoteUnfilledForm,
        quote_sent_to_client_form: CaseQuoteSentToClientForm,
    }),
    CaseRootReadOnlyProps,
    CaseEnquiryTimestamps,
]);
type TCaseFormChildrenCodec = typeof CaseFormChildren;

export const CaseForm: TFormCodec<TCaseEditableFullCodec, TCaseFormChildrenCodec> = form(
    CaseEditableFull,
    CaseFormChildren,
);
export type TCaseFormCodec = typeof CaseForm;
export type TCaseForm = TTypeOfCodec<TCaseFormCodec>;
