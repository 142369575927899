import * as t from "io-ts";
import * as User4 from "../models/User4";
import * as Party4 from "./Party4";
import * as Party5 from "../models/Party5";
import * as Party6 from "../models/Party6";

export const codec = t.type({
    id: t.string,
    users: t.array(User4.codec),
    created_at: t.string,
    ...Party4.codec.props,
    ...Party5.codec.props,
    ...Party6.codec.props,
});

export type T = t.TypeOf<typeof codec>;

export const newDefault = (): T => ({
    id: "",
    users: [],
    created_at: "",
    ...Party4.newDefault(),
    ...Party5.newDefault(),
    ...Party6.newDefault(),
});
