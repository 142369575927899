import { union } from "../../shared/src/codecs/types/union";
import { literal } from "../../shared/src/codecs/types/literal";
import { TTypeOfNewDefault } from "../../shared/src/codecs/codec";
import { overload } from "../../shared/src/codecs/types/overload";
import { intersection } from "../../shared/src/codecs/types/intersection";
import { required } from "../../shared/src/codecs/types/required";
import { boolean } from "../../shared/src/codecs/types/boolean";
import { SellingPropertyState } from "./SellingPropertyState";
import { PaymentMethod } from "./PaymentMethod";
import { HasProvidedConveyancerDetails } from "./HasProvidedConveyancerDetails";
import { HasProvidedProofOfFundsForOffer } from "./HasProvidedProofOfFundsForOffer";
import { requiredFlatOverloaded } from "../../shared/src/codecs/types/requiredFlatOverloaded";

const RiskOfFallThrough1 = union([literal("low"), literal("medium"), literal("high")]);

export type TRiskOfFallThrough1NewDfault = TTypeOfNewDefault<typeof RiskOfFallThrough1>;

export const RiskOfFallThrough2 = overload(
    RiskOfFallThrough1,
    intersection([
        required({
            selling_property_state: SellingPropertyState,
            payment_method: PaymentMethod,
            id_check_complete: boolean(),
            mortgage_broker_has_vouched: boolean(),
        }),
        requiredFlatOverloaded({
            has_provided_conveyancer_details: HasProvidedConveyancerDetails,
            has_provided_proof_of_funds: HasProvidedProofOfFundsForOffer,
        }),
    ]),
    (details) => details.has_provided_proof_of_funds
        && details.id_check_complete
        && details.has_provided_conveyancer_details
        && (
            details.payment_method === "cash"
            || details.mortgage_broker_has_vouched
        )
        && details.selling_property_state === "no_property_to_sell"
            ? "low" as const

        // Medium risk
        : details.has_provided_proof_of_funds
        && (
            details.id_check_complete
            || details.has_provided_conveyancer_details
        )
            ? "medium" as const

        // High risk
        : "high" as const,
);