import * as t from "io-ts";
import { required } from "../../shared/src/validation/basic/required";
import { inOrder } from "../../shared/src/validation/compose/inOrder";
import { rangeConstants } from "../../shared/src/validation/constants/range";
import { object } from "../../shared/src/validation/create/object";
import { range } from "../../shared/src/validation/create/range";

export const codec = t.type({
    amount: t.number,
});

export type T = t.TypeOf<typeof codec>;

export const validator = object({
    amount: inOrder(
        required,
        range(0, rangeConstants.GREATER_THAN),
    ),
});

export const newDefault = (): T => ({
    amount: 0,
});
