import * as t from "io-ts";
import { integerPositive } from "../../shared/src/validation/basic/integerPositive";
import { all } from "../../shared/src/validation/compose/all";
import { isEnum } from "../../shared/src/validation/create/isEnum";
import { object } from "../../shared/src/validation/create/object";
import * as CaseTransactionType from "./CaseTransactionType";

export const codec = t.partial({
    price_pence: t.union([t.number, t.null]),
    transaction_type: CaseTransactionType.codec,
});

export type T = t.TypeOf<typeof codec>;

export const validator = all(
    object({
        price_pence: integerPositive,
        transaction_type: isEnum(CaseTransactionType.values),
    }),
);

export const newDefault = (): Required<T> => ({
    price_pence: 0,
    transaction_type: "purchase",
});
