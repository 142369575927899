import * as rxjs from "rxjs";
import * as rxjsOperators from "rxjs/operators";
import { option, either } from "fp-ts";
import * as TForm from "./../../models/TForm";
import * as request from "./../request";
import { TState } from "./lensBaseTypes";
import { reduceDataToStateUpdate } from "./reduceDataToStateUpdate";
import { set } from "./set";
import { findFirst } from "./findFirst";
import { TGetState } from "../../state/TGetState";
import { TSetState } from "../../state/TSetState";
import { pipe } from "fp-ts/lib/function";
import { TValidationError } from "../../../../shared/src/validation/Error";

interface IObservableOfValidatedForm<T, S> {
    // 5 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1],
        K3 extends keyof S[K1][K2],
        K4 extends keyof S[K1][K2][K3],
        K5 extends keyof S[K1][K2][K3][K4]
    >
    (
        path: [K1, K2, K3, K4, K5],
        validator: (value: unknown) => option.Option<TValidationError>,
    ): rxjs.Observable<either.Either<TValidationError, TForm.TFormV2<TForm.TUnpackViewAndEditFormType<T>, TForm.TUnpackEditFormType<T>, TForm.TUnpackUiType<T>>>>;
    // 4 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1],
        K3 extends keyof S[K1][K2],
        K4 extends keyof S[K1][K2][K3]
    >
    (
        path: [K1, K2, K3, K4],
        validator: (value: unknown) => option.Option<TValidationError>,
    ): rxjs.Observable<either.Either<TValidationError, TForm.TFormV2<TForm.TUnpackViewAndEditFormType<T>, TForm.TUnpackEditFormType<T>, TForm.TUnpackUiType<T>>>>;
    // 3 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1],
        K3 extends keyof S[K1][K2]
    >
    (
        path: [K1, K2, K3],
        validator: (value: unknown) => option.Option<TValidationError>,
    ): rxjs.Observable<either.Either<TValidationError, TForm.TFormV2<TForm.TUnpackViewAndEditFormType<T>, TForm.TUnpackEditFormType<T>, TForm.TUnpackUiType<T>>>>;
    // 2 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1]
    >
    (
        path: [K1, K2],
        validator: (value: unknown) => option.Option<TValidationError>,
    ): rxjs.Observable<either.Either<TValidationError, TForm.TFormV2<TForm.TUnpackViewAndEditFormType<T>, TForm.TUnpackEditFormType<T>, TForm.TUnpackUiType<T>>>>;
    // 1 level lens path guard
    <K1 extends keyof S>
    (
        path: [K1],
        validator: (value: unknown) => option.Option<TValidationError>,
    ): rxjs.Observable<either.Either<TValidationError, TForm.TFormV2<TForm.TUnpackViewAndEditFormType<T>, TForm.TUnpackEditFormType<T>, TForm.TUnpackUiType<T>>>>;
}

export const ofFormValidatedAndSetToSubmitting = <T>(getState: TGetState, setState: TSetState): IObservableOfValidatedForm<T, TState> => (
    // There is no way to create paramater overloads here so we set to any
    lensPath: any, // eslint-disable-line
    validator: (value: unknown) => option.Option<TValidationError>,
): rxjs.Observable<either.Either<TValidationError, TForm.TFormV2<TForm.TUnpackViewAndEditFormType<T>, TForm.TUnpackEditFormType<T>, TForm.TUnpackUiType<T>>>> =>
     pipe(
        request.rxjsPayloadFrom<TForm.TFormV2<TForm.TUnpackViewAndEditFormType<T>, TForm.TUnpackEditFormType<T>, TForm.TUnpackUiType<T>>>(
            findFirst(getState())(lensPath)
        ),
        rxjsOperators.tap(reduceDataToStateUpdate<TForm.TFormV2<TForm.TUnpackViewAndEditFormType<T>, TForm.TUnpackEditFormType<T>, TForm.TUnpackUiType<T>>>(setState)(
            set<TForm.TFormV2<TForm.TUnpackViewAndEditFormType<T>, TForm.TUnpackEditFormType<T>, TForm.TUnpackUiType<T>>>()(
                lensPath,
                /* eslint-disable */
                // The type is always expected to be never for the form, since we have abstraced the lens scoping,
                // which is untrue
                // @ts-ignore
                TForm.setFormToSubmitting<TForm.TFormV2<TForm.TUnpackViewAndEditFormType<T>, TForm.TUnpackEditFormType<T>, TForm.TUnpackUiType<T>>>()
                /* eslint-enable */
            )
        )),
        rxjsOperators.mergeMap((form) =>
            rxjs.of(
                option.fold<TValidationError, either.Either<TValidationError, TForm.TFormV2<TForm.TUnpackViewAndEditFormType<T>, TForm.TUnpackEditFormType<T>, TForm.TUnpackUiType<T>>>>(
                    () => either.right(form),
                    (errors) => either.left(errors)
                )(validator(form.edit))
            )
        )
    );
