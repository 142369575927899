import { boolean } from "../../shared/src/codecs/types/boolean";
import { nullCodec } from "../../shared/src/codecs/types/nullCodec";
import { overload } from "../../shared/src/codecs/types/overload";
import { required } from "../../shared/src/codecs/types/required";
import { string } from "../../shared/src/codecs/types/string";
import { union } from "../../shared/src/codecs/types/union";

export const HasProvidedConveyancerDetails = overload(
    boolean(),
    required({
        conveyancer_organisation_name: string(),
        conveyancer_address: string(),
        conveyancer_name: string(),
        conveyancer_phone_number: union([string(), nullCodec()]),
        conveyancer_email_address: union([string(), nullCodec()])
    }),
    (details) => details.conveyancer_organisation_name
        && details.conveyancer_address
        && details.conveyancer_name
        && details.conveyancer_phone_number
        && details.conveyancer_email_address
        ? true : false,
);