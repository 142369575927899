import * as t from "io-ts";
import * as OfferStatus from "../models/OfferStatus";

const codec = t.type({
    status: OfferStatus.codec,
    rejected_reason: t.string,
    shared_with_seller_at: t.union([t.string, t.null]),
});

export type T = t.TypeOf<typeof codec>;

export const newDefault = (): T => ({
    status: "pending",
    rejected_reason: "",
    shared_with_seller_at: null,
});
