import React from "react";

export default (props: React.SVGAttributes<SVGElement>) => <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g clipPath="url(#clip0_5280:25134)">
<path d="M41.7998 33.0001C41.7998 33.2816 41.6924 33.5631 41.4776 33.7779C41.048 34.2075 40.3515 34.2075 39.922 33.7779L20.8998 14.7557L1.87757 33.7779C1.44802 34.2075 0.751515 34.2075 0.321964 33.7779C-0.107586 33.3483 -0.107654 32.6518 0.321964 32.2223L20.122 12.4223C20.5515 11.9927 21.248 11.9927 21.6776 12.4223L41.4776 32.2223C41.6924 32.4371 41.7998 32.7186 41.7998 33.0001V33.0001Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_5280:25134">
<rect width="44" height="44" fill="white"/>
</clipPath>
</defs>
</svg>
;
