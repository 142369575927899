import { TTypeOfCodec } from "../../../shared/src/codecs/codec";
import { TUser12Codec, User12 } from "../User";
import { form, TFormCodec } from "../../../shared/src/codecs/types/form";
import { EmptyObject, TEmptyObjectCodec } from "../EmptyObject";

export const CaseOtherSideMemberForm: TFormCodec<TUser12Codec, TEmptyObjectCodec> = 
    form(
        User12,
        EmptyObject,
    );
export type TCaseOtherSideMemberFormCodec = typeof CaseOtherSideMemberForm;
export type TCaseOtherSideMemberForm = TTypeOfCodec<TCaseOtherSideMemberFormCodec>;