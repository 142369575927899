import * as t from "io-ts";
import { nonEmptyString } from "../../shared/src/validation/basic/nonEmptyString";
import { required } from "../../shared/src/validation/basic/required";
import { uuid } from "../../shared/src/validation/basic/uuid";
import { all } from "../../shared/src/validation/compose/all";
import { object } from "../../shared/src/validation/create/object";
import { TValidationFunction } from "../../shared/src/validation/Function";

export const codec = t.type({
    id: t.string,
    reference: t.string,
});

export type T = t.TypeOf<typeof codec>;

export const validator: TValidationFunction = object({
    id: all(
        required,
        uuid,
    ),
    reference: all(
        required,
        nonEmptyString,
    ),
});
