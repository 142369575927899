import { nullCodec } from "../../shared/src/codecs/types/nullCodec";
import { positiveInteger } from "../../shared/src/codecs/types/positiveInteger";
import { required } from "../../shared/src/codecs/types/required";
import { union } from "../../shared/src/codecs/types/union";
import { uuid } from "../../shared/src/codecs/types/uuid";
import { CaseLedgerType } from "./CaseLedger";

export const CaseNewLedgerEntry = required({
    case_id: uuid(),
    type: CaseLedgerType,
    vat_excluded_amount_gbp_pence: union([positiveInteger(), nullCodec()]),
});
export type TCaseNewLedgerEntryCodec = typeof CaseNewLedgerEntry;