import { required } from "../../shared/src/codecs/types/required";
import { Tenure1 } from "./Tenure";
import { uuid } from "../../shared/src/codecs/types/uuid";
import { string } from "../../shared/src/codecs/types/string";
import { CasePropertyTitleClass } from "./CasePropertyTitleClass";
import { CasePropertyTitleGuarantee2 } from "./CasePropertyTitleGuarantee";
import { date } from "../../shared/src/codecs/types/date";
import { nullCodec } from "../../shared/src/codecs/types/nullCodec";
import { union } from "../../shared/src/codecs/types/union";
import { boolean } from "../../shared/src/codecs/types/boolean";
import { CasePropertyTransferOf } from "./CaseProperty";
import { TTypeOfCodec } from "../../shared/src/codecs/codec";
import { YesNoUnknown } from "./YesNoUnknown";
import { literal } from "../../shared/src/codecs/types/literal";
import { longString } from "../../shared/src/codecs/types/longString";
import { HomesAndLegalPropertyType } from "./HomesAndLegalPropertyType";
import { dateTime } from "../../shared/src/codecs/types/dateTime";
import { dateTimeOrNullAsBoolean } from "../../shared/src/codecs/types/dateTimeOrNullAsBoolean";

export const DocumentsInTitlesObtainedStatus = union([
    literal("yes"),
    literal("no"),
    literal("some_missing"),
    literal("unknown"),
]);
export type TDocumentsInTitlesObtainedStatusCodec = typeof DocumentsInTitlesObtainedStatus;
export type TDocumentsInTitlesObtainedStatus = TTypeOfCodec<TDocumentsInTitlesObtainedStatusCodec>;

export const FlyingFreeholdType = union([
    literal("sufficient_rights"),
    literal("indemnity"),
    literal("none"),
    literal("unknown"),
]);
export type TFlyingFreeholdTypeCodec = typeof FlyingFreeholdType;
export type TFlyingFreeholdType = TTypeOfCodec<TFlyingFreeholdTypeCodec>;

export const MinesAndMineralsReliefType = union([
    literal("compensation"),
    literal("indemnity"),
    literal("none"),
    literal("unknown"),
]);
export type TMinesAndMineralsReliefTypeCodec = typeof MinesAndMineralsReliefType;
export type TMinesAndMineralsReliefType = TTypeOfCodec<TMinesAndMineralsReliefTypeCodec>;

export const TenantBecomesOwnerOfFhOrMcMemberStatus = union([
    literal("freehold_owner"),
    literal("management_company_member"),
    literal("neither"),
    literal("unknown"),
]);
export type TTenantBecomesOwnerOfFhOrMcMemberStatusCodec = typeof TenantBecomesOwnerOfFhOrMcMemberStatus;
export type TTenantBecomesOwnerOfFhOrMcMemberStatus = TTypeOfCodec<TTenantBecomesOwnerOfFhOrMcMemberStatusCodec>;

export const PlanningRegsRequirements = union([
    literal("consent"),
    literal("transfer"),
    literal("neither"),
    literal("unknown"),
]);
export type TPlanningRegsRequirementsCodec = typeof PlanningRegsRequirements;
export type TPlanningRegsRequirements = TTypeOfCodec<TPlanningRegsRequirementsCodec>;

export const TitleCheckEditable = required({
    id: uuid(),
    property_type: HomesAndLegalPropertyType,
    last_transfer_date: union([date(), nullCodec()]),
    title_or_deed_obtained: YesNoUnknown,
    all_documents_referred_to_in_titles_and_deeds_obtained: DocumentsInTitlesObtainedStatus,
    missing_deed_or_title_documents_description: longString(),
    can_epitome_of_title_be_drafted: YesNoUnknown,
    reconstituting_title: YesNoUnknown,
    title_defects_that_require_indemnification: YesNoUnknown,
    title_defects_description: longString(),
    deed_of_variation_needed: YesNoUnknown,
    client_and_lender_has_approved_title_owned_less_than_6_months: YesNoUnknown,
    registered_proprietors_names_match_sellers: YesNoUnknown,
    initial_reason_proprietors_names_do_not_match_sellers: longString(),
    properties_more_than_10_years_old: YesNoUnknown,
    new_build_warranties_provided: YesNoUnknown,
    right_of_way_acceptable: YesNoUnknown,
    right_of_way_not_acceptable_reason: longString(),
    is_flying_freehold: YesNoUnknown,
    flying_freehold_sufficient_rights_or_indemnity: FlyingFreeholdType,
    mines_minerals_excepted: YesNoUnknown,
    mines_minerals_excepted_relief: MinesAndMineralsReliefType,
    leasehold_terms_sufficient_with_lender_and_client: YesNoUnknown,
    annual_ground_rent_below_250_or_1000_for_london: YesNoUnknown,
    clause_cap_ground_rent_below_250_or_1000_for_london: YesNoUnknown,
    doubling_ground_rent: YesNoUnknown,
    tenant_becomes_owner_of_fh_or_mc_member: TenantBecomesOwnerOfFhOrMcMemberStatus,
    share_cert_obtained: YesNoUnknown,
    sufficient_repair_covenants: YesNoUnknown,
    lease_contains_provision_for_forfeiture_on_bankruptcy: YesNoUnknown,
    sufficient_rights_of_support: YesNoUnknown,
    sufficient_rights_of_access: YesNoUnknown,
    landlord_insurers_building: YesNoUnknown,
    deed_of_covenant_required: YesNoUnknown,
    license_to_assign_required: YesNoUnknown,
    building_regs_planning_structural_guarantee_required: YesNoUnknown,
    planning_regs_require_consent_or_transfer: PlanningRegsRequirements,
    insurance_sufficient_for_maisonette: YesNoUnknown,
    has_other_title_issues: YesNoUnknown,
    all_other_title_issues_added: dateTimeOrNullAsBoolean(),
    all_charges_and_restrictions_for_title_added: dateTimeOrNullAsBoolean(),
});
export type TTitleCheckEditableCodec = typeof TitleCheckEditable;
export type TTitleCheckEditable = TTypeOfCodec<TTitleCheckEditableCodec>;

export const TitleCheckReadOnly = required({
    tenure: Tenure1,
    title_number: string(),
    title_guarantee: CasePropertyTitleGuarantee2,
    title_class: CasePropertyTitleClass,
    affected_by_building_safety_act: union([boolean(), nullCodec()]),
    date_on_title_register: union([nullCodec(), dateTime()]),
    is_registered: union([boolean(), nullCodec()]),
    transfer_of: CasePropertyTransferOf,
    recent_last_transfer_date_risk_acknowledged: boolean(),
    building_name: string(),
    building_number: string(),
    sub_building_name: string(),
    sub_building_number: string(),
    street_name: string(),
});
export type TTitleCheckReadOnlyCodec = typeof TitleCheckReadOnly;
export type TTitleCheckReadOnly = TTypeOfCodec<TTitleCheckReadOnlyCodec>;

export const TitleCheckStatus = union([literal("pending"), literal("done")]);
export type TTitleCheckStatusCodec = typeof TitleCheckStatus;
export type TTitleCheckStatus = TTypeOfCodec<TTitleCheckStatusCodec>;
