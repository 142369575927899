import { array as fptsArray } from "fp-ts";
import { overload } from "../../../shared/src/codecs/types/overload";
import { required } from "../../../shared/src/codecs/types/required";
import { string } from "../../../shared/src/codecs/types/string";
import { pipe } from "fp-ts/lib/function";
import { array } from "../../../shared/src/codecs/types/array";
import { Eq } from "fp-ts/lib/string";

export const UserLastName = overload(
    string(),
    required({
        last_name: string(),
    }),
    (p) => pipe(
        [
            ...(p.last_name ? [p.last_name] : []),
        ],
        (a) => a.length === 0 ? "Name not given"
            : a.join(" "),
    )
);
export type TUserLastNameCodec = typeof UserLastName;

export const UserLastNames = overload(
    string(),
    array(UserLastName),
    (names) =>
        pipe(
            names,
            fptsArray.uniq(Eq),
            (n) => n.join(" & "),
        ),
);
export type TUserLastNamesCodec = typeof UserLastNames;
