import React, { CSSProperties } from "react";
import { array } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import { TPercentageSize, TPixelSize } from "../../models/StringLiterals";

type TSpacingRowProps = React.PropsWithChildren<{
    divRef?: React.RefObject<HTMLDivElement>;
    justifyContent?: "start" | "end" | "center" | "space-around" | "space-between" | "space-evenly" | "stretch";
    alignItems?: "center" | "flex-start" | "flex-end" | "baseline";
    spacing?: string;
    childSize?: "auto" | "even-fill" | string;
    height?: "auto" | TPixelSize | TPercentageSize | `${number}vh`;
    minHeight?: "auto" | TPixelSize | TPercentageSize | `${number}vh`;
    onClick?: () => void;
    cursorStyle?: CSSProperties["cursor"],
    isInline?: boolean;
    title?: string;
}>;

const getGridTemplateColumns = (props: TSpacingRowProps): string => {
    if (!props.childSize || props.childSize === "auto") {
        return getGridTemplateColumnsBasedOnVisibleChildCount(props.children, "auto");
    }

    if (props.childSize === "even-fill") {
        return getGridTemplateColumnsBasedOnVisibleChildCount(props.children, "1fr");
    }

    return props.childSize;
};

const getGridTemplateColumnsBasedOnVisibleChildCount = (children: React.ReactNode, columnSize: string): string =>
     pipe(
        React.Children.toArray(children),
        array.map(() => columnSize),
        (arr) => arr.join(" "),
    );

export const SpacingRow = (props: TSpacingRowProps): JSX.Element => (
    <div
        ref={props.divRef}
        style={{
            width: "100%",
            height: props.height,
            minHeight: props.minHeight,
            display: props.isInline ? "inline-grid" : "grid",
            gridTemplateRows: "100%",
            justifyContent: props.justifyContent || "start",
            gridColumnGap: props.spacing || "0px",
            gridTemplateColumns: getGridTemplateColumns(props),
            alignItems: props.alignItems || "center",
            cursor: props.cursorStyle
        }}
        title={props.title}
        onClick={props.onClick}
    >
        {props.children}
    </div>
);
