import { TTypeOfCodec } from "../../../shared/src/codecs/codec";
import { array, TArrayCodec } from "../../../shared/src/codecs/types/array";
import { boolean, TBooleanCodec } from "../../../shared/src/codecs/types/boolean";
import { formIO, TFormIOCodec } from "../../../shared/src/codecs/types/formIO";
import { required, TRequiredCodec } from "../../../shared/src/codecs/types/required";
import { string, TAnyStringCodec } from "../../../shared/src/codecs/types/string";
import { EmptyObject, TEmptyObjectCodec } from "../EmptyObject";
import { AdminUserEditable, TAdminUserEditableCodec } from "../formEditable/AdminUserEditable";

const AdminPageFormIOOutput: TRequiredCodec<{
    adminUsers: TArrayCodec<TAdminUserEditableCodec>,
    klyant: TRequiredCodec<{
        authUrl: TAnyStringCodec,
        hasError: TBooleanCodec,
    }>
}> = required({
    adminUsers: array(AdminUserEditable),
    klyant: required({
        authUrl: string(),
        hasError: boolean(),
    }),
});
type TAdminPageFormIOOutputCodec = typeof AdminPageFormIOOutput;

export const AdminPageFormIO: TFormIOCodec<TEmptyObjectCodec, TAdminPageFormIOOutputCodec> = formIO(
    EmptyObject,
    AdminPageFormIOOutput
);

export type TAdminPageFormIOCodec = typeof AdminPageFormIO;
export type TAdminPageFormIO = TTypeOfCodec<TAdminPageFormIOCodec>;