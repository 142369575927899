export const onCallIfExists = (anonymousFunction: unknown) =>
    () => {
        if (typeof anonymousFunction === "function") {
            anonymousFunction();
        }
    };

export const isNotAFunction = (callback: unknown) => typeof callback !== "function";
    
export const isAFunction = <F>(callback: unknown): callback is F  =>
    typeof callback === "function"
;

export const isAPlainFunction = <F extends () => void>(callback: unknown): callback is F  =>
    typeof callback === "function"
;

export const isAFunctionCurried = (callback?: unknown) =>
    () => typeof callback === "function";

