import { form, TFormCodec } from "../../../shared/src/codecs/types/form";
import { TTypeOfCodec } from "../../../shared/src/codecs/codec";
import { TUserPhoneNumber2Codec, UserPhoneNumber2 } from "../UserPhoneNumber";
import { TTwoFactorAuthCode1Codec, TwoFactorAuthCode1 } from "../TwoFactorAuthCode";
import { required, TRequiredCodec } from "../../../shared/src/codecs/types/required";
import { EmptyObject, TEmptyObjectCodec } from "../EmptyObject";

export const TwoFactorAuthCodeForm: TFormCodec<TTwoFactorAuthCode1Codec, TEmptyObjectCodec> = form(
    TwoFactorAuthCode1,
    EmptyObject
);
export type TTwoFactorAuthCodeFormCodec = typeof TwoFactorAuthCodeForm;
export type TTwoFactorAuthCodeForm = TTypeOfCodec<TTwoFactorAuthCodeFormCodec>;

export const ResendTwoFactorAuthCodeForm: TFormCodec<TEmptyObjectCodec, TEmptyObjectCodec> = form(
    EmptyObject,
    EmptyObject,
);
export type TResendTwoFactorAuthCodeFormCodec = typeof ResendTwoFactorAuthCodeForm;
export type TResendTwoFactorAuthCodeForm = TTypeOfCodec<TResendTwoFactorAuthCodeFormCodec>;

export const TwoFactorAuthForm: TFormCodec<TUserPhoneNumber2Codec, TRequiredCodec<{
    submit_auth_code: TTwoFactorAuthCodeFormCodec;
    resend_auth_code: TResendTwoFactorAuthCodeFormCodec;
}>> = form(
    UserPhoneNumber2,
    required({
        submit_auth_code: TwoFactorAuthCodeForm,
        resend_auth_code: ResendTwoFactorAuthCodeForm,
    }),
)
export type TTwoFactorAuthFormCodec = typeof TwoFactorAuthForm;
export type TTwoFactorAuthForm = TTypeOfCodec<TTwoFactorAuthFormCodec>;
