import { TCodec } from "../codec";
import { either } from "fp-ts/lib";
import { TError, errorConstants } from "../errors";

export type TAnyPositiveIntegerCodec = TCodec<
    "PositiveIntegerCodec",
    undefined,
    number,
    number
>;

const decode = (input: unknown): either.Either<TError, number> =>
    typeof input === "number"
    && Number.isInteger(input)
    && input >= 0
        ? either.right(input)
        : either.left([[errorConstants.INTEGER_POSITIVE_VALIDATION, ""]]);

export const positiveInteger = (): TAnyPositiveIntegerCodec => ({
    type: "PositiveIntegerCodec",
    payload: undefined,
    decode,
    decodeNewDefault: decode,
    newDefault: () => 0,
});
