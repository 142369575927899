import React from "react";
import { TFrontMainColours } from "../../models/TFrontMainColours";

type TFrontTextColours = {
    colour: TFrontMainColours;
};

export class FrontTextColour extends React.Component<React.PropsWithChildren<TFrontTextColours>> {
    public render (): JSX.Element {
        return (
            <span className={`front-text-colour front-text-colour--${this.props.colour}`}>
                {this.props.children}
            </span>
        );
    }
}
