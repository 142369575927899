import React from "react";

export default (props: React.SVGAttributes<SVGElement>) => <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g clipPath="url(#clip0_5216:24696)">
<path d="M20.9 43.9998C20.7825 43.9998 20.6651 43.981 20.5521 43.9433C16.5058 42.5946 11.4282 38.1104 7.61613 32.5195C4.14171 27.4236 0 18.981 0 7.69977C0 7.0923 0.492525 6.59977 1.1 6.59977C7.29018 6.59977 17.0238 2.36189 20.2898 0.184504C20.6593 -0.061827 21.1407 -0.061827 21.5102 0.184504C24.7762 2.36189 34.5099 6.59977 40.7 6.59977C41.3075 6.59977 41.8 7.0923 41.8 7.69977C41.8 18.981 37.6583 27.4236 34.1839 32.5195C30.3718 38.1104 25.2942 42.5946 21.2479 43.9433C21.1349 43.981 21.0175 43.9998 20.9 43.9998ZM2.21292 8.76368C2.46194 18.9513 6.24394 26.6016 9.43381 31.2801C13.3253 36.9876 17.9168 40.5853 20.9 41.7312C23.8832 40.5853 28.4747 36.9876 32.3661 31.2801C35.556 26.6016 39.338 18.9513 39.587 8.76368C36.0048 8.53887 32.0156 7.30632 29.1805 6.2563C26.0604 5.10075 22.9133 3.63184 20.9 2.40327C18.8867 3.63184 15.7396 5.10075 12.6195 6.2563C9.78436 7.30632 5.79521 8.53893 2.21292 8.76368Z" fill="black"/>
<path d="M20.8998 24.2C20.2923 24.2 19.7998 23.7075 19.7998 23.1V12.1C19.7998 11.4925 20.2923 11 20.8998 11C21.5073 11 21.9998 11.4925 21.9998 12.1V23.1C21.9998 23.7075 21.5073 24.2 20.8998 24.2Z" fill="black"/>
<path d="M20.8998 30.8C20.2923 30.8 19.7998 30.3076 19.7998 29.7V27.5C19.7998 26.8925 20.2923 26.4 20.8998 26.4C21.5073 26.4 21.9998 26.8925 21.9998 27.5V29.7C21.9998 30.3076 21.5073 30.8 20.8998 30.8Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_5216:24696">
<rect width="44" height="44" fill="white"/>
</clipPath>
</defs>
</svg>
;
