import { TTypeOfCodec } from "../../../shared/src/codecs/codec";
import { form, TFormCodec } from "../../../shared/src/codecs/types/form";
import { intersection, TIntersectionCodec } from "../../../shared/src/codecs/types/intersection";
import { required, TRequiredCodec } from "../../../shared/src/codecs/types/required";
import { string, TAnyStringCodec } from "../../../shared/src/codecs/types/string";
import { EmptyObject, TEmptyObjectCodec } from "../EmptyObject";
import { ListingSignMovementEditParts, TListingSignMovementEditPartsCodec } from "../ListingSignMovement";

export const ListingSignMovementEditForm: TFormCodec<
    TIntersectionCodec<[
        TRequiredCodec<{
            id: TAnyStringCodec
        }>,
        TListingSignMovementEditPartsCodec
    ]>,
    TEmptyObjectCodec
> = form(
    intersection([
        required({
            id: string(),
        }),
        ListingSignMovementEditParts,
    ]),
    EmptyObject,
);
export type TListingSignMovementEditFormCodec = typeof ListingSignMovementEditForm;
export type TListingSignMovementEditForm = TTypeOfCodec<TListingSignMovementEditFormCodec>;