import { array, either, record } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import { TTypeOfCodec } from "../../shared/src/codecs/codec";
import { literal } from "../../shared/src/codecs/types/literal"
import { required, TRequiredCodec } from "../../shared/src/codecs/types/required";
import { union } from "../../shared/src/codecs/types/union";
import { decodeWithStrictRecordKey, TCaseCanBeSignedOffAsReadyForExchange, TCaseCanBeSignedOffAsReadyForExchangeExpectedInput } from "./CaseCanBeSignedOffAsReadyForExchange";

export const ReadyForExchangeChecklistPassFail = union([
    literal("pass"),
    literal("fail"),
]);
type TReadyForExchangeChecklistPassFailCodec = typeof ReadyForExchangeChecklistPassFail;

export const ReadyForExchangeChecklist: TRequiredCodec<Record<keyof TCaseCanBeSignedOffAsReadyForExchange, TReadyForExchangeChecklistPassFailCodec>> = required({
    enquiries: ReadyForExchangeChecklistPassFail, 
    id_checks: ReadyForExchangeChecklistPassFail, 
    conflicts_of_interest: ReadyForExchangeChecklistPassFail, 
    undertakings: ReadyForExchangeChecklistPassFail, 
    last_risk_assessment: ReadyForExchangeChecklistPassFail, 
    charges: ReadyForExchangeChecklistPassFail,
    video_calls: ReadyForExchangeChecklistPassFail,
    bank_accounts: ReadyForExchangeChecklistPassFail,
    sdlt_return_uploaded_or_referred_to_compass: ReadyForExchangeChecklistPassFail,
    sdlt_declaration_given: ReadyForExchangeChecklistPassFail,
    mortgage_offer_questions_answered: ReadyForExchangeChecklistPassFail,
    mortgage_offer_expired: ReadyForExchangeChecklistPassFail,
    clients_authorise_exchange_and_complete: ReadyForExchangeChecklistPassFail,
    protocol_forms_required: ReadyForExchangeChecklistPassFail,
    search_report: ReadyForExchangeChecklistPassFail,
    leasehold_report: ReadyForExchangeChecklistPassFail,
    joint_ownership_held_as: ReadyForExchangeChecklistPassFail,
    deposit_added_to_ledger: ReadyForExchangeChecklistPassFail,
    draft_completion_statement_uploaded: ReadyForExchangeChecklistPassFail,
    estate_agent_fee_added_to_ledger: ReadyForExchangeChecklistPassFail,
    apportionment_question_answered: ReadyForExchangeChecklistPassFail,
    all_charges_and_title_restrictions_added: ReadyForExchangeChecklistPassFail,
    all_clients_added_and_right_to_buy_or_sell_checked: ReadyForExchangeChecklistPassFail,
    all_other_side_clients_added_and_right_to_buy_or_sell_checked: ReadyForExchangeChecklistPassFail,
    other_side_solicitor_details_checked: ReadyForExchangeChecklistPassFail,
    client_notified_of_erc: ReadyForExchangeChecklistPassFail,
    leasehold_fee: ReadyForExchangeChecklistPassFail,
    land_reg_fee: ReadyForExchangeChecklistPassFail,
    electronic_transfer: ReadyForExchangeChecklistPassFail,
    dual_rep_lender_conflict: ReadyForExchangeChecklistPassFail,
    dual_rep_missing_consent: ReadyForExchangeChecklistPassFail,
    recent_last_transfer_risk_not_assessed: ReadyForExchangeChecklistPassFail,
    has_unresolved_mandatory_roadblocks: ReadyForExchangeChecklistPassFail,
});
export type TReadyForExchangeChecklistCodec = typeof ReadyForExchangeChecklist;
export type TReadyForExchangeChecklist = TTypeOfCodec<TReadyForExchangeChecklistCodec>;

export type TReadyForExchangeDecodeParam = Record<keyof TReadyForExchangeChecklist, unknown>;

export const fromReadyForExchangeDecodeParam = (p: TCaseCanBeSignedOffAsReadyForExchangeExpectedInput): TReadyForExchangeChecklist => {
    const errorKeys = pipe(
        decodeWithStrictRecordKey(p),
        either.fold(
            array.map(([, errorKey]) => errorKey.split(".")[0]), // Get just the first part of the error key, e.g. "enquiries.case_details" becomes "enquiries"
            () => [],
        ),
    );

    return pipe(
        ReadyForExchangeChecklist.newDefault(),
        record.mapWithIndex((key) =>
            errorKeys.includes(key)
                ? "fail"
                : "pass"
        ),
    );
};

