import React from "react";
import { ProbateAppContainer } from "./containers/ProbateAppContainer";
import { Datacenter, datadogLogs } from "@datadog/browser-logs";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
// import { createRoot } from "react-dom/client";
import ReactDOM from "react-dom";
import { createStateObservable } from "./state/createStateObservable";
import { probateActions } from "./state/probateActions";

const setBrandStylesThenRenderReact = (): void => {
    datadogLogs.init({
        clientToken: (env.REACT_APP_DATADOG_CLIENT_TOKEN || ""),
        forwardErrorsToLogs: true,
        datacenter: Datacenter.EU,
        sampleRate: 100,
    });

    const [state$, dispatch] = createStateObservable(probateActions);

    // LATER this is the new way of rendering react apps but for some reason it breaks
    // the useOpenClose function, until that is fixed we are rolling back and the app
    // will continue working as if an older version of React - Tom Freire Camacho 2023-11-03

    // const container = document.getElementById('app');
    // const root = createRoot(container!);

    // state$.subscribe((s) => {
    //     root.render(<ProbateAppContainer 
    //         state={s} 
    //         dispatch={dispatch} 
    //     />);
    // });

    state$.subscribe((s) => {
        ReactDOM.render(
            <ProbateAppContainer state={s} dispatch={dispatch} />, 
            document.getElementById("app")
        );
    });
};

setBrandStylesThenRenderReact();
