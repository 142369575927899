import { TTypeOfCodec } from "../../shared/src/codecs/codec";
import { boolean } from "../../shared/src/codecs/types/boolean";
import { dateTime } from "../../shared/src/codecs/types/dateTime";
import { intersection } from "../../shared/src/codecs/types/intersection";
import { literal } from "../../shared/src/codecs/types/literal";
import { nullCodec } from "../../shared/src/codecs/types/nullCodec";
import { required } from "../../shared/src/codecs/types/required";
import { requiredFlatOverloaded } from "../../shared/src/codecs/types/requiredFlatOverloaded";
import { string } from "../../shared/src/codecs/types/string";
import { union } from "../../shared/src/codecs/types/union";
import { uuid } from "../../shared/src/codecs/types/uuid";
import { EmailSimpleDisplayString } from "./overloads/Emails";
import { UserName, UserNames } from "./overloads/UserName";

export const CaseCustomerSatisfactionFormalisedAsComplaintAction = required({
    occurred_at: dateTime(),
});
export type TCaseCustomerSatisfactionFormalisedAsComplaintActionCodec = typeof CaseCustomerSatisfactionFormalisedAsComplaintAction;
export type TCaseCustomerSatisfactionFormalisedAsComplaintAction = TTypeOfCodec<TCaseCustomerSatisfactionFormalisedAsComplaintActionCodec>;

export const CaseCustomerSatisfactionRaisedWithOmbudsmanAction = required({
    occurred_at: dateTime(),
});
export type TCaseCustomerSatisfactionRaisedWithOmbudsmanActionCodec = typeof CaseCustomerSatisfactionRaisedWithOmbudsmanAction;
export type TCaseCustomerSatisfactionRaisedWithOmbudsmanAction = TTypeOfCodec<TCaseCustomerSatisfactionRaisedWithOmbudsmanActionCodec>;

export const CaseCustomerSatisfactionAssignmentAction = intersection([
    required({
        id: uuid(),
        occurred_at: dateTime(),
    }),
    requiredFlatOverloaded({
        assigned_to: UserName,
    }),
]);
export type TCaseCustomerSatisfactionAssignmentActionCodec = typeof CaseCustomerSatisfactionAssignmentAction;
export type TCaseCustomerSatisfactionAssignmentAction = TTypeOfCodec<TCaseCustomerSatisfactionAssignmentActionCodec>;

export const CaseCustomerSatisfactionNoteAction = intersection([
    required({
        occurred_at: dateTime(),
        note: string(),
        is_pinned: boolean(),
    }),
    requiredFlatOverloaded({
        created_by: UserName,
    }),
]);
export type TCaseCustomerSatisfactionNoteActionCodec = typeof CaseCustomerSatisfactionNoteAction;
export type TCaseCustomerSatisfactionNoteAction = TTypeOfCodec<TCaseCustomerSatisfactionNoteActionCodec>;

export const CaseCustomerSatisfactionEmailAction = required({
    id: uuid(),
    occurred_at: dateTime(),
    subject: string(),
    sent_from: EmailSimpleDisplayString,
});
export type TCaseCustomerSatisfactionEmailActionCodec = typeof CaseCustomerSatisfactionEmailAction;
export type TCaseCustomerSatisfactionEmailAction = TTypeOfCodec<TCaseCustomerSatisfactionEmailActionCodec>;

export const CaseCustomerSatisfactionPhoneCallAction = intersection([
    required({
        id: string(),
        occurred_at: dateTime(),
    }),
    requiredFlatOverloaded({
        call_from: UserNames,
    }),
    requiredFlatOverloaded({
        call_to: UserNames,
    }),
]);
export type TCaseCustomerSatisfactionPhoneCallActionCodec = typeof CaseCustomerSatisfactionPhoneCallAction;
export type TCaseCustomerSatisfactionPhoneCallAction = TTypeOfCodec<TCaseCustomerSatisfactionPhoneCallActionCodec>;

export const CaseCustomerSatisfactionClosedAction = intersection([
    required({
        occurred_at: dateTime(),
    }),
    requiredFlatOverloaded({
        closed_by: UserName,
    }),
]);
export type TCaseCustomerSatisfactionClosedActionCodec = typeof CaseCustomerSatisfactionClosedAction;
export type TCaseCustomerSatisfactionClosedAction = TTypeOfCodec<TCaseCustomerSatisfactionClosedActionCodec>;

const CaseCustomerSatisfactionSentiment = union([
    literal("sad"), 
    literal("happy")
]);
export type TCaseCustomerSatisfactionSentimentCodec = typeof CaseCustomerSatisfactionSentiment;
export type TCaseCustomerSatisfactionSentiment = TTypeOfCodec<TCaseCustomerSatisfactionSentimentCodec>;

export const CaseCustomerSatisfactionSentimentAction = intersection([
    required({
        sentiment: CaseCustomerSatisfactionSentiment,
        occurred_at: dateTime(),
    }),
    requiredFlatOverloaded({
        changed_by: union([nullCodec(), UserName]),
    }),
]);
export type TCaseCustomerSatisfactionSentimentActionCodec = typeof CaseCustomerSatisfactionSentimentAction;
export type TCaseCustomerSatisfactionSentimentAction = TTypeOfCodec<TCaseCustomerSatisfactionSentimentActionCodec>;

export const CaseCustomerSatisfactionDeferAction = required({
    id: uuid(),
    occurred_at: dateTime(),
    defer_till: dateTime(),
});
export type TCaseCustomerSatisfactionDeferActionCodec = typeof CaseCustomerSatisfactionDeferAction;
export type TCaseCustomerSatisfactionDeferAction = TTypeOfCodec<TCaseCustomerSatisfactionDeferActionCodec>;

export const CaseCustomerSatisfactionNewNote = required({
    case_id: uuid(),
    note: string(),
    occurred_at: dateTime(),
});
export type TCaseCustomerSatisfactionNewNoteCodec = typeof CaseCustomerSatisfactionNewNote;
export type TCaseCustomerSatisfactionNewNote = TTypeOfCodec<TCaseCustomerSatisfactionNewNoteCodec>;

export const CaseCustomerSatisfactionNewFormalisedAsComplaint = required({
    case_id: uuid(),
    occurred_at: dateTime(),
});
export type TCaseCustomerSatisfactionNewFormalisedAsComplaintCodec = typeof CaseCustomerSatisfactionNewFormalisedAsComplaint;
export type TCaseCustomerSatisfactionNewFormalisedAsComplaint = TTypeOfCodec<TCaseCustomerSatisfactionNewFormalisedAsComplaintCodec>;

export const CaseCustomerSatisfactionNewRaisedWithOmbudsman = required({
    case_id: uuid(),
    occurred_at: dateTime(),
});
export type TCaseCustomerSatisfactionNewRaisedWithOmbudsmanCodec = typeof CaseCustomerSatisfactionNewRaisedWithOmbudsman;
export type TCaseCustomerSatisfactionNewRaisedWithOmbudsman = TTypeOfCodec<TCaseCustomerSatisfactionNewRaisedWithOmbudsmanCodec>;

export const CaseCustomerSatisfactionNewSentimentAction = required({
    case_id: uuid(),
    sentiment: CaseCustomerSatisfactionSentiment,
    occurred_at: dateTime(),
});
export type TCaseCustomerSatisfactionNewSentimentActionCodec = typeof CaseCustomerSatisfactionNewSentimentAction;
export type TCaseCustomerSatisfactionNewSentimentAction = TTypeOfCodec<TCaseCustomerSatisfactionNewSentimentActionCodec>;

export const CaseCustomerSatisfactionNewAssignmentAction = intersection([
    required({
        case_id: uuid(),
        assignee_name: string(),
        assigned_to: uuid(),
        occurred_at: dateTime(),
    }),
]);
export type TCaseCustomerSatisfactionNewAssignmentActionCodec = typeof CaseCustomerSatisfactionNewAssignmentAction;
export type TCaseCustomerSatisfactionNewAssignmentAction = TTypeOfCodec<TCaseCustomerSatisfactionNewAssignmentActionCodec>;

export const CaseCustomerSatisfactionNoteTogglePin = required({
    is_pinned: boolean(),
});
export type TCaseCustomerSatisfactionNoteTogglePinCodec = typeof CaseCustomerSatisfactionNoteTogglePin;
export type TCaseCustomerSatisfactionNoteTogglePin = TTypeOfCodec<TCaseCustomerSatisfactionNoteTogglePinCodec>;
