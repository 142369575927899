import { array, eq } from "fp-ts";
import { TAction } from "../actions/TAction";
import { TChangeRouteAction, TRouteActionType } from "./routerTypes";
import { paths } from "./routerPaths";
import { pipe } from "fp-ts/lib/pipeable";

export const isTRouteActionType = (action: TAction): action is TChangeRouteAction<TRouteActionType> =>
    pipe(
        Object.keys(paths) as Array<TRouteActionType>,
        array.elem(eq.eqString)(action.type)
    )
;