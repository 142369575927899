import React from "react";

export default (props: React.SVGAttributes<SVGElement>) => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g clipPath="url(#clip0_5384:25131)">
<path d="M20.8333 16.375V15.25C20.8333 14.0125 19.7833 13 18.5 13C17.2167 13 16.1667 14.0125 16.1667 15.25V16.375C15.525 16.375 15 16.8812 15 17.5V20.875C15 21.4938 15.525 22 16.1667 22H20.8333C21.475 22 22 21.4938 22 20.875V17.5C22 16.8812 21.475 16.375 20.8333 16.375ZM19.6667 16.375H17.3333V15.25C17.3333 14.6312 17.8583 14.125 18.5 14.125C19.1417 14.125 19.6667 14.6312 19.6667 15.25V16.375Z" fill="black"/>
<path d="M20 5H4C2.9 5 2.01 5.9 2.01 7L2 19C2 20.1 2.9 21 4 21H12V19H4V9L12 14L20 9V12H22V7C22 5.9 21.1 5 20 5ZM12 12L4 7H20L12 12Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_5384:25131">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>
;
