import React from "react";

export default (props: React.SVGAttributes<SVGElement>) => <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g clipPath="url(#clip0_10263_45518)">
<path d="M40.7 8.8H37.4V3.3C37.4 1.48039 35.9196 0 34.1 0H9.9C8.08039 0 6.6 1.48039 6.6 3.3V8.8H3.3C1.48039 8.8 0 10.2804 0 12.1V31.9C0 33.7197 1.48039 35.2 3.3 35.2H6.6V40.7C6.6 42.5196 8.08039 44 9.9 44H34.1C35.9196 44 37.4 42.5196 37.4 40.7V35.2H40.7C42.5197 35.2 44 33.7197 44 31.9V12.1C44 10.2804 42.5197 8.8 40.7 8.8ZM8.8 3.3C8.8 2.69349 9.29349 2.2 9.9 2.2H34.1C34.7065 2.2 35.2 2.69349 35.2 3.3V8.8H8.8V3.3ZM34.1 41.8H9.9C9.29349 41.8 8.8 41.3065 8.8 40.7V26.4H35.2V40.7C35.2 41.3065 34.7065 41.8 34.1 41.8ZM41.8 31.9C41.8 32.5065 41.3065 33 40.7 33H37.4V26.4H38.5C39.1075 26.4 39.6 25.9075 39.6 25.3C39.6 24.6925 39.1075 24.2 38.5 24.2H5.5C4.89253 24.2 4.4 24.6925 4.4 25.3C4.4 25.9075 4.89253 26.4 5.5 26.4H6.6V33H3.3C2.69349 33 2.2 32.5065 2.2 31.9V12.1C2.2 11.4935 2.69349 11 3.3 11H40.7C41.3065 11 41.8 11.4935 41.8 12.1V31.9Z" fill="black"/>
<path d="M31.9 30.8001H12.1C11.4925 30.8001 11 30.3076 11 29.7001C11 29.0926 11.4925 28.6001 12.1 28.6001H31.9C32.5075 28.6001 33 29.0926 33 29.7001C33 30.3076 32.5075 30.8001 31.9 30.8001Z" fill="black"/>
<path d="M31.9 35.2H12.1C11.4925 35.2 11 34.7075 11 34.1C11 33.4925 11.4925 33 12.1 33H31.9C32.5075 33 33 33.4925 33 34.1C33 34.7075 32.5075 35.2 31.9 35.2Z" fill="black"/>
<path d="M31.9 39.5999H12.1C11.4925 39.5999 11 39.1074 11 38.4999C11 37.8924 11.4925 37.3999 12.1 37.3999H31.9C32.5075 37.3999 33 37.8924 33 38.4999C33 39.1074 32.5075 39.5999 31.9 39.5999Z" fill="black"/>
<path d="M36.3 19.8C34.4803 19.8 33 18.3196 33 16.5C33 14.6803 34.4803 13.2 36.3 13.2C38.1197 13.2 39.6 14.6803 39.6 16.5C39.6 18.3196 38.1197 19.8 36.3 19.8ZM36.3 15.4C35.6935 15.4 35.2 15.8934 35.2 16.5C35.2 17.1065 35.6935 17.6 36.3 17.6C36.9065 17.6 37.4 17.1065 37.4 16.5C37.4 15.8934 36.9065 15.4 36.3 15.4Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_10263_45518">
<rect width="44" height="44" fill="white" transform="matrix(0 1 -1 0 44 0)"/>
</clipPath>
</defs>
</svg>
;
