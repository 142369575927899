import { TTypeOfCodec } from "../../shared/src/codecs/codec";
import { dateTime } from "../../shared/src/codecs/types/dateTime";
import { literal } from "../../shared/src/codecs/types/literal";
import { nullCodec } from "../../shared/src/codecs/types/nullCodec";
import { required } from "../../shared/src/codecs/types/required";
import { string } from "../../shared/src/codecs/types/string";
import { union } from "../../shared/src/codecs/types/union";
import { uuid } from "../../shared/src/codecs/types/uuid";

export const TransferCompanyType = union([
    literal('our_side'),
    literal('other_side'),
]);
export type TTransferCompanyTypeCodec = typeof TransferCompanyType;
export type TTransferCompanyType = TTypeOfCodec<TTransferCompanyTypeCodec>;

export const TransferRecordType = union([
    literal('transferor'),
    literal('transferee'),
    literal('second_trustee'),
    literal('last_deceased_for_as1')
]);
export type TTransferRecordTypeCodec = typeof TransferRecordType;
export type TTransferRecordType = TTypeOfCodec<TTransferRecordTypeCodec>;

export const TransferSetupRecordWithUserNames = required({
    record_type: TransferRecordType,
    text_to_append: string(),
    user_case_id: union([nullCodec(), uuid()]),
    other_side_user_case_id: union([nullCodec(), uuid()]),
    company_type: union([TransferCompanyType, nullCodec()]),
    legal_first_legal_name: union([nullCodec(), string()]),
    legal_middle_legal_name: union([nullCodec(), string()]),
    legal_last_legal_name: union([nullCodec(), string()]),
    created_at: dateTime(),
});
export type TTransferSetupRecordWithUserNamesCodec = typeof TransferSetupRecordWithUserNames;
export type TTransferSetupRecordWithUserNames = TTypeOfCodec<TTransferSetupRecordWithUserNamesCodec>;

export const TransferSetupRecord = required({
    id: uuid(),
    record_type: TransferRecordType,
    text_to_append: string(),
    user_case_id: union([nullCodec(), uuid()]),
    other_side_user_case_id: union([nullCodec(), uuid()]),
    company_type: union([TransferCompanyType, nullCodec()]),
});
export type TTransferSetupRecordCodec = typeof TransferSetupRecord;
export type TTransferSetupRecord = TTypeOfCodec<TTransferSetupRecordCodec>;