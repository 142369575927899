export class AllParams {
    listingId: string = "";
    demoParam: string = "";
    userId: string = "";
    buyersGroupId: string = "";
    partyId: string = "";
    enquiryId: string = "";
    quoteId: string = "";
    caseId: string = "";
}

export type TParam<T extends string> = {
    param: T;
};

export const param = <T extends keyof AllParams> (p: T): TParam<T> => ({
    param: p,
});