import * as t from "io-ts";

export const validationErrorCodeConstants = {
    REGEX_VALIDATION: "RegexValidation" as const,
    STRING_VALIDATION: "StringValidation" as const,
    NON_EMPTY_STRING_VALIDATION: "NonEmptyStringValidation" as const,
    UTC_DATETIME_VALIDATION: "UtcDatetimeValidation" as const,
    UTC_DATE_VALIDATION: "UtcDateValidation" as const,
    FUTURE_DATETIME_VALIDATION: "FutureDatetimeValidation" as const,
    REQUIRED_VALIDATION: "RequiredValidation" as const,
    REQUIRED_CONDITIONALLY_VALIDATION: "RequiredConditionallyValidation" as const,
    OBJECT_VALIDATION: "ObjectValidation" as const,
    UNION_OF_OBJECTS_VALIDATION: "UnionOfObjectsValidation" as const,
    RANGE_VALIDATION: "RangeValidation" as const,
    ARRAY_VALIDATION: "ArrayValidation" as const,
    ARRAY_LENGTH_VALIDATION: "ArrayLengthValidation" as const,
    ARRAY_NOT_UNIQUE_VALIDATION: "ArrayNotUniqueValidation" as const,
    NON_EMPTY_ARRAY_VALIDATION: "NonEmptyArrayValidation" as const,
    ENUM_VALIDATION: "EnumValidation" as const,
    UNION_VALIDATION: "UnionValidation" as const,
    INTERSECTION_VALIDATION: "IntersectionValidation" as const,
    BOOLEAN_VALIDATION: "BooleanValidation" as const,
    DECIMAL_VALIDATION: "DecimalValidation" as const,
    INTEGER_VALIDATION: "IntegerValidation" as const,
    INTEGER_POSITIVE_VALIDATION: "IntegerPositiveValidation" as const,
    IS_STRICTLY_EQUAL_TO_VALUE_VALIDATION: "IsStrictlyEqualToValueValidation" as const,
    IS_STRICTLY_EQUAL_TO_TYPE_VALIDATION: "IsStrictlyEqualToTypeValidation" as const,
    CREDENTIALS_VALIDATION: "CredentialsValidation" as const,
    UUID_VALIDATION: "UuidValidation" as const,
    FILE_DOES_NOT_MATCH_CONTENT_TYPE: "FileDoesNotMatchContentType" as const,
    FILE_TOO_LARGE: "FileTooLarge" as const,
    INTEGER_LIKE_STRING_VALIDATION: "IntegerLikeStringValidation" as const,
    OBJECT_NOT_ONE_OF_EXPECTED_VALIDATION: "ObjectNotOneOfExpectedValidation" as const,
    NOT_UNIQUE: "NotUnique" as const,
    NULL_VALIDATION: "NullValidation" as const,
    POSITIVE_DECIMAL_VALIDATION: "PositiveDecimalValidation" as const,
    NO_PRIMARY_PHONE_NUMBER_SET_VALIDATION: "NoPrimaryPhoneNumberSetValidation" as const,
    LISTING_ALREADY_HAS_AN_ACCEPTED_OFFER: "ListingAlreadyHasAnOfferAcceptedValidation" as const,
    NO_USER_WITH_EMAIL_ADDRESS_VALIDATION: "NoUserWithEmailAddressValidation" as const,
    NOTHING_TO_SEND_VALIDATION: "NothingToSendValidation" as const,
    SENDERS_EMAIL_NOT_SAILHOMES_VALIDATION: "SendersEmailNotSailHomesValidation" as const,
    GDPR_TERMS_NOT_AGREED: "GdprTermsNotAgreed" as const,
    INTEREST_ALREADY_EXPRESSED: "InterestAlreadyExpressed" as const,
    DOCUMENT_DOES_NOT_EXIST_IN_CASE: "DocumentDoesNotExistInCase" as const,
    CASE_ENQUIRY_NOT_IN_UNSUBMITTED_STATUS: "CaseEnquiryNotInUnsubmittedStatus" as const,
    LITERAL_VALIDATION: "LiteralValidation" as const,
    CASE_STATE_PREVENTS_CHANGE: "CaseStatePreventsChange" as const,
    NOT_AUTHORIZED_FOR_DUAL_REP_CASE: "NotAuthorizedForDualRepCase" as const,
    CHAT_COMMAND_MORE_THAN_ONE_NOT_SUPPORTED: "ChatCommandMoreThanOneNotSupported" as const,
    CHAT_COMMAND_INVALID: "ChatCommandInvalid" as const,
    CHAT_COMMAND_ASSIGNEE_NOT_FOUND: "ChatCommandAssigneeNotFound" as const,
    CHAT_COMMAND_AWAITABLE_ENTITY_NOT_FOUND: "ChatCommandAwaitableEntityNotFound" as const,
    NO_RESULTS: "NoResults" as const,
};

export const codec = t.union([
    t.literal(validationErrorCodeConstants.REGEX_VALIDATION),
    t.literal(validationErrorCodeConstants.STRING_VALIDATION),
    t.literal(validationErrorCodeConstants.NON_EMPTY_STRING_VALIDATION),
    t.literal(validationErrorCodeConstants.UTC_DATETIME_VALIDATION),
    t.literal(validationErrorCodeConstants.UTC_DATE_VALIDATION),
    t.literal(validationErrorCodeConstants.FUTURE_DATETIME_VALIDATION),
    t.literal(validationErrorCodeConstants.REQUIRED_VALIDATION),
    t.literal(validationErrorCodeConstants.REQUIRED_CONDITIONALLY_VALIDATION),
    t.literal(validationErrorCodeConstants.OBJECT_VALIDATION),
    t.literal(validationErrorCodeConstants.UNION_OF_OBJECTS_VALIDATION),
    t.literal(validationErrorCodeConstants.RANGE_VALIDATION),
    t.literal(validationErrorCodeConstants.ARRAY_VALIDATION),
    t.literal(validationErrorCodeConstants.ARRAY_LENGTH_VALIDATION),
    t.literal(validationErrorCodeConstants.ARRAY_NOT_UNIQUE_VALIDATION),
    t.literal(validationErrorCodeConstants.NON_EMPTY_ARRAY_VALIDATION),
    t.literal(validationErrorCodeConstants.ENUM_VALIDATION),
    t.literal(validationErrorCodeConstants.UNION_VALIDATION),
    t.literal(validationErrorCodeConstants.BOOLEAN_VALIDATION),
    t.literal(validationErrorCodeConstants.DECIMAL_VALIDATION),
    t.literal(validationErrorCodeConstants.INTEGER_VALIDATION),
    t.literal(validationErrorCodeConstants.INTEGER_POSITIVE_VALIDATION),
    t.literal(validationErrorCodeConstants.IS_STRICTLY_EQUAL_TO_VALUE_VALIDATION),
    t.literal(validationErrorCodeConstants.IS_STRICTLY_EQUAL_TO_TYPE_VALIDATION),
    t.literal(validationErrorCodeConstants.CREDENTIALS_VALIDATION),
    t.literal(validationErrorCodeConstants.UUID_VALIDATION),
    t.literal(validationErrorCodeConstants.FILE_DOES_NOT_MATCH_CONTENT_TYPE),
    t.literal(validationErrorCodeConstants.FILE_TOO_LARGE),
    t.literal(validationErrorCodeConstants.INTEGER_LIKE_STRING_VALIDATION),
    t.literal(validationErrorCodeConstants.OBJECT_NOT_ONE_OF_EXPECTED_VALIDATION),
    t.literal(validationErrorCodeConstants.NOT_UNIQUE),
    t.literal(validationErrorCodeConstants.NULL_VALIDATION),
    t.literal(validationErrorCodeConstants.POSITIVE_DECIMAL_VALIDATION),
    t.literal(validationErrorCodeConstants.NO_PRIMARY_PHONE_NUMBER_SET_VALIDATION),
    t.literal(validationErrorCodeConstants.LISTING_ALREADY_HAS_AN_ACCEPTED_OFFER),
    t.literal(validationErrorCodeConstants.NO_USER_WITH_EMAIL_ADDRESS_VALIDATION),
    t.literal(validationErrorCodeConstants.NOTHING_TO_SEND_VALIDATION),
    t.literal(validationErrorCodeConstants.INTEREST_ALREADY_EXPRESSED),
    t.literal(validationErrorCodeConstants.GDPR_TERMS_NOT_AGREED),
    t.literal(validationErrorCodeConstants.SENDERS_EMAIL_NOT_SAILHOMES_VALIDATION),
    t.literal(validationErrorCodeConstants.DOCUMENT_DOES_NOT_EXIST_IN_CASE),
    t.literal(validationErrorCodeConstants.CASE_ENQUIRY_NOT_IN_UNSUBMITTED_STATUS),
    t.literal(validationErrorCodeConstants.INTERSECTION_VALIDATION),
    t.literal(validationErrorCodeConstants.LITERAL_VALIDATION),
    t.literal(validationErrorCodeConstants.CASE_STATE_PREVENTS_CHANGE),
    t.literal(validationErrorCodeConstants.NOT_AUTHORIZED_FOR_DUAL_REP_CASE),
    t.literal(validationErrorCodeConstants.CHAT_COMMAND_MORE_THAN_ONE_NOT_SUPPORTED),
    t.literal(validationErrorCodeConstants.CHAT_COMMAND_INVALID),
    t.literal(validationErrorCodeConstants.CHAT_COMMAND_ASSIGNEE_NOT_FOUND),
    t.literal(validationErrorCodeConstants.CHAT_COMMAND_AWAITABLE_ENTITY_NOT_FOUND),
    t.literal(validationErrorCodeConstants.NO_RESULTS),
]);

export type T = t.TypeOf<typeof codec>;
