import { TChangeRouteAction, TParamsObject, TPathPart, TRouteActionType } from "./routerTypes";
import { TPaths } from "./routerPaths";

import { array, record, option, either, apply, tuple } from "fp-ts/lib";
import * as errors from "../../errors";
import { contramap } from "fp-ts/lib/Ord";
import { Ord } from "fp-ts/lib/number";
import { pipe } from "fp-ts/lib/function";
import * as JSONCrush from "../../JSONCrush";
import { getQueryParams } from "./getQueryParams";

export const createActionFromUrl = (
    p: TPaths, 
    locationPathname: string, 
    locationSearch: string
): TChangeRouteAction<TRouteActionType> =>
    pipe(
        p,
        record.filterMapWithIndex((key, val) =>
            pipe(
                // Match url against regex generated from the path array
                // e.g.
                // ["listings", param("listingId"), "bookings", param("bookingId")] becomes `^/?listings/([^/]*)/bookings/([^/]*)$`
                array.map((part) => typeof part === "string" ? part : "([^/]*)")(val.pattern).join("/"),
                (regex) => (new RegExp(`^//?${regex}$`).exec(locationPathname)),
                (match) =>
                    match
                    ? either.right(match)
                    : either.left(errors.Error.create(errors.ErrorCode.constants.REGEX_DOES_NOT_MATCH)),
                    either.chain((match) =>
                    {
                        const queryParams = getQueryParams(locationSearch);
                        return apply.sequenceT(either.either)(
                            // Generate params object
                            either.right<errors.Error.T, Record<string, string>>(
                                pipe(
                                    // Filter the route pattern down to only params
                                    array.filterMap<TPathPart<string>, string>(
                                        (part) => typeof part === "string"
                                            ? option.none
                                            : option.some(part.param)
                                    )(val.pattern),
                                    // Build up action params object by zipping together the url params and the pattern params
                                    array.reduceRightWithIndex(
                                        {} as Record<string, string>,
                                        (k, v, ac) => {
                                            try {
                                                ac[v] = match[k + 1];
                                            } catch (e) {
                                                return ac;
                                            }
                                            return ac;
                                        }
                                    )
                                )
                            ),
                            // Generate query string object using its codec
                            pipe(
                                val.queryParamsCodec.decode(queryParams),
                                either.mapLeft(() => errors.Error.create(errors.ErrorCode.constants.CODEC_DECODE))
                            ),
                            // Generate blocks object using its codec
                            ("blocks" in queryParams) && ("blocksCodec" in val) ? pipe(
                                queryParams.blocks,
                                JSONCrush.uncrush,
                                JSON.parse,
                                val.blocksCodec.decode,
                                either.mapLeft(() => errors.Error.create(errors.ErrorCode.constants.CODEC_DECODE))
                            ) : either.right(undefined),
                        );
                    }
                ),
                either.fold(
                    () => option.none,
                    ([params, queryStringParams, blockParams]) => 
                        option.some<TChangeRouteAction<TRouteActionType>>({
                            type: key,
                            params,
                            queryStringParams,
                            blockParams,
                        })
                ),
            ),
        ),
        (v) => record.toArray<keyof TPaths, TChangeRouteAction<TRouteActionType>>(v),
        // Order the matched routes by ones that do not use parameters first as these will be the exact matches in the route definition.
        // The best matching route will be returned first.
        array.sortBy([
            contramap((pair: [keyof TPaths, TChangeRouteAction<TRouteActionType>]) =>
                pipe(
                    p[pair[0]].pattern,
                    array.filter<TPathPart<keyof TParamsObject<TRouteActionType>>>((paramItem) => typeof paramItem !== "string"),
                    (a) => a.length,
                )
            )(Ord)
        ]),
        array.head,
        option.fold(
            // If no match, match to "VIEW_NOT_FOUND"
            () => ({
                type: "VIEW_NOT_FOUND",
                params: {},
                queryStringParams: {},
            } as TChangeRouteAction<"VIEW_NOT_FOUND">),
            tuple.snd,
        ),
    );