import React from "react";

type TCRMPadding = {
    size: "tiny" | "medium" | "large";
    direction?: "left" | "right";
};

export class CRMPadding extends React.Component<React.PropsWithChildren<TCRMPadding>> {
    public render (): JSX.Element {
        return (
            <div
                className={`
                    crm-padding
                    crm-padding--${this.props.direction || "all"}-${this.props.size}
                `}
            >
                {this.props.children}
            </div>
        );
    }
}
