import * as Function from "../Function";
import * as Error from "../Error";
import * as ErrorCode from "../ErrorCode";
import { option, eq, array } from "fp-ts/lib";

export const isEnum = <T extends string>(enums: Array<T>): Function.TValidationFunction =>
    (value: unknown): option.Option<Error.TValidationError> =>
        typeof value === "undefined" || (
            typeof value === "string" && array.elem(eq.eqString)(value, enums)
        )
            ? option.none
            : option.some([[ErrorCode.validationErrorCodeConstants.ENUM_VALIDATION]]);
