import React from "react";
import ProbatePanelEnquiryPage from "../pages/ProbatePanelEnquiryPage/ProbatePanelEnquiryPage";
import ProbatePanelQuotePage from "../pages/ProbatePanelQuotePage/ProbatePanelQuotePage";
import ProbatePanelGDPRPage from "../pages/ProbatePanelGDPRPage/ProbatePanelGDPRPage";
import ProbatePanelTnCsPage from "../pages/ProbatePanelTnCsPage/ProbatePanelTnCsPage";
import ProbatePanelInterestedInPage from "../pages/ProbatePanelInterestedInPage/ProbatePanelInterestedInPage";
import NotFoundPage from "../pages/NotFoundPage/NotFoundPage";
import * as TContainerStateProps from "../state/TContainerStateProps";

export const ProbateAppContainer = (props: TContainerStateProps.TContainerStateProps): JSX.Element => {
    return (() => (
        <div>
            { (() => {
                switch (props.state.routes.active) {
                    case "VIEW_NOT_FOUND":
                        return <NotFoundPage
                            state={props.state}
                            dispatch={props.dispatch}
                        />;
                    case "VIEW_PROBATE_PANEL_ENQUIRY_PAGE":
                        return <ProbatePanelEnquiryPage
                            state={props.state}
                            dispatch={props.dispatch}
                        />;
                    case "VIEW_PROBATE_QUOTE_PAGE":
                        return <ProbatePanelQuotePage
                            state={props.state}
                            dispatch={props.dispatch}
                        />;
                    case "VIEW_PROBATE_INTERESTED_PAGE":
                        return <ProbatePanelInterestedInPage
                            state={props.state}
                            dispatch={props.dispatch}
                        />;
                    case "VIEW_PROBATE_GDPR_PAGE":
                        return <ProbatePanelGDPRPage
                            state={props.state}
                            dispatch={props.dispatch}
                        />;
                    case "VIEW_PROBATE_TNCS_PAGE":
                        return <ProbatePanelTnCsPage
                            state={props.state}
                            dispatch={props.dispatch}
                        />;
                    default:
                        return <div></div>;
                }
            })() }
        </div>
    ))();
};

