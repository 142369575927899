import { TTypeOfCodec } from "../../../shared/src/codecs/codec";
import { form, TFormCodec } from "../../../shared/src/codecs/types/form";
import { CasePropertyFull, CasePropertyTenureAndTitleNumber, TCasePropertyFullCodec } from "../CaseProperty";
import { requiredFlatOverloaded, TRequiredFlatOverloadedCodec } from "../../../shared/src/codecs/types/requiredFlatOverloaded";
import { Address2, TAddress2Codec } from "../Address";
import { array, TArrayCodec } from "../../../shared/src/codecs/types/array";
import { TCaseDualRepSearchResultsFormCodec, CaseDualRepSearchResultsForm } from "./CaseForm";
import { intersection, TIntersectionCodec } from "../../../shared/src/codecs/types/intersection";
import { required, TRequiredCodec } from "../../../shared/src/codecs/types/required";
import { string } from "../../../shared/src/codecs/types/string";
import { uuid } from "../../../shared/src/codecs/types/uuid";
import { TUuidFormCodec, UuidForm } from "./UuidForm";
import { boolean, TBooleanCodec } from "../../../shared/src/codecs/types/boolean";

const LandRegistryTitleSuggestion = intersection([
    CasePropertyTenureAndTitleNumber,
    required({
        id: uuid(),
        address: string(),
    })
]);
type TLandRegistryTitleSuggestionCodec = typeof LandRegistryTitleSuggestion;
export type TLandRegistryTitleSuggestion = TTypeOfCodec<TLandRegistryTitleSuggestionCodec>;

export const CasePropertyForm: TFormCodec<
    TCasePropertyFullCodec,
    TIntersectionCodec<[
        TRequiredFlatOverloadedCodec<{
            address_short_string: TAddress2Codec,
        }>,
        TRequiredFlatOverloadedCodec<{
            dual_rep_suggestion_forms: TArrayCodec<TCaseDualRepSearchResultsFormCodec>,
            land_registry_title_suggestions: TArrayCodec<TLandRegistryTitleSuggestionCodec>;
        }>,
        TRequiredCodec<{
            order_register_form: TUuidFormCodec,
            valuation: TRequiredCodec<{
                is_listing_created: TBooleanCodec,
                create_listing_form: TUuidFormCodec,
            }>
        }>
    ]>
> = 
    form(
        CasePropertyFull,
        intersection([
            requiredFlatOverloaded({
                address_short_string: Address2,
            }),
            requiredFlatOverloaded({
                dual_rep_suggestion_forms: array(CaseDualRepSearchResultsForm),
                land_registry_title_suggestions: array(LandRegistryTitleSuggestion),
            }),
            required({
                order_register_form: UuidForm,
                valuation: required({
                    is_listing_created: boolean(),
                    create_listing_form: UuidForm,
                })
            }),
        ])
    );

export type TCasePropertyFormCodec = typeof CasePropertyForm;
export type TCasePropertyForm = TTypeOfCodec<TCasePropertyFormCodec>;