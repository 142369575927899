import { TTypeOfCodec } from "../../../shared/src/codecs/codec";
import { intersection, TIntersectionCodec } from "../../../shared/src/codecs/types/intersection";
import { required, TRequiredCodec } from "../../../shared/src/codecs/types/required";
import { TUser20Codec, TUser4Codec, User20, User4 } from "../User";
import { array, TArrayCodec } from "../../../shared/src/codecs/types/array";
import { PhoneNumberForm, TPhoneNumberFormCodec } from "./PhoneNumberForm";
import { form, TFormCodec } from "../../../shared/src/codecs/types/form";
import { TUserCase1Codec, TUserCase3Codec, TUserCase4Codec, UserCase1, UserCase3, UserCase4 } from "../UserCase";
import { TUuidCodec, uuid } from "../../../shared/src/codecs/types/uuid";
import { EmptyObject, TEmptyObjectCodec } from "../EmptyObject";
import { TUnionCodec, union } from "../../../shared/src/codecs/types/union";
import { date, TDateCodec } from "../../../shared/src/codecs/types/date";
import { nullCodec, TNullCodec } from "../../../shared/src/codecs/types/nullCodec";
import { TUsersIdCheck3Codec, TUsersIdCheck4Codec, UsersIdCheck3, UsersIdCheck4 } from "../UsersIdCheck";

const CaseSendInstructedEmailFormPayload = required({
    case_id: uuid(),
    user_id: uuid(),
});
type TCaseSendInstructedEmailFormPayloadCodec = typeof CaseSendInstructedEmailFormPayload;

export const CaseSendInstructedEmailForm: TFormCodec<TCaseSendInstructedEmailFormPayloadCodec, TEmptyObjectCodec> = form(
    CaseSendInstructedEmailFormPayload,
    EmptyObject,
);
export type TCaseSendInstructedEmailFormCodec = typeof CaseSendInstructedEmailForm;
export type TCaseSendInstructedEmailForm = TTypeOfCodec<TCaseSendInstructedEmailFormCodec>;

export const CaseMemberIdCheckForm: TFormCodec<TUsersIdCheck3Codec, TUsersIdCheck4Codec> = form(
    UsersIdCheck3,
    UsersIdCheck4,
);
export type TCaseMemberIdCheckFormCodec = typeof CaseMemberIdCheckForm;
export type TCaseMemberIdCheckForm = TTypeOfCodec<TCaseMemberIdCheckFormCodec>;

export const CaseMemberCreateIdCheckForm: TFormCodec<TUserCase4Codec, TEmptyObjectCodec> = form(
    UserCase4,
    EmptyObject,
);
export type TCaseMemberCreateIdCheckFormCodec = typeof CaseMemberCreateIdCheckForm;
export type TCaseMemberCreateIdCheckForm = TTypeOfCodec<TCaseMemberCreateIdCheckFormCodec>;

export const CaseMemberSuggestedSimilarUserForm: TFormCodec<TRequiredCodec<{
    swap_from_user_id: TUuidCodec,
    swap_to_user_id: TUuidCodec,
    case_id: TUuidCodec,
}>, TUser20Codec> = form(
    required({
        swap_from_user_id: uuid(),
        swap_to_user_id: uuid(),
        case_id: uuid(),
    }),
    User20,
);
export type TCaseMemberSuggestedSimilarUserFormCodec = typeof CaseMemberSuggestedSimilarUserForm;
export type TCaseMemberSuggestedSimilarUserForm = TTypeOfCodec<TCaseMemberSuggestedSimilarUserFormCodec>;

export const CaseMemberChildrenForm: TFormCodec<
    TUserCase1Codec,
    TIntersectionCodec<[
        TRequiredCodec<{
            send_instructed_email: TCaseSendInstructedEmailFormCodec;
            id_checks: TArrayCodec<TCaseMemberIdCheckFormCodec>;
            create_id_check: TCaseMemberCreateIdCheckFormCodec;
            suggested_similar_users: TArrayCodec<TCaseMemberSuggestedSimilarUserFormCodec>;
        }>,
        TUserCase3Codec,
        TUserCase4Codec,
    ]>
> = form(
    UserCase1,
    intersection([
        required({
            send_instructed_email: CaseSendInstructedEmailForm,
            id_checks: array(CaseMemberIdCheckForm),
            create_id_check: CaseMemberCreateIdCheckForm,
            suggested_similar_users: array(CaseMemberSuggestedSimilarUserForm),
        }),
        UserCase3,
        UserCase4,
    ]),
);
export type TCaseMemberChildrenFormCodec = typeof CaseMemberChildrenForm;
export type TCaseMemberChildrenForm = TTypeOfCodec<TCaseMemberChildrenFormCodec>;

export const CaseMemberForm: TFormCodec<
    TIntersectionCodec<[
        TUser4Codec,
        TRequiredCodec<{
            phone_numbers: TArrayCodec<TPhoneNumberFormCodec>;
            legal_date_of_birth: TUnionCodec<[TDateCodec, TNullCodec]>;
        }>
]>,
    TCaseMemberChildrenFormCodec
> =
    form(
        intersection([
            User4,
            required({
                phone_numbers: array(PhoneNumberForm),
                legal_date_of_birth: union([date(), nullCodec()]),
            })
        ]),
        CaseMemberChildrenForm,
    );

export type TCaseMemberFormCodec = typeof CaseMemberForm;
export type TCaseMemberForm = TTypeOfCodec<TCaseMemberFormCodec>;