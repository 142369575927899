import * as Function from "../Function";
import * as Error from "../Error";
import * as ErrorCode from "../ErrorCode";
import { option } from "fp-ts/lib";
import { string } from "./string";

export const nonEmptyString: Function.TValidationFunction = (value: unknown): option.Option<Error.TValidationError> =>
    option.fold<Error.TValidationError, option.Option<Error.TValidationError>>(
        () => typeof value === "string" && value === ""
            ? option.some([[ErrorCode.validationErrorCodeConstants.NON_EMPTY_STRING_VALIDATION]])
            : option.none,
        (e) => option.some(e),
    )(string(value));
