import { TTypeOfCodec } from "../../../shared/src/codecs/codec";
import { formIO, TFormIOCodec } from "../../../shared/src/codecs/types/formIO";
import { required, TRequiredCodec } from "../../../shared/src/codecs/types/required";
import { TAnyUuidCodec, uuid } from "../../../shared/src/codecs/types/uuid";

export const RemoveUserNumberFormIO: TFormIOCodec<
    TRequiredCodec<{
        user_id: TAnyUuidCodec;
        number_id: TAnyUuidCodec;
    }>,
    TRequiredCodec<{
        user_id: TAnyUuidCodec;
        number_id: TAnyUuidCodec;
    }>
> = formIO(
    required({
        user_id: uuid(),
        number_id: uuid(),
    }),
    required({
        user_id: uuid(),
        number_id: uuid(),
    }),
);

export type TRemoveUserNumberFormIOCodec = typeof RemoveUserNumberFormIO;

export type TRemoveUserNumberFormIO = TTypeOfCodec<TRemoveUserNumberFormIOCodec>;