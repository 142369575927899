import { TTypeOfNewDefault } from "../../shared/src/codecs/codec";
import { array } from "../../shared/src/codecs/types/array";
import { dateTimeOrNullAsBoolean } from "../../shared/src/codecs/types/dateTimeOrNullAsBoolean";
import { intersection } from "../../shared/src/codecs/types/intersection";
import { required } from "../../shared/src/codecs/types/required";
import { requiredFlatOverloaded } from "../../shared/src/codecs/types/requiredFlatOverloaded";
import { string } from "../../shared/src/codecs/types/string";
import { stringCapped } from "../../shared/src/codecs/types/stringCapped";
import { uuid } from "../../shared/src/codecs/types/uuid";
import { CasesBankAccountVerificationStatus1, CasesBankAccountVerificationStatus2 } from "./CasesBankAccountVerificationStatus";
import { User17 } from "./User";
import { CasesBankAccountSortCodeHyphenated } from "./overloads/CasesBankAccountSortCodeHyphenated";
import { RecipientsFullLegalNames } from "./overloads/RecipientsFullLegalNames";

export const CasesBankAccount1 = required({
    id: uuid(),
    recipient_users__cases_ids: array(uuid()),
    bank_name: string(),
    account_number: string(),
    sort_code: stringCapped(6),
    verification_status: CasesBankAccountVerificationStatus1,
});
export type TCasesBankAccountCodec = typeof CasesBankAccount1;
export type TCasesBankAccount1NewDefault = TTypeOfNewDefault<typeof CasesBankAccount1>;

export const CasesBankAccount2 = required({
    id: uuid(),
    recipient_users__cases_ids: array(uuid()),
    bank_name: string(),
    encrypted_account_number: string(),
    encrypted_sort_code: string(),
    verification_status: CasesBankAccountVerificationStatus1,
});
export type TCasesBankAccount2NewDefault = TTypeOfNewDefault<typeof CasesBankAccount2>;

export const CasesBankAccountVerificationSteps = required({
    bank_verification_check_url: string(),
    all_clients_agree_with_this_bank_account: dateTimeOrNullAsBoolean(),
    verification_status: CasesBankAccountVerificationStatus2,
});

export const CasesBankAccount3 = intersection([
    required({
        id: uuid(),
    }),
    CasesBankAccountVerificationSteps,
]);
export type TCasesBankAccount3Codec = typeof CasesBankAccount3;

export const CasesBankAccount4 = intersection([
    required({
        bank_name: string(),
        account_number: string(),
    }),
    requiredFlatOverloaded({
        recipient_full_legal_names: RecipientsFullLegalNames,
    }),
    requiredFlatOverloaded({
        sort_code_hyphenated: CasesBankAccountSortCodeHyphenated,
    }),
]);
export type TCasesBankAccount4Codec = typeof CasesBankAccount4;

export const CasesBankAccount5 = required({
    id: uuid(),
    bank_verification_check_url: string(),
    all_clients_agree_with_this_bank_account: dateTimeOrNullAsBoolean(),
    verification_status: CasesBankAccountVerificationStatus2,
    bank_name: string(),
    encrypted_account_number: string(),
    encrypted_sort_code: string(),
    recipient_users: array(User17),
});