import { TCodec } from "../codec";
import { either } from "fp-ts/lib";
import { errorConstants, TError } from "../errors";
import * as regexes from "../../regexes";

export type TAnyPasswordCodec = TCodec<
    "PasswordCodec",
    undefined,
    string,
    string
>;

const decode = (input: unknown): either.Either<TError, string> =>
    typeof input === "string"
    && (new RegExp(regexes.constants.PASSWORD_REGEX)).test(input)
        ? either.right(input)
        : either.left([[errorConstants.REGEX_VALIDATION, ""]]);

const decodeNewDefault = (input: unknown): either.Either<TError, string> =>
    typeof input === "string"
        ? either.right(input)
        : either.left([[errorConstants.REGEX_VALIDATION, ""]]);

export const password = (): TAnyPasswordCodec => ({
    type: "PasswordCodec",
    payload: undefined,
    decode,
    decodeNewDefault,
    newDefault: () => "",
});