import { boolean } from "../../shared/src/codecs/types/boolean";
import { integer } from "../../shared/src/codecs/types/integer";
import { nullCodec } from "../../shared/src/codecs/types/nullCodec";
import { overload } from "../../shared/src/codecs/types/overload";
import { required } from "../../shared/src/codecs/types/required";
import { union } from "../../shared/src/codecs/types/union";

export const HasProvidedProofOfFundsForOffer = overload(
    boolean(),
    required({
        funds_cash_verified_upto: union([integer(), nullCodec()]),
        funds_dip_verified_upto: union([integer(), nullCodec()]),
        amount: integer(),
    }),
    (details) =>
        (
            (details.funds_cash_verified_upto !== null
            || details.funds_dip_verified_upto !== null)
        )
        && (
            (details.funds_cash_verified_upto || 0) + (details.funds_dip_verified_upto || 0)
            >= details.amount
        ),
);