import { array, option } from "fp-ts";
import { fromEquals } from "fp-ts/lib/Eq";
import { pipe } from "fp-ts/lib/pipeable";

export const errorConstants = {
    REGEX_VALIDATION: "RegexValidation" as const,
    STRING_VALIDATION: "StringValidation" as const,
    STRING_ENCRYPTED_VALIDATION: "StringEncryptedValidation" as const,
    NON_EMPTY_STRING_VALIDATION: "NonEmptyStringValidation" as const,
    UTC_DATETIME_VALIDATION: "UtcDatetimeValidation" as const,
    UTC_DATE_VALIDATION: "UtcDateValidation" as const,
    FUTURE_DATETIME_VALIDATION: "FutureDatetimeValidation" as const,
    REQUIRED_VALIDATION: "RequiredValidation" as const,
    REQUIRED_CONDITIONALLY_VALIDATION: "RequiredConditionallyValidation" as const,
    OBJECT_VALIDATION: "ObjectValidation" as const,
    UNION_OF_OBJECTS_VALIDATION: "UnionOfObjectsValidation" as const,
    RANGE_VALIDATION: "RangeValidation" as const,
    ARRAY_VALIDATION: "ArrayValidation" as const,
    TUPLE_VALIDATION: "TupleValidation" as const,
    ARRAY_LENGTH_VALIDATION: "ArrayLengthValidation" as const,
    ARRAY_NOT_UNIQUE_VALIDATION: "ArrayNotUniqueValidation" as const,
    NON_EMPTY_ARRAY_VALIDATION: "NonEmptyArrayValidation" as const,
    ENUM_VALIDATION: "EnumValidation" as const,
    UNION_VALIDATION: "UnionValidation" as const,
    INTERSECTION_VALIDATION: "IntersectionValidation" as const,
    BOOLEAN_VALIDATION: "BooleanValidation" as const,
    TRUE_VALIDATION: "TrueValidation" as const,
    FALSE_VALIDATION: "FalseValidation" as const,
    DECIMAL_VALIDATION: "DecimalValidation" as const,
    INTEGER_VALIDATION: "IntegerValidation" as const,
    FILE_VALIDATION: "FileValidation" as const,
    INTEGER_POSITIVE_VALIDATION: "IntegerPositiveValidation" as const,
    IS_STRICTLY_EQUAL_TO_VALUE_VALIDATION: "IsStrictlyEqualToValueValidation" as const,
    IS_STRICTLY_EQUAL_TO_TYPE_VALIDATION: "IsStrictlyEqualToTypeValidation" as const,
    CREDENTIALS_VALIDATION: "CredentialsValidation" as const,
    UUID_VALIDATION: "UuidValidation" as const,
    FILE_DOES_NOT_MATCH_CONTENT_TYPE: "FileDoesNotMatchContentType" as const,
    FILE_TOO_LARGE: "FileTooLarge" as const,
    INTEGER_LIKE_STRING_VALIDATION: "IntegerLikeStringValidation" as const,
    OBJECT_NOT_ONE_OF_EXPECTED_VALIDATION: "ObjectNotOneOfExpectedValidation" as const,
    NOT_UNIQUE: "NotUnique" as const,
    NULL_VALIDATION: "NullValidation" as const,
    POSITIVE_DECIMAL_VALIDATION: "PositiveDecimalValidation" as const,
    NO_PRIMARY_PHONE_NUMBER_SET_VALIDATION: "NoPrimaryPhoneNumberSetValidation" as const,
    LISTING_ALREADY_HAS_AN_ACCEPTED_OFFER: "ListingAlreadyHasAnOfferAcceptedValidation" as const,
    NO_USER_WITH_EMAIL_ADDRESS_VALIDATION: "NoUserWithEmailAddressValidation" as const,
    NOTHING_TO_SEND_VALIDATION: "NothingToSendValidation" as const,
    SENDERS_EMAIL_NOT_SAILHOMES_VALIDATION: "SendersEmailNotSailHomesValidation" as const,
    GDPR_TERMS_NOT_AGREED: "GdprTermsNotAgreed" as const,
    INTEREST_ALREADY_EXPRESSED: "InterestAlreadyExpressed" as const,
    DOCUMENT_DOES_NOT_EXIST_IN_CASE: "DocumentDoesNotExistInCase" as const,
    CASE_ENQUIRY_NOT_IN_UNSUBMITTED_STATUS: "CaseEnquiryNotInUnsubmittedStatus" as const,
    LITERAL_VALIDATION: "LiteralValidation" as const,
    ERROR_TUPLE_VALIDATION: "ErrorTupleValidation" as const,
    CASE_STATE_PREVENTS_CHANGE: "CaseStatePreventsChange" as const,
    AUTO_COMPLETE_ADDRESS_FOUND_NO_MATCHES: "AutoCompleteAddressFoundNoMatches" as const,
    EXACT_NUMBER_VALIDATION: "ExactNumberValidation" as const,
    PHONE_NUMBER_INVALID: "PhoneNumberInvalid" as const,
    TWO_FACTOR_AUTH_CODE_INVALID: "TwoFactorAuthCodeInvalid" as const,
    IN_USE: "InUse",
    CASE_LEDGER_HAS_NOT_BEEN_FINALISED: "CaseLedgerHasNotBeenFinalised" as const,
    NOT_ALL_CASE_LEDGER_CREDIT_ENTRIES_MARKED_AS_PAID: "NotAllCaseLedgerCreditEntriesMarkedAsPaid" as const,
    GOOGLE_CALENDAR_API_REQUEST_FAILED: "GoogleCalendarApiRequestFailed" as const,
    GOOGLE_ARCHIVE_API_REQUEST_FAILED: "GoogleArchiveApiRequestFailed" as const,
    CUSTOM_VALIDATION: "CustomValidation" as const,
    UNDEFINED_VALIDATION: "UndefinedValidation" as const,
    NO_RESULTS: "NoResults" as const,
    TITLE_NUMBER_INVALID: "TitleNumberInvalid" as const,
};

export const errorValues = Object.values(errorConstants);

export type TErrorCode = typeof errorConstants[keyof typeof errorConstants];

export type TErrorTuple = [TErrorCode, string];

export type TError = Array<TErrorTuple>;

export const errorTupleEq = fromEquals<TErrorTuple>(([a, b], [c, d]) => a == c && b === d);

export const doesErrorKeyExist = (key: string, errors: TError): boolean => pipe(
    errors,
    array.findFirst((tuple) => tuple[1] === key),
    option.fold(
        () => false,
        () => true,
    ),
);

export const doesErrorKeySliceExist = (key: string, errors: TError): boolean => pipe(
    errors,
    array.findFirst((tuple) => tuple[1].split(".")[0] === key),
    option.fold(
        () => false,
        () => true,
    ),
);

export const doesErrorCodeExist = (errorCode: string, errors: TError): boolean => pipe(
    errors,
    array.findFirst((tuple) => tuple[0] === errorCode),
    option.fold(
        () => false,
        () => true,
    ),
);
