import { TTypeOfCodec } from "../../../shared/src/codecs/codec";
import { array, TArrayCodec } from "../../../shared/src/codecs/types/array";
import { fileIO, TFileIOCodec } from "../../../shared/src/codecs/types/fileIO";
import { form, TFormCodec } from "../../../shared/src/codecs/types/form";
import { nullCodec, TAnyNullCodec } from "../../../shared/src/codecs/types/nullCodec";
import { required, TRequiredCodec } from "../../../shared/src/codecs/types/required";
import { requiredFlatOverloaded } from "../../../shared/src/codecs/types/requiredFlatOverloaded";
import { string, TAnyStringCodec } from "../../../shared/src/codecs/types/string";
import { TUnionCodec, union } from "../../../shared/src/codecs/types/union";
import { TUuidCodec, uuid } from "../../../shared/src/codecs/types/uuid";
import { EmptyObject, TEmptyObjectCodec } from "../EmptyObject";
import { Introducer2, TIntroducer2Codec } from "../Introducer";
import { CaseAutoAssignmentFormWithPlaceholders, TCaseAutoAssignmentFormWithPlaceholdersCodec } from "../overloads/CaseAutoAssignmentFormWithPlaceholders";
import { CreateLegalFileDownloadUrl } from "../overloads/LegalFileDownloadUrl";
import { Pagination1, Pagination3, TPagination1Codec, TPagination3Codec } from "../Pagination";
import { TUserIdAndFullNameCodec, UserIdAndFullName } from "../User";

// EXISTING AGREEMENT FORM
export const IntroducerExistingAgreementForm = form(
    required({
        id: uuid(),
    }),
    requiredFlatOverloaded({
        download_url: CreateLegalFileDownloadUrl("Introducer agreement"),
    }),
);
export type TIntroducerExistingAgreementFormCodec = typeof IntroducerExistingAgreementForm;
export type TIntroducerExistingAgreementForm = TTypeOfCodec<TIntroducerExistingAgreementFormCodec>;

// UPLOAD AGREEMENT FORM
export const IntroducerUploadAgreementForm: TFileIOCodec<TRequiredCodec<{
    id: TUuidCodec,
    file_extension: TAnyStringCodec,
    mime_type: TAnyStringCodec,
}>, TEmptyObjectCodec> = fileIO(
    required({
        id: uuid(),
        file_extension: string(),
        mime_type: string(),
    }),
    EmptyObject,
);
export type TIntroducerUploadAgreementFormCodec = typeof IntroducerUploadAgreementForm;
export type TIntroducerUploadAgreementForm = TTypeOfCodec<TIntroducerUploadAgreementFormCodec>;

// INTRODUCER FORM
export const IntroducerForm: TFormCodec<
    TIntroducer2Codec,
    TRequiredCodec<{
        upload_form: TIntroducerUploadAgreementFormCodec,
        existing_agreement_form: TUnionCodec<[TIntroducerExistingAgreementFormCodec, TAnyNullCodec]>,
        case_auto_assignment_forms: TCaseAutoAssignmentFormWithPlaceholdersCodec,
    }>
> = form(
    Introducer2,
    required({
        upload_form: IntroducerUploadAgreementForm,
        existing_agreement_form: union([
            IntroducerExistingAgreementForm,
            nullCodec(),
        ]),
        case_auto_assignment_forms: CaseAutoAssignmentFormWithPlaceholders,
    }),
);
export type TIntroducerFormCodec = typeof IntroducerForm;
export type TIntroducerForm = TTypeOfCodec<TIntroducerFormCodec>;

// CREATE INTRODUCER FORM
export const IntroducerCreateForm: TFormCodec<TEmptyObjectCodec, TEmptyObjectCodec> = form(EmptyObject, EmptyObject);
export type TIntroducerCreateFormCodec = typeof IntroducerCreateForm;
export type TIntroducerCreateForm = TTypeOfCodec<TIntroducerCreateFormCodec>;

// INTRODUCER FORM CHILDREN
const IntroducersFormChildren: TRequiredCodec<{
    introducers: TArrayCodec<TIntroducerFormCodec>,
    counts: TPagination3Codec,
    create_introducer_form: TIntroducerCreateFormCodec,
    case_handlers: TArrayCodec<TUserIdAndFullNameCodec>,
    case_assistants: TArrayCodec<TUserIdAndFullNameCodec>
}> = required({
    introducers: array(IntroducerForm),
    counts: Pagination3,
    create_introducer_form: IntroducerCreateForm,
    case_handlers: array(UserIdAndFullName),
    case_assistants: array(UserIdAndFullName),
});
export type TIntroducersFormChildrenCodec = typeof IntroducersFormChildren;
export type TIntroducersFormChildren = TTypeOfCodec<TIntroducersFormChildrenCodec>;

// INTRODUCERS FORM
export const IntroducersForm: TFormCodec<
    TPagination1Codec,
    TIntroducersFormChildrenCodec
> = form(
    Pagination1,
    IntroducersFormChildren,
);
export type TIntroducersFormCodec = typeof IntroducersForm;
export type TIntroducersForm = TTypeOfCodec<TIntroducersFormCodec>;
