import { form, TFormCodec } from "../../../shared/src/codecs/types/form";
import { TTypeOfCodec } from "../../../shared/src/codecs/codec";
import { required, TRequiredCodec } from "../../../shared/src/codecs/types/required";
import { TAnyUuidCodec, uuid } from "../../../shared/src/codecs/types/uuid";
import { array, TArrayCodec } from "../../../shared/src/codecs/types/array";
import { CaseTitleCheckForm, TCaseTitleCheckFormCodec } from "./CaseTitleCheckForm";

export const CaseTitleChecksForm: TFormCodec<
TRequiredCodec<{
    case_id: TAnyUuidCodec,
}>,
TRequiredCodec<{
    title_checks: TArrayCodec<TCaseTitleCheckFormCodec>,
}>> = form(
    required({
        case_id: uuid(),
    }),
    required({
        title_checks: array(CaseTitleCheckForm)
    }),
);
export type TCaseTitleChecksFormCodec = typeof CaseTitleChecksForm;
export type TCaseTitleChecksForm = TTypeOfCodec<TCaseTitleChecksFormCodec>;
