import { required } from "../../shared/src/codecs/types/required";
import { TTypeOfCodec } from "../../shared/src/codecs/codec";
import { uuid } from "../../shared/src/codecs/types/uuid";
import { union } from "../../shared/src/codecs/types/union";
import { nullCodec } from "../../shared/src/codecs/types/nullCodec";
import { phoneNumber } from "../../shared/src/codecs/types/phoneNumber";
import { requiredFlatOverloaded } from "../../shared/src/codecs/types/requiredFlatOverloaded";
import { overload } from "../../shared/src/codecs/types/overload";
import { nonEmptyString } from "../../shared/src/codecs/types/nonEmptyString";
import { dateTime } from "../../shared/src/codecs/types/dateTime";
import { intersection } from "../../shared/src/codecs/types/intersection";
import { boolean } from "../../shared/src/codecs/types/boolean";

export const UserPhoneNumber1 = required({
    id: uuid(),
    phone_number: union([phoneNumber(), nullCodec()]),
});
export type TUserPhoneNumber1Codec = typeof UserPhoneNumber1;

export const UserPhoneNumber2 = requiredFlatOverloaded({
    primary_phone_number_obscured: overload(
        union([nonEmptyString(), nullCodec()]),
        required({
            phone_number: union([phoneNumber(), nullCodec()]),
        }),
        ({phone_number}) =>
            phone_number !== null
                ? `${"*".repeat(phone_number.length - 3)}${phone_number.substr(phone_number.length - 3)}`
                : null,
    ),
});
export type TUserPhoneNumber2Codec = typeof UserPhoneNumber2;

export const UserPhoneNumber3 = required({
    user_phone_number_id: union([uuid(), nullCodec()]),
    phone_number: union([phoneNumber(), nullCodec()]),
    two_factor_last_sent: union([dateTime(), nullCodec()]),
});
export type TUserPhoneNumber3 = TTypeOfCodec<typeof UserPhoneNumber3>;

export const UserPhoneNumber4 = intersection([
    UserPhoneNumber1,
    required({
        is_verified: boolean(),
    }),
]);
export type TUserPhoneNumber4 = TTypeOfCodec<typeof UserPhoneNumber4>;
