import { union } from "../../shared/src/codecs/types/union";
import { literal } from "../../shared/src/codecs/types/literal";
import { TTypeOfCodec } from "../../shared/src/codecs/codec";
import { requireExhaustive } from "../../shared/src/util";

export const CasePropertyTitleClass = union([
    literal("unknown"),
    literal("absolute"),
    literal("possessory"),
    literal("qualified"),
    literal("good_leasehold"),
]);
type TCasePropertyTitleClass = TTypeOfCodec<typeof CasePropertyTitleClass>;
export const CasePropertyTitleClass_displayString = (p: TCasePropertyTitleClass): string =>
    p === "absolute" ? "Absolute"
    : p === "good_leasehold" ? "Good Leasehold"
    : p === "possessory" ? "Possessory"
    : p === "qualified" ? "Qualified"
    : p === "unknown" ? "Unknown"
    : requireExhaustive(p);
