import React from "react";

export default (props: React.SVGAttributes<SVGElement>) => <svg style={{ display: "block" }} viewBox="1 1.5 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
    {/* <!-- Generator: Sketch 61.2 (89653) - https://sketch.com --> */}
    <title>0: Icons / Camera</title>
    <desc>Created with Sketch.</desc>
    <g id="0:-Icons-/-Camera" stroke="none" strokeWidth="1">
        <path d="M12.0000004,9.75 C10.1773004,9.75 8.70000031,11.26155 8.70000031,13.125 C8.70000031,14.989125 10.1773004,16.5 12.0000004,16.5 C13.8222605,16.5 15.3000006,14.989125 15.3000006,13.125 C15.3000006,11.26155 13.8222605,9.75 12.0000004,9.75 Z M20.8000008,6.375 L18.1600007,6.375 C17.7970007,6.375 17.4058407,6.087 17.2912206,5.73465 L16.6087806,3.640125 C16.4937206,3.288 16.1030006,3 15.7400006,3 L8.26000029,3 C7.89700027,3 7.50584026,3.288 7.39122025,3.64035 L6.70878023,5.734875 C6.59372022,6.087 6.20300021,6.375 5.84000019,6.375 L3.20000009,6.375 C1.99000004,6.375 1,7.3875 1,8.625 L1,18.75 C1,19.9875 1.99000004,21 3.20000009,21 L20.8000008,21 C22.0100008,21 23,19.9875 23,18.75 L23,8.625 C23,7.3875 22.0100008,6.375 20.8000008,6.375 Z M12.0000002,18.75 C8.96246032,18.75 6.50000022,16.231575 6.50000022,13.125 C6.50000022,10.01865 8.96246032,7.5 12.0000002,7.5 C15.0371006,7.5 17.5000002,10.01865 17.5000002,13.125 C17.5000002,16.231575 15.0371006,18.75 12.0000002,18.75 Z M20.0300008,10.198425 C19.6049607,10.198425 19.2600007,9.846075 19.2600007,9.410925 C19.2600007,8.976225 19.6049607,8.623425 20.0300008,8.623425 C20.4554808,8.623425 20.8000008,8.976225 20.8000008,9.410925 C20.8000008,9.846075 20.4552608,10.198425 20.0300008,10.198425 Z" id="Shape"></path>
    </g>
</svg>;
