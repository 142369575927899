import React from "react";

export default (props: React.SVGAttributes<SVGElement>) => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g clipPath="url(#clip0_5388:25199)">
<path d="M20 5H4C2.9 5 2.01 5.9 2.01 7L2 19C2 20.1 2.9 21 4 21H12V19H4V9L12 14L20 9V12H22V7C22 5.9 21.1 5 20 5ZM12 12L4 7H20L12 12Z" fill="black"/>
<path d="M17.01 23C16.74 23 16.49 22.9 16.3 22.71C16.1 22.51 16.01 22.28 16.01 22C16 21.45 16.44 21.01 16.99 21C17 21 17 21 17.01 21C17.29 21 17.52 21.1 17.72 21.29C17.9 21.48 18 21.72 18 21.99C18 22.26 17.9 22.5 17.71 22.7C17.52 22.9 17.28 23 17.01 23ZM16.13 19.34C16.13 18.89 16.23 18.5 16.42 18.18C16.61 17.85 16.95 17.48 17.42 17.06C17.7 16.81 17.9 16.59 18.03 16.4C18.16 16.21 18.22 16 18.22 15.76C18.22 15.47 18.11 15.23 17.9 15.02C17.69 14.82 17.4 14.72 17.05 14.72C16.68 14.72 16.31 14.82 16.09 15.02C15.88 15.22 15.69 15.47 15.69 16H14C14 14.99 14.46 14.27 14.97 13.79C15.53 13.28 16.25 13 17 13C17.59 13 18.11 13.12 18.57 13.35C19.03 13.58 19.36 13.9 19.62 14.31C19.88 14.72 20 15.17 20 15.66C20 16.15 19.9 16.56 19.69 16.91C19.48 17.26 19.21 17.62 18.8 18C18.48 18.3 18.27 18.56 18.15 18.77C18.03 18.98 17.97 19.26 17.97 19.58V20H16.12V19.34H16.13Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_5388:25199">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>
;
