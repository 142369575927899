import { TTypeOfCodec } from "../../shared/src/codecs/codec";
import { union } from "../../shared/src/codecs/types/union";
import { literal } from "../../shared/src/codecs/types/literal";
import { uuid } from "../../shared/src/codecs/types/uuid";
import { nonEmptyString } from "../../shared/src/codecs/types/nonEmptyString";
import { required } from "../../shared/src/codecs/types/required";
import { requiredFlatOverloaded } from "../../shared/src/codecs/types/requiredFlatOverloaded";
import { intersection } from "../../shared/src/codecs/types/intersection";
import { DeleteListingDocumentFormIO } from "./formIO/DeleteListingDocumentFormIO";
import { formEditable } from "../../shared/src/codecs/types/formEditable";
import * as S3BucketUrl from "../../domain/models/S3BucketUrl";
import { overload } from "../../shared/src/codecs/types/overload";
import { string } from "../../shared/src/codecs/types/string";
import { dateTimeOrNullAsBoolean } from "../../shared/src/codecs/types/dateTimeOrNullAsBoolean";

const getListingDocumentUrl = (id: string, file_extension: string) => `${S3BucketUrl.fromAccountAndBucketNames(process.env.ACCOUNT_NAME ?? "", S3BucketUrl.bucketNames.LISTINGS_DOCUMENTS_BUCKET)}/${id}/${id}.${file_extension}`;

export const ListingDocumentType = union([
    literal("energy_certificate"),
    literal("floorplan"),
    literal("title_plan"),
    literal("title_register"),
    literal("environmental_search"),
    literal("local_authority_search"),
    literal("drainage_water_search"),
    literal("flood_risk_assessment"),
    literal("inventory"),
    literal("ta6"),
    literal("ta10"),
    literal("survey"),
    literal("other"),
    literal("builders_inspection_report"),
    literal("mos"),
    literal("ta7"),
    literal("lpe1"),
    literal("invoice"),
    literal("grant_of_probate"),
    literal("leasehold_lease"),
    literal("chancel_search"),
    literal("chancel_insurance_policy"),
    literal("valuation_report"),
    literal("rental_report"),
    literal("coal_search"),
    literal("leasehold_title_plan"),
    literal("leasehold_title_register"),
]);

export type TListingDocumentType = TTypeOfCodec<typeof ListingDocumentType>;

export const ListingDocument1 = required({
    id: uuid(),
    listing_id: uuid(),
    name: nonEmptyString(),
    file_extension: nonEmptyString(),
    mime_type: nonEmptyString(),
    document_type: ListingDocumentType,
});

export const ListingDocumentEditable = 
    formEditable(
        required({
            id: uuid(),
            name: nonEmptyString(),
            document_type: ListingDocumentType,
            sent_to_solicitor: dateTimeOrNullAsBoolean(),
        })
    );

export type TListingDocumentEditableCodec = typeof ListingDocumentEditable;
export type TListingDocumentEditable = TTypeOfCodec<TListingDocumentEditableCodec>;

export const ListingDocument2 = intersection([
    required({
        id: uuid(),
        listing_id: uuid(),
        file_extension: nonEmptyString(),
        mime_type: nonEmptyString(),
    }),
    requiredFlatOverloaded({
        delete_form: DeleteListingDocumentFormIO,
    }),
    requiredFlatOverloaded({
        editable_form: ListingDocumentEditable,
    }),
    requiredFlatOverloaded({
        url: overload(
            string(),
            required({
                id: uuid(),
                file_extension: string(),
            }),
            (t) => t
                ? getListingDocumentUrl(t.id, t.file_extension)
                : "",
        ),
    }),
]);
export type TListingDocument2Codec = typeof ListingDocument2;

export const ListingDocument3 = required({
    listing_id: uuid(),
    name: nonEmptyString(),
    file_extension: nonEmptyString(),
    mime_type: nonEmptyString(),
    document_type: ListingDocumentType,
});
export type TListingDocument3Codec = typeof ListingDocument3;
export type TListingDocument3 = TTypeOfCodec<typeof ListingDocument3>;