import * as t from "io-ts";
import * as Country2 from "./Country2";

export const codec =
    t.type({
        postcode: t.union([t.string, t.null]),
        county: t.string,
        city_town: t.string,
        country: Country2.codec,
        building_name: t.string,
        building_number: t.string,
        sub_building_name: t.string,
        sub_building_number: t.string,
        street_name: t.string,
        locality: t.string,
        district: t.string,
    });

export type T = t.TypeOf<typeof codec>;

export const newDefault = (): T => ({
    postcode: null,
    county: "",
    city_town: "",
    country: "unknown",
    building_name: "",
    building_number: "",
    sub_building_name: "",
    sub_building_number: "",
    street_name: "",
    locality: "",
    district: "",
});
