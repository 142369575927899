import { TCodec } from "../codec";
import { either } from "fp-ts/lib";
import { TError, errorConstants } from "../errors";

export type TAnyTrueCodec = TCodec<
    "TrueCodec",
    undefined,
    true,
    true
>;

export type TTrueCodec = TCodec<
    "TrueCodec",
    undefined,
    true,
    true
>;

const decode = (input: unknown): either.Either<TError, true> =>
    typeof input === "boolean" && input === true
        ? either.right(input)
        : either.left([[errorConstants.TRUE_VALIDATION, ""]]);

export const trueCodec = (): TAnyTrueCodec => ({
    type: "TrueCodec",
    payload: undefined,
    decode,
    decodeNewDefault: decode,
    newDefault: () => true,
});
