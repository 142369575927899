import { form, TFormCodec } from "../../../shared/src/codecs/types/form";
import { TTypeOfCodec } from "../../../shared/src/codecs/codec";
import { required, TRequiredCodec } from "../../../shared/src/codecs/types/required";
import { EmptyObject, TEmptyObjectCodec } from "../EmptyObject";
import { integer, TIntegerCodec } from "../../../shared/src/codecs/types/integer";

export const InboxCountForm: TFormCodec<TEmptyObjectCodec, TRequiredCodec<{
    unresolved: TIntegerCodec,
    confidential: TIntegerCodec,
}>> = form(
    EmptyObject,
    required({
        unresolved: integer(),
        confidential: integer(),
    }),
);
export type TInboxCountFormCodec = typeof InboxCountForm;
export type TInboxCountForm = TTypeOfCodec<TInboxCountFormCodec>;
