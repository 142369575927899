import { TTypeOfCodec } from "../../shared/src/codecs/codec";
import { dateTime } from "../../shared/src/codecs/types/dateTime";
import { required } from "../../shared/src/codecs/types/required";
import { string } from "../../shared/src/codecs/types/string";
import { uuid } from "../../shared/src/codecs/types/uuid";

export const CasesDocumentLegalFile3 = required({
    cases_document_id: uuid(),
    legal_file_id: uuid(),
    name: string(),
    created_at: dateTime(),
    file_extension: string(),
    id: uuid(),
});

export type TCasesDocumentLegalFile3 = TTypeOfCodec<typeof CasesDocumentLegalFile3>;