import React from "react";
import { THighlight2, TPrimary6 } from "../../models/TCRMMainColours";

export type TCardBackgroundColour = "neutral-12" | "highlights-positive-green-12" | "neutral-paper" | "neutral-8";

type TCRMCardOutside = {
    borderRounding: "left" | "right" | "none";
    shadow?: boolean;
    fade?: boolean;
    backgroundColor?: TCardBackgroundColour;
    borderColor?: THighlight2 | TPrimary6;
};

export class CRMCardOutside extends React.Component<React.PropsWithChildren<TCRMCardOutside>> {
    public render (): JSX.Element {
        return (
            <div
                className={`
                    crm-card-outside
                    crm-card-outside--${this.props.shadow === false || this.props.borderColor ? "no-shadow" : "shadow"}
                    crm-card-outside--${this.props.backgroundColor || "neutral-paper"}
                    crm-card-outside--rounded-${this.props.borderRounding}
                    ${this.props.borderColor ? `crm-card-outside--border--${this.props.borderColor}` : ""}
                `}
            >
                {this.props.children}
            </div>
        );
    }
}
