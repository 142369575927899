import { TTypeOfCodec } from "../../../shared/src/codecs/codec";
import { array, TArrayCodec } from "../../../shared/src/codecs/types/array";
import { dateTime } from "../../../shared/src/codecs/types/dateTime";
import { dateTimeOrNullAsBoolean } from "../../../shared/src/codecs/types/dateTimeOrNullAsBoolean";
import { deferDateTime } from "../../../shared/src/codecs/types/deferDateTime";
import { form, TFormCodec } from "../../../shared/src/codecs/types/form";
import { longString } from "../../../shared/src/codecs/types/longString";
import { nullCodec } from "../../../shared/src/codecs/types/nullCodec";
import { required, TRequiredCodec } from "../../../shared/src/codecs/types/required";
import { union } from "../../../shared/src/codecs/types/union";
import { uuid } from "../../../shared/src/codecs/types/uuid";
import { UserAssignableToAdhoc, TUserAssignableToAdhocCodec } from "../User";

export const CaseAdHocJobFormEditablePart = required({
    job: longString(),
    is_done: dateTimeOrNullAsBoolean(),
    deferred_date: union([deferDateTime(), nullCodec()]),
    assigned_to: uuid(),
});
export type TCaseAdHocJobFormEditablePartCodec = typeof CaseAdHocJobFormEditablePart;
export type TCaseAdHocJobFormEditablePart = TTypeOfCodec<TCaseAdHocJobFormEditablePartCodec>;

export const CaseAdHocJobFormReadOnlyPart = required({
    id: uuid(),
    case_id: uuid(),
    created_at: union([dateTime(), nullCodec()]),
});
export type TCaseAdHocJobFormReadOnlyPartCodec = typeof CaseAdHocJobFormReadOnlyPart;

export const CaseAdHocJobForm: TFormCodec<TCaseAdHocJobFormEditablePartCodec, TCaseAdHocJobFormReadOnlyPartCodec> = form(
    CaseAdHocJobFormEditablePart,
    CaseAdHocJobFormReadOnlyPart,
);
export type TCaseAdHocJobFormCodec = typeof CaseAdHocJobForm;
export type TCaseAdHocJobForm = TTypeOfCodec<TCaseAdHocJobFormCodec>;

export const CaseAdHocJobNew = required({
    job: longString(),
    case_id: uuid(),
    user_id: uuid(),
});
export type TCaseAdHocJobNewCodec = typeof CaseAdHocJobNew;
export type TCaseAdHocJobNew = TTypeOfCodec<TCaseAdHocJobNewCodec>;

export const CaseAdHocJobNewForm: TFormCodec<TCaseAdHocJobNewCodec, TRequiredCodec<{
    users: TArrayCodec<TUserAssignableToAdhocCodec>
}>> = form(
    CaseAdHocJobNew,
    required({
        users: array(UserAssignableToAdhoc),
    }),
);
export type TCaseAdHocJobNewFormCodec = typeof CaseAdHocJobNewForm;
export type TCaseAdHocJobNewForm = TTypeOfCodec<TCaseAdHocJobNewFormCodec>;
