import { TCodec } from "../codec";
import { either } from "fp-ts/lib";
import { TError, errorConstants } from "../errors";

export type TAnyIntegerCodec = TCodec<
    "IntegerCodec",
    undefined,
    number,
    number
>;

export type TIntegerCodec = TCodec<
    "IntegerCodec",
    undefined,
    number,
    number
>;

const decode = (input: unknown): either.Either<TError, number> =>
    typeof input === "number"
    && Number.isInteger(input)
        ? either.right(input)
        : either.left([[errorConstants.INTEGER_VALIDATION, ""]]);

export const integer = (): TAnyIntegerCodec => ({
    type: "IntegerCodec",
    payload: undefined,
    decode,
    decodeNewDefault: decode,
    newDefault: () => 0,
});
