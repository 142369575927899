import { option, array as fptsArray } from "fp-ts";
import { errorConstants, TError } from "../../shared/src/codecs/errors";
import { array } from "../../shared/src/codecs/types/array";
import { custom } from "../../shared/src/codecs/types/custom";
import { dateTime } from "../../shared/src/codecs/types/dateTime";
import { falseCodec } from "../../shared/src/codecs/types/falseCodec";
import { intersection } from "../../shared/src/codecs/types/intersection";
import { nullCodec } from "../../shared/src/codecs/types/nullCodec";
import { required } from "../../shared/src/codecs/types/required";
import { requiredFlatOverloaded } from "../../shared/src/codecs/types/requiredFlatOverloaded";
import { string } from "../../shared/src/codecs/types/string";
import { trueCodec } from "../../shared/src/codecs/types/trueCodec";
import { union } from "../../shared/src/codecs/types/union";
import { TransactionType1 } from "../../domain/codecs/TransactionType";
import { CaseConflictOfInterestProceedable } from "./CaseConflictsOfInterest";
import { CaseEnquiryResolvedOrNotRelevant } from "./CaseEnquiry";
import { CaseLedgerType } from "./CaseLedger";
import { CaseRiskAssessmentProceedableForReadyForExchange } from "./CasesRiskAssessment";
import { Tenure1 } from "./Tenure";
import { UsersIdCheckPassed } from "./UsersIdCheck";
import { CasesPurchasePaymentMethod1 } from "./CasesPurchasePaymentMethod";
import { CaseLenderDictionary, CasesLenderNameOnPanel1 } from "./CasesLenderNameOnPanel";
import { dateTimeOrNullAsBoolean } from "../../shared/src/codecs/types/dateTimeOrNullAsBoolean";
import { uuid } from "../../shared/src/codecs/types/uuid";
import { UserVideoVerificationCallPassed } from "./UserVideoVerificationCall";
import { overload } from "../../shared/src/codecs/types/overload";
import { boolean } from "../../shared/src/codecs/types/boolean";
import { nonEmptyString } from "../../shared/src/codecs/types/nonEmptyString";
import { literal } from "../../shared/src/codecs/types/literal";
import { CasesDocument2 } from "./CasesDocument";
import { integer } from "../../shared/src/codecs/types/integer";
import { CaseMortgageOffer, CaseMortgageOfferMattersToReferWithDetails, CasesSdltAnswersReviewOutcome } from "./Cases";
import { CasePropertyTitleGuarantee2 } from "./CasePropertyTitleGuarantee";
import { CasesDocumentType } from "./CasesDocumentTypes";
import { date } from "../../shared/src/codecs/types/date";
import { DateTime } from "luxon";
import { UserCaseUserType } from "./UserCaseUserType";
import { pipe } from "fp-ts/lib/function";
import { Eq, Ord } from "fp-ts/lib/string";
import { getEq } from "fp-ts/lib/Array";
import { TTypeOfCodec } from "../../shared/src/codecs/codec";
import { JointOwnershipHeldAsType } from "./CasesContractBlock";
import { CaseCharge1 } from "./CaseCharge";

const CaseUndertakingsProceedable = union([
    required({
        has_non_standard_undertakings: trueCodec(),
        non_standard_undertakings_approved: dateTime(),
    }),
    required({
        has_non_standard_undertakings: falseCodec(),
    }),
]);

export const CaseCanBeSignedOffAsReadyForExchange = intersection([
    required({
        enquiries: array(CaseEnquiryResolvedOrNotRelevant),
        id_checks: array(UsersIdCheckPassed),
        conflicts_of_interest: array(CaseConflictOfInterestProceedable),
        undertakings: CaseUndertakingsProceedable,
        last_risk_assessment: CaseRiskAssessmentProceedableForReadyForExchange,
        video_calls: array(UserVideoVerificationCallPassed),
        bank_accounts: array(union([
            required({
                all_clients_agree_with_this_bank_account: dateTime(),
                verification_status: literal("approved"),
            }),
            required({
                verification_status: literal("not_verified_as_will_not_be_used"),
            }),
        ])),
        has_unresolved_mandatory_roadblocks: falseCodec()
    }),
    requiredFlatOverloaded({
        charges: custom(
            required({
                charges: array(CaseCharge1),
                case_details: required({
                    transaction_type: TransactionType1,
                }),
            }),
            ({charges, case_details}) =>
                case_details.transaction_type === "purchase"
                    ? option.none
                    : pipe(
                        charges,
                        fptsArray.filter((charge) => charge.account_number.trim() === "")
                    ).length > 0 ? option.some([[errorConstants.CUSTOM_VALIDATION]]) as option.Option<TError>
                    : option.none
        )
    }),
    requiredFlatOverloaded({
        recent_last_transfer_risk_not_assessed: custom(
            required({
                cases_properties: array(
                    required({
                        is_registered: union([boolean(), nullCodec()]),
                        last_transfer_date: union([date(), nullCodec()]),
                        recent_last_transfer_date_risk_acknowledged: boolean(),
                    })
                )
            }),
            ({cases_properties}) =>
                cases_properties.filter(({is_registered, last_transfer_date, recent_last_transfer_date_risk_acknowledged}) =>
                    is_registered === null
                    || (
                        is_registered === true
                        && (
                            last_transfer_date === null
                            || (
                                DateTime.fromISO(last_transfer_date).toSeconds() >= DateTime.utc().minus({months: 6}).toSeconds()
                                && recent_last_transfer_date_risk_acknowledged === false
                            )
                        )
                    )
                ).length > 0
                    ? option.some([[errorConstants.CUSTOM_VALIDATION]]) as option.Option<TError>
                    : option.none
        )
    }),
    requiredFlatOverloaded({
        sdlt_return_uploaded_or_referred_to_compass: custom(
            required({
                case_details: required({
                    transaction_type: TransactionType1,
                    price_pence: union([integer(), nullCodec()]),
                    sdlt_answers_reviewed_outcome: CasesSdltAnswersReviewOutcome,
                }),
                documents: array(CasesDocument2),
            }),
            ({case_details, documents}) =>
                (
                    case_details.transaction_type === "purchase"
                    || case_details.transaction_type === "transfer"
                )
                && (case_details.price_pence || 0) >= 4000000
                && (
                    case_details.sdlt_answers_reviewed_outcome === "unknown"
                    || (
                        case_details.sdlt_answers_reviewed_outcome === "compass_referral_not_required"
                        && documents.filter(({type, received_date}) =>
                            (
                                type === "draft_sdlt_return"
                                || type === "signed_sdlt_return"
                            )
                            && received_date
                        ).length === 0
                    )
                )
                    ? option.some([[errorConstants.CUSTOM_VALIDATION]]) as option.Option<TError>
                    : option.none
        ),
    }),
    requiredFlatOverloaded({
        sdlt_declaration_given: custom(
            required({
                case_details: required({
                    transaction_type: TransactionType1,
                    price_pence: union([integer(), nullCodec()]),
                }),
                sdlt_declarations: array(required({user_id: uuid()})),
                users_cases: array(required({
                    user_id: uuid(),
                    user_type: UserCaseUserType,
                })),
            }),
            ({case_details, sdlt_declarations, users_cases}) =>
                (
                    case_details.transaction_type === "purchase"
                    || case_details.transaction_type === "transfer"
                )
                && (case_details.price_pence || 0) >= 4000000
                // Check the users who need to make an SDLT declaration have done so
                && getEq(Eq).equals(
                    pipe(
                        users_cases,
                        fptsArray.filter(({user_type}) => user_type === "client"),
                        fptsArray.map(({user_id}) => user_id),
                        fptsArray.sort(Ord),
                    ),
                    pipe(
                        sdlt_declarations,
                        // Remove any declarations where the user is not a client (just in case they declared when they were a client and now aren't)
                        fptsArray.filter(({user_id}) =>
                            pipe(
                                users_cases,
                                fptsArray.filter(({user_type}) => user_type === "client"),
                                fptsArray.map((u) => u.user_id),
                            ).includes(user_id),
                        ),
                        fptsArray.map(({user_id}) => user_id),
                        fptsArray.sort(Ord),
                    ),
                ) === false
                    ? option.some([[errorConstants.CUSTOM_VALIDATION]]) as option.Option<TError>
                    : option.none
        ),
    }),
    requiredFlatOverloaded({
        mortgage_offer_questions_answered: custom(
            required({
                case_details: intersection([
                    required({
                        transaction_type: TransactionType1,
                        purchase_payment_method: CasesPurchasePaymentMethod1,
                    }),
                    CaseMortgageOffer,
                    CaseMortgageOfferMattersToReferWithDetails,
                ])
            }),
            ({case_details}) =>
                (
                    case_details.transaction_type === "remortgage"
                    || (
                        (
                            case_details.transaction_type === "purchase"
                            || case_details.transaction_type === "transfer"
                        )
                        && case_details.purchase_payment_method === "mortgage"
                    )
                )
                && (
                    case_details.mortgage_offer_date === null
                    || case_details.mortgage_offer_advance_amount === null
                    || case_details.mortgage_offer_retention_amount === null
                    || case_details.mortgage_offer_term_years === null
                    || case_details.mortgage_offer_interest_rate === null
                    || case_details.mortgage_offer_early_redemption_penalty === "unknown"
                    || case_details.mortgage_offer_all_client_names_correct === false
                    || case_details.mortgage_offer_address_correct === false
                    || case_details.mortgage_offer_amount_matches_purchase_price === false
                    || case_details.mortgage_offer_tenure_matches_contract_tenure === false
                    || case_details.mortgage_offer_lender_requirements_checked_and_case_can_proceed === false
                    || case_details.mortgage_offer_we_require_buildings_insurance === null
                    || case_details.mortgage_offer_an_occupier_consent_form_is_required === null
                    || case_details.mortgage_offer_there_are_matters_we_need_to_refer === null
                    || (
                        case_details.mortgage_offer_there_are_matters_we_need_to_refer === true
                        && case_details.mortgage_offer_matters_to_refer_details === ""
                    )
                )
                    ? option.some([[errorConstants.CUSTOM_VALIDATION]]) as option.Option<TError>
                    : option.none
        ),
    }),
    requiredFlatOverloaded({
        mortgage_offer_expired: custom(
            required({
                case_details: required({
                    transaction_type: TransactionType1,
                    purchase_payment_method: CasesPurchasePaymentMethod1,
                }),
                documents: array(required({
                    type: CasesDocumentType,
                    received_date: union([dateTime(), nullCodec()]),
                    valid_until: union([date(), nullCodec()]),
                })),
            }),
            ({case_details, documents}) =>
                (
                    case_details.transaction_type === "remortgage"
                    || (
                        (
                            case_details.transaction_type === "purchase"
                            || case_details.transaction_type === "transfer"
                        )
                        && case_details.purchase_payment_method === "mortgage"
                    )
                )
                && documents.filter((document) =>
                    document.type === "mortgage_offer"
                    && document.received_date
                    && document.valid_until
                    && DateTime.fromISO(document.valid_until).toSeconds() >= DateTime.utc().set({hour: 0, minute: 0, second: 0, millisecond: 0}).toSeconds()
                ).length === 0
                    ? option.some([[errorConstants.CUSTOM_VALIDATION]]) as option.Option<TError>
                    : option.none
        ),
    }),
    requiredFlatOverloaded({
        clients_authorise_exchange_and_complete: overload(
            boolean(),
            required({
                case_details: required({
                    clients_authorise_exchange_and_complete: dateTime(),
                }),
            }),
            () => true,
        ),
    }),
    requiredFlatOverloaded({
        protocol_forms_required: custom(
            required({
                case_details: required({
                    transaction_type: TransactionType1,
                }),
                cases_properties: array(
                    required({
                        tenure: Tenure1,
                        title_guarantee: CasePropertyTitleGuarantee2,
                    }),
                ),
                documents: array(CasesDocument2),
            }),
            ({case_details, cases_properties, documents}) =>
                case_details.transaction_type === "sale"
                && cases_properties.filter(({title_guarantee}) => title_guarantee === "full").length > 0
                && (
                    documents.filter(({type, received_date}) => type === "ta6" && received_date !== null).length === 0
                    || documents.filter(({type, received_date}) => type === "ta10" && received_date !== null).length === 0
                    || (
                        cases_properties.filter(({tenure}) => tenure === "leasehold").length > 0
                        && documents.filter(({type, received_date}) => type === "ta7" && received_date !== null).length === 0
                    )
                )
                    ? option.some([[errorConstants.CUSTOM_VALIDATION]]) as option.Option<TError>
                    : option.none
        ),
    }),
    requiredFlatOverloaded({
        search_report: custom(
            required({
                case_details: required({
                    searches_ordered: union([dateTime(), nullCodec()]),
                    transaction_type: TransactionType1,
                }),
                documents: array(CasesDocument2),
            }),
            ({case_details, documents}) =>
                case_details.searches_ordered
                && (
                    // transaction_type NOT 'sale'
                    case_details.transaction_type === "purchase"
                    || case_details.transaction_type === "transfer"
                    || case_details.transaction_type === "remortgage"
                    || case_details.transaction_type === "unknown"
                )
                && documents.filter(({type, received_date}) => type === "search_report" && received_date !== null).length === 0
                    ? option.some([[errorConstants.CUSTOM_VALIDATION]]) as option.Option<TError>
                    : option.none
        ),
    }),
    requiredFlatOverloaded({
        leasehold_report: custom(
            required({
                case_details: required({
                    transaction_type: TransactionType1,
                }),
                cases_properties: array(required({tenure: Tenure1})),
                documents: array(CasesDocument2),
            }),
            ({case_details, cases_properties, documents}) =>
                case_details.transaction_type === "purchase"
                && cases_properties.filter(({tenure}) => tenure === "leasehold").length > 0
                && documents.filter(({type, received_date}) => type === "leasehold_pack_report" && received_date !== null).length === 0
                    ? option.some([[errorConstants.CUSTOM_VALIDATION]]) as option.Option<TError>
                    : option.none
        ),
    }),
    requiredFlatOverloaded({
        joint_ownership_held_as: custom(
            required({
                case_details: required({
                    transaction_type: TransactionType1,
                    joint_ownership_held_as: JointOwnershipHeldAsType,
                }),
                users_cases: array(required({
                    user_type: UserCaseUserType,
                })),
            }),
            ({case_details, users_cases}) =>
                (
                    case_details.transaction_type === "purchase"
                    || case_details.transaction_type === "transfer"
                    || case_details.transaction_type === "remortgage"
                )
                // This question is only applicable if there is more than 1 client.
                && users_cases.filter(({user_type}) => user_type === "client").length > 1
                && case_details.joint_ownership_held_as === "unknown"
                    ? option.some([[errorConstants.CUSTOM_VALIDATION]]) as option.Option<TError>
                    : option.none
        )
    }),
    requiredFlatOverloaded({
        deposit_added_to_ledger: custom(
            required({
                case_details: required({
                    transaction_type: TransactionType1,
                    price_pence: union([integer(), nullCodec()]),
                    has_related_transaction: boolean(),
                }),
                case_ledger_entries: array(required({type: CaseLedgerType, deleted_at: union([nullCodec(), string()])}))
            }),
            (data) =>
                (
                    data.case_details.transaction_type === "purchase"
                    || data.case_details.transaction_type === "transfer"
                )
                && (data.case_details.price_pence || 0) > 0
                && data.case_ledger_entries.filter((l) =>
                    l.type === "money_from_client_deposit"
                    && l.deleted_at === null
                ).length === 0
                && data.case_details.has_related_transaction === false
                    ? option.some([[errorConstants.CUSTOM_VALIDATION]]) as option.Option<TError>
                    : option.none
        ),
    }),
    requiredFlatOverloaded({
        draft_completion_statement_uploaded: custom(
            required({
                documents: array(CasesDocument2),
            }),
            ({documents}) =>
                documents.filter(({type, received_date}) => type === "completion_statement_draft" && received_date !== null).length === 0
                    ? option.some([[errorConstants.CUSTOM_VALIDATION]]) as option.Option<TError>
                    : option.none
        ),
    }),
    requiredFlatOverloaded({
        estate_agent_fee_added_to_ledger: custom(
            required({
                case_details: required({
                    transaction_type: TransactionType1,
                    no_estate_agent: boolean(),
                }),
                case_ledger_entries: array(required({type: CaseLedgerType, deleted_at: union([nullCodec(), string()])}))
            }),
            (data) =>
                data.case_details.transaction_type === "sale"
                && data.case_details.no_estate_agent === false
                && data.case_ledger_entries.filter((l) => l.type === "estate_agent_fee" && l.deleted_at === null).length === 0
                    ? option.some([[errorConstants.CUSTOM_VALIDATION]]) as option.Option<TError>
                    : option.none
        ),
    }),
    requiredFlatOverloaded({
        apportionment_question_answered: overload(
            boolean(),
            required({
                case_details: union([
                    required({
                        has_management_company: trueCodec(),
                        requires_apportionment_statement: boolean(),
                    }),
                    required({
                        has_management_company: falseCodec(),
                    }),
                ])
            }),
            () => true,
        )
    }),
    requiredFlatOverloaded({
        all_charges_and_title_restrictions_added: overload(
            boolean(),
            required({
                case_details: union([
                    required({
                        transaction_type: union([
                            literal("transfer"),
                            literal("sale"),
                            literal("remortgage"),
                        ]),
                        add_charges_and_title_restrictions_job_done_at: dateTime(),
                    }),
                    required({
                        transaction_type: literal("purchase"),
                    }),
                ])
            }),
            () => true,
        )
    }),
    requiredFlatOverloaded({
        all_clients_added_and_right_to_buy_or_sell_checked: overload(
            boolean(),
            required({
                case_details: required({
                    all_clients_added_and_right_to_buy_or_sell_checked: trueCodec(),
                }),
            }),
            () => true,
        ),
    }),
    requiredFlatOverloaded({
        all_other_side_clients_added_and_right_to_buy_or_sell_checked: overload(
            boolean(),
            required({
                case_details: union([
                    required({
                        transaction_type: union([
                            literal("purchase"),
                            literal("sale"),
                        ]),
                        all_other_side_clients_added_and_right_to_buy_or_sell_checked: trueCodec(),
                    }),
                    required({
                        transaction_type: union([
                            literal("transfer"),
                            literal("remortgage"),
                        ]),
                    }),
                ]),
            }),
            () => true,
        ),
    }),
    requiredFlatOverloaded({
        other_side_solicitor_details_checked: overload(
            boolean(),
            required({
                case_details: union([
                    required({
                        transaction_type: union([
                            literal("purchase"),
                            literal("sale"),
                        ]),
                        other_side_conveyancer_sra_or_clc_number: nonEmptyString(),
                    }),
                    required({
                        transaction_type: union([
                            literal("transfer"),
                            literal("remortgage"),
                        ]) 
                    }),
                ])
            }),
            () => true,
        )
    }),
    requiredFlatOverloaded({
        client_notified_of_erc: overload(
            boolean(),
            required({
                charges: array(union([
                    required({
                        has_early_repayment_charge: falseCodec(),
                    }),
                    required({
                        has_early_repayment_charge: trueCodec(),
                        client_notified_of_early_repayment_charge: trueCodec(),
                    })
                ]))
            }),
            () => true,
        )
    }),
    requiredFlatOverloaded({
        leasehold_fee: custom(
            required({
                cases_properties: array(required({tenure: Tenure1})),
                case_ledger_entries: array(required({type: CaseLedgerType, deleted_at: union([nullCodec(), string()])}))
            }),
            (data) =>
                data.cases_properties.filter((p) => p.tenure === "leasehold").length
                && !data.case_ledger_entries.filter((l) => l.type === "leasehold_fee" && l.deleted_at === null).length
                ? option.some([[errorConstants.CUSTOM_VALIDATION]]) as option.Option<TError>
                : option.none
            
        ),
    }),
    requiredFlatOverloaded({
        land_reg_fee: custom(
            required({
                case_details: required({transaction_type: TransactionType1}),
                case_ledger_entries: array(required({type: CaseLedgerType, deleted_at: union([nullCodec(), string()])})),
            }),
            (data) =>
                (
                    data.case_details.transaction_type === "purchase"
                    || data.case_details.transaction_type === "transfer"
                    || data.case_details.transaction_type === "remortgage"
                )
                && ! data.case_ledger_entries.filter((l) => l.type === "land_reg_fee" && l.deleted_at === null).length
                ? option.some([[errorConstants.CUSTOM_VALIDATION]]) as option.Option<TError>
                : option.none
            
        ),
    }),
    requiredFlatOverloaded({
        electronic_transfer: custom(
            required({
                case_details: required({transaction_type: TransactionType1}),
                case_ledger_entries: array(required({type: CaseLedgerType, deleted_at: union([nullCodec(), string()])})),
            }),
            ({case_details, case_ledger_entries}) =>
                (
                    (case_details.transaction_type === "purchase" || case_details.transaction_type === "sale")
                    || (
                        case_details.transaction_type === "remortgage"
                        && case_ledger_entries.filter((l) => l.type === "mortgage_redemption" && l.deleted_at === null).length
                    )
                )
                && !case_ledger_entries.filter((l) => l.type === "electronic_transfer" && l.deleted_at === null).length
                    ? option.some([[errorConstants.CUSTOM_VALIDATION]]) as option.Option<TError>
                    : option.none
            
        ),
    }),
    requiredFlatOverloaded({
        dual_rep_lender_conflict: custom(
            required({
                case_details: required({
                    dual_rep_lender_conflict_approved_to_continue: dateTimeOrNullAsBoolean(),
                    mortgage_lender_name: CasesLenderNameOnPanel1,
                    purchase_payment_method: CasesPurchasePaymentMethod1,
                }),
                case_dual_reps: array(
                    required({
                        original_case_id: uuid(),
                        dual_rep_case_id: uuid(),
                    })
                )
            }),
            (data) => data.case_dual_reps.length > 0 
                && data.case_details.purchase_payment_method === "mortgage"
                && CaseLenderDictionary[data.case_details.mortgage_lender_name].allowsDualRep === false
                && data.case_details.dual_rep_lender_conflict_approved_to_continue === null
                ? option.some([[errorConstants.CUSTOM_VALIDATION]]) as option.Option<TError>
                : option.none
        )
    }),
    requiredFlatOverloaded({
        dual_rep_missing_consent: required({
            case_dual_reps: array(
                required({
                    all_our_side_clients_consent: trueCodec(),
                }),
            ),
        }),
    }),
]);
export type TCaseCanBeSignedOffAsReadyForExchange = TTypeOfCodec<typeof CaseCanBeSignedOffAsReadyForExchange>;

export type TCaseCanBeSignedOffAsReadyForExchangeExpectedInput = {
    case_details: unknown,
    enquiries: unknown,
    id_checks: unknown,
    conflicts_of_interest: unknown,
    undertakings: unknown,
    last_risk_assessment: unknown,
    charges: unknown
    cases_properties: unknown
    case_ledger_entries: unknown
    case_dual_reps: unknown,
    video_calls: unknown,
    bank_accounts: unknown,
    documents: unknown,
    sdlt_declarations: unknown,
    users_cases: unknown,
    has_unresolved_mandatory_roadblocks: boolean
};
export const decodeWithStrictRecordKey = (p: TCaseCanBeSignedOffAsReadyForExchangeExpectedInput) => CaseCanBeSignedOffAsReadyForExchange.decode(p);
