import { TBlocksParamsObject, TChangeRouteAction, TParamsObject, TQueryParamsObject, TRouteActionType, TRouteActionTypeWithBlocks, TRouteActionTypeWithoutBlocks } from "./routerTypes";

export function createChangeRouteAction <T extends TRouteActionTypeWithoutBlocks>(
    type: T, 
    params: TParamsObject<T>, 
    queryStringParams: TQueryParamsObject<T>
): TChangeRouteAction<T>
export function createChangeRouteAction <T extends TRouteActionTypeWithBlocks>(
    type: T, 
    params: TParamsObject<T>, 
    queryStringParams: TQueryParamsObject<T>, 
    blockParams: TBlocksParamsObject<T>
): TChangeRouteAction<T>
export function createChangeRouteAction <T extends TRouteActionType>(
    type: T, 
    params: TParamsObject<T>, 
    queryStringParams: TQueryParamsObject<T>, 
    blockParams?: TBlocksParamsObject<T>
): TChangeRouteAction<T>
export function createChangeRouteAction <T extends TRouteActionType>(
    type: T, 
    params: TParamsObject<T>, 
    queryStringParams: TQueryParamsObject<T>, 
    blockParams?: TBlocksParamsObject<T>
): TChangeRouteAction<T> {
    return {
        type,
        params,
        queryStringParams,
        blockParams: blockParams ?? undefined,
    }
};