import { TTypeOfCodec } from "../../shared/src/codecs/codec";
import { literal } from "../../shared/src/codecs/types/literal";
import { union } from "../../shared/src/codecs/types/union";

export const ListingServiceType = union([
    literal("Unknown"),
    literal("Clearance"),
    literal("Refurb"),
    literal("EPC"),
    literal("Survey"),
    literal("Draindown"),
    literal("Lock change"),
    literal("Key safe"),
    literal("Electrical test"),
    literal("Heating service"),
    literal("Other"),
    literal("Estate agency"),
    literal("Garden tidy"),
    literal("Cleaning"),
    literal("Minor refurbishment"),
    literal("Property inspection"),
    literal("Garden maintenance"),
    literal("Property inventory"),
    literal("Document search"),
    literal("RICS Survey"),
    literal("Admin only estate agency"),
]);
export type TListingServiceTypeCodec = typeof ListingServiceType;
export type TListingServiceType = TTypeOfCodec<typeof ListingServiceType>;