import { TTypeOfCodec } from "../../shared/src/codecs/codec";
import { array } from "../../shared/src/codecs/types/array";
import { boolean } from "../../shared/src/codecs/types/boolean";
import { decimal } from "../../shared/src/codecs/types/decimal";
import { integer } from "../../shared/src/codecs/types/integer";
import { required } from "../../shared/src/codecs/types/required";
import { string } from "../../shared/src/codecs/types/string";
import { uuid } from "../../shared/src/codecs/types/uuid";
import { CasePropertyAddress1TitleNumberRegistered } from "./CaseProperty";
import { CaseMember } from "./CaseMember";
import { CaseStatus } from "./Cases";
import { CaseSearchResultClient } from "./CaseSearchResultClient";
import { StaffUser } from "./User";
import { CaseSellerReasonForSale1 } from "../codecs/CaseSellerReasonForSale";
import { TransactionType1 } from "./TransactionType";
import { CasesDocumentType } from "./CasesDocumentTypes";
import { CasesPurchasePaymentMethod1 } from "./CasesPurchasePaymentMethod";
import { union } from "../../shared/src/codecs/types/union";
import { nullCodec } from "../../shared/src/codecs/types/nullCodec";

export const CaseSearchResult = required({
    id: uuid(),
    properties: array(CasePropertyAddress1TitleNumberRegistered),
    client_users: array(CaseSearchResultClient),
    klyant_matter_id: string(),
    emails: array(CaseMember),
    case_handlers: array(StaffUser),
    is_dual_rep: boolean(),
    status: CaseStatus,
    introducer_external_reference_id: string(),
    search_matches_rank: decimal(),
    cases_status_rank: integer(),
    gop_received: union([nullCodec(), CasesDocumentType]),
    seller_reason_for_sale: CaseSellerReasonForSale1,
    transaction_type: TransactionType1,
    purchase_payment_method: CasesPurchasePaymentMethod1,
});
export type TCaseSearchResultCodec = typeof CaseSearchResult;
export type TCaseSearchResult = TTypeOfCodec<typeof CaseSearchResult>;

export const CaseSearchResultSimple = required({
    id: uuid(),
    search_matches_rank: decimal(),
    cases_status_rank: integer(),
});
export type TCaseSearchResultSimpleCodec = typeof CaseSearchResultSimple;
export type TCaseSearchResultSimple = TTypeOfCodec<typeof CaseSearchResultSimple>;