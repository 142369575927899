import * as PriceQualifier1 from "../models/PriceQualifier1";
import * as Country2 from "../models/Country2";
import * as Tenure1 from "./Tenure1";
import * as ListingDocument2 from "../models/ListingDocument2";
import * as ListingImage3 from "../models/ListingImage3";
import * as Listing9 from "../models/Listing9";
import * as t from "io-ts";
import * as ListingImage6 from "../models/ListingImage6";
import * as ListingAmenity2 from "./ListingAmenity2";
import * as S3BucketUrl from "../models/S3BucketUrl";
import * as ListingImage1 from "./ListingImage1";
import { array, option } from "fp-ts/lib";
import * as ListingDocument6 from "./ListingDocument6";
import * as ListingDocument7 from "./ListingDocument7";
import { pipe } from "fp-ts/lib/function";

export const codec = t.type({
    id: t.string,
    description: t.string,
    price: t.union([t.number, t.null]),
    price_qualifier: t.union([PriceQualifier1.codec, t.null]),
    postcode: t.union([t.string, t.null]),
    county: t.string,
    city_town: t.string,
    country: Country2.codec,
    tenure: Tenure1.codec,
    building_name: t.string,
    building_number: t.string,
    sub_building_name: t.string,
    sub_building_number: t.string,
    street_name: t.string,
    locality: t.string,
    district: t.string,
    images: t.array(ListingImage1.codec),
    documents: t.array(ListingDocument2.codec),
    amenities: t.array(ListingAmenity2.codec),
    latitude: t.union([t.number, t.null]),
    longitude: t.union([t.number, t.null]),
});

export type T = t.TypeOf<typeof codec>;

export const newDefault = (): T => ({
    id: "",
    description: "",
    price: 0,
    price_qualifier: "fixed_price",
    postcode: "",
    county: "",
    city_town: "",
    country: "england",
    tenure: "freehold",
    building_name: "",
    building_number: "",
    sub_building_name: "",
    sub_building_number: "",
    street_name: "",
    locality: "",
    district: "",
    images: [],
    documents: [],
    amenities: [],
    latitude: null,
    longitude: null,
});

export const fromListing9AndAccountName = (m: Listing9.T, accountName: string): T => (
    {
        id: m.id,
        description: m.description ? m.description : "",
        price: m.price_main_currency,
        price_qualifier: m.price_qualifier,
        building_name: m.building_name,
        building_number: m.building_number,
        sub_building_name: m.sub_building_name,
        sub_building_number: m.sub_building_number,
        street_name: m.street_name,
        postcode: m.postcode,
        locality: m.locality,
        district: m.district,
        county: m.county,
        city_town: m.city_town,
        country: m.country,
        tenure: m.tenure,
        images: array.map((i: ListingImage6.T) => ListingImage3.fromListingImage6(
            i,
            S3BucketUrl.fromAccountAndBucketNames(accountName, S3BucketUrl.bucketNames.LISTINGS_IMAGES_BUCKET)
        ))(m.images),
        documents:  pipe(
            m.documents,
            array.filterMap<ListingDocument7.T, ListingDocument6.T>((document) =>
                ListingDocument6.codec.is(document) ? option.some(document) : option.none
            ),
            array.map(ListingDocument2.fromListingDocument6),
        ),
        amenities: array.map(ListingAmenity2.fromListingAmenity3)(m.amenities),
        latitude: m.latitude,
        longitude: m.longitude,
    }
);
