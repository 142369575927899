import { array as fpArray, option, nonEmptyArray } from "fp-ts/lib";
import * as Function from "../Function";
import * as Error from "../Error";
import * as ErrorTuple from "../ErrorTuple";
import { pipe } from "fp-ts/lib/function";

export const array = (validationFunction: Function.TValidationFunction): Function.TValidationFunction => (input: unknown): option.Option<Error.TValidationError> => {
    if (typeof input === "undefined") {
        return option.none;
    }

    if (! Array.isArray(input)) {
        return option.some([["ArrayValidation"]]);
    }

    const errors =  pipe(
        fpArray.filterMapWithIndex<unknown, Error.TValidationError>((index, arrayItem) =>
            option.map<Error.TValidationError, Error.TValidationError>((item) =>
                nonEmptyArray.map<ErrorTuple.TValidationErrorTuple, ErrorTuple.TValidationErrorTuple>(
                    ([err, path]) => [err, path ? `${index}.${path}` : `${index}`]
                )(item)
            )(validationFunction(arrayItem))
        )(input),
        fpArray.flatten
    );

    if (errors.length > 0) {
        return option.some(errors as Error.TValidationError);
    }

    return option.none;
};
