import { TTypeOfCodec } from "../../../shared/src/codecs/codec";
import { formIO, TFormIOCodec } from "../../../shared/src/codecs/types/formIO";
import { required, TRequiredCodec } from "../../../shared/src/codecs/types/required";
import { CaseId, TCaseIdCodec } from "../CaseId";
import { CaseMemberForm, TCaseMemberFormCodec } from "../form/CaseMemberForm";

export const NewCaseMemberFormIO: TFormIOCodec<TCaseIdCodec, TRequiredCodec<{
    member: TCaseMemberFormCodec;
}>> = formIO(
    CaseId,
    required({
        member: CaseMemberForm,
    }),
);

export type TNewCaseMemberFormIOCodec = typeof NewCaseMemberFormIO;

export type TNewCaseMemberFormIO = TTypeOfCodec<TNewCaseMemberFormIOCodec>;