import React from "react";

export default (props: React.SVGAttributes<SVGElement>) => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g clipPath="url(#clip0)">
<path d="M19 13.3V8H16V10.6L12 7L2 16H5V24H11V18H13V24H19V16H22L19 13.3ZM17 22H15V16H9V22H7V14.19L12 9.69L17 14.19V22Z" fill="black"/>
<path d="M10 14H14C14 12.9 13.1 12 12 12C10.9 12 10 12.9 10 14Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>
;
