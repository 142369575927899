import { union } from "../../shared/src/codecs/types/union";
import { literal } from "../../shared/src/codecs/types/literal";

export const ListingParkingType = union([
    literal("allocated"),
    literal("communal"),
    literal("covered"),
    literal("garage"),
    literal("driveway"),
    literal("gated"),
    literal("off_street"),
    literal("on_street"),
    literal("rear"),
    literal("permit"),
    literal("private"),
    literal("residents"),
    literal("secure"),
    literal("undercroft"),
    literal("underground"),
    literal("visitor"),
    literal("none"),
]);
