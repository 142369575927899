import { overload } from "../../../shared/src/codecs/types/overload";
import { required } from "../../../shared/src/codecs/types/required";
import { boolean } from "../../../shared/src/codecs/types/boolean";
import { array } from "../../../shared/src/codecs/types/array";
import { unknown } from "../../../shared/src/codecs/types/unknown";

export const CaseSingleClientTransacting = overload(
    boolean(),
    required({
        users: array(unknown()),
    }),
    ({users}) =>
        users.length > 1 ? false : true,
);
export type TCaseSingleClientTransactingCodec = typeof CaseSingleClientTransacting;
