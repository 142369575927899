import { TCodec } from "../codec";
import { TError, errorConstants } from "../errors";
import { either } from "fp-ts/lib";
import { DateTime } from "luxon";

export type TAnyDateCodec = TCodec<"DateCodec", undefined,string, string>;

export type TDateCodec = TCodec<
    "DateCodec",
    undefined,
    string,
    string
>;

export const decode = (input: unknown): either.Either<TError, string> => {
    const decoded =  typeof input === "string"
        ? DateTime.fromISO(input).toUTC().toFormat("yyyy-MM-dd")
        : "Invalid DateTime";
    return decoded === "Invalid DateTime"
        ? either.left([[errorConstants.UTC_DATE_VALIDATION, ""]])
        : either.right(decoded);
};

export const date = (): TAnyDateCodec => ({
    type: "DateCodec",
    payload: undefined,
    decode,
    decodeNewDefault: (input: unknown): either.Either<TError, string> =>
        input === ""
        ? either.right("")
        : decode(input)
    ,
    newDefault: () => "",
});
