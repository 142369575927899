import * as t from "io-ts";
import * as JsonResponse1 from "./JsonResponse1";
import * as User4 from "../models/User4";

export const codec = JsonResponse1.createCodec(
    User4.codec,
    t.type({}),
);

export type T = t.TypeOf<typeof codec>;

export const newDefault = (): T => ({
    data: User4.newDefault(),
    meta: {},
});
