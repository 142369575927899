import * as t from "io-ts";
import * as util from "../../shared/src/util";

type TDisplayName =
    "Unknown"
    | "Owner Initiated"
    | "Repossession"
    | "Probate"
;

export const codec = t.union([
    t.literal("unknown"),
    t.literal("owner_initiated"),
    t.literal("repossession"),
    t.literal("probate"),
]);

export const values = util.typeValuesFromCodec(codec.types as Array<t.LiteralC<T>>);

type T = t.TypeOf<typeof codec>;

export const fromValueToDisplayName = (value: T): TDisplayName => {
    switch (value) {
        case "unknown":
            return "Unknown";
        case "owner_initiated":
            return "Owner Initiated";
        case "repossession":
            return "Repossession";
        case "probate":
            return "Probate";
        default:
            return util.requireExhaustive(value);
    }
};
