import { required } from "../../shared/src/codecs/types/required";
import { TTypeOfCodec } from "../../shared/src/codecs/codec";
import { union } from "../../shared/src/codecs/types/union";
import { literal } from "../../shared/src/codecs/types/literal";
import { string } from "../../shared/src/codecs/types/string";
import { uuid } from "../../shared/src/codecs/types/uuid";
import { dateTime } from "../../shared/src/codecs/types/dateTime";
import { intersection } from "../../shared/src/codecs/types/intersection";

import { requiredFlatOverloaded } from "../../shared/src/codecs/types/requiredFlatOverloaded";
import { UsersIdChecksImageArray } from "./overloads/UsersIdChecksImage";
import { boolean } from "../../shared/src/codecs/types/boolean";

import { nonEmptyString } from "../../shared/src/codecs/types/nonEmptyString";
import { UsersIdCheckHasExpired } from "./overloads/UsersIdCheckHasExpired";
import { UsersIdCheckUploadedProofOfAddressUrl } from "./overloads/UsersIdCheckUploadedProofOfAddressUrl";

export const UsersIdCheckIdentityDocumentStatus = union([
    literal("unknown"),
    literal("pass"),
    literal("fail"),
]);

export const UsersIdCheckAddressStatus = union([
    literal("unknown"),
    literal("pass"),
    literal("more_proof_required"),
    literal("fail"),
]);

export const UsersIdCheckPepsSanctionsStatus = union([
    literal("unknown"),
    literal("pass"),
    literal("requires_investigation"),
    literal("fail"),
]);

export const UsersIdCheck1 = required({
    id_check_result_identity_document_status: UsersIdCheckIdentityDocumentStatus,
});

export const UsersIdCheckAddress = required({
    id_check_result_address_status: UsersIdCheckAddressStatus,
});

export const UsersIdCheck2 = required({
    id_check_result_peps_sanctions_status: UsersIdCheckPepsSanctionsStatus,
});

export const UsersIdCheckPotentialFraudOutcomeStatus = union([
    literal("unknown"),
    literal("not_fraud"),
    literal("is_fraud"),
]);
export const UsersIdCheck5 = required({
    potential_fraud_outcome: UsersIdCheckPotentialFraudOutcomeStatus,
});

export const UsersIdCheck3 = intersection([
    required({
        id: uuid(),
    }),
    UsersIdCheck1,
    UsersIdCheckAddress,
    UsersIdCheck2,
    UsersIdCheck5,
]);
export type TUsersIdCheck3Codec = typeof UsersIdCheck3;

export const UsersIdCheck4 = intersection([
    required({
        users__cases_id: uuid(),
        id_check_result_peps_sanctions_report: string(),
        created_at: dateTime(),
        potential_fraud: boolean(),
    }),
    requiredFlatOverloaded({
        id_check_image_urls: UsersIdChecksImageArray,
    }),
    requiredFlatOverloaded({
        has_expired: UsersIdCheckHasExpired,
    }),
    requiredFlatOverloaded({
        uploaded_proof_of_address_url: UsersIdCheckUploadedProofOfAddressUrl,
    }),
]);
export type TUsersIdCheck4Codec = typeof UsersIdCheck4;

export const UsersIdCheckPassed = required({
    id_check_result_identity_document_status: literal("pass"),
    id_check_result_address_status: literal("pass"),
    id_check_result_peps_sanctions_status: literal("pass"),
});

export const UsersIdCheck7 = intersection([
    required({
        id: uuid(),
    }),
    UsersIdCheck1,
]);
export type TUsersIdCheck7Codec = typeof UsersIdCheck7;
export type TUsersIdCheck7 = TTypeOfCodec<TUsersIdCheck7Codec>;

export const UsersIdCheck8 = required({
    created_at: dateTime(),
    expires_at: dateTime(),
});
export type TUsersIdCheck8Codec = typeof UsersIdCheck8;
export type TUsersIdCheck8 = TTypeOfCodec<TUsersIdCheck8Codec>;
