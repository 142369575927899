import React from "react";

export default (props: React.SVGAttributes<SVGElement>) => 
    <svg 
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path 
            d="M4 15C3.16667 15 2.45833 14.7083 1.875 14.125C1.29167 13.5417 1 12.8333 1 12C1 11.1667 1.29167 10.4583 1.875 9.875C2.45833 9.29167 3.16667 9 4 9V7C4 6.45 4.19583 5.97917 4.5875 5.5875C4.97917 5.19583 5.45 5 6 5H9C9 4.16667 9.29167 3.45833 9.875 2.875C10.4583 2.29167 11.1667 2 12 2C12.8333 2 13.5417 2.29167 14.125 2.875C14.7083 3.45833 15 4.16667 15 5H18C18.55 5 19.0208 5.19583 19.4125 5.5875C19.8042 5.97917 20 6.45 20 7V9C20.8333 9 21.5417 9.29167 22.125 9.875C22.7083 10.4583 23 11.1667 23 12C23 12.8333 22.7083 13.5417 22.125 14.125C21.5417 14.7083 20.8333 15 20 15V19C20 19.55 19.8042 20.0208 19.4125 20.4125C19.0208 20.8042 18.55 21 18 21H6C5.45 21 4.97917 20.8042 4.5875 20.4125C4.19583 20.0208 4 19.55 4 19V15ZM6 19H18V7H6V19Z"
            fill="black"
        />
        <path 
            d="M7 12.2L7.8 13L9 11.8143L10.2 13L11 12.2L9.81429 11L11 9.8L10.2 9L9 10.2L7.8 9L7 9.8L8.2 11L7 12.2Z" 
            fill="black"
        />
        <path 
            d="M13 12.2L13.8 13L15 11.8143L16.2 13L17 12.2L15.8143 11L17 9.8L16.2 9L15 10.2L13.8 9L13 9.8L14.2 11L13 12.2Z" 
            fill="black"
        />
        <path 
            d="M11.9996 16.5502L10.4996 18.0502L8.99961 16.5502L8.02461 17.5252L6.97461 16.4752L8.99961 14.4502L10.4996 15.9502L11.9996 14.4502L13.4996 15.9502L14.9996 14.4502L17.0246 16.4752L15.9746 17.5252L14.9996 16.5502L13.4996 18.0502L11.9996 16.5502Z" 
            fill="black"
        />
    </svg>
;
