import { literal } from "../../shared/src/codecs/types/literal";
import { union } from "../../shared/src/codecs/types/union";

export const SellingPropertyState = union([
    literal("unknown"),
    literal("property_to_sell_not_on_the_market"),
    literal("property_to_sell_on_the_market"),
    literal("property_to_sell_under_offer"),
    literal("property_to_sell_already_exchanged"),
    literal("no_property_to_sell"),
    literal("property_to_let"),
]);
