import { TCodec } from "../codec";
import { either } from "fp-ts/lib";
import { decode as dateTimeDecode } from "./dateTime";
import { TError } from "../errors";

export type TAnyDateTimeOrNullAsBooleanCodec = TCodec<"DateTimeOrNullAsBooleanCodec", undefined, string | null, string | null>;

export const dateTimeOrNullAsBoolean = (): TAnyDateTimeOrNullAsBooleanCodec => ({
    type: "DateTimeOrNullAsBooleanCodec",
    payload: undefined,
    decode: (input: unknown): either.Either<TError, string | null> =>
        input === null ? either.right(null)
        : dateTimeDecode(input),
    decodeNewDefault: (input: unknown): either.Either<TError, string | null> =>
        input === null ? either.right(null)
        : dateTimeDecode(input),
    newDefault: () => null,
});