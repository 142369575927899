import * as t from "io-ts";
import { array, option, identity } from "fp-ts";
import { pipe } from "fp-ts/lib/function";

export const codec = t.type({
    id: t.string,
    first_name: t.string,
    last_name: t.string,
    email: t.union([t.string, t.null]),
});

export type T = t.TypeOf<typeof codec>;

export const newDefault = (): T => ({
    id: "",
    email: "",
    first_name: "",
    last_name: "",
});

export const toDisplayName = (user: T): string =>
    user.first_name || user.last_name ? `${user.first_name} ${user.last_name}`
        : user.email ? user.email
        : "";

export const usersToNamesString = (users: Array<T>, namesNotGivenTranslation: () => string): string =>
     pipe(
        users,
        option.fromPredicate(array.isNonEmpty),
        option.fold(
            namesNotGivenTranslation,
            (userArray) => userArray
                .map((user) => `${user.first_name} ${user.last_name}`)
                .join(", ")
        ),
        option.fromPredicate((names) => names.trim().length > 0),
        option.fold(
            namesNotGivenTranslation,
            identity.flatten
        )
    );
