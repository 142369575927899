import { TTypeOfCodec } from "../../shared/src/codecs/codec";
import { boolean } from "../../shared/src/codecs/types/boolean";
import { intersection } from "../../shared/src/codecs/types/intersection";
import { literal } from "../../shared/src/codecs/types/literal";
import { overload } from "../../shared/src/codecs/types/overload";
import { required } from "../../shared/src/codecs/types/required";
import { requiredFlatOverloaded } from "../../shared/src/codecs/types/requiredFlatOverloaded";
import { union } from "../../shared/src/codecs/types/union";
import { SellingPropertyState } from "./SellingPropertyState";
import { PaymentMethod } from "./PaymentMethod";
import { HasProvidedConveyancerDetails } from "./HasProvidedConveyancerDetails";

const EstimatedTimeToCompletion1 = union([literal("fast"), literal("slow")]);

export type TEstimatedTimeToCompletion1 = TTypeOfCodec<typeof EstimatedTimeToCompletion1>;

export const EstimatedTimeToCompletion2 = overload(
    EstimatedTimeToCompletion1,
    intersection([
        required({
            selling_property_state: SellingPropertyState,
            payment_method: PaymentMethod,
            mortgage_broker_has_vouched: boolean(),
        }),
        requiredFlatOverloaded({
            has_provided_conveyancer_details: HasProvidedConveyancerDetails,
        }),
    ]),
    (details) => details.has_provided_conveyancer_details
        && details.selling_property_state === "no_property_to_sell"
        && (
            details.payment_method === "cash"
            || details.mortgage_broker_has_vouched
        )
            ? "fast" as const
            : "slow" as const,
);