import React from "react";

export default (props: React.SVGAttributes<SVGElement>) => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g clipPath="url(#clip0)">
<path d="M17 2H7C5.9 2 5 2.9 5 4V20L12 17L19 20V4C19 2.9 18.1 2 17 2ZM17 17L12 14.82L7 17V4H17V17Z" fill="#00011B"/>
</g>
<defs>
<clipPath id="clip0">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>
;
