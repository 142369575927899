import { required } from "../../shared/src/codecs/types/required";
import { uuid } from "../../shared/src/codecs/types/uuid";
import { string } from "../../shared/src/codecs/types/string";
import { array } from "../../shared/src/codecs/types/array";
import { union } from "../../shared/src/codecs/types/union";
import { literal } from "../../shared/src/codecs/types/literal";
import { nullCodec } from "../../shared/src/codecs/types/nullCodec";
import { postcode } from "../../shared/src/codecs/types/postcode";
import { TTypeOfCodec } from "../../shared/src/codecs/codec";
import { TransactionType1 } from "./TransactionType";

export const CompanyNumberType = union([literal("company_number"), literal("other_side_company_number")]);
export type TCompanyNumberType = TTypeOfCodec<typeof CompanyNumberType>;

export const CompanyNumberConflictRow = required({
    id: uuid(),
    company_number: string(),
    type: CompanyNumberType,
});
export type TCompanyNumberConflictRow = TTypeOfCodec<typeof CompanyNumberConflictRow>;

export const PostcodeType = union([
    literal("property_postcode"),
    literal("case_company_postcode"),
    literal("case_other_side_company_postcode"),
    literal("client_correspondence_postcode"),
    literal("other_side_client_correspondence_postcode"),
]);
export type TPostcodeType = TTypeOfCodec<typeof PostcodeType>;

export const PostcodeConflictRow = required({
    type: PostcodeType,
    case_id: uuid(),
    postcode: string(),
    property_id: union([uuid(), nullCodec()]),
    user_id: union([uuid(), nullCodec()]),
});
export type TPostcodeConflictRow = TTypeOfCodec<typeof PostcodeConflictRow>;

export const UserType = union([
    literal("our_side_user"),
    literal("other_side_user")
]);
export type TUserType = TTypeOfCodec<typeof UserType>;

export const UserLastNameConflictType = union([
    literal("our_side_user_last_name"),
    literal("other_side_user_last_name")
]);
export type TUserLastNameConflictType = TTypeOfCodec<typeof UserLastNameConflictType>;

export const HighRiskConflictType = union([
    literal("high_risk_old_case_company_number"),
    literal("high_risk_old_case_client_last_name")
]);
export type THighRiskConflictType = TTypeOfCodec<typeof HighRiskConflictType>;

export const LastNamesConflictRow = required({
    id: uuid(),
    legal_last_legal_name: string(),
    case_id: uuid(),
    type: UserLastNameConflictType,
});
export type TLastNamesConflictRow = TTypeOfCodec<typeof LastNamesConflictRow>;

export const CaseWithDetailsForCoi = required({
    id: uuid(),
    company_number: string(),
    other_side_company_number: string(),
    company_postcode: union([postcode(), nullCodec()]),
    other_side_company_postcode: union([postcode(), nullCodec()]),
    case_properties: array(
        required({
            id: uuid(),
            postcode: union([postcode(), nullCodec()]),
        })
    ),
    transaction_type: TransactionType1,
});
export type TCaseWithDetailsForCoi = TTypeOfCodec<typeof CaseWithDetailsForCoi>;

export const RelatedCaseRowsForCoi = required({
    purchase_case_id: union([uuid(), nullCodec()]),
    sale_case_id: union([uuid(), nullCodec()]),
});
export type TRelatedCaseRowsForCoi = TTypeOfCodec<typeof RelatedCaseRowsForCoi>;

export const DualRepCaseRowsForCoi = required({
    original_case_id: uuid(),
    dual_rep_case_id: uuid(),
});
export type TDualRepCaseRowsForCoi = TTypeOfCodec<typeof DualRepCaseRowsForCoi>;

export const UserForCoi = required({
    id: uuid(),
    legal_last_legal_name: string(),
    postcode: string(),
    type: UserType,
    case_id: uuid(),
});
export type TUserForCoi = TTypeOfCodec<typeof UserForCoi>;

export const CoiSourceTarget = union([
    UserLastNameConflictType,
    CompanyNumberType,
    PostcodeType,
    HighRiskConflictType
]);
export type TCoiSourceTarget = TTypeOfCodec<typeof CoiSourceTarget>;

export const CaseConflictOfInterestStatusProceedable = union([
    literal("resolved_no_conflict"),
    literal("resolved_conflict_but_good_to_conitnue"),
]);

export const CaseConflictOfInterestStatusNotProceedable = union([
    literal("flagged"),
    literal("resolved_conflict_but_unable_to_conitnue"),
]);

export const CaseConflictOfInterestStatus = union([
    ...CaseConflictOfInterestStatusProceedable.payload,
    ...CaseConflictOfInterestStatusNotProceedable.payload,
]);
export type TCaseConflictOfInterestStatus = TTypeOfCodec<typeof CaseConflictOfInterestStatus>;

export const CaseConflictOfInterestProceedable = required({
    status: CaseConflictOfInterestStatusProceedable,
});
