import { eq } from "fp-ts";

export type TTuple<A, B> = [A, B];
type TGenericTuple = TTuple<unknown, unknown>;
type TTupleMember = "head" | "tail";

const getMember = <T extends TGenericTuple>(member: TTupleMember) =>
    (tuple: T) =>
        member === "head" ? tuple[0] : tuple[1];


const updateHead = <T extends TGenericTuple, V>(value: V) =>
    (tuple: T) => {
        tuple[0] = value;
        return tuple;
    }

const updateTail = <T extends TGenericTuple, V>(value: V) =>
    (tuple: T) => {
        tuple[1] = value;
        return tuple;
    }

const isIdentical = <T extends TGenericTuple>(tupleA: T) => 
    (tupleB: T): boolean =>
        eq.eqStrict.equals(
            getMember("tail")(tupleA),
            getMember("tail")(tupleB),
        )

const isDifferent = <T extends TGenericTuple>(tupleA: T) => 
    (tupleB: T): boolean =>
        !isIdentical(tupleA)(tupleB);

export const tuple = {
    getMember,
    updateHead,
    updateTail,
    isIdentical,
    isDifferent,
};

