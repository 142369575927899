import { TTypeOfCodec } from "../../shared/src/codecs/codec";
import { required } from "../../shared/src/codecs/types/required";
import { string } from "../../shared/src/codecs/types/string";
import { array } from "../../shared/src/codecs/types/array";
import { form } from "../../shared/src/codecs/types/form";
import { EmptyObject } from "./EmptyObject";
import { nullCodec } from "../../shared/src/codecs/types/nullCodec";
import { union } from "../../shared/src/codecs/types/union";
import { intersection } from "../../shared/src/codecs/types/intersection";
import { partial } from "../../shared/src/codecs/types/partial";


export const EmailAttachments = required({
    enquiries: array(string()),
    files: array(string()),
    redemption_statements: array(string()),
    quotes: array(string()),
});

export type TEmailAttachmentsCodec = typeof EmailAttachments;
export type TEmailAttachments = TTypeOfCodec<TEmailAttachmentsCodec>;

export const EmailComposition = intersection([
    partial({
        emailId: string(),
    }),
    required({
        caseID: union([string(), nullCodec()]),
        subject: string(),
        sentFrom: string(),
        sendTo: array(string()),
        cc_addresses: array(string()),
        bcc_addresses: array(string()),
        body: string(),
        body_draft: string(),
        attachments: EmailAttachments,
    }),
]);
export type TEmailCompositionCodec = typeof EmailComposition;
export type TEmailComposition = TTypeOfCodec<TEmailCompositionCodec>;

export const AddEmailUserForm = form( 
    required({
        email: string(),
        first_name: string(),
        last_name: string(),
    }),
    EmptyObject,
);
export type TAddEmailUserFormCodec = typeof AddEmailUserForm;
export type TAddEmailUserForm = TTypeOfCodec<TAddEmailUserFormCodec>;
