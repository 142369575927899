import * as t from "io-ts";
import * as regexes from "../../shared/src/regexes";
import { string } from "../../shared/src/validation/basic/string";
import { object } from "../../shared/src/validation/create/object";
import { regex } from "../../shared/src/validation/create/regex";
import { TValidationFunction } from "../../shared/src/validation/Function";

export const codec = t.partial({
    email: t.string,
    first_name: t.string,
    last_name: t.string,
    phone_number: t.string,
});

export type T = t.TypeOf<typeof codec>;

export const validator: TValidationFunction =
    object({
        email: regex(regexes.constants.EMAIL_REGEX),
        first_name: string,
        last_name: string,
        phone_number: regex(regexes.constants.MOBILE_PHONE_REGEX),
    });

export const newDefault = (): T => ({});
