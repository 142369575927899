import React from "react";
import { CRMParagraph } from "../Simple/CRMParagraph/CRMParagraph";

export class CRMInputLabel extends React.Component<React.PropsWithChildren<{}>> {
    public render (): JSX.Element {
        return (
            <CRMParagraph>
                <strong>
                    {this.props.children}
                </strong>
            </CRMParagraph>
        );
    }
}
