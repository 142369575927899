import { TTypeOfCodec } from "../../../shared/src/codecs/codec";
import { formIO, TFormIOCodec } from "../../../shared/src/codecs/types/formIO";
import { EmptyObject, TEmptyObjectCodec } from "../EmptyObject";
import { TUuidObjectCodec, UuidObject } from "../Id";

export const DeleteListingPhotoFormIO: TFormIOCodec<TUuidObjectCodec, TEmptyObjectCodec> = formIO(
    UuidObject,
    EmptyObject,
);

export type TDeleteListingPhotoFormIOCodec = typeof DeleteListingPhotoFormIO;

export type TDeleteListingPhotoFormIO = TTypeOfCodec<TDeleteListingPhotoFormIOCodec>;