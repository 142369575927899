import * as JsonErrorCode1 from "../models/JsonErrorCode1";
import * as JsonInnerError1 from "../models/JsonInnerError1";
import * as JsonInnerErrorCode1 from "../models/JsonInnerErrorCode1";
import * as RequestSource1 from "../models/RequestSource1";
import * as t from "io-ts";
import { array, option, eq, nonEmptyArray, string } from "fp-ts/lib";
import { pipe } from "fp-ts/function";
import { TValidationError } from "../../shared/src/validation/Error";

export const codec = t.type({
    error_code: JsonErrorCode1.codec,
    inner_errors: t.array(JsonInnerError1.codec),
});

export type T = t.TypeOf<typeof codec>;

export const malformedJson = (): T =>
    createResponseError(
        JsonErrorCode1.constants.BAD_REQUEST,
        [
            JsonInnerError1.create(
                JsonInnerErrorCode1.constants.MALFORMED_JSON,
                RequestSource1.constants.BODY
            ),
        ]
    );

export const malformedJsonQueryStringFilters = (): T =>
    createResponseError(
        JsonErrorCode1.constants.BAD_REQUEST,
        [
            JsonInnerError1.create(
                JsonInnerErrorCode1.constants.MALFORMED_JSON,
                RequestSource1.constants.QUERY_STRING,
                ["filters"],
            ),
        ]
    );

export const malformedUrlEncodedData = (): T =>
    createResponseError(
        JsonErrorCode1.constants.BAD_REQUEST,
        [
            JsonInnerError1.create(
                JsonInnerErrorCode1.constants.MALFORMED_URL_ENCODED_DATA,
                RequestSource1.constants.BODY
            ),
        ]
    );

export const malformedMultipartFormData = (): T =>
    createResponseError(
        JsonErrorCode1.constants.BAD_REQUEST,
        [
            JsonInnerError1.create(
                JsonInnerErrorCode1.constants.MALFORMED_MULTIPART_FORM_DATA,
                RequestSource1.constants.BODY
            ),
        ]
    );

export const internalServerError = (): T =>
    createResponseError(JsonErrorCode1.constants.INTERNAL_SERVER_ERROR, []);

export const accessTokenInvalid = (): T =>
    createResponseError(JsonErrorCode1.constants.UNAUTHORIZED, [
        JsonInnerError1.create(
            JsonInnerErrorCode1.constants.ACCESS_TOKEN_INVALID,
            RequestSource1.constants.HEADER,
            ["authorization"],
        ),
    ]);

export const twoFactorRequired = (): T =>
    createResponseError(JsonErrorCode1.constants.UNAUTHORIZED, [
        JsonInnerError1.create(
            JsonInnerErrorCode1.constants.TWO_FACTOR_AUTHENTICATION_REQUIRED,
            RequestSource1.constants.HEADER,
            ["authorization"],
        ),
    ]);

export const sessionInvalid = (): T =>
    createResponseError(JsonErrorCode1.constants.UNAUTHORIZED, [
        JsonInnerError1.create(
            JsonInnerErrorCode1.constants.SESSION_INVALID,
            RequestSource1.constants.HEADER,
            ["authorization"],
        ),
    ]);

export const originNotAllowed = (): T =>
    createResponseError(JsonErrorCode1.constants.FORBIDDEN, [
        JsonInnerError1.create(
            JsonInnerErrorCode1.constants.ORIGIN_NOT_ALLOWED,
            RequestSource1.constants.HEADER,
            ["origin"],
        ),
    ]);

export const actionNotAllowed = (source: RequestSource1.T, keys: Array<string>): T =>
    createResponseError(JsonErrorCode1.constants.FORBIDDEN, [
        JsonInnerError1.create(
            JsonInnerErrorCode1.constants.ACTION_NOT_ALLOWED,
            source,
            keys,
        ),
    ]);

export const unprocessableEntity = (source: RequestSource1.T, validationErrors: TValidationError): T =>
    createResponseError(JsonErrorCode1.constants.UNPROCESSABLE_ENTITY, JsonInnerError1.arrayFromValidationErrors(source, validationErrors));

export const unsupportedMediaType = (source: RequestSource1.T, validationErrors: TValidationError): T =>
    createResponseError(JsonErrorCode1.constants.UNSUPPORTED_MEDIA_TYPE, JsonInnerError1.arrayFromValidationErrors(source, validationErrors));

export const badRequest = (source: RequestSource1.T, validationErrors: TValidationError): T =>
    createResponseError(JsonErrorCode1.constants.BAD_REQUEST, JsonInnerError1.arrayFromValidationErrors(source, validationErrors));

export const badRequestNonValidation = (innerError: JsonInnerErrorCode1.T, key?: string, source?: RequestSource1.T): T =>
    createResponseError(JsonErrorCode1.constants.BAD_REQUEST, [
        JsonInnerError1.create(
            innerError,
            source ? source : RequestSource1.constants.BODY,
            key ? [key] : [],
        ),
    ]);

export const resourceNotFound = (key?: string | Array<string>, source?: RequestSource1.T): T =>
    createResponseError(JsonErrorCode1.constants.NOT_FOUND, [
        JsonInnerError1.create(
            JsonInnerErrorCode1.constants.RESOURCE_NOT_FOUND,
            source ? source : RequestSource1.constants.PATH,
            key ? typeof key === "string" ? [key] : key : [],
        ),
    ]);

export const resourceInUse = (key?: string | Array<string>, source?: RequestSource1.T): T =>
    createResponseError(JsonErrorCode1.constants.IN_USE, [
        JsonInnerError1.create(
            JsonInnerErrorCode1.constants.RESOURCE_IN_USE,
            source ? source : RequestSource1.constants.PATH,
            key ? typeof key === "string" ? [key] : key : [],
        ),
    ]);

export const featureNotEnabledYet = (key?: string | Array<string>, source?: RequestSource1.T): T =>
    createResponseError(JsonErrorCode1.constants.BAD_REQUEST, [
        JsonInnerError1.create(
            JsonInnerErrorCode1.constants.FEATURE_NOT_ENABLED_YET,
            source ? source : RequestSource1.constants.BODY,
            key ? typeof key === "string" ? [key] : key : [],
        ),
    ]);

export const notAuthorizedForResource = (key?: string): T =>
    createResponseError(JsonErrorCode1.constants.UNAUTHORIZED, [
        JsonInnerError1.create(
            JsonInnerErrorCode1.constants.NOT_AUTHORIZED_FOR_RESOURCE,
            RequestSource1.constants.PATH,
            key ? [key] : [],
        ),
    ]);

const createResponseError = (errorCode: JsonErrorCode1.T, innerErrors: Array<JsonInnerError1.T>): T => (
    {
        error_code: errorCode,
        inner_errors: innerErrors,
    }
);

export const is = (v: Record<string, unknown>): v is T => "error_code" in v && "inner_errors" in v;

export const getInnerErrorsForTargetKeys = (targetKeys: Array<string>, innerErrors: T["inner_errors"]): Array<JsonInnerError1.T> =>
    array.filterMap<JsonInnerError1.T, JsonInnerError1.T>((innerError) =>
        array.intersection(eq.eqString)(targetKeys, innerError.error_target.keys).length ? option.some(innerError) : option.none,
    )(innerErrors);

export const getAllTargetKeys = (innerErrors: T["inner_errors"]): Array<string> =>
    pipe(
        innerErrors,
        array.map((innerError) => innerError.error_target.keys),
        array.flatten,
        array.uniq(string.Eq),
    )

export const doTargetKeysHaveInnerErrors = (targetKeys: Array<string>, innerErrors: T["inner_errors"]): boolean =>
    getInnerErrorsForTargetKeys(targetKeys, innerErrors).length > 0;

export const getInnerErrorsForTargetKeysErrorMessageString = (targetKeys: Array<string>, innerErrors: T["inner_errors"]): string => {
    const targetKeysHaveInnerErrors = doTargetKeysHaveInnerErrors(targetKeys, innerErrors);
    const innerErrorsForTargetKeysArray = targetKeysHaveInnerErrors ? getInnerErrorsForTargetKeys(targetKeys, innerErrors) : [];
    return targetKeysHaveInnerErrors
        ? `Validation errors:${innerErrorsForTargetKeysArray.map(
                (innerError) => (" " + innerError.error_code))}`
        : "";
};

export const doesInnerErrorExist = (innerErrorCode: T["inner_errors"][number]["error_code"], innerErrors: T["inner_errors"]): boolean =>
     pipe(
        innerErrors,
        array.filter((innerError) => innerError.error_code === innerErrorCode),
        nonEmptyArray.fromArray,
        option.fold(
            () => false,
            () => true,
        ),
    );
