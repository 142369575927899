import { TTypeOfCodec } from "../../shared/src/codecs/codec";
import { required } from "../../shared/src/codecs/types/required";
import { string } from "../../shared/src/codecs/types/string";
import { uuid } from "../../shared/src/codecs/types/uuid";

export const UsersCasesUserLegalName = required({
    legal_first_legal_name: string(),
    legal_middle_legal_name: string(),
    legal_last_legal_name: string(),
    users__cases_id: uuid(),
});
export type TUsersCasesUserLegalNameCodec = typeof UsersCasesUserLegalName;
export type TUsersCasesUserLegalName = TTypeOfCodec<TUsersCasesUserLegalNameCodec>;