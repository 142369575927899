import { Observable } from "rxjs";
import * as rxjsOperators from "rxjs/operators";
import * as rxjs from "rxjs";
import * as TGetState from "./TGetState";
import * as TSetState from "./TSetState";
import { TDispatch } from "./TDispatch";
import * as TForm from "../models/TForm";
import { TState, stateLens, TStateLens } from "../../../domain/codecs/state";
import { TAction, TActionPayload, TPayloadActionType } from "./actions/TAction";

export type TFormUpdateActionPayload<P, K extends keyof P> = {
    key: K;
    value: P[K];
};

export type TUserActionPayload<P, K extends keyof P> = TFormUpdateActionPayload<P, K> & {userId: string};
export type TUserExistingPhoneNumberActionPayload<P, K extends keyof P> = TFormUpdateActionPayload<P, K> & {userId: string; phoneNumberId: string};

export type TActionObservable<K extends TPayloadActionType, P> = rxjs.Observable<TActionPayload<K, P>>;

export type TActionObservableWithPayload<K extends TPayloadActionType, P> = rxjs.Observable<TActionPayload<K, P>>;

export type TFormActionPayload<P, K extends keyof P> = TFormUpdateActionPayload<P, K> & { resourceId?: string};

export type TUpdateWherePayload = { resourceId?: string };

export type TFormActionPayloadWithIndex<P, K extends keyof P> = TFormUpdateActionPayload<P, K> & { index: number};

export type TUnpackFormActionPayload<T> = TFormActionPayload<Required<TForm.TUnpackEditFormType<T>>, keyof TForm.TUnpackEditFormType<T>>;

// eslint-disable-next-line
type TExtractPayloadFromObservable<T extends rxjs.Observable<any>> = T extends rxjs.Observable<infer P> ? P : never;

export const makeFormUpdateAction = <T extends TPayloadActionType, P, K extends keyof P>(type: T, key: K, value: P[K], resourceId?: string): TActionPayload<T, TFormActionPayload<P, K>> => ({
    type,
    payload: {
        key,
        value,
        resourceId,
    },
});

export const makeAction = <T extends TPayloadActionType, P>(type: T, payload: P): TActionPayload<T, P> => ({
    type,
    payload,
});

type TCodecStateMap = (codecState: TState) => TState;

export type TCodecSetState = (setCodecState: TCodecStateMap) => void;

const createSetCodecState = (setState: TSetState.TSetState): TCodecSetState => (setCodecState: TCodecStateMap) => {
    setState(({...s}) => {
        s.forms = setCodecState(s.forms);
        return s;
    });
};

export type TActionsSet = Array<{
    type: string;
    run: (
        // eslint-disable-next-line
        obs$: rxjs.Observable<any>,
        getState: TGetState.TGetState,
        setState: TSetState.TSetState,
        dispatch: TDispatch,
        l: TStateLens,
        setCodecState: TCodecSetState,
    ) => void;
}>;

export const applyActions = (
    actions: TActionsSet,
    getState: TGetState.TGetState,
    setState: TSetState.TSetState,
    dispatch: TDispatch
) => (actions$: rxjs.Observable<TAction>) => {
    actions.map((a) => {
        a.run(
            actions$.pipe(
                rxjsOperators.filter((ac) =>
                    typeof ac === "object" &&
                    "type" in ac &&
                    ac.type === a.type
                )
            ),
            getState,
            setState,
            dispatch,
            stateLens,
            createSetCodecState(setState)
        );
    });
};

export type TGetCodecState = () => TState;

