import * as t from "io-ts";
import * as regexes from "../regexes";
import * as sharedRegexes from "../../shared/src/regexes";
import { required } from "../../shared/src/validation/basic/required";
import { inOrder } from "../../shared/src/validation/compose/inOrder";
import { object } from "../../shared/src/validation/create/object";
import { regex } from "../../shared/src/validation/create/regex";
import { TValidationFunction } from "../../shared/src/validation/Function";
import * as Enviroment from "./Enviroment";

export const codec = t.type({
    email: t.string,
    redirect_url: t.string,
});

export type T = t.TypeOf<typeof codec>;

export const validator = (env: Enviroment.T): TValidationFunction =>
    object({
        email: inOrder(
            required,
            regex(sharedRegexes.constants.EMAIL_REGEX)
        ),
        redirect_url: inOrder(
            required,
            regex(regexes.constants.VALID_SAILHOMES_REDIRECT_URL(env))
        ),
    });

export const newDefault = (): T => ({
    email: "",
    redirect_url: "",
});
