import React from "react";
import { TFrontMainColours } from "../../../../models/TFrontMainColours";
import { THexColor, TPixelSize } from "../../../../models/StringLiterals";
import { isHexColor } from "../../../../../../shared/src/utilsByDomain/color/isHexColor";
import Cross from "../../../../../src/assets/frontIcons/Property 1=Cross";
import InfoLetter from "../../../../../src/assets/frontIcons/Property 1=Info Letter";
import Info from "../../../../../src/assets/frontIcons/Property 1=Info";
import Money from "../../../../../src/assets/frontIcons/Property 1=Money";
import Plus from "../../../../../src/assets/frontIcons/Property 1=Plus";
import Tick from "../../../../../src/assets/frontIcons/Property 1=Tick";
import CrossFilled from "../../../../../src/assets/frontIcons/Property 1=Cross Filled";
import QuestionMark from "../../../../../src/assets/frontIcons/question-mark";
import BurgerMenu from "../../../../../src/assets/frontIcons/Property 1=Burger Menu";
import IDCard from "../../../../../src/assets/frontIcons/ID Check";
import Coins from "../../../../../src/assets/frontIcons/Coins";
import Bank from "../../../../../src/assets/frontIcons/Bank";
import Contract from "../../../../../src/assets/frontIcons/Contract";
import Done from "../../../../../src/assets/frontIcons/Name=Done";
import ChevronDown from "../../../../../src/assets/frontIcons/Name=Chevron Down";
import ChevronUp from "../../../../../src/assets/frontIcons/Name=Chevron Up";
import ChevronRight from "../../../../../src/assets/frontIcons/Name=Chevron Right";
import PendingLocked from "../../../../../src/assets/frontIcons/Name=Pending Locked";
import ShieldDone from "../../../../../src/assets/frontIcons/Name=Shield Done";
import ShieldError from "../../../../../src/assets/frontIcons/Name=Shield Error";
import SmallCross from "../../../../../src/assets/frontIcons/Name=Small Cross";
import PhoneDelete from "../../../../../src/assets/frontIcons/Name=Delete Phone";
import Edit from "../../../../../src/assets/frontIcons/Name=Edit";
import ShieldCross from "../../../../../src/assets/frontIcons/Name=Shield Cross";
import Pending from "../../../../../src/assets/frontIcons/Name=Pending";
import ExclamationCircle from "../../../../../src/assets/frontIcons/Name=Exclamation Circle";
import EmailOpen from "../../../../../src/assets/frontIcons/Name=Email Open";
import Logout from "../../../../../src/assets/frontIcons/Name=Logout";
import Clock from "../../../../../src/assets/frontIcons/Name=Clock";
import Checked from "../../../../../src/assets/frontIcons/Name=Checked";
import Unchecked from "../../../../../src/assets/frontIcons/Name=Unchecked";
import RadioUnselected from "../../../../../src/assets/frontIcons/Name=Radio Unselected";
import RadioSelected from "../../../../../src/assets/frontIcons/Name=Radio Selected";
import RadioGhostSelected from "../../../../../src/assets/frontIcons/Name=Radio Ghost Selected";
import PlusThin from "../../../../../src/assets/frontIcons/Name=Plus Thin";
import Lock from "../../../../../src/assets/frontIcons/Name=Lock";
import Search from "../../../../../src/assets/frontIcons/Name=Search";
import Camera from "../../../../../src/assets/frontIcons/Name=Camera";
import Measure from "../../../../../src/assets/frontIcons/Name=Measure";
import House from "../../../../../src/assets/frontIcons/Name=House";
import Microscope from "../../../../../src/assets/frontIcons/Name=Microscope";
import Roller from "../../../../../src/assets/frontIcons/Name=Roller";
import Document from "../../../../../src/assets/frontIcons/Name=Document";
import CheckboxUnchecked from "../../../../../src/assets/frontIcons/Icon=Checkbox unchecked";
import CheckboxChecked from "../../../../../src/assets/frontIcons/Icon=Checkbox checked";
import Menu from "../../../../../src/assets/frontIcons/Name=Menu";
import ThumbsUp from "../../../../../src/assets/frontIcons/Name=Thumbs up";
import Paperclip from "../../../../../src/assets/frontIcons/Name=Paperclip";
import Flare from "../../../../../src/assets/frontIcons/Name=Flare";
import SailBoat from "../../../../../src/assets/frontIcons/Name=SailBoat";
import QuestionMarkCircle from "../../../../../src/assets/frontIcons/Name=Question Mark Circle";
import TickCircle from "../../../../../src/assets/frontIcons/Name=Tick Circle";
import ChevronLeft from "../../../../../src/assets/frontIcons/Name=Chevron Left";
import Star from "../../../../../src/assets/frontIcons/Name=Star";
import Lily from "../../../../../src/assets/frontIcons/Name=Lily";
import Feather from "../../../../../src/assets/frontIcons/Name=Feather";
import Bulb from "../../../../../src/assets/frontIcons/Name=Bulb";
import Print from "../../../../../src/assets/frontIcons/Name=Print";

/* IMPORTANT
    - This icon component is setup for single tone not duo tone. All paths will be coloured to one.
    If you want insets make sure they are cliped not coloured.
    - React does not like namesspaces in svgs these look like "xmlns:dc" and similar they will need to be removed
    if they exist. Also not sure if react likes metadata but it is not needed so can be removed as well.
*/

export const iconLib = {
    "cross": Cross,
    "cross-filled": CrossFilled,
    "info-letter": InfoLetter,
    "info": Info,
    "money": Money,
    "plus": Plus, // This icon had to be edited for single tone
    "tick": Tick,
    "question-mark": QuestionMark,
    "burger-menu": BurgerMenu,
    "id-card": IDCard,
    "coins": Coins,
    "bank": Bank,
    "contract": Contract,
    "done": Done,
    "chevron-down": ChevronDown,
    "chevron-up": ChevronUp,
    "chevron-right": ChevronRight,
    "pending-locked" : PendingLocked,
    "shield-done" : ShieldDone,
    "shield-error" : ShieldError,
    "small-cross" : SmallCross,
    "phone-delete" : PhoneDelete,
    "edit" : Edit,
    "shield-cross" : ShieldCross,
    "pending" : Pending,
    "exclamation-circle" : ExclamationCircle,
    "email-open" : EmailOpen,
    "logout" : Logout,
    "clock" : Clock,
    "checked" : Checked,
    "unchecked" : Unchecked,
    "radio-unselected" : RadioUnselected,
    "radio-selected" : RadioSelected,
    "radio-ghost-selected" : RadioGhostSelected,
    "plus-thin" : PlusThin,
    "lock" : Lock,
    "search" : Search,
    "camera" : Camera,
    "measure" : Measure,
    "house" : House,
    "microscope" : Microscope,
    "roller" : Roller,
    "document" : Document,
    "checkbox-unchecked": CheckboxUnchecked,
    "checkbox-checked": CheckboxChecked,
    "menu": Menu,
    "thumbs-up": ThumbsUp,
    "paperclip": Paperclip,
    "flare": Flare,
    "sail-boat": SailBoat,
    "question-mark-circle": QuestionMarkCircle,
    "tick-circle": TickCircle,
    "chevron-left": ChevronLeft,
    "star": Star,
    "lily": Lily,
    "feather": Feather,
    "bulb": Bulb,
    "print": Print,
} as const;

export type TIcon = keyof typeof iconLib;

type TIconSizes = 
      "mini" 
    | "tiny" 
    | "small" 
    | "medium" 
    | "large" 
    | "extra-large" 
    | "extra-extra-large" 
    | "huge"
    | TPixelSize
;

type TIconProps = {
    iconName: TIcon;
    colour: TFrontMainColours | THexColor;
    size?: TIconSizes;
    onClick?: () => void;
}

export const FrontIcon = (props: TIconProps) => {

    const IconElement = iconLib[props.iconName];

    const getIconSizeInPixels = (size: TIconSizes): TPixelSize => {
        switch (size) {
            case "mini" :
                return "14px";
            case "tiny" :
                return "16px";
            case "small" :
                return "20px";
            case "medium" :
                return "24px";
            case "large" :
                return "30px";
            case "extra-large" :
                return "36px";
            case "extra-extra-large" :
                return "40px";
            case "huge":
                return "70px";
            default:
                return size;
        }
    }

    const hasCustomColor = isHexColor(props.colour);

    return (
        <IconElement
            className={`
                front-icon 
                front-icon--colour-${hasCustomColor ? "hex" : props.colour}
            `}
            style={{
                height: getIconSizeInPixels(props.size || "medium"),
                width: getIconSizeInPixels(props.size || "medium"),
                color: hasCustomColor ? props.colour : ""
            }}
            onClick={props.onClick}
        />
    );


}
