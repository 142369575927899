import { TTypeOfCodec } from "../../shared/src/codecs/codec";
import { dateTime } from "../../shared/src/codecs/types/dateTime";
import { longString } from "../../shared/src/codecs/types/longString";
import { required } from "../../shared/src/codecs/types/required";
import { uuid } from "../../shared/src/codecs/types/uuid";

export const CasePropertyNewTitleIssue = required({
    case_property_id: uuid(),
    description: longString(),
});
export type TCasePropertyNewTitleIssueCodec = typeof CasePropertyNewTitleIssue;

export const CasePropertyTitleIssue = required({
    id: uuid(),
    case_property_id: uuid(),
    description: longString(),
    created_at: dateTime(),
});
export type TCasePropertyTitleIssueCodec = typeof CasePropertyTitleIssue;
export type TCasePropertyTitleIssue = TTypeOfCodec<TCasePropertyTitleIssueCodec>;