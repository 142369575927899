import { TTypeOfCodec } from "../../../shared/src/codecs/codec";
import { form, TFormCodec } from "../../../shared/src/codecs/types/form";
import { partial, TPartialCodec } from "../../../shared/src/codecs/types/partial";
import { regex, TAnyRegexCodec } from "../../../shared/src/codecs/types/regex";
import { required, TRequiredCodec } from "../../../shared/src/codecs/types/required";
import { string, TAnyStringCodec } from "../../../shared/src/codecs/types/string";
import { regexes } from "../../../shared/src/regexes";

export const CreateServiceBearerTokenForm: TFormCodec<TRequiredCodec<{
        service_name: TAnyRegexCodec
    }>,
    TPartialCodec<{
        returned_jwt: TAnyStringCodec
    }>
> = 
    form(
        required({
            service_name: regex(regexes.LOWER_NUM_AND_UNDERSCORE),
        }),
        partial({
            returned_jwt: string()
        }),
    );

export type TCreateServiceBearerTokenFormCodec = typeof CreateServiceBearerTokenForm;
export type TCreateServiceBearerTokenForm = TTypeOfCodec<TCreateServiceBearerTokenFormCodec>;