import * as Function from "../Function";
import * as Error from "../Error";
import * as ErrorCode from "../ErrorCode";
import * as ErrorTuple from "../ErrorTuple";
import { array, record, option, nonEmptyArray } from "fp-ts/lib";
import { isObject } from "../../util";

export const object = (params: Record<string, Function.TValidationFunction>): Function.TValidationFunction => (input: unknown): option.Option<Error.TValidationError> => {
        if (typeof input === "undefined") {
            return option.none;
        }

        if (!isObject(input)) {
            return option.some([[ErrorCode.validationErrorCodeConstants.OBJECT_VALIDATION]]);
        }

        const errors = array.foldMap<Array<ErrorTuple.TValidationErrorTuple>>(
            array.getMonoid<ErrorTuple.TValidationErrorTuple>()
        )<[string, Function.TValidationFunction]>(
            ([key, validator]) =>
                option.fold(
                    () => [],
                    (result: Error.TValidationError) =>
                        array.map<ErrorTuple.TValidationErrorTuple, ErrorTuple.TValidationErrorTuple>(
                            ([code, path]) => [code, path ? `${key}.${path}` : `${key}`]
                        )(result)
                )(validator(input[key]))
        )(record.toArray(params));
        return nonEmptyArray.fromArray(errors);
    };
