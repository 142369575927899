import * as t from "io-ts";

export const constants = {
    BODY: "Body" as const,
    PATH: "Path" as const,
    QUERY_STRING: "QueryString" as const,
    HEADER: "Header" as const,
};

export const codec = t.union([
    t.literal(constants.BODY),
    t.literal(constants.PATH),
    t.literal(constants.QUERY_STRING),
    t.literal(constants.HEADER),
]);

export type T = t.TypeOf<typeof codec>;
