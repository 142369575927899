import { nonEmptyArray } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import { NonEmptyArray } from "fp-ts/lib/NonEmptyArray";
import { literal } from "../shared/src/codecs/types/literal";
import { union } from "../shared/src/codecs/types/union";

export const ListingGeneralJobs = [
    "sh_listing_ad_hoc"
] as const;

export type TListingGeneralJobType = typeof ListingGeneralJobs[number];

export const ListingServiceJobs = [
    "sh_progress_additional_service"
] as const;

export type TListingServiceJobType = typeof ListingServiceJobs[number];

export const ListingCarSaleJobs = [
    "sh_progress_car_sale"
] as const;

export type TListingCarSaleJobType = typeof ListingCarSaleJobs[number];

export const ListingPublicNoticeJobs = [
    "sh_rightmove_public_notice"
] as const;

export type TJobListingPublicNoticeJobType = typeof ListingPublicNoticeJobs[number];


export const ListingEnquiryAdHocJobs = [
    "sh_listing_enquiry_ad_hoc"
] as const;

export type TJobListingEnquiryAdHocJobType = typeof ListingEnquiryAdHocJobs[number];

export const CaseAdHocJobs = [
    "sl_case_ad_hoc"
] as const;

export type TJobCaseAdHocJobType = typeof CaseAdHocJobs[number];


export const ListingEnquiryOffersShareFeedbackWithBuyerJobs = [
    "sh_offers_share_feedback_with_buyer"
] as const;

export type TJobListingEnquiryOffersShareFeedbackWithBuyerJobType = typeof ListingEnquiryOffersShareFeedbackWithBuyerJobs[number];


export const ListingPrepJobs = [
    "sh_listing_prep_order_freehold_title_register",
    "sh_listing_prep_order_freehold_title_plan",
    "sh_listing_prep_order_leasehold_title_register",
    "sh_listing_prep_order_leasehold_title_plan",
    "sh_listing_prep_order_leasehold_lease",
    "sh_listing_prep_order_local_authority_search",
    "sh_listing_prep_order_drainage_and_water_search",
    "sh_listing_prep_order_chancel_search",
    "sh_listing_prep_order_enviromental_search",
    "sh_listing_prep_order_epc",
    "sh_listing_prep_order_floorplan",
    "sh_listing_prep_upload_freehold_title_register",
    "sh_listing_prep_upload_freehold_title_plan",
    "sh_listing_prep_upload_leasehold_title_register",
    "sh_listing_prep_upload_leasehold_title_plan",
    "sh_listing_prep_upload_leasehold_lease",
    "sh_listing_prep_upload_local_authority_search",
    "sh_listing_prep_upload_drainage_and_water_search",
    "sh_listing_prep_upload_chancel_search",
    "sh_listing_prep_upload_enviromental_search",
    "sh_listing_prep_upload_epc",
    "sh_listing_prep_upload_floorplan",
    "sh_listing_prep_send_docs_to_solicitor",
    "sh_offers_shared",
    "sh_listing_id_check",
    "sh_go_live_on_portals",
    "sh_chase_listing_instruction",
    "sh_listing_prep_choose_viewings_strategy",
    "sh_listing_prep_assign_viewings_conducted_by_occupier",
    "sh_listing_prep_assign_viewings_conducted_by_designated_person",
    "sh_listing_prep_viewings_update_viewber_details",
] as const;



export const ListingValuationJobs = [
    "sh_arrange_valuation_date",
    "sh_send_valuation",
    "sh_send_seller_pifs",
    "sh_set_legal_seller_or_company_name",
] as const;

export const ListingProgressionJobs = [
    "sh_pay_referral_fee",
    "sh_progression_seller_solicitor_kick_off",
    "sh_progression_buyer_solicitor_kick_off",
    "sh_progression_mortgage_broker_kick_off",
    "sh_progression_collect_buyer_own_survey_details",
    "sh_progression_check_buyer_sol_has_everything_needed_to_start",
    "sh_progression_check_buyer_sol_has_received_contracts",
    "sh_progression_check_buyer_sol_has_raised_enquiries",
    "sh_progression_check_if_all_enquires_are_resolved",
    "sh_progression_establish_an_exchange_date",
    "sh_progression_establish_a_completion_date",
    "sh_progression_has_completed",
    "sh_progression_release_keys_to_buyer",
    "sh_progression_mortgage_application_submitted",
    "sh_progression_mortgage_offer_received"
] as const;

export const KeyManagementJobs = [
    "sh_choose_key_strategy",
    "sh_assign_keyholding_occupier",
    "sh_assign_keyholding_designated_person",
    "sh_book_in_keysafe_to_be_fitted",
    "sh_confirm_key_safe_fitted",
    "sh_arrange_key_delivery",
    "sh_confirm_keys_delivered",
    "sh_setup_keynest",
] as const;

export const MarketingJobs = [
    "sh_book_photography",
    "sh_book_video_tour",
    "sh_upload_photos",
    "sh_link_video_tour",
    "sh_order_descripition",
    "sh_enter_and_proofread_description",
    "sh_set_minimum_listing_specifics",
    "sh_approve_listing_optional_specifics",
    "sh_enter_portal_admin_links",
] as const;

type TJobListingBasicJobType =
    typeof ListingPrepJobs[number]
    | typeof ListingValuationJobs[number]
    | typeof ListingProgressionJobs[number]
    | typeof KeyManagementJobs[number]
    | typeof MarketingJobs[number]
;

export const BuyerEnquiriesJobs = [
    "sh_viewings_book",
    "sh_viewings_confirm",
    "sh_viewings_feedback",
    "sh_get_legal_buyers",
    "sh_thirdfort_id_check",
] as const;

export type TBuyerEnquiriesJobType = typeof BuyerEnquiriesJobs[number];


export const LegalCaseBasicJobs = [
    "sl_all_clients_added_and_checked",
    "sl_book_video_call_verification",
    "sl_review_peps_and_sanctions",
    "sl_review_uploaded_proof_of_address",
    "sl_do_video_call_verification",
    "sl_authoriser_check_video_call_verification_fails",
    "sl_get_other_side_conveyancer_details",
    "sl_verify_other_sides_conveyancer",
    "sl_upload_expected_documents",
    "sl_review_document",
    "sl_approve_enquiries",
    "sl_send_enquiries",
    "sl_resolve_enquiries",
    "sl_get_property_details",
    "sl_get_contract_details",
    "sl_set_up_case_in_klyant",
    "sl_get_other_side_client_details_and_check",
    "sl_get_management_company_details",
    "sl_send_instructed_email",
    "sl_add_estate_agent_details_to_case",
    "sl_chase_offer_accepted",
    "sl_assign_case_handler",
    "sl_chase_case_instruction",
    "sl_verify_bank_accounts",
    "sl_create_draft_contract_document_and_tr1",
    "sl_send_draft_contract",
    "sl_bankruptcy_search",
    "sl_get_survey_details",
    "sl_upload_title_report",
    "sl_update_title_report",
    "sl_set_risk_grade",
    "sl_add_details_of_mortgage_offer",
    "sl_share_mortgage_retention_with_client",
    "sl_request_funds_from_lender",
    "sl_confirm_funds_received_from_lender",
    "sl_request_funds_from_client",
    "sl_confirm_funds_received_from_client",
    "sl_pay_seller_solicitor",
    "sl_confirm_payment_received_by_seller_solicitor",
    "sl_pay_sail_legal_invoice_from_client_account_funds",
    "sl_proceeds_of_sale_received_from_buyer_solicitor",
    "sl_upload_search_report",
    "sl_update_search_report",
    "sl_upload_enquiries_report",
    "sl_update_enquiries_report",
    "sl_upload_mortgage_report",
    "sl_update_mortgage_report",
    "sl_upload_leasehold_report",
    "sl_update_leasehold_report",
    "sl_verify_seller_conveyancer_bank_account",
    "sl_review_fraudulent_id_check",
    "sl_review_case_without_id_check",
    "sl_review_case_cois",
    "sl_finalise_case_ledger",
    "sl_chase_payments_due",
    "sl_add_slip_to_cashroom",
    "sl_submit_registration",
    "sl_resolve_requisition",
    "sl_resolve_cancellation",
    "sl_complete_registration",
    "sl_payout_ledger_items",
    "sl_refer_sale_to_sail_homes",
    "sl_upload_quote",
    "sl_check_if_searches_required",
    "sl_order_searches",
    "sl_get_mortgage_lender",
    "sl_add_completion_deadline_for_auction_case",
    "sl_suspicious_file_uploaded_to_case",
    "sl_chase_external_documents",
    "sl_external_documents_overdue",
    "sl_order_lpe1",
    "sl_review_sdlt_answers",
    "sl_add_sdlt_to_ledger",
    "sl_send_report",
    "sl_audit_case",
    "sl_add_charges_and_title_restrictions",
    "sl_carry_out_ready_for_exchange_review",
    "sl_carry_out_ready_for_exchange_review_actions",
    "sl_get_first_redemption_statement",
    "sl_get_redemption_statement_for_completion",
    "sl_chase_redemption_statement",
    "sl_get_early_repayment_charge",
    "sl_notify_client_of_early_repayment_charge",
    "sl_review_unhappy_satisfaction",
    "sl_dual_rep_conflict_job",
    "sl_post_forwarded_or_shredded",
    "sl_order_oes",
    "sl_check_if_affected_by_bsa",
    "sl_charge_aborted_costs",
    "sl_pay_referral_fee",
] as const;

export type TJobLegalCaseBasicJobType = typeof LegalCaseBasicJobs[number];


export const MiscJobs = [
    "document_management",
    "raise_enquiries",
    "approve_enquiries",
    "send_enquiries",
    "resolve_enquiries",
] as const;

type TMiscJobType = typeof MiscJobs[number];

export const LegalAdminJobs = [
    "sl_admin_upload_signed_introducer_referral_agreement",
    "sl_admin_review_introducer_referral_agreement",
] as const;
export type TJobLegalAdminJobType = typeof LegalAdminJobs[number];

export const SailProbateHelplineJobs = [
    "sp_probate_helpline_callback",
] as const;
export type TSailProbateHelplineJobType = typeof SailProbateHelplineJobs[number];

export type TJobType =
    TJobCaseAdHocJobType
    | TJobListingEnquiryAdHocJobType
    | TJobListingPublicNoticeJobType
    | TJobListingBasicJobType
    | TBuyerEnquiriesJobType
    | TJobLegalCaseBasicJobType
    | TJobListingEnquiryOffersShareFeedbackWithBuyerJobType
    | TListingGeneralJobType
    | TListingServiceJobType
    | TListingCarSaleJobType
    | TMiscJobType
    | TJobLegalAdminJobType
    | TSailProbateHelplineJobType
;

export const JobTypes: NonEmptyArray<TJobType> = [
    ...CaseAdHocJobs,
    ...ListingEnquiryAdHocJobs,
    ...ListingPublicNoticeJobs,
    ...ListingPrepJobs,
    ...ListingValuationJobs,
    ...ListingProgressionJobs,
    ...KeyManagementJobs,
    ...MarketingJobs,
    ...BuyerEnquiriesJobs,
    ...LegalCaseBasicJobs,
    ...ListingEnquiryOffersShareFeedbackWithBuyerJobs,
    ...ListingGeneralJobs,
    ...ListingServiceJobs,
    ...ListingCarSaleJobs,
    ...MiscJobs,
    ...LegalAdminJobs,
    ...SailProbateHelplineJobs,
];

export const JobTypesCodec = union(pipe(
    JobTypes,
    nonEmptyArray.map((job) => literal(job)),
));

export const JobTypeCategories = {
    CaseAdHocJobs,
    ListingEnquiryAdHocJobs,
    ListingPublicNoticeJobs,
    ListingPrepJobs,
    ListingValuationJobs,
    ListingProgressionJobs,
    KeyManagementJobs,
    MarketingJobs,
    BuyerEnquiriesJobs,
    LegalCaseBasicJobs,
    MiscJobs,
    LegalAdminJobs,
    ListingEnquiryOffersShareFeedbackWithBuyerJobs,
    ListingGeneralJobs,
    ListingServiceJobs,
    ListingCarSaleJobs,
    SailProbateHelplineJobs,
};