import { union } from "../../shared/src/codecs/types/union";
import { literal } from "../../shared/src/codecs/types/literal";
import { TTypeOfCodec } from "../../shared/src/codecs/codec";
import * as util from "../../shared/src/util";

export const PaymentMethod = union([
    literal("cash"),
    literal("mortgage"),
    literal("bridging_loan"),
    literal("other"),
    literal("unknown"),
]);

type TPaymentMethod = TTypeOfCodec<typeof PaymentMethod>;

export const PaymentMethod_displayName = (p: TPaymentMethod): string => {
    switch (p) {
        case "cash":
            return "Cash";
        case "mortgage":
            return "Mortgage";
        case "bridging_loan":
            return "Bridging loan";
        case "other":
            return "Other";
        case "unknown":
            return "Unknown";
        default:
            return util.requireExhaustive(p);
    }
};