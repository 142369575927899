import * as t from "io-ts";
import * as EnquiryPurchasingIntent1 from "./EnquiryPurchasingIntent1";
import * as EnquiryPaymentMethod1 from "./EnquiryPaymentMethod1";
import * as EnquiryRenovationWorkBy1 from "./EnquiryRenovationWorkBy1";
import * as regexes from "../../shared/src/regexes";
import { TValidationFunction } from "../../shared/src/validation/Function";
import { object } from "../../shared/src/validation/create/object";
import { uuid } from "../../shared/src/validation/basic/uuid";
import { inOrder } from "../../shared/src/validation/compose/inOrder";
import { string } from "../../shared/src/validation/basic/string";
import { isEnum } from "../../shared/src/validation/create/isEnum";
import { nullable as createNullable } from "../../shared/src/validation/create/nullable";
import { integerPositive } from "../../shared/src/validation/basic/integerPositive";
import { boolean } from "../../shared/src/validation/basic/boolean";
import { or } from "../../shared/src/validation/compose/or";
import { nonEmptyString } from "../../shared/src/validation/basic/nonEmptyString";
import { regex } from "../../shared/src/validation/create/regex";
import { nullable } from "../../shared/src/validation/basic/nullable";
import { integer } from "../../shared/src/validation/basic/integer";
import { range } from "../../shared/src/validation/create/range";
import { rangeConstants } from "../../shared/src/validation/constants/range";

export const codec = t.partial({
    party_id: t.string,
    purchasing_intent: EnquiryPurchasingIntent1.codec,
    payment_method: EnquiryPaymentMethod1.codec,
    renovation_work_by: EnquiryRenovationWorkBy1.codec,
    amount_required_when_reselling: t.union([t.null, t.number]),
    notes: t.string,
    closed: t.boolean,
    conveyancer_organisation_name: t.string,
    conveyancer_name: t.string,
    conveyancer_phone_number: t.union([t.string, t.null]),
    conveyancer_address: t.string,
    conveyancer_email_address: t.union([t.string, t.null]),
    mortgage_broker_organisation_name: t.string,
    mortgage_broker_name: t.string,
    mortgage_broker_email_address: t.union([t.string, t.null]),
    mortgage_broker_phone_number: t.union([t.string, t.null]),
    mortgage_broker_has_vouched: t.boolean,
    funds_cash_verified_upto: t.union([t.number, t.null]),
    funds_dip_verified_upto: t.union([t.number, t.null]),
    id_check_complete: t.boolean,
    final_offer_made: t.boolean,
    funds_ltv_percentage: t.union([t.number, t.null]),
    purchasers_company_name: t.string,
    purchasers_address: t.string,
});

export type T = t.TypeOf<typeof codec>;

export const validator: TValidationFunction = object({
    party_id: uuid,
    purchasing_intent: inOrder(
        string,
        isEnum(EnquiryPurchasingIntent1.values),
    ),
    payment_method: inOrder(
        string,
        isEnum(EnquiryPaymentMethod1.values),
    ),
    renovation_work_by: inOrder(
        string,
        isEnum(EnquiryRenovationWorkBy1.values),
    ),
    amount_required_when_reselling: createNullable(
        integerPositive,
    ),
    notes: string,
    closed: boolean,
    conveyancer_organisation_name: string,
    conveyancer_name: string,
    conveyancer_phone_number: or(
        inOrder(
            nonEmptyString,
            regex(regexes.constants.MOBILE_PHONE_REGEX),
        ),
        nullable,
    ),
    conveyancer_address: string,
    conveyancer_email_address: or(
        inOrder(
            nonEmptyString,
            regex(regexes.constants.EMAIL_REGEX),
        ),
        nullable,
    ),
    mortgage_broker_organisation_name: string,
    mortgage_broker_name: string,
    mortgage_broker_email_address: or(
        inOrder(
            nonEmptyString,
            regex(regexes.constants.EMAIL_REGEX),
        ),
        nullable,
    ),
    mortgage_broker_phone_number: or(
        inOrder(
            nonEmptyString,
            regex(regexes.constants.MOBILE_PHONE_REGEX),
        ),
        nullable,
    ),
    mortgage_broker_has_vouched: boolean,
    funds_cash_verified_upto: or(
        integerPositive,
        nullable,
    ),
    funds_dip_verified_upto: or(
        integerPositive,
        nullable,
    ),
    id_check_complete: boolean,
    final_offer_made: boolean,
    funds_ltv_percentage: or(
        inOrder(
            integer,
            range(0, rangeConstants.GREATER_THAN_OR_EQUAL),
            range(100, rangeConstants.LESS_THAN_OR_EQUAL),
        ),
        nullable,
    ),
    purchasers_company_name: string,
    purchasers_address: string,
});

export const newDefault = (): Required<T> => ({
    party_id: "",
    purchasing_intent: "to_rent",
    payment_method: "cash",
    renovation_work_by: "buyer",
    amount_required_when_reselling: null,
    notes: "",
    closed: false,
    conveyancer_organisation_name: "",
    conveyancer_name: "",
    conveyancer_phone_number: null,
    conveyancer_address: "",
    conveyancer_email_address: null,
    mortgage_broker_organisation_name: "",
    mortgage_broker_name: "",
    mortgage_broker_email_address: null,
    mortgage_broker_phone_number: null,
    mortgage_broker_has_vouched: false,
    funds_cash_verified_upto: null,
    funds_dip_verified_upto: null,
    id_check_complete: false,
    final_offer_made: false,
    funds_ltv_percentage: null,
    purchasers_company_name: "",
    purchasers_address: "",
});
