import * as JsonError1 from "../models/JsonError1";
import { array, option } from "fp-ts/lib";
import { pipe } from "fp-ts/lib/function";

export const constants = {
    NOT_REQUESTED: "NotRequested" as const,
    PENDING: "Pending" as const,
    STATUS_2XX: "2XX" as const,
    STATUS_204: "204" as const,
    STATUS_401: "401" as const,
    STATUS_404: "404" as const,
    STATUS_422: "422" as const,
    UNEXPECTED: "Unexpected" as const,
};

export type T<J> = {
    tag: typeof constants.NOT_REQUESTED
        | typeof constants.PENDING
        | typeof constants.STATUS_2XX
        | typeof constants.STATUS_204
        | typeof constants.STATUS_401
        | typeof constants.STATUS_404
        | typeof constants.STATUS_422
        | typeof constants.UNEXPECTED;
    response: J;
    validationInnerErrors: JsonError1.T["inner_errors"];
};

type TFoldParam<R> = {
    onNotRequested: () => R;
    onPending: () => R;
    on2XX: () => R;
    on204: () => R;
    on401: (innerErrors: JsonError1.T["inner_errors"]) => R;
    on404: () => R;
    on422: () => R;
    onUnexpected: () => R;
};

export const fold = <R, J>(fp: TFoldParam<R>) =>
    (p: T<J>): R =>
        p.tag === "NotRequested" ? fp.onNotRequested()
        : p.tag === "Pending" ? fp.onPending()
        : p.tag === "2XX" ? fp.on2XX()
        : p.tag === "204" ? fp.on204()
        : p.tag === "401" ? fp.on401(p.validationInnerErrors)
        : p.tag === "404" ? fp.on404()
        : p.tag === "422" ? fp.on422()
        : fp.onUnexpected();

export const createNotRequested = <J>(response: J): T<J> => ({
    tag: constants.NOT_REQUESTED,
    response,
    validationInnerErrors: [],
});

export const createPending = <J>(response: J, validationInnerErrors: JsonError1.T["inner_errors"] = []): T<J> => ({
    tag: constants.PENDING,
    response,
    validationInnerErrors,
});

export const create2XX = <J>(response: J, validationInnerErrors: JsonError1.T["inner_errors"] = []): T<J> => ({
    tag: constants.STATUS_2XX,
    response,
    validationInnerErrors,
});

export const create204 = (validationInnerErrors: JsonError1.T["inner_errors"] = []): T<void> => ({
    tag: constants.STATUS_204,
    response: undefined,
    validationInnerErrors,
});

export const create401 = <J>(response: J, validationInnerErrors: JsonError1.T["inner_errors"] = []): T<J> => ({
    tag: constants.STATUS_401,
    response,
    validationInnerErrors,
});

export const create404 = <J>(response: J, validationInnerErrors: JsonError1.T["inner_errors"] = []): T<J> => ({
    tag: constants.STATUS_404,
    response,
    validationInnerErrors,
});

export const create422 = <J>(response: J, validationInnerErrors: JsonError1.T["inner_errors"]): T<J> => ({
    tag: constants.STATUS_422,
    response,
    validationInnerErrors,
});

export const createUnexpected = <J>(response: J, validationInnerErrors: JsonError1.T["inner_errors"] = []): T<J> => ({
    tag: constants.UNEXPECTED,
    response,
    validationInnerErrors,
});

// Check if a response is T422 and that response contains errors for a specific set of keys
export const hasValidationErrorsForTargetKeys = <J>(targetKeys: Array<string>, response: T<J>): boolean =>
    JsonError1.getInnerErrorsForTargetKeys(targetKeys, response.validationInnerErrors).length > 0;

// Remove target keys from the validation errors
// 1. After removing a target key if there are other keys present the error is preserved without the target key
// 2. If removing the target key leaves no other keys then the inner error is removed
export const removeValidationErrors = <J>(targetKeysToRemove: Array<string>, response: T<J>): T<J> =>
     pipe(
        response.validationInnerErrors,
        array.filterMap((innerError) =>
             pipe(
                array.filterMap<string, string>((target) =>
                    // Remove the keys that appear in targetKeysToRemove
                    targetKeysToRemove.includes(target) ? option.none : option.some(target),
                )(innerError.error_target.keys),
                // Remove inner errors that no longer have any target keys
                (keys) => keys.length === 0 ? option.none : option.some( pipe(
                    {...innerError},
                    (ie) => {
                        ie.error_target.keys = keys;
                        return ie;
                    }
                )),
            ),
        ),
        (validationInnerErrors) => ({
            tag: response.tag,
            response: response.response,
            validationInnerErrors,
        }),
    );
