import { pipe } from "fp-ts/lib/function";
import { array } from "../../../shared/src/codecs/types/array";
import { overload } from "../../../shared/src/codecs/types/overload";
import { string } from "../../../shared/src/codecs/types/string";
import { Address1 } from "../Address";
import { array as fptsArray } from "fp-ts";
import { Eq } from "fp-ts/lib/string";

export const AddressFirstLine = overload(
    string(),
    Address1,
    (t) => {
        const formattedAddress: Array<string> = [];
        if (t.sub_building_name) {
            formattedAddress.push(t.sub_building_name);
        } else if (t.sub_building_number) {
            formattedAddress.push(t.sub_building_number);
        }
        if (t.building_number) {
            formattedAddress.push(`${t.building_number} ${t.street_name}`);
        }
        else {
            formattedAddress.push(t.building_name);
            formattedAddress.push(t.street_name);
        }
        return formattedAddress.join(", ");
    }
);

export const AddressFirstLines = overload(
    string(),
    array(AddressFirstLine),
    (p) =>
        pipe(
            p,
            fptsArray.uniq(Eq),
            (v) => v.join(" & "),
        ),
);
export type TAddressFirstLinesCodec = typeof AddressFirstLines;
