import * as t from "io-ts";
import * as PartySellingPropertyState from "./PartySellingPropertyState";

export const codec = t.type({
    id: t.string,
    selling_property_state: PartySellingPropertyState.codec,
});

type T = t.TypeOf<typeof codec>;

export const newDefault = (): T => ({
    id: "",
    selling_property_state:"unknown",
});
