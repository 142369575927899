import React from "react";
import { CRMCardOutside } from "../../components/CRMCardOutside/CRMCardOutside";
import { CRMColumns } from "../../components/CRMColumns/CRMColumns";
import { CRMDropdownComponent } from "../../components/CRMDropdownComponent/CRMDropdownComponent";
import CRMInputGeneralComponent from "../../components/CRMInputs/CRMInputGeneralComponent/CRMInputGeneralComponent";
import { CRMInputLabel } from "../../components/CRMInputLabel/CRMInputLabel";
import { CRMPadding } from "../../components/Simple/CRMPadding/CRMPadding";
import { CRMPageBackground } from "../../components/Simple/CRMPageBackground/CRMPageBackground";
import { CRMSpacer } from "../../components/CRMSpacer/CRMSpacer";
import { CRMTitleSubSection } from "../../components/CRMTitleSubSection/CRMTitleSubSection";
import { CRMTextHighlight } from "../../components/CRMTextHighlight/CRMTextHighlight";
import { makeFormUpdateAction} from "../../state/applyActions";
import { TProbateCase1AndC2From } from "../../models/TFormModels";
import { makeAction } from "../../state/applyActions";
import { CRMCardInside } from "../../components/CRMCardInside/CRMCardInside";
import Checkbox from "../../components/OldFront/Simple/CheckboxComponent/Checkbox";
import { AnchorLinkDispatch } from "../../containers/AnchorLinkDispatch";
import FrontParagraphLarge from "../../components/Front/Simple/FrontParagraphLarge/FrontParagraphLarge";
import { FrontIcon } from "../../components/Front/Simple/FrontIcon/FrontIcon";
import FrontParagraphRegular from "../../components/Front/Simple/FrontParagraphRegular/FrontParagraphRegular";
import FrontButtonPrimary from "../../components/Front/Simple/FrontButtonPrimary/FrontButtonPrimary";
import * as JsonError1 from "../../../../domain/models/JsonError1";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import TagManager from "react-gtm-module";
import { createChangeRouteAction } from "../../state/router/createChangeRouteAction";

class ProbatePanelEnquiryPage extends React.Component<React.PropsWithChildren<TContainerStateProps>> {
    public componentDidMount() {
        TagManager.initialize({
            gtmId: "GTM-5J4P8R5",
        });
    }

    public render(): JSX.Element {
        return (
            <div className="probate-panel-enquiry-page">
                <CRMPageBackground>
                    <CRMPadding size="large">
                        <CRMColumns
                            padding="large"
                            columns={[
                                {
                                    flex: 0.5,
                                    content: (<div></div>),
                                },
                                {
                                    flex: 1.3,
                                    content: (
                                        <CRMCardOutside borderRounding="right" shadow={true}>
                                            <CRMPadding size="large">
                                                <img style={{display: "block", height: "100px"}} src="/static/img/SAIL_PROBATE_LOGO_2021.png" />
                                                <CRMSpacer size="large" />
                                                <FrontParagraphLarge>
                                                    Answers the questions to the best of your knowledge to get a fixed fee quote for grant of probate and estate administration.
                                                </FrontParagraphLarge>
                                                <CRMSpacer size="large" />
                                                <CRMTitleSubSection>Your details</CRMTitleSubSection>
                                                <CRMSpacer size="large" />
                                                <CRMInputLabel>Your name</CRMInputLabel>
                                                <CRMSpacer size="tiny" />
                                                <CRMInputGeneralComponent
                                                    inputType="text"
                                                    value={this.props.state.probate.caseForm.edit.full_name}
                                                    displayError={JsonError1.doTargetKeysHaveInnerErrors(["full_name"], this.props.state.probate.caseForm.validationErrors)}
                                                    placeholder="Your name"
                                                    onChange={
                                                        (value) => this.props.dispatch(
                                                            makeFormUpdateAction<"PROBATE_CASE_INPUT_CHANGED", TProbateCase1AndC2From["edit"], "full_name">(
                                                                "PROBATE_CASE_INPUT_CHANGED",
                                                                "full_name",
                                                                value,
                                                            )
                                                        )
                                                    }
                                                    onPressEnterKey={()=>null}
                                                />
                                                <CRMSpacer size="medium" />
                                                <CRMInputLabel>Phone number</CRMInputLabel>
                                                <CRMSpacer size="tiny" />
                                                <CRMInputGeneralComponent
                                                    inputType="tel"
                                                    value={this.props.state.probate.caseForm.edit.phone_number}
                                                    displayError={JsonError1.doTargetKeysHaveInnerErrors(["phone_number"], this.props.state.probate.caseForm.validationErrors)}
                                                    placeholder="Phone number"
                                                    onChange={
                                                        (value) => this.props.dispatch(
                                                            makeFormUpdateAction<"PROBATE_CASE_INPUT_CHANGED", TProbateCase1AndC2From["edit"], "phone_number">(
                                                                "PROBATE_CASE_INPUT_CHANGED",
                                                                "phone_number",
                                                                value,
                                                            )
                                                        )
                                                    }
                                                    onPressEnterKey={()=>null}
                                                />
                                                <CRMSpacer size="medium" />
                                                <CRMInputLabel>Email</CRMInputLabel>
                                                <CRMSpacer size="tiny" />
                                                <CRMInputGeneralComponent
                                                    inputType="email"
                                                    value={this.props.state.probate.caseForm.edit.email}
                                                    displayError={JsonError1.doTargetKeysHaveInnerErrors(["email"], this.props.state.probate.caseForm.validationErrors)}
                                                    placeholder="Email"
                                                    onChange={
                                                        (value) => this.props.dispatch(
                                                            makeFormUpdateAction<"PROBATE_CASE_INPUT_CHANGED", TProbateCase1AndC2From["edit"], "email">(
                                                                "PROBATE_CASE_INPUT_CHANGED",
                                                                "email",
                                                                value,
                                                            )
                                                        )
                                                    }
                                                    onPressEnterKey={()=>null}
                                                />
                                                <CRMSpacer size="medium" />
                                                <CRMInputLabel>GDPR and T&amp;Cs</CRMInputLabel>
                                                <Checkbox
                                                    label={"I agree to the GDPR policy and T&Cs"}
                                                    isChecked={this.props.state.probate.caseForm.edit.gdpr_agreed}
                                                    onChange={(value) => this.props.dispatch(
                                                        makeFormUpdateAction<"PROBATE_CASE_INPUT_CHANGED", TProbateCase1AndC2From["edit"], "gdpr_agreed">(
                                                            "PROBATE_CASE_INPUT_CHANGED",
                                                            "gdpr_agreed",
                                                            value,
                                                        )
                                                    )}
                                                />
                                                <span>
                                                    <AnchorLinkDispatch
                                                        dispatch={this.props.dispatch}
                                                        action={createChangeRouteAction(
                                                            "VIEW_PROBATE_GDPR_PAGE",
                                                            {},
                                                            {}
                                                        )}
                                                        text={`GDPR Policy`}
                                                    />
                                                    , &nbsp;
                                                    <AnchorLinkDispatch
                                                        dispatch={this.props.dispatch}
                                                        action={createChangeRouteAction(
                                                            "VIEW_PROBATE_TNCS_PAGE",
                                                            {},
                                                            {}
                                                        )}
                                                        text={`Terms & Conditions`}
                                                    />
                                                </span>
                                                {
                                                    JsonError1.doTargetKeysHaveInnerErrors(["gdpr_agreed"], this.props.state.probate.caseForm.validationErrors)
                                                    && <div>
                                                        <CRMSpacer size="tiny" />
                                                        <CRMTextHighlight colour="highlights-instructional-brick-8">You must agree to the GDPR policy and terms and conditions to continue.</CRMTextHighlight>
                                                    </div>
                                                }
                                                <CRMSpacer size="large" />
                                                <CRMTitleSubSection>Details of the deceased</CRMTitleSubSection>
                                                <CRMSpacer size="large" />
                                                <CRMCardInside>
                                                    <CRMPadding size="medium">
                                                        <FrontParagraphRegular>
                                                            <FrontIcon iconName="info" colour="highlights-error-firebrick-4"/>
                                                            <div style={{width: "15px"}}></div>
                                                            Please note any joint assets or liabilities should be included in these details
                                                        </FrontParagraphRegular>
                                                    </CRMPadding>
                                                </CRMCardInside>
                                                <CRMSpacer size="medium" />
                                                <CRMInputLabel>Does a will exist and is it&apos;s location known?</CRMInputLabel>
                                                <CRMSpacer size="tiny" />
                                                <CRMDropdownComponent
                                                    options={[{
                                                        value: "yes",
                                                        label: "Yes",
                                                    },
                                                    {
                                                        value: "no",
                                                        label: "No/Unsure",
                                                    }]}
                                                    value={this.props.state.probate.caseForm.edit.will_exists ? "yes" : "no"}
                                                    displayError={JsonError1.doTargetKeysHaveInnerErrors(["will_exists"], this.props.state.probate.caseForm.validationErrors)}
                                                    onChange={
                                                        (value) => this.props.dispatch(
                                                            makeFormUpdateAction<"PROBATE_CASE_INPUT_CHANGED", TProbateCase1AndC2From["edit"], "will_exists">(
                                                                "PROBATE_CASE_INPUT_CHANGED",
                                                                "will_exists",
                                                                value === "yes" ? true : false,
                                                            )
                                                        )
                                                    }
                                                />
                                                <CRMSpacer size="large" />
                                                <CRMTitleSubSection>Their properties</CRMTitleSubSection>
                                                <CRMSpacer size="large" />
                                                <CRMInputLabel>Do they own property?</CRMInputLabel>
                                                <CRMSpacer size="tiny" />
                                                <CRMDropdownComponent
                                                    options={[{
                                                        value: "yes",
                                                        label: "Yes",
                                                    },
                                                    {
                                                        value: "no",
                                                        label: "No",
                                                    }]}
                                                    value={this.props.state.probate.caseForm.edit.owns_property ? "yes" : "no"}
                                                    displayError={JsonError1.doTargetKeysHaveInnerErrors(["owns_property"], this.props.state.probate.caseForm.validationErrors)}
                                                    onChange={
                                                        (value) => this.props.dispatch(
                                                            makeFormUpdateAction<"PROBATE_CASE_INPUT_CHANGED", TProbateCase1AndC2From["edit"], "owns_property">(
                                                                "PROBATE_CASE_INPUT_CHANGED",
                                                                "owns_property",
                                                                value === "yes" ? true : false,
                                                            )
                                                        )
                                                    }
                                                />
                                                {
                                                    this.props.state.probate.caseForm.edit.owns_property &&
                                                    <>
                                                        <CRMSpacer size="medium" />
                                                        <CRMInputLabel>How many properties do they own?</CRMInputLabel>
                                                        <CRMSpacer size="tiny" />
                                                        <CRMInputGeneralComponent
                                                            inputType="numericString"
                                                            value={this.props.state.probate.caseForm.edit.number_of_properties_owned?.toString() ?? ""}
                                                            displayError={JsonError1.doTargetKeysHaveInnerErrors(["number_of_properties_owned"], this.props.state.probate.caseForm.validationErrors)}
                                                            placeholder="Number of properties"
                                                            onChange={
                                                                (value) => this.props.dispatch(
                                                                    makeFormUpdateAction<"PROBATE_CASE_INPUT_CHANGED", TProbateCase1AndC2From["edit"], "number_of_properties_owned">(
                                                                        "PROBATE_CASE_INPUT_CHANGED",
                                                                        "number_of_properties_owned",
                                                                        value === "" ? null : parseInt(value),
                                                                    )
                                                                )
                                                            }
                                                            onPressEnterKey={()=>null}
                                                        />
                                                        <CRMSpacer size="medium" />
                                                        <CRMInputLabel>Are any of these properties outside of the UK?</CRMInputLabel>
                                                        <CRMSpacer size="tiny" />
                                                        <CRMDropdownComponent
                                                            options={[{
                                                                value: "yes",
                                                                label: "Yes",
                                                            },
                                                            {
                                                                value: "no",
                                                                label: "No",
                                                            }]}
                                                            value={this.props.state.probate.caseForm.edit.foreign_properties_owned ? "yes" : "no"}
                                                            displayError={JsonError1.doTargetKeysHaveInnerErrors(["foreign_properties_owned"], this.props.state.probate.caseForm.validationErrors)}
                                                            onChange={
                                                                (value) => this.props.dispatch(
                                                                    makeFormUpdateAction<"PROBATE_CASE_INPUT_CHANGED", TProbateCase1AndC2From["edit"], "foreign_properties_owned">(
                                                                        "PROBATE_CASE_INPUT_CHANGED",
                                                                        "foreign_properties_owned",
                                                                        value === "yes" ? true : false,
                                                                    )
                                                                )
                                                            }
                                                        />
                                                    </>
                                                }
                                                <CRMSpacer size="large" />
                                                <CRMTitleSubSection>Their assets</CRMTitleSubSection>
                                                <CRMSpacer size="large" />
                                                <CRMInputLabel>Number of institutions where accounts held (includes banks and building societies)</CRMInputLabel>
                                                <CRMSpacer size="tiny" />
                                                <CRMInputGeneralComponent
                                                    inputType="numericString"
                                                    value={this.props.state.probate.caseForm.edit.banking_institutes_with_assets_count?.toString() ?? ""}
                                                    displayError={JsonError1.doTargetKeysHaveInnerErrors(["banking_institutes_with_assets_count"], this.props.state.probate.caseForm.validationErrors)}
                                                    placeholder="Number of institutions"
                                                    onChange={
                                                        (value) => this.props.dispatch(
                                                            makeFormUpdateAction<"PROBATE_CASE_INPUT_CHANGED", TProbateCase1AndC2From["edit"], "banking_institutes_with_assets_count">(
                                                                "PROBATE_CASE_INPUT_CHANGED",
                                                                "banking_institutes_with_assets_count",
                                                                value === "" ? null : parseInt(value),
                                                            )
                                                        )
                                                    }
                                                    onPressEnterKey={()=>null}
                                                />
                                                <CRMSpacer size="medium" />
                                                <CRMInputLabel>Number of government financial schemes enrolled in (ISAs, Premium bonds, National Savings)</CRMInputLabel>
                                                <CRMSpacer size="tiny" />
                                                <CRMInputGeneralComponent
                                                    inputType="numericString"
                                                    value={this.props.state.probate.caseForm.edit.government_financial_schemes_count?.toString() ?? ""}
                                                    displayError={JsonError1.doTargetKeysHaveInnerErrors(["government_financial_schemes_count"], this.props.state.probate.caseForm.validationErrors)}
                                                    placeholder="Number of schemes"
                                                    onChange={
                                                        (value) => this.props.dispatch(
                                                            makeFormUpdateAction<"PROBATE_CASE_INPUT_CHANGED", TProbateCase1AndC2From["edit"], "government_financial_schemes_count">(
                                                                "PROBATE_CASE_INPUT_CHANGED",
                                                                "government_financial_schemes_count",
                                                                value === "" ? null : parseInt(value),
                                                            )
                                                        )
                                                    }
                                                    onPressEnterKey={()=>null}
                                                />
                                                <CRMSpacer size="medium" />
                                                <CRMInputLabel>Number of organisations they own shares in</CRMInputLabel>
                                                <CRMSpacer size="tiny" />
                                                <CRMInputGeneralComponent
                                                    inputType="numericString"
                                                    value={this.props.state.probate.caseForm.edit.organizations_with_shares_owned_count?.toString() ?? ""}
                                                    displayError={JsonError1.doTargetKeysHaveInnerErrors(["organizations_with_shares_owned_count"], this.props.state.probate.caseForm.validationErrors)}
                                                    placeholder="Number of organisations"
                                                    onChange={
                                                        (value) => this.props.dispatch(
                                                            makeFormUpdateAction<"PROBATE_CASE_INPUT_CHANGED", TProbateCase1AndC2From["edit"], "organizations_with_shares_owned_count">(
                                                                "PROBATE_CASE_INPUT_CHANGED",
                                                                "organizations_with_shares_owned_count",
                                                                value === "" ? null : parseInt(value),
                                                            )
                                                        )
                                                    }
                                                    onPressEnterKey={()=>null}
                                                />
                                                <CRMSpacer size="medium" />
                                                <CRMInputLabel>Number of pensions they have</CRMInputLabel>
                                                <CRMSpacer size="tiny" />
                                                <CRMInputGeneralComponent
                                                    inputType="numericString"
                                                    value={this.props.state.probate.caseForm.edit.pensions_count?.toString() ?? ""}
                                                    displayError={JsonError1.doTargetKeysHaveInnerErrors(["pensions_count"], this.props.state.probate.caseForm.validationErrors)}
                                                    placeholder="Number of pensions"
                                                    onChange={
                                                        (value) => this.props.dispatch(
                                                            makeFormUpdateAction<"PROBATE_CASE_INPUT_CHANGED", TProbateCase1AndC2From["edit"], "pensions_count">(
                                                                "PROBATE_CASE_INPUT_CHANGED",
                                                                "pensions_count",
                                                                value === "" ? null : parseInt(value),
                                                            )
                                                        )
                                                    }
                                                    onPressEnterKey={()=>null}
                                                />
                                                <CRMSpacer size="medium" />
                                                <CRMInputLabel>Number of vehicles they own</CRMInputLabel>
                                                <CRMSpacer size="tiny" />
                                                <CRMInputGeneralComponent
                                                    inputType="numericString"
                                                    value={this.props.state.probate.caseForm.edit.vehicles_owned_count?.toString() ?? ""}
                                                    displayError={JsonError1.doTargetKeysHaveInnerErrors(["vehicles_owned_count"], this.props.state.probate.caseForm.validationErrors)}
                                                    placeholder="Number of vehicles"
                                                    onChange={
                                                        (value) => this.props.dispatch(
                                                            makeFormUpdateAction<"PROBATE_CASE_INPUT_CHANGED", TProbateCase1AndC2From["edit"], "vehicles_owned_count">(
                                                                "PROBATE_CASE_INPUT_CHANGED",
                                                                "vehicles_owned_count",
                                                                value === "" ? null : parseInt(value),
                                                            )
                                                        )
                                                    }
                                                    onPressEnterKey={()=>null}
                                                />
                                                <CRMSpacer size="medium" />
                                                <CRMInputLabel>Number of life protection policies</CRMInputLabel>
                                                <CRMSpacer size="tiny" />
                                                <CRMInputGeneralComponent
                                                    inputType="numericString"
                                                    value={this.props.state.probate.caseForm.edit.life_protection_policies_count?.toString() ?? ""}
                                                    displayError={JsonError1.doTargetKeysHaveInnerErrors(["life_protection_policies_count"], this.props.state.probate.caseForm.validationErrors)}
                                                    placeholder="Number of policies"
                                                    onChange={
                                                        (value) => this.props.dispatch(
                                                            makeFormUpdateAction<"PROBATE_CASE_INPUT_CHANGED", TProbateCase1AndC2From["edit"], "life_protection_policies_count">(
                                                                "PROBATE_CASE_INPUT_CHANGED",
                                                                "life_protection_policies_count",
                                                                value === "" ? null : parseInt(value),
                                                            )
                                                        )
                                                    }
                                                    onPressEnterKey={()=>null}
                                                />
                                                <CRMSpacer size="medium" />
                                                <CRMTitleSubSection>Their liabilities</CRMTitleSubSection>
                                                <CRMSpacer size="large" />
                                                <CRMInputLabel>Total number of mortgages</CRMInputLabel>
                                                <CRMSpacer size="tiny" />
                                                <CRMInputGeneralComponent
                                                    inputType="numericString"
                                                    value={this.props.state.probate.caseForm.edit.mortgages_count?.toString() ?? ""}
                                                    displayError={JsonError1.doTargetKeysHaveInnerErrors(["mortgages_count"], this.props.state.probate.caseForm.validationErrors)}
                                                    placeholder="Number of mortgages"
                                                    onChange={
                                                        (value) => this.props.dispatch(
                                                            makeFormUpdateAction<"PROBATE_CASE_INPUT_CHANGED", TProbateCase1AndC2From["edit"], "mortgages_count">(
                                                                "PROBATE_CASE_INPUT_CHANGED",
                                                                "mortgages_count",
                                                                value === "" ? null : parseInt(value),
                                                            )
                                                        )
                                                    }
                                                    onPressEnterKey={()=>null}
                                                />
                                                <CRMSpacer size="medium" />
                                                <CRMInputLabel>Total number of outstanding loans</CRMInputLabel>
                                                <CRMSpacer size="tiny" />
                                                <CRMInputGeneralComponent
                                                    inputType="numericString"
                                                    value={this.props.state.probate.caseForm.edit.loans_outstanding_count?.toString() ?? ""}
                                                    displayError={JsonError1.doTargetKeysHaveInnerErrors(["loans_outstanding_count"], this.props.state.probate.caseForm.validationErrors)}
                                                    placeholder="Number of loans"
                                                    onChange={
                                                        (value) => this.props.dispatch(
                                                            makeFormUpdateAction<"PROBATE_CASE_INPUT_CHANGED", TProbateCase1AndC2From["edit"], "loans_outstanding_count">(
                                                                "PROBATE_CASE_INPUT_CHANGED",
                                                                "loans_outstanding_count",
                                                                value === "" ? null : parseInt(value),
                                                            )
                                                        )
                                                    }
                                                    onPressEnterKey={()=>null}
                                                />
                                                <CRMSpacer size="medium" />
                                                <CRMInputLabel>Total number of open credit cards</CRMInputLabel>
                                                <CRMSpacer size="tiny" />
                                                <CRMInputGeneralComponent
                                                    inputType="numericString"
                                                    value={this.props.state.probate.caseForm.edit.credit_cards_count?.toString() ?? ""}
                                                    displayError={JsonError1.doTargetKeysHaveInnerErrors(["credit_cards_count"], this.props.state.probate.caseForm.validationErrors)}
                                                    placeholder="Number of credit cards"
                                                    onChange={
                                                        (value) => this.props.dispatch(
                                                            makeFormUpdateAction<"PROBATE_CASE_INPUT_CHANGED", TProbateCase1AndC2From["edit"], "credit_cards_count">(
                                                                "PROBATE_CASE_INPUT_CHANGED",
                                                                "credit_cards_count",
                                                                value === "" ? null : parseInt(value),
                                                            )
                                                        )
                                                    }
                                                    onPressEnterKey={()=>null}
                                                />
                                                <CRMSpacer size="medium" />
                                                <CRMInputLabel>Are any of these assets or liabilities located outside of the United Kingdom</CRMInputLabel>
                                                <CRMSpacer size="tiny" />
                                                <CRMDropdownComponent
                                                    options={[{
                                                        value: "yes",
                                                        label: "Yes",
                                                    },
                                                    {
                                                        value: "no",
                                                        label: "No",
                                                    }]}
                                                    value={this.props.state.probate.caseForm.edit.assets_and_liabilities_includes_foreign ? "yes" : "no"}
                                                    displayError={JsonError1.doTargetKeysHaveInnerErrors(["assets_and_liabilities_includes_foreign"], this.props.state.probate.caseForm.validationErrors)}
                                                    onChange={
                                                        (value) => this.props.dispatch(
                                                            makeFormUpdateAction<"PROBATE_CASE_INPUT_CHANGED", TProbateCase1AndC2From["edit"], "assets_and_liabilities_includes_foreign">(
                                                                "PROBATE_CASE_INPUT_CHANGED",
                                                                "assets_and_liabilities_includes_foreign",
                                                                value === "yes" ? true : false,
                                                            )
                                                        )
                                                    }
                                                />
                                                <CRMSpacer size="medium" />
                                                <CRMInputLabel>Please estimate. Is the total value of the estate (including properties) over £325,000 (this is the tax threshold)</CRMInputLabel>
                                                <CRMSpacer size="tiny" />
                                                <CRMDropdownComponent
                                                    options={[{
                                                        value: "yes",
                                                        label: "Yes",
                                                    },
                                                    {
                                                        value: "no",
                                                        label: "No",
                                                    }]}
                                                    value={this.props.state.probate.caseForm.edit.estate_is_estimated_to_be_taxable ? "yes" : "no"}
                                                    displayError={false}
                                                    onChange={
                                                        (value) => this.props.dispatch(
                                                            makeFormUpdateAction<"PROBATE_CASE_INPUT_CHANGED", TProbateCase1AndC2From["edit"], "estate_is_estimated_to_be_taxable">(
                                                                "PROBATE_CASE_INPUT_CHANGED",
                                                                "estate_is_estimated_to_be_taxable",
                                                                value === "yes" ? true : false,
                                                            )
                                                        )
                                                    }
                                                />
                                                <CRMSpacer size="large" />
                                                <FrontButtonPrimary
                                                    label="Get My Quote"
                                                    fullWidth={true}
                                                    onClick={()=>this.props.dispatch(
                                                        makeAction("PROBATE_GET_MY_QUOTE_BUTTON_CLICKED", undefined)
                                                    )}
                                                />
                                            </CRMPadding>
                                        </CRMCardOutside>
                                    ),
                                },
                                {
                                    flex: 0.5,
                                    content: (<div></div>),
                                },
                            ]}
                        />
                    </CRMPadding>
                </CRMPageBackground>
            </div>
        );
    }
}

export default ProbatePanelEnquiryPage;
