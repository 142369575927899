import React, { CSSProperties } from "react";
import AddPhone from "../../../src/assets/crmIcons/Icon=Add Phone";
import Bookmark from "../../../src/assets/crmIcons/Icon=Bookmark";
import Bookmarked from "../../../src/assets/crmIcons/Icon=Bookmarked";
import Calendar from "../../../src/assets/crmIcons/Icon=Calendar";
import CalendarAdd from "../../../src/assets/crmIcons/Icon=Calendar Add";
import Delete from "../../../src/assets/crmIcons/Icon=Delete";
import DoneOutline from "../../../src/assets/crmIcons/Icon=Done Outline";
import Done from "../../../src/assets/crmIcons/Icon=Done";
import DownArrow from "../../../src/assets/crmIcons/Icon=Down Arrow";
import UpArrow from "../../../src/assets/crmIcons/Icon=Up Arrow";
import Pending from "../../../src/assets/crmIcons/Icon=Pending";
import Book from "../../../src/assets/crmIcons/Icon=Book";
import OndemandVideo from "../../../src/assets/crmIcons/Icon=OndemandVideo";
import SaveAndAdd from "../../../src/assets/crmIcons/Icon=Save & Add";
import Save from "../../../src/assets/crmIcons/Icon=Save";
import Star from "../../../src/assets/crmIcons/Icon=Star";
import WarningBubble from "../../../src/assets/crmIcons/Icon=Warning Bubble";
import ErrorOutline from "../../../src/assets/crmIcons/Icon=Error Outline";
import FormIcon from "../../../src/assets/crmIcons/Icon=FormIcon";
import InputsTouched from "../../../src/assets/crmIcons/Icon=Inputs Touched";
import FullArrowUp from "../../../src/assets/crmIcons/Name=Full Arrow Up";
import Open from "../../../src/assets/crmIcons/Name=Open";
import Money from "../../../src/assets/crmIcons/Icon=Money";
import House from "../../../src/assets/crmIcons/Icon=House";
import HouseWithPerson from "../../../src/assets/crmIcons/Name=House With Person";
import Add from "../../../src/assets/crmIcons/Name=Add";
import AddCircle from "../../../src/assets/crmIcons/Name=Add Cricle";
import AddBox from "../../../src/assets/crmIcons/Name=Add Box";
import PersonAdd from "../../../src/assets/crmIcons/Name=Person Add";
import PersonOff from "../../../src/assets/crmIcons/Name=Person Off";
import PersonTick from "../../../src/assets/crmIcons/Name=Person Tick";
import Pencil from "../../../src/assets/crmIcons/Icon=Pencil";
import CheckboxUnchecked from "../../../src/assets/crmIcons/Icon=Checkbox unchecked";
import CheckboxChecked from "../../../src/assets/crmIcons/Icon=Checkbox checked";
import CheckboxWithStrikethrough from "../../../src/assets/crmIcons/Icon=Checkbox Strikethrough";
import Link from "../../../src/assets/crmIcons/Icon=Link";
import People from "../../../src/assets/crmIcons/Icon=People";
import Stamp from "../../../src/assets/crmIcons/Icon=Stamp";
import New from "../../../src/assets/crmIcons/Icon=New";
import LegalGavel from "../../../src/assets/crmIcons/Icon=Legal Gavel";
import IdentityBadge from "../../../src/assets/crmIcons/Icon=Identity Badge";
import Chainlink from "../../../src/assets/crmIcons/Icon=Chainlink";
import ChainlinkWithPlusSymbol from "../../../src/assets/crmIcons/Icon=Chainlink With Plus Symbol";
import ChainlinkWithStrikethrough from "../../../src/assets/crmIcons/Icon=Chainlink With Strikethrough";
import HouseAndBuilding from "../../../src/assets/crmIcons/Name=House and building";
import SearchList from "../../../src/assets/crmIcons/Name=Search List";
import Repeat from "../../../src/assets/crmIcons/Icon=Repeat";
import Chart from "../../../src/assets/crmIcons/Name=Chart";
import ClockFull from "../../../src/assets/crmIcons/Name=Clock Full";
import Dynamic from "../../../src/assets/crmIcons/Name=Dyanmic";
import NotInterested from "../../../src/assets/crmIcons/Name=Not Interested";
import Minus from "../../../src/assets/crmIcons/Name=Minus";
import DoneAll from "../../../src/assets/crmIcons/Name=Done All";
import QuestionInCircle from "../../../src/assets/crmIcons/Icon=Question in Circle";
import Files from "../../../src/assets/crmIcons/Name=Files";
import Clock from "../../../src/assets/crmIcons/Name=Clock";
import Attach from "../../../src/assets/crmIcons/Icon=Attach";
import Sign from "../../../src/assets/crmIcons/Icon=Sign";
import EmailOutbound from "../../../src/assets/crmIcons/Icon=Email Outbound";
import EmailInbound from "../../../src/assets/crmIcons/Icon=Email Inbound";
import EmailNotification from "../../../src/assets/crmIcons/Icon=Email Notification";
import Document from "../../../src/assets/crmIcons/Icon=Document";
import Denied from "../../../src/assets/crmIcons/Icon=Denied";
import Options from "../../../src/assets/crmIcons/Icon=Options";
import RadioUnselected from "../../../src/assets/crmIcons/Name=Radio Unselected";
import RadioSelected from "../../../src/assets/crmIcons/Name=Radio Selected";
import Dot from "../../../src/assets/crmIcons/Name=Dot";
import Close from "../../../src/assets/crmIcons/Name=Close";
import ArrowUp from "../../../src/assets/crmIcons/Name=ArrowUp";
import Eye from "../../../src/assets/crmIcons/Name=Eye";
import TooltipOpen from "../../../src/assets/crmIcons/Icon=Tooltip Open";
import ArrowRight from "../../../src/assets/crmIcons/Name=Arrow Right";
import FileClip from "../../../src/assets/crmIcons/Icon=File Clip";
import FileClipNotification from "../../../src/assets/crmIcons/Name=File Clip Notification";
import FileClipTick from "../../../src/assets/crmIcons/Name=File Clip Tick";
import ForwardArrow from "../../../src/assets/crmIcons/Icon=Forward Arrow";
import BackwardArrow from "../../../src/assets/crmIcons/Icon=Backward Arrow";
import FileBox from "../../../src/assets/crmIcons/Icon=File Box";
import Archive from "../../../src/assets/crmIcons/Icon=Archive";
import ChatBubbleOutbound from "../../../src/assets/crmIcons/Icon=Chat Bubble Outbound";
import ChatBubble from "../../../src/assets/crmIcons/Icon=Chat Bubble";
import CasesTick from "../../../src/assets/crmIcons/Name=Cases Tick";
import Jobs from "../../../src/assets/crmIcons/Name=Jobs";
import Snooze from "../../../src/assets/crmIcons/Icon=Snooze";
import Alarm from "../../../src/assets/crmIcons/Icon=Alarm";
import CresentMoon from "../../../src/assets/crmIcons/Icon=Cresent Moon";
import HalfMoon from "../../../src/assets/crmIcons/Icon=Half Moon";
import Sun from "../../../src/assets/crmIcons/Icon=Sun";
import ArrowDown from "../../../src/assets/crmIcons/Icon=Arrow down";
import Camera from "../../../src/assets/crmIcons/Name=Camera";
import Hand from "../../../src/assets/crmIcons/Name=Hand";
import Backspace from "../../../src/assets/crmIcons/Name=Backspace";
import LinedArrow from "../../../src/assets/crmIcons/Name=Lined Arrow";
import Wave from "../../../src/assets/crmIcons/Name=Wave";
import CalendarTick from "../../../src/assets/crmIcons/Name=Calendar Tick";
import Users from "../../../src/assets/crmIcons/Name=Users";
import Unfile from "../../../src/assets/crmIcons/Name=Unfile";
import ArrowLeft from "../../../src/assets/crmIcons/Name=Arrow Left";
import Filter from "../../../src/assets/crmIcons/Icon=Filter";
import Tasks from "../../../src/assets/crmIcons/Name=Tasks";
import Settings from "../../../src/assets/crmIcons/Name=Settings";
import Logout from "../../../src/assets/crmIcons/Name=Logout";
import Person from "../../../src/assets/crmIcons/Name=Person";
import NewBox from "../../../src/assets/crmIcons/Name=New Box";
import EmailSecure from "../../../src/assets/crmIcons/Name=Email Secure";
import EmailAlert from "../../../src/assets/crmIcons/Name=Email Alert";
import EmailPending from "../../../src/assets/crmIcons/Name=Email Pending";
import EmailQuestion from "../../../src/assets/crmIcons/Name=Email Question";
import SailBoat from "../../../src/assets/crmIcons/SailBoat";
import Phone from "../../../src/assets/crmIcons/Name=Phone";
import PhoneDisabled from "../../../src/assets/crmIcons/Name=Phone Disabled";
import CalendarCross from "../../../src/assets/crmIcons/Name=Calendar Cross";
import Air from "../../../src/assets/crmIcons/Name=Air";
import Alert from "../../../src/assets/crmIcons/Name=Alert";
import Report from "../../../src/assets/crmIcons/Name=Report";
import CanDownload from "../../../src/assets/crmIcons/Name=Can Download";
import QuestionMark from "../../../src/assets/crmIcons/Name=Question Mark";
import Bold from "../../../src/assets/crmIcons/Icon=Bold";
import Italic from "../../../src/assets/crmIcons/Icon=Italic";
import Underline from "../../../src/assets/crmIcons/Icon=Underline";
import BulletList from "../../../src/assets/crmIcons/Icon=BulletList";
import NumberList from "../../../src/assets/crmIcons/Icon=NumberList";
import SendEmail from "../../../src/assets/crmIcons/Icon=Send Email";
import Reply from "../../../src/assets/crmIcons/Name=Reply";
import Internal from "../../../src/assets/crmIcons/Name=Internal";
import External from "../../../src/assets/crmIcons/Name=External";
import QuoteLeft from "../../../src/assets/crmIcons/Name=Quote Left";
import QuoteRight from "../../../src/assets/crmIcons/Name=Quote Right";
import Job from "../../../src/assets/crmIcons/Name=Job";
import Triangle from "../../../src/assets/crmIcons/Name=Triangle";
import Compact from "../../../src/assets/crmIcons/Name=Compact";
import Comfy from "../../../src/assets/crmIcons/Name=Comfy";
import Spacious from "../../../src/assets/crmIcons/Name=Spacious";
import TickedEmail from "../../../src/assets/crmIcons/Name=Ticked Email";
import Enter from "../../../src/assets/crmIcons/Name=Enter";
import DeleteAll from "../../../src/assets/crmIcons/Name=Delete All";
import Enlarge from "../../../src/assets/crmIcons/Name=Enlarge";
import Shrink from "../../../src/assets/crmIcons/Name=Shrink";
import Resize from "../../../src/assets/crmIcons/Name=Resize";
import Introducers from "../../../src/assets/crmIcons/Name=Introducers";
import OpenDoor from "../../../src/assets/crmIcons/Name=Open Door";
import ChatPoints from "../../../src/assets/crmIcons/Name=Chat Points";
import Email from "../../../src/assets/crmIcons/Name=Email";
import Lightbulb from "../../../src/assets/crmIcons/Name=Lightbulb";
import Pin from "../../../src/assets/crmIcons/Name=Pin";
import Pinned from "../../../src/assets/crmIcons/Name=Pinned";
import Sad from "../../../src/assets/crmIcons/Name=Sad";
import Happy from "../../../src/assets/crmIcons/Name=Happy";
import PinList from "../../../src/assets/crmIcons/Name=Pin List";
import Timeline from "../../../src/assets/crmIcons/Name=Timeline";
import EmailNew from "../../../src/assets/crmIcons/Name=Email New";
import EventNew from "../../../src/assets/crmIcons/Name=Event New";
import NewChat from "../../../src/assets/crmIcons/Name=New Chat";
import Handshake from "../../../src/assets/crmIcons/Name=Handshake";
import DualRep from "../../../src/assets/crmIcons/Name=Dual Rep";
import LineArrowLeft from "../../../src/assets/crmIcons/Name=Line Arrow Left";
import Lock from "../../../src/assets/crmIcons/Name=Lock";
import Unlocked from "../../../src/assets/crmIcons/Name=Unlocked";
import Callback from "../../../src/assets/crmIcons/Name=Callback";
import Campaign from "../../../src/assets/crmIcons/Name=Campaign";
import Quote from "../../../src/assets/crmIcons/Name=Quote";
import Undo from "../../../src/assets/crmIcons/Name=Undo";
import Copy from "../../../src/assets/crmIcons/Name=Copy";
import ReplyReverse from "../../../src/assets/crmIcons/Name=Reply Reverse";
import Huge from "../../../src/assets/crmIcons/Name=Huge";
import Large from "../../../src/assets/crmIcons/Name=Large";
import Medium from "../../../src/assets/crmIcons/Name=Medium";
import Small from "../../../src/assets/crmIcons/Name=Small";
import Roadblocks from "../../../src/assets/crmIcons/roadblocks";
import Robot from "../../../src/assets/crmIcons/Name=Robot";
import Action from "../../../src/assets/crmIcons/Name=Action";
import KeyLeft from "../../../src/assets/crmIcons/Name=KeyLeft";
import KeyRight from "../../../src/assets/crmIcons/Name=KeyRight";
import KeyUp from "../../../src/assets/crmIcons/Name=KeyUp";
import KeyDown from "../../../src/assets/crmIcons/Name=KeyDown";
import Roadblock from "../../../src/assets/crmIcons/Name=Roadblock";
import Hourglass from "../../../src/assets/crmIcons/Name=Hourglass";
import QuestionAnwser from "../../../src/assets/crmIcons/Name=Question Anwser";
import Signature from "../../../src/assets/crmIcons/Name=Signature";
import Broken from "../../../src/assets/crmIcons/Name=Broken";
import Flag from "../../../src/assets/crmIcons/Name=Flag";
import Speed from "../../../src/assets/crmIcons/Name=Speed";
import PinnedSide from "../../../src/assets/crmIcons/Name=PinnedSide";
import HouseFilled from "../../../src/assets/crmIcons/Name=HouseFilled";
import Pause from "../../../src/assets/crmIcons/Name=Pause";
import InwardArrows from "../../../src/assets/crmIcons/Name=Inward Arrows";
import PhoneFilled from "../../../src/assets/crmIcons/Name=Phone Filled";
import Chase from "../../../src/assets/crmIcons/Name=Chase";
import ChaseStop from "../../../src/assets/crmIcons/Name=Stop Chase";
import ChaseTimeout from "../../../src/assets/crmIcons/Name=Chase Timeout";
import { TCRMMainColours } from "../../models/TCRMMainColours";
import { THexColor } from "../../models/StringLiterals";

/* IMPORTANT
    - This icon component is setup for single tone not duo tone. All paths will be coloured to one.
    If you want insets make sure they are cliped not coloured.
    - React does not like namesspaces in svgs these look like "xmlns:dc" and similar they will need to be removed
    if they exist. Also not sure if react likes metadata but it is not needed so can be removed as well.
*/

export const iconLib = {
    "done-outline": DoneOutline,
    "pending": Pending,
    "save-and-add": SaveAndAdd,
    "save": Save,
    "done": Done,
    "add-phone": AddPhone,
    "bookmarked": Bookmarked,
    "bookmark": Bookmark,
    "down-arrow": DownArrow,
    "up-arrow": UpArrow,
    "calendar": Calendar,
    "calendar-add": CalendarAdd,
    "warning-bubble": WarningBubble,
    "star": Star,
    "delete": Delete,
    "error-outline": ErrorOutline,
    "book": Book,
    "ondemand-video": OndemandVideo,
    "form-icon": FormIcon,
    "inputs-touched": InputsTouched,
    "full-arrow-up": FullArrowUp,
    "open": Open,
    "money": Money,
    "house": House,
    "house-with-person": HouseWithPerson,
    "add": Add,
    "add-circle": AddCircle,
    "add-box": AddBox,
    "person-add": PersonAdd,
    "person-off": PersonOff,
    "pencil": Pencil,
    "checkbox-unchecked": CheckboxUnchecked,
    "checkbox-checked": CheckboxChecked,
    "checkbox-with-strikethrough": CheckboxWithStrikethrough,
    "link": Link,
    "people": People,
    "new": New,
    "stamp": Stamp,
    "legal-gavel": LegalGavel,
    "identity-badge": IdentityBadge,
    "chainlink": Chainlink,
    "chainlink-with-plus-symbol": ChainlinkWithPlusSymbol,
    "chainlink-with-strikethrough": ChainlinkWithStrikethrough,
    "house-and-building": HouseAndBuilding,
    "search-list": SearchList,
    "repeat": Repeat,
    "chart": Chart,
    "clock-full": ClockFull,
    "dynamic": Dynamic,
    "not-interested": NotInterested,
    "minus": Minus,
    "done-all": DoneAll,
    "question-in-circle": QuestionInCircle,
    "files": Files,
    "clock": Clock,
    "attach": Attach,
    "sign": Sign,
    "email-outbound": EmailOutbound,
    "email-inbound": EmailInbound,
    "email-notification": EmailNotification,
    "document": Document,
    "denied": Denied,
    "options": Options,
    "radio-unselected": RadioUnselected,
    "radio-selected": RadioSelected,
    "dot": Dot,
    "close": Close,
    "arrow-up": ArrowUp,
    "arrow-down": ArrowDown,
    "eye": Eye,
    "tooltip-open": TooltipOpen,
    "arrow-right": ArrowRight,
    "file-clip": FileClip,
    "file-clip-notification": FileClipNotification,
    "file-clip-tick": FileClipTick,
    "forward-arrow": ForwardArrow,
    "backward-arrow": BackwardArrow,
    "file-box": FileBox,
    "archive": Archive,
    "chat-bubble-outbound": ChatBubbleOutbound,
    "chat-bubble": ChatBubble,
    "cases-tick": CasesTick,
    "person-tick": PersonTick,
    "jobs": Jobs,
    "snooze": Snooze,
    "alarm": Alarm,
    "cresent-moon": CresentMoon,
    "half-moon": HalfMoon,
    "sun": Sun,
    "camera": Camera,
    "hand": Hand,
    "backspace": Backspace,
    "lined-arrow": LinedArrow,
    "wave": Wave,
    "calendar-tick": CalendarTick,
    "users": Users,
    "unfile": Unfile,
    "arrow-left": ArrowLeft,
    "filter": Filter,
    "tasks": Tasks,
    "sailboat": SailBoat,
    "settings": Settings,
    "logout": Logout,
    "person": Person,
    "new-box": NewBox,
    "email-secure": EmailSecure,
    "email-pending": EmailPending,
    "email-alert": EmailAlert,
    "email-question": EmailQuestion,
    "phone": Phone,
    "phone-disabled": PhoneDisabled,
    "calendar-cross": CalendarCross,
    "air": Air,
    "alert": Alert,
    "report": Report,
    "can-download": CanDownload,
    "question-mark": QuestionMark,
    "bold" : Bold,
    "italic" : Italic,
    "underline" : Underline,
    "bullet-list" : BulletList,
    "number-list" : NumberList,
    "send-email" : SendEmail,
    "reply": Reply,
    "internal": Internal,
    "external": External,
    "quote-left": QuoteLeft,
    "quote-right": QuoteRight,
    "job": Job,
    "triangle": Triangle,
    "compact": Compact,
    "comfy": Comfy,
    "spacious": Spacious,
    "email-ticked": TickedEmail,
    "enter": Enter,
    "delete-all": DeleteAll,
    "enlarge": Enlarge,
    "shrink": Shrink,
    "resize": Resize,
    "introducer": Introducers,
    "open-door": OpenDoor,
    "chat-points": ChatPoints,
    "email": Email,
    "lightbulb": Lightbulb,
    "pin": Pin,
    "pinned": Pinned,
    "sad": Sad,
    "happy": Happy,
    "timeline": Timeline,
    "pin-list": PinList,
    "new-chat": NewChat,
    "email-new": EmailNew,
    "event-new": EventNew,
    "handshake": Handshake,
    "dual-rep": DualRep,
    "line-arrow-left": LineArrowLeft,
    "lock": Lock,
    "unlocked": Unlocked,
    "callback": Callback,
    "campaign": Campaign,
    "quote": Quote,
    "undo": Undo,
    "copy": Copy,
    "reply-reverse": ReplyReverse,
    "roadblocks": Roadblocks,
    "huge": Huge,
    "large": Large,
    "medium": Medium,
    "small": Small,
    "robot": Robot,
    "action": Action,
    "key-left": KeyLeft,
    "key-right": KeyRight,
    "key-up": KeyUp,
    "key-down": KeyDown,
    "roadblock": Roadblock,
    "hourglass": Hourglass,
    "question-anwser": QuestionAnwser,
    "signature": Signature,
    "broken": Broken,
    "flag": Flag,
    "speed": Speed,
    "pinned-side": PinnedSide,
    "house-filled": HouseFilled,
    "pause": Pause,
    "inward-arrows": InwardArrows,
    "phone-filled": PhoneFilled,
    "chase": Chase,
    "chase-stop": ChaseStop,
    "chase-timeout": ChaseTimeout,
} as const;

export type TIcon = keyof typeof iconLib;

type TSizeInPixels = `${number}px`;

export type TIconSizes = "tiny" | "x-small" | "small" | "medium" | "extra-medium" | "large" | "extra-large" | "giant" | TSizeInPixels;

export interface IIcon {
    iconName: TIcon;
    colour: TCRMMainColours | "hex";
    hexColour?: THexColor;
    size?: TIconSizes;
    onClick?: (e: React.MouseEvent<SVGElement, MouseEvent>) => void;
    inline?: boolean;
    title?: string;
    cursor?: CSSProperties["cursor"];
}
 
export class CRMIcon extends React.Component<React.PropsWithChildren<IIcon>> {
    public render (): JSX.Element {
        const IconElement = iconLib[this.props.iconName];
        return (
            <div title={this.props.title}>
                <div 
                    style={{
                        cursor: `${this.props.cursor ? this.props.cursor : typeof this.props.onClick === "function" ? "pointer" : "inherit"}`,
                        height: getIconSizeInPixels(this.props.size || "medium"),
                        width: getIconSizeInPixels(this.props.size || "medium"),
                    }}
                >
                    <IconElement
                        className={`
                            crm-icon 
                            crm-icon--colour-${this.props.hexColour ? "hex" : this.props.colour}
                            ${this.props.inline ? "crm-icon--inline" : ""}
                        `}
                        style={{
                            height: getIconSizeInPixels(this.props.size || "medium"),
                            width: getIconSizeInPixels(this.props.size || "medium"),
                            color: this.props.hexColour ? this.props.hexColour : ""
                        }}
                        onClick={this.props.onClick}
                    />
                </div>
            </div>
        );
    }
}

const getIconSizeInPixels = (size: TIconSizes): TSizeInPixels => {
    switch (size) {
        case "giant":
            return "50px";
        case "extra-large":
            return "36px";
        case "large":
            return "30px";
        case "extra-medium":
            return "26px";
        case "medium":
            return "24px";
        case "small":
            return "20px";
        case "x-small":
            return "18px";
        case "tiny":
            return "16px";
        default:
            return size;
    }
}