import { TTypeOfCodec } from "../../shared/src/codecs/codec";
import { boolean } from "../../shared/src/codecs/types/boolean";
import { dateTime } from "../../shared/src/codecs/types/dateTime";
import { nullCodec } from "../../shared/src/codecs/types/nullCodec";
import { required } from "../../shared/src/codecs/types/required";
import { string } from "../../shared/src/codecs/types/string";
import { union } from "../../shared/src/codecs/types/union";
import { CasesDocumentType } from "./CasesDocumentTypes";

export const CasesDocument1 = required({
    type: CasesDocumentType
});
export type TCasesDocument1 = TTypeOfCodec<typeof CasesDocument1>;


export const CasesDocument2 = required({
    type: CasesDocumentType,
    received_date: union([dateTime(), nullCodec()]),
});
export type TCasesDocument2 = TTypeOfCodec<typeof CasesDocument2>;

export const CasesDocumentFile1 = required({
    id: string(),
    type: CasesDocumentType,
    name: string(),
    file_extension: string(),
    additional_name: string(),
    download_token: string(),
    from_external_source: boolean(),
});
export type TCasesDocumentFile1 = TTypeOfCodec<typeof CasesDocumentFile1>;
export type TCasesDocumentFile1Codec = typeof CasesDocumentFile1;

export const CasesDocumentFileDownloadToken = required({
    download_token: string(),
});
export type TCasesDocumentFileDownloadToken = TTypeOfCodec<typeof CasesDocumentFileDownloadToken>;