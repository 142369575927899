import React from "react";
import { FontSize } from "../BuildingBlocks/FontSize";
import { TextColor } from "../BuildingBlocks/TextColor";
import { Uppercase } from "../Uppercase/Uppercase";
import { WeightBold } from "../WeightBold/WeightBold"
import { CRMFontSizes } from "../../models/CRMFontSizes";
import { CRMColors } from "../../models/CRMColors";
import { FontSignikaNegative } from "../BuildingBlocks/FontSignikaNegative";

export class CRMTitleSubSection extends React.Component<React.PropsWithChildren<{}>> {
    public render (): JSX.Element {
        return (
            <FontSignikaNegative>
                <FontSize size={CRMFontSizes.MED}>
                    <TextColor color={CRMColors.NEUTRAL_INK}>
                        <Uppercase>
                            <WeightBold>
                                {this.props.children}
                            </WeightBold>
                        </Uppercase>
                    </TextColor>
                </FontSize>
            </FontSignikaNegative>
        );
    }
}
