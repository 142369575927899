import * as t from "io-ts";
import * as util from "../../shared/src/util";

export const codec = t.union([
    t.literal("unknown"),
    t.literal("good"),
    t.literal("good_benefit_from_refurbishment"),
    t.literal("poor_liveable_but_requires_work"),
    t.literal("poor_not_immediately_liveable"),
]);

export const values = util.typeValuesFromCodec(codec.types as Array<t.LiteralC<T>>);

type T = t.TypeOf<typeof codec>;

export const toDisplayName = (p: T): string => {
    switch (p) {
        case "unknown":
            return "Unknown";
        case "good":
            return "Good";
        case "good_benefit_from_refurbishment":
            return "Good, would benefit from refurbishment";
        case "poor_liveable_but_requires_work":
            return "Poor, liveable but required work";
        case "poor_not_immediately_liveable":
            return "Poor, not immediately liveable";
        default:
            return util.requireExhaustive(p);
    }
};
