import * as t from "io-ts";
import * as util from "../../shared/src/util";

type TDisplayName =
    "Unknown"
    | "Job"
    | "New family"
    | "Future family"
    | "Family outgrowing current place"
    | "Get on the ladder"
    | "Downsize"
    | "Family live down there"
    | "They like the area"
    | "Other"
    | "Not moving";

export const codec = t.union([
    t.literal("unknown"),
    t.literal("job"),
    t.literal("new_family"),
    t.literal("future_family"),
    t.literal("family_outgrowing_current_place"),
    t.literal("get_on_ladder"),
    t.literal("downsize"),
    t.literal("family_live_down_there"),
    t.literal("like_the_area"),
    t.literal("other"),
    t.literal("not_moving"),
]);

export const values = util.typeValuesFromCodec(codec.types as Array<t.LiteralC<T>>);

type T = t.TypeOf<typeof codec>;

export const fromValueToDisplayName = (value: T): TDisplayName => {
    switch (value) {
        case "unknown":
            return "Unknown";
        case "job":
            return "Job";
        case "new_family":
            return "New family";
        case "future_family":
            return "Future family";
        case "family_outgrowing_current_place":
            return "Family outgrowing current place";
        case "get_on_ladder":
            return "Get on the ladder";
        case "downsize":
            return "Downsize";
        case "family_live_down_there":
            return "Family live down there";
        case "like_the_area":
            return "They like the area";
        case "other":
            return "Other";
        case "not_moving":
            return "Not moving";
        default:
            return util.requireExhaustive(value);
    }
};
