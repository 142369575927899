import { union } from "../../shared/src/codecs/types/union";
import { literal } from "../../shared/src/codecs/types/literal";

export const ListingHeatingType = union([
    literal("air_conditioning"),
    literal("central"),
    literal("eco_friendly"),
    literal("electric"),
    literal("gas"),
    literal("gas_central"),
    literal("night_storage"),
    literal("oil"),
    literal("solar"),
    literal("solar_water"),
    literal("under_floor"),
    literal("lpg"),
    literal("community_heating"),
]);
