import * as t from "io-ts";
import * as ProbateInterestedInState from "./ProbateInterestedInState";

export const codec = t.type({
    id: t.string,
    is_complex: t.boolean,
    gop_fee: t.number,
    estate_administration_fee: t.number,
    interested_in_state: ProbateInterestedInState.codec,
    full_name: t.string,
    phone_number: t.string,
    email: t.string,
    owns_property: t.boolean,
    number_of_properties_owned: t.union([t.null, t.number]),
    foreign_properties_owned: t.boolean,
    banking_institutes_with_assets_count: t.union([t.null, t.number]),
    organizations_with_shares_owned_count: t.union([t.null, t.number]),
    pensions_count: t.union([t.null, t.number]),
    vehicles_owned_count: t.union([t.null, t.number]),
    life_protection_policies_count: t.union([t.null, t.number]),
    government_financial_schemes_count: t.union([t.null, t.number]),
    credit_cards_count: t.union([t.null, t.number]),
    loans_outstanding_count: t.union([t.null, t.number]),
    mortgages_count: t.union([t.null, t.number]),
    will_exists: t.boolean,
    assets_and_liabilities_includes_foreign: t.boolean,
    estate_is_estimated_to_be_taxable: t.boolean,
});

export type T = t.TypeOf<typeof codec>;

export const newDefault = (): T => ({
    id: "",
    is_complex: false,
    interested_in_state: "unknown",
    gop_fee: 0,
    estate_administration_fee: 0,
    full_name: "",
    phone_number: "",
    email: "",
    owns_property: false,
    number_of_properties_owned: null,
    foreign_properties_owned: false,
    banking_institutes_with_assets_count: null,
    organizations_with_shares_owned_count: null,
    pensions_count: null,
    vehicles_owned_count: null,
    life_protection_policies_count: null,
    government_financial_schemes_count: null,
    credit_cards_count: null,
    loans_outstanding_count: null,
    mortgages_count: null,
    will_exists: false,
    assets_and_liabilities_includes_foreign: false,
    estate_is_estimated_to_be_taxable: false,
});
