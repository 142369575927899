import { TTypeOfCodec } from "../../../shared/src/codecs/codec";
import { formIO, TFormIOCodec } from "../../../shared/src/codecs/types/formIO";
import { required, TRequiredCodec } from "../../../shared/src/codecs/types/required";
import { TAnyUuidCodec, uuid } from "../../../shared/src/codecs/types/uuid";
import { PhoneNumberForm, TPhoneNumberFormCodec } from "../form/PhoneNumberForm";

export const NewUserNumberFormIO: TFormIOCodec<TRequiredCodec<{ user_id: TAnyUuidCodec}>, TPhoneNumberFormCodec> = formIO(
    required({
        user_id: uuid(),
    }),
    PhoneNumberForm
);

export type TNewUserNumberFormIOCodec = typeof NewUserNumberFormIO;

export type TNewUserNumberFormIO = TTypeOfCodec<TNewUserNumberFormIOCodec>;