import React from "react";
import { TButtonIcon } from "../../models/TButton";
import * as TFormStatus from "../../models/TFormStatus";
import { CRMFormStatusBadge } from "../CRMFormStatusBadge/CRMFormStatusBadge";
import { TIcon } from "../CRMIcon/CRMIcon";

export type TCRMFormButtonIcon = {
    formStatus: TFormStatus.T;
    ButtonElement: React.ComponentType<TButtonIcon>;
    icon: TIcon;
    title?: string;
    onClick?: () => void;
};

const FormStatusToDisabledState = (status: TFormStatus.T): boolean =>
    status === "untouched" || status === "submitting";

export class CRMFormButtonIcon extends React.Component<React.PropsWithChildren<TCRMFormButtonIcon>> {
    public render (): JSX.Element {
        const ButtonElement = this.props.ButtonElement;

        return (
            <div className="crm-form-button-icon" title={this.props.title}>
                <ButtonElement
                    icon={this.props.icon}
                    disabled={FormStatusToDisabledState(this.props.formStatus)}
                    onClick={this.props.onClick}
                />
                <div className="crm-form-button-icon__status-badge">
                    <CRMFormStatusBadge formStatus={this.props.formStatus} />
                </div>
            </div>
        );
    }
}
