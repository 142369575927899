import React, { ReactFragment } from "react";
import AnchorLink from "../components/OldFront/Simple/AnchorLinkComponent/AnchorLink";
import { TDispatch } from "../state/TDispatch";
import { TChangeRouteAction, TRouteActionType } from "../state/router/routerTypes";
import { getUrlStringForRoute } from "../state/router/getUrlStringsForRoute";
import { paths } from "../state/router/routerPaths";
import { createChangeRouteAction } from "../state/router/createChangeRouteAction";

export const AnchorLinkDispatch = (props: {
    dispatch: TDispatch;
    text: string | ReactFragment;
    action: TChangeRouteAction<TRouteActionType>;
}): JSX.Element =>
    <AnchorLink
        text={props.text}
        url={
            getUrlStringForRoute(
                paths,
                props.action.type,
                props.action.params,
                props.action.queryStringParams,
                props.action.blockParams,
            )
        }
        onClick={(e) => {
            e.preventDefault();
            props.dispatch(
                createChangeRouteAction(
                    props.action.type,
                    props.action.params,
                    props.action.queryStringParams,
                    props.action.blockParams,
                )
            );
        }}
    />;
