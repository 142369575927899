import { TTypeOfCodec } from "../../shared/src/codecs/codec";
import { union } from "../../shared/src/codecs/types/union";
import { literal } from "../../shared/src/codecs/types/literal";
import * as util from "../../shared/src/util";

export const Tenure1 = union([
    literal("freehold"),
    literal("leasehold"),
    literal("commonhold"),
    literal("unknown"),
]);
export type TTenure1Codec = typeof Tenure1;
export type TTenure1 = TTypeOfCodec<TTenure1Codec>;

const Tenure2 = union([
    literal("Freehold"),
    literal("Leasehold"),
    literal("Commonhold"),
    literal("Unknown"),
]);

type TTenure2 = TTypeOfCodec<typeof Tenure2>;

export const Tenure3 = union([
    literal("freehold"),
    literal("leasehold"),
    literal("commonhold"),
]);
export type TTenure3 = TTypeOfCodec<typeof Tenure3>;

export const Tenure1_Tenure2 = (tenure: TTenure1): TTenure2 =>
    tenure === "unknown" ? "Unknown"
    : tenure === "freehold" ? "Freehold"
    : tenure === "leasehold" ? "Leasehold"
    : tenure === "commonhold" ? "Commonhold"
    : util.requireExhaustive(tenure);
