import * as ListingDocumentType2 from "./ListingDocumentType2";
import * as ListingDocument7 from "./ListingDocument7";
import * as t from "io-ts";

export const codec = t.type({
    id: t.string,
    name: t.string,
    file_extension: t.string,
    document_type: ListingDocumentType2.codec,
    mime_type: t.string,
});

export type T = t.TypeOf<typeof codec>;

export const fromListingDocument7 = (m: ListingDocument7.T): T => (
    {
        id: m.id,
        name: m.name,
        file_extension: m.file_extension,
        document_type: m.document_type,
        mime_type: m.mime_type,
    }
);