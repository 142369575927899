import { TTypeOfCodec } from "../../../shared/src/codecs/codec";
import { array, TArrayCodec } from "../../../shared/src/codecs/types/array";
import { dateTime, TAnyDateTimeCodec } from "../../../shared/src/codecs/types/dateTime";
import { form, TFormCodec } from "../../../shared/src/codecs/types/form";
import { intersection, TIntersectionCodec } from "../../../shared/src/codecs/types/intersection";
import { literal } from "../../../shared/src/codecs/types/literal";
import { integer } from "../../../shared/src/codecs/types/integer";
import { nullCodec, TNullCodec } from "../../../shared/src/codecs/types/nullCodec";
import { required, TRequiredCodec } from "../../../shared/src/codecs/types/required";
import { string, TAnyStringCodec } from "../../../shared/src/codecs/types/string";
import { TUnionCodec, union } from "../../../shared/src/codecs/types/union";
import { TUuidCodec, uuid } from "../../../shared/src/codecs/types/uuid";
import { TUuidObjectCodec, UuidObject } from "../Id";
import { Pagination1, Pagination3, TPagination3Codec } from "../Pagination";
import { Address5 } from "../Address";
import { UserName, UserNames2 } from "../overloads/UserName";
import { boolean } from "../../../shared/src/codecs/types/boolean";
import { PlainUser, PlainUserEmpty, StaffUser2, TPlainUserEmptyCodec, TStaffUser2Codec } from "../User";
import { requireExhaustive } from "../../../shared/src/util";
import { ChatsMessageForm } from "./ChatsMessageForm";
import { TChatsMessageFormCodec } from "./ChatsMessageForm";
import { EmptyObject } from "../EmptyObject";

export const TrueRoadblockType = union([
    literal("ad-hoc"),
    literal("client_verification"),
    literal("contracts"),
    literal("quote"),
    literal("chase_instruction"),
    literal("ongoing_risk_assessment"),
    literal("title_checks"),
]);
export type TTrueRoadblockTypeCodec = typeof RoadblockType;
export type TTrueRoadblockType = TTypeOfCodec<TRoadblockTypeCodec>;

export const TestRoadblockType = union([
    literal("test_roadblock_1"),
    literal("test_roadblock_2"),
    literal("test_roadblock_3"),
    literal("test_roadblock_4"),
    literal("test_roadblock_5"),
    literal("test_roadblock_6"),
]);
export type TTestRoadblockTypeCodec = typeof RoadblockType;
export type TTestRoadblockType = TTypeOfCodec<TRoadblockTypeCodec>;

export const RoadblockType = union([
    TrueRoadblockType,
    TestRoadblockType
]);
export type TRoadblockTypeCodec = typeof RoadblockType;
export type TRoadblockType = TTypeOfCodec<TRoadblockTypeCodec>;

export const RoadblockChecklistItemType = union([
    literal("test_checklist_item_1"),
    literal("test_checklist_item_2"),
    literal("test_checklist_item_3"),
    literal("test_checklist_item_4"),
    literal("test_checklist_item_5"),
    literal("test_checklist_item_6"),
    literal("quote_created"),
    literal("quote_sent"),
    literal("draft_contract_created"),
    literal("draft_tr1_created"),
    literal("draft_contract_sent"),
    literal("os_conveyancer_details_added"),
    literal("verify_os_conveyancer"),
    literal("property_details_added"),
    literal("contract_details_added"),
    literal("estate_agent_details_added"),
    literal("add_completion_deadline_for_auction_case"),
    literal("add_os_client_details"),
    literal("case_instructed"),
    literal("client_id_checks_passed"),
    literal("client_video_calls_passed"),
    literal("email_phone_validation_check_completed"),
    literal("terms_of_engagement_agreed"),
    literal("consent_to_dual_rep"),
    literal("bank_accounts_verified"),
    literal("purchase_payment_method_set"),
    literal("all_clients_added_and_right_to_buy_or_sell_checked"),
    literal("ongoing_risk_assessment_completed"),
    literal("all_title_checks_complete"),
    literal("ad_hoc")
]);
export type TRoadblockChecklistItemTypeCodec = typeof RoadblockChecklistItemType;
export type TRoadblockChecklistItemType = TTypeOfCodec<TRoadblockChecklistItemTypeCodec>;

// CHECKLIST ITEMS
export const RoadblockChecklistItem: TRequiredCodec<{
    id: TUuidCodec,
    roadblocks_id: TUuidCodec,
    manual_item_name: TUnionCodec<[TNullCodec, TAnyStringCodec]>,
    type: TRoadblockChecklistItemTypeCodec,
    resolved_at: TUnionCodec<[TAnyDateTimeCodec, TNullCodec]>,
    resolved_by: TUnionCodec<[TUuidCodec, TNullCodec]>,
    created_by: TUnionCodec<[TUuidCodec, TNullCodec]>,
}> = required({
    id: uuid(),
    roadblocks_id: uuid(),
    manual_item_name: union([nullCodec(), string()]),
    type: RoadblockChecklistItemType,
    resolved_at: union([dateTime(), nullCodec()]),
    resolved_by: union([uuid(), nullCodec()]),
    created_by: union([uuid(), nullCodec()]),
});
export type TRoadblockCheckListItemCodec = typeof RoadblockChecklistItem;
export type TRoadblockCheckListItem = TTypeOfCodec<TRoadblockCheckListItemCodec>;


export const RoadblockChecklistItemWithData: TIntersectionCodec<[
    TRoadblockCheckListItemCodec,
    TRequiredCodec<{
        roadblock_type: TRoadblockTypeCodec,
        created_by_user: TPlainUserEmptyCodec,
        resolved_by_user: TPlainUserEmptyCodec
    }>
]> = intersection([
    RoadblockChecklistItem,
    required({
        roadblock_type: RoadblockType,
        created_by_user: PlainUserEmpty,
        resolved_by_user: PlainUserEmpty,
    })
]);
export type TRoadblockChecklistItemWithDataCodec = typeof RoadblockChecklistItemWithData;
export type TRoadblockChecklistItemWithData = TTypeOfCodec<TRoadblockChecklistItemWithDataCodec>;


export const RoadblockCheckListItemManual: TRequiredCodec<{
    id: TUuidCodec,
    name: TAnyStringCodec,
    resolved_at: TUnionCodec<[TNullCodec, TAnyDateTimeCodec]>,
    resolved_by: TUnionCodec<[TNullCodec, TUuidCodec]>,
    resolved_by_name: TUnionCodec<[TNullCodec, TAnyStringCodec]>
}> = required({
    id: uuid(),
    name: string(),
    resolved_at: union([nullCodec(), dateTime()]),
    resolved_by: union([nullCodec(), uuid()]),
    resolved_by_name: union([nullCodec(), string()]),
});
export type TRoadblockCheckListItemManualCodec = typeof RoadblockCheckListItemManual;
export type TRoadblockCheckListItemManual = TTypeOfCodec<TRoadblockCheckListItemManualCodec>;


export const RoadblockChecklistItemAutomatic: TIntersectionCodec<[
    TRoadblockCheckListItemManualCodec,
    TRequiredCodec<{
        resolution_instruction: TAnyStringCodec
    }>
]> = intersection([
    RoadblockCheckListItemManual,
    required({
        resolution_instruction: string(),
    })
]);
export type TRoadblockCheckListItemAutomaticCodec = typeof RoadblockChecklistItemAutomatic;
export type TRoadblockCheckListItemAutomatic = TTypeOfCodec<TRoadblockCheckListItemAutomaticCodec>;


export const RoadblockCheckListItemManualForm: TFormCodec<
    TRoadblockCheckListItemManualCodec,
    TRequiredCodec<{
        roadblock_id: TAnyStringCodec,
        roadblock_type: TRoadblockTypeCodec,
        case_id: TUuidCodec
    }>
> = form(
    RoadblockCheckListItemManual,
    required({
        roadblock_id: string(),
        roadblock_type: RoadblockType,
        case_id: uuid(),
    })
);
export type TRoadblockCheckListItemManualFormCodec = typeof RoadblockCheckListItemManualForm;
export type TRoadblockCheckListItemManualForm = TTypeOfCodec<TRoadblockCheckListItemManualFormCodec>;


export const RoadblockSignOffForm = form(
    required({
        roadblock_id: uuid(),
        signed_by: uuid(),
    }),
    EmptyObject
);
export type TRoadblockSignOffFormCodec = typeof RoadblockSignOffForm;
export type TRoadblockSignOffForm = TTypeOfCodec<TRoadblockSignOffFormCodec>;
// END CHECKLIST ITEMS

export const RoadblockActionTag = union([
    literal("note"),
    literal("sign_off_invalidation"),
    literal("assignment"),
    literal("await"),
    literal("open"),
    literal("closed"),
]);
export type TRoadblockActionTagCodec = typeof RoadblockActionTag;
export type TRoadblockActionTag = TTypeOfCodec<TRoadblockActionTagCodec>;

export const ChatMessageAction = required({
    tag: literal("message"),
    action_date: dateTime(),
    action: required({
        note_body: string(),
        created_at: dateTime(),
        created_by: union([uuid(), nullCodec()])
    }),
});
export type TChatMessageActionCodec = typeof ChatMessageAction;
export type TChatMessageAction = TTypeOfCodec<TChatMessageActionCodec>;
export const isChatMessageAction = (action: TRoadblockAction): action is TChatMessageAction => action.tag === "message";

export const SignOffInvalidationAction = required({
    tag: literal("sign_off_invalidation"),
    action_date: dateTime(),
    action: required({
        id: uuid(),
        user: union([
            nullCodec(),
            UserName,
        ]),
        sign_off_invalidated_reason: string(),
        created_by: nullCodec(),
    }),
});
export type TSignOffInvalidationActionCodec = typeof SignOffInvalidationAction;
export type TSignOffInvalidationAction = TTypeOfCodec<TSignOffInvalidationActionCodec>;
export const isSignOffInvalidationAction = (action: TRoadblockAction): action is TSignOffInvalidationAction => action.tag === "sign_off_invalidation";

export const ChatAssignmentAction = required({
    tag: literal("assignment"),
    action_date: dateTime(),
    action: required({
        assigned_to_user_id: union([uuid(), nullCodec()]),
        created_at: dateTime(),
        created_by: union([uuid(), nullCodec()]),
        created_by_note_id: union([uuid(), nullCodec()]),
    }),
});
export type TChatAssignmentActionCodec = typeof ChatAssignmentAction;
export type TChatAssignmentAction = TTypeOfCodec<TChatAssignmentActionCodec>;
export const isChatAssignmentAction = (action: TRoadblockAction): action is TChatAssignmentAction => action.tag === "assignment";

export const ChatAwaitAction = required({
    tag: literal("await"),
    action_date: dateTime(),
    action: required({
        blocked_until: dateTime(),
        blocked_by: string(),
        cancelled_at: union([nullCodec(), dateTime()]),
        cancelled_by: union([uuid(), nullCodec()]),
        cancelled_by_note_id: union([uuid(), nullCodec()]),
        created_at: dateTime(),
        created_by: union([uuid(), nullCodec()]),
        created_by_note_id: union([uuid(), nullCodec()]),
    }),
});
export type TChatAwaitActionCodec = typeof ChatAwaitAction;
export type TChatAwaitAction = TTypeOfCodec<TChatAwaitActionCodec>;
export const isChatAwaitAction = (action: TRoadblockAction): action is TChatAwaitAction => action.tag === "await";

export const RoadblockClosedType = union([
    nullCodec(),
    literal("resolved"),
    literal("suspended")
]);

const OpenClosedActionDetails = required({
    starts_at: dateTime(),
    closed_at: union([dateTime(), nullCodec()]),
    closed_by: union([uuid(), nullCodec()]),
    closed_by_note_id: union([uuid(), nullCodec()]),
    created_at: dateTime(),
    created_by: union([uuid(), nullCodec()]),
    created_by_note_id: union([uuid(), nullCodec()]),
    closed_type: RoadblockClosedType
})

export const RoadblockOpenAction = required({
    tag: literal("open"),
    action_date: dateTime(),
    action: OpenClosedActionDetails,
});
export type TRoadblockOpenActionCodec = typeof RoadblockOpenAction;
export type TRoadblockOpenAction = TTypeOfCodec<TRoadblockOpenActionCodec>;
export const isRoadblockOpenAction = (action: TRoadblockAction): action is TRoadblockOpenAction => action.tag === "open";

export const RoadblockClosedAction = required({
    tag: literal("closed"),
    action_date: dateTime(),
    action: OpenClosedActionDetails,
});
export type TRoadblockClosedActionCodec = typeof RoadblockClosedAction;
export type TRoadblockClosedAction = TTypeOfCodec<TRoadblockClosedActionCodec>;
export const isRoadblockClosedAction = (action: TRoadblockAction): action is TRoadblockClosedAction => action.tag === "closed";
export const isRoadblockResolvedAction = (action: TRoadblockAction): action is TRoadblockClosedAction => 
    action.tag === "closed" && action.action.closed_type === "resolved"
;

export const RoadblockCheckItemAction = required({
    tag: literal("check-item"),
    action_date: dateTime(),
    action: RoadblockChecklistItem,
});
export type TRoadblockCheckItemActionCodec = typeof RoadblockCheckItemAction;
export type TRoadblockCheckItemAction = TTypeOfCodec<TRoadblockCheckItemActionCodec>;
export const isRoadblockCheckItemAction = (action: TRoadblockAction): action is TRoadblockCheckItemAction => action.tag === "check-item";

export const RoadblockAction = union([
    ChatMessageAction,
    SignOffInvalidationAction,
    ChatAssignmentAction,
    ChatAwaitAction,
    RoadblockOpenAction,
    RoadblockClosedAction,
    RoadblockCheckItemAction,
]);
export type TRoadblockActionCodec = typeof RoadblockAction;
export type TRoadblockAction = TTypeOfCodec<TRoadblockActionCodec>;

export const RoadblockStatus = union([
    literal("open"),
    literal("blocked"),
    literal("resolved"),
    literal("awaiting_signoff"),
    literal("suspended")
]);
export type TRoadblockStatusCodec = typeof RoadblockStatus;
export type TRoadblockStatus = TTypeOfCodec<TRoadblockStatusCodec>;
export const RoadblockStatus_displayString = (p: TRoadblockStatus): string =>
    p === "open" ? "Open"
    : p === "blocked" ? "Blocked"
    : p === "suspended" ? "Suspended"
    : p === "resolved" ? "Resolved"
    : p === "awaiting_signoff" ? "Awaiting Signoff"
    : requireExhaustive(p);

export const RoadblockDetails = intersection([
    required({
        case_id: uuid(),
        chats_id: uuid(),
        parent_roadblock_id: union([uuid(), nullCodec()]),
        roadblock_type: RoadblockType,
        created_at: dateTime(),
        created_by: union([uuid(), nullCodec()]),
        status: RoadblockStatus,
        sla: dateTime(),
        blocked_till: union([nullCodec(), dateTime()]),
        blocked_by_user: union([nullCodec(), UserName]),
        blocked_waiting_on: string(),
        resolved_by_user: union([nullCodec(), UserName]),
        resolved_on: union([nullCodec(), dateTime()]),
    }),
]);
export type TRoadblockDetailsCodec = typeof RoadblockDetails;
export type TRoadblockDetails = TTypeOfCodec<TRoadblockDetailsCodec>;

export const RoadblockAwaitableEntity = required({
    id: uuid(),
    created_at: dateTime(),
    blocked_until_days: integer(),
    name: string()
});
export type TRoadblockAwaitableEntityCodec = typeof RoadblockAwaitableEntity;
export type TRoadblockAwaitableEntity = TTypeOfCodec<TRoadblockAwaitableEntityCodec>;


export const RoadblockForm: TFormCodec<
    TUuidObjectCodec, 
    TRequiredCodec<{
        actions: TArrayCodec<TRoadblockActionCodec>,
        details: TRoadblockDetailsCodec,
        participants: TArrayCodec<TStaffUser2Codec>,
        awaitable_entities: TArrayCodec<TRoadblockAwaitableEntityCodec>,
        resolve_validation_errors: TArrayCodec<TArrayCodec<TAnyStringCodec>>,
        
        checklist_items_automatic: TArrayCodec<TRoadblockCheckListItemAutomaticCodec>,
        checklist_items_manual: TArrayCodec<TRoadblockCheckListItemManualFormCodec>,

        signoff_form: TRoadblockSignOffFormCodec,
        chat_message_form: TChatsMessageFormCodec,
    }>
> = form(
    UuidObject,
    required({
        actions: array(RoadblockAction),
        details: RoadblockDetails,
        participants: array(StaffUser2),
        awaitable_entities: array(RoadblockAwaitableEntity),
        resolve_validation_errors: array(array(string())),
        
        checklist_items_automatic: array(RoadblockChecklistItemAutomatic),        
        checklist_items_manual: array(RoadblockCheckListItemManualForm),

        signoff_form: RoadblockSignOffForm,
        chat_message_form: ChatsMessageForm,
    }),
);
export type TRoadblockFormCodec = typeof RoadblockForm;
export type TRoadblockForm = TTypeOfCodec<TRoadblockFormCodec>;

export const RoadblocksFilters = intersection([
    required({
        assignedToUserId: union([nullCodec(), uuid()]),
        actionableStatus: union([literal("actionable"), literal("blocked")]),
        roadblockType: union([nullCodec(), RoadblockType])
    }),
    Pagination1
]);
export type TRoadblocksFiltersCodec = typeof RoadblocksFilters;
export type TRoadblocksFilters = TTypeOfCodec<TRoadblocksFiltersCodec>;

export const RoadblocksListItem = intersection([
    required({
        id: uuid(),
        type: RoadblockType,
        clients: UserNames2,
        case_address: Address5,
        case_id: uuid(),
        status: RoadblockStatus
    }),
])
export type TRoadblocksListItemCodec = typeof RoadblocksListItem;
export type TRoadblocksListItem = TTypeOfCodec<TRoadblocksListItemCodec>;


export const RoadblocksForm: TFormCodec<
    TRoadblocksFiltersCodec,
    TRequiredCodec<{
        counts: TPagination3Codec;
        roadblocks: TArrayCodec<TRoadblocksListItemCodec>;
    }>
> = form(
    RoadblocksFilters,
    required({
        counts: Pagination3,
        roadblocks: array(RoadblocksListItem),
    })
);
export type TRoadblocksFormCodec = typeof RoadblocksForm;
export type TRoadblocksForm = TTypeOfCodec<TRoadblocksFormCodec>;

export const RoadblocksListItem2 =
    required({
        id: uuid(),
        type: RoadblockType,
        status: RoadblockStatus,
        sla: dateTime(),
        opened_on: dateTime(),
        last_closed_on: union([dateTime(), nullCodec()]),
        assigned_users: array(PlainUser),
        detailed_roadblock_form: RoadblockForm,
    });
export type TRoadblocksListItem2Codec = typeof RoadblocksListItem2;
export type TRoadblocksListItem2 = TTypeOfCodec<TRoadblocksListItem2Codec>;

export const RoadblocksFilters2 =
    required({
        case_id: uuid(),
        assigned_to_me: boolean(),
    });
export type TRoadblocksFilters2Codec = typeof RoadblocksFilters2;
export type TRoadblocksFilters2 = TTypeOfCodec<TRoadblocksFilters2Codec>;

export const RoadblocksForm2: TFormCodec<
    TRoadblocksFilters2Codec,
    TRequiredCodec<{
        roadblocks: TArrayCodec<TRoadblocksListItem2Codec>
    }>
> = form(
    RoadblocksFilters2,
    required({
        roadblocks: array(RoadblocksListItem2)
    }),
);
export type TRoadblocksForm2Codec = typeof RoadblocksForm2;
export type TRoadblocksForm2 = TTypeOfCodec<TRoadblocksForm2Codec>;
