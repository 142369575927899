import { TTypeOfCodec, TTypeOfNewDefault } from "../../shared/src/codecs/codec";
import { literal } from "../../shared/src/codecs/types/literal";
import { overload } from "../../shared/src/codecs/types/overload";
import { union } from "../../shared/src/codecs/types/union";
import { requireExhaustive } from "../../shared/src/util";
import { Country1ToCopyText } from "./overloads/Address";

export const CountryList: Array<TCountry1> = [
    "england", 
    "scotland", 
    "wales", 
    "northern_ireland", 
    "unknown"
]; 

export const Country1 = union([literal("england"), literal("scotland"), literal("wales"), literal("northern_ireland"), literal("unknown")]);
export type TCountry1 = TTypeOfCodec<typeof Country1>;

export const CountryCopyText = union([
    literal("England"), 
    literal("Scotland"), 
    literal("Wales"), 
    literal("Northern Ireland"), 
    literal("Unknown")
]);
export type TCountryCopyText = TTypeOfCodec<typeof CountryCopyText>;
export type TCountryCopyTextNewDefault = TTypeOfNewDefault<typeof CountryCopyText>;

export const Country2 = overload(
    CountryCopyText,
    Country1,
    Country1ToCopyText
);

export const Country3 = union([
    literal("England"),
    literal("Scotland"),
    literal("Wales"),
    literal("Northern Ireland"),
]);
type TCountry3 = TTypeOfCodec<typeof Country3>;

export const Country3ToCountry1 = (c3: TCountry3): TCountry1 => {
    switch (c3) {
        case "England":
            return "england";
        case "Scotland":
            return "scotland";
        case "Wales":
            return "wales";
        case "Northern Ireland":
            return "northern_ireland";
        default:
            return requireExhaustive(c3);
    }
};

export const Country4 = union([
    literal("unknown"),
    literal("GB"),
    literal("AF"),
    literal("AX"),
    literal("AL"),
    literal("DZ"),
    literal("AS"),
    literal("AD"),
    literal("AO"),
    literal("AI"),
    literal("AQ"),
    literal("AG"),
    literal("AR"),
    literal("AM"),
    literal("AW"),
    literal("AU"),
    literal("AT"),
    literal("AZ"),
    literal("BS"),
    literal("BH"),
    literal("BD"),
    literal("BB"),
    literal("BY"),
    literal("BE"),
    literal("BZ"),
    literal("BJ"),
    literal("BM"),
    literal("BT"),
    literal("BO"),
    literal("BA"),
    literal("BW"),
    literal("BV"),
    literal("BR"),
    literal("IO"),
    literal("BN"),
    literal("BG"),
    literal("BF"),
    literal("BI"),
    literal("KH"),
    literal("CM"),
    literal("CA"),
    literal("CV"),
    literal("KY"),
    literal("CF"),
    literal("TD"),
    literal("CL"),
    literal("CN"),
    literal("CX"),
    literal("CC"),
    literal("CO"),
    literal("KM"),
    literal("CG"),
    literal("CD"),
    literal("CK"),
    literal("CR"),
    literal("CI"),
    literal("HR"),
    literal("CU"),
    literal("CY"),
    literal("CZ"),
    literal("DK"),
    literal("DJ"),
    literal("DM"),
    literal("DO"),
    literal("EC"),
    literal("EG"),
    literal("SV"),
    literal("GQ"),
    literal("ER"),
    literal("EE"),
    literal("ET"),
    literal("FK"),
    literal("FO"),
    literal("FJ"),
    literal("FI"),
    literal("FR"),
    literal("GF"),
    literal("PF"),
    literal("TF"),
    literal("GA"),
    literal("GM"),
    literal("GE"),
    literal("DE"),
    literal("GH"),
    literal("GI"),
    literal("GR"),
    literal("GL"),
    literal("GD"),
    literal("GP"),
    literal("GU"),
    literal("GT"),
    literal("GG"),
    literal("GN"),
    literal("GW"),
    literal("GY"),
    literal("HT"),
    literal("HM"),
    literal("VA"),
    literal("HN"),
    literal("HK"),
    literal("HU"),
    literal("IS"),
    literal("IN"),
    literal("ID"),
    literal("IR"),
    literal("IQ"),
    literal("IE"),
    literal("IM"),
    literal("IL"),
    literal("IT"),
    literal("JM"),
    literal("JP"),
    literal("JE"),
    literal("JO"),
    literal("KZ"),
    literal("KE"),
    literal("KI"),
    literal("KR"),
    literal("KP"),
    literal("KW"),
    literal("KG"),
    literal("LA"),
    literal("LV"),
    literal("LB"),
    literal("LS"),
    literal("LR"),
    literal("LY"),
    literal("LI"),
    literal("LT"),
    literal("LU"),
    literal("MO"),
    literal("MK"),
    literal("MG"),
    literal("MW"),
    literal("MY"),
    literal("MV"),
    literal("ML"),
    literal("MT"),
    literal("MH"),
    literal("MQ"),
    literal("MR"),
    literal("MU"),
    literal("YT"),
    literal("MX"),
    literal("FM"),
    literal("MD"),
    literal("MC"),
    literal("MN"),
    literal("ME"),
    literal("MS"),
    literal("MA"),
    literal("MZ"),
    literal("MM"),
    literal("NA"),
    literal("NR"),
    literal("NP"),
    literal("NL"),
    literal("AN"),
    literal("NC"),
    literal("NZ"),
    literal("NI"),
    literal("NE"),
    literal("NG"),
    literal("NU"),
    literal("NF"),
    literal("MP"),
    literal("NO"),
    literal("OM"),
    literal("PK"),
    literal("PW"),
    literal("PS"),
    literal("PA"),
    literal("PG"),
    literal("PY"),
    literal("PE"),
    literal("PH"),
    literal("PN"),
    literal("PL"),
    literal("PT"),
    literal("PR"),
    literal("QA"),
    literal("RE"),
    literal("RO"),
    literal("RU"),
    literal("RW"),
    literal("BL"),
    literal("SH"),
    literal("KN"),
    literal("LC"),
    literal("MF"),
    literal("PM"),
    literal("VC"),
    literal("WS"),
    literal("SM"),
    literal("ST"),
    literal("SA"),
    literal("SN"),
    literal("RS"),
    literal("SC"),
    literal("SL"),
    literal("SG"),
    literal("SK"),
    literal("SI"),
    literal("SB"),
    literal("SO"),
    literal("ZA"),
    literal("GS"),
    literal("ES"),
    literal("LK"),
    literal("SD"),
    literal("SR"),
    literal("SJ"),
    literal("SZ"),
    literal("SE"),
    literal("CH"),
    literal("SY"),
    literal("TW"),
    literal("TJ"),
    literal("TZ"),
    literal("TH"),
    literal("TL"),
    literal("TG"),
    literal("TK"),
    literal("TO"),
    literal("TT"),
    literal("TN"),
    literal("TR"),
    literal("TM"),
    literal("TC"),
    literal("TV"),
    literal("UG"),
    literal("UA"),
    literal("AE"),
    literal("US"),
    literal("UM"),
    literal("UY"),
    literal("UZ"),
    literal("VU"),
    literal("VE"),
    literal("VN"),
    literal("VG"),
    literal("VI"),
    literal("WF"),
    literal("EH"),
    literal("YE"),
    literal("ZM"),
    literal("ZW"),
]);

export type TCountry4 = TTypeOfCodec<typeof Country4>;

export const Country4ToDisplayName = (p: TCountry4): string =>
    p === "unknown" ? "Unknown"
    : p === "AF" ? "Afghanistan"
    : p === "AX" ? "Aland Islands"
    : p === "AL" ? "Albania"
    : p === "DZ" ? "Algeria"
    : p === "AS" ? "American Samoa"
    : p === "AD" ? "Andorra"
    : p === "AO" ? "Angola"
    : p === "AI" ? "Anguilla"
    : p === "AQ" ? "Antarctica"
    : p === "AG" ? "Antigua And Barbuda"
    : p === "AR" ? "Argentina"
    : p === "AM" ? "Armenia"
    : p === "AW" ? "Aruba"
    : p === "AU" ? "Australia"
    : p === "AT" ? "Austria"
    : p === "AZ" ? "Azerbaijan"
    : p === "BS" ? "Bahamas"
    : p === "BH" ? "Bahrain"
    : p === "BD" ? "Bangladesh"
    : p === "BB" ? "Barbados"
    : p === "BY" ? "Belarus"
    : p === "BE" ? "Belgium"
    : p === "BZ" ? "Belize"
    : p === "BJ" ? "Benin"
    : p === "BM" ? "Bermuda"
    : p === "BT" ? "Bhutan"
    : p === "BO" ? "Bolivia"
    : p === "BA" ? "Bosnia And Herzegovina"
    : p === "BW" ? "Botswana"
    : p === "BV" ? "Bouvet Island"
    : p === "BR" ? "Brazil"
    : p === "IO" ? "British Indian Ocean Territory"
    : p === "BN" ? "Brunei Darussalam"
    : p === "BG" ? "Bulgaria"
    : p === "BF" ? "Burkina Faso"
    : p === "BI" ? "Burundi"
    : p === "KH" ? "Cambodia"
    : p === "CM" ? "Cameroon"
    : p === "CA" ? "Canada"
    : p === "CV" ? "Cape Verde"
    : p === "KY" ? "Cayman Islands"
    : p === "CF" ? "Central African Republic"
    : p === "TD" ? "Chad"
    : p === "CL" ? "Chile"
    : p === "CN" ? "China"
    : p === "CX" ? "Christmas Island"
    : p === "CC" ? "Cocos (Keeling) Islands"
    : p === "CO" ? "Colombia"
    : p === "KM" ? "Comoros"
    : p === "CG" ? "Congo"
    : p === "CD" ? "Congo, Democratic Republic"
    : p === "CK" ? "Cook Islands"
    : p === "CR" ? "Costa Rica"
    : p === "CI" ? "Cote D'Ivoire"
    : p === "HR" ? "Croatia"
    : p === "CU" ? "Cuba"
    : p === "CY" ? "Cyprus"
    : p === "CZ" ? "Czech Republic"
    : p === "DK" ? "Denmark"
    : p === "DJ" ? "Djibouti"
    : p === "DM" ? "Dominica"
    : p === "DO" ? "Dominican Republic"
    : p === "EC" ? "Ecuador"
    : p === "EG" ? "Egypt"
    : p === "SV" ? "El Salvador"
    : p === "GQ" ? "Equatorial Guinea"
    : p === "ER" ? "Eritrea"
    : p === "EE" ? "Estonia"
    : p === "ET" ? "Ethiopia"
    : p === "FK" ? "Falkland Islands (Malvinas)"
    : p === "FO" ? "Faroe Islands"
    : p === "FJ" ? "Fiji"
    : p === "FI" ? "Finland"
    : p === "FR" ? "France"
    : p === "GF" ? "French Guiana"
    : p === "PF" ? "French Polynesia"
    : p === "TF" ? "French Southern Territories"
    : p === "GA" ? "Gabon"
    : p === "GM" ? "Gambia"
    : p === "GE" ? "Georgia"
    : p === "DE" ? "Germany"
    : p === "GH" ? "Ghana"
    : p === "GI" ? "Gibraltar"
    : p === "GR" ? "Greece"
    : p === "GL" ? "Greenland"
    : p === "GD" ? "Grenada"
    : p === "GP" ? "Guadeloupe"
    : p === "GU" ? "Guam"
    : p === "GT" ? "Guatemala"
    : p === "GG" ? "Guernsey"
    : p === "GN" ? "Guinea"
    : p === "GW" ? "Guinea-Bissau"
    : p === "GY" ? "Guyana"
    : p === "HT" ? "Haiti"
    : p === "HM" ? "Heard Island & Mcdonald Islands"
    : p === "VA" ? "Holy See (Vatican City State)"
    : p === "HN" ? "Honduras"
    : p === "HK" ? "Hong Kong"
    : p === "HU" ? "Hungary"
    : p === "IS" ? "Iceland"
    : p === "IN" ? "India"
    : p === "ID" ? "Indonesia"
    : p === "IR" ? "Iran, Islamic Republic Of"
    : p === "IQ" ? "Iraq"
    : p === "IE" ? "Ireland"
    : p === "IM" ? "Isle Of Man"
    : p === "IL" ? "Israel"
    : p === "IT" ? "Italy"
    : p === "JM" ? "Jamaica"
    : p === "JP" ? "Japan"
    : p === "JE" ? "Jersey"
    : p === "JO" ? "Jordan"
    : p === "KZ" ? "Kazakhstan"
    : p === "KE" ? "Kenya"
    : p === "KI" ? "Kiribati"
    : p === "KR" ? "Korea"
    : p === "KP" ? "North Korea"
    : p === "KW" ? "Kuwait"
    : p === "KG" ? "Kyrgyzstan"
    : p === "LA" ? "Lao People's Democratic Republic"
    : p === "LV" ? "Latvia"
    : p === "LB" ? "Lebanon"
    : p === "LS" ? "Lesotho"
    : p === "LR" ? "Liberia"
    : p === "LY" ? "Libyan Arab Jamahiriya"
    : p === "LI" ? "Liechtenstein"
    : p === "LT" ? "Lithuania"
    : p === "LU" ? "Luxembourg"
    : p === "MO" ? "Macao"
    : p === "MK" ? "Macedonia"
    : p === "MG" ? "Madagascar"
    : p === "MW" ? "Malawi"
    : p === "MY" ? "Malaysia"
    : p === "MV" ? "Maldives"
    : p === "ML" ? "Mali"
    : p === "MT" ? "Malta"
    : p === "MH" ? "Marshall Islands"
    : p === "MQ" ? "Martinique"
    : p === "MR" ? "Mauritania"
    : p === "MU" ? "Mauritius"
    : p === "YT" ? "Mayotte"
    : p === "MX" ? "Mexico"
    : p === "FM" ? "Micronesia, Federated States Of"
    : p === "MD" ? "Moldova"
    : p === "MC" ? "Monaco"
    : p === "MN" ? "Mongolia"
    : p === "ME" ? "Montenegro"
    : p === "MS" ? "Montserrat"
    : p === "MA" ? "Morocco"
    : p === "MZ" ? "Mozambique"
    : p === "MM" ? "Myanmar"
    : p === "NA" ? "Namibia"
    : p === "NR" ? "Nauru"
    : p === "NP" ? "Nepal"
    : p === "NL" ? "Netherlands"
    : p === "AN" ? "Netherlands Antilles"
    : p === "NC" ? "New Caledonia"
    : p === "NZ" ? "New Zealand"
    : p === "NI" ? "Nicaragua"
    : p === "NE" ? "Niger"
    : p === "NG" ? "Nigeria"
    : p === "NU" ? "Niue"
    : p === "NF" ? "Norfolk Island"
    : p === "MP" ? "Northern Mariana Islands"
    : p === "NO" ? "Norway"
    : p === "OM" ? "Oman"
    : p === "PK" ? "Pakistan"
    : p === "PW" ? "Palau"
    : p === "PS" ? "Palestinian Territory, Occupied"
    : p === "PA" ? "Panama"
    : p === "PG" ? "Papua New Guinea"
    : p === "PY" ? "Paraguay"
    : p === "PE" ? "Peru"
    : p === "PH" ? "Philippines"
    : p === "PN" ? "Pitcairn"
    : p === "PL" ? "Poland"
    : p === "PT" ? "Portugal"
    : p === "PR" ? "Puerto Rico"
    : p === "QA" ? "Qatar"
    : p === "RE" ? "Reunion"
    : p === "RO" ? "Romania"
    : p === "RU" ? "Russian Federation"
    : p === "RW" ? "Rwanda"
    : p === "BL" ? "Saint Barthelemy"
    : p === "SH" ? "Saint Helena"
    : p === "KN" ? "Saint Kitts And Nevis"
    : p === "LC" ? "Saint Lucia"
    : p === "MF" ? "Saint Martin"
    : p === "PM" ? "Saint Pierre And Miquelon"
    : p === "VC" ? "Saint Vincent And Grenadines"
    : p === "WS" ? "Samoa"
    : p === "SM" ? "San Marino"
    : p === "ST" ? "Sao Tome And Principe"
    : p === "SA" ? "Saudi Arabia"
    : p === "SN" ? "Senegal"
    : p === "RS" ? "Serbia"
    : p === "SC" ? "Seychelles"
    : p === "SL" ? "Sierra Leone"
    : p === "SG" ? "Singapore"
    : p === "SK" ? "Slovakia"
    : p === "SI" ? "Slovenia"
    : p === "SB" ? "Solomon Islands"
    : p === "SO" ? "Somalia"
    : p === "ZA" ? "South Africa"
    : p === "GS" ? "South Georgia And Sandwich Isl."
    : p === "ES" ? "Spain"
    : p === "LK" ? "Sri Lanka"
    : p === "SD" ? "Sudan"
    : p === "SR" ? "Suriname"
    : p === "SJ" ? "Svalbard And Jan Mayen"
    : p === "SZ" ? "Swaziland"
    : p === "SE" ? "Sweden"
    : p === "CH" ? "Switzerland"
    : p === "SY" ? "Syrian Arab Republic"
    : p === "TW" ? "Taiwan"
    : p === "TJ" ? "Tajikistan"
    : p === "TZ" ? "Tanzania"
    : p === "TH" ? "Thailand"
    : p === "TL" ? "Timor-Leste"
    : p === "TG" ? "Togo"
    : p === "TK" ? "Tokelau"
    : p === "TO" ? "Tonga"
    : p === "TT" ? "Trinidad And Tobago"
    : p === "TN" ? "Tunisia"
    : p === "TR" ? "Turkey"
    : p === "TM" ? "Turkmenistan"
    : p === "TC" ? "Turks And Caicos Islands"
    : p === "TV" ? "Tuvalu"
    : p === "UG" ? "Uganda"
    : p === "UA" ? "Ukraine"
    : p === "AE" ? "United Arab Emirates"
    : p === "GB" ? "United Kingdom"
    : p === "US" ? "United States"
    : p === "UM" ? "United States Outlying Islands"
    : p === "UY" ? "Uruguay"
    : p === "UZ" ? "Uzbekistan"
    : p === "VU" ? "Vanuatu"
    : p === "VE" ? "Venezuela"
    : p === "VN" ? "Vietnam"
    : p === "VG" ? "Virgin Islands, British"
    : p === "VI" ? "Virgin Islands, U.S."
    : p === "WF" ? "Wallis And Futuna"
    : p === "EH" ? "Western Sahara"
    : p === "YE" ? "Yemen"
    : p === "ZM" ? "Zambia"
    : p === "ZW" ? "Zimbabwe"
    : requireExhaustive(p);
