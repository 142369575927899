import React from "react";

interface IAnchorLink {
    text: string | React.ReactNode;
    url: string;
    onClick?: (e: React.MouseEvent) => void;
    target?: React.AnchorHTMLAttributes<HTMLAnchorElement>["target"];
}

class AnchorLink extends React.Component<React.PropsWithChildren<IAnchorLink>> {
    public render (): JSX.Element {
        return (
            <a className="anchorLink__link"
                href={this.props.url}
                onClick={(e) => this.props.onClick ? this.props.onClick(e) : undefined}
                target={this.props.target}
            >
                {this.props.text}
            </a>
        );
    }
}

export default AnchorLink;
