import { TTypeOfCodec } from "../../shared/src/codecs/codec";
import { union } from "../../shared/src/codecs/types/union";
import { literal } from "../../shared/src/codecs/types/literal";

export const LegalEmailStatus1 = union([
    literal("unassigned"),
    literal("assigned"),
    literal("irrelevant"),
]);

export type TLegalEmailStatus1 = TTypeOfCodec<typeof LegalEmailStatus1>;
export type TLegalEmailStatus1Codec = typeof LegalEmailStatus1;
