import { TForm } from "../models/TForm";
import * as ListingSuccessResponse2 from "../../../domain/models/ListingSuccessResponse2";
import * as ListingStatus1 from "../../../domain/models/ListingStatus1";
import * as TFormStatus from "../models/TFormStatus";
import { option, function as fptsFunction, record } from "fp-ts/lib";
import * as util from "../../../shared/src/util";
import { pipe } from "fp-ts/lib/function";

type TStatusForm = TForm<
    {},
    {},
    ListingSuccessResponse2.T
>;

const newStatusForm = (): TStatusForm => ({
    status: TFormStatus.constants.UNTOUCHED,
    form: {},
    response: {},
    meta: ListingSuccessResponse2.newDefault(),
});

export class C {
    public lead: TStatusForm = newStatusForm();
    public prepValuation: TStatusForm = newStatusForm();
    public chaseInstruction: TStatusForm = newStatusForm();
    public instructedNeedsPrep: TStatusForm = newStatusForm();
    public instructedOnMarket: TStatusForm = newStatusForm();
    public sstc: TStatusForm = newStatusForm();
    public completed: TStatusForm = newStatusForm();
    public lost: TStatusForm = newStatusForm();
}

export const keyFromListingStatus1 = (s: ListingStatus1.T): keyof C => {
    switch (s) {
        case "lead":
            return "lead";
        case "prep_valuation":
            return "prepValuation";
        case "chase_instruction":
            return "chaseInstruction";
        case "instructed_needs_market_prep":
            return "instructedNeedsPrep";
        case "instructed_on_the_market":
            return "instructedOnMarket";
        case "sstc":
            return "sstc";
        case "completed":
            return "completed";
        case "lost":
            return "lost";
        default:
            return util.requireExhaustive(s);
    }
};

export const updateStatus = (
    {...s}: C,
    status: ListingStatus1.T,
    endomorphism: fptsFunction.Endomorphism<C[keyof C]>
): C => {
    s =  pipe(
        s,
        record.modifyAt<TStatusForm>(
            keyFromListingStatus1(status),
            endomorphism,
        ),
        option.getOrElse(() => s),
    );
    return s;
};
