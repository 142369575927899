import { union } from "../../shared/src/codecs/types/union";
import { literal } from "../../shared/src/codecs/types/literal";
import { TTypeOfCodec } from "../../shared/src/codecs/codec";

export const ActionType1 = union([
    literal("requires_action"),
    literal("no_action_required"),
]);

export type TActionType1Codec = typeof ActionType1;
export type TActionType1 = TTypeOfCodec<typeof ActionType1>;
