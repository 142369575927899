import * as t from "io-ts";
import * as ListingForm5 from "./ListingForm5";
import * as ListingCondition1 from "./ListingCondition1";
import * as ListingReasonForSale1 from "./ListingReasonForSale1";
import * as ListingPropertyType1 from "./ListingPropertyType1";
import * as ListingValuationType from "./ListingValuationType";

const codec =
    t.type({
        ...ListingForm5.codec.props,
        condition: ListingCondition1.codec,
        reason_for_sale: ListingReasonForSale1.codec,
        admin_notes: t.string,
        property_type: ListingPropertyType1.codec,
        bedroom_count: t.union([t.number, t.null]),
        bathroom_count: t.union([t.number, t.null]),
        valuation_type: ListingValuationType.codec,
        valuation_date: t.union([t.string, t.null]),
        seller_latest_desired_sell_date: t.union([t.string, t.null]),
        seller_minimum_desired_sell_value: t.union([t.number, t.null]),
        seller_specfic_agent_needs: t.string,
        significant_renovations_made: t.string,
        competitor_agent_name: t.string,
        competitor_agent_instructed_at: t.union([t.string, t.null]),
        competitor_agent_market_price: t.union([t.number, t.null]),
        competitor_agent_listing_status: t.string,
    });

export type T = t.TypeOf<typeof codec>;

export const newDefault = (): T => ({
    ...ListingForm5.newDefault(),
    condition: "unknown",
    reason_for_sale: "unknown",
    admin_notes: "",
    property_type: "unknown",
    bedroom_count: null,
    bathroom_count: null,
    valuation_type: "undetermined",
    valuation_date: null,
    seller_latest_desired_sell_date: null,
    seller_minimum_desired_sell_value: null,
    seller_specfic_agent_needs: "",
    significant_renovations_made: "",
    competitor_agent_name: "",
    competitor_agent_instructed_at: null,
    competitor_agent_market_price: null,
    competitor_agent_listing_status: "",
});
