import { array } from "fp-ts";
import { pipe } from "fp-ts/lib/pipeable";
import { isFilled, requireExhaustive } from "../../../shared/src/util";
import { TCountry1, TCountryCopyText } from "../Country";
import { TUserLegalCorrespondenceAddress1 } from "../User";
import * as regexes from "./../../../shared/src/regexes";

export const isValidPostcode = (input: unknown): boolean =>
    typeof input === "string" &&
    (new RegExp(regexes.constants.POSTCODE_REGEX)).test(input)
;

export const Country1ToCopyText = (country: TCountry1): TCountryCopyText => {
    switch (country) {
        case "england":
            return "England";
        case "scotland":
            return "Scotland";
        case "wales":
            return "Wales";
        case "northern_ireland":
            return "Northern Ireland";
        case "unknown":
            return "Unknown";
        default:
            return requireExhaustive(country);
    }
};

export const UserLegalCorrespondenceAddressToCopyText = (address: TUserLegalCorrespondenceAddress1): string => {
    return pipe(
        [
            address.legal_correspondence_sub_building_name || address.legal_correspondence_sub_building_number,
            `${address.legal_correspondence_building_number || address.legal_correspondence_building_name} ${address.legal_correspondence_street_name}`,
            address.legal_correspondence_locality,
            address.legal_correspondence_city_town,
            address.legal_correspondence_postcode
        ],
        array.filter(isFilled),
        (list) => list.join(", ") 
    );
}