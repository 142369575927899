import { formStatus, TFormStatusCodec, TTypeOfCodec, TTypeOfNewDefault } from "../../../shared/src/codecs/codec";
import { required, TRequiredCodec } from "../../../shared/src/codecs/types/required";
import { LegalCaseFormIO, TLegalCaseFormIOCodec } from "../formIO/LegalCaseFormIO";
import { NewCaseMemberFormIO, TNewCaseMemberFormIOCodec } from "../formIO/NewCaseMemberFormIO";
import { NewUserNumberFormIO, TNewUserNumberFormIOCodec } from "../formIO/NewUserNumberFormIO";
import { RemoveCaseMemberFormIO, TRemoveCaseMemberFormIOCodec } from "../formIO/RemoveCaseMemberFormIO";
import { RemoveUserNumberFormIO, TRemoveUserNumberFormIOCodec } from "../formIO/RemoveUserNumberFormIO";

export const LegalCaseDetailsPagePartial: TRequiredCodec<{
    data: TLegalCaseFormIOCodec;
    autocomplete_address_status: TFormStatusCodec;
    create_member: TNewCaseMemberFormIOCodec;
    remove_member: TRemoveCaseMemberFormIOCodec;
    create_member_number: TNewUserNumberFormIOCodec;
    remove_member_number: TRemoveUserNumberFormIOCodec;
}> = required({
    data: LegalCaseFormIO,
    autocomplete_address_status: formStatus,
    create_member: NewCaseMemberFormIO,
    remove_member: RemoveCaseMemberFormIO,
    create_member_number: NewUserNumberFormIO,
    remove_member_number: RemoveUserNumberFormIO,
});

export type TLegalCaseDetailsPagePartialCodec = typeof LegalCaseDetailsPagePartial;
export type TLegalCaseDetailsPagePartial = TTypeOfCodec<TLegalCaseDetailsPagePartialCodec>;
export type TLegalCaseDetailsPagePartialNewDefault = TTypeOfNewDefault<TLegalCaseDetailsPagePartialCodec>;