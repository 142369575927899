import React from "react";
import { CRMCardOutside } from "../../components/CRMCardOutside/CRMCardOutside";
import { CRMColumns } from "../../components/CRMColumns/CRMColumns";
import { CRMPadding } from "../../components/Simple/CRMPadding/CRMPadding";
import { CRMPageBackground } from "../../components/Simple/CRMPageBackground/CRMPageBackground";
import { CRMSpacer } from "../../components/CRMSpacer/CRMSpacer";
import FrontParagraphLarge from "../../components/Front/Simple/FrontParagraphLarge/FrontParagraphLarge";
import { FrontSpacer } from "../../components/FrontSpacer/FrontSpacer";
import FrontParagraphRegular from "../../components/Front/Simple/FrontParagraphRegular/FrontParagraphRegular";
import FrontTitleSectionSubTitle from "../../components/Front/Simple/FrontTitleSectionSubTitle/FrontTitleSectionSubTitle";
import { WeightBold } from "../../components/WeightBold/WeightBold";
import { Uppercase } from "../../components/Uppercase/Uppercase";
import FrontButtonPrimary from "../../components/Front/Simple/FrontButtonPrimary/FrontButtonPrimary";
import FrontProbateEstateAdministrationQuoteContainer from "../../containers/FrontProbateEstateAdministrationQuoteContainer/FrontProbateEstateAdministrationQuoteContainer";
import FrontProbateGOPQuoteContainer from "../../containers/FrontProbateGOPQuoteContainer/FrontProbateGOPQuoteContainer";
import FrontTitleMinor from "../../components/Front/Simple/FrontTitleMinor/FrontTitleMinor";
import { makeAction } from "../../state/applyActions";
import * as ProbateInterestedInStateNoUnknown from "../../../../domain/models/ProbateInterestedInStateNoUnknown";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import TagManager from "react-gtm-module";

class ProbatePanelQuotePage extends React.Component<React.PropsWithChildren<TContainerStateProps>> {
    public componentDidMount() {
        TagManager.initialize({
            gtmId: "GTM-5J4P8R5",
        });
    }

    public render(): JSX.Element {
        return (
            <div className="probate-panel-enquiry-page">
                <CRMPageBackground>
                    <div style={{padding: "30px"}}>
                        <CRMCardOutside borderRounding="right" shadow={true}>
                            <CRMPadding size="large">
                                <img style={{display: "block", height: "100px"}} src="/static/img/SAIL_PROBATE_LOGO_2021.png" />
                                <FrontSpacer size="small-2"></FrontSpacer>
                                <FrontParagraphLarge>
                                    Call us: &nbsp;<a style={{textDecoration: "none", color: "#049DCC", fontWeight: "bold"}} href="tel:0117 457 8232">0117 457 8232</a>
                                </FrontParagraphLarge>
                                <FrontSpacer size="large-2"></FrontSpacer>
                                <FrontParagraphLarge>
                                    Thank you! Based on your information, here are you quotes for grant of probate or estate administration.
                                </FrontParagraphLarge>
                                <FrontSpacer size="medium-1"></FrontSpacer>
                                <FrontTitleMinor>What would work best for you?</FrontTitleMinor>
                                <FrontSpacer size="small-2"></FrontSpacer>
                                <FrontParagraphRegular>
                                    Once you’ve decided, we’ll recommend the best legal expert for you, tailored to your needs.
                                </FrontParagraphRegular>
                                <FrontSpacer size="small-2"></FrontSpacer>
                                <CRMColumns
                                    padding="large"
                                    columns={[
                                        {
                                            flex: 1,
                                            content: (
                                                    <CRMPadding size="large">
                                                        <FrontProbateGOPQuoteContainer
                                                            fee={this.props.state.probate.viewCaseForm.view.gop_fee.toString()}
                                                            caseForm={this.props.state.probate.viewCaseForm}
                                                        />
                                                        <CRMSpacer size="medium"></CRMSpacer>
                                                        <FrontButtonPrimary
                                                            label="SELECT GRANT OF PROBATE ONLY"
                                                            fullWidth={true}
                                                            onClick={()=>this.props.dispatch(
                                                                makeAction(
                                                                    "SELECT_INTEREST_IN_QUOTE_TYPE",
                                                                    "gop" as ProbateInterestedInStateNoUnknown.T,
                                                                )
                                                            )}
                                                        />
                                                    </CRMPadding>
                                            ),
                                        },
                                        {
                                            flex: 1,
                                            content: (
                                                    <CRMPadding size="large">
                                                        <FrontProbateEstateAdministrationQuoteContainer
                                                            fee={this.props.state.probate.viewCaseForm.view.estate_administration_fee.toString()}
                                                            caseForm={this.props.state.probate.viewCaseForm}
                                                        />
                                                        <CRMSpacer size="medium"></CRMSpacer>
                                                        <FrontButtonPrimary
                                                            label="SELECT ESTATE ADMINISTRATION"
                                                            fullWidth={true}
                                                            onClick={()=>this.props.dispatch(
                                                                makeAction(
                                                                    "SELECT_INTEREST_IN_QUOTE_TYPE",
                                                                    "estate_admin" as ProbateInterestedInStateNoUnknown.T,
                                                                )
                                                            )}
                                                        />
                                                    </CRMPadding>
                                            ),
                                        },
                                    ]}
                                />
                            </CRMPadding>
                        </CRMCardOutside>
                        <CRMSpacer size="medium" />
                        <CRMCardOutside borderRounding="right" shadow={true}>
                            <CRMPadding size="large">
                                <WeightBold>
                                    <Uppercase>
                                        <FrontTitleSectionSubTitle text="Correct information" />
                                    </Uppercase>
                                </WeightBold>
                                <FrontSpacer size="medium-1" />
                                <FrontParagraphRegular>
                                    This quotation is for indicative purposes only, based on the information you have supplied. If anything changes please tell us, in case it affects the fees, or other legal expenses. Your legal advisor will send you a separate definitive Terms and Conditions of Business upon instruction.
                                </FrontParagraphRegular>
                                <FrontSpacer size="medium-1" />
                                <FrontParagraphRegular>
                                    If the Estate Administration work is not as straightforward as first envisaged (for example: No Will, family tree needs to be established, estate is liable to Inheritance Tax, transferable nil rate band claim, residence nil rate band claim, further assets established, agricultural assets, business assets) and further work is required, your legal advisor will contact you and discuss this and the additional legal costs we have agreed they will charge for such work, when the need is identified.
                                </FrontParagraphRegular>
                                <FrontSpacer size="medium-1" />
                                <WeightBold>
                                    <Uppercase>
                                        <FrontTitleSectionSubTitle text="Disbursements/third party payments" />
                                    </Uppercase>
                                </WeightBold>
                                <FrontSpacer size="medium-1" />
                                <FrontParagraphRegular>
                                    Additional services may be identified as useful or needed as part of estate administration, your solicitor will advise you on these when appropriate. Some examples include (but are not limited to), house clearance, property valuation and financial asset searches, indemnities for lost shares, stock broker fees.
                                </FrontParagraphRegular>
                                <FrontSpacer size="medium-1" />
                                <WeightBold>
                                    <Uppercase>
                                        <FrontTitleSectionSubTitle text="Best legal advisor for your needs" />
                                    </Uppercase>
                                </WeightBold>
                                <FrontSpacer size="medium-1" />
                                <FrontParagraphRegular>
                                    We select the legal advisor best placed to act on your behalf at the time you ask us to instruct them. If your instructions change a different member of our panel may need to be selected.
                                    When you instruct your legal advisor in writing you will have the reassurance of becoming their client and knowing that they will take direct responsibility for the legal work they conduct for you. You will be able to contact your legal advisor direct during the transaction, but our specialist staff will be available to you and your legal advisor to help where we can during the process.
                                </FrontParagraphRegular>
                            </CRMPadding>
                        </CRMCardOutside>
                    </div>
                </CRMPageBackground>
            </div>
        );
    }
}

export default ProbatePanelQuotePage;
