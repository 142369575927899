import { either, option } from "fp-ts/lib";
import { pipe } from "fp-ts/lib/function";
import { TError } from "./errors";
import { TAnyRequiredCodec } from "./types/required";
import { TAnyStringCodec } from "./types/string";
import { TAnyIntersectionCodec } from "./types/intersection";
import { TAnyUnionCodec, union } from "./types/union";
import { TAnyArrayCodec } from "./types/array";
import { TAnyNonEmptyArrayCodec } from "./types/nonEmptyArray";
import { TAnyFixedLengthArrayCodec } from "./types/fixedLengthArray";
import { TAnyPartialCodec } from "./types/partial";
import { TAnyCustomCodec } from "./types/custom";
import { TAnyNonEmptyStringCodec } from "./types/nonEmptyString";
import { literal, TAnyLiteralCodec } from "./types/literal";
import { TAnyIntegerCodec } from "./types/integer";
import { TAnyDecimalCodec } from "./types/decimal";
import { TAnyBooleanCodec } from "./types/boolean";
import { TAnyUuidCodec } from "./types/uuid";
import { TAnyUniqueArrayCodec } from "./types/uniqueArray";
import { TAnyDateCodec } from "./types/date";
import { TAnyDateMonthCodec } from "./types/dateMonth";
import { TAnyDateTimeCodec } from "./types/dateTime";
import { TAnyFutureDateTimeCodec } from "./types/futureDateTime";
import { TAnyEmailCodec } from "./types/email";
import { TAnyPasswordCodec } from "./types/password";
import { TAnyPostcodeCodec } from "./types/postcode";
import { TAnyPhoneNumberCodec } from "./types/phoneNumber";
import { TAnyUnknownCodec } from "./types/unknown";
import { TAnyErrorCodeCodec } from "./types/errorCode";
import { TAnyTupleCodec } from "./types/tuple";
import { TAnyFormEditableCodec } from "./types/formEditable";
import { TAnyOverloadCodec } from "./types/overload";
import { TAnyFormIOCodec } from "./types/formIO";
import { TAnyRequiredFlatOverloadedCodec } from "./types/requiredFlatOverloaded";
import { TAnyNullCodec } from "./types/nullCodec";
import { NonEmptyArray } from "fp-ts/lib/NonEmptyArray";
import { TAnyPositiveIntegerCodec } from "./types/positiveInteger";
import { TAnyPositiveDecimalCodec } from "./types/positiveDecimal";
import { TAnyLongStringCodec } from "./types/longString";
import { TAnyDeferDateTimeCodec } from "./types/deferDateTime";
import { TAnyFileIOCodec } from "./types/fileIO";
import { TAnyDateOrNullAsBooleanCodec } from "./types/dateOrNullAsBoolean";
import { TAnyDateTimeOrNullAsBooleanCodec } from "./types/dateTimeOrNullAsBoolean";
import { TAnyFormCodec } from "./types/form";
import { TAnyCurrencyIntegerCodec } from "./types/currencyInteger";
import { TAnyStringCappedCodec } from "./types/stringCapped";
import { TAnyExactNumberCodec } from "./types/exactNumber";
import { TAnyRequiredFromJsonCodec } from "./types/requiredFromJson";
import { TAnyIntegerFromStringCodec } from "./types/integerFromString";
import { TAnyBooleanFromStringCodec } from "./types/booleanFromString";
import { TAnyTrueCodec } from "./types/trueCodec";
import { TAnyFalseCodec } from "./types/falseCodec";
import { TAnyTryCatchCodec } from "./types/tryCatch";
import { TAnyRegexCodec } from "./types/regex";
import { TAnyUndefinedCodec } from "./types/undefined";
import { TAnyMapCodec } from "./types/map";

export type TCodec<
    T extends string, // The ref for the type e.g. "RequiredCodec"
    P, // The payload argument for the type's constructor
       // e.g. for required codec this would be `P extends Record<string, TAnyCodec>`
       // because when you construct a required codec, you would do: `required({key: codec})
    D, // The derived type for the codec, often dervied from the payload type
       // e.g. for required codec this would be `{[K in keyof P]: TTypeOfCodec<P[K]>;}`
    N  // The return type of the new default function - usually the same as D, but not always e.g. for nonEmptyString or nonEmptyArray
> = {
    type: T;
    payload: P;
    decode: (input: unknown) => either.Either<TError, D>;
    decodeNewDefault: (input: unknown) => either.Either<TError, N>;
    newDefault: () => N;
};

export type TUnionCodecExtension<
    P extends NonEmptyArray<TAnyCodec>,
    D extends TTypeOfCodec<P[number]>,
    N extends TTypeOfNewDefault<P[number]>,
> = TCodec<"UnionCodec", P, D, N> & {
    values: NonEmptyArray<D>;
};

export type TAnyCodec =
    TAnyStringCodec
    | TAnyLongStringCodec
    | TAnyRequiredCodec
    | TAnyRequiredFromJsonCodec
    | TAnyPartialCodec
    | TAnyUnionCodec
    | TAnyArrayCodec
    | TAnyNonEmptyArrayCodec
    | TAnyFixedLengthArrayCodec
    | TAnyCustomCodec
    | TAnyNonEmptyStringCodec
    | TAnyLiteralCodec
    | TAnyIntegerCodec
    | TAnyCurrencyIntegerCodec
    | TAnyPositiveIntegerCodec
    | TAnyDecimalCodec
    | TAnyPositiveDecimalCodec
    | TAnyBooleanCodec
    | TAnyTrueCodec
    | TAnyFalseCodec
    | TAnyUuidCodec
    | TAnyUniqueArrayCodec
    | TAnyDateCodec
    | TAnyDateMonthCodec
    | TAnyDateTimeCodec
    | TAnyFutureDateTimeCodec
    | TAnyDeferDateTimeCodec
    | TAnyDateOrNullAsBooleanCodec
    | TAnyDateTimeOrNullAsBooleanCodec
    | TAnyEmailCodec
    | TAnyPasswordCodec
    | TAnyPostcodeCodec
    | TAnyPhoneNumberCodec
    | TAnyErrorCodeCodec
    | TAnyUnknownCodec
    | TAnyNullCodec
    | TAnyTupleCodec
    | TAnyOverloadCodec
    | TAnyRequiredFlatOverloadedCodec
    | TAnyFormEditableCodec
    | TAnyFormIOCodec
    | TAnyFormCodec
    | TAnyIntersectionCodec
    | TAnyFileIOCodec
    | TAnyStringCappedCodec
    | TAnyExactNumberCodec
    | TAnyIntegerFromStringCodec
    | TAnyBooleanFromStringCodec
    | TAnyTryCatchCodec
    | TAnyRegexCodec
    | TAnyUndefinedCodec
    | TAnyMapCodec;

export type TAnyRecordCodec = TAnyRequiredCodec | TAnyPartialCodec | TAnyRequiredFlatOverloadedCodec;

type TAnyOfStringCodecs = TAnyStringCodec | TAnyUuidCodec | TAnyNonEmptyStringCodec | TAnyLiteralCodec | TAnyDateCodec | TAnyDateMonthCodec
    | TAnyDateTimeCodec | TAnyFutureDateTimeCodec | TAnyEmailCodec | TAnyPasswordCodec | TAnyPostcodeCodec | TAnyPhoneNumberCodec | TAnyRegexCodec;

type TAnyRecordOfStringsExcludePartial = TCodec<"RequiredCodec" | "PartialCodec", {[K: string]: TAnyOfStringCodecs | TAnyUnionOfStrings}, Record<string, string>, Record<string, string>>;

export type TAnyRecordOfStrings = TAnyRecordOfStringsExcludePartial
    | TCodec<"IntersectionCodec", [TAnyRecordOfStringsExcludePartial, ...Array<TAnyRecordOfStringsExcludePartial>], Record<string, string>, Record<string, string>>
;

type TAnyUnionOfStrings = TCodec<
    "UnionCodec",
    NonEmptyArray<TAnyOfStringCodecs>,
    string,
    string
>;

export const isAnyRecordCodec = (codec: TAnyCodec): codec is TAnyRecordCodec =>
    codec.type === "RequiredCodec"
    || codec.type === "PartialCodec"
    || codec.type === "RequiredFlatOverloadedCodec";

export type TAnyListCodec = TAnyArrayCodec | TAnyNonEmptyArrayCodec | TAnyUniqueArrayCodec | TAnyFixedLengthArrayCodec;

export const isListOfStrings = (codec: TAnyListCodec): boolean =>
    codec.payload.codec.type === "StringCodec"
;

export type TTypeOfCodec<C extends TAnyCodec> = C extends TCodec<string, unknown, infer D, unknown> ? D : never;

export type TTypeOfNewDefault<C extends TAnyCodec> = C extends TCodec<string, unknown, unknown, infer N> ? N : never;

export type TValidatorFunction<P extends TAnyCodec> = (decoded: TTypeOfCodec<P>) => option.Option<TError>;
type TNewDefaultValidatorFunction<P extends TAnyCodec> = (decoded: TTypeOfNewDefault<P>) => option.Option<TError>;

export const is = <C extends TAnyCodec>(codec: C, input: unknown): input is TTypeOfCodec<C> =>
    either.isLeft(codec.decode(input))
    ? false
    : true;

export type TAnyInheritedCodec<T extends string, P extends { codec: TAnyCodec }> = TCodec<T, P, unknown, unknown>;
export type TInheritedCodec<T extends string, P extends { codec: TAnyCodec }> = TCodec<T, P, TTypeOfCodec<P["codec"]>, TTypeOfNewDefault<P["codec"]>>;

export const inherit = <T extends string, P extends { codec: TAnyCodec }>(type: T, payload: P, validator: TValidatorFunction<P["codec"]>, newDefaultValidator: TNewDefaultValidatorFunction<P["codec"]>): TInheritedCodec<T, P> => ({
    type,
    payload,
    decode: (input: unknown): either.Either<TError, TTypeOfCodec<P["codec"]>> =>
         pipe(
            input,
            payload.codec.decode,
            either.chain((decoded) =>
                 pipe(
                    validator(decoded as TTypeOfCodec<P["codec"]>),
                    option.fold(
                        () => either.right(decoded),
                        (e) => either.left(e),
                    )
                )
            )
        ) as either.Either<TError, TTypeOfCodec<P["codec"]>>,
    decodeNewDefault: (input: unknown): either.Either<TError, TTypeOfNewDefault<P["codec"]>> =>
         pipe(
            input,
            payload.codec.decodeNewDefault,
            either.chain((decoded) =>
                 pipe(
                    newDefaultValidator(decoded as TTypeOfNewDefault<P["codec"]>),
                    option.fold(
                        () => either.right(decoded),
                        (e) => either.left(e),
                    )
                )
            )
        ) as either.Either<TError, TTypeOfNewDefault<P["codec"]>>,
    newDefault: (): TTypeOfNewDefault<P["codec"]> => payload.codec.newDefault() as TTypeOfNewDefault<P["codec"]>,
});

export const formStatus = union([
    literal("untouched"),
    literal("success"),
    literal("loading"),
    literal("requiresSubmission"),
    literal("validationError"),
    literal("submitting"),
    literal("failure"),
    literal("unauthorised"),
    literal("notFound"),
    literal("twoFactorRequired"),
]);

export type TFormStatusCodec = typeof formStatus;

export type TFormStatus = TTypeOfCodec<TFormStatusCodec>;

export const FormStatus_highestPriority = (statuses: Array<TFormStatus>): TFormStatus =>
    statuses.includes("loading") ? "loading"
    : statuses.includes("submitting") ? "submitting"
    : statuses.includes("failure") ? "failure"
    : statuses.includes("unauthorised") ? "unauthorised"
    : statuses.includes("twoFactorRequired") ? "twoFactorRequired"
    : statuses.includes("notFound") ? "notFound"
    : statuses.includes("validationError") ? "validationError"
    : statuses.includes("requiresSubmission") ? "requiresSubmission"
    : statuses.includes("success") ? "success"
    : statuses.includes("untouched") ? "untouched"
    : statuses.length > 0 ? statuses[0]
    : "untouched";

// This is a clever little type that allows you to discriminate types based on if they are unions or not
export type IsUnion<T, U extends T = T> =
    // eslint-disable-next-line
    (T extends any ?
    (U extends T ? false : true)
        : never) extends false ? false : true;