import { overload } from "../../../shared/src/codecs/types/overload";
import { required } from "../../../shared/src/codecs/types/required";
import { string } from "../../../shared/src/codecs/types/string";
import { pipe } from "fp-ts/lib/function";

export const UserInitials = overload(
    string(),
    required({
        first_name: string(),
        last_name: string(),
    }),
    (p) => pipe(
        [
            ...(p.first_name ? [p.first_name.substring(0, 1).toUpperCase()] : []),
            ...(p.last_name ? [p.last_name.substring(0, 1).toUpperCase()] : []),
        ],
        (a) => a.length === 0 ? ""
            : a.join("."),
    )
);
export type TUserInitialsCodec = typeof UserInitials;
