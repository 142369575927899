import * as t from "io-ts";
import * as ViewingType from "./ViewingType";
import * as ViewingStatus1 from "./ViewingStatus1";
import { DateTime } from "luxon";
import { option } from "fp-ts";
import { all } from "../../shared/src/validation/compose/all";
import { object } from "../../shared/src/validation/create/object";
import { inOrder } from "../../shared/src/validation/compose/inOrder";
import { array } from "../../shared/src/validation/create/array";
import { arrayUniqueBy } from "../../shared/src/validation/create/arrayUniqueBy";
import { objectCallback } from "../../shared/src/validation/create/objectCallback";
import { or } from "../../shared/src/validation/compose/or";
import { stringIsoUtcDateTime } from "../../shared/src/validation/basic/stringIsoUtcDateTime";
import { string } from "../../shared/src/validation/basic/string";
import { nullable } from "../../shared/src/validation/basic/nullable";
import { required } from "../../shared/src/validation/basic/required";
import { TValidationError } from "../../shared/src/validation/Error";

export const codec = t.partial({
    status: ViewingStatus1.codec,
    viewing_type: ViewingType.codec,
    requested_times: t.array(t.string),
    confirmed_time: t.union([t.string, t.null]),
    agent: t.string,
    feedback: t.string,
    shared_with_seller_at: t.union([t.string, t.null]),
});

export type T = t.TypeOf<typeof codec>;

export const validator = all(
    object({
        status: ViewingStatus1.validator,
        viewing_type: ViewingType.validator,
        requested_times: inOrder(
            array(stringIsoUtcDateTime),
            arrayUniqueBy((x) => typeof x === "string" ? DateTime.fromISO(x).toISO() : ""),
        ),
        agent: string,
        feedback: string,
        shared_with_seller_at: or(
            stringIsoUtcDateTime,
            nullable,
        ),
    }),
    objectCallback((val: Record<string, unknown>): option.Option<TValidationError> =>
        typeof val.status === "string" && val.status === "confirmed"
            ? object({
                confirmed_time: all(
                    required,
                    stringIsoUtcDateTime,
                ),
            })(val)
            : object({
                confirmed_time: or(
                    stringIsoUtcDateTime,
                    nullable,
                ),
            })(val)
    ),
);

export const newDefault = (): T => ({
    status: "pending",
    viewing_type: "online",
    requested_times: [],
    confirmed_time: "",
    agent: "",
    feedback: "",
    shared_with_seller_at: null,
});
