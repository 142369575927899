import React from "react";

type TFrontFooterBase = {
    copyRightString: string;
    backgroundColor: string;
    hiddenLink?: string;
};

export class FrontFooterBase extends React.Component<React.PropsWithChildren<TFrontFooterBase>> {
    public render (): JSX.Element {
        return (
            <div className="front-footer-base__container" style={{backgroundColor: this.props.backgroundColor}}>
                <div className="front-footer-base__main">
                    {/* LEFT ALIGN */}
                    <div>
                        {this.props.copyRightString}
                        {this.props.hiddenLink && <a href={this.props.hiddenLink} aria-hidden="true" className="front-footer-base__hidden-link">hidden</a>}
                    </div>
                    {/* RIGHT ALIGN */}
                    <div>
                        {/* Intentionally left empty for now */}
                    </div>
                </div>
            </div>
        );
    }
}
