import { TTypeOfCodec } from "../../../shared/src/codecs/codec";
import { form, TFormCodec } from "../../../shared/src/codecs/types/form";
import { CaseId, TCaseIdCodec } from "../CaseId";
import { EmptyObject, TEmptyObjectCodec } from "../EmptyObject";

export const CaseNewPropertyForm: TFormCodec<TCaseIdCodec, TEmptyObjectCodec> = 
    form(
        CaseId,
        EmptyObject,
    );

export type TCaseNewPropertyFormCodec = typeof CaseNewPropertyForm;
export type TCaseNewPropertyForm = TTypeOfCodec<TCaseNewPropertyFormCodec>;