import { TCodec } from "../codec";
import { either } from "fp-ts/lib";
import { errorConstants, TError } from "../errors";
import * as regexes from "../../regexes";

export type TAnyEmailCodec = TCodec<
    "EmailCodec",
    undefined,
    string ,
    string
>;

const decode = (input: unknown): either.Either<TError, string> =>
    typeof input === "string"
    && (new RegExp(regexes.constants.EMAIL_REGEX)).test(input)
        ? either.right(input.toLowerCase().trim())
        : either.left([[errorConstants.REGEX_VALIDATION, ""]]);

export const email = (): TAnyEmailCodec => ({
    type: "EmailCodec",
    payload: undefined,
    decode,
    decodeNewDefault: (input) => input === "" ? either.right("") : decode(input),
    newDefault: () => "",
});