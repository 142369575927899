import { array as fptsArray } from "fp-ts";
import { TRequiredCodec, required } from "../../shared/src/codecs/types/required";
import { TTypeOfCodec, TTypeOfNewDefault } from "../../shared/src/codecs/codec";
import { TUuidCodec, uuid } from "../../shared/src/codecs/types/uuid";
import { TAnyStringCodec, string } from "../../shared/src/codecs/types/string";
import { TUnionCodec, union } from "../../shared/src/codecs/types/union";
import { TNullCodec, nullCodec } from "../../shared/src/codecs/types/nullCodec";
import { boolean } from "../../shared/src/codecs/types/boolean";
import { array } from "../../shared/src/codecs/types/array";
import { nonEmptyString } from "../../shared/src/codecs/types/nonEmptyString";
import { InternalScope } from "./InternalScope";
import { email } from "../../shared/src/codecs/types/email";
import { intersection } from "../../shared/src/codecs/types/intersection";
import { UserPhoneNumber1, UserPhoneNumber4 } from "./UserPhoneNumber";

import { overload } from "../../shared/src/codecs/types/overload";
import { pipe } from "fp-ts/lib/function";
import { dateTime } from "../../shared/src/codecs/types/dateTime";
import { dateTimeOrNullAsBoolean } from "../../shared/src/codecs/types/dateTimeOrNullAsBoolean";
import { deferDateTime } from "../../shared/src/codecs/types/deferDateTime";
import { requiredFlatOverloaded } from "../../shared/src/codecs/types/requiredFlatOverloaded";
import { Country4 } from "./Country";

import { JobTypeCodec } from "./Job";
import { date } from "../../shared/src/codecs/types/date";

import { ClientIdentityVerificationStatus } from "./overloads/ClientIdentityVerificationStatus";

import { UserCaseUserType } from "./UserCaseUserType";

import { phoneNumber } from "../../shared/src/codecs/types/phoneNumber";
import { literal } from "../../shared/src/codecs/types/literal";
import { UserName } from "./overloads/UserName";
import { UuidObject } from "./Id";
import { ConfidentialLegalEmailOverload } from "./overloads/Emails";

export const PlainUser = required({
    id: uuid(),
    first_name: string(),
    last_name: string(),
});
export type TPlainUserCodec = typeof PlainUser;
export type TPlainUser = TTypeOfCodec<typeof PlainUser>;

export const PlainUserEmpty: TRequiredCodec<{
    id: TUnionCodec<[TNullCodec, TUuidCodec]>,
    first_name: TUnionCodec<[TNullCodec, TAnyStringCodec]>,
    last_name: TUnionCodec<[TNullCodec, TAnyStringCodec]>,
}> = required({
    id: union([nullCodec(), uuid()]),
    first_name: union([nullCodec(), string()]),
    last_name: union([nullCodec(), string()]),
});
export type TPlainUserEmptyCodec = typeof PlainUserEmpty;
export type TPlainUserEmpty = TTypeOfCodec<typeof PlainUserEmpty>;

export const PlainUser_NameCopy = overload(
    string(),
    PlainUser,
    (user) => pipe(
        [user.first_name, user.last_name],
        fptsArray.map((name) => name.trim()),
        fptsArray.filter((name) => name !== ""),
        (nameArray) => nameArray.join(" "),
    )
);

export const StaffUserIncludingDeleted = required({
    id: uuid(),
    first_name: string(),
    last_name: string(),
    email: email(),
    deleted_at: union([dateTime(), nullCodec()])
});
export type TStaffUserIncludingDeletedCodec = typeof StaffUserIncludingDeleted;
export type TStaffUserIncludingDeleted = TTypeOfCodec<TStaffUserIncludingDeletedCodec>;

export const StaffUser = required({
    id: uuid(),
    first_name: string(),
    last_name: string(),
    email: email(),
});
export type TStaffUserCodec = typeof StaffUser;
export type TStaffUser = TTypeOfCodec<typeof StaffUser>;

export const StaffUser2 = required({
    id: uuid(),
    first_name: string(),
    last_name: string(),
    email: email(),
    crm_username: string(),
});
export type TStaffUser2Codec = typeof StaffUser2;
export type TStaffUser2 = TTypeOfCodec<typeof StaffUser2>;

export const CaseAuthoriserUser = intersection([
    StaffUser,
    required({
        legal_case_authoriser_company_position: string(),
    })
]);
export type TCaseAuthoriserUserCodec = typeof CaseAuthoriserUser;
export type TCaseAuthoriserUser = TTypeOfCodec<TCaseAuthoriserUserCodec>;

export const UserWithLegalNames = required({
    id: uuid(),
    homes_first_legal_name: string(),
    homes_middle_legal_name: string(),
    homes_last_legal_name: string(),
    email: union([email(), nullCodec()]),
});

export const UserWithValidLegalNames = required({
    id: uuid(),
    homes_first_legal_name: nonEmptyString(),
    homes_middle_legal_name: string(),
    homes_last_legal_name: nonEmptyString(),
    email: union([email(), nullCodec()]),
});
export type TUserWithValidLegalNames = TTypeOfCodec<typeof UserWithValidLegalNames>;


export const UserWithValidLegalNamesAndIdOrGopChecked = intersection([
    UserWithValidLegalNames,
    required({
        homes_id_or_gop_seen_and_confirmed_good: dateTime(),
    }),
]);

export const UserWithValidLegalNamesAndDeceasedBoolean = intersection([
    UserWithValidLegalNames,
    required({
        is_deceased: boolean(),
    }),
]);
export type TUserWithValidLegalNamesAndDeceasedBoolean = TTypeOfCodec<typeof UserWithValidLegalNamesAndDeceasedBoolean>;

export const User2 = required({
    id: uuid(),
});

export const User3 = required({
    user_id: uuid(),
});

export const UserLegalCorrespondenceAddress1 = required({
    legal_correspondence_building_number: string(),
    legal_correspondence_building_name: string(),
    legal_correspondence_sub_building_number: string(),
    legal_correspondence_sub_building_name: string(),
    legal_correspondence_street_name: string(),
    legal_correspondence_city_town: string(),
    legal_correspondence_postcode: string(),
    legal_correspondence_county: string(),
    legal_correspondence_locality: string(),
    legal_correspondence_district: string(),
});
export type TUserLegalCorrespondenceAddress1Codec = typeof UserLegalCorrespondenceAddress1;
export type TUserLegalCorrespondenceAddress1 = TTypeOfCodec<typeof UserLegalCorrespondenceAddress1>;

export const UserLegalCorrespondenceAddress2 = required({
    legal_correspondence_country: Country4,
});

export const UserLegalCorrespondenceAddress3 = intersection([
    UserLegalCorrespondenceAddress1,
    UserLegalCorrespondenceAddress2,
]);

export const User4 = intersection([
    required({
        id: uuid(),
        created_at: dateTime(),
        email: union([email(), nullCodec()]),
        first_name: string(),
        last_name: string(),
        is_legal_buyer: boolean(),
        is_primary_contact: boolean(),
        legal_first_legal_name: string(),
        legal_middle_legal_name: string(),
        legal_last_legal_name: string(),
        email_is_verified: boolean(),
        national_insurance_number: string(),
    }),
    UserLegalCorrespondenceAddress3,
]);
export type TUser4Codec = typeof User4;

export const UserWithGmailHistoryId = required({
    id: uuid(),
    gmail_latest_history_id: union([string(), nullCodec()]),
});

export type TUserWithGmailHistoryId = TTypeOfCodec<typeof UserWithGmailHistoryId>;

export const SessionUser = 
    intersection([
        required({
            id: uuid(),
            user_role: InternalScope,
            jobs: array(JobTypeCodec),
            can_sign_off_roadblocks: boolean(),
            first_name: string(),
            last_name: string(),
            email: string(),
            enabled_feature_toggles: array(string()),
            is_chaser_staff: boolean()
        }),
        requiredFlatOverloaded({
            confidential_email: ConfidentialLegalEmailOverload
        })
    ])
;

export type TSessionUser = TTypeOfCodec<typeof SessionUser>;

export type TSessionUserNewDefault = TTypeOfNewDefault<typeof SessionUser>;

export const User6 = intersection([
    UserWithLegalNames,
    required({
        phone_numbers: array(UserPhoneNumber1)
    }),
]);
export type TUser6 = TTypeOfCodec<typeof User6>;

export const User7 = intersection([
    UserWithLegalNames,
    required({
        phone_numbers: union([UserPhoneNumber1, nullCodec()])
    }),
]);
export type TUser7 = TTypeOfCodec<typeof User7>;

export const User8 = required({
    id: uuid(),
    first_name: string(),
    last_name: string(),
    is_legal_seller: boolean(),
});

export const AdminUser = required({
    id: uuid(),
    user_role: InternalScope,
    jobs: array(JobTypeCodec),
    first_name: string(),
    last_name: string(),
});
export type TAdminUserCodec = typeof AdminUser;
export type TAdminUser = TTypeOfCodec<typeof AdminUser>;

export const User11 = required({
    id: uuid(),
    first_name: string(),
    last_name: string(),
    email: union([email(), nullCodec()]),
    description_of_user: string(),
    homes_first_legal_name: string(),
    homes_middle_legal_name: string(),
    homes_last_legal_name: string(),
    is_legal_seller: boolean(),
    homes_id_or_gop_seen_and_confirmed_good: dateTimeOrNullAsBoolean(),
    homes_id_sign_off_deferred: union([nullCodec(), deferDateTime()]),
    is_deceased: boolean(),
});
export type TUser11Codec = typeof User11;

export const UserLegalNames = required({
    legal_first_legal_name: string(),
    legal_middle_legal_name: string(),
    legal_last_legal_name: string(),
});

export type TUserLegalNamesCodec = typeof UserLegalNames;
export type TUserLegalNames = TTypeOfCodec<typeof UserLegalNames>;

export const User12 = intersection([
    UserLegalNames,
    UserLegalCorrespondenceAddress3,
    required({
        id: uuid(),
        users__cases___other_side_id: uuid(),
    }),
]);
export type TUser12Codec = typeof User12;

export const User14 = intersection([
    required({
        id: uuid(),
        email: union([email(), nullCodec()]),
        phone_numbers: array(UserPhoneNumber4),
        email_is_verified: boolean(),
    }),
    requiredFlatOverloaded({
        full_name: UserName,
        identity_verification_status: ClientIdentityVerificationStatus,
    })
]);
export type TUser14Codec = typeof User14;
export type TUser14 = TTypeOfCodec<typeof User14>;

export const User15 = required({
    id: uuid(),
    first_name: string(),
    email: email(),
    user_type: UserCaseUserType,
});
export type TUser15 = TTypeOfCodec<typeof User15>;

export const User16 = intersection([
    required({
        users__cases_id: uuid(),
    }),
    requiredFlatOverloaded({
        full_name: overload(
            string(),
            required({
                first_name: string(),
                last_name: string(),
                legal_first_legal_name: string(),
                legal_middle_legal_name: string(),
                legal_last_legal_name: string(),
            }),
            (p) => pipe(
                [
                    ...(p.legal_first_legal_name || p.legal_middle_legal_name || p.legal_last_legal_name
                            ? [
                                ...(p.legal_first_legal_name ? [p.legal_first_legal_name] : []),
                                ...(p.legal_middle_legal_name ? [p.legal_middle_legal_name] : []),
                                ...(p.legal_last_legal_name ? [p.legal_last_legal_name] : []),
                            ]
                            : [
                                ...(p.first_name ? [p.first_name] : []),
                                ...(p.last_name ? [p.last_name] : []),
                            ]),
                ],
                (a) => a.join(" "),
            )
        )
    })
]);
export type TUser16Codec = typeof User16;
export type TUser16 = TTypeOfCodec<typeof User16>;

export const User17 = required({
    id: uuid(),
    legal_first_legal_name: string(),
    legal_middle_legal_name: string(),
    legal_last_legal_name: string(),
});

export const User18 = required({
    users__cases_id: uuid(),
    legal_first_legal_name: string(),
    legal_middle_legal_name: string(),
    legal_last_legal_name: string(),
    legal_correspondence_building_name: string(),
    legal_correspondence_building_number: string(),
    legal_correspondence_sub_building_name: string(),
    legal_correspondence_sub_building_number: string(),
    legal_correspondence_street_name: string(),
    legal_correspondence_city_town: string(),
    legal_correspondence_locality: string(),
    legal_correspondence_district: string(),
    legal_correspondence_county: string(),
    legal_correspondence_country: Country4,
    legal_correspondence_postcode: string(),
    legal_date_of_birth: union([date(), nullCodec()]),
});
export type TUser18Codec = typeof User18;
export type TUser18 = TTypeOfCodec<TUser18Codec>;

export const User19 = required({
    id: uuid(),
    legal_first_legal_name: string(),
    legal_middle_legal_name: string(),
    legal_last_legal_name: string(),
    first_name: string(),
    email: union([email(), nullCodec()]),
    legal_date_of_birth: union([date(), nullCodec()]),
});
export type TUser19 = TTypeOfCodec<typeof User19>;

export const User20 = required({
    first_name: string(),
    last_name: string(),
    email: union([email(), nullCodec()]),
});
export type TUser20Codec = typeof User20;
export type TUser20 = TTypeOfCodec<typeof User20>;

export const User21 = required({
    first_name: string(),
    last_name: string(),
});
export type TUser21Codec = typeof User21;
export type TUser21 = TTypeOfCodec<typeof User21>;

export const ClientCaseUser = required({
    first_name: string(),
    last_name: string(),
    email: union([email(), nullCodec()]),
    phone_number: union([phoneNumber(), nullCodec()]),
    is_primary_contact: boolean(),
    is_company_director: boolean(),
    national_insurance_number: string(),
    ever_made_bankrupt: union([
        literal("unknown"),
        literal("yes"),
        literal("no"),
    ]),
    user_type: UserCaseUserType,
});
export type TClientCaseUserCodec = typeof ClientCaseUser;
export type TClientCaseUser = TTypeOfCodec<TClientCaseUserCodec>;

export const UserIdAndFullName = intersection([
    UuidObject,
    requiredFlatOverloaded({
        full_name: UserName,
    })
]);
export type TUserIdAndFullNameCodec = typeof UserIdAndFullName;
export type TUserIdAndFullName = TTypeOfCodec<TUserIdAndFullNameCodec>;

export const UserReceivingQuote = UserIdAndFullName;
export type TUserReceivingQuoteCodec = typeof UserIdAndFullName;

export const UserBookableForVideoCall = UserIdAndFullName;
export type TUserBookableForVideoCallCodec = typeof UserIdAndFullName;

export const UserAssignableToAdhoc = UserIdAndFullName;
export type TUserAssignableToAdhocCodec = typeof UserIdAndFullName;
export type TUserAssignableToAdhoc = TTypeOfCodec<TUserAssignableToAdhocCodec>;

export const UserBankruptcySearch = UserIdAndFullName;
export type TUserBankruptcySearchCodec = typeof UserIdAndFullName;
export type TUserBankruptcySearch = TTypeOfCodec<TUserIdAndFullNameCodec>;

export const CaseUserType = required({
    user_type: UserCaseUserType
});
export type TCaseUserTypeCodec = typeof CaseUserType;
export type TCaseUserType = TTypeOfCodec<TCaseUserTypeCodec>;

export const UserForMelissaRequest = required({
    id: uuid(),
    email: union([nullCodec(), email()]),
    first_name: string(),
    legal_first_legal_name: string(),
    legal_middle_legal_name: string(),
    legal_last_legal_name: string(),
    legal_correspondence_building_name: string(),
    legal_correspondence_building_number: string(),
    legal_correspondence_sub_building_name: string(),
    legal_correspondence_sub_building_number: string(),
    legal_correspondence_street_name: string(),
    legal_correspondence_postcode: string(),
    legal_correspondence_city_town: string(),
    legal_correspondence_country: Country4,
});
export type TUserForMelissaRequest = TTypeOfCodec<typeof UserForMelissaRequest>;

export const CreateUser = required({
    first_name: string(),
    last_name: string(),
    email: union([nullCodec(), email()]),
    phone_number: union([nullCodec(), phoneNumber()]),
});
export type TCreateUserCodec = typeof CreateUser;
export type TCreateUser = TTypeOfCodec<TCreateUserCodec>;
