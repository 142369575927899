import React from "react";
import { TFrontPadding } from "../../models/TFrontPadding";

type TFrontSpacer = {
    size: TFrontPadding;
};

export class FrontSpacer extends React.Component<React.PropsWithChildren<TFrontSpacer>> {
    public render (): JSX.Element {
        return (
            <div className={`front-spacer front-spacer--${this.props.size}`}></div>
        );
    }
}
