import { TTypeOfCodec } from "../../../shared/src/codecs/codec";
import { formIO, TFormIOCodec } from "../../../shared/src/codecs/types/formIO";
import { required } from "../../../shared/src/codecs/types/required";
import { uuid } from "../../../shared/src/codecs/types/uuid";

const UserAndCase = required({
    user_id: uuid(),
    case_id: uuid(),
});

type TUserAndCaseCodec = typeof UserAndCase;

export const RemoveCaseMemberFormIO: TFormIOCodec<TUserAndCaseCodec, TUserAndCaseCodec> = formIO(
    UserAndCase,
    UserAndCase,
);

export type TRemoveCaseMemberFormIOCodec = typeof RemoveCaseMemberFormIO;

export type TRemoveCaseMemberFormIO = TTypeOfCodec<TRemoveCaseMemberFormIOCodec>;
