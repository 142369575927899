import { dateTime } from "../../../shared/src/codecs/types/dateTime";
import { literal } from "../../../shared/src/codecs/types/literal";
import { nullCodec } from "../../../shared/src/codecs/types/nullCodec";
import { overload } from "../../../shared/src/codecs/types/overload";
import { required } from "../../../shared/src/codecs/types/required";
import { union } from "../../../shared/src/codecs/types/union";

export const CaseLedgerPaidStatus = overload(
    union([
        literal("paid"),
        literal("not_paid"),
        literal("user_says_paid"),
    ]),
    required({
        paid_at: union([dateTime(), nullCodec()]),
        user_says_paid: union([dateTime(), nullCodec()]),
    }),
    ({paid_at, user_says_paid}) =>
        paid_at ? "paid"
        : user_says_paid ? "user_says_paid"
        : "not_paid",
);
