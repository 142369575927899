import { TTypeOfCodec } from "../../../shared/src/codecs/codec";
import { array, TArrayCodec } from "../../../shared/src/codecs/types/array";
import { form, TFormCodec } from "../../../shared/src/codecs/types/form";
import { required, TRequiredCodec } from "../../../shared/src/codecs/types/required";
import { uuid } from "../../../shared/src/codecs/types/uuid";
import { EmptyObject, TEmptyObjectCodec } from "../EmptyObject";
import { ListingEnquiry3, TListingEnquiry3Codec, ListingEnquiry4, TListingEnquiry4Codec } from "../ListingEnquiry";
import { ListingEnquiryAdHocTask1, ListingEnquiryAdHocTask2, TListingEnquiryAdHocTask1Codec, TListingEnquiryAdHocTask2Codec } from "../ListingEnquiryAdHocTask";
import { Offer5, Offer5Children, TOffer5ChildrenCodec, TOffer5Codec } from "../Offer";
 
export const ListingEnquiryInnerForm: TFormCodec<TListingEnquiry3Codec, TListingEnquiry4Codec> = form(
    ListingEnquiry3,
    ListingEnquiry4,
);
export type TListingEnquiryInnerFormCodec = typeof ListingEnquiryInnerForm;
export type TListingEnquiryInnerForm = TTypeOfCodec<TListingEnquiryInnerFormCodec>;

export const ListingEnquiryAdHocTasksForm: TFormCodec<TListingEnquiryAdHocTask1Codec, TEmptyObjectCodec> = form(ListingEnquiryAdHocTask1, EmptyObject);
export type TListingEnquiryAdHocTasksFormCodec = typeof ListingEnquiryAdHocTasksForm;
export type TListingEnquiryAdHocTasksForm = TTypeOfCodec<typeof ListingEnquiryAdHocTasksForm>;

export const ListingEnquiryNewAdHocTaskForm: TFormCodec<TListingEnquiryAdHocTask2Codec, TEmptyObjectCodec> = form(ListingEnquiryAdHocTask2, EmptyObject);
export type TListingEnquiryNewAdHocTaskFormCodec = typeof ListingEnquiryNewAdHocTaskForm;
export type TListingEnquiryNewAdHocTaskForm = TTypeOfCodec<TListingEnquiryNewAdHocTaskFormCodec>;

export const ListingEnquiryOfferForm: TFormCodec<TOffer5Codec, TOffer5ChildrenCodec> = form(Offer5, Offer5Children);
export type TListingEnquiryOfferFormCodec = typeof ListingEnquiryOfferForm;
export type TListingEnquiryOfferForm = TTypeOfCodec<TListingEnquiryOfferFormCodec>;

const ListingEnquiryFormMain = required({
    listing_enquiry_id: uuid(),
});

type TListingEnquiryFormMainCodec = typeof ListingEnquiryFormMain;

const ListingEnquiryFormMainChildren: TRequiredCodec<{
    enquiry: TListingEnquiryInnerFormCodec;
    adhoc_tasks: TArrayCodec<TListingEnquiryAdHocTasksFormCodec>;
    new_adhoc_task: TListingEnquiryNewAdHocTaskFormCodec;
    enquiry_offers: TArrayCodec<TListingEnquiryOfferFormCodec>;
}> = required({
    enquiry: ListingEnquiryInnerForm,
    adhoc_tasks: array(ListingEnquiryAdHocTasksForm),
    new_adhoc_task: ListingEnquiryNewAdHocTaskForm,
    enquiry_offers: array(ListingEnquiryOfferForm),
});

type TListingEnquiryFormMainChildrenCodec = typeof ListingEnquiryFormMainChildren;

export const ListingEnquiryForm: TFormCodec<TListingEnquiryFormMainCodec, TListingEnquiryFormMainChildrenCodec> = form(
    ListingEnquiryFormMain,
    ListingEnquiryFormMainChildren,
);

export type TListingEnquiryFormCodec = typeof ListingEnquiryForm;
export type TListingEnquiryForm = TTypeOfCodec<TListingEnquiryFormCodec>;