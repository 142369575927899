import { TTypeOfCodec } from "../../shared/src/codecs/codec";
import { nullCodec } from "../../shared/src/codecs/types/nullCodec";
import { required } from "../../shared/src/codecs/types/required";
import { union } from "../../shared/src/codecs/types/union";
import { uuid } from "../../shared/src/codecs/types/uuid";
import { TransactionType5 } from "./TransactionType";

export const CaseAutoAssignment = required({
    introducer_id: union([nullCodec(), uuid()]),
    transaction_type: TransactionType5,
    case_handler_id: union([nullCodec(), uuid()]),
    case_assistant_id: union([nullCodec(), uuid()]),
});
export type TCaseAutoAssignmentCodec = typeof CaseAutoAssignment;
export type TCaseAutoAssignment = TTypeOfCodec<TCaseAutoAssignmentCodec>;
