import * as Function from "../Function";
import * as Error from "../Error";
import * as ErrorCode from "../ErrorCode";
import { rangeConstants } from "../constants/range";
import { option } from "fp-ts/lib";

export const range = (value: number, symbol: rangeConstants): Function.TValidationFunction => (input: unknown): option.Option<Error.TValidationError> => {
    if (typeof input === "undefined") {
        return option.none;
    }

    if (typeof input !== "number") {
        return option.some([[ErrorCode.validationErrorCodeConstants.RANGE_VALIDATION]]);
    }

    return symbol === rangeConstants.GREATER_THAN && input > value ? option.none
    : symbol === rangeConstants.GREATER_THAN_OR_EQUAL && input >= value ? option.none
    : symbol === rangeConstants.LESS_THAN && input < value ? option.none
    : symbol === rangeConstants.LESS_THAN_OR_EQUAL && input <= value ? option.none
    : option.some([[ErrorCode.validationErrorCodeConstants.RANGE_VALIDATION]]);
};
