import { TCodec } from "../codec";
import { either } from "fp-ts/lib";
import { TError, errorConstants } from "../errors";

export type TAnyBooleanCodec = TCodec<
    "BooleanCodec",
    undefined,
    boolean,
    boolean
>;

export type TBooleanCodec = TCodec<
    "BooleanCodec",
    undefined,
    boolean,
    boolean
>;

const decode = (input: unknown): either.Either<TError, boolean> =>
    typeof input === "boolean"
        ? either.right(input)
        : either.left([[errorConstants.BOOLEAN_VALIDATION, ""]]);

export const boolean = (): TAnyBooleanCodec => ({
    type: "BooleanCodec",
    payload: undefined,
    decode,
    decodeNewDefault: decode,
    newDefault: () => false,
});
