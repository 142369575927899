import { Errors, Any } from "io-ts";
import { taskEither } from "fp-ts/lib";
import * as Error from "../errors/Error";
import * as ErrorCode from "../errors/ErrorCode";
import { PathReporter } from 'io-ts/lib/PathReporter';

export const decode = <T>(codec: Any, input: unknown): taskEither.TaskEither<Error.T, T> =>
    {
        const result = codec.decode(input);
        return taskEither.mapLeft<Errors, Error.T>(() => {
            // eslint-disable-next-line no-console
            console.debug(`Codec Decode:\n${JSON.stringify(input)}\n`);
            console.error(PathReporter.report(result));
            return Error.create(ErrorCode.constants.CODEC_DECODE);
        })(taskEither.fromEither(result));
    };
