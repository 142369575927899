import { TTypeOfCodec } from "../../../shared/src/codecs/codec";
import { array, TArrayCodec } from "../../../shared/src/codecs/types/array";
import { form, TFormCodec } from "../../../shared/src/codecs/types/form";
import { required, TRequiredCodec } from "../../../shared/src/codecs/types/required";
import { string, TAnyStringCodec } from "../../../shared/src/codecs/types/string";
import { EmptyObject, TEmptyObjectCodec } from "../EmptyObject";

export const SessionFeatureToggleForm: TFormCodec<
    TRequiredCodec<{
        enabled_feature_toggles: TArrayCodec<TAnyStringCodec>
    }>,
    TEmptyObjectCodec
> = form(
    required({
        enabled_feature_toggles: array(string()),
    }),
    EmptyObject,
);
export type TSessionFeatureToggleFormCodec = typeof SessionFeatureToggleForm;
export type TSessionFeatureToggleForm = TTypeOfCodec<TSessionFeatureToggleFormCodec>;
