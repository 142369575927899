import { DateTime } from "luxon";
import * as t from "io-ts";
import * as CaseDocumentType1 from "./CaseDocumentType1";

const codec = t.type({
    type: t.union([
        t.literal(""),
        CaseDocumentType1.codec,
    ]),
    due_date: t.string,
    additional_name: t.string,
    from_external_source: t.boolean,
});

export type T = t.TypeOf<typeof codec>;

export const newDefault = (): T => ({
    type: "",
    due_date: DateTime.local().toISODate(),
    additional_name: "",
    from_external_source: false,
});
