import { TTypeOfCodec } from "../../shared/src/codecs/codec";
import { TUnionCodec, union } from "../../shared/src/codecs/types/union";
import { literal, TLiteralCodec } from "../../shared/src/codecs/types/literal";
import { overload } from "../../shared/src/codecs/types/overload";
import * as util from "../../shared/src/util";
import { required } from "../../shared/src/codecs/types/required";
import { TransactionType2, TTransactionType2 } from "../../domain/models/CaseTransactionType";
import { NonEmptyArray } from "fp-ts/lib/NonEmptyArray";

export const TransactionType5 = union([
    literal("purchase"),
    literal("sale"),
    literal("transfer"),
    literal("remortgage")
]);
export type TTransactionType5 = TTypeOfCodec<typeof TransactionType5>;

export const TransactionType1 = union([
    ...TransactionType5.payload,
    literal("unknown"),
]);
export type TTransactionType1Codec = typeof TransactionType1;
export type TTransactionType1 = TTypeOfCodec<TTransactionType1Codec>;


const TransactionType2Codec: TUnionCodec<NonEmptyArray<TLiteralCodec<TTransactionType2>>> = union(
    TransactionType2.map((type) => literal(type)) as NonEmptyArray<TLiteralCodec<TTransactionType2>>
);

export const TransactionType3 = overload(
    TransactionType2Codec,
    required({
        transaction_type: TransactionType1,
    }),
    ({transaction_type}) =>
        transaction_type === "purchase" ? "Purchase"
        : transaction_type === "sale" ? "Sale"
        : transaction_type === "transfer" ? "Transfer"
        : transaction_type === "remortgage" ? "Remortgage"
        : transaction_type === "unknown" ? "Transaction type not set"
        : util.requireExhaustive(transaction_type),
);

const TransactionType4 = union([
    literal("Purchase"),
    literal("Sale"),
    literal("Transfer"),
    literal("Remortgage"),
    literal("Unknown"),
]);
type TTransactionType4 = TTypeOfCodec<typeof TransactionType4>;

export const TransactionType1_TransactionType4 = (p: TTransactionType1): TTransactionType4 =>
    p === "purchase" ? "Purchase"
    : p === "sale" ? "Sale"
    : p === "transfer" ? "Transfer"
    : p === "remortgage" ? "Remortgage"
    : p === "unknown" ? "Unknown"
    : util.requireExhaustive(p);

export const getTransactingString = (p: TTransactionType1): string =>
    p === "purchase" ? "buying"
    : p === "sale" ? "selling"
    : p === "remortgage" ? "remortgaging"
    : p === "transfer" ? "transferring"
    : p === "unknown" ? "proceeding with transaction"
    : util.requireExhaustive(p);