import * as t from "io-ts";
import * as util from "../../shared/src/util";

export const codec = t.union([
    t.literal("freehold"),
    t.literal("leasehold"),
    t.literal("commonhold"),
    t.literal("unknown")
]);

export const values = util.typeValuesFromCodec(codec.types as Array<t.LiteralC<T>>);

type T = t.TypeOf<typeof codec>;

export const toDisplayName = (v: T): string => {
    switch (v) {
        case "freehold":
            return "Freehold";
        case "leasehold":
            return "Leasehold";
        case "commonhold":
            return "Commonhold";
        case "unknown":
            return "Unknown";
        default:
            return util.requireExhaustive(v);
    }
};
