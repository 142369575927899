import React from "react";
import { TPercentageSize, TPixelSize } from "../../models/StringLiterals";

type TCursorPointerProps = {
    title?: string;
    divRef?: React.RefObject<HTMLDivElement>;
    height?: TPixelSize | TPercentageSize;
    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

export const CursorPointer = (props: React.PropsWithChildren<TCursorPointerProps>): JSX.Element => {

    return (
        <div
            ref={props.divRef}
            title={props.title}
            style={{ cursor: "pointer", height: props.height }}
            // this helps avoid potential bugs with 3rd party addons (such as draft js) 
            onMouseDown={props.onClick}
        >
            {props.children}
        </div>
    );
};
