import React from "react";

export default (props: React.SVGAttributes<SVGElement>) => <svg viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
<rect x="0.616089" y="0.805542" width="22.7224" height="21.3889" fill="url(#pattern0)"/>
<defs>
<pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
<use xlinkHref="#image0" transform="translate(0 -0.0311729) scale(0.0078125 0.00829958)"/>
</pattern>
<image id="image0" width="128" height="128" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACACAYAAADDPmHLAAAACXBIWXMAAAsTAAALEwEAmpwYAAAFDklEQVR4nO3dO68VVRjG8f9rAAUvBMUQKITQGEOlEmNCtDHYqaWVhY2xNfoZNLGnsFS/gXQSEisbL4kmBgvwxEgoDPd4QQ7wWJzBkO1am32btdbMfn7lzGHNu/Y8rNn7nX3mgJmZmZmZmZmZmZmZmZmZmdlASDom6YSks5I2Nb/N7t+ekHSs9nxsRpL2Szq5wAm/n5OS9teen00h6Yik8z2c/LvOSzpSe56WIGmvpI0eT/5dG5L21p7vqjxQu4AV+gg4VOA4h4APCxyniKhdwCpIOgScIx3oS8Ap4Nqcw+4GjgNPJPbdAQ5HxK9zjml9kPRBZrk+LWn3EuPu7sZIeX+Vc7AlKP2u/5akAysY+0A31qSTq6i9trG8B9iX2HYmIi4sO3A3xpkZjzk4YwlAah7/rHD81FijeO1GMQlb3LYSB5F0tOdD7EptW+Fx+x4/KSK+7XN8KPQxUJJKHGdsIqL38+NLwJpzANacA7DmHIA1V+RTAPBdZvszpN9h/wz82V85VTwCPJ3Y/hfpRtP4STqV6bO/Vru2VZP0emauX9asq/Yl4GJm+2jut98jN6fca1CEA1COA5DgADgASQ5AIQ5AObk5XSpaxYTaAchNfp0C4BUgIfU9vKHLzckBSPAKUEjVbwVL2slWJ+x/u4DtEXG7cEm9kLQNuEn69d4ZETcKl/SfqitARPxNuuUbwJ7C5fRpD+mT/0fNkw/1LwGwHpeBJpd/KHczaJqLwMHE9pkDkPvGUe4bNX3/fEKzAfAKUIYDMMU6B6BqEwjaCMA6NIO8AkyxDs2gJptA0HYAvAIU4ACU4QBM4QBU5ACU4QBM4QBUVP0RMZJ2kP9V7h0RsTnDGM12AiVtZ+tGUMpM8+tT9RUgIm4C1zO7Hy9ZS09yHwGv1z750EAAOmNuBjW7/EM7ARhzM6jZJhC0HwCvAD1zAPrnAMzAAajEAeifAzADB6ASB6B/DsAM1jEA1b8NBO0EwI2gSloJgBtBlbQSgMuZ7Y91N4sGSdKDwKOZ3bk5F9VEALqbIlczu4e8CuRqvxIRt4pWktFEADpjfCPY9PUfHIC+OQBzcAAqcAD65QDMYZ0C0EQTCNoKwBibQV4B5jDGZlDTTSAYRgC8AvTIAeiXAzAHB6ACB6BfDsAcrrD1eLhJD3ePkxsUSbtI/zGMO+TvexTXTAC6ZwLm7pAN8ZNArubLLT3/sJkAdMZ0GWi+CQTtBWBMzaDmr//QXgDG1AxqvgkEwwmAV4CeOAD9cQAW4AAU5gD0xwFYgANQmAPQHwdgAesQgKYaQS38vYB7LdQImuO5/UV+XlLgFWAhV9m6WTLpoe7mylDsAh5MbL8NXCtcy1RNBSAi7jCOdnB2+e/m2IymAtAZw/uAQSz/4AD0xQFYggNQkAPQDwdgCQ5AQUMKwL6iVSxnEE0gaDMAv2S2v9Q1WJrW1fhcZvdGyVoGSdJB5b1Xu777kfT2lPoP165vUpP/oyR9AxzN7P4U+JzGOmpsPQvoTeAd0q/rD8CzEZH84xO1tBqAt4DPatexYu9GxCe1i5jUagAC+Ap4uXIpq/I98EJLvw9wV5MBAJB0APia9F8WH5LfgWMRcbZ2ISktfgoAICIuAK8AP9WuZQkbwPFWTz40HACAiDgHvAh8DNyoXM48NoETwPMR8WPtYqZp9hIwSdKTwBvAq8BTtPdlllvAb8Bp4ItuBTMzMzMzMzMzMzMzMzMzMzMr7l9iZoqFZoeAAwAAAABJRU5ErkJggg=="/>
</defs>
</svg>
;
