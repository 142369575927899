// IMPORTANT - Remeber that when bringing in external strings as part of regexes you need to escape the special characters

export const constants = {
    EMAIL_REGEX: "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$",
    SAIL_LEGAL_EMAIL: "@saillegal",
    SAIL_LEGAL_CONFIDENTIAL_EMAIL: "@confidential.saillegal",
    PASSWORD_REGEX: "^.{16,}$",
    MOBILE_PHONE_REGEX: /^(\+[1-9]|0)[\d]+$/,
    POSTCODE_REGEX: "^[A-Za-z]{1,2}[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}$",
    UUID_REGEX: /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i,
    AUTHORIZATION_HEADER_BEARER: /^Bearer [A-Za-z0-9-_=]+\.[A-Za-z0-9-_=]+\.?[A-Za-z0-9-_.+=]*$/,
    VALID_AUTHORIZATION_HEADER: /^(Session)$|^Bearer [A-Za-z0-9-_=]+\.[A-Za-z0-9-_=]+\.?[A-Za-z0-9-_.+=]*$/,
    COOKIE_HEADER_KEY_VALUE: /\s*([^=]*)=([^;]*)(?:$|;)/g,
    CONTENT_TYPE_JSON: "application/json",
    CONTENT_TYPE_URL_ENCODED: "application/x-www-form-urlencoded",
    CONTENT_TYPE_FORM_DATA: "^multipart/form-data",
    CONTENT_TYPE_APPLICATION_OCTET_STREAM: "application/octet-stream",
    LETTERS: /[a-zA-Z]/,
    HTML: /<(?:.|\n)*?>/gm,
    LOWER_NUM_AND_UNDERSCORE: /^[a-z0-9_]+$/
};

// Alias to make imports easier
export const regexes = constants;