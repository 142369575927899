import React from "react";
import { CRMFontSizes } from "../../../models/CRMFontSizes";
import { FontQuicksand } from "../../BuildingBlocks/FontQuicksand";
import { FontSize } from "../../BuildingBlocks/FontSize";
import { Flex } from "../../BuildingBlocks/Flex";
import { TJustificationOptions } from "../../BuildingBlocks/SpacingColumn";
import { Text } from "../../BuildingBlocks/Text";

interface ICRMParagraphProps {
    fontSize?: number;
    lineHeight?: number;
    justifyContent?: TJustificationOptions;
}

export const CRMParagraph = (props: React.PropsWithChildren<ICRMParagraphProps>): JSX.Element =>
    (
        <Flex justifyContent={props.justifyContent}>
            <FontQuicksand>
                <Text
                    size={props.fontSize || CRMFontSizes.SMALL}
                    lineHeight={props.lineHeight || 1.4}
                    weight={500}
                >
                    {props.children}
                </Text>
            </FontQuicksand>
        </Flex>
    );
