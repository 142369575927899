import React from "react";

export class CRMPageBackground extends React.Component<React.PropsWithChildren<{}>> {
    public render (): JSX.Element {
        return (
            <div className="crm-page-background">
                <div className="crm-page-background__col">
                    {this.props.children}
                </div>
            </div>
        );
    }
}
