import { form, TFormCodec } from "../../../shared/src/codecs/types/form";
import { TTypeOfCodec } from "../../../shared/src/codecs/codec";
import { TAnyUuidCodec, TUuidCodec, uuid } from "../../../shared/src/codecs/types/uuid";
import { string, TAnyStringCodec } from "../../../shared/src/codecs/types/string";
import { required, TRequiredCodec } from "../../../shared/src/codecs/types/required";
import { array, TArrayCodec } from "../../../shared/src/codecs/types/array";
import { CaseUserType, ClientCaseUser, StaffUser, TCaseUserTypeCodec, TClientCaseUserCodec, TStaffUserCodec, TUser14Codec, TUser16Codec, TUser18Codec, TUserBookableForVideoCallCodec, User14, User16, User18, UserBookableForVideoCall, UserReceivingQuote } from "../User";
import { CasesBankAccount1, TCasesBankAccountCodec } from "../CasesBankAccount";
import { CasesIsTransactingAsCompanyStatus, CasesPurchaseMortgageLenderNameKnown, CasesRelatedTransactionQuestion, CasesSdlt, CaseStatus, ClientCase, TCasesIsTransactingAsCompanyStatusCodec, TCasesRelatedTransactionQuestion, TCasesSdltCodec, TCaseStatusCodec, TClientCaseCodec } from "../Cases";
import { CaseId, TCaseIdCodec } from "../CaseId";
import { EmptyObject, TEmptyObjectCodec } from "../EmptyObject";
import { intersection, TIntersectionCodec } from "../../../shared/src/codecs/types/intersection";
import { partial, TPartialCodec } from "../../../shared/src/codecs/types/partial";
import { CaseLedgerAmountAndPaidStatus, TCaseLedgerAmountAndPaidStatusCodec } from "../CaseLedger";
import { boolean, TBooleanCodec } from "../../../shared/src/codecs/types/boolean";
import { CasesRelatedTransaction3, TCasesRelatedTransaction3Codec } from "../CasesRelatedTransaction";
import { Address2, TAddress2Codec, Address8, TAddress8Codec } from "../Address";
import { requiredFlatOverloaded, TRequiredFlatOverloadedCodec } from "../../../shared/src/codecs/types/requiredFlatOverloaded";
import { TUnionCodec, union } from "../../../shared/src/codecs/types/union";
import { nullCodec, TNullCodec } from "../../../shared/src/codecs/types/nullCodec";
import { TVideoVerificationCall4Codec, TVideoVerificationCall5Codec, VideoVerificationCall4, VideoVerificationCall5 } from "../VideoVerificationCall";
import { literal } from "../../../shared/src/codecs/types/literal";
import { fileIO, TFileIOCodec } from "../../../shared/src/codecs/types/fileIO";
import { CaseSdltAnswersSubmissionStatus, TCaseSdltAnswersSubmissionStatusCodec } from "../overloads/CaseSdltAnswersSubmissionStatus";
import { CaseSingleClientTransacting, TCaseSingleClientTransactingCodec } from "../overloads/CaseSingleClientTransacting";
import { CaseSdltOrLtt, TCaseSdltOrLttCodec } from "../overloads/CaseSdltOrLtt";
import { TUserNamesCodec, UserNames } from "../overloads/UserName";
import { CasesPurchasePaymentMethod1 } from "../CasesPurchasePaymentMethod";
import { CasesLenderNameOnPanel1 } from "../CasesLenderNameOnPanel";
import { dateTime, TAnyDateTimeCodec } from "../../../shared/src/codecs/types/dateTime";
import { currencyInteger } from "../../../shared/src/codecs/types/currencyInteger";
import { UuidForm } from "./UuidForm";
import { TransactionType1 } from "../TransactionType";
import { CaseOnboardingUrl } from "../overloads/CaseOnboardingUrl";
import { ClientCaseCharge, ClientCaseChargeBeneficiary, TClientCaseChargeBeneficiaryCodec, TClientCaseChargeCodec } from "../CaseCharge";
import { positiveInteger, TAnyPositiveIntegerCodec } from "../../../shared/src/codecs/types/positiveInteger";
import { requireExhaustive } from "../../../shared/src/util";
import { JointOwnershipHeldAsType, TJointOwnershipHeldAsTypeCodec } from "../CasesContractBlock";
import { DateTime } from "luxon";
import { date } from "../../../shared/src/codecs/types/date";
import { overload } from "../../../shared/src/codecs/types/overload";

export const ClientCaseCreateBankAccountForm: TFormCodec<TCaseIdCodec, TEmptyObjectCodec> = form(
    CaseId,
    EmptyObject
);
export type TClientCaseCreateBankAccountFormCodec = typeof ClientCaseCreateBankAccountForm;
export type TClientCaseCreateBankAccountForm = TTypeOfCodec<TClientCaseCreateBankAccountFormCodec>;

export const ClientCaseSubmitBankAccountsForVerificationForm: TFormCodec<TCaseIdCodec, TEmptyObjectCodec> = form(
    CaseId,
    EmptyObject
);
export type TClientCaseSubmitBankAccountsForVerificationFormCodec = typeof ClientCaseSubmitBankAccountsForVerificationForm;
export type TClientCaseSubmitBankAccountsForVerificationForm = TTypeOfCodec<TClientCaseSubmitBankAccountsForVerificationFormCodec>;

export const ClientCaseBankAccountForm: TFormCodec<TCasesBankAccountCodec, TEmptyObjectCodec> = form(
    CasesBankAccount1,
    EmptyObject,
);
export type TClientCaseBankAccountFormCodec = typeof ClientCaseBankAccountForm;
export type TClientCaseBankAccountForm = TTypeOfCodec<TClientCaseBankAccountFormCodec>;

export const ClientCaseCreateIdVerificationLinkForm: TFormCodec<
    TRequiredCodec<{
        users__cases_id: TAnyUuidCodec,
    }>,
    TRequiredCodec<{
        redirect_url: TAnyStringCodec,
    }>
> = form(
    required({ users__cases_id: uuid() }),
    required({ redirect_url: string() }),
);
export type TClientCaseCreateIdVerificationLinkFormCodec = typeof ClientCaseCreateIdVerificationLinkForm;
export type TClientCaseCreateIdVerificationLinkForm = TTypeOfCodec<TClientCaseCreateIdVerificationLinkFormCodec>;

export const ClientCaseIdentityVerificationUserProofOfAddressForm: TFileIOCodec<
    TRequiredCodec<{
        users_id_checks_id: TAnyStringCodec,
        name: TAnyStringCodec,
        file_extension: TAnyStringCodec,
        mime_type: TAnyStringCodec,
    }>,
    TEmptyObjectCodec
> = fileIO(
    required({
        users_id_checks_id: string(),
        name: string(),
        file_extension: string(),
        mime_type: string(),
    }),
    EmptyObject,
);
export type TClientCaseIdentityVerificationUserProofOfAddressFormCodec = typeof ClientCaseIdentityVerificationUserProofOfAddressForm;
export type TClientCaseIdentityVerificationUserProofOfAddressForm = TTypeOfCodec<TClientCaseIdentityVerificationUserProofOfAddressFormCodec>;

export const ClientCaseIdentityVerificationUserForm: TFormCodec<
    TUser18Codec,
    TRequiredCodec<{
        user14: TUser14Codec,
        create_id_verification_link: TClientCaseCreateIdVerificationLinkFormCodec,
        upload_proof_of_address_form: TClientCaseIdentityVerificationUserProofOfAddressFormCodec,
    }>
> = form(
    User18,
    required({
        user14: User14,
        create_id_verification_link: ClientCaseCreateIdVerificationLinkForm,
        upload_proof_of_address_form: ClientCaseIdentityVerificationUserProofOfAddressForm,
    })
);
export type TClientCaseIdentityVerificationUserFormCodec = typeof ClientCaseIdentityVerificationUserForm;
export type TClientCaseIdentityVerificationUserForm = TTypeOfCodec<TClientCaseIdentityVerificationUserFormCodec>;

export const ClientCaseUserSaysPaymentPaidForm: TFormCodec<
    TRequiredCodec<{case_ledger_entries_ids: TArrayCodec<TUuidCodec>}>,
    TEmptyObjectCodec
> = form(
    required({
        case_ledger_entries_ids: array(uuid()),
    }),
    EmptyObject
);
export type TClientCaseUserSaysPaymentPaidFormCodec = typeof ClientCaseUserSaysPaymentPaidForm;
export type TClientCaseUserSaysPaymentPaidForm = TTypeOfCodec<TClientCaseUserSaysPaymentPaidFormCodec>;

export const ClientCaseUserForm: TFormCodec<TIntersectionCodec<[
    TRequiredCodec<{
        users__cases_id: TUuidCodec
    }>,
    TClientCaseUserCodec,
]>, TRequiredCodec<{
    user_id: TUuidCodec
}>> = form(
    intersection([
        required({
            users__cases_id: uuid(),
        }),
        ClientCaseUser,
    ]),
    required({
        user_id: uuid(),
    }),
);
export type TClientCaseUserFormCodec = typeof ClientCaseUserForm;
export type TClientCaseUserForm = TTypeOfCodec<TClientCaseUserFormCodec>;

export const ClientCaseCreateUserForm: TFormCodec<TIntersectionCodec<[
    TCaseIdCodec,
    TClientCaseUserCodec
]>, TEmptyObjectCodec> = form(
    intersection([
        CaseId,
        ClientCaseUser,
    ]),
    EmptyObject
);
export type TClientCaseCreateUserFormCodec = typeof ClientCaseCreateUserForm;
export type TClientCaseCreateUserForm = TTypeOfCodec<TClientCaseCreateUserFormCodec>;

export const ClientCaseCompanyForm: TFormCodec<TIntersectionCodec<[
    TCaseIdCodec,
    TRequiredCodec<{
        company_name: TAnyStringCodec,
        is_transacting_as_company: TCasesIsTransactingAsCompanyStatusCodec
    }>
]>, TEmptyObjectCodec> = form(
    intersection([
        CaseId,
        required({
            company_name: string(),
            is_transacting_as_company: CasesIsTransactingAsCompanyStatus,
        }),
    ]),
    EmptyObject
);
export type TClientCaseCompanyFormCodec = typeof ClientCaseCompanyForm;
export type TClientCaseCompanyForm = TTypeOfCodec<TClientCaseCompanyFormCodec>;

export const ClientCaseSubmitStepAsCompleteForm: TFormCodec<TCaseIdCodec, TEmptyObjectCodec> = form(
    CaseId,
    EmptyObject
);
export type TClientCaseSubmitStepAsCompleteFormCodec = typeof ClientCaseSubmitStepAsCompleteForm;
export type TClientCaseSubmitStepAsCompleteForm = TTypeOfCodec<TClientCaseSubmitStepAsCompleteFormCodec>;

export const ClientCaseRelatedTransactionQuestionForm: TFormCodec<TIntersectionCodec<[
    TRequiredCodec<{id: TUuidCodec}>,
    TCasesRelatedTransactionQuestion,
]>, TEmptyObjectCodec> = form(
    intersection([
        required({
            id: uuid(),
        }),
        CasesRelatedTransactionQuestion,
    ]),
    EmptyObject
);
export type TClientCaseRelatedTransactionQuestionFormCodec = typeof ClientCaseRelatedTransactionQuestionForm;
export type TClientCaseRelatedTransactionQuestionForm = TTypeOfCodec<TClientCaseRelatedTransactionQuestionFormCodec>;

export const ClientCaseRelatedTransactionAddressForm: TFormCodec<TCasesRelatedTransaction3Codec, TIntersectionCodec<[
    TRequiredFlatOverloadedCodec<{
        address_string: TAddress2Codec,
    }>,
    TRequiredFlatOverloadedCodec<{
        linked_case: TUnionCodec<[
            TAddress8Codec,
            TNullCodec,
        ]>,
    }>,
    TRequiredCodec<{
        linked_case_status: TUnionCodec<[
            TCaseStatusCodec,
            TNullCodec,
        ]>,
    }>
]>> = form(
    CasesRelatedTransaction3,
    intersection([
        requiredFlatOverloaded({
            address_string: Address2,
        }),
        requiredFlatOverloaded({
            linked_case: union([Address8, nullCodec()]),
        }),
        required({
            linked_case_status: union([CaseStatus, nullCodec()]),
        })
    ]),
);
export type TClientCaseRelatedTransactionAddressFormCodec = typeof ClientCaseRelatedTransactionAddressForm;
export type TClientCaseRelatedTransactionAddressForm = TTypeOfCodec<TClientCaseRelatedTransactionAddressFormCodec>;

export const ClientCasePurchasePaymentMethod = required({
    purchase_payment_method: CasesPurchasePaymentMethod1,
})
export type TClientCasePurchasePaymentMethodCodec = typeof ClientCasePurchasePaymentMethod;

export const ClientCaseMortgageDetails = required({
    mortgage_lender_name: CasesLenderNameOnPanel1,
    expected_mortgage_amount_pence: union([currencyInteger(), nullCodec()]),
});
export type TClientCaseMortgageDetailsCodec = typeof ClientCaseMortgageDetails;

export const ClientCasePurchasePaymentDetails = intersection([
    ClientCasePurchasePaymentMethod,
    required({
        mortgage_lender_name_known: CasesPurchaseMortgageLenderNameKnown, 
    }),
    ClientCaseMortgageDetails,
]);
export type TClientCasePurchasePaymentDetailsCodec = typeof ClientCasePurchasePaymentDetails;

export const ClientCasePurchasePaymentDetailsForm: TFormCodec<
    TIntersectionCodec<[
        TCaseIdCodec,
        TClientCasePurchasePaymentDetailsCodec
    ]>,
    TRequiredCodec<{    
        is_step_complete: TUnionCodec<[TAnyDateTimeCodec, TNullCodec]>
    }>
> = form(
    intersection([
        CaseId,
        ClientCasePurchasePaymentDetails
    ]),
    required({
        is_step_complete: union([dateTime(), nullCodec()]),
    }),
);
export type TClientCasePurchasePaymentDetailsFormCodec = typeof ClientCasePurchasePaymentDetailsForm;
export type TClientCasePurchasePaymentDetailsForm = TTypeOfCodec<TClientCasePurchasePaymentDetailsFormCodec>;

export const ClientCaseRemortgageMortgageDetailsForm: TFormCodec<
    TIntersectionCodec<[
        TCaseIdCodec,
        TClientCaseMortgageDetailsCodec
    ]>,
    TRequiredCodec<{    
        is_step_complete: TUnionCodec<[TAnyDateTimeCodec, TNullCodec]>
    }>
> = form(
    intersection([
        CaseId,
        ClientCaseMortgageDetails
    ]),
    required({
        is_step_complete: union([dateTime(), nullCodec()]),
    }),
);
export type TClientCaseRemortgageMortgageDetailsFormCodec = typeof ClientCaseRemortgageMortgageDetailsForm;
export type TClientCaseRemortgageMortgageDetailsForm = TTypeOfCodec<TClientCaseRemortgageMortgageDetailsFormCodec>;

export const ClientCaseRelatedTransaction = required({
    question: ClientCaseRelatedTransactionQuestionForm,
    address: ClientCaseRelatedTransactionAddressForm,
    users_who_can_receive_quote: array(UserReceivingQuote),
});
export type TClientCaseRelatedTransactionCodec = typeof ClientCaseRelatedTransaction;
export type TClientCaseRelatedTransaction = TTypeOfCodec<TClientCaseRelatedTransactionCodec>;

export const ClientCaseVideoVerificationCallAvailableTimesForm: TFormCodec<TVideoVerificationCall5Codec, TEmptyObjectCodec> = form(
    VideoVerificationCall5,
    EmptyObject,
);
export type TClientCaseVideoVerificationCallAvailableTimesFormCodec = typeof ClientCaseVideoVerificationCallAvailableTimesForm;
export type TClientCaseVideoVerificationCallAvailableTimesForm = TTypeOfCodec<TClientCaseVideoVerificationCallAvailableTimesFormCodec>;

export const ClientCaseVideoVerificationCallCreateForm: TFormCodec<TVideoVerificationCall4Codec, TClientCaseVideoVerificationCallAvailableTimesFormCodec> = form(
    VideoVerificationCall4,
    ClientCaseVideoVerificationCallAvailableTimesForm,
);
export type TClientCaseVideoVerificationCallCreateFormCodec = typeof ClientCaseVideoVerificationCallCreateForm;
export type TClientCaseVideoVerificationCallCreateForm = TTypeOfCodec<TClientCaseVideoVerificationCallCreateFormCodec>;

export const ClientCaseVideoVerificationCallLoggedInUserStatus = union([
    literal("not_booked"),
    literal("booked"),
    literal("missed_appointment"),
]);
export type TClientCaseVideoVerificationCallLoggedInUserStatusCodec = typeof ClientCaseVideoVerificationCallLoggedInUserStatus;

export const ClientCaseSdltForm: TFormCodec<TCasesSdltCodec, TIntersectionCodec<[
    TRequiredFlatOverloadedCodec<{
        status: TCaseSdltAnswersSubmissionStatusCodec,
    }>,
    TRequiredFlatOverloadedCodec<{
        single_client_transacting: TCaseSingleClientTransactingCodec,
    }>,
    TRequiredFlatOverloadedCodec<{
        sdlt_or_ltt: TCaseSdltOrLttCodec,
    }>,
    TRequiredFlatOverloadedCodec<{
        client_names: TUserNamesCodec,
    }>,
]>> = form(
    CasesSdlt,
    intersection([
        requiredFlatOverloaded({
            status: CaseSdltAnswersSubmissionStatus,
        }),
        requiredFlatOverloaded({
            single_client_transacting: CaseSingleClientTransacting,
        }),
        requiredFlatOverloaded({
            sdlt_or_ltt: CaseSdltOrLtt,
        }),
        requiredFlatOverloaded({
            client_names: UserNames,
        }),
    ]),
);
export type TClientCaseSdltFormCodec = typeof ClientCaseSdltForm;
export type TClientCaseSdltForm = TTypeOfCodec<TClientCaseSdltFormCodec>;

export const ClientCaseChargeForm: TFormCodec<TClientCaseChargeCodec, TClientCaseChargeBeneficiaryCodec> = form(
    ClientCaseCharge,
    ClientCaseChargeBeneficiary,
);
export type TClientCaseChargeFormCodec = typeof ClientCaseChargeForm;
export type TClientCaseChargeForm = TTypeOfCodec<TClientCaseChargeFormCodec>;

export const ClientCasePropertyInformationForms = required({
    is_step_complete: boolean(),
    include_ta7: boolean(),
    submit_step_as_complete_form: UuidForm,
});
export type TClientCasePropertyInformationFormsCodec = typeof ClientCasePropertyInformationForms;

export const ClientCaseSwitchToRelatedTransaction = required({
    related_transaction_type: TransactionType1,
    url: CaseOnboardingUrl,
});
export type TClientCaseSwitchToRelatedTransactionCodec = typeof ClientCaseSwitchToRelatedTransaction;
export type TClientCaseSwitchToRelatedTransaction = TTypeOfCodec<TClientCaseSwitchToRelatedTransactionCodec>;

const ClientCaseJointOwnershipHeldAsFormType = union([
    nullCodec(),
    literal("joint_tenants"),
    literal("tenants_in_common"),
    literal("not_applicable"),
]);
export type TClientCaseJointOwnershipHeldAsFormTypeCodec = typeof ClientCaseJointOwnershipHeldAsFormType;
export type TClientCaseJointOwnershipHeldAsFormType = TTypeOfCodec<TClientCaseJointOwnershipHeldAsFormTypeCodec>;

export const ClientCaseJointOwnershipHeldAsFormTenantsInCommonPreference = union([
    nullCodec(),
    literal("equal_shares"),
    literal("different_shares"),
]);
export type TClientCaseJointOwnershipHeldAsFormTenantsInCommonPreferenceCodec = typeof ClientCaseJointOwnershipHeldAsFormTenantsInCommonPreference;
export type TClientCaseJointOwnershipHeldAsFormTenantsInCommonPreference = TTypeOfCodec<TClientCaseJointOwnershipHeldAsFormTenantsInCommonPreferenceCodec>;

export const ClientCaseJointOwnershipHeldAsForm: TFormCodec<
    TRequiredCodec<{
        id: TUuidCodec,
        type: TClientCaseJointOwnershipHeldAsFormTypeCodec,
        tenants_in_common_preference: TClientCaseJointOwnershipHeldAsFormTenantsInCommonPreferenceCodec
    }>,
    TEmptyObjectCodec
> = form(
    required({
        id: uuid(),
        type: ClientCaseJointOwnershipHeldAsFormType,
        tenants_in_common_preference: ClientCaseJointOwnershipHeldAsFormTenantsInCommonPreference,
    }),
    EmptyObject,
);
export type TClientCaseJointOwnershipHeldAsFormCodec = typeof ClientCaseJointOwnershipHeldAsForm;
export type TClientCaseJointOwnershipHeldAsForm = TTypeOfCodec<TClientCaseJointOwnershipHeldAsFormCodec>;

export const ClientCaseJointOwnershipHeldAsFormType_displayString = (p: TClientCaseJointOwnershipHeldAsFormType): string =>
    p === "joint_tenants" ? "Joint tenants"
    : p === "tenants_in_common" ? "Tenants in common"
    : p === "not_applicable" ? "Not applicable"
    : p === null ? ""
    : requireExhaustive(p);

export const ClientCaseJointOwnershipHeldAsFormTenantsInCommonPreference_displayString = (p: TClientCaseJointOwnershipHeldAsFormTenantsInCommonPreference): string =>
    p === "different_shares" ? "Different shares"
    : p === "equal_shares" ? "Equal shares"
    : p === null ? ""
    : requireExhaustive(p);

export const ClientCaseDeclarationOfTrustReferralForm: TFormCodec<
    TRequiredCodec<{
        id: TUuidCodec,
        refer_to_sail_wills_for_declaration_of_trust: TUnionCodec<[TNullCodec, TBooleanCodec]>
    }>,
    TRequiredCodec<{
        joint_ownership_held_as_preference: TJointOwnershipHeldAsTypeCodec
    }>
> = form(
    required({
        id: uuid(),
        refer_to_sail_wills_for_declaration_of_trust: union([nullCodec(), boolean()]),
    }),
    required({
        joint_ownership_held_as_preference: JointOwnershipHeldAsType,
    }),
);
export type TClientCaseDeclarationOfTrustReferralFormCodec = typeof ClientCaseDeclarationOfTrustReferralForm;
export type TClientCaseDeclarationOfTrustReferralForm = TTypeOfCodec<TClientCaseDeclarationOfTrustReferralFormCodec>;

export const ClientCaseWillReferralFormUserAgeBracket = union([
    literal("young"),
    literal("mid"),
    literal("older"),
]);
type TClientCaseWillReferralFormUserAgeBracketCodec = typeof ClientCaseWillReferralFormUserAgeBracket;
export type TClientCaseWillReferralFormUserAgeBracket = TTypeOfCodec<TClientCaseWillReferralFormUserAgeBracketCodec>;
const ClientCaseWillReferralFormUserAgeBracketOverload = overload(
    ClientCaseWillReferralFormUserAgeBracket,
    required({
        legal_date_of_birth: union([nullCodec(), date()]),
    }),
    ({legal_date_of_birth}): TClientCaseWillReferralFormUserAgeBracket => {
        const age = Math.floor(DateTime.utc().diff(DateTime.fromISO(legal_date_of_birth || ""), "years").years);
        return legal_date_of_birth === null ? "mid"
        : age < 30 ? "young"
        : age < 50 ? "mid"
        : "older"
    }
);
type TClientCaseWillReferralFormUserAgeBracketOverloadCodec = typeof ClientCaseWillReferralFormUserAgeBracketOverload;
export const ClientCaseWillReferralForm: TFormCodec<
    TRequiredCodec<{
        id: TUuidCodec,
        refer_to_sail_wills_for_will: TUnionCodec<[TNullCodec, TBooleanCodec]>
    }>,
    TIntersectionCodec<[
        TRequiredCodec<{
            is_intestate: TBooleanCodec,
        }>,
        TRequiredFlatOverloadedCodec<{
            user_age_bracket: TClientCaseWillReferralFormUserAgeBracketOverloadCodec
        }>
    ]>
> = form(
    required({
        id: uuid(),
        refer_to_sail_wills_for_will: union([nullCodec(), boolean()]),
    }),
    intersection([
        required({
            is_intestate: boolean(),
        }),
        requiredFlatOverloaded({
            user_age_bracket: ClientCaseWillReferralFormUserAgeBracketOverload,
        }),
    ])
);
export type TClientCaseWillReferralFormCodec = typeof ClientCaseWillReferralForm;
export type TClientCaseWillReferralForm = TTypeOfCodec<TClientCaseWillReferralFormCodec>;

export const ClientCaseForm: TFormCodec<
    TCaseIdCodec,
    TIntersectionCodec<[
        TPartialCodec<{
            identity_verification: TRequiredCodec<{
                users: TArrayCodec<TClientCaseIdentityVerificationUserFormCodec>
            }>,
            bank_accounts: TRequiredCodec<{
                accounts: TArrayCodec<TClientCaseBankAccountFormCodec>,
                available_recipients: TArrayCodec<TUser16Codec>,
                create_account: TClientCaseCreateBankAccountFormCodec,
                submit_for_verification: TClientCaseSubmitBankAccountsForVerificationFormCodec
            }>,
            payment: TRequiredCodec<{
                details: TArrayCodec<TCaseLedgerAmountAndPaidStatusCodec>,
                totalAmountDuePence: TAnyPositiveIntegerCodec,
                paymentReference: TAnyStringCodec,
                user_says_paid: TClientCaseUserSaysPaymentPaidFormCodec
            }>,
            confirm_case_users: TRequiredCodec<{
                company: TClientCaseCompanyFormCodec,
                users: TArrayCodec<TClientCaseUserFormCodec>,
                create_case_user: TClientCaseCreateUserFormCodec,
                is_step_complete: TBooleanCodec,
                submit_step_as_complete: TClientCaseSubmitStepAsCompleteFormCodec
            }>,
            related_transaction: TClientCaseRelatedTransactionCodec,
            remortgage_details: TClientCaseRemortgageMortgageDetailsFormCodec,
            purchase_payment_details: TClientCasePurchasePaymentDetailsFormCodec,
            video_verification_call: TRequiredCodec<{
                users_who_can_be_booked: TArrayCodec<TUserBookableForVideoCallCodec>,
                create_booking: TClientCaseVideoVerificationCallCreateFormCodec,
                logged_in_user_status: TClientCaseVideoVerificationCallLoggedInUserStatusCodec,
            }>,
            sdlt_questions: TClientCaseSdltFormCodec,
            charge_account_numbers: TRequiredCodec<{
                charges: TArrayCodec<TClientCaseChargeFormCodec>,
                is_step_complete: TBooleanCodec,
                submit_step_as_complete: TClientCaseSubmitStepAsCompleteFormCodec,
            }>,
            dual_rep_consent: TRequiredCodec<{
                is_step_complete: TBooleanCodec,
                client_case_handler: TStaffUserCodec,
                other_side_case_handler: TUnionCodec<[TStaffUserCodec, TNullCodec]>,
                submit_step_as_complete: TClientCaseSubmitStepAsCompleteFormCodec,
            }>,
            property_information_forms: TClientCasePropertyInformationFormsCodec,
            bsa_advice_agreed: TRequiredCodec<{
                is_step_complete: TBooleanCodec,
                submit_step_as_complete: TClientCaseSubmitStepAsCompleteFormCodec,
            }>
            joint_ownership_held_as_preference: TRequiredCodec<{
                is_step_complete: TBooleanCodec,
                form: TClientCaseJointOwnershipHeldAsFormCodec
            }>,
            declaration_of_trust_referral: TRequiredCodec<{
                is_step_complete: TBooleanCodec,
                form: TClientCaseDeclarationOfTrustReferralFormCodec
            }>
            will_referral: TRequiredCodec<{
                is_step_complete: TBooleanCodec,
                form: TClientCaseWillReferralFormCodec
            }>
        }>,
        TRequiredCodec<{
            agree_to_toe: TRequiredCodec<{
                is_step_complete: TBooleanCodec,
                submit_step_as_complete: TClientCaseSubmitStepAsCompleteFormCodec,
            }>,
        }>,
        TRequiredCodec<{
            session_user_case_user_type: TCaseUserTypeCodec,
        }>,
        TRequiredCodec<{
            case: TClientCaseCodec
        }>,
        TRequiredCodec<{
            quote_download_url: TAnyStringCodec,
        }>,
        TRequiredCodec<{
            switch_to_related_transaction: TUnionCodec<[
                TNullCodec,
                TClientCaseSwitchToRelatedTransactionCodec,
            ]>
        }>
    ]>
> = form(
    CaseId,
    intersection([
        partial({
            identity_verification: required({
                users: array(ClientCaseIdentityVerificationUserForm),
            }),
            bank_accounts: required({
                accounts: array(ClientCaseBankAccountForm),
                available_recipients: array(User16),
                create_account: ClientCaseCreateBankAccountForm,
                submit_for_verification: ClientCaseSubmitBankAccountsForVerificationForm,
            }),
            payment: required({
                details: array(CaseLedgerAmountAndPaidStatus),
                totalAmountDuePence: positiveInteger(),
                paymentReference: string(),
                user_says_paid: ClientCaseUserSaysPaymentPaidForm,
            }),
            confirm_case_users: required({
                company: ClientCaseCompanyForm,
                users: array(ClientCaseUserForm),
                create_case_user: ClientCaseCreateUserForm,
                is_step_complete: boolean(),
                submit_step_as_complete: ClientCaseSubmitStepAsCompleteForm,
            }),
            related_transaction: ClientCaseRelatedTransaction,
            remortgage_details: ClientCaseRemortgageMortgageDetailsForm,
            purchase_payment_details: ClientCasePurchasePaymentDetailsForm,
            video_verification_call: required({
                users_who_can_be_booked: array(UserBookableForVideoCall),
                create_booking: ClientCaseVideoVerificationCallCreateForm,
                logged_in_user_status: ClientCaseVideoVerificationCallLoggedInUserStatus,
            }),
            sdlt_questions: ClientCaseSdltForm,
            charge_account_numbers: required({
                charges: array(ClientCaseChargeForm),
                is_step_complete: boolean(),
                submit_step_as_complete: ClientCaseSubmitStepAsCompleteForm,
            }),
            dual_rep_consent: required({
                is_step_complete: boolean(),
                client_case_handler: StaffUser,
                other_side_case_handler: union([StaffUser, nullCodec()]),
                submit_step_as_complete: ClientCaseSubmitStepAsCompleteForm,
            }),
            property_information_forms: ClientCasePropertyInformationForms,
            bsa_advice_agreed: required({
                is_step_complete: boolean(),
                submit_step_as_complete: ClientCaseSubmitStepAsCompleteForm,
            }),
            joint_ownership_held_as_preference: required({
                is_step_complete: boolean(),
                form: ClientCaseJointOwnershipHeldAsForm,
            }),
            declaration_of_trust_referral: required({
                is_step_complete: boolean(),
                form: ClientCaseDeclarationOfTrustReferralForm,
            }),
            will_referral: required({
                is_step_complete: boolean(),
                form: ClientCaseWillReferralForm,
            }),
        }),
        required({
            agree_to_toe: required({
                is_step_complete: boolean(),
                submit_step_as_complete: ClientCaseSubmitStepAsCompleteForm,
            }),
        }),
        required({
            session_user_case_user_type: CaseUserType,
        }),
        required({
            case: ClientCase,
        }),
        required({
            quote_download_url: string(),
        }),
        required({
            switch_to_related_transaction: union([
                nullCodec(),
                ClientCaseSwitchToRelatedTransaction,
            ])
        }),
    ]),
);
export type TClientCaseFormCodec = typeof ClientCaseForm;
export type TClientCaseForm = TTypeOfCodec<TClientCaseFormCodec>;
