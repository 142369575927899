import { dateTime } from "../../shared/src/codecs/types/dateTime";
import { nullCodec } from "../../shared/src/codecs/types/nullCodec";
import { required } from "../../shared/src/codecs/types/required";
import { union } from "../../shared/src/codecs/types/union";
import { uuid } from "../../shared/src/codecs/types/uuid";
import { literal } from "../../shared/src/codecs/types/literal";
import { intersection } from "../../shared/src/codecs/types/intersection";
import { UserBankruptcySearch } from "./User";
import { array } from "../../shared/src/codecs/types/array";

const BankruptcySearchResult = union([
    literal("pass"),
    literal("fail"),
    literal("not_applicable"),
    literal("unknown"),
]);
export type TBankruptcySearchResult = typeof BankruptcySearchResult;

export const BankruptcySearch1 = required({
    bankruptcy_search_id: uuid(),
    bankruptcy_search_result: BankruptcySearchResult,
    expires_at: union([dateTime(), nullCodec()]),
});
export type TBankruptcySearch1Codec = typeof BankruptcySearch1;

export const BankruptcySearchWithUsers = intersection([
    BankruptcySearch1,
    required({
        bankruptcy_search_users: array(UserBankruptcySearch)
    })
]);
export type TBankruptcySearchWithUsersCodec = typeof BankruptcySearchWithUsers;