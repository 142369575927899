import * as t from "io-ts";
import * as util from "../../shared/src/util";

type TDisplayName = "Unknown"
    | "First time buyer"
    | "Homeowner"
    | "Investor";

export const codec = t.union([
    t.literal("unknown"),
    t.literal("first_time_buyer"),
    t.literal("homeowner"),
    t.literal("investor"),
]);

export const values = util.typeValuesFromCodec(codec.types as Array<t.LiteralC<T>>);

type T = t.TypeOf<typeof codec>;

export const fromValueToDisplayName = (value: T): TDisplayName => {
    switch (value) {
        case "unknown":
            return "Unknown";
        case "first_time_buyer":
            return "First time buyer";
        case "homeowner":
            return "Homeowner";
        case "investor":
            return "Investor";
        default:
            return util.requireExhaustive(value);
    }
};
