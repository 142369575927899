import * as t from "io-ts";
import * as util from "../../shared/src/util";

type TDisplayName =
    "Desktop"
    | "Video"
    | "In Person"
    | "Undetermined"
;

export const codec = t.union([
    t.literal("desktop"),
    t.literal("video"),
    t.literal("in_person"),
    t.literal("undetermined"),
]);

export const values = util.typeValuesFromCodec(codec.types as Array<t.LiteralC<T>>);

type T = t.TypeOf<typeof codec>;

export const fromValueToDisplayName = (value: T): TDisplayName => {
    switch (value) {
        case "desktop":
            return "Desktop";
        case "video":
            return "Video";
        case "in_person":
            return "In Person";
        case "undetermined":
            return "Undetermined";
        default:
            return util.requireExhaustive(value);
    }
};
