import React, { useState } from "react";
import { option, array, eq } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import { array as arrayExt } from "../../../shared/src/utilByDomainGroupExport";
import { KeyCodes } from "../models/KeyCodes";
import { IUseOpenClose, useOpenClose } from "./UseOpenClose";

interface IUseDropdown<A extends string, O extends IOption<A>> extends IUseOpenClose {
    optionsRef: Array<React.RefObject<HTMLDivElement>>;
    focusedOptionIndex: number;
    isOptionSelected: (value: A) => boolean;
    getFaceText: () => JSX.Element;
    getFaceTextWithCount: () => JSX.Element;
    toggleSelectedValue: (val: A) => Array<A>;
    getValueOptionProp: (val: A, prop: keyof O) => O[keyof O] | null;
    getValue: (val: A) => O | null;
    isAnythingSelected: () => boolean;
    onKeyboardNavigation: (e: React.KeyboardEvent<HTMLDivElement>, onChange?: (val: A) => void) => void;
}

export interface IOption<A> {
    value: A;
    label: JSX.Element | string;
    isHidden?: boolean;
};

const normaliseValueToArray = <A extends string>(value: A | Array<A>): Array<A> =>
    typeof value === "string" ? [value] : value;

export const useDropdown = <A extends string, O extends IOption<A>>(
    values: A | Array<A>, 
    options: Array<O>, 
    inputRef?: React.RefObject<HTMLDivElement>
): IUseDropdown<A, O> => {
    
    const {
        ref,
        isOpen,
        setIsOpen,
        toggleOpen
    } = useOpenClose({
        inputRef,
    });

    const optionsRef = options.map(() => React.createRef<HTMLDivElement>());
    const [focusedOptionIndex, setFocusedOptionIndex] = useState<number>(-1);

    const isAnythingSelected = (): boolean =>  pipe(
        values,
        normaliseValueToArray,
        (valueArray) =>  valueArray.join("").trim(),
        (condensedValue) => condensedValue.length > 0
    );

    const isOptionSelected = (value: A): boolean => array.elem(eq.eqString)(value, normaliseValueToArray(values));

    const findOptionByKey = (key: A): option.Option<O> =>  pipe(
        options,
        array.findFirst<O>((selectOption) => selectOption.value === key),
    );

    const getValueOptionProp = (key: A, prop: keyof O): O[keyof O] | null =>
         pipe(
            findOptionByKey(key),
            option.fold(
                () => null,
                (selectOption) => selectOption[prop],
            )
        );

    const getValue = (key: A): O | null =>
        pipe(
            findOptionByKey(key),
            option.fold(
                () => null,
                (selectOption) => selectOption,
            )
        );
  
    const getFaceText = (): JSX.Element => <>{pipe(
        normaliseValueToArray(values),
        array.filterMapWithIndex((index, key) => 
            pipe(
                findOptionByKey(key),
                option.map((op) => <span key={index}>{op.label}</span>)
            )
        ),
        array.intersperse(<>, </>),
    )}</>;
    
    const getFaceTextWithCount = (): JSX.Element =>  pipe(
        normaliseValueToArray(values),
        array.filter((key) => getValueOptionProp(key, "label") !== null),
        (vals) => {
            if (vals.length > 1) {
                return (<>{getValueOptionProp(vals[0], "label")}, +{vals.length-1} more</>);
            }
            return getFaceText();
        }
    );

    const toggleSelectedValue = (newValue: A): Array<A> => {
        if (isOptionSelected(newValue)) {
            return arrayExt.deleteValue<A>(newValue, normaliseValueToArray(values));
        }
        return normaliseValueToArray(values).concat(newValue);
    };

    const focusOnRef = (focusRef: React.RefObject<HTMLDivElement>): void =>  pipe(
        focusRef.current,
        option.fromNullable,
        option.fold<HTMLDivElement, void>(
            () => undefined,
            (current) => current.focus(),
        ),
    );

    type TKeyboardScenario = "selectDown" | "selectUp" | "open" | "close" | "selectAndClose" | "doNothing";

    const getKeyboardScenario = (e: React.KeyboardEvent<HTMLDivElement>, onChange?: (val: A) => void): TKeyboardScenario => {
        if (e.keyCode === KeyCodes.ENTER && !isOpen) {
            return "open";
        }

        if (e.keyCode === KeyCodes.ENTER && isOpen && onChange && focusedOptionIndex > -1) {
            return "selectAndClose";
        }

        if (e.keyCode === KeyCodes.ESCAPE) {
            return "close";
        }

        if (e.keyCode === KeyCodes.ENTER && isOpen && (!onChange || focusedOptionIndex === -1)) {
            return "close";
        }

        if (e.keyCode === KeyCodes.ARROW_DOWN || e.keyCode === KeyCodes.TAB) {
            return "selectDown";
        }

        if (e.keyCode === KeyCodes.ARROW_UP) {
            return "selectUp";
        }

        return "doNothing";
    };

    const focusOnNextOption = (): void => {
        const newIndex = (focusedOptionIndex + 1) % options.length;
        setFocusedOptionIndex(newIndex);
        focusOnRef(optionsRef[newIndex]);
    };

    const focusOnPreviousOption = (): void => {
        const newIndex = focusedOptionIndex === 0 ? options.length - 1 : focusedOptionIndex - 1;
        setFocusedOptionIndex(newIndex);
        focusOnRef(optionsRef[newIndex]);
    };

    const onKeyboardNavigation = (e: React.KeyboardEvent<HTMLDivElement>, onChange?: (val: A) => void): void => {
        e.preventDefault();
        const scenario = getKeyboardScenario(e, onChange);

        switch (scenario) {
            case "open":
                setIsOpen(true);
                break;
            case "close":
                setIsOpen(false);
                setFocusedOptionIndex(-1);
                break;
            case "selectAndClose":
                setIsOpen(false);
                /* eslint-disable */
                // @ts-ignore
                onChange(options[focusedOptionIndex].key); 
                setFocusedOptionIndex(-1);
                /* eslint-enable */
                break;
            case "selectDown":
                focusOnNextOption();
                break;
            case "selectUp":
                focusOnPreviousOption();
                break;
            default:
                break;
        }
    };

    return {
        ref,
        optionsRef,
        focusedOptionIndex,
        isOpen,
        setIsOpen,
        isOptionSelected,
        getFaceText,
        getFaceTextWithCount,
        toggleSelectedValue,
        getValueOptionProp,
        getValue,
        isAnythingSelected,
        onKeyboardNavigation,
        toggleOpen
    };
};
