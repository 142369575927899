import { required } from "../../shared/src/codecs/types/required";
import { union } from "../../shared/src/codecs/types/union";
import { array } from "../../shared/src/codecs/types/array";
import { literal } from "../../shared/src/codecs/types/literal";
import { TTypeOfCodec, TTypeOfNewDefault } from "../../shared/src/codecs/codec";
import { string } from "../../shared/src/codecs/types/string";
import { dateTime } from "../../shared/src/codecs/types/dateTime";
import { nullCodec } from "../../shared/src/codecs/types/nullCodec";
import { boolean } from "../../shared/src/codecs/types/boolean";
import { StaffUser } from "../../domain/codecs/User";
import { CaseEnquiryNote1 } from "./CaseEnquiryNote";
import { CasesDocumentType } from "./CasesDocumentTypes";
import { CasesDocument4 } from "./CasesDocument4";
import { CasesDocumentLegalFile3 } from "./CasesDocumentLegalFile3";
import { CaseEnquiryStatus } from "./CaseEnquiryStatus";
import { CasesDocument5 } from "./CasesDocument5";
import { LegalEmail2 } from "./LegalEmail2";
import { date } from "../../shared/src/codecs/types/date";

export const CaseEnquiryResolvedOrNotRelevant = required({
    status: union([
        literal("resolved"),
        literal("not_relevant"),
    ]),
});

export const CaseEnquiryStatusObject = required({
    status: CaseEnquiryStatus,
});
export type TCaseEnquiryStatusObjectCodec = typeof CaseEnquiryStatusObject;
export type TCaseEnquiryStatusObject = TTypeOfCodec<TCaseEnquiryStatusObjectCodec>;


export const CaseEnquiryRaw = required({
    id: string(),
    case_id: string(),
    created_at: dateTime(),
    message: string(),
    deleted_at: union([nullCodec(), dateTime()]),
    status: CaseEnquiryStatus,
    created_by: string(),
    is_resolved: union([nullCodec(), dateTime()]),
});
export type TCaseEnquiryRaw = TTypeOfCodec<typeof CaseEnquiryRaw>;
export type TCaseEnquiryRawNewDefault = TTypeOfNewDefault<typeof CaseEnquiryRaw>;

export const EnquiryDocument = required({
    id: string(),
    type: CasesDocumentType,
    reference: string(),
});
export type TEnquiryDocument = TTypeOfCodec<typeof EnquiryDocument>;
export type TEnquiryDocumentNewDefault = TTypeOfNewDefault<typeof EnquiryDocument>;

export const CaseEnquiry1 = required({
    id: string(),
    message: string(),
    status: CaseEnquiryStatus,
    created_by: string(),
    is_resolved: union([nullCodec(), dateTime()]),
    documents: array(EnquiryDocument),
    reference_id: union([nullCodec(), string()]),
});

export type TCaseEnquiry1 = TTypeOfCodec<typeof CaseEnquiry1>;
export type TCaseEnquiry1NewDefault = TTypeOfNewDefault<typeof CaseEnquiry1>;


export const CaseEnquiry1Sort = (a: TCaseEnquiry1, b: TCaseEnquiry1): number => {
    const aReferenceId = a.reference_id;
    const bReferenceId = b.reference_id;

    if (aReferenceId === null || bReferenceId === null) {
        // Put nulls first
        if (aReferenceId === null && bReferenceId !== null) {
            return -1
        }
        if (bReferenceId === null && aReferenceId !== null) {
            return 1
        }
        // They both must be null, leave in original order
        return 0
    }
    // Compare the strings using english localeCompare
    // This should become ['A1', 'a10B', 'A11', 'A12', 'A2', 'A3', 'A4', 'B10', 'B2', 'F1', 'F12', 'F3', '2', 'A10A']
    // this ['2','A1', 'A2', 'A3', 'A4', 'A10A', 'a10B', 'A11', 'A12', 'B2', 'B10', 'F1', 'F3', 'F12']
    return aReferenceId.localeCompare(bReferenceId, 'en', { numeric: true, sensitivity: "accent" })
}


export const EnquiryDocument2 = required({
    id: string(),
    type: CasesDocumentType,
    reference: string(),
    case_id: string(),
    due_date: dateTime(),
    received_date: union([dateTime(), nullCodec()]),
    additional_name: string(),
    from_external_source: boolean(),
    files: array(CasesDocumentLegalFile3),
    all_files_checked_for_enquiries: boolean(),
    valid_until: union([date(), nullCodec()])
});
export type TEnquiryDocument2 = TTypeOfCodec<typeof EnquiryDocument2>;
export type TEnquiryDocument2NewDefault = TTypeOfNewDefault<typeof EnquiryDocument2>;

export const EnquiryTimelineItem = union([
    required({
        _tag: literal("document"),
        payload: CasesDocument4
    }),
    required({
        _tag: literal("email"),
        payload: LegalEmail2,
    }),
    required({
        _tag: literal("note"),
        payload: CaseEnquiryNote1
    }),
])

export type TEnquiryTimelineItem = TTypeOfCodec<typeof EnquiryTimelineItem>;
export type TEnquiryTimelineItemNewDefault = TTypeOfNewDefault<typeof EnquiryTimelineItem>;

export const CaseEnquiry3 = required({
    id: string(),
    message: string(),
    status: CaseEnquiryStatus,
    documents: array(CasesDocument5),
    reference_id: union([nullCodec(), string()]),
    created_by_user: StaffUser,
    timeline: array(EnquiryTimelineItem),
    created_at: dateTime(),
});
export type TCaseEnquiry3 = TTypeOfCodec<typeof CaseEnquiry3>;
export type TCaseEnquiry3NewDefault = TTypeOfNewDefault<typeof CaseEnquiry3>;