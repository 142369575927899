import { required } from "../../../../shared/src/codecs/types/required";
import { string } from "../../../../shared/src/codecs/types/string";
import { partial } from "../../../../shared/src/codecs/types/partial";
import { array as arrayCodec } from "../../../../shared/src/codecs/types/array";
import { union } from "../../../../shared/src/codecs/types/union";
import { literal } from "../../../../shared/src/codecs/types/literal";
import { CaseRoadblocksBlockRouteParamsCodec, Redirect, noQueryParamsCodec } from "./routerCodecs";
import { param } from "./routerParams";
import { uuid } from "../../../../shared/src/codecs/types/uuid";

export const paths = {
    VIEW_CRM_BLOCKS_TEST: {
        pattern: ["crm", "blockstest"],
        queryParamsCodec: noQueryParamsCodec,
    },
    ["VIEW_ADMIN"]: {
        pattern: ["crm/admin"],
        queryParamsCodec: noQueryParamsCodec,
    },
    ["VIEW_LISTING"]: {
        pattern: ["listings", param("listingId")],
        queryParamsCodec: noQueryParamsCodec,
    },
    ["VIEW_NOT_FOUND"]: {
        pattern: ["404"],
        queryParamsCodec: noQueryParamsCodec,
    },
    ["VIEW_LISTING_BOOK_VIEWING"]: {
        pattern: ["listings", param("listingId"), "book-a-viewing"],
        queryParamsCodec: noQueryParamsCodec,
    },
    ["VIEW_LISTING_BOOK_VIEWING_ADDITIONAL_QUESTIONS"]: {
        pattern: ["listings", param("listingId"), "book-a-viewing", "additional-questions"],
        queryParamsCodec: noQueryParamsCodec,
    },
    ["VIEW_LISTING_BOOK_VIEWING_SUCCESS"]: {
        pattern: ["listings", param("listingId"), "book-a-viewing", "success"],
        queryParamsCodec: noQueryParamsCodec,
    },
    ["VIEW_CRM_SEARCH"]: {
        pattern: ["crm", "search"],
        queryParamsCodec: noQueryParamsCodec,
    },
    ["VIEW_CRM_LISTING"]: {
        pattern: ["crm", "listing", param("listingId")],
        queryParamsCodec: partial({
            visible_sections: string(),
            visible_tab: string(),
        }),
    },
    ["VIEW_CRM_LISTING_PERFORMANCE"]: {
        pattern: ["crm", "listing", param("listingId"), "performance"],
        queryParamsCodec: noQueryParamsCodec,
    },
    ["VIEW_CRM_LISTINGS"]: {
        pattern: ["crm", "listings"],
        queryParamsCodec: noQueryParamsCodec,
    },
    ["VIEW_CRM_PARTY"]: {
        pattern: ["crm", "parties", param("partyId")],
        queryParamsCodec: noQueryParamsCodec,
    },
    ["VIEW_CRM_ENQUIRY"]: {
        pattern: ["crm", "enquiries", param("enquiryId")],
        queryParamsCodec: noQueryParamsCodec,
    },
    ["VIEW_CRM_ADD_ENQUIRY"]: {
        pattern: ["crm", "add-enquiry"],
        queryParamsCodec: noQueryParamsCodec,
    },
    ["VIEW_CRM_LEGAL_CASE"]: {
        pattern: ["crm", "legal", "cases",  param("caseId")],
        queryParamsCodec: partial({
            visible_tab: string(),
        }),
        blocksCodec: arrayCodec(
            union([
                required({
                    type: literal("case_details"),
                    meta: partial({
                        visible_sections: string(),
                    }),
                }),
                required({
                    type: literal("case_quotes"),
                    meta: required({}),
                }),
                required({
                    type: literal("case_overview"),
                    meta: required({}),
                }),
                required({
                    type: literal("case_contracts"),
                    meta: partial({
                        visible_sections: string(),
                    }),
                }),
                required({
                    type: literal("case_title_checks"),
                    meta: partial({
                        openTitleCheckPropertyId: uuid(),
                    }),
                }),
                required({
                    type: literal("charges_and_restrictions"),
                    meta: required({}),
                }),
                CaseRoadblocksBlockRouteParamsCodec
            ])
        ),
    },
    ["VIEW_CRM_LEGAL_NEW_CASES"]: {
        pattern: ["crm", "legal", "cases"],
        queryParamsCodec: noQueryParamsCodec,
    },
    ["VIEW_CRM_LEGAL_TITLE_CHECKS"]: {
        pattern: ["crm", "legal", "title-checks"],
        queryParamsCodec: noQueryParamsCodec,
    },
    ["VIEW_CRM_LEGAL_INBOX"]: {
        pattern: ["crm", "legal", "inbox"],
        queryParamsCodec: noQueryParamsCodec,
    },
    ["VIEW_CRM_LEGAL_REPORTS"]: {
        pattern: ["crm", "legal", "reports"],
        queryParamsCodec: noQueryParamsCodec,
    },
    ["VIEW_CRM_PROBATE_HELPLINE_CALLBACKS"]: {
        pattern: ["crm", "probate", "probate-helpline", "callbacks"],
        queryParamsCodec: noQueryParamsCodec,
    },
    ["VIEW_CRM_INDEX"]: {
        pattern: ["crm"],
        queryParamsCodec: noQueryParamsCodec,
    },
    ["VIEW_PROBATE_PANEL_ENQUIRY_PAGE"]: {
        pattern: ["probate", "enquiry"],
        queryParamsCodec: noQueryParamsCodec,
    },
    ["VIEW_PROBATE_PROPERTY_REPORT_PAGE"]: {
        pattern: ["probate", "property-report"],
        queryParamsCodec: partial({
            introducer_id: string(),
        }),
    },
    ["VIEW_PROBATE_QUOTE_PAGE"]: {
        pattern: ["probate", "quote", param("quoteId")],
        queryParamsCodec: noQueryParamsCodec,
    },
    ["VIEW_PROBATE_INTERESTED_PAGE"]: {
        pattern: ["probate", "quote", param("quoteId"), "interested"],
        queryParamsCodec: noQueryParamsCodec,
    },
    ["VIEW_PROBATE_GDPR_PAGE"]: {
        pattern: ["probate", "gdpr"],
        queryParamsCodec: noQueryParamsCodec,
    },
    ["VIEW_PROBATE_TNCS_PAGE"]: {
        pattern: ["probate", "tncs"],
        queryParamsCodec: noQueryParamsCodec,
    },
    ["VIEW_BUYER_ENQUIRY"]: {
        pattern: ["buyer", "enquiries", param("enquiryId")],
        queryParamsCodec: noQueryParamsCodec,
    },
    ["VIEW_CLIENT_CASE"]: {
        pattern: ["client", "cases", param("caseId")],
        queryParamsCodec: partial({
            errorCode: string(),
            userCaseId: string(),
        }),
    },
    ["VIEW_AUTH_2FA"]: {
        pattern: ["auth", "two-factor",],
        queryParamsCodec: Redirect,
    },
    ["VIEW_BUYER_EMAIL_VERIFICATION_CONFIRMATION"]: {
        pattern: ["buyer", "email-confirmation",],
        queryParamsCodec: noQueryParamsCodec,
    },
    ["VIEW_MAGIC_LINK_RESEND"]: {
        pattern: ["auth", "resend-link"],
        queryParamsCodec: Redirect,
    },
    ["VIEW_MAGIC_LINK_RESENT"]: {
        pattern: ["auth", "resent-link"],
        queryParamsCodec: Redirect,
    },
    ["VIEW_CRM_TRIAGE"]: {
        pattern: ["crm", "legal", "triage"],
        queryParamsCodec: noQueryParamsCodec,
    },
    ["VIEW_CRM_LEGAL_INTRODUCERS"]: {
        pattern: ["crm", "legal", "introducers"],
        queryParamsCodec: noQueryParamsCodec,
    },
    ["VIEW_CRM_LEGAL_CASE_AUTO_ASSIGNMENT"]: {
        pattern: ["crm", "legal", "case-auto-assignment"],
        queryParamsCodec: noQueryParamsCodec,
    },
    ["VIEW_CRM_LEGAL_CLIENT_COMPANY_PARTNERS"]: {
        pattern: ["crm", "legal", "companies", "partners"],
        queryParamsCodec: noQueryParamsCodec,
    },
    ["VIEW_CRM_LEGAL_QUOTE_RATES"]: {
        pattern: ["crm", "legal", "quote-rates"],
        queryParamsCodec: noQueryParamsCodec,
    },
    ["VIEW_CRM_LEGAL_MARKETING_CAMPAIGNS"]: {
        pattern: ["crm", "legal", "marketing-campaigns"],
        queryParamsCodec: noQueryParamsCodec,
    },
    ["VIEW_PUBLIC_LEGAL_QUOTE"]: {
        pattern: ["legal", "quote"],
        queryParamsCodec: partial({
            introducer_id: string(),
            view: string(),
        }),
    },
    
};

export type TPaths = typeof paths;