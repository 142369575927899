import * as Enviroment from "./models/Enviroment";

export const sailHomesBrandConstants = (env: Enviroment.T) => ({
    homeURL: `https://${Enviroment.getDomainNameFromEnv(env)}/`,
    contact: {
        emailURL: "mailto:hello@sailhomes.co.uk?subject=Sail%20Homes%20Listing%20Enquiry",
        phoneURL: "tel:+448081965200",
        phoneText: "0808 196 5200",
    },
    faqURL: `https://www.${Enviroment.getDomainNameFromEnv(env)}/faq`,
    pricingURL: `https://www.${Enviroment.getDomainNameFromEnv(env)}/pricing`,
    forSale: `https://www.${Enviroment.getDomainNameFromEnv(env)}/listings`,
    privacyURL: `https://www.${Enviroment.getDomainNameFromEnv(env)}/privacy-policy`,
    valuationURL: `https://www.${Enviroment.getDomainNameFromEnv(env)}/valuation/your-details`,
    logoutUrl: `https://auth.${Enviroment.getDomainNameFromEnv(env)}/web/logout`,
    tncsUrl: `https://www.${Enviroment.getDomainNameFromEnv(env)}/terms-conditions`,
    complaintsUrl: `https://www.${Enviroment.getDomainNameFromEnv(env)}/pages/complaints-procedure`,
}) as const;

export const sailLegalBrandConstants = () => ({
    legalCSNumber: "0808 196 7007",
})

export type TCookiePreference = "all" | "none";
export const cookiePreferenceCookieName = "sailLegalClientCookiePreference";

// VAT CONSTANTS
// 20% - Current as of 2021-11-22
export const currentStandardVatRate = 0.20;
export const multipleToIncludeVAT = 1.2;
