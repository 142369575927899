import { array, TArrayCodec } from "../../../shared/src/codecs/types/array";
import { boolean, TBooleanCodec } from "../../../shared/src/codecs/types/boolean";
import { required, TRequiredCodec } from "../../../shared/src/codecs/types/required";
import { string, TAnyStringCodec } from "../../../shared/src/codecs/types/string";
import { CasesSearchForm, TCasesSearchFormCodec } from "../form/CasesSearchForm";
import { TEmailCompositionFormCodec, EmailCompositionForm } from "../form/EmailCompositionForm";
import { DetailedEmailForm, TDetailedEmailFormCodec } from "../form/TriageForm";

type TGlobalLegalPartial = TRequiredCodec<{
    cases_search: TCasesSearchFormCodec;
    compose_email: TEmailCompositionFormCodec;
    compose_email_is_open: TBooleanCodec;
    attached_email: TDetailedEmailFormCodec;
    additional_compose_email_addresses: TArrayCodec<TAnyStringCodec>;
}>;

export const GlobalLegalPartial: TGlobalLegalPartial = required({
    cases_search: CasesSearchForm,
    compose_email: EmailCompositionForm,
    compose_email_is_open: boolean(),
    attached_email: DetailedEmailForm,
    additional_compose_email_addresses: array(string()), 
});
export type TGlobalLegalPartialCodec = typeof GlobalLegalPartial;