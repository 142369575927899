import { array, nonEmptyArray } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import { TCodec, TTypeOfCodec } from "../../shared/src/codecs/codec";
import { literal } from "../../shared/src/codecs/types/literal";
import { union } from "../../shared/src/codecs/types/union";
import * as util from "../../shared/src/util";

export type lenderDetails = {
    allowsDualRep: boolean,
}

function createLenderDictionaryMap<T extends { [name: string]: lenderDetails }>(r: T)  {
    return r;
};
export const CaseLenderDictionary = createLenderDictionaryMap({
    unknown: {
        allowsDualRep: false,
    },
    atom: {
        allowsDualRep: false,
    },
    bank_of_ireland: {
        allowsDualRep: true,
    },
    bank_of_scotland: {
        allowsDualRep: false,
    },
    barclays: {
        allowsDualRep: false,
    },
    birmingham_midshires: {
        allowsDualRep: false,
    },
    bluestone: {
        allowsDualRep: false,
    },
    buckinghamshire: {
        allowsDualRep: false,
    },
    cheltenham_and_gloucester: {
        allowsDualRep: false,
    },
    clydesdale_and_yorkshire: {
        allowsDualRep: false,
    },
    co_op: {
        allowsDualRep: false,
    },
    coventry_building_society: {
        allowsDualRep: false,
    },
    cynergy: {
        allowsDualRep: false,
    },
    danske: {
        allowsDualRep: false,
    },
    dudley: {
        allowsDualRep: false,
    },
    first_direct: {
        allowsDualRep: false,
    },
    generation_home: {
        allowsDualRep: false,
    },
    halifax: {
        allowsDualRep: false,
    },
    help_to_buy_isa: {
        allowsDualRep: false,
    },
    hinckley_and_rugby: {
        allowsDualRep: false,
    },
    hsbc: {
        allowsDualRep: false,
    },
    ipswich: {
        allowsDualRep: false,
    },
    just_retirement_money: {
        allowsDualRep: false,
    },
    kensington: {
        allowsDualRep: false,
    },
    l_and_g: {
        allowsDualRep: false,
    },
    leeds_building_society: {
        allowsDualRep: false,
    },
    leek: {
        allowsDualRep: false,
    },
    livemore_capital: {
        allowsDualRep: false,
    },
    lloyds: {
        allowsDualRep: false,
    },
    lms: {
        allowsDualRep: false,
    },
    marsden: {
        allowsDualRep: false,
    },
    melton_mowbray: {
        allowsDualRep: false,
    },
    monmouthshire: {
        allowsDualRep: false,
    },
    the_mortgage_lender: {
        allowsDualRep: false,
    },
    the_mortgage_works: {
        allowsDualRep: false,
    },
    national_counties_and_family_building_society: {
        allowsDualRep: false,
    },
    nationwide: {
        allowsDualRep: false,
    },
    natwest: {
        allowsDualRep: true,
    },
    newcastle_building_society: {
        allowsDualRep: false,
    },
    pepper_money: {
        allowsDualRep: false,
    },
    precise_mortgages: {
        allowsDualRep: false,
    },
    royal_bank_of_scotland: {
        allowsDualRep: true,
    },
    santander: {
        allowsDualRep: false,
    },
    scottish_widdows: {
        allowsDualRep: false,
    },
    skipton: {
        allowsDualRep: false,
    },
    stafford_railway: {
        allowsDualRep: false,
    },
    suffolk: {
        allowsDualRep: false,
    },
    tipton_and_coseley: {
        allowsDualRep: false,
    },
    tsb: {
        allowsDualRep: true,
    },
    west_bromwich: {
        allowsDualRep: false,
    },
    yorkshire: {
        allowsDualRep: false,
    },
});

const lenderEnumValues = pipe(
    Object.keys(CaseLenderDictionary), // Using Object.keys to maintain order
    array.map((v) => literal(v))
) as nonEmptyArray.NonEmptyArray< // Couldn't figure out how to do this without an as, if someone is smarter go for it
    TCodec<
        "LiteralCodec",
        keyof typeof CaseLenderDictionary,
        keyof typeof CaseLenderDictionary,
        keyof typeof CaseLenderDictionary
    >
>;

export const CasesLenderNameOnPanel1 = union(
    lenderEnumValues
);

export type TCasesLenderNameOnPanel1Codec = typeof CasesLenderNameOnPanel1;
export type TCasesLenderNameOnPanel1 = TTypeOfCodec<typeof CasesLenderNameOnPanel1>;

export const CasesLenderNameOnPanel1_displayString = (lender: TCasesLenderNameOnPanel1): string => {
    switch (lender) {
        case "unknown":
            return "Unknown";
        case "atom":
            return "Atom";
        case "bank_of_ireland":
            return "Bank of Ireland";
        case "bank_of_scotland":
            return "Bank of Scotland";
        case "barclays":
            return "Barclays";
        case "birmingham_midshires":
            return "Birmingham Midshires";
        case "bluestone":
            return "Bluestone";
        case "buckinghamshire":    
            return "Buckinghamshire";
        case "cheltenham_and_gloucester":
            return "Cheltenham and Gloucester";
        case "clydesdale_and_yorkshire":    
            return "Clydesdale & Yorkshire";
        case "co_op":
            return "Co-op";
        case "coventry_building_society":
            return "Coventry BS/Godiva";
        case "cynergy":    
            return "Cynergy";
        case "danske":    
            return "Danske";
        case "dudley":    
            return "Dudley";
        case "first_direct":
            return "First Direct";
        case "generation_home":
            return "Generation Home";
        case "halifax":
            return "Halifix";
        case "help_to_buy_isa":
            return "Help to Buy ISA";
        case "hinckley_and_rugby":    
            return "Hinckley & Rugby";
        case "hsbc":
            return "HSBC";
        case "ipswich":
            return "Ipswich Building Society";
        case "just_retirement_money":
            return "Just Retirement Money";
        case "kensington":
            return "Kensington";
        case "l_and_g":
            return "L&G";
        case "leeds_building_society":    
            return "Leeds Building Society";
        case "leek":    
            return "Leek";
        case "livemore_capital":    
            return "LiveMore Capital";
        case "lloyds":
            return "Lloyds";
        case "lms":
            return "LMS";
        case "marsden":    
            return "Marsden";
        case "melton_mowbray":    
            return "Melton Mowbray";
        case "monmouthshire":    
            return "Monmouthshire";
        case "the_mortgage_lender":    
            return "The Mortgage Lender";
        case "the_mortgage_works":
            return "The Mortgage Works";
        case "national_counties_and_family_building_society":    
            return "National Counties & Family Building Society";
        case "nationwide":
            return "Nationwide";
        case "natwest":
            return "Natwest";
        case "newcastle_building_society":    
            return "Newcastle Building Society";
        case "pepper_money":
            return "Pepper Money";
        case "precise_mortgages":
            return "Precise Mortgages";
        case "royal_bank_of_scotland":
            return "Royal Bank of Scotland";
        case "santander":
            return "Santander";
        case "scottish_widdows":
            return "Scottish Widows";
        case "skipton":
            return "Skipton Building Society";
        case "stafford_railway":    
            return "Stafford Railway";
        case "suffolk":
            return "Suffolk Building Society";
        case "tipton_and_coseley":    
            return "Tipton & Coseley";
        case "tsb":
            return "TSB";
        case "west_bromwich":
            return "West Bromwich";
        case "yorkshire":
            return "Yorkshire Building Society";
        default:
            return util.requireExhaustive(lender);
    }
};