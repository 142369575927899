import React from "react";

export default (props: React.SVGAttributes<SVGElement>) => <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g clipPath="url(#clip0_5307:25131)">
<path d="M35.6786 8.32145C31.7311 4.37396 26.4826 2.19995 20.9 2.19995C15.3174 2.19995 10.069 4.37396 6.1215 8.32145C2.17401 12.2689 0 17.5174 0 23.0999C0 28.6825 2.17401 33.931 6.1215 37.8785C10.069 41.826 15.3174 43.9999 20.9 43.9999C26.4826 43.9999 31.7311 41.826 35.6786 37.8785C39.6261 33.931 41.8 28.6825 41.8 23.0999C41.8 17.5174 39.6261 12.2689 35.6786 8.32145V8.32145ZM20.9 41.7999C10.5888 41.7999 2.2 33.4111 2.2 23.0999C2.2 12.7888 10.5888 4.39995 20.9 4.39995C31.2112 4.39995 39.6 12.7888 39.6 23.0999C39.6 33.4111 31.2112 41.7999 20.9 41.7999Z" fill="black"/>
<path d="M20.8998 39.5999C20.8997 39.5999 20.8998 39.5999 20.8998 39.5999C20.2923 39.5999 19.7998 39.1074 19.7998 38.4999V36.2999C19.7998 35.6925 20.2923 35.2 20.8998 35.2C21.5074 35.2 21.9998 35.6925 21.9998 36.2999V38.4999C21.9998 39.1074 21.5072 39.5999 20.8998 39.5999Z" fill="black"/>
<path d="M20.8998 33C20.2923 33 19.7998 32.5076 19.7998 31.9V9.90005C19.7998 9.29257 20.2923 8.80005 20.8998 8.80005C21.5073 8.80005 21.9998 9.29257 21.9998 9.90005V31.9C21.9998 32.5076 21.5073 33 20.8998 33Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_5307:25131">
<rect width="44" height="44" fill="white"/>
</clipPath>
</defs>
</svg>
;
