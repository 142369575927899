import React from "react";

export default (props: React.SVGAttributes<SVGElement>) => <svg width="100%" height="100%" viewBox="0 0 25 25" version="1.1" style={{ fillRule: "evenodd", clipRule: "evenodd", strokeLinejoin: "round", strokeMiterlimit:2, display: "block"}} {...props}>
    <g transform="matrix(1.08679,0,-0.01897,1.08679,-0.286582,-0.541492)">
        <g>
            <path id="Color:" d="M19.188,3.375L17.031,3.375L17.031,4.813L19.188,4.813C19.981,4.813 20.625,5.457 20.625,6.25L20.625,20.625C20.625,21.419 19.981,22.063 19.188,22.063L4.813,22.063C4.019,22.063 3.375,21.419 3.375,20.625L3.375,6.25C3.375,5.457 4.019,4.813 4.813,4.813L6.969,4.813L6.969,3.375L4.813,3.375C3.225,3.375 1.938,4.662 1.938,6.25L1.938,20.625C1.938,22.213 3.225,23.5 4.813,23.5L19.188,23.5C20.775,23.5 22.063,22.213 22.063,20.625L22.063,6.25C22.063,4.662 20.775,3.375 19.188,3.375ZM6.497,13.982L11.455,18.941C11.605,19.09 11.805,19.154 12,19.144C12.195,19.154 12.395,19.09 12.545,18.941L17.503,13.982C17.785,13.701 17.785,13.246 17.503,12.965C17.223,12.685 16.767,12.685 16.486,12.965L12.719,16.734L12.719,1.219C12.719,0.821 12.397,0.5 12,0.5C11.603,0.5 11.281,0.821 11.281,1.219L11.281,16.734L7.513,12.965C7.233,12.685 6.777,12.685 6.497,12.965C6.216,13.246 6.216,13.701 6.497,13.982Z"/>
        </g>
    </g>
</svg>
;
