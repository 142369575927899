import React from "react";

type TFrontTitleMinor = {};

class FrontTitleMinor extends React.Component<React.PropsWithChildren<TFrontTitleMinor>> {
    public render (): JSX.Element {
        return (
            <div className="front-title-minor">{this.props.children}</div>
        );
    }
}

export default FrontTitleMinor;
