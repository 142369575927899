import * as t from "io-ts";
import * as LegalEmail3 from "./LegalEmail3";
// NB when refactoring to new codecs, see codecModels/LegalEmail

export const codec = t.type({
    ...LegalEmail3.codec.props,
    summary: t.string,
});

export type T = t.TypeOf<typeof codec>;
