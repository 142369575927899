import React from "react";

export default (props: React.SVGAttributes<SVGElement>) => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g clipPath="url(#clip0_6962_34229)">
<path d="M19 19L19 5C19 3.9 18.1 3 17 3L7 3C5.9 3 5 3.9 5 5L5 19C5 20.1 5.9 21 7 21L17 21C18.1 21 19 20.1 19 19ZM7 19L7 5L17 5L17 19L7 19Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_6962_34229">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>
;
