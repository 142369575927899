import React from "react";
import { THexColor, TPercentageSize, TPixelSize, TPixelSizeShortHand } from "../../models/StringLiterals";

type TTextProps = {
    display?: "flex" | "inline-flex";
    width?: TPixelSize | TPercentageSize;
    size?: number;
    lineHeight?: number;
    color?: THexColor;
    align?: "left" | "right" | "center";
    weight?: number;
    margin?: TPixelSizeShortHand;
    letterSpacing?: TPixelSize;
};

export const Text = (props: React.PropsWithChildren<TTextProps>): JSX.Element => {
    return (
        <div style={{ 
            display: props.display,
            width: props.width,
            color: props.color,
            fontSize: props.size,
            lineHeight: props.lineHeight,
            textAlign: props.align,
            fontWeight: props.weight,
            margin: props.margin,
            letterSpacing: props.letterSpacing,
        }}>
            {props.children}
        </div>
    );
};
