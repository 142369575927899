import * as Function from "../Function";
import * as Error from "../Error";
import * as ErrorCode from "../ErrorCode";
import { option } from "fp-ts/lib";
import { none } from "fp-ts/lib/Option";

export const nonEmptyArray: Function.TValidationFunction = (value: unknown): option.Option<Error.TValidationError> =>
    (
        typeof value === "undefined"
        || (Array.isArray(value) && value.length > 0)
    ) ?
    none
    : option.some([[ErrorCode.validationErrorCodeConstants.NON_EMPTY_ARRAY_VALIDATION]]);
