import { required } from "../../shared/src/codecs/types/required";
import { uuid } from "../../shared/src/codecs/types/uuid";
import { string } from "../../shared/src/codecs/types/string";
import { dateTime } from "../../shared/src/codecs/types/dateTime";
import { TTypeOfCodec } from "../../shared/src/codecs/codec";
import { union } from "../../shared/src/codecs/types/union";
import { nullCodec } from "../../shared/src/codecs/types/nullCodec";
import { boolean } from "../../shared/src/codecs/types/boolean";
import { literal } from "../../shared/src/codecs/types/literal";
import { NormalisedRestrictionFinancialType, NormalisedRestrictionNonFinancialType } from "./LandRegistry";
import { positiveInteger } from "../../shared/src/codecs/types/positiveInteger";
import { TitleEntrySection } from "./TitleEntrySection";

export const CaseTitleRestrictionType = union([
    literal("unknown"),
    ...NormalisedRestrictionFinancialType.payload,
    ...NormalisedRestrictionNonFinancialType.payload,
]);

export const CaseTitleRestriction1 = required({
    id: uuid(),
    cases_id: uuid(),
    type: CaseTitleRestrictionType,
    cases_charges_id: union([nullCodec(), uuid()]),
    restriction_holder: string(),
    cases_properties_id: union([nullCodec(), uuid()]),
    section: TitleEntrySection,
    restriction_order: union([nullCodec(), positiveInteger()]),
    restriction_text: string(),
});
export type TCaseTitleRestriction1Codec = typeof CaseTitleRestriction1;
export type TCaseTitleRestriction1 = TTypeOfCodec<TCaseTitleRestriction1Codec>;

export const CaseTitleRestriction2 = required({
    cases_id: uuid(),
});
export type TCaseTitleRestriction2Codec = typeof CaseTitleRestriction2;
export type TCaseTitleRestriction2 = TTypeOfCodec<TCaseTitleRestriction2Codec>;
