import * as t from "io-ts";
import * as ViewingType from "./ViewingType";
import { array, option, ord } from "fp-ts/lib";
import * as Enquiry2 from "./Enquiry2";
import * as PartyC8 from "./Party8";
import * as ViewingStatus1 from "../models/ViewingStatus1";
import { DateTime } from "luxon";
import { Ord as ordDate } from "fp-ts/lib/Date";
import { pipe } from "fp-ts/lib/function";

export type TSimpleViewing = {
    date: Date;
    status: ViewingStatus1.T;
    viewing_type: ViewingType.T;
    shared_with_seller_at: string | null;
    feedback: string | null;
    agent: string | null;
    party: PartyC8.T;
};

export type TSimpleViewingGroupedByDate = {
    dateFormattedYMD: string;
    viewings: Array<TSimpleViewing>;
};

export type TSortDateDirection = "asc" | "desc";
export type TPastUpcomingAll = "past" | "upcoming" | "all";

export const codec = t.type({
    id: t.string,
    enquiry_id: t.string,
    listing_id: t.string,
    status: ViewingStatus1.codec,
    viewing_type: ViewingType.codec,
    requested_times: t.array(t.string),
    confirmed_time: t.union([t.string, t.null]),
    agent: t.string,
    feedback: t.string,
    shared_with_seller_at: t.union([t.string, t.null]),
    enquiry: Enquiry2.codec,
    party: PartyC8.codec,
});

export type T = t.TypeOf<typeof codec>;

export const newDefault = (): T => ({
    id: "",
    enquiry_id: "",
    listing_id: "",
    status: "pending",
    viewing_type: "in_person",
    requested_times: [],
    confirmed_time: null,
    agent: "",
    feedback: "",
    shared_with_seller_at: null,
    enquiry: Enquiry2.newDefault(),
    party: PartyC8.newDefault(),
});

export const sortToSimpleViewingArray = (viewings: Array<T>, sortDirection: TSortDateDirection, returnPastUpcomingOrAllViewings: TPastUpcomingAll): Array<TSimpleViewing> =>
     pipe(
        viewings,
        // Get all confirmed & unconfirmed viewings and map them to a simpler structure
        array.map<T, Array<TSimpleViewing>>((viewing) =>
            viewing.status === "confirmed" && viewing.confirmed_time
                ? [{
                    date: new Date(viewing.confirmed_time),
                    status: viewing.status,
                    viewing_type: viewing.viewing_type,
                    shared_with_seller_at: viewing.shared_with_seller_at,
                    feedback: viewing.feedback,
                    agent: viewing.agent,
                    party: viewing.party,
                }]
                :  pipe(
                    viewing.requested_times,
                    array.map((requestedTime) => ({
                        date: new Date(requestedTime),
                        status: viewing.status,
                        viewing_type: viewing.viewing_type,
                        shared_with_seller_at: viewing.shared_with_seller_at,
                        feedback: viewing.feedback,
                        agent: viewing.agent,
                        party: viewing.party,
                    })),
                )
        ),
        array.flatten,
        returnPastUpcomingOrAllViewings === "upcoming" ? array.filter((viewing) => DateTime.fromJSDate(viewing.date, {zone: "Europe/London"}).toUTC() >= DateTime.local().toUTC())
            : returnPastUpcomingOrAllViewings === "past" ? array.filter((viewing) => DateTime.fromJSDate(viewing.date, {zone: "Europe/London"}).toUTC() <= DateTime.local().toUTC())
            : (flattenedViewings): Array<TSimpleViewing> => flattenedViewings,
        // Order the viewings by date asc or desc
        array.sortBy([ pipe(
            ordDate,
            ord.contramap((viewing: TSimpleViewing) => viewing.date)
        )]),
        (ascViewings) => sortDirection === "asc" ? ascViewings : array.reverse(ascViewings),
    );

export const getSimpleViewingArrayGroupedByDate = (viewings: Array<T>, sortDirection: TSortDateDirection, returnPastUpcomingOrAllViewings: TPastUpcomingAll): Array<TSimpleViewingGroupedByDate> =>
     pipe(
        sortToSimpleViewingArray(viewings, sortDirection, returnPastUpcomingOrAllViewings),
        // Group the viewings by their common calendar date so that we have all viewings on a single date grouped
        array.reduce<TSimpleViewing, Array<TSimpleViewingGroupedByDate>>([], (a, viewing) =>
             pipe(
                a,
                array.findIndex((group) => group.dateFormattedYMD === DateTime.fromJSDate(viewing.date, {zone: "Europe/London"}).toFormat("yyyy-MM-dd")),
                option.fold(
                    () => [
                        ...a,
                        {
                            dateFormattedYMD: DateTime.fromJSDate(viewing.date, {zone: "Europe/London"}).toFormat("yyyy-MM-dd"),
                            viewings: [viewing],
                        },
                    ],
                    (groupIndex) => {
                        a[groupIndex].viewings.push(viewing);
                        return a;
                    }
                )
            )
        ),
    );
