import * as FirstPartyFetchResponse from "../../../../domain/models/FirstPartyFetchResponse";
import * as TForm from "../../models/TForm";
import { TState } from "./lensBaseTypes";
import { reduceDataToStateUpdate } from "./reduceDataToStateUpdate";
import { set } from "./set";
import { TSetState } from "../../state/TSetState";

interface IResolveFormResponse<S> {
    // 5 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1],
        K3 extends keyof S[K1][K2],
        K4 extends keyof S[K1][K2][K3],
        K5 extends keyof S[K1][K2][K3][K4]
    >
    (
        path: [K1, K2, K3, K4, K5],
    ): (data: FirstPartyFetchResponse.T<unknown>) => void;
    // 4 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1],
        K3 extends keyof S[K1][K2],
        K4 extends keyof S[K1][K2][K3]
    >
    (
        path: [K1, K2, K3, K4],
    ): (data: FirstPartyFetchResponse.T<unknown>) => void;
    // 3 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1],
        K3 extends keyof S[K1][K2]
    >
    (
        path: [K1, K2, K3],
    ): (data: FirstPartyFetchResponse.T<unknown>) => void;
    // 2 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1]
    >
    (
        path: [K1, K2],
    ): (data: FirstPartyFetchResponse.T<unknown>) => void;
    // 1 level lens path guard
    <K1 extends keyof S>
    (
        path: [K1],
    ): (data: FirstPartyFetchResponse.T<unknown>) => void;
}

export const resolveFormResponse = <T>(setState: TSetState): IResolveFormResponse<TState> =>
    // There is no way to create paramater overloads here so we set to any
    (lensPath: any) => // eslint-disable-line
        (data: FirstPartyFetchResponse.T<unknown> ) => {
            reduceDataToStateUpdate<FirstPartyFetchResponse.T<unknown>>(setState)(
                set<FirstPartyFetchResponse.T<unknown>>()(
                    lensPath,
                    /* eslint-disable */
                    // The type is always expected to be never for the form, since we have abstraced the lens scoping,
                    // which is untrue
                    // @ts-ignore
                    TForm.resolveStatus<T>()
                    /* eslint-enable */
                ),
                set<FirstPartyFetchResponse.T<unknown>>()(
                    lensPath,
                    /* eslint-disable */
                    // The type is always expected to be never for the form, since we have abstraced the lens scoping,
                    // which is untrue
                    // @ts-ignore
                    TForm.resolveErrors<T>()
                    /* eslint-enable */
                )
            )(data);
        };
