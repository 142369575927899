import { TTypeOfCodec } from "../../../shared/src/codecs/codec";
import { boolean, TAnyBooleanCodec } from "../../../shared/src/codecs/types/boolean";
import { email, TAnyEmailCodec } from "../../../shared/src/codecs/types/email";
import { form, TFormCodec } from "../../../shared/src/codecs/types/form";
import { literal, TLiteralCodec } from "../../../shared/src/codecs/types/literal";
import { password, TAnyPasswordCodec } from "../../../shared/src/codecs/types/password";
import { phoneNumber, TAnyPhoneNumberCodec } from "../../../shared/src/codecs/types/phoneNumber";
import { required, TRequiredCodec } from "../../../shared/src/codecs/types/required";
import { string, TAnyStringCodec } from "../../../shared/src/codecs/types/string";
import { TUnionCodec, union } from "../../../shared/src/codecs/types/union";

export const CreateStaffUserForm: TFormCodec<
    TRequiredCodec<{
        email: TAnyEmailCodec,
        first_name: TAnyStringCodec,
        last_name: TAnyStringCodec,
        password: TAnyPasswordCodec,
        phone_number: TAnyPhoneNumberCodec,
        staff_type: TUnionCodec<[
            TLiteralCodec<"sail_homes_staff">,
            TLiteralCodec<"sail_legal_non_authoriser_staff">,
            TLiteralCodec<"sail_legal_authoriser_staff">,
            TLiteralCodec<"sail_homes_admin">,
            TLiteralCodec<"sail_legal_admin">,
            TLiteralCodec<"sail_probate_admin">,
        ]>,
        gmail_integration_setting: TUnionCodec<[
            TLiteralCodec<"attempt_if_sail_legal">,
            TLiteralCodec<"do_not_attempt">,
        ]>
    }>,
    TRequiredCodec<{}>
> = 
    form(
        required({
            email: email(),
            first_name: string(),
            last_name: string(),
            password: password(),
            phone_number: phoneNumber(),
            staff_type: union([
                literal("sail_homes_staff"),
                literal("sail_legal_non_authoriser_staff"),
                literal("sail_legal_authoriser_staff"),
                literal("sail_homes_admin"),
                literal("sail_legal_admin"),
                literal("sail_probate_admin"),
            ]),
            gmail_integration_setting: union([
                literal("attempt_if_sail_legal"),
                literal("do_not_attempt"),
            ]),
        }),
        required({}),
    );

export type TCreateStaffUserFormCodec = typeof CreateStaffUserForm;
export type TCreateStaffUserForm = TTypeOfCodec<TCreateStaffUserFormCodec>;