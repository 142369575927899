import { TTypeOfCodec } from "../../shared/src/codecs/codec";
import { date } from "../../shared/src/codecs/types/date";
import { dateTimeOrNullAsBoolean } from "../../shared/src/codecs/types/dateTimeOrNullAsBoolean";
import { literal } from "../../shared/src/codecs/types/literal";
import { nullCodec } from "../../shared/src/codecs/types/nullCodec";
import { required } from "../../shared/src/codecs/types/required";
import { union } from "../../shared/src/codecs/types/union";
import { uuid } from "../../shared/src/codecs/types/uuid";

const ListingSignMovementType = union([
    literal("for_sale"),
    literal("sold"),
    literal("collection")
]);
export type TListingSignMovementType = TTypeOfCodec<typeof ListingSignMovementType>;

export const ListingSignMovementEditParts = required({
    type: ListingSignMovementType,
    expected_install_date: union([nullCodec(), date()]),
    confirmed_install: dateTimeOrNullAsBoolean(),
});
export type TListingSignMovementEditPartsCodec = typeof ListingSignMovementEditParts;

export const ListingSignMovementCreateParts = required({
    listing_id: uuid(),
    type: ListingSignMovementType,
    expected_install_date: union([nullCodec(), date()]),
});
export type TListingSignMovementCreatePartsCodec = typeof ListingSignMovementCreateParts;
