import * as t from "io-ts";
import * as util from "../../shared/src/util";

export const codec = t.union([t.literal("guide_price"), t.literal("offers_over"), t.literal("fixed_price"), t.literal("oiro")]);

export const values = util.typeValuesFromCodec(codec.types as Array<t.LiteralC<T>>);

type T = t.TypeOf<typeof codec>;

export const toString = (s: T): string => {
    switch(s) {
        case "guide_price":
            return "Guide Price";
        case "offers_over":
            return "Offers Over";
        case "fixed_price":
            return "Fixed Price";
        case "oiro":
            return "Offers In Region Of";
        default:
            return util.requireExhaustive(s);
    }
};
