import { TTypeOfCodec } from "../../shared/src/codecs/codec";
import { literal } from "../../shared/src/codecs/types/literal";
import { union } from "../../shared/src/codecs/types/union";

export const CasesBankAccountVerificationStatus1 = union([
    literal("unsubmitted"),
    literal("pending"),
    literal("approved"),
    literal("rejected"),
    literal("not_verified_as_will_not_be_used"),
]);
export type TCasesBankAccountVerificationStatus1 = TTypeOfCodec<typeof CasesBankAccountVerificationStatus1>;

export const CasesBankAccountVerificationStatus2 = union([
    literal("pending"),
    literal("approved"),
    literal("rejected"),
    literal("not_verified_as_will_not_be_used"),
]);

export type TCasesBankAccountVerificationStatus2 = TTypeOfCodec<typeof CasesBankAccountVerificationStatus2>;