import React from "react";

export default (props: React.SVGAttributes<SVGElement>) => <svg style={{ display: "block" }} viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
    <title>checkbox_selected</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="checkbox_selected">
            <path d="M8,13 C8.89654229,13 10.563209,13 13,13 C13,10.6110735 13,8.94440687 13,8 C13,7.1590025 13,5.49233584 13,3 C10.6666183,3 8.99995167,3 8,3 C7.04556706,3 5.37890039,3 3,3 C3,5.44681711 3,7.11348378 3,8 C3,8.92017521 3,10.5868419 3,13 C5.41255938,13 7.07922605,13 8,13 Z" id="Oval" fill="#35B8BE" fillRule="nonzero"></path>
            <rect id="Rectangle" stroke="#C4C4C4" x="0.5" y="0.5" width="15" height="15"></rect>
        </g>
    </g>
</svg>;
