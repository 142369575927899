import { overload } from "../../../shared/src/codecs/types/overload";
import { required } from "../../../shared/src/codecs/types/required";
import { string } from "../../../shared/src/codecs/types/string";
import { array as codecArray } from "../../../shared/src/codecs/types/array";
import { array, ord } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import { uuid } from "../../../shared/src/codecs/types/uuid";
import { TUsersIdCheckImageType, UsersIdCheckImageType } from "../UsersIdCheckImage";
import { Ord } from "fp-ts/number";

export const UsersIdChecksImageArray = overload(
    codecArray(string()),
    required({
        id_check_images: codecArray(
            required({
                id: uuid(),
                type: UsersIdCheckImageType,
            }),
        )
    }),
    ({id_check_images}) => pipe(
        id_check_images,
        array.sortBy([ord.contramap((idCheckImage: {type: TUsersIdCheckImageType}) => idCheckImage.type === "document" ? 0 : 1)(Ord)]),
        array.map(({id}) =>
            `https://api.${process.env.SAIL_LEGAL_DOMAIN_NAME}/web/user-id-check-image/${id}`
        ),
    )
);

export const UsersIdChecksImageString = overload(
    string(),
    required({
        id: uuid(),
    }),
    ({id}) => `https://api.${process.env.SAIL_LEGAL_DOMAIN_NAME}/web/user-id-check-image/${id}`,
);
export type TUsersIdChecksImageStringCodec = typeof UsersIdChecksImageString;
