import { BehaviorSubject, Subject, merge } from "rxjs";
import * as TSetState from "./TSetState";
import * as TGetState from "./TGetState";
import { TDispatch } from "../state/TDispatch";
import { registerActions } from "./registerActions";
import { C as State } from "../state/State";
import { TAction } from "./actions/TAction";
import { Routes } from "./router/routerRoutes";
import { createActionFromUrl } from "./router/createActionFromUrl";
import { paths } from "./router/routerPaths";
import { updateState } from "./router/updateState";
import { isTRouteActionType } from "./router/isRouteActionType";
import { updateUrl } from "./router/updateUrl";
import { TActionsSet } from "./applyActions";

export const createStateObservable = (actions: TActionsSet): [BehaviorSubject<State<Routes>>, TDispatch] => {

    const defineStateObservableAndAccessorMutatorFunctions = (): [
        BehaviorSubject<State<Routes>>, // state$
        TGetState.TGetState,
        TSetState.TSetState,
        TDispatch
    ] => {
        let state = new State<Routes>(new Routes())
        const state$ = new BehaviorSubject<State<Routes>>(state);

        const setState: TSetState.TSetState = (callback) => {
            state = callback(state);
            state$.next(state);
        };

        const getState: TGetState.TGetState = () => ({...state});

        const dispatch = (action: TAction): void => {
            if (isTRouteActionType(action)) {
                setState(updateState(paths, action));
                updateUrl(state);
                window.scrollTo(0, 0);
            }
            dispatchedActions$.next(action);
        };

        return [state$, getState, setState, dispatch];
    }

    /**
     * Create the actions observable
     */
    const dispatchedActions$ = new Subject<TAction>();

    /**
     * Create the state and the getState, setState and dispatch functions
     */
    let [state$, getState, setState, dispatch] = defineStateObservableAndAccessorMutatorFunctions()

    /**
     * Register all of the actions
     */
    registerActions(actions, dispatchedActions$, setState, getState, dispatch);

    /**
     * Dispatch the initial route action
     */ 
    dispatch(createActionFromUrl(paths, window.location.pathname, window.location.search));

    /**
     * Dispatch the first load action
     */
    dispatchedActions$.next({
        type: "FIRST_LOAD",
        payload: null,
    });

    /**
     * On 'popstate' (e.g. user hits back button), sync the state to the url and dispatch associated action
     */
    window.addEventListener('popstate', () => {
        const action = createActionFromUrl(paths, window.location.pathname, window.location.search);
        setState(updateState(paths, action));
        dispatchedActions$.next(action);
    });

    return [state$, dispatch];
};
