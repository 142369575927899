import { TTypeOfCodec, TTypeOfNewDefault } from "../../../shared/src/codecs/codec";
import { formEditable, TFormEditableCodec } from "../../../shared/src/codecs/types/formEditable";
import { Offer2, TOffer2Codec } from "../Offer";

export const BuyerSubmitsNewOfferFormEditable: TFormEditableCodec<TOffer2Codec> = formEditable(Offer2);

export type TBuyerSubmitsNewOfferFormEditableCodec = typeof BuyerSubmitsNewOfferFormEditable;

export type TBuyerSubmitsNewOfferFormEditable = TTypeOfCodec<TBuyerSubmitsNewOfferFormEditableCodec>;

export type TBuyerSubmitsNewOfferFormEditableNewDefault = TTypeOfNewDefault<TBuyerSubmitsNewOfferFormEditableCodec>;
