import { array as fptsArray, option } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import { array } from "../../shared/src/codecs/types/array";
import { overload } from "../../shared/src/codecs/types/overload";
import { required } from "../../shared/src/codecs/types/required";
import { string } from "../../shared/src/codecs/types/string";
import { wrapArray } from "../../shared/src/codecs/types/wrapArray";
import { Tenure1 } from "./Tenure";
import { intersection } from "../../shared/src/codecs/types/intersection";
import { partial } from "../../shared/src/codecs/types/partial";
import { union } from "../../shared/src/codecs/types/union";
import { literal } from "../../shared/src/codecs/types/literal";
import { date } from "../../shared/src/codecs/types/date";
import { booleanFromString } from "../../shared/src/codecs/types/booleanFromString";
import { TAnyCodec, TCodec, TTypeOfCodec, TTypeOfNewDefault } from "../../shared/src/codecs/codec";
import { dateTime } from "../../shared/src/codecs/types/dateTime";
import { CasePropertyTitleClass } from "./CasePropertyTitleClass";
import { nullCodec } from "../../shared/src/codecs/types/nullCodec";
import { positiveInteger } from "../../shared/src/codecs/types/positiveInteger";
import { DateTime } from "luxon";
import { Ord } from "fp-ts/lib/number";
import { undefinedCodec } from "../../shared/src/codecs/types/undefined";
import { boolean } from "../../shared/src/codecs/types/boolean";
import { Eq } from "fp-ts/lib/string";

const LandRegistryMoneyString_pence = (value: string): number =>
    pipe(
        value.match(/^[^0-9]*([0-9.,]*)/) || [],
        (results) =>
            typeof results[1] !== "undefined"
                ? option.some(results[1])
                : option.none,
        option.map((value) => value.replace(/,/g, "")),
        option.map(Number),
        option.filter((value) => !isNaN(value)),
        option.map((value) => value * 100),
        option.getOrElse(() => 0),
    );

const getDateISOIfValid = (date: string): string | null =>
    DateTime.fromISO(date).isValid ? DateTime.fromISO(date).toISODate()
    : DateTime.fromFormat(date, "dd.MM.yyyy").isValid ? DateTime.fromFormat(date, "dd.MM.yyyy").toISODate()
    : null;

const normaliseEntryText = (value: string): string =>
    value.replace(/\n/g, " ");

const LandRegistrySearchPropertiesResponseSuccessResult = required({
    title_number: string(),
    tenure: Tenure1,
    sub_building_name: string(),
    building_name: string(),
    building_number: string(),
    street_name: string(),
    city: string(),
    postcode: string(),
    full_address: string(),
});
export type TLandRegistrySearchPropertiesResponseSuccessResult = TTypeOfCodec<typeof LandRegistrySearchPropertiesResponseSuccessResult>;

export const LandRegistrySearchPropertiesResponseSuccess = overload(
    required({
        _tag: literal("success"),
        results: array(LandRegistrySearchPropertiesResponseSuccessResult),
    }),
    required({
        "soap:Envelope": required({
            "soap:Body": required({
                "ns3:searchPropertiesResponse": required({
                    return: required({
                        "ns4:GatewayResponse": required({
                            "ns4:Results": required({
                                "ns4:Title": wrapArray(
                                    required({
                                        "ns4:TitleNumber": required({
                                            "_text": string(),
                                        }),
                                        "ns4:TenureInformation": required({
                                            "ns4:TenureTypeCode": required({
                                                "_text": string(),
                                            })
                                        }),
                                        "ns4:Address": intersection([
                                            partial({
                                                "ns4:BuildingNumber": required({
                                                    "_text": string(),
                                                }),
                                            }),
                                            partial({
                                                "ns4:BuildingName": required({
                                                    "_text": string(),
                                                }),
                                            }),
                                            partial({
                                                "ns4:SubBuildingName": required({
                                                    "_text": string(),
                                                }),
                                            }),
                                            partial({
                                                "ns4:StreetName": required({
                                                    "_text": string(),
                                                }),
                                            }),
                                            required({
                                                "ns4:CityName": required({
                                                    "_text": string(),
                                                }),
                                                "ns4:PostcodeZone": required({
                                                    "ns4:Postcode": required({
                                                        "_text": string(),
                                                    })
                                                })
                                            }),
                                        ])
                                    })
                                ),
                            })
                        })
                    })
                })
            })
        })
    }),
    (p) =>
        pipe(
            p["soap:Envelope"]["soap:Body"]["ns3:searchPropertiesResponse"].return["ns4:GatewayResponse"]["ns4:Results"]["ns4:Title"],
            fptsArray.map((result) => ({
                title_number: result["ns4:TitleNumber"]._text,
                tenure:
                    result["ns4:TenureInformation"]["ns4:TenureTypeCode"]._text === "10" ? "freehold" as "freehold"
                    : result["ns4:TenureInformation"]["ns4:TenureTypeCode"]._text === "20" ? "leasehold" as "leasehold"
                    : "unknown" as "unknown",
                sub_building_name: result["ns4:Address"]["ns4:SubBuildingName"]?._text || "",
                building_name: result["ns4:Address"]["ns4:BuildingName"]?._text || "",
                building_number: result["ns4:Address"]["ns4:BuildingNumber"]?._text || "",
                street_name: result["ns4:Address"]["ns4:StreetName"]?._text || "",
                city: result["ns4:Address"]["ns4:CityName"]._text,
                postcode: result["ns4:Address"]["ns4:PostcodeZone"]["ns4:Postcode"]._text,
                full_address:
                    pipe(
                        [
                            pipe(
                                [
                                    result["ns4:Address"]["ns4:SubBuildingName"]?._text || "",
                                    result["ns4:Address"]["ns4:BuildingName"]?._text || "",
                                    result["ns4:Address"]["ns4:BuildingNumber"]?._text || "",
                                ],
                                fptsArray.map((addressPart) => addressPart.trim()),
                                fptsArray.filter((addressPart) => addressPart !== ""),
                                (a) => a.join(" "),
                            ),
                            result["ns4:Address"]["ns4:StreetName"]?._text || "",
                            result["ns4:Address"]["ns4:CityName"]._text,
                            result["ns4:Address"]["ns4:PostcodeZone"]["ns4:Postcode"]._text,
                        ],
                        fptsArray.map((addressPart) => addressPart.trim()),
                        fptsArray.filter((addressPart) => addressPart !== ""),
                        (a) => a.join(", "),
                    ),
            })),
            (results) => ({
                _tag: "success" as "success",
                results,
            }),
        ),
);
export type TLandRegistrySearchPropertiesResponseSuccess = TTypeOfCodec<typeof LandRegistrySearchPropertiesResponseSuccess>;

const SearchPropertiesApiErrorCodes = union([
    literal("bg.invalid.property.search.criteria"),
    literal("bg.properties.nopropertyfound"),
    literal("bg.properties.toomanyproperties"),
    literal("bg.postcode.invalid"),
    literal("soapenv:SchemaFault"),
]);
export const LandRegistrySearchPropertiesResponseError = overload(
    required({
        _tag: literal("error"),
        code: SearchPropertiesApiErrorCodes,
    }),
    union([
        required({
            "soap:Envelope": required({
                "soap:Body": required({
                    "ns3:searchPropertiesResponse": required({
                        return: required({
                            "ns4:GatewayResponse": required({
                                "ns4:Rejection": required({
                                    "ns4:RejectionResponse": required({
                                        "ns4:Code": required({
                                            "_text": SearchPropertiesApiErrorCodes,
                                        })
                                    })
                                })
                            })
                        })
                    })
                })
            })
        }),
        required({
            "soapenv:Envelope": required({
                "soapenv:Body": required({
                    "soapenv:Fault": required({
                        "faultcode": required({
                            "_text": SearchPropertiesApiErrorCodes,
                        })
                    })
                })
            })
        })
    ]),
    (p) => ({
        _tag: "error" as "error",
        code: "soapenv:Envelope" in p
            ? p["soapenv:Envelope"]["soapenv:Body"]["soapenv:Fault"].faultcode._text
            : p["soap:Envelope"]["soap:Body"]["ns3:searchPropertiesResponse"].return["ns4:GatewayResponse"]["ns4:Rejection"]["ns4:RejectionResponse"]["ns4:Code"]._text,
    }),
);

export const LandRegistrySearchPropertiesResponseOutOfHours = overload(
    required({
        _tag: literal("out_of_hours"),
        poll_id: string(),
    }),
    required({
        "soap:Envelope": required({
            "soap:Body": required({
                "ns3:searchPropertiesResponse": required({
                    return: required({
                        "ns4:GatewayResponse": required({
                            "ns4:Acknowledgement": required({
                                "ns4:AcknowledgementDetails": required({
                                    "ns4:UniqueID": required({
                                        "_text": string(),
                                    })
                                })
                            })
                        })
                    })
                })
            })
        })
    }),
    (p) => ({
        _tag: "out_of_hours" as "out_of_hours",
        poll_id: p["soap:Envelope"]["soap:Body"]["ns3:searchPropertiesResponse"].return["ns4:GatewayResponse"]["ns4:Acknowledgement"]["ns4:AcknowledgementDetails"]["ns4:UniqueID"]._text,
    }),
)

export const LandRegistrySearchPropertiesResponse = union([
    LandRegistrySearchPropertiesResponseSuccess,
    LandRegistrySearchPropertiesResponseError,
    LandRegistrySearchPropertiesResponseOutOfHours,
]);
export type TLandRegistrySearchPropertiesResponseCodec = typeof LandRegistrySearchPropertiesResponse;
export type TLandRegistrySearchPropertiesResponse = TTypeOfCodec<TLandRegistrySearchPropertiesResponseCodec>;

const EntryDetails = intersection([
    required({
        "ns4:EntryNumber": required({
            "_text": string(),
        }),
        "ns4:EntryText": required({
            "_text": string(),
        }),
        "ns4:Infills": union([
            undefinedCodec(),
            required({
                "ns4:Amount": union([
                    wrapArray(partial({
                        "_text": string(),
                    })),
                    undefinedCodec(),
                ]),
                "ns4:ChargeDate": union([
                    wrapArray(partial({
                        "_text": string(),
                    })),
                    undefinedCodec(),
                ]),
                "ns4:ChargeParty": union([
                    wrapArray(partial({
                        "_text": string(),
                    })),
                    undefinedCodec(),
                ]),
                "ns4:Date": union([
                    wrapArray(partial({
                        "_text": date(),
                    })),
                    undefinedCodec(),
                ]),
                "ns4:DeedDate": union([
                    wrapArray(partial({
                        "_text": string(),
                    })),
                    undefinedCodec(),
                ]),
                "ns4:DeedExtent": union([
                    wrapArray(partial({
                        "_text": string(),
                    })),
                    undefinedCodec(),
                ]),
                "ns4:DeedParty": union([
                    wrapArray(partial({
                        "_text": string(),
                    })),
                    undefinedCodec(),
                ]),
                "ns4:DeedType": union([
                    wrapArray(partial({
                        "_text": string(),
                    })),
                    undefinedCodec(),
                ]),
                "ns4:ExtentOfLand": union([
                    wrapArray(partial({
                        "_text": string(),
                    })),
                    undefinedCodec(),
                ]),
                "ns4:MiscellaneousText": union([
                    wrapArray(partial({
                        "_text": string(),
                    })),
                    undefinedCodec(),
                ]),
                "ns4:Name": union([
                    wrapArray(partial({
                        "_text": string(),
                    })),
                    undefinedCodec(),
                ]),
                "ns4:Note": union([
                    wrapArray(partial({
                        "_text": string(),
                    })),
                    undefinedCodec(),
                ]),
                "ns4:OptionalMiscText": union([
                    wrapArray(partial({
                        "_text": string(),
                    })),
                    undefinedCodec(),
                ]),
                "ns4:PlansReference": union([
                    wrapArray(partial({
                        "_text": string(),
                    })),
                    undefinedCodec(),
                ]),
                "ns4:TitleNumber": union([
                    wrapArray(partial({
                        "_text": string(),
                    })),
                    undefinedCodec(),
                ]),
                "ns4:VerbatimText": union([
                    wrapArray(partial({
                        "_text": string(),
                    })),
                    undefinedCodec(),
                ]),
            }),
        ])
    }),
    partial({
        "ns4:RegistrationDate": required({
            "_text": date(),
        }),
        "ns4:SubRegisterCode": required({
            "_text": string(),
        }),
        "ns4:ScheduleCode": required({
            "_text": string(),
        }),
    })
]);
type TEntryDetails = TTypeOfCodec<typeof EntryDetails>;

const Entries = required({
    "ns4:EntryDetails": wrapArray(EntryDetails),
});
type TEntries = TTypeOfCodec<typeof Entries>;

const NormalisedEntry = required({
    entryNumber: positiveInteger(),
    entryText: string(),
});
export type TNormalisedEntry = TTypeOfCodec<typeof NormalisedEntry>;

const Entries_NormalisedEntries = (entry: TEntries): Array<TNormalisedEntry> =>
    pipe(
        entry["ns4:EntryDetails"],
        fptsArray.map((entryDetail) => ({
            entryNumber:
                pipe(
                    entryDetail["ns4:EntryNumber"]._text,
                    Number,
                ),
            entryText: normaliseEntryText(entryDetail["ns4:EntryText"]._text),
        }))
    );

const NormalisedRentChargeEntry = intersection([
    NormalisedEntry,
    required({
        rentChargeAmountString: string(),
    }),
]);
export type TNormalisedRentChargeEntry = TTypeOfCodec<typeof NormalisedRentChargeEntry>;

const NormalisedUnilateralNotice = intersection([
    NormalisedEntry,
    required({
        beneficiaryEntryText: string(),
        beneficiary: string(),
    }),
]);
export type TNormalisedUnilateralNotice = TTypeOfCodec<typeof NormalisedUnilateralNotice>;

const NormalisedDeathOfProprietorNotice = intersection([
    NormalisedEntry,
    required({
        deceased: string(),
    }),
]);
export type TNormalisedDeathOfProprietorNotice = TTypeOfCodec<typeof NormalisedDeathOfProprietorNotice>;

const Address = required({
    "ns4:AddressLine": required({
        "ns4:Line": wrapArray(required({
            "_text": string(),
        })),
    }),
    "ns4:PostcodeZone": union([
        undefinedCodec(),
        required({
            "ns4:Postcode": required({
                "_text": string(),
            }),
        }),
    ])
});

const Name = intersection([
    required({
        "ns4:SurnameName": required({
            "_text": string(),
        }),
    }),
    partial({
        "ns4:ForenamesName": required({
            "_text": string(),
        }),
    }),
]);

const SharedParty = partial({
    "ns4:Address": wrapArray(Address),
    "ns4:CharityDetails": intersection([
        required({
            "ns4:CharityName": wrapArray(required({
                "_text": string(),
            })),
            "ns4:CharityType": required({
                "_text": string(),
            })
        }),
        partial({
            "ns4:CharityAddress": wrapArray(Address),
        })
    ]),
    "ns4:TradingName": required({
        "_text": string(),
    }),
    "ns4:PartyNumber": required({
        "_text": string(),
    }),
    "ns4:PartyDescription": required({
        "_text": string(),
    }),
});
const Party = union([
    intersection([
        required({
            "ns4:PrivateIndividual": intersection([
                required({
                    "ns4:Name": Name,
                }),
                partial({
                    "ns4:Alias": wrapArray(Name),
                }),
            ]),
        }),
        SharedParty,
    ]),
    intersection([
        required({
            "ns4:Organization": intersection([
                required({
                    "ns4:Name": required({
                        "_text": string(),
                    }),
                }),
                partial({
                    "ns4:CompanyRegistrationNumber": required({
                        "_text": string(),
                    })
                })
            ])
        }),
        SharedParty,
    ])
]);
type TPartyCodec = typeof Party;
type TParty = TTypeOfCodec<TPartyCodec>;
const Party_displayName = (party: TParty): string =>
    "ns4:Organization" in party
        ? party["ns4:Organization"]["ns4:Name"]._text
        : pipe(
            [
                ...pipe(
                    party["ns4:PrivateIndividual"]["ns4:Name"]["ns4:ForenamesName"]?._text || "",
                    (forenames) => forenames.split(" "),
                    fptsArray.map((forename) => forename.trim()),
                ),
                party["ns4:PrivateIndividual"]["ns4:Name"]["ns4:SurnameName"]._text,
            ],
            fptsArray.map((namePart) => namePart.trim()),
            fptsArray.filter((namePart) => namePart !== ""),
            (namePart) => namePart.join(" "),
        );

const Restriction = intersection([
    required({
        "ns4:RestrictionTypeCode": required({
            "_text": string(),
        }),
        "ns4:EntryDetails": EntryDetails,
    }),
    partial({
        "ns4:ChargeID": required({
            "_text": string(),
        }),
    }),
]);

const Charge = intersection([
    required({
        "ns4:EntryDetails": EntryDetails,
    }),
    partial({
        "ns4:MultipleTitleIndicator": required({
            "_text": string(),
        }),
    }),
]);

const ChargeProprietor = required({
    "ns4:ChargeeParty": wrapArray(Party),
    "ns4:EntryDetails": EntryDetails,
});

const SubCharge = intersection([
    required({
        "ns4:RegisteredCharge": Charge,
        "ns4:ChargeProprietor": ChargeProprietor,
    }),
    partial({
        "ns4:ChargeDate": required({
            "_text": date(),
        }),
    }),
]);

const LandRegistryOfficialCopyWithSummaryResponseSuccessOriginal = required({
    "soap:Envelope": required({
        "soap:Body": required({
            "ns3:performOCWithSummaryResponse": required({
                return: required({
                    "ns4:GatewayResponse": required({
                        "ns4:TypeCode": required({
                            "_text": literal("30"),
                        }),
                        "ns4:Results": union([
                            undefinedCodec(),
                            required({
                                "ns4:ActualPrice": required({
                                    "ns4:GrossPriceAmount": required({
                                        "_text": string(),
                                    }),
                                }),
                                "ns4:Attachment": union([
                                    undefinedCodec(),
                                    required({
                                        "ns4:EmbeddedFileBinaryObject": required({
                                            "_text": string(),
                                        }),
                                    }),
                                ]),
                                "ns4:OCSummaryData": required({
                                    "ns4:OfficialCopyDateTime": required({
                                        "_text": string(),
                                    }),
                                    "ns4:EditionDate": required({
                                        "_text": date(),
                                    }),
                                    "ns4:PropertyAddress": wrapArray(Address),
                                    "ns4:Title": required({
                                        "ns4:TitleNumber": required({
                                            "_text": string(),
                                        }),
                                        "ns4:ClassOfTitleCode": required({
                                            "_text": string(),
                                        }),
                                        "ns4:CommonholdIndicator": required({
                                            "_text": booleanFromString(),
                                        }),
                                        "ns4:TitleRegistrationDetails": intersection([
                                            required({
                                                "ns4:DistrictName": required({
                                                    "_text": string(),
                                                }),
                                                "ns4:AdministrativeArea": required({
                                                    "_text": string(),
                                                }),
                                                "ns4:LandRegistryOfficeName": required({
                                                    "_text": string(),
                                                }),
                                                "ns4:LatestEditionDate": required({
                                                    "_text": date(),
                                                }),
                                            }),
                                            partial({
                                                "ns4:RegistrationDate": required({
                                                    "_text": date(),
                                                }),
                                            }),
                                        ]),
                                    }),
                                    "ns4:RegisterEntryIndicators": required({
                                        "ns4:AgreedNoticeIndicator": required({
                                            "_text": booleanFromString(),
                                        }),
                                        "ns4:BankruptcyIndicator": required({
                                            "_text": booleanFromString(),
                                        }),
                                        "ns4:CautionIndicator": required({
                                            "_text": booleanFromString(),
                                        }),
                                        "ns4:CCBIIndicator": required({
                                            "_text": booleanFromString(),
                                        }),
                                        "ns4:ChargeeIndicator": required({
                                            "_text": booleanFromString(),
                                        }),
                                        "ns4:ChargeIndicator": required({
                                            "_text": booleanFromString(),
                                        }),
                                        "ns4:ChargeRelatedRestrictionIndicator": required({
                                            "_text": booleanFromString(),
                                        }),
                                        "ns4:ChargeRestrictionIndicator": required({
                                            "_text": booleanFromString(),
                                        }),
                                        "ns4:CreditorsNoticeIndicator": required({
                                            "_text": booleanFromString(),
                                        }),
                                        "ns4:DeathOfProprietorIndicator": required({
                                            "_text": booleanFromString(),
                                        }),
                                        "ns4:DeedOfPostponementIndicator": required({
                                            "_text": booleanFromString(),
                                        }),
                                        "ns4:DiscountChargeIndicator": required({
                                            "_text": booleanFromString(),
                                        }),
                                        "ns4:EquitableChargeIndicator": required({
                                            "_text": booleanFromString(),
                                        }),
                                        "ns4:GreenOutEntryIndicator": required({
                                            "_text": booleanFromString(),
                                        }),
                                        "ns4:HomeRightsChangeOfAddressIndicator": required({
                                            "_text": booleanFromString(),
                                        }),
                                        "ns4:HomeRightsIndicator": required({
                                            "_text": booleanFromString(),
                                        }),
                                        "ns4:LeaseHoldTitleIndicator": required({
                                            "_text": booleanFromString(),
                                        }),
                                        "ns4:MultipleChargeIndicator": required({
                                            "_text": booleanFromString(),
                                        }),
                                        "ns4:NonChargeRestrictionIndicator": required({
                                            "_text": booleanFromString(),
                                        }),
                                        "ns4:NotedChargeIndicator": required({
                                            "_text": booleanFromString(),
                                        }),
                                        "ns4:PricePaidIndicator": required({
                                            "_text": booleanFromString(),
                                        }),
                                        "ns4:PropertyDescriptionNotesIndicator": required({
                                            "_text": booleanFromString(),
                                        }),
                                        "ns4:RentChargeIndicator": required({
                                            "_text": booleanFromString(),
                                        }),
                                        "ns4:RightOfPreEmptionIndicator": required({
                                            "_text": booleanFromString(),
                                        }),
                                        "ns4:ScheduleOfLeasesIndicator": required({
                                            "_text": booleanFromString(),
                                        }),
                                        "ns4:SubChargeIndicator": required({
                                            "_text": booleanFromString(),
                                        }),
                                        "ns4:UnidentifiedEntryIndicator": required({
                                            "_text": booleanFromString(),
                                        }),
                                        "ns4:UnilateralNoticeBeneficiaryIndicator": required({
                                            "_text": booleanFromString(),
                                        }),
                                        "ns4:UnilateralNoticeIndicator": required({
                                            "_text": booleanFromString(),
                                        }),
                                        "ns4:VendorsLienIndicator": required({
                                            "_text": booleanFromString(),
                                        }),
                                    }),
                                    "ns4:Proprietorship": union([
                                        required({
                                            "ns4:CautionerParty": wrapArray(Party),
                                            "ns4:CurrentProprietorshipDate": union([
                                                undefinedCodec(),
                                                required({
                                                    "_text": date(),
                                                }),
                                            ]),
                                        }),
                                        required({
                                            "ns4:RegisteredProprietorParty": wrapArray(Party),
                                            "ns4:CurrentProprietorshipDate": union([
                                                undefinedCodec(),
                                                required({
                                                    "_text": date(),
                                                }),
                                            ]),
                                        }),
                                    ]),
                                    "ns4:PricePaidEntry": union([
                                        undefinedCodec(),
                                        required({
                                            "ns4:EntryDetails": EntryDetails,
                                        }),
                                    ]),
                                    "ns4:Lease": union([
                                        undefinedCodec(),
                                        required({
                                            "ns4:LeaseEntry": wrapArray(intersection([
                                                required({
                                                    "ns4:LeaseTerm": required({
                                                        "_text": string(),
                                                    }),
                                                    "ns4:LeaseDate": required({
                                                        "_text": date(),
                                                    }),
                                                    "ns4:LeaseParty": wrapArray(Party),
                                                    "ns4:EntryDetails": EntryDetails,
                                                }),
                                                partial({
                                                    "ns4:Rent": required({
                                                        "_text": string(),
                                                    })
                                                })
                                            ]))
                                        }),
                                    ]),
                                    "ns4:RestrictionDetails": union([
                                        undefinedCodec(),
                                        required({
                                            "ns4:RestrictionEntry": wrapArray(union([
                                                required({
                                                    "ns4:ChargeRelatedRestriction": Restriction,
                                                }),
                                                required({
                                                    "ns4:ChargeRestriction": Restriction,
                                                }),
                                                required({
                                                    "ns4:NonChargeRestriction": Restriction,
                                                }),
                                            ]))
                                        }),
                                    ]),
                                    "ns4:Charge": union([
                                        undefinedCodec(),
                                        required({
                                            "ns4:ChargeEntry": wrapArray(intersection([
                                                required({
                                                    "ns4:RegisteredCharge": Charge,
                                                    "ns4:ChargeProprietor": ChargeProprietor,
                                                }),
                                                partial({
                                                    "ns4:ChargeID": required({
                                                        "_text": string(),
                                                    }),
                                                    "ns4:ChargeDate": required({
                                                        "_text": date(),
                                                    }),
                                                    "ns4:SubCharge": wrapArray(SubCharge),
                                                })
                                            ]))
                                        }),
                                    ]),
                                    "ns4:AgreedNotice": union([
                                        undefinedCodec(),
                                        required({
                                            "ns4:AgreedNoticeEntry": wrapArray(intersection([
                                                required({
                                                    "ns4:EntryDetails": EntryDetails,
                                                }),
                                                partial({
                                                    "ns4:AgreedNoticeType": required({
                                                        "_text": string(),
                                                    }),
                                                }),
                                            ]))
                                        }),
                                    ]),
                                    "ns4:Bankruptcy": union([undefinedCodec(), Entries]),
                                    "ns4:Caution": union([undefinedCodec(), Entries]),
                                    "ns4:DeedOfPostponement": union([undefinedCodec(), Entries]),
                                    "ns4:GreenOutEntry": union([undefinedCodec(), Entries]),
                                    "ns4:HomeRights": union([
                                        undefinedCodec(),
                                        required({
                                            "ns4:HomeRightsEntry": wrapArray(intersection([
                                                required({
                                                    "ns4:ChangeOfAddressIndicator": required({
                                                        "_text": booleanFromString(),
                                                    }),
                                                    "ns4:HomeRightsEntryDetails": EntryDetails,
                                                }),
                                                partial({
                                                    "ns4:ChangeOfAddressEntryDetails": EntryDetails,
                                                }),
                                            ]))
                                        }),
                                    ]),
                                    "ns4:RentCharge": union([undefinedCodec(), Entries]),
                                    "ns4:VendorsLien": union([undefinedCodec(), Entries]),
                                    "ns4:RightOfPreEmption": union([undefinedCodec(), Entries]),
                                    "ns4:DocumentDetails": union([
                                        undefinedCodec(),
                                        required({
                                            "ns4:Document": wrapArray(required({
                                                "ns4:DocumentType": required({
                                                    "_text": string(),
                                                }),
                                                "ns4:EntryNumber": wrapArray(required({
                                                    "_text": string(),
                                                })),
                                                "ns4:PlanOnlyIndicator": required({
                                                    "_text": booleanFromString(),
                                                }),
                                                "ns4:RegisterDescription": required({
                                                    "_text": string(),
                                                }),
                                                "ns4:DocumentDate": union([
                                                    undefinedCodec(),
                                                    required({
                                                        "_text": string(),
                                                    }),
                                                ]),
                                                "ns4:FiledUnder": union([
                                                    undefinedCodec(),
                                                    required({
                                                        "_text": string(),
                                                    })
                                                ])
                                            })),
                                        }),
                                    ]),
                                    "ns4:UnilateralNoticeDetails": union([
                                        undefinedCodec(),
                                        required({
                                            "ns4:UnilateralNoticeEntry": wrapArray(required({
                                                "ns4:UnilateralNotice": EntryDetails,
                                                "ns4:UnilateralNoticeBeneficiary": EntryDetails,
                                            })),
                                        }),
                                    ]),
                                    "ns4:DeathOfProprietor": union([undefinedCodec(), Entries]),
                                    "ns4:DiscountCharge": union([undefinedCodec(), Entries]),
                                    "ns4:EquitableCharge": union([undefinedCodec(), Entries]),
                                    "ns4:NotedCharge": union([undefinedCodec(), Entries]),
                                    "ns4:CreditorsNotice": union([undefinedCodec(), Entries]),
                                    "ns4:UnidentifiedEntry": union([undefinedCodec(), Entries]),
                                    "ns4:CCBIEntry": union([undefinedCodec(), Entries]),
                                }),
                            }),
                        ]),
                    }),
                })
            })
        })
    })
});

const NormalisedEntryCharge = required({
    entryNumber: positiveInteger(),
    entrySection: union([
        nullCodec(),
        literal("A"),
        literal("B"),
        literal("C"),
        literal("D"),
    ]),
    entryText: string(),
    chargeDate: union([nullCodec(), date()]),
    proprietor: string(),
});
export type TNormalisedEntryCharge = TTypeOfCodec<typeof NormalisedEntryCharge>;
const EntryDetails_NormalisedEntryCharge = (entryDetails: TEntryDetails): TNormalisedEntryCharge => ({
    entryNumber:
        pipe(
            entryDetails["ns4:EntryNumber"]._text,
            Number,
        ),
    entrySection:
        entryDetails["ns4:SubRegisterCode"]?._text === "A" ? "A" as "A"
        : entryDetails["ns4:SubRegisterCode"]?._text === "B" ? "B" as "B"
        : entryDetails["ns4:SubRegisterCode"]?._text === "C" ? "C" as "C"
        : entryDetails["ns4:SubRegisterCode"]?._text === "D" ? "D" as "D"
        : null,
    entryText:
        pipe(
            entryDetails["ns4:EntryText"]._text,
            normaliseEntryText,
        ),
    chargeDate:
        pipe(
            entryDetails["ns4:Infills"]?.["ns4:ChargeDate"] || [],
            fptsArray.filterMap(({_text}) =>
                typeof _text === "string"
                    ? option.some(_text)
                    : option.none,
            ),
            fptsArray.head,
            option.fold(
                () => null,
                getDateISOIfValid,
            ),
        ),
    proprietor:
        pipe(
            entryDetails["ns4:Infills"]?.["ns4:Name"] || [],
            fptsArray.filterMap(({_text}) =>
                _text
                && _text.trim() !== ""
                    ? option.some(_text.trim())
                    : option.none,
            ),
            (a) =>
                a.length > 0
                    ? a.join(" & ")
                    : "",
        ),
});

const NormalisedRestrictionShared = required({
    entrySection: union([
        nullCodec(),
        literal("A"),
        literal("B"),
        literal("C"),
        literal("D"),
    ]),
    entryNumber: positiveInteger(),
    entryText: string(),
    registrationDate: union([nullCodec(), date()]),
});
export const NormalisedRestrictionFinancialType = union([
    literal("charge"),
    literal("charge_related"),
]);
export type TNormalisedRestrictionFinancialType = TTypeOfCodec<typeof NormalisedRestrictionFinancialType>;
export const NormalisedRestrictionFinancial = intersection([
    required({
        type: NormalisedRestrictionFinancialType,
        relatedChargeId: union([
            nullCodec(),
            positiveInteger(),
        ]),
        relatedChargeDate: union([
            nullCodec(),
            date(),
        ]),
        relatedChargeBeneficiary: union([
            nullCodec(),
            string(),
        ]),
    }),
    NormalisedRestrictionShared,
]);
export type TNormalisedRestrictionFinancial = TTypeOfCodec<typeof NormalisedRestrictionFinancial>;
export const NormalisedRestrictionNonFinancialType = union([
    literal("joint_proprietor"),
    literal("non_charge"),
]);
export type TNormalisedRestrictionNonFinancialType = TTypeOfCodec<typeof NormalisedRestrictionNonFinancialType>;
const NormalisedRestriction = union([
    NormalisedRestrictionFinancial,
    intersection([
        required({
            type: NormalisedRestrictionNonFinancialType,
        }),
        NormalisedRestrictionShared,
    ]),
]);
export type TNormalisedRestriction = TTypeOfCodec<typeof NormalisedRestriction>;

export const NormalisedRegisteredCharge = required({
    type: literal("charge"),
    id: union([
        nullCodec(),
        positiveInteger(),
    ]),
    entrySection: union([
        nullCodec(),
        literal("A"),
        literal("B"),
        literal("C"),
        literal("D"),
    ]),
    entryNumber: positiveInteger(),
    entryText: string(),
    chargeDate: union([nullCodec(), date()]),
    registrationDate: union([nullCodec(), date()]),
    proprietor: union([nullCodec(), string()]),
});
export type TNormalisedRegisteredCharge = TTypeOfCodec<typeof NormalisedRegisteredCharge>;

export const NormalisedDiscountCharge = intersection([
    required({
        type: literal("discount_charge"),
    }),
    NormalisedEntryCharge,
]);
export type TNormalisedDiscountCharge = TTypeOfCodec<typeof NormalisedDiscountCharge>;

export const NormalisedNotedCharge = intersection([
    required({
        type: literal("noted_charge"),
    }),
    NormalisedEntryCharge,
]);
export type TNormalisedNotedCharge = TTypeOfCodec<typeof NormalisedNotedCharge>;

export const NormalisedEquitableCharge = intersection([
    required({
        type: literal("equitable_charge"),
    }),
    NormalisedEntryCharge,
]);
export type TNormalisedEquitableCharge = TTypeOfCodec<typeof NormalisedEquitableCharge>;

const NormalisedCharge = union([
    NormalisedRegisteredCharge,
    NormalisedDiscountCharge,
    NormalisedNotedCharge,
    NormalisedEquitableCharge,
]);
export type TNormalisedCharge = TTypeOfCodec<typeof NormalisedCharge>;

const NormalisedAgreedNotice =
    required({
        type: union([
            literal("right_of_preemption"),
            literal("option_to_purchase"),
            literal("unknown"),
        ]),
        entrySection: union([
            nullCodec(),
            literal("A"),
            literal("B"),
            literal("C"),
            literal("D"),
        ]),
        entryNumber: positiveInteger(),
        entryText: string(),
        deedParty: string(),
        deedDate: union([nullCodec(), date()]),
    });
export type TNormalisedAgreedNotice = TTypeOfCodec<typeof NormalisedAgreedNotice>;

const NormalisedHomeRightsEntry = required({
    entrySection: union([
        nullCodec(),
        literal("A"),
        literal("B"),
        literal("C"),
        literal("D"),
    ]),
    entryNumber: positiveInteger(),
    entryText: string(),
    parties: string(),
});
export type TNormalisedHomeRightsEntry = TTypeOfCodec<typeof NormalisedHomeRightsEntry>;

export const NormalisedProprietorIndividual = required({
    type: literal("individual"),
    name: string(),
    legal_first_name: string(),
    legal_middle_name: string(),
    legal_last_name: string(),
});
export type TNormalisedProprietorIndividual = TTypeOfCodec<typeof NormalisedProprietorIndividual>;
const NormalisedProprietorCompany = required({
    type: literal("company"),
    name: string(),
    company_number: string(),
});
export type TNormalisedProprietorCompany = TTypeOfCodec<typeof NormalisedProprietorCompany>;
const NormalisedProprietor = union([
    NormalisedProprietorIndividual,
    NormalisedProprietorCompany,
]);
export type TNormalisedProprietor = TTypeOfCodec<typeof NormalisedProprietor>;

export const LandRegistryOfficialCopyWithSummaryResponseSuccessOverload = required({
    _tag: literal("success"),
    registerPdfBase64: union([nullCodec(), string()]),
    registerTimestamp: union([nullCodec(), dateTime()]),
    registerEditionDate: union([nullCodec(), date()]),
    titleNumber: union([nullCodec(), string()]),
    address: union([nullCodec(), string()]),
    lastPricePaid: union([
        nullCodec(),
        required({
            amountPence: positiveInteger(),
            date: union([nullCodec(), date()]),
        }),
    ]),
    proprietors: array(NormalisedProprietor),
    tenure: Tenure1,
    titleClass: CasePropertyTitleClass,
    firstRegistrationDate: union([nullCodec(), date()]),
    lastRegistrationDate: union([nullCodec(), date()]),
    groundRentsTotalPence: union([nullCodec(), positiveInteger()]),
    earliestLeaseApproximateExpiry: union([nullCodec(), date()]),
    restrictions: array(NormalisedRestriction),
    charges: array(NormalisedCharge),
    firstRegistrationCaution: union([
        required({
            status: literal("none"),
        }),
        required({
            status: literal("found"),
            beneficiary: union([nullCodec(), string()]),
        })
    ]),
    isRegistered: boolean(),
    agreedNotices: array(NormalisedAgreedNotice),
    bankruptcyEntries: array(NormalisedEntry),
    cautionEntries: array(NormalisedEntry),
    deedOfPostponementEntries: array(NormalisedEntry),
    greenOutEntries: array(NormalisedEntry),
    homeRightsEntries: array(NormalisedHomeRightsEntry),
    rentChargeEntries: array(NormalisedRentChargeEntry),
    vendorsLienEntries: array(NormalisedEntry),
    unilateralNotices: array(NormalisedUnilateralNotice),
    deathOfProprietorNotices: array(NormalisedDeathOfProprietorNotice),
    creditorsNotices: array(NormalisedEntry),
});
type TLandRegistryOfficialCopyWithSummaryResponseSuccessOverload = typeof LandRegistryOfficialCopyWithSummaryResponseSuccessOverload;

export const LandRegistryOfficialCopyWithSummaryResponseSuccess: TCodec<"OverloadCodec", {overloadCodec: TAnyCodec; codec: TLandRegistryOfficialCopyWithSummaryResponseSuccessOverload}, TTypeOfCodec<TLandRegistryOfficialCopyWithSummaryResponseSuccessOverload>, TTypeOfNewDefault<TLandRegistryOfficialCopyWithSummaryResponseSuccessOverload>> = overload(
    LandRegistryOfficialCopyWithSummaryResponseSuccessOverload,
    LandRegistryOfficialCopyWithSummaryResponseSuccessOriginal,
    (p) => {
        const registerPdfBase64 = p["soap:Envelope"]["soap:Body"]["ns3:performOCWithSummaryResponse"].return["ns4:GatewayResponse"]["ns4:Results"]?.["ns4:Attachment"]?.["ns4:EmbeddedFileBinaryObject"]._text || null;

        const registerTimestamp = p["soap:Envelope"]["soap:Body"]["ns3:performOCWithSummaryResponse"].return["ns4:GatewayResponse"]["ns4:Results"]?.["ns4:OCSummaryData"]?.["ns4:OfficialCopyDateTime"]._text
            ? pipe(
                p["soap:Envelope"]["soap:Body"]["ns3:performOCWithSummaryResponse"].return["ns4:GatewayResponse"]["ns4:Results"]?.["ns4:OCSummaryData"]?.["ns4:OfficialCopyDateTime"]._text,
                (value) => DateTime.fromISO(value, {zone: "Europe/London"}).toUTC().toISO({includeOffset: false}),
            )
            : null;

        const registerEditionDate = p["soap:Envelope"]["soap:Body"]["ns3:performOCWithSummaryResponse"].return["ns4:GatewayResponse"]["ns4:Results"]?.["ns4:OCSummaryData"]?.["ns4:EditionDate"]._text || null;

        const titleNumber = p["soap:Envelope"]["soap:Body"]["ns3:performOCWithSummaryResponse"].return["ns4:GatewayResponse"]["ns4:Results"]?.["ns4:OCSummaryData"]?.["ns4:Title"]["ns4:TitleNumber"]._text || null;

        const address =
            pipe(
                p["soap:Envelope"]["soap:Body"]["ns3:performOCWithSummaryResponse"].return["ns4:GatewayResponse"]["ns4:Results"]?.["ns4:OCSummaryData"]?.["ns4:PropertyAddress"] || [],
                fptsArray.map((a) =>
                    pipe(
                        [
                            // Address lines
                            pipe(
                                a["ns4:AddressLine"]["ns4:Line"] || [],
                                fptsArray.map((line) => line._text),
                            ),
        
                            // Postcode
                            a["ns4:PostcodeZone"]?.["ns4:Postcode"]._text
                                ? [a["ns4:PostcodeZone"]?.["ns4:Postcode"]._text]
                                : []
                        ],
                        fptsArray.flatten,
                    ),
                ),
                fptsArray.flatten,
                fptsArray.map((addressPart) => addressPart.trim()),
                fptsArray.filter((addressPart) => addressPart !== ""),
                (addressParts) =>
                    addressParts.length > 0
                        ? addressParts.join(", ")
                        : null,
            );

        const proprietors =
            pipe(
                p["soap:Envelope"]["soap:Body"]["ns3:performOCWithSummaryResponse"].return["ns4:GatewayResponse"]["ns4:Results"]
                && "ns4:RegisteredProprietorParty" in p["soap:Envelope"]["soap:Body"]["ns3:performOCWithSummaryResponse"].return["ns4:GatewayResponse"]["ns4:Results"]["ns4:OCSummaryData"]["ns4:Proprietorship"]
                    ? p["soap:Envelope"]["soap:Body"]["ns3:performOCWithSummaryResponse"].return["ns4:GatewayResponse"]["ns4:Results"]["ns4:OCSummaryData"]["ns4:Proprietorship"]["ns4:RegisteredProprietorParty"]
                    : [],
                fptsArray.map((party) => {
                    const partyDisplayName = Party_displayName(party);
                    const partyDisplayParts = partyDisplayName.split(" ");
                    const firstName =
                        pipe(
                            partyDisplayParts,
                            fptsArray.head,
                            option.getOrElse(() => ""),
                        );
                    const middleNames =
                        partyDisplayParts.length > 2
                            ? partyDisplayParts.slice(1, -1).join(" ")
                            : "";
                    const lastName =
                        partyDisplayParts.length > 1
                            ? pipe(
                                partyDisplayParts,
                                fptsArray.last,
                                option.getOrElse(() => ""),
                            )
                            : "";

                    return "ns4:Organization" in party
                        ? {
                            type: "company" as "company",
                            name: party["ns4:Organization"]["ns4:Name"]._text,
                            company_number: party["ns4:Organization"]["ns4:CompanyRegistrationNumber"]?._text || "",
                        }
                        : {
                            type: "individual" as "individual",
                            name: partyDisplayName,
                            legal_first_name: firstName,
                            legal_middle_name: middleNames,
                            legal_last_name: lastName,
                        }
                }),
            );

        const getTenure = () => {
            const classOfTitleCode = p["soap:Envelope"]["soap:Body"]["ns3:performOCWithSummaryResponse"].return["ns4:GatewayResponse"]["ns4:Results"]?.["ns4:OCSummaryData"]?.["ns4:Title"]["ns4:ClassOfTitleCode"]._text;
            const isCommonhold = p["soap:Envelope"]["soap:Body"]["ns3:performOCWithSummaryResponse"].return["ns4:GatewayResponse"]["ns4:Results"]?.["ns4:OCSummaryData"]["ns4:Title"]["ns4:CommonholdIndicator"]._text;

            return isCommonhold ? "commonhold" as "commonhold"
                : (
                    classOfTitleCode === "10"
                    || classOfTitleCode === "20"
                    || classOfTitleCode === "30"
                    || classOfTitleCode === "40"
                ) ? "freehold" as "freehold"
                : (
                    classOfTitleCode === "60"
                    || classOfTitleCode === "70"
                    || classOfTitleCode === "80"
                    || classOfTitleCode === "90"
                ) ? "leasehold" as "leasehold"
                : "unknown" as "unknown";
        };

        const getTitleClass = () => {
            const classOfTitleCode = p["soap:Envelope"]["soap:Body"]["ns3:performOCWithSummaryResponse"].return["ns4:GatewayResponse"]["ns4:Results"]?.["ns4:OCSummaryData"]?.["ns4:Title"]["ns4:ClassOfTitleCode"]._text;

            return (
                classOfTitleCode === "10"
                || classOfTitleCode === "60"
                || classOfTitleCode === "100"
            ) ? "absolute" as "absolute"
            : (
                classOfTitleCode === "20"
                || classOfTitleCode === "90"
                || classOfTitleCode === "110"
            ) ? "possessory" as "possessory"
            : (
                classOfTitleCode === "30"
                || classOfTitleCode === "80"
                || classOfTitleCode === "120"
            ) ? "qualified" as "qualified"
            : classOfTitleCode === "70" ? "good_leasehold" as "good_leasehold"
            : "unknown" as "unknown"
        };

        const firstRegistrationDate = p["soap:Envelope"]["soap:Body"]["ns3:performOCWithSummaryResponse"].return["ns4:GatewayResponse"]["ns4:Results"]?.["ns4:OCSummaryData"]?.["ns4:Title"]["ns4:TitleRegistrationDetails"]["ns4:RegistrationDate"]?._text || null;

        const lastRegistrationDate = p["soap:Envelope"]["soap:Body"]["ns3:performOCWithSummaryResponse"].return["ns4:GatewayResponse"]["ns4:Results"]?.["ns4:OCSummaryData"]?.["ns4:Proprietorship"]["ns4:CurrentProprietorshipDate"]?._text || null;

        const groundRentsTotalPence =
            pipe(
                p["soap:Envelope"]["soap:Body"]["ns3:performOCWithSummaryResponse"].return["ns4:GatewayResponse"]["ns4:Results"]?.["ns4:OCSummaryData"]?.["ns4:Lease"]?.["ns4:LeaseEntry"] || [],
                fptsArray.map((entry) => entry["ns4:Rent"]?._text),
                fptsArray.filterMap((value) =>
                    typeof value === "string"
                        ? option.some(value)
                        : option.none,
                ),
                fptsArray.map(LandRegistryMoneyString_pence),
                fptsArray.reduce(0, (a, b) => a + b),
                Math.ceil,
            ) || null;

        const earliestLeaseApproximateExpiry =
            pipe(
                p["soap:Envelope"]["soap:Body"]["ns3:performOCWithSummaryResponse"].return["ns4:GatewayResponse"]["ns4:Results"]?.["ns4:OCSummaryData"]?.["ns4:Lease"]?.["ns4:LeaseEntry"] || [],
                fptsArray.filterMap((entry) => {
                    const yearsToAdd = Number((entry["ns4:LeaseTerm"]._text.match(/([0-9]*)\s?(years?)/) || [])[1]);
                    if (isNaN(yearsToAdd) || yearsToAdd <= 0) {
                        return option.none;
                    }

                    return isNaN(yearsToAdd)
                        || yearsToAdd <= 0
                        || getDateISOIfValid(entry["ns4:LeaseDate"]._text) === null
                        ? option.none
                        : pipe(
                            DateTime.fromISO(getDateISOIfValid(entry["ns4:LeaseDate"]._text) || "").plus({years: yearsToAdd}).toSeconds(),
                            option.some,
                        )
                }),
                fptsArray.sort(Ord),
                fptsArray.head,
                option.map((seconds) => DateTime.fromSeconds(seconds).toISODate()),
                option.getOrElse<string | null>(() => null),
            );

        const restrictions =
            pipe(
                p["soap:Envelope"]["soap:Body"]["ns3:performOCWithSummaryResponse"].return["ns4:GatewayResponse"]["ns4:Results"]?.["ns4:OCSummaryData"]["ns4:RestrictionDetails"]?.["ns4:RestrictionEntry"] || [],
                fptsArray.map((restriction): TNormalisedRestriction => {
                    const r =
                        "ns4:ChargeRelatedRestriction" in restriction ? restriction["ns4:ChargeRelatedRestriction"]
                        : "ns4:ChargeRestriction" in restriction ? restriction["ns4:ChargeRestriction"]
                        : restriction["ns4:NonChargeRestriction"];

                    const relatedChargeDate = typeof r["ns4:EntryDetails"]["ns4:Infills"]?.["ns4:ChargeDate"] !== "undefined"
                        && Array.isArray(r["ns4:EntryDetails"]["ns4:Infills"]["ns4:ChargeDate"])
                            ? pipe(
                                r["ns4:EntryDetails"]["ns4:Infills"]["ns4:ChargeDate"] as Array<{"_text": string}>,
                                fptsArray.map(({_text}) => _text),
                                fptsArray.filter((d) => getDateISOIfValid(d) !== null),
                                fptsArray.head,
                                option.getOrElse<string | null>(() => null),
                            )
                            : null;

                    const relatedChargeBeneficiary = typeof r["ns4:EntryDetails"]["ns4:Infills"]?.["ns4:ChargeParty"] !== "undefined"
                        && Array.isArray(r["ns4:EntryDetails"]["ns4:Infills"]["ns4:ChargeParty"])
                            ? pipe(
                                r["ns4:EntryDetails"]["ns4:Infills"]["ns4:ChargeParty"] as Array<{"_text": string}>,
                                fptsArray.map(({_text}) => _text.trim()),
                                fptsArray.filter((party) => party !== ""),
                                (parties) => parties.length > 0
                                    ? parties.join(" & ")
                                    : null
                            )
                            : null;

                    const relatedChargeId =
                        pipe(
                            r["ns4:ChargeID"]?._text || "",
                            Number,
                            (rId) => !isNaN(rId) && rId > 0
                                ? rId
                                : null,
                        );

                    return {
                        ...(
                            r["ns4:RestrictionTypeCode"]._text === "20"
                            || r["ns4:RestrictionTypeCode"]._text === "30"
                                ? {
                                    type:
                                        r["ns4:RestrictionTypeCode"]._text === "20"
                                            ? "charge_related" as "charge_related"
                                            : "charge" as "charge",
                                    relatedChargeId,
                                    relatedChargeDate,
                                    relatedChargeBeneficiary,
                                }
                                : {
                                    type:
                                        r["ns4:RestrictionTypeCode"]._text === "0"
                                            ? "non_charge" as "non_charge"
                                            : "joint_proprietor" as "joint_proprietor",
                                }
                        ),
                        entrySection:
                            r["ns4:EntryDetails"]["ns4:SubRegisterCode"]?._text === "A" ? "A" as "A"
                            : r["ns4:EntryDetails"]["ns4:SubRegisterCode"]?._text === "B" ? "B" as "B"
                            : r["ns4:EntryDetails"]["ns4:SubRegisterCode"]?._text === "C" ? "C" as "C"
                            : r["ns4:EntryDetails"]["ns4:SubRegisterCode"]?._text === "D" ? "D" as "D"
                            : null,
                        entryNumber:
                            pipe(
                                r["ns4:EntryDetails"]["ns4:EntryNumber"]._text,
                                Number,
                            ),
                        entryText:
                            pipe(
                                r["ns4:EntryDetails"]["ns4:EntryText"]._text,
                                normaliseEntryText,
                            ),
                        registrationDate:
                            pipe(
                                r["ns4:EntryDetails"]["ns4:RegistrationDate"]?._text || "",
                                getDateISOIfValid,
                            ),
                    };
                }),
            );
        
        const registeredCharges =
            pipe(
                p["soap:Envelope"]["soap:Body"]["ns3:performOCWithSummaryResponse"].return["ns4:GatewayResponse"]["ns4:Results"]?.["ns4:OCSummaryData"]["ns4:Charge"]?.["ns4:ChargeEntry"] || [],
                fptsArray.map((charge) => ({
                    type: "charge" as "charge",
                    id:
                        pipe(
                            charge["ns4:ChargeID"]?._text || "",
                            Number,
                            (r) => !isNaN(r) && r > 0
                                ? r
                                : null,
                        ),
                    entrySection:
                        charge["ns4:RegisteredCharge"]["ns4:EntryDetails"]["ns4:SubRegisterCode"]?._text === "A" ? "A" as "A"
                        : charge["ns4:RegisteredCharge"]["ns4:EntryDetails"]["ns4:SubRegisterCode"]?._text === "B" ? "B" as "B"
                        : charge["ns4:RegisteredCharge"]["ns4:EntryDetails"]["ns4:SubRegisterCode"]?._text === "C" ? "C" as "C"
                        : charge["ns4:RegisteredCharge"]["ns4:EntryDetails"]["ns4:SubRegisterCode"]?._text === "D" ? "D" as "D"
                        : null,
                    entryNumber:
                        pipe(
                            charge["ns4:RegisteredCharge"]["ns4:EntryDetails"]["ns4:EntryNumber"]._text,
                            Number,
                        ),
                    entryText:
                        pipe(
                            charge["ns4:RegisteredCharge"]["ns4:EntryDetails"]["ns4:EntryText"]._text,
                            normaliseEntryText,
                        ),
                    chargeDate:
                        pipe(
                            charge["ns4:ChargeDate"]?._text || "",
                            getDateISOIfValid,
                        ),
                    registrationDate:
                        pipe(
                            charge["ns4:RegisteredCharge"]["ns4:EntryDetails"]["ns4:RegistrationDate"]?._text || "",
                            getDateISOIfValid,
                        ),
                    proprietor:
                        pipe(
                            charge["ns4:ChargeProprietor"]["ns4:ChargeeParty"],
                            fptsArray.map(Party_displayName),
                            fptsArray.filter((name) => name !== ""),
                            (a) =>
                                a.length > 0
                                    ? a.join(" & ")
                                    : null,
                        )
                }))
            );

        const discountCharges =
            pipe(
                p["soap:Envelope"]["soap:Body"]["ns3:performOCWithSummaryResponse"].return["ns4:GatewayResponse"]["ns4:Results"]?.["ns4:OCSummaryData"]["ns4:DiscountCharge"]?.["ns4:EntryDetails"] || [],
                fptsArray.map((entry) => ({
                    ...EntryDetails_NormalisedEntryCharge(entry),
                    type: "discount_charge" as "discount_charge",
                })),
            );

        const notedCharges =
            pipe(
                p["soap:Envelope"]["soap:Body"]["ns3:performOCWithSummaryResponse"].return["ns4:GatewayResponse"]["ns4:Results"]?.["ns4:OCSummaryData"]["ns4:NotedCharge"]?.["ns4:EntryDetails"] || [],
                fptsArray.map((entry) => ({
                    ...EntryDetails_NormalisedEntryCharge(entry),
                    type: "noted_charge" as "noted_charge",
                })),
            );

        const equitableCharges =
            pipe(
                p["soap:Envelope"]["soap:Body"]["ns3:performOCWithSummaryResponse"].return["ns4:GatewayResponse"]["ns4:Results"]?.["ns4:OCSummaryData"]["ns4:EquitableCharge"]?.["ns4:EntryDetails"] || [],
                fptsArray.map((entry) => ({
                    ...EntryDetails_NormalisedEntryCharge(entry),
                    type: "equitable_charge" as "equitable_charge",
                })),
            );

        const charges = [
            ...registeredCharges,
            ...discountCharges,
            ...notedCharges,
            ...equitableCharges,
        ];

        const firstRegistrationCaution =
            p["soap:Envelope"]["soap:Body"]["ns3:performOCWithSummaryResponse"].return["ns4:GatewayResponse"]["ns4:Results"]?.["ns4:OCSummaryData"]["ns4:Title"]["ns4:ClassOfTitleCode"]._text === "130"
                ? {
                    status: "found" as "found",
                    beneficiary:
                        "ns4:CautionerParty" in p["soap:Envelope"]["soap:Body"]["ns3:performOCWithSummaryResponse"].return["ns4:GatewayResponse"]["ns4:Results"]["ns4:OCSummaryData"]["ns4:Proprietorship"]
                            ? pipe(
                                p["soap:Envelope"]["soap:Body"]["ns3:performOCWithSummaryResponse"].return["ns4:GatewayResponse"]["ns4:Results"]["ns4:OCSummaryData"]["ns4:Proprietorship"]["ns4:CautionerParty"],
                                fptsArray.map(Party_displayName),
                                (names) =>
                                    names.length > 0
                                        ? names.join(" & ")
                                        : null
                            )
                            : null
                }
                : {
                    status: "none" as "none",
                };

        const lastPricePaid =
            Array.isArray(p["soap:Envelope"]["soap:Body"]["ns3:performOCWithSummaryResponse"].return["ns4:GatewayResponse"]["ns4:Results"]?.["ns4:OCSummaryData"]["ns4:PricePaidEntry"]?.["ns4:EntryDetails"]["ns4:Infills"]?.["ns4:Amount"])
            && (p["soap:Envelope"]["soap:Body"]["ns3:performOCWithSummaryResponse"].return["ns4:GatewayResponse"]["ns4:Results"]?.["ns4:OCSummaryData"]["ns4:PricePaidEntry"]?.["ns4:EntryDetails"]["ns4:Infills"]?.["ns4:Amount"] || []).length > 0
                ? pipe(
                    (p["soap:Envelope"]["soap:Body"]["ns3:performOCWithSummaryResponse"].return["ns4:GatewayResponse"]["ns4:Results"]?.["ns4:OCSummaryData"]["ns4:PricePaidEntry"]?.["ns4:EntryDetails"]["ns4:Infills"]?.["ns4:Amount"] || [])[0]._text || "",
                    (value) => value === ""
                        ? option.none
                        : option.some(value),
                    option.map(LandRegistryMoneyString_pence),
                    option.fold(
                        () => null,
                        (amountPence) => ({
                            amountPence,
                            date:
                                pipe(
                                    p["soap:Envelope"]["soap:Body"]["ns3:performOCWithSummaryResponse"].return["ns4:GatewayResponse"]["ns4:Results"]?.["ns4:OCSummaryData"]["ns4:PricePaidEntry"]?.["ns4:EntryDetails"]["ns4:Infills"]?.["ns4:Date"] || [],
                                    fptsArray.filterMap(({_text}) =>
                                        typeof _text === "string"
                                        && getDateISOIfValid(_text) !== null
                                            ? option.some(getDateISOIfValid(_text))
                                            : option.none,
                                    ),
                                    fptsArray.head,
                                    option.fold(
                                        () => null,
                                        (d) => d,
                                    )
                                ),
                        })
                    )
                )
                : null;

        const agreedNotices =
            pipe(
                p["soap:Envelope"]["soap:Body"]["ns3:performOCWithSummaryResponse"].return["ns4:GatewayResponse"]["ns4:Results"]?.["ns4:OCSummaryData"]["ns4:AgreedNotice"]?.["ns4:AgreedNoticeEntry"] || [],
                fptsArray.map((entry) => ({
                    type:
                        entry["ns4:AgreedNoticeType"]?._text === "10" ? "option_to_purchase" as "option_to_purchase"
                        : entry["ns4:AgreedNoticeType"]?._text === "20" ? "right_of_preemption" as "right_of_preemption"
                        : "unknown" as "unknown",
                    entrySection:
                        entry["ns4:EntryDetails"]["ns4:SubRegisterCode"]?._text === "A" ? "A" as "A"
                        : entry["ns4:EntryDetails"]["ns4:SubRegisterCode"]?._text === "B" ? "B" as "B"
                        : entry["ns4:EntryDetails"]["ns4:SubRegisterCode"]?._text === "C" ? "C" as "C"
                        : entry["ns4:EntryDetails"]["ns4:SubRegisterCode"]?._text === "D" ? "D" as "D"
                        : null,
                    entryNumber:
                        pipe(
                            entry["ns4:EntryDetails"]["ns4:EntryNumber"]._text,
                            Number,
                        ),
                    entryText:
                        pipe(
                            entry["ns4:EntryDetails"]["ns4:EntryText"]._text,
                            normaliseEntryText,
                        ),
                    deedParty:
                        pipe(
                            entry["ns4:EntryDetails"]["ns4:Infills"]?.["ns4:DeedParty"] || [],
                            fptsArray.map((party) => party._text?.trim() || ""),
                            fptsArray.filter((party) => party !== ""),
                            (a) => a.join(" & "),
                        ),
                    deedDate:
                        pipe(
                            entry["ns4:EntryDetails"]["ns4:Infills"]?.["ns4:DeedDate"] || [],
                            fptsArray.filterMap(({_text}) =>
                                _text
                                    ? option.some(_text)
                                    : option.none,
                            ),
                            fptsArray.head,
                            option.fold(
                                () => null,
                                getDateISOIfValid,
                            ),
                        )
                }))
            );

        const bankruptcyEntries =
            p["soap:Envelope"]["soap:Body"]["ns3:performOCWithSummaryResponse"].return["ns4:GatewayResponse"]["ns4:Results"]?.["ns4:OCSummaryData"]["ns4:Bankruptcy"]
                ? Entries_NormalisedEntries(p["soap:Envelope"]["soap:Body"]["ns3:performOCWithSummaryResponse"].return["ns4:GatewayResponse"]["ns4:Results"]?.["ns4:OCSummaryData"]["ns4:Bankruptcy"])
                : [];

        const cautionEntries =
            p["soap:Envelope"]["soap:Body"]["ns3:performOCWithSummaryResponse"].return["ns4:GatewayResponse"]["ns4:Results"]?.["ns4:OCSummaryData"]["ns4:Caution"]
                ? Entries_NormalisedEntries(p["soap:Envelope"]["soap:Body"]["ns3:performOCWithSummaryResponse"].return["ns4:GatewayResponse"]["ns4:Results"]?.["ns4:OCSummaryData"]["ns4:Caution"])
                : [];

        const deedOfPostponementEntries =
            p["soap:Envelope"]["soap:Body"]["ns3:performOCWithSummaryResponse"].return["ns4:GatewayResponse"]["ns4:Results"]?.["ns4:OCSummaryData"]["ns4:DeedOfPostponement"]
                ? Entries_NormalisedEntries(p["soap:Envelope"]["soap:Body"]["ns3:performOCWithSummaryResponse"].return["ns4:GatewayResponse"]["ns4:Results"]?.["ns4:OCSummaryData"]["ns4:DeedOfPostponement"])
                : [];

        const greenOutEntries =
            p["soap:Envelope"]["soap:Body"]["ns3:performOCWithSummaryResponse"].return["ns4:GatewayResponse"]["ns4:Results"]?.["ns4:OCSummaryData"]["ns4:GreenOutEntry"]
                ? Entries_NormalisedEntries(p["soap:Envelope"]["soap:Body"]["ns3:performOCWithSummaryResponse"].return["ns4:GatewayResponse"]["ns4:Results"]?.["ns4:OCSummaryData"]["ns4:GreenOutEntry"])
                : [];

        const homeRightsEntries =
            pipe(
                p["soap:Envelope"]["soap:Body"]["ns3:performOCWithSummaryResponse"].return["ns4:GatewayResponse"]["ns4:Results"]?.["ns4:OCSummaryData"]["ns4:HomeRights"]?.["ns4:HomeRightsEntry"] || [],
                fptsArray.map((entry) => ({
                    entrySection:
                        entry["ns4:HomeRightsEntryDetails"]["ns4:SubRegisterCode"]?._text === "A" ? "A" as "A"
                        : entry["ns4:HomeRightsEntryDetails"]["ns4:SubRegisterCode"]?._text === "D" ? "D" as "D"
                        : entry["ns4:HomeRightsEntryDetails"]["ns4:SubRegisterCode"]?._text === "B" ? "B" as "B"
                        : entry["ns4:HomeRightsEntryDetails"]["ns4:SubRegisterCode"]?._text === "C" ? "C" as "C"
                        : null,
                    entryNumber:
                        pipe(
                            entry["ns4:HomeRightsEntryDetails"]["ns4:EntryNumber"]._text,
                            Number,
                        ),
                    entryText:
                        pipe(
                            entry["ns4:HomeRightsEntryDetails"]["ns4:EntryText"]._text,
                            normaliseEntryText,
                        ),
                    parties:
                        pipe(
                            entry["ns4:HomeRightsEntryDetails"]["ns4:Infills"]?.["ns4:Name"] || [],
                            fptsArray.map((name) => name._text?.trim() || ""),
                            fptsArray.uniq(Eq),
                            fptsArray.filter((name) => name !== ""),
                            (a) => a.join(" & "),
                        ),
                }))
            );

        const rentChargeEntries =
            pipe(
                p["soap:Envelope"]["soap:Body"]["ns3:performOCWithSummaryResponse"].return["ns4:GatewayResponse"]["ns4:Results"]?.["ns4:OCSummaryData"]["ns4:RentCharge"]?.["ns4:EntryDetails"] || [],
                fptsArray.map((entry) => ({
                    entryNumber:
                        pipe(
                            entry["ns4:EntryNumber"]._text,
                            Number,
                        ),
                    entryText:
                        pipe(
                            entry["ns4:EntryText"]._text,
                            normaliseEntryText,
                        ),
                    rentChargeAmountString:
                        pipe(
                            entry["ns4:Infills"]?.["ns4:Amount"] || [],
                            fptsArray.filterMap(({_text}) =>
                                _text
                                    ? option.some(_text.trim())
                                    : option.none
                            ),
                            (a) => a.join(" & "),
                        )
                })),
            );

        const vendorsLienEntries =
            p["soap:Envelope"]["soap:Body"]["ns3:performOCWithSummaryResponse"].return["ns4:GatewayResponse"]["ns4:Results"]?.["ns4:OCSummaryData"]["ns4:VendorsLien"]
                ? Entries_NormalisedEntries(p["soap:Envelope"]["soap:Body"]["ns3:performOCWithSummaryResponse"].return["ns4:GatewayResponse"]["ns4:Results"]?.["ns4:OCSummaryData"]["ns4:VendorsLien"])
                : [];

        const unilateralNotices =
            pipe(
                p["soap:Envelope"]["soap:Body"]["ns3:performOCWithSummaryResponse"].return["ns4:GatewayResponse"]["ns4:Results"]?.["ns4:OCSummaryData"]["ns4:UnilateralNoticeDetails"]?.["ns4:UnilateralNoticeEntry"] || [],
                fptsArray.map((unilateralNotice) => ({
                    entryNumber:
                        pipe(
                            unilateralNotice["ns4:UnilateralNotice"]["ns4:EntryNumber"]._text,
                            Number,
                        ),
                    entryText:
                        pipe(
                            unilateralNotice["ns4:UnilateralNotice"]["ns4:EntryText"]._text,
                            normaliseEntryText,
                        ),
                    beneficiaryEntryText:
                        pipe(
                            unilateralNotice["ns4:UnilateralNoticeBeneficiary"]["ns4:EntryText"]._text,
                            normaliseEntryText,
                        ),
                    beneficiary:
                        pipe(
                            unilateralNotice["ns4:UnilateralNoticeBeneficiary"]["ns4:Infills"]?.["ns4:Name"] || [],
                            fptsArray.filterMap(({_text}) =>
                                _text
                                    ? option.some(_text.trim())
                                    : option.none
                            ),
                            (a) => a.join(" & "),
                        ),
                }))
            );

        const deathOfProprietorNotices =
            pipe(
                p["soap:Envelope"]["soap:Body"]["ns3:performOCWithSummaryResponse"].return["ns4:GatewayResponse"]["ns4:Results"]?.["ns4:OCSummaryData"]["ns4:DeathOfProprietor"]?.["ns4:EntryDetails"] || [],
                fptsArray.map((notice) => ({
                    entryNumber:
                        pipe(
                            notice["ns4:EntryNumber"]._text,
                            Number,
                        ),
                    entryText:
                        pipe(
                            notice["ns4:EntryText"]._text,
                            normaliseEntryText,
                        ),
                    deceased:
                        pipe(
                            notice["ns4:Infills"]?.["ns4:Name"] || [],
                            fptsArray.filterMap(({_text}) =>
                                _text
                                    ? option.some(_text.trim())
                                    : option.none
                            ),
                            (a) => a.join(" & "),
                        ),
                }))
            );

        const creditorsNotices =
            p["soap:Envelope"]["soap:Body"]["ns3:performOCWithSummaryResponse"].return["ns4:GatewayResponse"]["ns4:Results"]?.["ns4:OCSummaryData"]["ns4:CreditorsNotice"]
                ? Entries_NormalisedEntries(p["soap:Envelope"]["soap:Body"]["ns3:performOCWithSummaryResponse"].return["ns4:GatewayResponse"]["ns4:Results"]?.["ns4:OCSummaryData"]["ns4:CreditorsNotice"])
                : [];

        return {
            _tag: "success" as "success",
            registerPdfBase64,
            registerTimestamp,
            registerEditionDate,
            address,
            proprietors,
            titleNumber,
            tenure: getTenure(),
            titleClass: getTitleClass(),
            firstRegistrationDate,
            lastRegistrationDate,
            groundRentsTotalPence,
            earliestLeaseApproximateExpiry,
            restrictions,
            charges,
            firstRegistrationCaution,
            isRegistered: firstRegistrationCaution.status === "none",
            lastPricePaid,
            agreedNotices,
            bankruptcyEntries,
            cautionEntries,
            deedOfPostponementEntries,
            greenOutEntries,
            homeRightsEntries,
            rentChargeEntries,
            vendorsLienEntries,
            unilateralNotices,
            deathOfProprietorNotices,
            creditorsNotices,
        };
    }
);
export type TLandRegistryOfficialCopyWithSummaryResponseSuccess = TTypeOfCodec<typeof LandRegistryOfficialCopyWithSummaryResponseSuccess>;

const LandRegistryOfficialCopyWithSummaryResponseErrorNotAvailableElectronically = overload(
    required({
        _tag: literal("error"),
        code: literal("not_available_electronically"),
    }),
    required({
        "soap:Envelope": required({
            "soap:Body": required({
                "ns3:performOCWithSummaryResponse": required({
                    return: required({
                        "ns4:GatewayResponse": required({
                            "ns4:TypeCode": required({
                                "_text": literal("20"),
                            }),
                        }),
                    }),
                }),
            }),
        })
    }),
    () => ({
        _tag: "error" as "error",
        code: "not_available_electronically" as "not_available_electronically",
    }),
);

const LandRegistryOfficialCopyWithSummaryResponseOutOfHours = overload(
    required({
        _tag: literal("out_of_hours"),
        poll_id: string(),
    }),
    required({
        "soap:Envelope": required({
            "soap:Body": required({
                "ns3:performOCWithSummaryResponse": required({
                    return: required({
                        "ns4:GatewayResponse": required({
                            "ns4:TypeCode": required({
                                "_text": literal("10"),
                            }),
                            "ns4:Acknowledgement": required({
                                "ns4:AcknowledgementDetails": required({
                                    "ns4:UniqueID": required({
                                        "_text": string(),
                                    })
                                })
                            }),
                        }),
                    }),
                }),
            }),
        })
    }),
    (p) => ({
        _tag: "out_of_hours" as "out_of_hours",
        poll_id: p["soap:Envelope"]["soap:Body"]["ns3:performOCWithSummaryResponse"].return["ns4:GatewayResponse"]["ns4:Acknowledgement"]["ns4:AcknowledgementDetails"]["ns4:UniqueID"]._text,
    }),
);

const LandRegistryOfficialCopyWithSummaryResponseErrorFault = overload(
    required({
        _tag: literal("error"),
        code: literal("fault"),
    }),
    required({
        "soap:Envelope": required({
            "soap:Body": required({
                "soap:Fault": required({
                    "faultcode": required({
                        "_text": string(),
                    })
                })
            })
        })
    }),
    () => ({
        _tag: "error" as "error",
        code: "fault" as "fault",
    }),
);

const LandRegistryOfficialCopyWithSummaryResponseErrorTitleNumberInvalid = overload(
    required({
        _tag: literal("error"),
        code: literal("title_number_invalid"),
    }),
    union([
        // This response is returned when the title number used does not match the land registry title number regex
        required({
            "soapenv:Envelope": required({
                "soapenv:Body": required({
                    "soapenv:Fault": required({
                        "faultcode": required({
                            "_text": string(),
                        })
                    })
                })
            })
        }),
        // This response is returned when the title number does match their regex but there are no title numbers for it
        required({
            "soap:Envelope": required({
                "soap:Body": required({
                    "ns3:performOCWithSummaryResponse": required({
                        return: required({
                            "ns4:GatewayResponse": required({
                                "ns4:Rejection": required({
                                    "ns4:RejectionResponse": required({
                                        "ns4:Code": required({
                                            "_text": literal("bg.title.invalid"),
                                        }),
                                    }),
                                }),
                            }),
                        }),
                    }),
                }),
            }),
        }),
    ]),
    () => ({
        _tag: "error" as "error",
        code: "title_number_invalid" as "title_number_invalid",
    }),
);

export const LandRegistryOfficialCopyWithSummaryResponse = union([
    LandRegistryOfficialCopyWithSummaryResponseSuccess,
    LandRegistryOfficialCopyWithSummaryResponseErrorNotAvailableElectronically,
    LandRegistryOfficialCopyWithSummaryResponseOutOfHours,
    LandRegistryOfficialCopyWithSummaryResponseErrorFault,
    LandRegistryOfficialCopyWithSummaryResponseErrorTitleNumberInvalid,
]);
export type TLandRegistryOfficialCopyWithSummaryResponseCodec = typeof LandRegistryOfficialCopyWithSummaryResponse;
export type TLandRegistryOfficialCopyWithSummaryResponse = TTypeOfCodec<TLandRegistryOfficialCopyWithSummaryResponseCodec>;
