import { overload } from "../../../shared/src/codecs/types/overload";
import { required } from "../../../shared/src/codecs/types/required";
import { CaseLedgerCategory, CaseLedgerType, ledgerTypeDictionary } from "../CaseLedger";

export const CaseLedgerTypeCategory = overload(
    CaseLedgerCategory,
    required({
        rate_type: CaseLedgerType,
    }),
    ({rate_type}) => ledgerTypeDictionary[rate_type].category,
);
