import * as t from "io-ts";
import * as util from "../../shared/src/util";

type  TDisplayName =
    "Lead"
    | "Prep valuation"
    | "Chase instruction"
    | "Instructed: needs market prep"
    | "Instructed: on the market"
    | "SSTC"
    | "Completed"
    | "Lost"
;

export const codec = t.union([
    t.literal('lead'),
    t.literal('prep_valuation'),
    t.literal('chase_instruction'),
    t.literal('instructed_needs_market_prep'),
    t.literal('instructed_on_the_market'),
    t.literal('sstc'),
    t.literal('completed'),
    t.literal('lost'),
]);

export type T = t.TypeOf<typeof codec>;

export const values = util.typeValuesFromCodec(codec.types as Array<t.LiteralC<T>>);

export const fromValueToDisplayName = (value: T): TDisplayName => {
    switch (value) {
        case "lead":
            return "Lead";
        case "prep_valuation":
            return "Prep valuation";
        case "chase_instruction":
            return "Chase instruction";
        case "instructed_needs_market_prep":
            return "Instructed: needs market prep";
        case "instructed_on_the_market":
            return "Instructed: on the market";
        case "sstc":
            return "SSTC";
        case "completed":
            return "Completed";
        case "lost":
            return "Lost";
        default:
            return util.requireExhaustive(value);
    }
};
