import React, { useEffect, useState } from "react";
import { onCallIfExists } from "../functions/functions";

type TUseHoverProps = {
    onHoverIn?: () => void;
    onHoverOut?: () => void;
};

export const useHover = <T extends HTMLElement>(props: TUseHoverProps): [React.RefObject<T>, boolean] => {
    
    const [isHovering, setIsHovering] = useState(false); 
    const ref = React.createRef<T>();

    const handleMouseOver = (): void => {
        setIsHovering(true);
        onCallIfExists(props.onHoverIn)();
    };

    const handleMouseOut = (): void => {
        setIsHovering(false);
        onCallIfExists(props.onHoverOut)();
    }

    useEffect(
        () => {
            const node = ref.current;
            if (node) {
                node.addEventListener("mouseenter", handleMouseOver);
                node.addEventListener("mouseleave", handleMouseOut);
                return () => {
                    node.removeEventListener("mouseenter", handleMouseOver);
                    node.removeEventListener("mouseleave", handleMouseOut);
                };
            }
            return () => null;
        },
        [ref.current]
    );

    return [ref, isHovering];
}