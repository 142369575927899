import { form, TFormCodec } from "../../../shared/src/codecs/types/form";
import { TTypeOfCodec } from "../../../shared/src/codecs/codec";
import { required, TRequiredCodec } from "../../../shared/src/codecs/types/required";
import { TAnyUuidCodec, uuid } from "../../../shared/src/codecs/types/uuid";
import { CaseLedgerEntryForm, TCaseLedgerEntryFormCodec } from "../form/CaseLedgerEntryForm";
import { array, TArrayCodec } from "../../../shared/src/codecs/types/array";
import { CaseLedgerFinal1, TCaseLedgerFinal1Codec } from "../CaseLedger";

const CaseLedgerFormPayload: TRequiredCodec<{
    case_id: TAnyUuidCodec;
}> = required({
    case_id: uuid(),
});
type TCaseLedgerFormPayloadCodec = typeof CaseLedgerFormPayload;

const CaseLedgerFormChildren: TRequiredCodec<{
    ledger_entries: TArrayCodec<TCaseLedgerEntryFormCodec>
    ledger_finalised: TCaseLedgerFinal1Codec
}> = required({
    ledger_entries: array(CaseLedgerEntryForm),
    ledger_finalised: CaseLedgerFinal1,
});
type TCaseLedgerFormChildrenCodec = typeof CaseLedgerFormChildren;

export const CaseLedgerForm: TFormCodec<TCaseLedgerFormPayloadCodec, TCaseLedgerFormChildrenCodec> = form(
    CaseLedgerFormPayload,
    CaseLedgerFormChildren,
);
export type TCaseLedgerFormCodec = typeof CaseLedgerForm;
export type TCaseLedgerForm = TTypeOfCodec<TCaseLedgerFormCodec>;
