import { union } from "../../shared/src/codecs/types/union";
import { literal } from "../../shared/src/codecs/types/literal";

export const ListingCondition1 = union([
    literal("unknown"),
    literal("good"),
    literal("good_benefit_from_refurbishment"),
    literal("poor_liveable_but_requires_work"),
    literal("poor_not_immediately_liveable"),
]);
