import { inherit, TAnyInheritedCodec, TInheritedCodec } from "../codec";
import { option } from "fp-ts/lib";
import { integer, TAnyIntegerCodec } from "./integer";

export type TAnyCurrencyIntegerCodec = TAnyInheritedCodec<"CurrencyIntegerCodec", { codec: TAnyIntegerCodec }>;

export const currencyInteger = (): TInheritedCodec<"CurrencyIntegerCodec", {codec: TAnyIntegerCodec}> => inherit(
    "CurrencyIntegerCodec",
    {codec: integer()},
    () => option.none,
    () => option.none,
);
