import * as t from "io-ts";
import * as ListingDocumentType1 from "./ListingDocumentType1";

export const codec = t.type({
    id: t.string,
    name: t.string,
    file_extension: t.string,
    document_type: ListingDocumentType1.codec,
    mime_type: t.string,
    created_at: t.string,
});

export type T = t.TypeOf<typeof codec>;
