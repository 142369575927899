import React from "react";

export default (props: React.SVGAttributes<SVGElement>) => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g clipPath="url(#clip0_6479_32378)">
<path d="M15 19L15 15L21 15L21 9L15 9L15 5L8 12L15 19ZM13 11L19 11L19 13L13 13L13 14.17L10.83 12L13 9.83L13 11ZM6 5L6 19L4 19L4 5L6 5Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_6479_32378">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>
;
