import React from "react";

export default (props: React.SVGAttributes<SVGElement>) => 
<svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g opacity="0.9">
<path d="M32.375 29.75H2.625C1.17759 29.75 0 28.5725 0 27.125V7.875C0 6.42759 1.17759 5.25 2.625 5.25H32.375C33.8225 5.25 35 6.42759 35 7.875V27.125C35 28.5725 33.8225 29.75 32.375 29.75ZM2.625 7C2.14255 7 1.75 7.39255 1.75 7.875V27.125C1.75 27.6075 2.14255 28 2.625 28H32.375C32.8575 28 33.25 27.6075 33.25 27.125V7.875C33.25 7.39255 32.8575 7 32.375 7H2.625Z" fill="none"/>
<path d="M14.8762 24.5C14.8758 24.4999 14.8754 24.4999 14.8751 24.5H6.12509C5.64187 24.5 5.25009 24.1083 5.25009 23.625C5.25009 23.5098 5.26918 22.4686 5.92997 21.4112C6.54619 20.4253 7.83927 19.25 10.5001 19.25C13.1609 19.25 14.454 20.4253 15.0702 21.4112C15.6616 22.3575 15.739 23.2907 15.7487 23.559C15.7504 23.5808 15.7512 23.6028 15.7512 23.625C15.7512 24.1083 15.3594 24.5 14.8762 24.5ZM7.20468 22.75H13.7956C13.7341 22.599 13.6543 22.4401 13.5506 22.2833C12.9872 21.4318 11.961 21 10.5001 21C9.03922 21 8.01296 21.4318 7.44962 22.2833C7.34588 22.4401 7.26609 22.599 7.20468 22.75V22.75Z" fill="none"/>
<path d="M28.8752 14H20.1252C19.6419 14 19.2502 13.6083 19.2502 13.125C19.2502 12.6417 19.6419 12.25 20.1252 12.25H28.8752C29.3584 12.25 29.7502 12.6417 29.7502 13.125C29.7502 13.6083 29.3584 14 28.8752 14Z" fill="none"/>
<path d="M27.1252 17.5H20.1252C19.6419 17.5 19.2502 17.1083 19.2502 16.625C19.2502 16.1417 19.6419 15.75 20.1252 15.75H27.1252C27.6084 15.75 28.0002 16.1417 28.0002 16.625C28.0002 17.1083 27.6084 17.5 27.1252 17.5Z" fill="none"/>
<path d="M27.1252 21H20.1252C19.6419 21 19.2502 20.6083 19.2502 20.125C19.2502 19.6417 19.6419 19.25 20.1252 19.25H27.1252C27.6084 19.25 28.0002 19.6417 28.0002 20.125C28.0002 20.6083 27.6084 21 27.1252 21Z" fill="none"/>
<path d="M10.4998 17.5C8.56993 17.5 6.99985 15.9299 6.99985 14C6.99985 12.0701 8.56993 10.5 10.4998 10.5C12.4298 10.5 13.9998 12.0701 13.9998 14C13.9998 15.9299 12.4298 17.5 10.4998 17.5ZM10.4998 12.25C9.53489 12.25 8.74985 13.035 8.74985 14C8.74985 14.965 9.53489 15.75 10.4998 15.75C11.4648 15.75 12.2498 14.965 12.2498 14C12.2498 13.035 11.4648 12.25 10.4998 12.25Z" fill="none"/>
<path d="M28.8752 24.5H20.1252C19.6419 24.5 19.2502 24.1083 19.2502 23.625C19.2502 23.1417 19.6419 22.75 20.1252 22.75H28.8752C29.3584 22.75 29.7502 23.1417 29.7502 23.625C29.7502 24.1083 29.3584 24.5 28.8752 24.5Z" fill="none"/>
</g>
</svg>
;
