import React from "react";

export default (props: React.SVGAttributes<SVGElement>) => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g clipPath="url(#clip0_6962_34223)">
<path d="M18 19L18 5C18 3.9 17.1 3 16 3L8 3C6.9 3 6 3.9 6 5L6 19C6 20.1 6.9 21 8 21L16 21C17.1 21 18 20.1 18 19ZM8 19L8 5L16 5L16 19L8 19Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_6962_34223">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>
;
