import React from "react";

export default (props: React.SVGAttributes<SVGElement>) => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g clipPath="url(#clip0_7691_37415)">
<path d="M0.270367 10.7766C-0.0900946 11.1671 -0.0900945 11.8003 0.270367 12.1908L6.14483 18.5548C6.50529 18.9453 7.08969 18.9453 7.45025 18.5548C7.81071 18.1642 7.81071 17.5311 7.45025 17.1406L2.22849 11.4837L7.45024 5.8268C7.81071 5.4363 7.81071 4.8032 7.45024 4.4126C7.08969 4.0221 6.50529 4.0221 6.14483 4.4126L0.270367 10.7766ZM24 10.4837L0.923075 10.4837L0.923075 12.4837L24 12.4837L24 10.4837Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_7691_37415">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>
;
