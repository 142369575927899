import { union } from "../../shared/src/codecs/types/union";
import { literal } from "../../shared/src/codecs/types/literal";

/**
 * IMPORTANT If you CHANGE this codec make sure to update the old codec @see {@link app/domain/models/ListingPropertyType1.ts}
 */
export const HomesAndLegalPropertyType = union([
    literal("detached_house"),
    literal("semi_detached_house"),
    literal("terraced_house"),
    literal("flat"),
    literal("bungalow"),
    literal("semi_detached_bungalow"),
    literal("detached_bungalow"),
    literal("maisonette"),
    literal("commercial"),
    literal("mixed_use"),
    literal("studio"),
    literal("garage"),
    literal("land"),
    literal("other"),
    literal("unknown"),
]);
