import { TTypeOfCodec } from "../../shared/src/codecs/codec";
import { boolean } from "../../shared/src/codecs/types/boolean";
import { email } from "../../shared/src/codecs/types/email";
import { literal } from "../../shared/src/codecs/types/literal";
import { nullCodec } from "../../shared/src/codecs/types/nullCodec";
import { postcode } from "../../shared/src/codecs/types/postcode";
import { required } from "../../shared/src/codecs/types/required";
import { string } from "../../shared/src/codecs/types/string";
import { union } from "../../shared/src/codecs/types/union";
import { uuid } from "../../shared/src/codecs/types/uuid";
import { requireExhaustive } from "../../shared/src/util";
import { CompanyType } from "./Cases";
import { Country1, Country4 } from "./Country";

export const ClientCompanyPartnership = required({
    id: uuid(),
    name: string(),
    number: string(),
    type: CompanyType,
    sub_type_listing_on_any_stock_exchange: union([boolean(), nullCodec()]),
    sub_type_aim_listed: union([boolean(), nullCodec()]),
    sub_type_majority_owned_or_consolidated_subsidiary: union([boolean(), nullCodec()]),
    sub_type_fca_regulated: union([boolean(), nullCodec()]),
    sub_type_other_non_uk_credit_or_financial_institutions: union([boolean(), nullCodec()]),
    sub_type_uk_or_eea_public_authorities: union([boolean(), nullCodec()]),
    sub_type_professionals_instructing: union([boolean(), nullCodec()]),
    sub_type_foundation: union([boolean(), nullCodec()]),
    sub_type_uk_charity: union([boolean(), nullCodec()]),
    sub_type_place_of_worship: union([boolean(), nullCodec()]),
    sub_type_state_school_or_college: union([boolean(), nullCodec()]),
    sub_type_club_or_association: union([boolean(), nullCodec()]),
    sub_type_pension_scheme: union([boolean(), nullCodec()]),
    sub_type_sovereign_wealth_funds: union([boolean(), nullCodec()]),
    address_building_name: string(),
    address_building_number: string(),
    address_sub_building_name: string(),
    address_sub_building_number: string(),
    address_street_name: string(),
    address_postcode: union([nullCodec(), postcode()]),
    address_locality: string(),
    address_city_town: string(),
    address_county: string(),
    address_district: string(),
    address_country: Country1,
});
export type TClientCompanyPartnershipCodec = typeof ClientCompanyPartnership;
export type TClientCompanyPartnership = TTypeOfCodec<TClientCompanyPartnershipCodec>;

export const ClientCompanyPartnershipStatus = union([
    literal("in_good_standing"),
    literal("review_due"),
    literal("setup_not_complete"),
    literal("id_checks_required"),
]);
export type TClientCompanyPartnershipStatusCodec = typeof ClientCompanyPartnershipStatus;
export type TClientCompanyPartnershipStatus = TTypeOfCodec<TClientCompanyPartnershipStatusCodec>;

export const ClientCompanyPartnershipUserType = union([
    literal("unknown"),
    literal("director"),
    literal("signatory"),
    literal("employee"),
]);
export type TClientCompanyPartnershipUserTypeCodec = typeof ClientCompanyPartnershipUserType;
export type TClientCompanyPartnershipUserType = TTypeOfCodec<TClientCompanyPartnershipUserTypeCodec>;
export const ClientCompanyPartnershipUserType_displayString = (type: TClientCompanyPartnershipUserType): string =>
    type === "director" ? "Director"
    : type === "signatory" ? "Signatory"
    : type === "employee" ? "Employee"
    : type === "unknown" ? "User type not set"
    : requireExhaustive(type);

export const ClientCompanyPartnershipUser = required({
    id: uuid(),
    first_name: string(),
    last_name: string(),
    type: ClientCompanyPartnershipUserType,
    email: union([nullCodec(), email()]),
});
export type TClientCompanyPartnershipUserCodec = typeof ClientCompanyPartnershipUser;
export type TClientCompanyPartnershipUser = TTypeOfCodec<TClientCompanyPartnershipUserCodec>;
