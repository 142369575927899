import { defaultTFormV2, defaultTListForm } from "../models/TForm";
import * as BuyerOffer1 from "../../../domain/models/BuyerOffer1";
import * as BuyerOffer3 from "../../../domain/models/BuyerOffer3";
import * as Listing6 from "../../../domain/models/Listing6";
import {
    TBuyerOffer1ReadOnlyForm,
    TBuyerOffer3Form,
    TBuyerOffer2ReadOnlyFormList,
    TListing6ReadOnlyForm,
} from "./../models/TFormModels";

export class C {
    latestOfferForm: TBuyerOffer1ReadOnlyForm = defaultTFormV2(BuyerOffer1.newDefault(), {}, {});
    newOfferForm: TBuyerOffer3Form = defaultTFormV2({}, BuyerOffer3.newDefault(), {}, "success");
    allOffersForm: TBuyerOffer2ReadOnlyFormList = defaultTListForm({}, {});
    listingForm: TListing6ReadOnlyForm = defaultTFormV2(Listing6.newDefault(), {}, {});
}
