import * as t from "io-ts";
import * as JsonResponse1 from "./JsonResponse1";
import * as ProbateCase2 from "./ProbateCase2";

export const codec = JsonResponse1.createCodec(
    ProbateCase2.codec,
    t.type({}),
);

export type T = t.TypeOf<typeof codec>;

export const newDefault = (): T => ({
    data: ProbateCase2.newDefault(),
    meta: {},
});
