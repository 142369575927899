import { TTypeOfCodec } from "../../../shared/src/codecs/codec";
import { array, TArrayCodec } from "../../../shared/src/codecs/types/array";
import { form, TFormCodec } from "../../../shared/src/codecs/types/form";
import { intersection, TIntersectionCodec } from "../../../shared/src/codecs/types/intersection";
import { literal, TLiteralCodec } from "../../../shared/src/codecs/types/literal";
import { required, TRequiredCodec } from "../../../shared/src/codecs/types/required";
import { union } from "../../../shared/src/codecs/types/union";
import {
    CaseCustomerSatisfactionAssignmentAction,
    CaseCustomerSatisfactionDeferAction,
    CaseCustomerSatisfactionEmailAction, 
    CaseCustomerSatisfactionFormalisedAsComplaintAction, 
    CaseCustomerSatisfactionNewAssignmentAction, 
    CaseCustomerSatisfactionNewFormalisedAsComplaint, 
    CaseCustomerSatisfactionNewNote, 
    CaseCustomerSatisfactionNewRaisedWithOmbudsman, 
    CaseCustomerSatisfactionNewSentimentAction, 
    CaseCustomerSatisfactionNoteAction, 
    CaseCustomerSatisfactionNoteTogglePin, 
    CaseCustomerSatisfactionPhoneCallAction, 
    CaseCustomerSatisfactionRaisedWithOmbudsmanAction, 
    CaseCustomerSatisfactionSentimentAction, 
    TCaseCustomerSatisfactionAssignmentActionCodec,
    TCaseCustomerSatisfactionDeferActionCodec,
    TCaseCustomerSatisfactionEmailActionCodec, 
    TCaseCustomerSatisfactionFormalisedAsComplaintActionCodec, 
    TCaseCustomerSatisfactionNewAssignmentActionCodec, 
    TCaseCustomerSatisfactionNewFormalisedAsComplaintCodec, 
    TCaseCustomerSatisfactionNewNoteCodec, 
    TCaseCustomerSatisfactionNewRaisedWithOmbudsmanCodec, 
    TCaseCustomerSatisfactionNewSentimentActionCodec, 
    TCaseCustomerSatisfactionNoteActionCodec, 
    TCaseCustomerSatisfactionNoteTogglePinCodec, 
    TCaseCustomerSatisfactionPhoneCallActionCodec, 
    TCaseCustomerSatisfactionRaisedWithOmbudsmanActionCodec, 
    TCaseCustomerSatisfactionSentimentActionCodec 
} from "../CaseCustomerSatisfaction";
import { EmptyObject, TEmptyObjectCodec } from "../EmptyObject";
import { TUuidObjectCodec, UuidObject } from "../Id";
import { DetailedEmailForm, TDetailedEmailFormCodec } from "./TriageForm";

export const CaseCustomerSatisfactionDeleteFormalisedAsComplaintForm: TFormCodec<TUuidObjectCodec, TEmptyObjectCodec> = form(
    UuidObject,
    EmptyObject,
);
export type TCaseCustomerSatisfactionDeleteFormalisedAsComplaintFormCodec = typeof CaseCustomerSatisfactionDeleteFormalisedAsComplaintForm;
export type TCaseCustomerSatisfactionDeleteFormalisedAsComplaintForm = TTypeOfCodec<TCaseCustomerSatisfactionDeleteFormalisedAsComplaintFormCodec>;

export const CaseCustomerSatisfactionFormalisedAsComplaintActionForm: TFormCodec<TUuidObjectCodec, TRequiredCodec<{
    tag: TLiteralCodec<"formalised_as_complaint">,
    action: TCaseCustomerSatisfactionFormalisedAsComplaintActionCodec,
    delete_form: TCaseCustomerSatisfactionDeleteFormalisedAsComplaintFormCodec,
}>> = form(
    UuidObject,
    required({
        tag: literal("formalised_as_complaint"),
        action: CaseCustomerSatisfactionFormalisedAsComplaintAction,
        delete_form: CaseCustomerSatisfactionDeleteFormalisedAsComplaintForm,
    }),
);
export type TCaseCustomerSatisfactionFormalisedAsComplaintActionFormCodec = typeof CaseCustomerSatisfactionFormalisedAsComplaintActionForm;
export type TCaseCustomerSatisfactionFormalisedAsComplaintActionForm = TTypeOfCodec<TCaseCustomerSatisfactionFormalisedAsComplaintActionFormCodec>;
const isCaseCustomerSatisfactionFormalisedAsComplaintActionForm = (p: TCaseCustomerSatisfactionFormAction): p is TCaseCustomerSatisfactionFormalisedAsComplaintActionForm => p.children.tag === "formalised_as_complaint";

export const CaseCustomerSatisfactionDeleteRaisedWithOmbudsmanForm: TFormCodec<TUuidObjectCodec, TEmptyObjectCodec> = form(
    UuidObject,
    EmptyObject,
);
export type TCaseCustomerSatisfactionDeleteRaisedWithOmbudsmanFormCodec = typeof CaseCustomerSatisfactionDeleteRaisedWithOmbudsmanForm;
export type TCaseCustomerSatisfactionDeleteRaisedWithOmbudsmanForm = TTypeOfCodec<TCaseCustomerSatisfactionDeleteRaisedWithOmbudsmanFormCodec>;

export const CaseCustomerSatisfactionRaisedWithOmbudsmanActionForm: TFormCodec<TUuidObjectCodec, TRequiredCodec<{
    tag: TLiteralCodec<"raised_with_ombudsman">,
    action: TCaseCustomerSatisfactionRaisedWithOmbudsmanActionCodec,
    delete_form: TCaseCustomerSatisfactionDeleteRaisedWithOmbudsmanFormCodec,
}>> = form(
    UuidObject,
    required({
        tag: literal("raised_with_ombudsman"),
        action: CaseCustomerSatisfactionRaisedWithOmbudsmanAction,
        delete_form: CaseCustomerSatisfactionDeleteRaisedWithOmbudsmanForm,
    }),
);
export type TCaseCustomerSatisfactionRaisedWithOmbudsmanActionFormCodec = typeof CaseCustomerSatisfactionRaisedWithOmbudsmanActionForm;
export type TCaseCustomerSatisfactionRaisedWithOmbudsmanActionForm = TTypeOfCodec<TCaseCustomerSatisfactionRaisedWithOmbudsmanActionFormCodec>;
const isCaseCustomerSatisfactionRaisedWithOmbudsmanActionForm = (p: TCaseCustomerSatisfactionFormAction): p is TCaseCustomerSatisfactionRaisedWithOmbudsmanActionForm => p.children.tag === "raised_with_ombudsman";

export const CaseCustomerSatisfactionAssignmentActionForm: TFormCodec<TEmptyObjectCodec, TRequiredCodec<{
    tag: TLiteralCodec<"assignment">,
    action: TCaseCustomerSatisfactionAssignmentActionCodec,
}>> = form(
    EmptyObject,
    required({
        tag: literal("assignment"),
        action: CaseCustomerSatisfactionAssignmentAction,
    }),
);
export type TCaseCustomerSatisfactionAssignmentActionFormCodec = typeof CaseCustomerSatisfactionAssignmentActionForm;
export type TCaseCustomerSatisfactionAssignmentActionForm = TTypeOfCodec<TCaseCustomerSatisfactionAssignmentActionFormCodec>;
const isCaseCustomerSatisfactionAssignmentActionForm = (p: TCaseCustomerSatisfactionFormAction): p is TCaseCustomerSatisfactionAssignmentActionForm => p.children.tag === "assignment";

export const CaseCustomerSatisfactionNoteTogglePinForm: TFormCodec<
    TIntersectionCodec<[
        TUuidObjectCodec,
        TCaseCustomerSatisfactionNoteTogglePinCodec,
    ]>,
    TEmptyObjectCodec
> = form(
    intersection([
        UuidObject,
        CaseCustomerSatisfactionNoteTogglePin,
    ]),
    EmptyObject
);
export type TCaseCustomerSatisfactionNoteTogglePinFormCodec = typeof CaseCustomerSatisfactionNoteTogglePinForm;
export type TCaseCustomerSatisfactionNoteTogglePinForm = TTypeOfCodec<TCaseCustomerSatisfactionNoteTogglePinFormCodec>;

export const CaseCustomerSatisfactionDeleteNoteForm: TFormCodec<TUuidObjectCodec, TEmptyObjectCodec> = form(
    UuidObject,
    EmptyObject,
);
export type TCaseCustomerSatisfactionDeleteNoteFormCodec = typeof CaseCustomerSatisfactionDeleteNoteForm;
export type TCaseCustomerSatisfactionDeleteNoteForm = TTypeOfCodec<TCaseCustomerSatisfactionDeleteNoteFormCodec>;

export const CaseCustomerSatisfactionNoteActionForm: TFormCodec<TUuidObjectCodec, TRequiredCodec<{
    tag: TLiteralCodec<"note">,
    action: TCaseCustomerSatisfactionNoteActionCodec,
    toggle_pin_form: TCaseCustomerSatisfactionNoteTogglePinFormCodec,
    delete_form: TCaseCustomerSatisfactionDeleteNoteFormCodec,
}>> = form(
    UuidObject,
    required({
        tag: literal("note"),
        action: CaseCustomerSatisfactionNoteAction,
        toggle_pin_form: CaseCustomerSatisfactionNoteTogglePinForm,
        delete_form: CaseCustomerSatisfactionDeleteNoteForm,
    }),
);
export type TCaseCustomerSatisfactionNoteActionFormCodec = typeof CaseCustomerSatisfactionNoteActionForm;
export type TCaseCustomerSatisfactionNoteActionForm = TTypeOfCodec<TCaseCustomerSatisfactionNoteActionFormCodec>;
const isCaseCustomerSatisfactionNoteActionForm = (p: TCaseCustomerSatisfactionFormAction): p is TCaseCustomerSatisfactionNoteActionForm => p.children.tag === "note";

export const CaseCustomerSatisfactionEmailActionForm: TFormCodec<TEmptyObjectCodec, TRequiredCodec<{
    tag: TLiteralCodec<"email">,
    action: TCaseCustomerSatisfactionEmailActionCodec,
    detailed_email_form: TDetailedEmailFormCodec,
}>> = form(
    EmptyObject,
    required({
        tag: literal("email"),
        action: CaseCustomerSatisfactionEmailAction,
        detailed_email_form: DetailedEmailForm,
    }),
);
export type TCaseCustomerSatisfactionEmailActionFormCodec = typeof CaseCustomerSatisfactionEmailActionForm;
export type TCaseCustomerSatisfactionEmailActionForm = TTypeOfCodec<TCaseCustomerSatisfactionEmailActionFormCodec>;
const isCaseCustomerSatisfactionEmailActionForm = (p: TCaseCustomerSatisfactionFormAction): p is TCaseCustomerSatisfactionEmailActionForm => p.children.tag === "email";

export const CaseCustomerSatisfactionPhoneCallActionForm: TFormCodec<TEmptyObjectCodec, TRequiredCodec<{
    tag: TLiteralCodec<"phone_call">,
    action: TCaseCustomerSatisfactionPhoneCallActionCodec,
}>> = form(
    EmptyObject,
    required({
        tag: literal("phone_call"),
        action: CaseCustomerSatisfactionPhoneCallAction,
    }),
);
export type TCaseCustomerSatisfactionPhoneCallActionFormCodec = typeof CaseCustomerSatisfactionPhoneCallActionForm;
export type TCaseCustomerSatisfactionPhoneCallActionForm = TTypeOfCodec<TCaseCustomerSatisfactionPhoneCallActionFormCodec>;
const isCaseCustomerSatisfactionPhoneCallActionForm = (p: TCaseCustomerSatisfactionFormAction): p is TCaseCustomerSatisfactionPhoneCallActionForm => p.children.tag === "phone_call";

export const CaseCustomerSatisfactionDeleteSentimentForm: TFormCodec<TUuidObjectCodec, TEmptyObjectCodec> = form(
    UuidObject,
    EmptyObject,
);
export type TCaseCustomerSatisfactionDeleteSentimentFormCodec = typeof CaseCustomerSatisfactionDeleteSentimentForm;
export type TCaseCustomerSatisfactionDeleteSentimentForm = TTypeOfCodec<TCaseCustomerSatisfactionDeleteSentimentFormCodec>;

export const CaseCustomerSatisfactionSentimentActionForm: TFormCodec<TUuidObjectCodec, TRequiredCodec<{
    tag: TLiteralCodec<"sentiment">,
    action: TCaseCustomerSatisfactionSentimentActionCodec,
    delete_form: TCaseCustomerSatisfactionDeleteSentimentFormCodec,
}>> = form(
    UuidObject,
    required({
        tag: literal("sentiment"),
        action: CaseCustomerSatisfactionSentimentAction,
        delete_form: CaseCustomerSatisfactionDeleteSentimentForm,
    }),
);
export type TCaseCustomerSatisfactionSentimentActionFormCodec = typeof CaseCustomerSatisfactionSentimentActionForm;
export type TCaseCustomerSatisfactionSentimentActionForm = TTypeOfCodec<TCaseCustomerSatisfactionSentimentActionFormCodec>;
export const isCaseCustomerSatisfactionSentimentActionForm = (p: TCaseCustomerSatisfactionFormAction): p is TCaseCustomerSatisfactionSentimentActionForm => 
    p.children.tag === "sentiment"
;

export const CaseCustomerSatisfactionDeferActionForm: TFormCodec<TEmptyObjectCodec, TRequiredCodec<{
    tag: TLiteralCodec<"defer">,
    action: TCaseCustomerSatisfactionDeferActionCodec,
}>> = form(
    EmptyObject,
    required({
        tag: literal("defer"),
        action: CaseCustomerSatisfactionDeferAction,
    }),
);
export type TCaseCustomerSatisfactionDeferActionFormCodec = typeof CaseCustomerSatisfactionDeferActionForm;
export type TCaseCustomerSatisfactionDeferActionForm = TTypeOfCodec<TCaseCustomerSatisfactionDeferActionFormCodec>;
export const isCaseCustomerSatisfactionDeferActionForm = (p: TCaseCustomerSatisfactionFormAction): p is TCaseCustomerSatisfactionDeferActionForm => 
    p.children.tag === "defer"
;

export const foldGrievanceFormAction = <T extends unknown>(fp: {
    onFormalisedAsComplaintAction: (a: TCaseCustomerSatisfactionFormalisedAsComplaintActionForm) => T,
    onRaisedWithOmbudsmanAction: (a: TCaseCustomerSatisfactionRaisedWithOmbudsmanActionForm) => T,
    onAssignmentAction: (a: TCaseCustomerSatisfactionAssignmentActionForm) => T,
    onNoteAction: (a: TCaseCustomerSatisfactionNoteActionForm) => T,
    onEmailAction: (a: TCaseCustomerSatisfactionEmailActionForm) => T,
    onPhoneCallAction: (a: TCaseCustomerSatisfactionPhoneCallActionForm) => T,
    onSentimentAction: (a: TCaseCustomerSatisfactionSentimentActionForm) => T,
    onDeferAction: (a: TCaseCustomerSatisfactionDeferActionForm) => T,
    onDefault: () => T,
}) => (action: TCaseCustomerSatisfactionFormAction) =>
    isCaseCustomerSatisfactionFormalisedAsComplaintActionForm(action) ? fp.onFormalisedAsComplaintAction(action)
    : isCaseCustomerSatisfactionRaisedWithOmbudsmanActionForm(action) ? fp.onRaisedWithOmbudsmanAction(action)
    : isCaseCustomerSatisfactionAssignmentActionForm(action) ? fp.onAssignmentAction(action)
    : isCaseCustomerSatisfactionNoteActionForm(action) ? fp.onNoteAction(action)
    : isCaseCustomerSatisfactionEmailActionForm(action) ? fp.onEmailAction(action)
    : isCaseCustomerSatisfactionPhoneCallActionForm(action) ? fp.onPhoneCallAction(action)
    : isCaseCustomerSatisfactionSentimentActionForm(action) ? fp.onSentimentAction(action)
    : isCaseCustomerSatisfactionDeferActionForm(action) ? fp.onDeferAction(action)
    : fp.onDefault()
;

export const CaseCustomerSatisfactionNewNoteForm: TFormCodec<TCaseCustomerSatisfactionNewNoteCodec, TEmptyObjectCodec> = form(
    CaseCustomerSatisfactionNewNote,
    EmptyObject,
);
export type TCaseCustomerSatisfactionNewNoteFormCodec = typeof CaseCustomerSatisfactionNewNoteForm;
export type TCaseCustomerSatisfactionNewNoteForm = TTypeOfCodec<TCaseCustomerSatisfactionNewNoteFormCodec>;

export const CaseCustomerSatisfactionNewDeferForm: TFormCodec<TUuidObjectCodec, TEmptyObjectCodec> = form(
    UuidObject,
    EmptyObject,
);
export type TCaseCustomerSatisfactionNewDeferFormCodec = typeof CaseCustomerSatisfactionNewDeferForm;
export type TCaseCustomerSatisfactionNewDeferForm = TTypeOfCodec<TCaseCustomerSatisfactionNewDeferFormCodec>;

export const CaseCustomerSatisfactionNewFormalisedAsComplaintForm: TFormCodec<TCaseCustomerSatisfactionNewFormalisedAsComplaintCodec, TEmptyObjectCodec> = form(
    CaseCustomerSatisfactionNewFormalisedAsComplaint,
    EmptyObject,
);
export type TCaseCustomerSatisfactionNewFormalisedAsComplaintFormCodec = typeof CaseCustomerSatisfactionNewFormalisedAsComplaintForm;
export type TCaseCustomerSatisfactionNewFormalisedAsComplaintForm = TTypeOfCodec<TCaseCustomerSatisfactionNewFormalisedAsComplaintFormCodec>;

export const CaseCustomerSatisfactionNewRaisedWithOmbudsmanForm: TFormCodec<TCaseCustomerSatisfactionNewRaisedWithOmbudsmanCodec, TEmptyObjectCodec> = form(
    CaseCustomerSatisfactionNewRaisedWithOmbudsman,
    EmptyObject,
);
export type TCaseCustomerSatisfactionNewRaisedWithOmbudsmanFormCodec = typeof CaseCustomerSatisfactionNewRaisedWithOmbudsmanForm;
export type TCaseCustomerSatisfactionNewRaisedWithOmbudsmanForm = TTypeOfCodec<TCaseCustomerSatisfactionNewRaisedWithOmbudsmanFormCodec>;

export const CaseCustomerSatisfactionNewSentimentForm: TFormCodec<TCaseCustomerSatisfactionNewSentimentActionCodec, TEmptyObjectCodec> = form(
    CaseCustomerSatisfactionNewSentimentAction,
    EmptyObject,
);
export type TCaseCustomerSatisfactionNewSentimentFormCodec = typeof CaseCustomerSatisfactionNewSentimentForm;
export type TCaseCustomerSatisfactionNewSentimentForm = TTypeOfCodec<TCaseCustomerSatisfactionNewSentimentFormCodec>;

export const CaseCustomerSatisfactionNewAssignmentForm: TFormCodec<TCaseCustomerSatisfactionNewAssignmentActionCodec, TEmptyObjectCodec> = form(
    CaseCustomerSatisfactionNewAssignmentAction,
    EmptyObject,
);
export type TCaseCustomerSatisfactionNewAssignmentFormCodec = typeof CaseCustomerSatisfactionNewAssignmentForm;
export type TCaseCustomerSatisfactionNewAssignmentForm = TTypeOfCodec<TCaseCustomerSatisfactionNewAssignmentFormCodec>;

export const CaseCustomerSatisfactionFormAction = union([
    CaseCustomerSatisfactionFormalisedAsComplaintActionForm,
    CaseCustomerSatisfactionRaisedWithOmbudsmanActionForm,
    CaseCustomerSatisfactionAssignmentActionForm,
    CaseCustomerSatisfactionNoteActionForm,
    CaseCustomerSatisfactionEmailActionForm,
    CaseCustomerSatisfactionPhoneCallActionForm,
    CaseCustomerSatisfactionSentimentActionForm,
    CaseCustomerSatisfactionDeferActionForm,
]);
export type TCaseCustomerSatisfactionFormActionCodec = typeof CaseCustomerSatisfactionFormAction;
export type TCaseCustomerSatisfactionFormAction = TTypeOfCodec<TCaseCustomerSatisfactionFormActionCodec>;

export const CaseCustomerSatisfactionForm: TFormCodec<
    TUuidObjectCodec, 
    TRequiredCodec<{
        actions: TArrayCodec<TCaseCustomerSatisfactionFormActionCodec>,
        assign_to_users_actions: TArrayCodec<TCaseCustomerSatisfactionNewAssignmentFormCodec>;
        create_note_action: TCaseCustomerSatisfactionNewNoteFormCodec,
        create_formalised_as_complaint_action: TCaseCustomerSatisfactionNewFormalisedAsComplaintFormCodec,
        create_raised_with_ombudsman_action: TCaseCustomerSatisfactionNewRaisedWithOmbudsmanFormCodec,
        create_sentiment_action: TCaseCustomerSatisfactionNewSentimentFormCodec,
        create_defer_action: TCaseCustomerSatisfactionNewDeferFormCodec,
    }>
> = form(
    UuidObject,
    required({
        actions: array(CaseCustomerSatisfactionFormAction),
        assign_to_users_actions: array(CaseCustomerSatisfactionNewAssignmentForm),
        create_note_action: CaseCustomerSatisfactionNewNoteForm,
        create_formalised_as_complaint_action: CaseCustomerSatisfactionNewFormalisedAsComplaintForm,
        create_raised_with_ombudsman_action: CaseCustomerSatisfactionNewRaisedWithOmbudsmanForm,
        create_sentiment_action: CaseCustomerSatisfactionNewSentimentForm,
        create_defer_action: CaseCustomerSatisfactionNewDeferForm,
    }),
);
export type TCaseCustomerSatisfactionFormCodec = typeof CaseCustomerSatisfactionForm;
export type TCaseCustomerSatisfactionForm = TTypeOfCodec<TCaseCustomerSatisfactionFormCodec>;
