import { boolean } from "../../shared/src/codecs/types/boolean";
import { dateTime } from "../../shared/src/codecs/types/dateTime";
import { nullCodec } from "../../shared/src/codecs/types/nullCodec";
import { phoneNumber } from "../../shared/src/codecs/types/phoneNumber";
import { required } from "../../shared/src/codecs/types/required";
import { union } from "../../shared/src/codecs/types/union";
import { uuid } from "../../shared/src/codecs/types/uuid";

export const PhoneNumber1 = required({
    id: uuid(),
    primary_number: boolean(),
    user_id: uuid(),
    phone_number: union([phoneNumber(), nullCodec()]),
    created_at: dateTime(),
    is_verified: boolean(),
});
export type TPhoneNumber1Codec = typeof PhoneNumber1;