import * as rxjs from "rxjs";
import * as t from "io-ts";
import { option } from "fp-ts";
import * as fetchWrapper from "./../wrappers/fetch";
import * as FirstPartyFetchResponse from "./../../../domain/models/FirstPartyFetchResponse";
import * as JsonResponse1 from "./../../../domain/models/JsonResponse1";
import * as JsonResponseMeta1 from "./../../../domain/models/JsonResponseMeta1";

export type TFormRequest<V extends Record<string, unknown>> =
    FirstPartyFetchResponse.T<
        JsonResponse1.T<V, {}>
        | void
    >;

export const payloadFetch =
    <P extends Record<string, unknown>>(
        url: string,
        method: fetchWrapper.TFetchMethods,
        payload: unknown,
        newDefault: P,
        codec: t.Any,
    ) =>
        (): rxjs.Observable<FirstPartyFetchResponse.T<JsonResponse1.T<P, {}>>> =>
            rxjs.from(
                fetchWrapper.json<JsonResponse1.T<P, {}>>({
                    requestParams: {
                        url: new URL(url, env.REACT_APP_API_URL).href,
                        method,
                        body: method === "GET" ? option.none : option.some(JSON.stringify(payload)),
                    },
                    expectedTypeCodec: JsonResponse1.createCodec(codec, t.type({})),
                    defaultResponse: JsonResponse1.create(newDefault),
                })()
            );

export const payloadFetchArray =
    <P extends JsonResponse1.T<P["data"], JsonResponseMeta1.T>>(
        url: string,
        method: fetchWrapper.TFetchMethods,
        payload: unknown,
        newDefault: P,
        codec: t.Any,
    ) =>
        (): rxjs.Observable<FirstPartyFetchResponse.T<P>> =>
            rxjs.from(
                fetchWrapper.json<P>({
                    requestParams: {
                        url: new URL(url, env.REACT_APP_API_URL).href,
                        method,
                        body: method === "GET" ? option.none : option.some(JSON.stringify(payload)),
                    },
                    expectedTypeCodec: codec,
                    defaultResponse: newDefault,
                })()
            );

export const payloadFetchNoContent =
    (
        url: string,
        method: fetchWrapper.TFetchMethods,
        payload: unknown,
    ) =>
        (): rxjs.Observable<FirstPartyFetchResponse.T<void>> =>
            rxjs.from(
                fetchWrapper.noContent({
                    requestParams: {
                        url: new URL(url, env.REACT_APP_API_URL).href,
                        method,
                        body: option.some(JSON.stringify(payload)),
                    },
                })()
            );

export const rxjsPayloadFrom = <T>(fromOption: option.Option<T>): rxjs.Observable<T> =>
    option.fold(
        () => rxjs.EMPTY,
        (payload: T) => rxjs.of(payload)
    )(fromOption);
