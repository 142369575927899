import { TTypeOfCodec, TTypeOfNewDefault } from "../../shared/src/codecs/codec";
import { email } from "../../shared/src/codecs/types/email";
import { intersection } from "../../shared/src/codecs/types/intersection";
import { literal } from "../../shared/src/codecs/types/literal";
import { required } from "../../shared/src/codecs/types/required";
import { requiredFlatOverloaded } from "../../shared/src/codecs/types/requiredFlatOverloaded";
import { string } from "../../shared/src/codecs/types/string";
import { union } from "../../shared/src/codecs/types/union";
import { UserName } from "./overloads/UserName";

export const CaseMemberRole = union([
    literal("other_side_conveyancer"),
    literal("leasehold_management_company"),
    literal("estate_agent"),
    literal("client"),
    literal("other_sides_client"),
    literal("related_transaction_conveyancer"),
])
export type TCaseMemberRole = TTypeOfCodec<typeof CaseMemberRole>;
export type TCaseMemberRoleNewDefault = TTypeOfNewDefault<typeof CaseMemberRole>;

export const CaseMemberRoleToCopyText = (role: TCaseMemberRole): string => {
    switch (role) {
        case "client":
            return "Client";
        case "estate_agent":
            return "Estate agent";
        case "leasehold_management_company":
            return "Leasehold management";
        case "other_side_conveyancer":
            return "O/S Conveyancer";
        case "other_sides_client":
            return "O/S Client"
        case "related_transaction_conveyancer":
            return "Related transaction conveyancer";
        default:
            return "";
    }
};

export const CaseMember = required({
    role: CaseMemberRole,
    name: string(),
    email: email(),
});
export type TCaseMember = TTypeOfCodec<typeof CaseMember>;
export type TCaseMemberNewDefault = TTypeOfNewDefault<typeof CaseMember>;

export const CaseMemberName = intersection([
    required({
        role: CaseMemberRole,
    }),
    requiredFlatOverloaded({
        name: UserName,
    })
]);
export type TCaseMemberName = TTypeOfCodec<typeof CaseMemberName>;