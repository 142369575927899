import * as Function from "../Function";
import * as Error from "../Error";
import * as ErrorCode from "../ErrorCode";
import { option, array } from "fp-ts/lib";

// byFunction only supports string and number so that developers don't accidentally assume deep matching (Which is not implmented)
export const arrayUniqueBy = (byFunction: (v: unknown) => string | number): Function.TValidationFunction => (value: unknown): option.Option<Error.TValidationError> =>
    typeof value === "undefined" || (
        Array.isArray(value)
        && array.map(byFunction)(value).filter((val, index, self) => self.indexOf(val) === index).length === value.length
    )
        ? option.none
        : option.some([[ErrorCode.validationErrorCodeConstants.ARRAY_NOT_UNIQUE_VALIDATION]]);
