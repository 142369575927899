import * as t from "io-ts";
import * as Case3 from "./Case3";

export const codec = t.type({
    id: t.string,
    ...Case3.codec.props,
});

export type T = t.TypeOf<typeof codec>;

export const newDefault = (): T => ({
    id: "",
    ...Case3.newDefault(),
});
