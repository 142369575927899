import { isAFunction } from "../functions";

type TOnKeyPressProps<T> = {
    onEscape?: TEventFunction<T>;
    onEnter?: TEventFunction<T>;
    onTab?: TEventFunction<T>;
    onBackspace?: TEventFunction<T>; 

    onElse?: TEventFunction<T>; 
}

type TEventFunction<T> = (event: TEvent<T>) => void;

type TEvent<T> = React.KeyboardEvent<T>;

export const onKeyPress = <T>(props: TOnKeyPressProps<T>) => 
    (event: TEvent<T> ) => {

        if (event.key === "Escape" && isAFunction<TEventFunction<T>>(props.onEscape)) {
            props.onEscape(event);
        }
        
        else if (event.key === "Enter" && isAFunction<TEventFunction<T>>(props.onEnter)) {
            props.onEnter(event);
        }
        
        else if (event.key === "Tab" && isAFunction<TEventFunction<T>>(props.onTab)) {
            props.onTab(event);
        }
        
        else if (event.key === "Backspace" && isAFunction<TEventFunction<T>>(props.onBackspace)) {
            props.onBackspace(event);
        }

        else if (isAFunction<TEventFunction<T>>(props.onElse)) {
            props.onElse(event);
        }
    }
