import * as ErrorCode from "./ErrorCode";

export type T = {
    type: ErrorCode.T;
    error?: Error;
};

export const create = (code: ErrorCode.T): T => (
    {
        type: code,
    }
);
