import { TTypeOfCodec } from "../../../shared/src/codecs/codec";
import { array, TArrayCodec } from "../../../shared/src/codecs/types/array";
import { boolean, TBooleanCodec } from "../../../shared/src/codecs/types/boolean";
import { date, TDateCodec } from "../../../shared/src/codecs/types/date";
import { fileIO, TFileIOCodec } from "../../../shared/src/codecs/types/fileIO";
import { form, TFormCodec } from "../../../shared/src/codecs/types/form";
import { intersection, TIntersectionCodec } from "../../../shared/src/codecs/types/intersection";
import { nullCodec, TNullCodec } from "../../../shared/src/codecs/types/nullCodec";
import { required, TRequiredCodec } from "../../../shared/src/codecs/types/required";
import { requiredFlatOverloaded, TRequiredFlatOverloadedCodec } from "../../../shared/src/codecs/types/requiredFlatOverloaded";
import { string, TAnyStringCodec } from "../../../shared/src/codecs/types/string";
import { TUnionCodec, union } from "../../../shared/src/codecs/types/union";
import { TUuidCodec, uuid } from "../../../shared/src/codecs/types/uuid";
import { ClientCompanyPartnership, ClientCompanyPartnershipStatus, ClientCompanyPartnershipUser, TClientCompanyPartnershipCodec, TClientCompanyPartnershipStatusCodec, TClientCompanyPartnershipUserCodec } from "../ClientCompanyPartnership";
import { EmptyObject, TEmptyObjectCodec } from "../EmptyObject";
import { TUuidObjectCodec, UuidObject } from "../Id";
import { CreateLegalFileDownloadUrl } from "../overloads/LegalFileDownloadUrl";
import { TUsersIdChecksImageStringCodec, UsersIdChecksImageString } from "../overloads/UsersIdChecksImage";
import { TUserPhoneNumber1Codec, UserPhoneNumber1 } from "../UserPhoneNumber";
import { TUsersIdCheck7Codec, TUsersIdCheck8Codec, UsersIdCheck7, UsersIdCheck8 } from "../UsersIdCheck";

export const ClientCompanyPartnershipCreateForm: TFormCodec<TEmptyObjectCodec, TEmptyObjectCodec> = form(EmptyObject, EmptyObject);
export type TClientCompanyPartnershipCreateFormCodec = typeof ClientCompanyPartnershipCreateForm;
export type TClientCompanyPartnershipCreateForm = TTypeOfCodec<TClientCompanyPartnershipCreateFormCodec>;

export const ClientCompanyPartnershipDeleteForm: TFormCodec<TUuidObjectCodec, TEmptyObjectCodec> = form(UuidObject, EmptyObject);
export type TClientCompanyPartnershipDeleteFormCodec = typeof ClientCompanyPartnershipDeleteForm;
export type TClientCompanyPartnershipDeleteForm = TTypeOfCodec<TClientCompanyPartnershipDeleteFormCodec>;

export const ClientCompanyPartnershipReviewForm: TFormCodec<TUuidObjectCodec, TEmptyObjectCodec> = form(UuidObject, EmptyObject);
export type TClientCompanyPartnershipReviewFormCodec = typeof ClientCompanyPartnershipReviewForm;
export type TClientCompanyPartnershipReviewForm = TTypeOfCodec<TClientCompanyPartnershipReviewFormCodec>;

export const ClientCompanyPartnershipUserCreateForm: TFormCodec<TUuidObjectCodec, TEmptyObjectCodec> = form(UuidObject, EmptyObject);
export type TClientCompanyPartnershipUserCreateFormCodec = typeof ClientCompanyPartnershipUserCreateForm;
export type TClientCompanyPartnershipUserCreateForm = TTypeOfCodec<TClientCompanyPartnershipUserCreateFormCodec>;

export const ClientCompanyPartnershipUserPhoneNumberCreateForm: TFormCodec<TUuidObjectCodec, TRequiredCodec<{
    client_company_id: TUuidCodec,
    client_company_user_id: TUuidCodec,
}>> = form(UuidObject, required({
    client_company_id: uuid(),
    client_company_user_id: uuid(),
}));
export type TClientCompanyPartnershipUserPhoneNumberCreateFormCodec = typeof ClientCompanyPartnershipUserPhoneNumberCreateForm;
export type TClientCompanyPartnershipUserPhoneNumberCreateForm = TTypeOfCodec<TClientCompanyPartnershipUserPhoneNumberCreateFormCodec>;

export const ClientCompanyPartnershipUserPhoneNumberDeleteForm: TFormCodec<TUuidObjectCodec, TRequiredCodec<{
    client_company_id: TUuidCodec,
    client_company_user_id: TUuidCodec,
}>> = form(UuidObject, required({
    client_company_id: uuid(),
    client_company_user_id: uuid(),
}));
export type TClientCompanyPartnershipUserPhoneNumberDeleteFormCodec = typeof ClientCompanyPartnershipUserPhoneNumberDeleteForm;
export type TClientCompanyPartnershipUserPhoneNumberDeleteForm = TTypeOfCodec<TClientCompanyPartnershipUserPhoneNumberDeleteFormCodec>;

export const ClientCompanyPartnershipUserPhoneNumberMakePrimaryForm: TFormCodec<TUuidObjectCodec, TRequiredCodec<{
    client_company_id: TUuidCodec,
    client_company_user_id: TUuidCodec,
}>> = form(UuidObject, required({
    client_company_id: uuid(),
    client_company_user_id: uuid(),
}));
export type TClientCompanyPartnershipUserPhoneNumberMakePrimaryFormCodec = typeof ClientCompanyPartnershipUserPhoneNumberMakePrimaryForm;
export type TClientCompanyPartnershipUserPhoneNumberMakePrimaryForm = TTypeOfCodec<TClientCompanyPartnershipUserPhoneNumberMakePrimaryFormCodec>;

export const ClientCompanyPartnershipUserPhoneNumberForm: TFormCodec<TUserPhoneNumber1Codec, TRequiredCodec<{
    delete_form: TClientCompanyPartnershipUserPhoneNumberDeleteFormCodec,
    is_verified: TBooleanCodec,
    primary_number: TBooleanCodec,
    client_company_id: TUuidCodec,
    client_company_user_id: TUuidCodec,
    make_primary_form: TClientCompanyPartnershipUserPhoneNumberMakePrimaryFormCodec,
}>> = form(UserPhoneNumber1, required({
    delete_form: ClientCompanyPartnershipUserPhoneNumberDeleteForm,
    is_verified: boolean(),
    primary_number: boolean(),
    client_company_id: uuid(),
    client_company_user_id: uuid(),
    make_primary_form: ClientCompanyPartnershipUserPhoneNumberMakePrimaryForm,
}));
export type TClientCompanyPartnershipUserPhoneNumberFormCodec = typeof ClientCompanyPartnershipUserPhoneNumberForm;
export type TClientCompanyPartnershipUserPhoneNumberForm = TTypeOfCodec<TClientCompanyPartnershipUserPhoneNumberFormCodec>;

export const ClientCompanyPartnershipUserIDCheckUploadIdentityDocumentForm: TFileIOCodec<TRequiredCodec<{
    id: TUuidCodec,
    file_extension: TAnyStringCodec,
    mime_type: TAnyStringCodec,
}>, TEmptyObjectCodec> = fileIO(required({
    id: uuid(),
    file_extension: string(),
    mime_type: string(),
}), EmptyObject);
export type TClientCompanyPartnershipUserIDCheckUploadIdentityDocumentFormCodec = typeof ClientCompanyPartnershipUserIDCheckUploadIdentityDocumentForm;
export type TClientCompanyPartnershipUserIDCheckUploadIdentityDocumentForm = TTypeOfCodec<TClientCompanyPartnershipUserIDCheckUploadIdentityDocumentFormCodec>;

export const ClientCompanyPartnershipUserIDCheckIdentityDocumentForm: TFormCodec<TUuidObjectCodec, TRequiredFlatOverloadedCodec<{
    id_check_image_url: TUsersIdChecksImageStringCodec,
}>> = form(UuidObject, requiredFlatOverloaded({
    id_check_image_url: UsersIdChecksImageString,
}));
export type TClientCompanyPartnershipUserIDCheckIdentityDocumentFormCodec = typeof ClientCompanyPartnershipUserIDCheckIdentityDocumentForm;
export type TClientCompanyPartnershipUserIDCheckIdentityDocumentForm = TTypeOfCodec<TClientCompanyPartnershipUserIDCheckIdentityDocumentFormCodec>;

export const ClientCompanyPartnershipUserIDCheckForm: TFormCodec<TUsersIdCheck7Codec, TIntersectionCodec<[
    TUsersIdCheck8Codec,
    TRequiredCodec<{
        upload_identity_document_form: TClientCompanyPartnershipUserIDCheckUploadIdentityDocumentFormCodec,
        identity_document_forms: TArrayCodec<TClientCompanyPartnershipUserIDCheckIdentityDocumentFormCodec>,
    }>,
]>> = form(UsersIdCheck7, intersection([
    UsersIdCheck8,
    required({
        upload_identity_document_form: ClientCompanyPartnershipUserIDCheckUploadIdentityDocumentForm,
        identity_document_forms: array(ClientCompanyPartnershipUserIDCheckIdentityDocumentForm),
    })
]));
export type TClientCompanyPartnershipUserIDCheckFormCodec = typeof ClientCompanyPartnershipUserIDCheckForm;
export type TClientCompanyPartnershipUserIDCheckForm = TTypeOfCodec<TClientCompanyPartnershipUserIDCheckFormCodec>;

export const ClientCompanyPartnershipUserIDCheckCreateForm: TFormCodec<TUuidObjectCodec, TEmptyObjectCodec> = form(UuidObject, EmptyObject);
export type TClientCompanyPartnershipUserIDCheckCreateFormCodec = typeof ClientCompanyPartnershipUserIDCheckCreateForm;
export type TClientCompanyPartnershipUserIDCheckCreateForm = TTypeOfCodec<TClientCompanyPartnershipUserIDCheckCreateFormCodec>;

export const ClientCompanyPartnershipUserForm: TFormCodec<TClientCompanyPartnershipUserCodec, TRequiredCodec<{
    phone_number_forms: TArrayCodec<TClientCompanyPartnershipUserPhoneNumberFormCodec>;
    create_phone_number_form: TClientCompanyPartnershipUserPhoneNumberCreateFormCodec,
    id_check_forms: TArrayCodec<TClientCompanyPartnershipUserIDCheckFormCodec>,
    create_id_check_form: TClientCompanyPartnershipUserIDCheckCreateFormCodec,
    client_company_id: TUuidCodec,
}>> = form(ClientCompanyPartnershipUser, required({
    phone_number_forms: array(ClientCompanyPartnershipUserPhoneNumberForm),
    create_phone_number_form: ClientCompanyPartnershipUserPhoneNumberCreateForm,
    id_check_forms: array(ClientCompanyPartnershipUserIDCheckForm),
    create_id_check_form: ClientCompanyPartnershipUserIDCheckCreateForm,
    client_company_id: uuid(),
}));
export type TClientCompanyPartnershipUserFormCodec = typeof ClientCompanyPartnershipUserForm;
export type TClientCompanyPartnershipUserForm = TTypeOfCodec<TClientCompanyPartnershipUserFormCodec>;

export const ClientCompanyPartnershipContractUploadForm: TFileIOCodec<TRequiredCodec<{
    id: TUuidCodec,
    file_extension: TAnyStringCodec,
    mime_type: TAnyStringCodec,
}>, TEmptyObjectCodec> = fileIO(required({
    id: uuid(),
    file_extension: string(),
    mime_type: string(),
}), EmptyObject);
export type TClientCompanyPartnershipContractUploadFormCodec = typeof ClientCompanyPartnershipContractUploadForm;
export type TClientCompanyPartnershipContractUploadForm = TTypeOfCodec<TClientCompanyPartnershipContractUploadFormCodec>;

export const ClientCompanyPartnershipContractForm = form(UuidObject, requiredFlatOverloaded({
    download_url: CreateLegalFileDownloadUrl("Partnership contract"),
}));
export type TClientCompanyPartnershipContractFormCodec = typeof ClientCompanyPartnershipContractForm;
export type TClientCompanyPartnershipContractForm = TTypeOfCodec<TClientCompanyPartnershipContractFormCodec>;

export const ClientCompanyPartnershipForm: TFormCodec<TClientCompanyPartnershipCodec, TIntersectionCodec<[
    TRequiredCodec<{
        status: TClientCompanyPartnershipStatusCodec,
        next_id_check_due_date: TDateCodec,
        next_review_due_date: TDateCodec,
        delete_form: TClientCompanyPartnershipDeleteFormCodec,
        user_forms: TArrayCodec<TClientCompanyPartnershipUserFormCodec>,
        create_user_form: TClientCompanyPartnershipUserCreateFormCodec,
        partnership_contract_form: TUnionCodec<[TNullCodec, TClientCompanyPartnershipContractFormCodec]>,
        upload_partnership_contract_form: TClientCompanyPartnershipContractUploadFormCodec,
        submit_review_form: TClientCompanyPartnershipReviewFormCodec,
    }>,
]>> = form(ClientCompanyPartnership, intersection([
    required({
        status: ClientCompanyPartnershipStatus,
        next_id_check_due_date: date(),
        next_review_due_date: date(),
        delete_form: ClientCompanyPartnershipDeleteForm,
        user_forms: array(ClientCompanyPartnershipUserForm),
        create_user_form: ClientCompanyPartnershipUserCreateForm,
        partnership_contract_form: union([nullCodec(), ClientCompanyPartnershipContractForm]),
        upload_partnership_contract_form: ClientCompanyPartnershipContractUploadForm,
        submit_review_form: ClientCompanyPartnershipReviewForm,
    }),
]));
export type TClientCompanyPartnershipFormCodec = typeof ClientCompanyPartnershipForm;
export type TClientCompanyPartnershipForm = TTypeOfCodec<TClientCompanyPartnershipFormCodec>;

export const ClientCompanyPartnershipPageForm: TFormCodec<TEmptyObjectCodec, TRequiredCodec<{
    client_company_partnership_forms: TArrayCodec<TClientCompanyPartnershipFormCodec>,
    create_form: TClientCompanyPartnershipCreateFormCodec,
}>> = form(EmptyObject, required({
    client_company_partnership_forms: array(ClientCompanyPartnershipForm),
    create_form: ClientCompanyPartnershipCreateForm,
}));
export type TClientCompanyPartnershipPageFormCodec = typeof ClientCompanyPartnershipPageForm;
export type TClientCompanyPartnershipPageForm = TTypeOfCodec<TClientCompanyPartnershipPageFormCodec>;
