import * as PriceQualifier1 from "../models/PriceQualifier1";
import * as Country2 from "../models/Country2";
import * as Tenure1 from "./Tenure1";
import * as ListingDocument3 from "../models/ListingDocument3";
import * as ListingImage3 from "../models/ListingImage3";
import * as Listing9 from "../models/Listing9";
import * as t from "io-ts";
import * as ListingImage6 from "../models/ListingImage6";
import * as ListingAmenity2 from "./ListingAmenity2";
import * as S3BucketUrl from "../models/S3BucketUrl";
import * as ListingImage1 from "./ListingImage1";
import * as ListingStatus1 from "./ListingStatus1";
import * as ListingContractType2 from "./ListingContractType2";
import * as ListingCondition1 from "./ListingCondition1";
import * as ListingReasonForSale1 from "./ListingReasonForSale1";
import * as ListingPropertyType1 from "./ListingPropertyType1";
import * as ListingChainStatus1 from "./ListingChainStatus1";
import * as ListingValuationType from "./ListingValuationType";
import { array } from "fp-ts";
import * as ListingKeyHolder1 from "./ListingKeyHolder1";
import * as ListingViewingsConductedBy1 from "./ListingViewingsConductedBy1";
import * as User4 from "./User4";

export const codec = t.type({
    id: t.string,
    description: t.string,
    price: t.union([t.number, t.null]),
    price_qualifier: t.union([PriceQualifier1.codec, t.null]),
    postcode: t.union([t.string, t.null]),
    county: t.string,
    city_town: t.string,
    country: Country2.codec,
    tenure: Tenure1.codec,
    building_name: t.string,
    building_number: t.string,
    sub_building_name: t.string,
    sub_building_number: t.string,
    street_name: t.string,
    locality: t.string,
    district: t.string,
    images: t.array(ListingImage1.codec),
    documents: t.array(ListingDocument3.codec),
    amenities: t.array(ListingAmenity2.codec),
    latitude: t.union([t.number, t.null]),
    longitude: t.union([t.number, t.null]),
    status: ListingStatus1.codec,
    has_introducer: t.boolean,
    introducer_id: t.union([t.null, t.string]),
    condition: ListingCondition1.codec,
    reason_for_sale: ListingReasonForSale1.codec,
    admin_notes: t.string,
    sellers_party_id: t.string,
    property_type: ListingPropertyType1.codec,
    bedroom_count: t.union([t.number, t.null]),
    bathroom_count: t.union([t.number, t.null]),
    property_summary: t.string,
    property_advantages: t.array(t.string),
    property_disadvantages: t.array(t.string),
    sellers_view_on_offers: t.string,
    chain_status: ListingChainStatus1.codec,
    renovation_cost: t.union([t.number, t.null]),
    estimated_resale_price: t.union([t.number, t.null]),
    estimated_rental_price_pcm: t.union([t.number, t.null]),
    video_tour_url: t.string,
    video_tour_required_before_viewing: t.boolean,
    lease_length_remaining_years: t.union([t.number, t.null]),
    lease_service_charge_pcy: t.union([t.number, t.null]),
    lease_ground_rent_pcy: t.union([t.number, t.null]),
    competitor_agent_instructed_at: t.union([t.string, t.null]),
    valuation_type: ListingValuationType.codec,
    valuation_date: t.union([t.string, t.null]),
    seller_latest_desired_sell_date: t.union([t.string, t.null]),
    seller_minimum_desired_sell_value: t.union([t.number, t.null]),
    seller_specfic_agent_needs: t.string,
    significant_renovations_made: t.string,
    competitor_agent_name: t.string,
    competitor_agent_market_price: t.union([t.number, t.null]),
    competitor_agent_listing_status: t.string,
    main_entrance_floor_level: t.union([t.null, t.number]),
    above_ground_floors_count: t.union([t.null, t.number]),
    bellow_ground_floors_count: t.union([t.null, t.number]),
    attics_count: t.union([t.null, t.number]),
    property_occupied: t.union([t.boolean, t.null]),
    viewings_can_be_booked_from: t.union([t.null, t.string]),
    viewings_conducted_by: ListingViewingsConductedBy1.codec,
    key_holder: ListingKeyHolder1.codec,
    preferred_viewing_times: t.string,
    key_holder_user: t.union([t.null, User4.codec]),
    occupier_user: t.union([t.null, User4.codec]),
    viewings_conducted_by_user: t.union([t.null, User4.codec]),
    date_of_death: t.union([t.null, t.string]),
    referrer_name: t.string,
    referrer_firm: t.string,
    referrer_email: t.union([t.null, t.string]),
    referrer_phone_number: t.union([t.null, t.string]),
    seller_conveyancer_organisation_name: t.string,
    seller_conveyancer_name: t.string,
    seller_conveyancer_phone_number: t.union([t.null, t.string]),
    seller_conveyancer_email_address: t.union([t.null, t.string]),
    seller_conveyancer_address: t.string,
    seller_company_name: t.string,
    aventria_reference: t.string,
});

export type T = t.TypeOf<typeof codec>;

export const newDefault = (): T => ({
    id: "",
    description: "",
    price: 0,
    price_qualifier: "fixed_price",
    postcode: "",
    county: "",
    city_town: "",
    country: "england",
    tenure: "freehold",
    building_name: "",
    building_number: "",
    sub_building_name: "",
    sub_building_number: "",
    street_name: "",
    locality: "",
    district: "",
    images: [],
    documents: [],
    amenities: [],
    status: "lead",
    has_introducer: false,
    introducer_id: null,
    condition: "unknown",
    reason_for_sale: "unknown",
    admin_notes: "",
    sellers_party_id: "",
    property_type: "unknown",
    bedroom_count: null,
    bathroom_count: null,
    property_summary: "",
    property_advantages: [],
    property_disadvantages: [],
    sellers_view_on_offers: "",
    chain_status: "unknown",
    renovation_cost: null,
    estimated_resale_price: null,
    estimated_rental_price_pcm: null,
    video_tour_url: "",
    video_tour_required_before_viewing: false,
    lease_length_remaining_years: null,
    lease_service_charge_pcy: null,
    lease_ground_rent_pcy: null,
    competitor_agent_instructed_at: null,
    valuation_type: "undetermined",
    valuation_date: null,
    seller_latest_desired_sell_date: null,
    seller_minimum_desired_sell_value: null,
    seller_specfic_agent_needs: "",
    significant_renovations_made: "",
    competitor_agent_name: "",
    competitor_agent_market_price: null,
    competitor_agent_listing_status: "",
    main_entrance_floor_level: null,
    above_ground_floors_count: null,
    bellow_ground_floors_count: null,
    attics_count: null,
    latitude: null,
    longitude: null,
    property_occupied: null,
    viewings_can_be_booked_from: null,
    viewings_conducted_by: "unknown",
    key_holder: "unknown",
    preferred_viewing_times: "",
    key_holder_user: null,
    occupier_user: null,
    viewings_conducted_by_user: null,
    date_of_death: null,
    referrer_name: "",
    referrer_firm: "",
    referrer_email: null,
    referrer_phone_number: null,
    seller_conveyancer_organisation_name: "",
    seller_conveyancer_name: "",
    seller_conveyancer_phone_number: null,
    seller_conveyancer_email_address: null,
    seller_conveyancer_address: "",
    seller_company_name: "",
    aventria_reference: "",
});

export const fromListing9AndAccountName = (m: Listing9.T, accountName: string): T => (
    {
        id: m.id,
        description: m.description ? m.description : "",
        price: m.price_main_currency,
        price_qualifier: m.price_qualifier,
        building_name: m.building_name,
        building_number: m.building_number,
        sub_building_name: m.sub_building_name,
        sub_building_number: m.sub_building_number,
        street_name: m.street_name,
        postcode: m.postcode,
        locality: m.locality,
        district: m.district,
        county: m.county,
        city_town: m.city_town,
        country: m.country,
        tenure: m.tenure,
        images: array.map((i: ListingImage6.T) => ListingImage3.fromListingImage6(
            i,
            S3BucketUrl.fromAccountAndBucketNames(accountName, S3BucketUrl.bucketNames.LISTINGS_IMAGES_BUCKET)
        ))(m.images),
        documents: array.map(ListingDocument3.fromListingDocument7)(m.documents),
        amenities: array.map(ListingAmenity2.fromListingAmenity3)(m.amenities),
        status: m.status,
        has_introducer: m.has_introducer,
        introducer_id: m.introducer_id,
        condition: m.condition,
        reason_for_sale: m.reason_for_sale,
        admin_notes: m.admin_notes,
        sellers_party_id: m.sellers_party_id,
        property_type: m.property_type,
        bedroom_count: m.bedroom_count,
        bathroom_count: m.bathroom_count,
        property_summary: m.property_summary,
        property_advantages: m.property_advantages,
        property_disadvantages: m.property_disadvantages,
        sellers_view_on_offers: m.sellers_view_on_offers,
        chain_status: m.chain_status,
        renovation_cost: m.renovation_cost_main_currency,
        estimated_resale_price: m.estimated_resale_price_main_currency,
        estimated_rental_price_pcm: m.estimated_rental_price_pcm_main_currency,
        video_tour_url: m.video_tour_url,
        video_tour_required_before_viewing: m.video_tour_required_before_viewing,
        lease_length_remaining_years: m.lease_length_remaining_years,
        lease_service_charge_pcy: m.lease_service_charge_pcy_full_currency,
        lease_ground_rent_pcy: m.lease_ground_rent_pcy_full_currency,
        competitor_agent_instructed_at: m.competitor_agent_instructed_at,
        valuation_type: m.valuation_type,
        valuation_date: m.valuation_date,
        seller_latest_desired_sell_date: m.seller_latest_desired_sell_date,
        seller_minimum_desired_sell_value: m.seller_minimum_desired_sell_value,
        seller_specfic_agent_needs: m.seller_specfic_agent_needs,
        significant_renovations_made: m.significant_renovations_made,
        competitor_agent_name: m.competitor_agent_name,
        competitor_agent_market_price: m.competitor_agent_market_price,
        competitor_agent_listing_status: m.competitor_agent_listing_status,
        main_entrance_floor_level: m.main_entrance_floor_level,
        above_ground_floors_count: m.above_ground_floors_count,
        bellow_ground_floors_count: m.bellow_ground_floors_count,
        attics_count: m.attics_count,
        latitude: m.latitude,
        longitude: m.longitude,
        property_occupied: m.property_occupied,
        viewings_can_be_booked_from: m.viewings_can_be_booked_from,
        viewings_conducted_by: m.viewings_conducted_by,
        key_holder: m.key_holder,
        preferred_viewing_times: m.preferred_viewing_times,
        key_holder_user: m.key_holder_user,
        occupier_user: m.occupier_user,
        viewings_conducted_by_user: m.viewings_conducted_by_user,
        date_of_death: m.date_of_death,
        referrer_name: m.referrer_name,
        referrer_firm: m.referrer_firm,
        referrer_email: m.referrer_email,
        referrer_phone_number: m.referrer_phone_number,
        seller_conveyancer_organisation_name: m.seller_conveyancer_organisation_name,
        seller_conveyancer_name: m.seller_conveyancer_name,
        seller_conveyancer_phone_number: m.seller_conveyancer_phone_number,
        seller_conveyancer_email_address: m.seller_conveyancer_email_address,
        seller_conveyancer_address: m.seller_conveyancer_address,
        seller_company_name: m.seller_company_name,
        aventria_reference: m.aventria_reference,
    }
);

export const getBuildingNameOrNumber = (listing: T): string =>
    listing.building_name !== "" ?
        listing.building_name :
        listing.building_number
;

export const getRentalYieldPercentage = (listing: T): number =>
    listing.estimated_rental_price_pcm && listing.price
        ? ((listing.estimated_rental_price_pcm * 12) / (listing.price + (listing.renovation_cost || 0))) * 100
        : 0;

export const getEntranceFloorDisplayName = (listing: T): string =>
    listing.main_entrance_floor_level === null ? ""
    : listing.main_entrance_floor_level === -1 ? "Basement"
    : listing.main_entrance_floor_level < -1 ? `Basement (${listing.main_entrance_floor_level})`
    : listing.main_entrance_floor_level === 0 ? "Ground Floor"
    : listing.main_entrance_floor_level === 1 ? "1st Floor"
    : listing.main_entrance_floor_level === 2 ? "2nd Floor"
    : listing.main_entrance_floor_level === 3 ? "3rd Floor"
    : `${listing.main_entrance_floor_level}th Floor`;
