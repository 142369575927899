import React from "react";

export default (props: React.SVGAttributes<SVGElement>) => 
    <svg 
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path 
            d="M13 23V17L10.9 15L9.9 19.4L3 18L3.4 16L8.2 17L9.8 8.9L8 9.6V13H6V8.3L9.95 6.6C10.5333 6.35 10.9625 6.1875 11.2375 6.1125C11.5125 6.0375 11.7667 6 12 6C12.35 6 12.675 6.09167 12.975 6.275C13.275 6.45833 13.5167 6.7 13.7 7L14.7 8.6C15.1333 9.3 15.7208 9.875 16.4625 10.325C17.2042 10.775 18.05 11 19 11V13C17.9 13 16.8708 12.7708 15.9125 12.3125C14.9542 11.8542 14.15 11.25 13.5 10.5L12.9 13.5L15 15.5V23H13ZM13.5 5.5C12.95 5.5 12.4792 5.30417 12.0875 4.9125C11.6958 4.52083 11.5 4.05 11.5 3.5C11.5 2.95 11.6958 2.47917 12.0875 2.0875C12.4792 1.69583 12.95 1.5 13.5 1.5C14.05 1.5 14.5208 1.69583 14.9125 2.0875C15.3042 2.47917 15.5 2.95 15.5 3.5C15.5 4.05 15.3042 4.52083 14.9125 4.9125C14.5208 5.30417 14.05 5.5 13.5 5.5Z"
            fill="#00011B"
        />
    </svg>
;
