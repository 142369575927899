import { boolean } from "../../shared/src/codecs/types/boolean";
import { nullCodec } from "../../shared/src/codecs/types/nullCodec";
import { overload } from "../../shared/src/codecs/types/overload";
import { required } from "../../shared/src/codecs/types/required";
import { string } from "../../shared/src/codecs/types/string";
import { union } from "../../shared/src/codecs/types/union";

export const HasProvidedMortgageBrokerDetails = overload(
    boolean(),
    required({
        mortgage_broker_organisation_name: string(),
        mortgage_broker_name: string(),
        mortgage_broker_email_address: union([string(), nullCodec()]),
        mortgage_broker_phone_number: union([string(), nullCodec()]),
    }),
    (details) => (
        (
            details.mortgage_broker_organisation_name
            || details.mortgage_broker_name
            || details.mortgage_broker_email_address
            || details.mortgage_broker_phone_number
        )
     ) ? true : false,
);