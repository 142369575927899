import { TTypeOfCodec } from "../../../shared/src/codecs/codec";
import { array, TArrayCodec } from "../../../shared/src/codecs/types/array";
import { formIO, TFormIOCodec } from "../../../shared/src/codecs/types/formIO";
import { required, TRequiredCodec } from "../../../shared/src/codecs/types/required";
import { TAnyUuidCodec, uuid } from "../../../shared/src/codecs/types/uuid";
import { Address2, TAddress2Codec } from "../Address";
import { Offer1, Offer3, TOffer1Codec, TOffer3Codec } from "../Offer";

export const PublicOffersPageFormIO: TFormIOCodec<
    TRequiredCodec<{
        enquiry_id: TAnyUuidCodec
    }>,
    TRequiredCodec<{
        their_latest_offer: TOffer1Codec;
        all_offers: TArrayCodec<TOffer3Codec>;
        listing_address: TAddress2Codec;
    }>
> = formIO(
    required({
        enquiry_id: uuid(),
    }),
    required({
        their_latest_offer: Offer1,
        all_offers: array(Offer3),
        listing_address: Address2,
    }),
);

export type TPublicOffersPageFormIOCodec = typeof PublicOffersPageFormIO;

export type TPublicOffersPageFormIO = TTypeOfCodec<TPublicOffersPageFormIOCodec>;