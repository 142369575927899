import * as t from "io-ts";
import * as util from "../../shared/src/util";

export const codec = t.union([
    t.literal("energy_certificate"),
    t.literal("floorplan"),
    t.literal("title_plan"),
    t.literal("title_register"),
    t.literal("environmental_search"),
    t.literal("local_authority_search"),
    t.literal("drainage_water_search"),
    t.literal("flood_risk_assessment"),
    t.literal("inventory"),
    t.literal("ta6"),
    t.literal("ta10"),
    t.literal("survey"),
    t.literal("other"),
    t.literal("builders_inspection_report"),
    t.literal("mos"),
    t.literal("ta7"),
    t.literal("lpe1"),
    t.literal("invoice"),
    t.literal("grant_of_probate"),
    t.literal("leasehold_lease"),
    t.literal("chancel_search"),
    t.literal("chancel_insurance_policy"),
    t.literal("valuation_report"),
    t.literal("rental_report"),
    t.literal("coal_search"),
    t.literal("leasehold_title_plan"),
    t.literal("leasehold_title_register"),
]);

export const values = util.typeValuesFromCodec(codec.types as Array<t.LiteralC<T>>);

type T = t.TypeOf<typeof codec>;

export const toDisplayName = (p: T): string => {
    switch (p) {
        case "energy_certificate":
            return "EPC";
        case "floorplan":
            return "Floorplan";
        case "title_plan":
            return "Title plan";
        case "title_register":
            return "Title register";
        case "environmental_search":
            return "Environmental search";
        case "local_authority_search":
            return "Local authority search";
        case "drainage_water_search":
            return "Drainage water search";
        case "flood_risk_assessment":
            return "Flood risk assessment";
        case "inventory":
            return "Inventory";
        case "ta6":
            return "TA6";
        case "ta10":
            return "TA10";
        case "survey":
            return "Survey";
        case "other":
            return "Other";
        case "builders_inspection_report":
            return "Builders inspection report";
        case "mos":
            return "Memorandum of sale";
        case "ta7":
            return "TA7";
        case "lpe1":
            return "LPE1";
        case "invoice":
            return "Invoice";
        case "grant_of_probate":
            return "Grant of probate";
        case "leasehold_lease":
            return "Leasehold lease";
        case "chancel_search":
            return "Chancel search";
        case "chancel_insurance_policy":
            return "Chancel insurance policy";
        case "valuation_report":
            return "Valuation report";
        case "rental_report":
            return "Rental report";
        case "coal_search":
            return "Coal search";
        case "leasehold_title_plan":
            return "Leasehold title plan";
        case "leasehold_title_register":
            return "Leasehold title register";

        default:
            return util.requireExhaustive(p);
    }
};
