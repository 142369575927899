import React from "react";

export default (props: React.SVGAttributes<SVGElement>) => <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g clipPath="url(#clip0_5222:25639)">
<path d="M15.3999 19.672V18.5C15.3999 15.743 17.6429 13.5 20.3999 13.5C23.1569 13.5 25.3999 15.743 25.3999 18.5V19.672C26.5638 20.0848 27.3999 21.1964 27.3999 22.5V28.5C27.3999 30.1542 26.0541 31.5 24.3999 31.5H16.3999C14.7457 31.5 13.3999 30.1542 13.3999 28.5V22.5C13.3999 21.1964 14.236 20.0848 15.3999 19.672ZM20.3999 15.5C18.7457 15.5 17.3999 16.8458 17.3999 18.5V19.5H23.3999V18.5C23.3999 16.8458 22.0541 15.5 20.3999 15.5ZM15.3999 28.5C15.3999 29.0514 15.8485 29.5 16.3999 29.5H24.3999C24.9513 29.5 25.3999 29.0514 25.3999 28.5V22.5C25.3999 21.9486 24.9513 21.5 24.3999 21.5H16.3999C15.8485 21.5 15.3999 21.9486 15.3999 22.5V28.5Z" fill="black"/>
<path d="M20.3996 41.5C23.9515 41.5 27.4157 40.5135 30.4179 38.647C33.3365 36.8326 35.7122 34.2619 37.2882 31.213C37.5419 30.7224 37.3497 30.119 36.8591 29.8654C36.3686 29.6119 35.7652 29.804 35.5116 30.2946C32.5766 35.9727 26.786 39.5 20.3996 39.5C11.0258 39.5 3.39962 31.8738 3.39962 22.5C3.39962 13.1262 11.0258 5.5 20.3996 5.5C29.7734 5.5 37.3996 13.1262 37.3996 22.5C37.3996 23.0051 37.7762 23.4308 38.2774 23.4925C38.7787 23.5542 39.2472 23.2325 39.3698 22.7426L41.3698 14.7426C41.5037 14.2068 41.178 13.6639 40.6422 13.5299C40.1063 13.3962 39.5634 13.7217 39.4295 14.2575L38.6756 17.2732C37.8018 14.2011 36.1553 11.3856 33.8347 9.06493C30.246 5.47637 25.4747 3.5 20.3996 3.5C15.3246 3.5 10.5532 5.47637 6.96461 9.065C3.37593 12.6536 1.39961 17.4249 1.39961 22.5C1.39961 27.5751 3.37593 32.3464 6.96461 35.935C10.5532 39.5237 15.3246 41.5 20.3996 41.5Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_5222:25639">
<rect width="40" height="40" fill="white" transform="matrix(-1 0 0 1 41.3999 1.5)"/>
</clipPath>
</defs>
</svg>
;
