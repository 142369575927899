import { TTypeOfCodec } from "../../../shared/src/codecs/codec";
import { array, TArrayCodec } from "../../../shared/src/codecs/types/array";
import { intersection, TIntersectionCodec } from "../../../shared/src/codecs/types/intersection";
import { required, TRequiredCodec } from "../../../shared/src/codecs/types/required";
import { TUuidCodec, uuid } from "../../../shared/src/codecs/types/uuid";
import { TUser11Codec, TUser20Codec, User20, User11 } from "../User";
import { PhoneNumberForm, TPhoneNumberFormCodec } from "./PhoneNumberForm";
import { form, TFormCodec } from "../../../shared/src/codecs/types/form";
import { requiredFlatOverloaded, TRequiredFlatOverloadedCodec } from "../../../shared/src/codecs/types/requiredFlatOverloaded";
import { ListingSellerUserDeleteForm, TListingSellerUserDeleteFormCodec } from "./ListingSellerUserDeleteForm";

export const ListingSellerSuggestedSimilarUserForm: TFormCodec<
    TRequiredCodec<{
        swap_from_user_id: TUuidCodec,
        swap_to_user_id: TUuidCodec,
        party_id: TUuidCodec,
    }>,
    TUser20Codec
> = form(
    required({
        swap_from_user_id: uuid(),
        swap_to_user_id: uuid(),
        party_id: uuid(),
    }),
    User20,
);
export type TListingSellerSuggestedSimilarUserFormCodec = typeof ListingSellerSuggestedSimilarUserForm;
export type TListingSellerSuggestedSimilarUserForm = TTypeOfCodec<TListingSellerSuggestedSimilarUserFormCodec>;

export const ListingSellerUserEditForm: TFormCodec<
    TUser11Codec,
    TIntersectionCodec<[
        TRequiredCodec<{
            phone_numbers: TArrayCodec<TPhoneNumberFormCodec>
            suggested_similar_users: TArrayCodec<TListingSellerSuggestedSimilarUserFormCodec>
        }>,
        TRequiredFlatOverloadedCodec<{
            delete_form: TListingSellerUserDeleteFormCodec
        }>
    ]>
> = form(
    User11,
    intersection([
        required({
            phone_numbers: array(PhoneNumberForm),
            suggested_similar_users: array(ListingSellerSuggestedSimilarUserForm),
        }),
        requiredFlatOverloaded({
            delete_form: ListingSellerUserDeleteForm
        })
    ])
);

export type TListingSellerUserEditFormCodec = typeof ListingSellerUserEditForm;
export type TListingSellerUserEditForm = TTypeOfCodec<TListingSellerUserEditFormCodec>;
