import { TTypeOfCodec } from "../../../shared/src/codecs/codec";
import { TFormCodec, form } from "../../../shared/src/codecs/types/form";
import { CasesContractBlock, TCasesContractBlockCodec } from "../CasesContractBlock";
import { TEmptyObjectCodec, EmptyObject } from "../EmptyObject";

export const CaseContractDetailsForm: TFormCodec<TCasesContractBlockCodec, TEmptyObjectCodec> = form(
    CasesContractBlock,
    EmptyObject,
);
export type TCaseContractDetailsFormCodec = typeof CaseContractDetailsForm;
export type TCaseContractDetailsForm = TTypeOfCodec<TCaseContractDetailsFormCodec>;
