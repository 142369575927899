import React, { ReactNode, ReactNodeArray } from "react";
import { FrontFooter } from "../Front/Complex/FrontFooter/FrontFooter";
import { FrontMenu } from "../Front/Complex/FrontMenu/FrontMenu";
import { getCurrentBrandHostnameAndEnv } from "../../../../domain/models/Enviroment";
import { sailHomesBrandConstants } from "../../../../domain/constants";
import { FrontFooterBase } from "../Front/Complex/FrontFooterBase/FrontFooterBase";

interface IPageProps {
    children: ReactNode | ReactNodeArray;
    hiddenLink?: string;
    showLogout: boolean;
}

class Page extends React.Component<React.PropsWithChildren<IPageProps>> {
    public render (): JSX.Element {
        const currentBrand = getCurrentBrandHostnameAndEnv("web", window.location.hostname);
        return (
            <div className="page">
                {currentBrand === "sailhomes" && 
                    <FrontMenu
                        showLogout={this.props.showLogout}
                        links={[
                            {
                                text: "Home",
                                url: sailHomesBrandConstants("web").homeURL,
                            },
                            {
                                text: "Valuations",
                                url: sailHomesBrandConstants("web").valuationURL,
                            },
                            {
                                text: "For Sale",
                                url: sailHomesBrandConstants("web").forSale,
                            },
                            {
                                text: "Pricing",
                                url: sailHomesBrandConstants("web").pricingURL,
                            },
                            {
                                text: "FAQ",
                                url: sailHomesBrandConstants("web").faqURL,
                            },
                            {
                                text: "Contact",
                                url: sailHomesBrandConstants("web").contact.emailURL,
                                button: true,
                            },
                        ]}
                        logoUrl="/static/img/SailHomesLogoLight.svg"
                        phoneUrlAndText={[sailHomesBrandConstants("web").contact.phoneURL, sailHomesBrandConstants("web").contact.phoneText]}
                        branding="sailhomes"
                    />
                }
                {currentBrand === "saillegal" && 
                    <FrontMenu
                        showLogout={this.props.showLogout}
                        links={[]}
                        logoUrl="/static/img/SAIL_LEGAL_LOGO_2021_WHITE.svg"
                        branding="saillegal"
                    />
                }
                {currentBrand === "sailprobate" && 
                    <FrontMenu
                        showLogout={this.props.showLogout}
                        links={[]}
                        logoUrl="/static/img/SAIL_PROBATE_LOGO_2021_WHITE.svg"
                        branding="sailprobate"
                    />
                }
                <div className="page__body">
                    {this.props.children}
                </div>
                {currentBrand === "sailhomes" && 
                    <FrontFooter
                        hiddenLink={this.props.hiddenLink}
                    />
                }
                {currentBrand === "saillegal" && 
                    <FrontFooterBase
                        copyRightString={`Copyright Sail Legal ${new Date().getFullYear().toString()}.`}
                        backgroundColor="#0f6e15"
                        hiddenLink={this.props.hiddenLink}
                    />
                }
                {currentBrand === "sailprobate" && 
                    <FrontFooterBase
                        backgroundColor="#650f6e"
                        hiddenLink={this.props.hiddenLink}
                        copyRightString={`Copyright Sail Probate ${new Date().getFullYear().toString()}.`}
                    />
                }
            </div>
        );
    }
}

export default Page;
