import { TTypeOfCodec } from "../../shared/src/codecs/codec";
import { array } from "../../shared/src/codecs/types/array";
import { dateTime } from "../../shared/src/codecs/types/dateTime";
import { nullCodec } from "../../shared/src/codecs/types/nullCodec";
import { required } from "../../shared/src/codecs/types/required";
import { string } from "../../shared/src/codecs/types/string";
import { union } from "../../shared/src/codecs/types/union";
import { uuid } from "../../shared/src/codecs/types/uuid";
import { EmailDirection1 } from "./EmailDirection";
import { LegalEmailStatus1 } from "./LegalEmailStatus";

export const LegalEmail2 = required({
    id: uuid(),
    cases_enquiry_id: uuid(),
    gmail_message_ids: array(union([string(), nullCodec()])),
    direction: EmailDirection1,
    status: LegalEmailStatus1,
    from_address: string(),
    to_addresses: array(string()),
    subject: string(),
    snippet: string(),
    date: dateTime(),
    created_at: dateTime(),
    cc_addresses: array(string()),
    bcc_addresses: array(string()),
    body_html_or_text: string(),
    summary: string(),
    case_status: LegalEmailStatus1,
});
export type TLegalEmail2 = TTypeOfCodec<typeof LegalEmail2>;
