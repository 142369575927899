import * as t from "io-ts";
import * as CaseEnquiryAttachmentsType from "./CaseEnquiryAttachmentsType";

const codec = t.type({
    type: CaseEnquiryAttachmentsType.codec,
    recipients: t.array(t.string),
    emailDirection: t.union([
        t.literal("inbound"),
        t.literal("outbound"),
        t.literal("both"),
    ]),
});

export type T = t.TypeOf<typeof codec>;

export const newDefault = (): T => ({
    type: "documents",
    recipients: [],
    emailDirection: "both",
});
