import { overload } from "../../../shared/src/codecs/types/overload";
import { required } from "../../../shared/src/codecs/types/required";
import { string } from "../../../shared/src/codecs/types/string";
import { pipe } from "fp-ts/lib/function";
import { array } from "../../../shared/src/codecs/types/array";

export const UserName = overload(
    string(),
    required({
        first_name: string(),
        last_name: string(),
    }),
    (p) => pipe(
        [
            ...(p.first_name ? [p.first_name.trim()] : []),
            ...(p.last_name ? [p.last_name.trim()] : []),
        ],
        (a) => a.length === 0 ? "Name not given"
            : a.join(" "),
    )
);
export type TUserNameCodec = typeof UserName;

export const UserNames = overload(
    string(),
    required({
        users: array(UserName),
    }),
    ({users}) => users.join(" & "),
);
export type TUserNamesCodec = typeof UserNames;

export const UserNames2 = overload(
    string(),
    array(UserName),
    (users) => users.sort().join(" & "),
);
export type TUserNames2Codec = typeof UserNames2;