import { boolean } from "../../shared/src/codecs/types/boolean";
import { integer } from "../../shared/src/codecs/types/integer";
import { intersection } from "../../shared/src/codecs/types/intersection";
import { required } from "../../shared/src/codecs/types/required";
import { requiredFlatOverloaded } from "../../shared/src/codecs/types/requiredFlatOverloaded";
import { PaymentMethod } from "./PaymentMethod";
import { HasProvidedConveyancerDetails } from "./HasProvidedConveyancerDetails";
import { HasProvidedMortgageBrokerDetails } from "./HasProvidedMortgageBrokerDetails";
import { HasProvidedProofOfFundsForOffer } from "./HasProvidedProofOfFundsForOffer";
import { EstimatedTimeToCompletion2 } from "./EstimatedTimeToCompletion";
import { RiskOfFallThrough2 } from "./RiskOfFallThrough";
import { TTypeOfCodec, TTypeOfNewDefault } from "../../shared/src/codecs/codec";
import { uuid } from "../../shared/src/codecs/types/uuid";
import { string } from "../../shared/src/codecs/types/string";
import { dateTime } from "../../shared/src/codecs/types/dateTime";
import { OfferStatus1, OfferStatus2 } from "./OfferStatus";
import { union } from "../../shared/src/codecs/types/union";
import { nullCodec } from "../../shared/src/codecs/types/nullCodec";

export const Offer1 = intersection(
    [
        required({
            amount: integer(),
            id_check_complete: boolean(),
            payment_method: PaymentMethod,
        }),
        requiredFlatOverloaded({
            has_provided_conveyancer_details: HasProvidedConveyancerDetails,
            has_provided_mortgage_broker_details: HasProvidedMortgageBrokerDetails,
            has_provided_proof_of_funds: HasProvidedProofOfFundsForOffer,
            estimated_time_to_complete: EstimatedTimeToCompletion2,
            risk_of_fall_through: RiskOfFallThrough2,
        }),
    ]
);
export type TOffer1Codec = typeof Offer1;
export type TOffer1NewDefault = TTypeOfNewDefault<typeof Offer1>;

export const Offer2 = required({
    enquiry_id: uuid(),
    amount: integer(),
});
export type TOffer2Codec = typeof Offer2;

export const Offer3 = intersection([
    required({
        amount: integer(),
        is_own_offer: boolean(),
    }),
    requiredFlatOverloaded({
        risk_of_fall_through: RiskOfFallThrough2,
        estimated_time_to_complete: EstimatedTimeToCompletion2,
    }),
]);
export type TOffer3Codec = typeof Offer3;
export type TOffer3NewDefault = TTypeOfNewDefault<typeof Offer3>;

export const Offer4 = required({
    id: uuid(),
    listings_enquiry_id: uuid(),
    amount: integer(),
    conditions: string(),
    created_at: dateTime(),
    accepted_at: dateTime(),
});

export type TOffer4 = TTypeOfCodec<typeof Offer4>;

export const Offer5 = required({
    id: uuid(),
    status: OfferStatus1,
    withdrawn_reason: string(),
    shared_with_seller_at: union([dateTime(), nullCodec()]),
    feedback_shared_with_buyer_at: union([dateTime(), nullCodec()]),
    feedback_shared_with_buyer_deferred: union([dateTime(), nullCodec()]),
});
export type TOffer5Codec = typeof Offer5;

export const Offer5Children = intersection([
    required({
        amount: integer(),
        conditions: string(),
        rejected_reason: string(),
    }),
    requiredFlatOverloaded({
        status_display_name: OfferStatus2,
    })
]);
export type TOffer5ChildrenCodec = typeof Offer5Children;