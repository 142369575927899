import React from "react";
import { CRMSpacing } from "../../models/CRMSpacing";
import { CRMColors } from "../../models/CRMColors";
import { BulletPoint } from "../BuildingBlocks/BulletPoint";
import { SpacingColumn } from "../BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../BuildingBlocks/SpacingRow";
import { Padding } from "../BuildingBlocks/Padding";

type TCRMUniversalBulletPoints = {
    points: Array<React.ReactChild>;
    bulletColor?: string;
    size?: "tiny" | "normal";
};

export class CRMUniversalBulletPoints extends React.Component<React.PropsWithChildren<TCRMUniversalBulletPoints>> {
    public render (): JSX.Element {
        return (
            <div className="crm-universal-bullet-points">
                <SpacingColumn spacing={CRMSpacing.TINY}>
                    {this.props.points.map((point, key) => (
                        <SpacingRow
                            key={key}
                            spacing={CRMSpacing.TINY}
                            alignItems="flex-start"
                        >
                            <Padding spacing="6px 0px 0px 0px">
                                <BulletPoint
                                    bulletColor={this.props.bulletColor || CRMColors.NEUTRAL_INK}
                                    size={this.props.size || "normal"}
                                />
                            </Padding>
                            
                            <span className="crm-universal-bullet-points__text">
                                {point}
                            </span>
                        </SpacingRow>
                    ))}
                </SpacingColumn>
            </div>
        );
    }
}
