import * as t from "io-ts";
import * as regexes from "../../shared/src/regexes";
import { nonEmptyString } from "../../shared/src/validation/basic/nonEmptyString";
import { inOrder } from "../../shared/src/validation/compose/inOrder";
import { nullable } from "../../shared/src/validation/create/nullable";
import { object } from "../../shared/src/validation/create/object";
import { regex } from "../../shared/src/validation/create/regex";
import { TValidationFunction } from "../../shared/src/validation/Function";

export const codec = t.type({
    phone_number: t.union([
        t.string,
        t.null,
    ]),
});

export type T = t.TypeOf<typeof codec>;

export const validator: TValidationFunction = object({
    phone_number: nullable(
        inOrder(
            nonEmptyString,
            regex(regexes.constants.MOBILE_PHONE_REGEX)
        )
    ),
});

export const newDefault = (): T => ({
    phone_number: null,
});
