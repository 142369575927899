import { pipe } from "fp-ts/lib/pipeable";
import { AllParams } from "./routerParams";
import { TPaths } from "./routerPaths";
import { TBlocksParamsObject, TParamsObject, TPathPart, TQueryParamsObject, TRouteActionType, TRouteActionTypeWithBlocks, TRouteActionTypeWithoutBlocks } from "./routerTypes";
import { array, record } from "fp-ts";
import * as JSONCrush from "../../JSONCrush";

export function getUrlStringForRoute <T extends TRouteActionTypeWithoutBlocks> (
    p: TPaths, 
    route: T, 
    params: TParamsObject<T> | AllParams, 
    queryParams: TQueryParamsObject<T>
): string
export function getUrlStringForRoute <T extends TRouteActionTypeWithBlocks> (
    p: TPaths, 
    route: T, 
    params: TParamsObject<T> | AllParams, 
    queryParams: TQueryParamsObject<T>, 
    blockParams: TBlocksParamsObject<T>
): string
export function getUrlStringForRoute <T extends TRouteActionType> (
    p: TPaths, 
    route: T, 
    params: TParamsObject<T> | AllParams, 
    queryParams: TQueryParamsObject<T>, 
    blockParams: TBlocksParamsObject<T>
): string
export function getUrlStringForRoute <T extends TRouteActionType> (
    p: TPaths, 
    route: T, 
    params: TParamsObject<T> | AllParams, 
    queryParams: TQueryParamsObject<T>, 
    blockParams?: TBlocksParamsObject<T>
): string
{
    const pathString = pipe(
        p[route].pattern as Array<TPathPart<keyof TParamsObject<T>>>,
        array.map((part) => typeof part === "string" ? part : (params as TParamsObject<T>)[part.param])
    ).join("/");

    
    const blocksEncoded = blockParams !== undefined ? JSONCrush.crush(JSON.stringify(blockParams)) : undefined;

    const queryParamsAndBlocks = {
        ...queryParams,
        ...(blocksEncoded === undefined ? {} : {blocks: blocksEncoded})
    };

    const queryString = record.keys<string>(queryParamsAndBlocks).length > 0 ? pipe(
        queryParamsAndBlocks,
        record.reduceRightWithIndex<string, string, string>(
            "",
            (key, val, qS) =>
                qS
                ? `${qS}&${key}=${encodeURIComponent(val)}`
                : `?${key}=${encodeURIComponent(val)}`
        )
    ) : "";

    return `/${pathString}${queryString}`;
};