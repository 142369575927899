import React from "react";

export default (props: React.SVGAttributes<SVGElement>) => <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M36.2999 44H7.6999C5.8803 44 4.3999 42.5196 4.3999 40.7V5.49995C4.3999 3.68035 5.8803 2.19995 7.6999 2.19995H36.2999C38.1196 2.19995 39.5999 3.68035 39.5999 5.49995V40.7C39.5999 42.5196 38.1196 44 36.2999 44ZM7.6999 4.39995C7.09339 4.39995 6.5999 4.89344 6.5999 5.49995V40.7C6.5999 41.3065 7.09339 41.8 7.6999 41.8H36.2999C36.9064 41.8 37.3999 41.3065 37.3999 40.7V5.49995C37.3999 4.89344 36.9064 4.39995 36.2999 4.39995H7.6999Z" fill="black"/>
<path d="M27.5 11H12.1C11.4925 11 11 10.5075 11 9.90005C11 9.29257 11.4925 8.80005 12.1 8.80005H27.5C28.1075 8.80005 28.6 9.29257 28.6 9.90005C28.6 10.5075 28.1075 11 27.5 11Z" fill="black"/>
<path d="M31.9 15.4H12.1C11.4925 15.4 11 14.9074 11 14.3C11 13.6925 11.4925 13.2 12.1 13.2H31.9C32.5075 13.2 33 13.6925 33 14.3C33 14.9074 32.5075 15.4 31.9 15.4Z" fill="black"/>
<path d="M31.9 19.8001H12.1C11.4925 19.8001 11 19.3076 11 18.7001C11 18.0926 11.4925 17.6001 12.1 17.6001H31.9C32.5075 17.6001 33 18.0926 33 18.7001C33 19.3076 32.5075 19.8001 31.9 19.8001Z" fill="black"/>
<path d="M23.1 24.2H12.1C11.4925 24.2 11 23.7075 11 23.1C11 22.4925 11.4925 22 12.1 22H23.1C23.7075 22 24.2 22.4925 24.2 23.1C24.2 23.7075 23.7075 24.2 23.1 24.2Z" fill="black"/>
<path d="M31.9 33.0001H12.1C11.4925 33.0001 11 32.5076 11 31.9001C11 31.2925 11.4925 30.8 12.1 30.8H31.9C32.5075 30.8 33 31.2925 33 31.9001C33 32.5076 32.5075 33.0001 31.9 33.0001Z" fill="black"/>
<path d="M27.5 37.3999H12.1C11.4925 37.3999 11 36.9075 11 36.2999C11 35.6924 11.4925 35.2 12.1 35.2H27.5C28.1075 35.2 28.6 35.6924 28.6 36.2999C28.6 36.9075 28.1075 37.3999 27.5 37.3999Z" fill="black"/>
</svg>
;
