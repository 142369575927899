import React from "react";

export default (props: React.SVGAttributes<SVGElement>) => <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g clipPath="url(#clip0_5460:25285)">
<path d="M33.2799 15.9544C32.9761 15.4283 32.3033 15.2482 31.7775 15.5518L20.9528 21.8014L12.5162 15.894C12.0185 15.5455 11.3326 15.6665 10.9842 16.1641C10.6357 16.6617 10.7567 17.3476 11.2543 17.6961L20.2649 24.0054C20.2671 24.0068 20.2695 24.0082 20.2717 24.0096C20.2902 24.0224 20.3089 24.0342 20.328 24.0457C20.3331 24.0489 20.3382 24.0522 20.3434 24.0551C20.3626 24.0663 20.3821 24.0766 20.402 24.0867C20.4067 24.0891 20.4114 24.0918 20.4161 24.0939C20.4384 24.1048 20.4611 24.1149 20.484 24.124C20.4976 24.1295 20.5113 24.1341 20.5249 24.1391C20.5349 24.1428 20.545 24.1464 20.5551 24.1497C20.5699 24.1544 20.5848 24.1588 20.5997 24.1629C20.6091 24.1655 20.6185 24.1681 20.628 24.1704C20.6429 24.1743 20.6579 24.1776 20.6729 24.1807C20.6831 24.1827 20.6934 24.1847 20.7036 24.1865C20.7179 24.189 20.732 24.1914 20.7463 24.1934C20.7591 24.1952 20.772 24.1966 20.785 24.1977C20.7967 24.199 20.8084 24.2003 20.8202 24.2012C20.8457 24.2028 20.8712 24.204 20.8969 24.204C20.9386 24.204 20.9804 24.2013 21.0222 24.1965C21.0232 24.1964 21.0243 24.1963 21.0254 24.1962C21.066 24.1915 21.1065 24.184 21.1468 24.1747C21.1498 24.174 21.1528 24.1732 21.1558 24.1727C21.1937 24.1634 21.2314 24.152 21.2686 24.1386C21.2741 24.1367 21.2795 24.1345 21.2849 24.1325C21.3198 24.1193 21.3545 24.1044 21.3884 24.0873C21.3954 24.0839 21.4021 24.0801 21.4089 24.0765C21.4212 24.0699 21.4337 24.0637 21.4459 24.0567L32.8774 17.4567C33.4034 17.1533 33.5837 16.4805 33.2799 15.9544V15.9544Z" fill="black"/>
<path d="M35.9778 37.7243C35.9528 37.6993 35.9267 37.676 35.8999 37.6539C39.7065 33.7331 41.8 28.5788 41.8 23.1021C41.8 17.5196 39.626 12.2711 35.6786 8.32364C31.7311 4.37616 26.4826 2.20215 20.9 2.20215C15.3174 2.20215 10.069 4.37616 6.1215 8.32364C2.17401 12.2711 0 17.5196 0 23.1021C0 28.5788 2.09357 33.7331 5.90012 37.6538C5.87338 37.676 5.84725 37.6992 5.82223 37.7242L1.42223 42.1242C0.992612 42.5538 0.992612 43.2503 1.42223 43.6798C1.63701 43.8947 1.91847 44.0021 2.2 44.0021C2.48153 44.0021 2.76299 43.8947 2.97784 43.6798L7.37784 39.2798C7.42225 39.2354 7.46089 39.1877 7.49609 39.1382C11.2495 42.2865 15.9426 44.0021 20.9 44.0021C25.8574 44.0021 30.5506 42.2865 34.304 39.1382C34.3392 39.1877 34.3779 39.2355 34.4223 39.2799L38.8223 43.6799C39.037 43.8947 39.3185 44.0021 39.6 44.0021C39.8815 44.0021 40.163 43.8947 40.3778 43.6798C40.8075 43.2503 40.8075 42.5538 40.3778 42.1242L35.9778 37.7243V37.7243ZM2.2 23.1021C2.2 12.7909 10.5888 4.40215 20.9 4.40215C31.2112 4.40215 39.6 12.7909 39.6 23.1021C39.6 33.4133 31.2112 41.8021 20.9 41.8021C10.5888 41.8021 2.2 33.4133 2.2 23.1021Z" fill="black"/>
<path d="M1.23296 10.1303C0.721256 10.1303 0.263037 9.77144 0.156406 9.25066C0.0526625 8.74342 0 8.22175 0 7.7C0 3.45421 3.45421 0 7.7 0C8.61094 0 9.50311 0.157781 10.3516 0.469013C10.922 0.67815 11.2148 1.31017 11.0056 1.88052C10.7964 2.45087 10.1645 2.74374 9.59406 2.53447C8.98899 2.31254 8.35168 2.2 7.7 2.2C4.6673 2.2 2.2 4.6673 2.2 7.7C2.2 8.07386 2.23761 8.44725 2.31172 8.80963C2.43347 9.4048 2.04971 9.98601 1.45454 10.1078C1.38022 10.123 1.30597 10.1303 1.23296 10.1303V10.1303Z" fill="black"/>
<path d="M40.5633 10.1304C40.4902 10.1304 40.4159 10.1231 40.3415 10.1078C39.7463 9.98601 39.3626 9.40473 39.4844 8.80963C39.5586 8.44725 39.5962 8.07393 39.5962 7.70007C39.5962 4.6673 37.1289 2.2 34.0962 2.2C33.4444 2.2 32.807 2.31261 32.2018 2.53468C31.6313 2.74409 30.9995 2.45121 30.7902 1.88086C30.581 1.31051 30.8737 0.678563 31.4441 0.469288C32.2927 0.157919 33.185 0 34.0962 0C38.342 0 41.7962 3.45428 41.7962 7.70007C41.7962 8.22175 41.7436 8.74349 41.6398 9.2508C41.5332 9.77151 41.0749 10.1304 40.5633 10.1304Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_5460:25285">
<rect width="44" height="44" fill="white"/>
</clipPath>
</defs>
</svg>
;
