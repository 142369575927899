import { form, TFormCodec } from "../../../shared/src/codecs/types/form";
import { TTypeOfCodec } from "../../../shared/src/codecs/codec";
import { required, TRequiredCodec } from "../../../shared/src/codecs/types/required";
import { array, TArrayCodec } from "../../../shared/src/codecs/types/array";
import { TAnyUuidCodec, uuid } from "../../../shared/src/codecs/types/uuid";
import { TCaseNewOtherSideMemberFormCodec, CaseNewOtherSideMemberForm } from "./CaseNewOtherSideMemberForm";
import { TCaseNewPropertyFormCodec, CaseNewPropertyForm } from "./CaseNewPropertyForm";
import { TCaseOtherSideMemberFormCodec, CaseOtherSideMemberForm } from "./CaseOtherSideMemberForm";
import { TCasePropertyFormCodec, CasePropertyForm } from "./CasePropertyForm";
import { CaseContractDetailsForm, TCaseContractDetailsFormCodec } from "./CaseContractDetailsForm";
import { boolean } from "../../../shared/src/codecs/types/boolean";
import { CaseStatus, TCaseStatusCodec } from "../Cases";
import { MarketingCampaign1, TMarketingCampaign1Codec } from "../MarketingCampaign";
import { TUnionCodec, union } from "../../../shared/src/codecs/types/union";
import { nullCodec, TNullCodec } from "../../../shared/src/codecs/types/nullCodec";
import { dateTime, TAnyDateTimeCodec } from "../../../shared/src/codecs/types/dateTime";
import { Introducer1, TIntroducer1Codec } from "../Introducer";
import { TCaseDualRepCreateFormCodec, TCaseDualRepLinkedFormCodec, CaseDualRepCreateForm, CaseDualRepLinkedForm } from "./CaseForm";
import { JointOwnershipHeldAsAnsweredType } from "../CasesContractBlock";
import { intersection } from "../../../shared/src/codecs/types/intersection";
import { requiredFlatOverloaded } from "../../../shared/src/codecs/types/requiredFlatOverloaded";
import { UserName } from "../overloads/UserName";
import { TransactionType1, TTransactionType1Codec } from "../TransactionType";
import { TransferSetupRecordForm, TTransferSetupRecordFormCodec } from "./TransferSetupRecordForm";
import { TUsersCasesUserLegalNameCodec, UsersCasesUserLegalName } from "../UsersCasesUserLegalName";
import { string, TAnyStringCodec } from "../../../shared/src/codecs/types/string";
import { NewTransferSetupRecordForCaseForm, TNewTransferSetupRecordForCaseFormCodec } from "./NewTransferSetupRecordForCaseForm";
import { UuidForm, TUuidFormCodec } from "./UuidForm";


export const CaseContractSectionFormUserJointOwnershipHeldAsPreference =
    intersection([
        required({
            id: uuid(),
            preference: JointOwnershipHeldAsAnsweredType,
        }),
        requiredFlatOverloaded({
            name: UserName,
        }),
    ]);
export type TCaseContractSectionFormUserJointOwnershipHeldAsPreferenceCodec = typeof CaseContractSectionFormUserJointOwnershipHeldAsPreference;

export const CaseContractSectionFormUserJointOwnershipHeldAsPreferences =
    required({
        answers: array(CaseContractSectionFormUserJointOwnershipHeldAsPreference),
        are_questions_applicable: boolean(),
        all_clients_have_answered: boolean(),
        all_clients_agree: boolean(),
    });
export type TCaseContractSectionFormUserJointOwnershipHeldAsPreferencesCodec = typeof CaseContractSectionFormUserJointOwnershipHeldAsPreferences;
export type TCaseContractSectionFormUserJointOwnershipHeldAsPreferences = TTypeOfCodec<TCaseContractSectionFormUserJointOwnershipHeldAsPreferencesCodec>;

export const CaseContractSectionForm: TFormCodec<
TRequiredCodec<{
    case_id: TAnyUuidCodec,
}>,
TRequiredCodec<{
    details: TCaseContractDetailsFormCodec,
    transaction_type: TTransactionType1Codec,
    case_status: TCaseStatusCodec,
    properties: TArrayCodec<TCasePropertyFormCodec>,
    new_property: TCaseNewPropertyFormCodec,
    other_side_members: TArrayCodec<TCaseOtherSideMemberFormCodec>,
    new_other_side_member: TCaseNewOtherSideMemberFormCodec,
    marketing_campaigns: TArrayCodec<TMarketingCampaign1Codec>,
    is_instructed: TUnionCodec<[TNullCodec, TAnyDateTimeCodec]>,
    introducers: TArrayCodec<TIntroducer1Codec>,
    dual_rep: TRequiredCodec<{
        create_link: TCaseDualRepCreateFormCodec,
        linked_case_form: TUnionCodec<[
            TCaseDualRepLinkedFormCodec,
            TNullCodec,
        ]>,
    }>,
    user_joint_ownership_held_as_preferences: TCaseContractSectionFormUserJointOwnershipHeldAsPreferencesCodec,
    transfer_setup_records: TArrayCodec<TTransferSetupRecordFormCodec>,
    new_transfer_setup_record: TNewTransferSetupRecordForCaseFormCodec,
    our_side_case_users: TArrayCodec<TUsersCasesUserLegalNameCodec>,
    our_side_company_name: TUnionCodec<[TNullCodec, TAnyStringCodec]>
    create_dcp_form: TUuidFormCodec,
    create_TA13_form: TUuidFormCodec,
    create_esign_cert_form: TUuidFormCodec,
    create_transfer_disclaimer_form: TUuidFormCodec,
}>> = form(
    required({
        case_id: uuid(),
    }),
    required({
        details: CaseContractDetailsForm,
        transaction_type: TransactionType1,
        case_status: CaseStatus,
        properties: array(CasePropertyForm),
        new_property: CaseNewPropertyForm,
        other_side_members: array(CaseOtherSideMemberForm),
        new_other_side_member: CaseNewOtherSideMemberForm,
        marketing_campaigns: array(MarketingCampaign1),
        is_instructed: union([nullCodec(), dateTime()]),
        introducers: array(Introducer1),
        dual_rep: required({
            create_link: CaseDualRepCreateForm,
            linked_case_form: union([CaseDualRepLinkedForm, nullCodec()]),
        }),
        user_joint_ownership_held_as_preferences: CaseContractSectionFormUserJointOwnershipHeldAsPreferences,
        transfer_setup_records: array(TransferSetupRecordForm),
        new_transfer_setup_record: NewTransferSetupRecordForCaseForm,
        our_side_case_users: array(UsersCasesUserLegalName),
        our_side_company_name: union([nullCodec(), string()]),
        create_dcp_form: UuidForm,
        create_TA13_form: UuidForm,
        create_esign_cert_form: UuidForm,
        create_transfer_disclaimer_form: UuidForm,
    }),
);
export type TCaseContractSectionFormCodec = typeof CaseContractSectionForm;
export type TCaseContractSectionForm = TTypeOfCodec<TCaseContractSectionFormCodec>;
