import React from "react";

interface ICRMTitleSectionProps {
    id?: string;
}
export class CRMTitleSection extends React.Component<React.PropsWithChildren<ICRMTitleSectionProps>> {
    public render (): JSX.Element {
        return (
            <div>
                <span id={this.props.id} className="crm-title-section">
                    {this.props.children}
                </span>
            </div>
        );
    }
}
