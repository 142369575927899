import { pipe } from "fp-ts/lib/function";
import { array, TArrayCodec } from "../../shared/src/codecs/types/array";
import { boolean, TBooleanCodec } from "../../shared/src/codecs/types/boolean";
import { intersection } from "../../shared/src/codecs/types/intersection";
import { overload } from "../../shared/src/codecs/types/overload";
import { positiveInteger, TAnyPositiveIntegerCodec } from "../../shared/src/codecs/types/positiveInteger";
import { required, TRequiredCodec } from "../../shared/src/codecs/types/required";
import { requiredFlatOverloaded } from "../../shared/src/codecs/types/requiredFlatOverloaded";
import { CaseLedgerEntry2, CaseLedgerType, ledgerTypeDictionary, TCaseLedgerType } from "./CaseLedger";
import { CaseLedgerTypeDisplayName } from "./overloads/CaseLedgerTypeDisplayName";
import { array as fptsArray, record } from "fp-ts";
import { TCodec, TTypeOfCodec, TTypeOfNewDefault } from "../../shared/src/codecs/codec";
import { TUnionCodec, union } from "../../shared/src/codecs/types/union";
import { nullCodec, TNullCodec } from "../../shared/src/codecs/types/nullCodec";
import { uuid } from "../../shared/src/codecs/types/uuid";
import { CreateLegalFileDownloadUrl } from "./overloads/LegalFileDownloadUrl";
import { QuoteRateAttribute, QuoteRateClientsOwnership, QuoteRateClientsPropertyCount, QuoteRatePropertyResidentialCommercial, QuoteRateTaxGroup, QuoteRateTransactionType, TQuoteRateAttribute } from "./QuoteRate";
import { Tenure1 } from "./Tenure";
import { last } from "fp-ts/lib/Semigroup";
import { dateTime } from "../../shared/src/codecs/types/dateTime";
import { Address3 } from "./Address";
import { QuoteRateFeePropertyValue, QuoteRateFeeTransferValue, TQuoteRateFeePropertyValue, TQuoteRateFeeTransferValue } from "./QuoteRateFee";
import { contramap } from "fp-ts/lib/Ord";
import { Ord } from "fp-ts/lib/number";
import { currentStandardVatRate, multipleToIncludeVAT } from "../constants";
import { string } from "../../shared/src/codecs/types/string";
import { DateTimeToSimpleUiDate } from "./overloads/DateTimeToSimpleUiDate";
import { CaseLedgerTypeCategory } from "./overloads/CaseLedgerTypeCategory";
import { dateTimeOrNullAsBoolean } from "../../shared/src/codecs/types/dateTimeOrNullAsBoolean";

const getApplicableFeePence = (p: {
    "property_price_pence--transfer-monetary-value": number,
    "property_price_pence--property-value": number,
    transfer_value_fees: Array<TQuoteRateFeeTransferValue>,
    property_value_fees: Array<TQuoteRateFeePropertyValue>,
    rate_type: TCaseLedgerType,
    referral_fee_pence: number
}): number => {
    const isPropertyValueFees = p.property_value_fees.length > 0;

    const inTransferValueBand = (fee: TQuoteRateFeeTransferValue): boolean =>
        p["property_price_pence--transfer-monetary-value"] >= (fee.when_greater_than_or_equal_to_property_price_pence || 0)
        && (
            p["property_price_pence--transfer-monetary-value"] < (fee.when_less_than_property_price_pence || 0)
            || (fee.when_less_than_property_price_pence || 0) === 0
        );

    const inPropertyValueBand = (fee: TQuoteRateFeePropertyValue): boolean =>
        p["property_price_pence--property-value"] >= (fee.when_greater_than_or_equal_to_property_price_pence || 0)
        && (
            p["property_price_pence--property-value"] < (fee.when_less_than_property_price_pence || 0)
            || (fee.when_less_than_property_price_pence || 0) === 0
        );

    const sortFromLowest = contramap((fee: {when_greater_than_or_equal_to_property_price_pence: number | null}) => fee.when_greater_than_or_equal_to_property_price_pence || 0)(Ord);

    const sumTransferValueBands = (acum: number, fee: TQuoteRateFeeTransferValue) => {
        if (fee.fee_pence === 0 && fee.fee_progressive_percentage_of_property_price === 0) {
            return acum;
        }

        if (
            fee.fee_pence > 0
            && inTransferValueBand(fee)
        ) {
            return acum + fee.fee_pence;
        }

        // Get the amount in this band that we should be applying the progressive percentage to
        const amountOfPropertyPriceThatApplies =
            (
                (fee.when_less_than_property_price_pence || 0) === 0 ? p["property_price_pence--transfer-monetary-value"]
                : p["property_price_pence--transfer-monetary-value"] < (fee.when_less_than_property_price_pence || 0) ? p["property_price_pence--transfer-monetary-value"]
                : (fee.when_less_than_property_price_pence || 0)
            ) - (fee.when_greater_than_or_equal_to_property_price_pence || 0);

        return amountOfPropertyPriceThatApplies > 0
            ? acum + Math.round((amountOfPropertyPriceThatApplies / 100) * fee.fee_progressive_percentage_of_property_price)
            : acum;
    };

    const sumPropertyValueBands = (acum: number, fee: TQuoteRateFeePropertyValue) => {
        if (fee.fee_pence === 0) {
            return acum;
        }

        if (
            fee.fee_pence > 0
            && inPropertyValueBand(fee)
        ) {
            return acum + fee.fee_pence;
        }

        return acum;
    };
    
    return pipe(
        (
            isPropertyValueFees
                ? pipe(
                    p.property_value_fees,
                    fptsArray.sortBy([sortFromLowest]),
                    fptsArray.reduce(0, sumPropertyValueBands),
                )
                : pipe(
                    p.transfer_value_fees,
                    fptsArray.sortBy([sortFromLowest]),
                    fptsArray.reduce(0, sumTransferValueBands),
                )
        ),
        (feePence) =>
            p.rate_type === "legal_fee"
                ? feePence + p.referral_fee_pence
                : feePence,
    );
};

export const QuoteItem = intersection([
    required({
        rate_type: CaseLedgerType,
        has_vat: boolean(),
        quantity: positiveInteger(),
    }),
    requiredFlatOverloaded({
        category: CaseLedgerTypeCategory,
    }),
    requiredFlatOverloaded({
        item_display_name: CaseLedgerTypeDisplayName,
    }),
    requiredFlatOverloaded({
        vat_pence: overload(
            positiveInteger(),
            required({
                rate_type: CaseLedgerType,
                transfer_value_fees: array(QuoteRateFeeTransferValue),
                property_value_fees: array(QuoteRateFeePropertyValue),
                quantity: positiveInteger(),
                "property_price_pence--transfer-monetary-value": positiveInteger(),
                "property_price_pence--property-value": positiveInteger(),
                referral_fee_pence: positiveInteger(),
                has_vat: boolean(),
            }),
            (p) =>
                p.has_vat
                    ? Math.round((getApplicableFeePence(p) * p.quantity) * currentStandardVatRate)
                    : 0,
        ),
    }),
    requiredFlatOverloaded({
        fee_pence_excluding_vat: overload(
            positiveInteger(),
            required({
                rate_type: CaseLedgerType,
                transfer_value_fees: array(QuoteRateFeeTransferValue),
                property_value_fees: array(QuoteRateFeePropertyValue),
                quantity: positiveInteger(),
                "property_price_pence--transfer-monetary-value": positiveInteger(),
                "property_price_pence--property-value": positiveInteger(),
                referral_fee_pence: positiveInteger(),
            }),
            (p) => getApplicableFeePence(p) * p.quantity,
        ),
    }),
    requiredFlatOverloaded({
        fee_pence_total: overload(
            positiveInteger(),
            required({
                rate_type: CaseLedgerType,
                transfer_value_fees: array(QuoteRateFeeTransferValue),
                property_value_fees: array(QuoteRateFeePropertyValue),
                has_vat: boolean(),
                quantity: positiveInteger(),
                "property_price_pence--transfer-monetary-value": positiveInteger(),
                "property_price_pence--property-value": positiveInteger(),
                referral_fee_pence: positiveInteger(),
            }),
            (p) =>
                p.has_vat
                    ? Math.round((getApplicableFeePence(p) * p.quantity) * multipleToIncludeVAT)
                    : getApplicableFeePence(p) * p.quantity
        )
    })
]);
export type TQuoteItem = TTypeOfCodec<typeof QuoteItem>;

export const QuoteItem_CaseLedgerEntry2 = overload(
    CaseLedgerEntry2,
    QuoteItem,
    (quoteItem) => ({
        type: quoteItem.rate_type,
        credit_or_debit: ledgerTypeDictionary[quoteItem.rate_type].creditOrDebit,
        vat_excluded_amount_gbp_pence: quoteItem.fee_pence_excluding_vat,
        vat_amount_gbp_pence: quoteItem.vat_pence,
        vat_status: quoteItem.has_vat,
        category: quoteItem.category,
        display_name: quoteItem.item_display_name,
    }),
);

const sum = (a: number, b: number): number => a + b;

export const Quote = overload(
    required({
        case_id: uuid(),
        transaction_type: QuoteRateTransactionType,
        total_pence: positiveInteger(),
        total_fee_pence: positiveInteger(),
        total_exc_fees_and_tax_pence: positiveInteger(),
        total_exc_tax_pence: positiveInteger(),
        total_tax_pence: positiveInteger(),
        referral_fee_pence: positiveInteger(),
        introducer_id: union([nullCodec(), uuid()]),
        items: array(QuoteItem),
        properties: array(Address3),
        tax_group: union([nullCodec(), QuoteRateTaxGroup]),
        will_charge_abortive_costs: boolean(),
    }),
    required({
        case_id: uuid(),
        transaction_type: QuoteRateTransactionType,
        items: array(QuoteItem),
        referral_fee_pence: positiveInteger(),
        introducer_id: union([nullCodec(), uuid()]),
        properties: array(Address3),
        tax_group: union([nullCodec(), QuoteRateTaxGroup]),
        will_charge_abortive_costs: boolean(),
    }),
    ({items, referral_fee_pence, introducer_id, properties, tax_group, case_id, transaction_type, will_charge_abortive_costs}) => ({
        total_pence: pipe(
            items,
            fptsArray.map(({fee_pence_total}) => fee_pence_total),
            fptsArray.reduce(0, sum),
        ),
        total_fee_pence: pipe(
            items,
            fptsArray.filter(({category}) => category === "fee"),
            fptsArray.map(({fee_pence_total}) => fee_pence_total),
            fptsArray.reduce(0, sum),
        ),
        total_exc_fees_and_tax_pence: pipe(
            items,
            fptsArray.filter(({category}) => category !== "fee"),
            fptsArray.filter(({rate_type}) =>
                rate_type !== "stamp_duty_land_tax"
                && rate_type !== "land_transaction_tax"
            ),
            fptsArray.map(({fee_pence_total}) => fee_pence_total),
            fptsArray.reduce(0, sum),
        ),
        total_exc_tax_pence: pipe(
            items,
            fptsArray.filter(({rate_type}) =>
                rate_type !== "stamp_duty_land_tax"
                && rate_type !== "land_transaction_tax"
            ),
            fptsArray.map(({fee_pence_total}) => fee_pence_total),
            fptsArray.reduce(0, sum),
        ),
        total_tax_pence: pipe(
            items,
            fptsArray.filter(({rate_type}) =>
                rate_type === "stamp_duty_land_tax"
                || rate_type === "land_transaction_tax"
            ),
            fptsArray.map(({fee_pence_total}) => fee_pence_total),
            fptsArray.reduce(0, sum),
        ),
        referral_fee_pence,
        introducer_id,
        items: pipe(
            items,
            fptsArray.filter(({fee_pence_total}) => fee_pence_total > 0),
        ),
        properties,
        tax_group,
        case_id,
        transaction_type,
        will_charge_abortive_costs
    })
);
export type TQuoteCodec = typeof Quote;
export type TQuote = TTypeOfCodec<TQuoteCodec>;

export const Quotes: TCodec<
    "OverloadCodec",
    {
        overloadCodec: TArrayCodec<TQuoteCodec>;
        codec: TRequiredCodec<{
            total_pence: TAnyPositiveIntegerCodec,
            total_fee_pence: TAnyPositiveIntegerCodec,
            total_exc_fees_and_tax_pence: TAnyPositiveIntegerCodec,
            total_exc_tax_pence: TAnyPositiveIntegerCodec,
            total_tax_pence: TAnyPositiveIntegerCodec,
            quotes: TArrayCodec<TQuoteCodec>
        }>
    },
    TTypeOfCodec<
        TRequiredCodec<{
            total_pence: TAnyPositiveIntegerCodec,
            total_fee_pence: TAnyPositiveIntegerCodec,
            total_exc_fees_and_tax_pence: TAnyPositiveIntegerCodec,
            total_exc_tax_pence: TAnyPositiveIntegerCodec,
            total_tax_pence: TAnyPositiveIntegerCodec,
            quotes: TArrayCodec<TQuoteCodec>
        }>
    >,
    TTypeOfNewDefault<
        TRequiredCodec<{
            total_pence: TAnyPositiveIntegerCodec,
            total_fee_pence: TAnyPositiveIntegerCodec,
            total_exc_fees_and_tax_pence: TAnyPositiveIntegerCodec,
            total_exc_tax_pence: TAnyPositiveIntegerCodec,
            total_tax_pence: TAnyPositiveIntegerCodec,
            quotes: TArrayCodec<TQuoteCodec>
        }>
    >
> = overload(
    required({
        total_pence: positiveInteger(),
        total_fee_pence: positiveInteger(),
        total_exc_fees_and_tax_pence: positiveInteger(),
        total_exc_tax_pence: positiveInteger(),
        total_tax_pence: positiveInteger(),
        quotes: array(Quote),
    }),
    array(Quote),
    (quotes) => ({
        total_pence: pipe(
            quotes,
            fptsArray.map(({total_pence}) => total_pence),
            fptsArray.reduce(0, sum),
        ),
        total_fee_pence: pipe(
            quotes,
            fptsArray.map(({total_fee_pence}) => total_fee_pence),
            fptsArray.reduce(0, sum),
        ),
        total_exc_fees_and_tax_pence: pipe(
            quotes,
            fptsArray.map(({total_exc_fees_and_tax_pence}) => total_exc_fees_and_tax_pence),
            fptsArray.reduce(0, sum),
        ),
        total_exc_tax_pence: pipe(
            quotes,
            fptsArray.map(({total_exc_tax_pence}) => total_exc_tax_pence),
            fptsArray.reduce(0, sum),
        ),
        total_tax_pence: pipe(
            quotes,
            fptsArray.map(({total_tax_pence}) => total_tax_pence),
            fptsArray.reduce(0, sum),
        ),
        quotes,
    })
);
export type TQuotesCodec = typeof Quotes;
export type TQuotes = TTypeOfCodec<TQuotesCodec>;

const QuoteFactorAttributesUnfilled = required<
    {[key in TQuoteRateAttribute]: TUnionCodec<[TNullCodec, TBooleanCodec]>},
    {[key in TQuoteRateAttribute]: null | boolean},
    {[key in TQuoteRateAttribute]: null | boolean}
    >(
        pipe(
            QuoteRateAttribute.values,
            fptsArray.map<TQuoteRateAttribute, [TQuoteRateAttribute, TUnionCodec<[TNullCodec, TBooleanCodec]>]>((attribute) => [attribute, union([nullCodec(), boolean()])]),
            record.fromFoldable(last<TUnionCodec<[TNullCodec, TBooleanCodec]>>(), fptsArray.Foldable),
        ) as {[key in TQuoteRateAttribute]: TUnionCodec<[TNullCodec, TBooleanCodec]>}
    );

const QuoteFactorAttributes = overload(
    QuoteFactorAttributesUnfilled,
    union([
        array(QuoteRateAttribute),
        QuoteFactorAttributesUnfilled,
    ]),
    (value) =>
        Array.isArray(value)
            ? pipe(
                QuoteFactorAttributesUnfilled.newDefault(),
                record.mapWithIndex((key) => value.includes(key)),
            )
            : value,
);

export const QuoteFactorsFilled = required({
    transaction_type: QuoteRateTransactionType,
    tenure: Tenure1,
    "property_price_pence--transfer-monetary-value": positiveInteger(),
    "property_price_pence--property-value": positiveInteger(),
    number_of_clients: positiveInteger(),
    attributes: QuoteFactorAttributes,
    property_residential_commercial: union([
        nullCodec(),
        ...QuoteRatePropertyResidentialCommercial.payload,
    ]),
    clients_ownership: union([
        nullCodec(),
        ...QuoteRateClientsOwnership.payload
    ]),
    clients_property_count: union([
        nullCodec(),
        ...QuoteRateClientsPropertyCount.payload,
    ]),
});

export const QuoteFactorsUnfilled = required({
    transaction_type: union([
        nullCodec(),
        ...QuoteRateTransactionType.payload,
    ]),
    tenure: union([...Tenure1.payload, nullCodec()]),
    "property_price_pence--transfer-monetary-value": union([positiveInteger(), nullCodec()]),
    "property_price_pence--property-value": union([positiveInteger(), nullCodec()]),
    number_of_clients: union([positiveInteger(), nullCodec()]),
    attributes: QuoteFactorAttributes,
    property_residential_commercial: union([
        nullCodec(),
        ...QuoteRatePropertyResidentialCommercial.payload,
    ]),
    clients_ownership: union([
        nullCodec(),
        ...QuoteRateClientsOwnership.payload
    ]),
    clients_property_count: union([
        nullCodec(),
        ...QuoteRateClientsPropertyCount.payload,
    ]),
});

export const CaseQuoteDownloadUrl = requiredFlatOverloaded({
    download_url: CreateLegalFileDownloadUrl("Quote"),
});
export type TCaseQuoteDownloadUrl = TTypeOfCodec<typeof CaseQuoteDownloadUrl>;

export const CaseQuote = intersection([
    Quote,
    QuoteFactorsFilled,
    required({
        id: uuid(),
        introducer_name: string(),
        created_at: dateTime(),
    }),
    CaseQuoteDownloadUrl,
]);
export type TCaseQuoteCodec = typeof CaseQuote;
export type TCaseQuote = TTypeOfCodec<TCaseQuoteCodec>;

export const QuoteEmailAttachment = intersection([
    required({
        id: uuid(),
        created_at: DateTimeToSimpleUiDate,
    }),
    CaseQuoteDownloadUrl,
]);
export type TQuoteEmailAttachment = TTypeOfCodec<typeof QuoteEmailAttachment>;

export const CaseCreateQuoteFilled = intersection([
    required({
        case_id: uuid(),
    }),
    QuoteFactorsFilled,
]);
export type TCaseCreateQuoteFilledCodec = typeof CaseCreateQuoteFilled;
export type TCaseCreateQuoteFilled = TTypeOfCodec<TCaseCreateQuoteFilledCodec>;


export const CaseQuoteSentToClient = required({
    case_id: uuid(),
    quote_sent_to_client: dateTimeOrNullAsBoolean(),
});

export type TCaseQuoteSentToClientCodec = typeof CaseQuoteSentToClient;
export type TCaseQuoteSentToClient = TTypeOfCodec<TCaseQuoteSentToClientCodec>;

export const CaseCreateQuoteUnfilled = intersection([
    required({
        case_id: uuid(),
    }),
    QuoteFactorsUnfilled,
]);
export type TCaseCreateQuoteUnfilledCodec = typeof CaseCreateQuoteUnfilled;
export type TCaseCreateQuoteUnfilled = TTypeOfCodec<TCaseCreateQuoteUnfilledCodec>;
