import React from "react";

export default (props: React.SVGAttributes<SVGElement>) => 
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g clipPath="url(#clip0_11419_61141)">
            <path d="M8.00065 2L1.33398 8H3.33398L3.33398 13.3333L7.33398 13.3333L7.33398 9.33333H8.66732L8.66732 13.3333H12.6673V8H14.6673L8.00065 2Z" fill="#00011B"/>
        </g>
        <defs>
            <clipPath id="clip0_11419_61141">
                <rect width="16" height="16" fill="white"/>
            </clipPath>
        </defs>
    </svg>
;
