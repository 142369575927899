import React from "react";

export class CRMCardInside extends React.Component<React.PropsWithChildren<{}>> {
    public render (): JSX.Element {
        return (
            <div className="crm-card-inside">
                {this.props.children}
            </div>
        );
    }
}
