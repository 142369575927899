import React from "react";

export default (props: React.SVGAttributes<SVGElement>) => (
    <svg 
        width="24" 
        height="24" 
        viewBox="0 0 24 24" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M20 4H18V20H20V4Z" fill="black"/>
        <path d="M6 4H4V20H6V4Z" fill="black"/>
        <path d="M13 4H11V8H13V4Z" fill="black"/>
        <path d="M13 10H11V14H13V10Z" fill="black"/>
        <path d="M13 16H11V20H13V16Z" fill="black"/>
    </svg>
)
