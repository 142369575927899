import React from "react";

export default (props: React.SVGAttributes<SVGElement>) => <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g clipPath="url(#clip0_5297:25131)">
<path d="M20.9 43.9998C20.7825 43.9998 20.6651 43.981 20.5521 43.9433C16.5058 42.5946 11.4282 38.1104 7.61612 32.5195C4.14171 27.4236 0 18.981 0 7.69977C0 7.0923 0.492525 6.59977 1.1 6.59977C7.29018 6.59977 17.0238 2.36189 20.2898 0.184504C20.6593 -0.061827 21.1407 -0.061827 21.5102 0.184504C24.7762 2.36189 34.5099 6.59977 40.7 6.59977C41.3075 6.59977 41.8 7.0923 41.8 7.69977C41.8 18.981 37.6583 27.4236 34.1839 32.5195C30.3718 38.1104 25.2942 42.5946 21.2479 43.9433C21.1349 43.981 21.0175 43.9998 20.9 43.9998V43.9998ZM2.21292 8.76368C2.46194 18.9513 6.24394 26.6016 9.43381 31.2801C13.3253 36.9876 17.9168 40.5853 20.9 41.7312C23.8832 40.5853 28.4747 36.9876 32.3661 31.2801C35.556 26.6016 39.338 18.9513 39.587 8.76368C36.0048 8.53887 32.0156 7.30632 29.1805 6.2563C26.0604 5.10075 22.9133 3.63184 20.9 2.40327C18.8867 3.63184 15.7396 5.10075 12.6195 6.2563C9.78436 7.30632 5.79521 8.53893 2.21292 8.76368V8.76368Z" fill="black"/>
<path d="M29.3155 26.6648L22.5899 20.9L29.3155 15.1352C29.7768 14.7399 29.8301 14.0454 29.4348 13.5841C29.0394 13.1229 28.3451 13.0693 27.8837 13.4648L20.8996 19.4512L13.9155 13.4648C13.4544 13.0695 12.7599 13.1229 12.3645 13.5841C11.9691 14.0454 12.0225 14.7398 12.4837 15.1352L19.2094 20.9L12.4837 26.6648C12.0225 27.0602 11.9691 27.7547 12.3645 28.216C12.5821 28.4697 12.8901 28.6001 13.2001 28.6001C13.4535 28.6001 13.708 28.513 13.9155 28.3353L20.8996 22.3488L27.8838 28.3352C28.0912 28.513 28.3458 28.6 28.5991 28.6C28.9091 28.6 29.2172 28.4697 29.4348 28.2159C29.8301 27.7546 29.7768 27.0602 29.3155 26.6648V26.6648Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_5297:25131">
<rect width="44" height="44" fill="white"/>
</clipPath>
</defs>
</svg>
;
