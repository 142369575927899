import { TTypeOfCodec } from "../../shared/src/codecs/codec";
import { literal } from "../../shared/src/codecs/types/literal";
import { union } from "../../shared/src/codecs/types/union";

export const YesNoUnknown = union([
    literal("yes"),
    literal("no"),
    literal("unknown"),
]);
export type TYesNoUnknownCodec = typeof YesNoUnknown;
export type TYesNoUnknown = TTypeOfCodec<TYesNoUnknownCodec>;