import * as t from "io-ts";
import * as JsonResponse1 from "./JsonResponse1";
import * as ListingEnquiriesViewing1 from "./ListingEnquiriesViewing1";

const codec = JsonResponse1.createCodec(
    ListingEnquiriesViewing1.codec,
    t.type({}),
);

export type T = t.TypeOf<typeof codec>;

export const newDefault = (): T => ({
    data: ListingEnquiriesViewing1.newDefault(),
    meta: {},
});
