import { TTypeOfCodec, TTypeOfNewDefault } from "../../../shared/src/codecs/codec";
import { form, TFormCodec } from "../../../shared/src/codecs/types/form";
import { EmptyObject, TEmptyObjectCodec } from "../EmptyObject";
import { PhoneNumber1, TPhoneNumber1Codec } from "../PhoneNumber";

const PhoneNumberFormCodec: TFormCodec<TPhoneNumber1Codec, TEmptyObjectCodec> = form(PhoneNumber1, EmptyObject);

export const PhoneNumberForm: TPhoneNumberFormCodec = PhoneNumberFormCodec;
export type TPhoneNumberFormCodec = typeof PhoneNumberFormCodec;
export type TPhoneNumberForm = TTypeOfCodec<TPhoneNumberFormCodec>;
export type TPhoneNumberFormNewDefault = TTypeOfNewDefault<TPhoneNumberFormCodec>;