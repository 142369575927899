import { TTypeOfCodec } from "../../shared/src/codecs/codec";
import { boolean } from "../../shared/src/codecs/types/boolean";
import { deferDateTime } from "../../shared/src/codecs/types/deferDateTime";
import { intersection } from "../../shared/src/codecs/types/intersection";
import { longString } from "../../shared/src/codecs/types/longString";
import { nullCodec } from "../../shared/src/codecs/types/nullCodec";
import { overload } from "../../shared/src/codecs/types/overload";
import { required } from "../../shared/src/codecs/types/required";
import { string } from "../../shared/src/codecs/types/string";
import { union } from "../../shared/src/codecs/types/union";
import { ListingSection } from "./ListingSection";

export const ListingAdHocTask2 = required({
    listing_id: string(),
    task: longString(),
    section: ListingSection,
});
export type TListingAdHocTask2Codec = typeof ListingAdHocTask2;
export type TListingAdHocTask2 = TTypeOfCodec<typeof ListingAdHocTask2>;


export const ListingAdHocTask1 = intersection([
    ListingAdHocTask2,
    required({
        id: string(),
        done: boolean(),
        deferred_date: union([deferDateTime(), nullCodec()]),
    })
]);
export type TListingAdHocTask1Codec = typeof ListingAdHocTask1;

const ListingAdHocTask4 = required({
    task: longString(),
    section: ListingSection,
});

export const ListingAdHocTaskSectionOverload = overload(
    ListingSection,
    ListingAdHocTask4,
    (task) => task.section 
);
export type TListingAdHocTaskSectionOverloadCodec = typeof ListingAdHocTaskSectionOverload;

export const ListingAdHocTaskOverload = overload(
    longString(),
    ListingAdHocTask4,
    (task) => task.task
);
export type TListingAdHocTaskOverloadCodec = typeof ListingAdHocTaskOverload;