import * as t from "io-ts";
import * as Case4 from "./Case4";
import * as CaseStatus1 from "./CaseStatus1";

export const codec = t.type({
    created_at: t.string,
    listing_id: t.union([t.string, t.null]),
    status: CaseStatus1.codec,
    ...Case4.codec.props,
    address: t.string,
});

export type T = t.TypeOf<typeof codec>;

export const newDefault = (): T => ({
    ...Case4.newDefault(),
    listing_id: null,
    created_at: "",
    status: "quote",
    address: "",
});
