import React, { useEffect, useState } from "react";
import { isAFunction } from "../functions/functions";

export interface IUseOpenClose {
    ref: React.RefObject<HTMLDivElement>;
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    toggleOpen: () => void;
}

type TOpenCloseProps = {
    inputRef?: React.RefObject<HTMLDivElement>;
    defaultValue?: boolean;
    turnOffGlobalScrollOnOpen?: boolean;
    onToggle?: (value: boolean) => void;
};

export const useOpenClose = (givenProps?: TOpenCloseProps): IUseOpenClose => {

    const props = givenProps || {};
    const ref = givenProps?.inputRef ? givenProps?.inputRef : React.createRef<HTMLDivElement>();
    const [isOpen, setIsOpen] = useState(props.defaultValue !== undefined ? props.defaultValue : false);

    const callOnToggleCallback = (isOpen: boolean) => {
        if (isAFunction<(isOpen: boolean) => void>(props.onToggle)) {
            props.onToggle(isOpen);
        }
    }

    const toggleOpen = () => {
        let newState = !isOpen;
        setIsOpen(newState);
        callOnToggleCallback(newState);
    }

    const handleClickOutside = (event: MouseEvent): void => {
        if (ref && ref.current && !ref.current.contains(event.target as Node | null)) {
            setIsOpen(false);
            callOnToggleCallback(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", (handleClickOutside));
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    });

    if (props.turnOffGlobalScrollOnOpen) {
        useEffect(() => {
            if (isOpen) {
                document.body.style.overflow = "hidden";
            }
            
            if (!isOpen) {
                document.body.style.overflow = "auto";
            }
            
            return () => {
                document.body.style.overflow = "auto";
            }
        }, [isOpen]);
    }

    return {
        ref,
        isOpen,
        setIsOpen,
        toggleOpen,
    };
};
