import React, { CSSProperties } from "react";
import { TPercentageSize, TPixelSize } from "../../models/StringLiterals";

type TPaddingType = "all"
    | "horizontal"
    | "vertical"
    | "left"
    | "right"
    | "top"
    | "bottom"
    | "custom"
;

export type TPaddingProps = {
    type?: TPaddingType;
    width?: TPixelSize | TPercentageSize,
    height?: TPixelSize | TPercentageSize,
    spacing: string;
    cursor?: CSSProperties["cursor"],
    onClick?: () => void;
};

/**
 * @deprecated Use <Background padding={`...`}> instead.
 */
export const Padding = (props: React.PropsWithChildren<TPaddingProps>): JSX.Element => {

    const paddingTypeToPaddingValue = (paddingType: TPaddingType, spacing: string): string => {
        switch (paddingType) {
            case "vertical":
                return `${spacing} 0`;
            case "horizontal":
                return `0 ${spacing}`;
            case "left":
                return `0 0 0 ${spacing}`;
            case "right":
                return `0 ${spacing} 0 0`;
            case "top":
                return `${spacing} 0 0 0`;
            case "bottom":
                return `0 0 ${spacing} 0`;
            case "all":
            case "custom":
            default:
                return spacing;
        }
    }

    return (
        <div
            style={{
                display: "flex",
                padding: paddingTypeToPaddingValue(props.type || "all", props.spacing),
                width: props.width,
                height: props.height,
                cursor: props.cursor,
            }}
            onClick={props.onClick}
        >
            {props.children}
        </div>
    );
};
