import React from "react";

export default (props: React.SVGAttributes<SVGElement>) => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g clipPath="url(#clip0_8364_41382)">
<path d="M11.25 18.975H12.75V17.975H14.25C14.4667 17.975 14.6458 17.9042 14.7875 17.7625C14.9292 17.6208 15 17.4417 15 17.225V13.975C15 13.7583 14.9292 13.5792 14.7875 13.4375C14.6458 13.2958 14.4667 13.225 14.25 13.225H10.5V11.475H15V9.975H12.75V8.975H11.25V9.975H9.75C9.53333 9.975 9.35417 10.0458 9.2125 10.1875C9.07083 10.3292 9 10.5083 9 10.725V13.975C9 14.1917 9.07083 14.3708 9.2125 14.5125C9.35417 14.6542 9.53333 14.725 9.75 14.725H13.5V16.475H9V17.975H11.25V18.975ZM5.5 22C5.1 22 4.75 21.85 4.45 21.55C4.15 21.25 4 20.9 4 20.5V3.5C4 3.1 4.15 2.75 4.45 2.45C4.75 2.15 5.1 2 5.5 2H14.525L20 7.475V20.5C20 20.9 19.85 21.25 19.55 21.55C19.25 21.85 18.9 22 18.5 22H5.5ZM13.275 7.475V3.5H5.5V20.5H18.5V7.475H13.275Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_8364_41382">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>
;
