import React from "react";

export default (props: React.SVGAttributes<SVGElement>) => <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g clipPath="url(#clip0)">
<path d="M11.6667 7.57495L19.0083 9.03328C19.6 9.15828 20 9.65828 20 10.25V18.75C20 19.4416 19.4417 20 18.75 20H11.25C11.4833 20 11.6667 19.8166 11.6667 19.5833V19.1666H18.75C18.975 19.1666 19.1667 18.9833 19.1667 18.75V10.25C19.1667 10.0583 19.0333 9.88329 18.8417 9.84162L11.6667 8.42495V7.57495Z" fill="white"/>
<path d="M16.25 11.6665C16.4834 11.6665 16.6667 11.8498 16.6667 12.0832C16.6667 12.3165 16.4834 12.4998 16.25 12.4998H14.5834C14.35 12.4998 14.1667 12.3165 14.1667 12.0832C14.1667 11.8498 14.35 11.6665 14.5834 11.6665H16.25Z" fill="white"/>
<path d="M16.25 14.1665C16.4834 14.1665 16.6667 14.3498 16.6667 14.5832C16.6667 14.8165 16.4834 14.9998 16.25 14.9998H14.5834C14.35 14.9998 14.1667 14.8165 14.1667 14.5832C14.1667 14.3498 14.35 14.1665 14.5834 14.1665H16.25Z" fill="white"/>
<path d="M16.25 16.6665C16.4834 16.6665 16.6667 16.8498 16.6667 17.0832C16.6667 17.3165 16.4834 17.4998 16.25 17.4998H14.5834C14.35 17.4998 14.1667 17.3165 14.1667 17.0832C14.1667 16.8498 14.35 16.6665 14.5834 16.6665H16.25Z" fill="white"/>
<path d="M11.6666 19.5835C11.6666 19.8168 11.4833 20.0001 11.25 20.0001C11.0166 20.0001 10.8333 19.8168 10.8333 19.5835V19.1668V7.91679C10.8333 7.79179 10.8916 7.67512 10.9833 7.59178C11.0833 7.51678 11.2083 7.48345 11.3333 7.50845L11.6666 7.57512V8.42512V19.1668V19.5835Z" fill="white"/>
<path d="M10.8334 19.1665V19.5832C10.8334 19.8165 11.0167 19.9998 11.25 19.9998H7.91669C8.15002 19.9998 8.33335 19.8165 8.33335 19.5832V19.1665H10.8334Z" fill="white"/>
<path d="M8.75002 4.1665C8.98335 4.1665 9.16669 4.34984 9.16669 4.58317C9.16669 4.81651 8.98335 4.99984 8.75002 4.99984H7.08335C6.85002 4.99984 6.66669 4.81651 6.66669 4.58317C6.66669 4.34984 6.85002 4.1665 7.08335 4.1665H8.75002Z" fill="white"/>
<path d="M9.16669 7.08317C9.16669 7.31651 8.98335 7.49984 8.75002 7.49984H7.08335C6.85002 7.49984 6.66669 7.31651 6.66669 7.08317C6.66669 6.84984 6.85002 6.6665 7.08335 6.6665H8.75002C8.98335 6.6665 9.16669 6.84984 9.16669 7.08317Z" fill="white"/>
<path d="M8.75002 9.1665C8.98335 9.1665 9.16669 9.34984 9.16669 9.58317C9.16669 9.8165 8.98335 9.99983 8.75002 9.99983H7.08335C6.85002 9.99983 6.66669 9.8165 6.66669 9.58317C6.66669 9.34984 6.85002 9.1665 7.08335 9.1665H8.75002Z" fill="white"/>
<path d="M8.75002 11.6665C8.98335 11.6665 9.16669 11.8498 9.16669 12.0832C9.16669 12.3165 8.98335 12.4998 8.75002 12.4998H7.08335C6.85002 12.4998 6.66669 12.3165 6.66669 12.0832C6.66669 11.8498 6.85002 11.6665 7.08335 11.6665H8.75002Z" fill="white"/>
<path d="M5 12.0832C5 12.3165 4.81667 12.4998 4.58333 12.4998H2.91667C2.68333 12.4998 2.5 12.3165 2.5 12.0832C2.5 11.8498 2.68333 11.6665 2.91667 11.6665H4.58333C4.81667 11.6665 5 11.8498 5 12.0832Z" fill="white"/>
<path d="M4.58333 4.1665C4.81667 4.1665 5 4.34984 5 4.58317C5 4.81651 4.81667 4.99984 4.58333 4.99984H2.91667C2.68333 4.99984 2.5 4.81651 2.5 4.58317C2.5 4.34984 2.68333 4.1665 2.91667 4.1665H4.58333Z" fill="white"/>
<path d="M4.58333 6.6665C4.81667 6.6665 5 6.84984 5 7.08317C5 7.31651 4.81667 7.49984 4.58333 7.49984H2.91667C2.68333 7.49984 2.5 7.31651 2.5 7.08317C2.5 6.84984 2.68333 6.6665 2.91667 6.6665H4.58333Z" fill="white"/>
<path d="M4.58333 9.1665C4.81667 9.1665 5 9.34984 5 9.58317C5 9.8165 4.81667 9.99983 4.58333 9.99983H2.91667C2.68333 9.99983 2.5 9.8165 2.5 9.58317C2.5 9.34984 2.68333 9.1665 2.91667 9.1665H4.58333Z" fill="white"/>
<path d="M7.50004 15.4165C7.50004 15.1832 7.30837 14.9998 7.08337 14.9998H4.58337C4.35004 14.9998 4.16671 15.1832 4.16671 15.4165V19.1665H3.33337V15.4165C3.33337 14.7248 3.89171 14.1665 4.58337 14.1665H7.08337C7.77504 14.1665 8.33337 14.7248 8.33337 15.4165V19.1665H7.50004V15.4165Z" fill="white"/>
<path d="M4.16671 19.1665H7.50004H8.33337V19.5832C8.33337 19.8165 8.15004 19.9998 7.91671 19.9998H3.75004C3.51671 19.9998 3.33337 19.8165 3.33337 19.5832V19.1665H4.16671Z" fill="white"/>
<path d="M1.45833 0.166615L10.6167 1.55828C11.225 1.65828 11.6667 2.17495 11.6667 2.79162V7.57495L11.3333 7.50828C11.2083 7.48328 11.0833 7.51661 10.9833 7.59162C10.8917 7.67495 10.8333 7.79162 10.8333 7.91662V2.79162C10.8333 2.58328 10.6833 2.40828 10.4833 2.37495L1.325 0.991615C1.3 0.983282 1.275 0.983281 1.25 0.983281C1.15 0.983281 1.05833 1.01661 0.983333 1.08328C0.883333 1.16661 0.833333 1.27495 0.833333 1.39995V18.75C0.833333 18.9833 1.025 19.1666 1.25 19.1666H3.33333V19.5833C3.33333 19.8166 3.51667 20 3.75 20H1.25C0.558333 20 0 19.4416 0 18.75V1.39995C0 1.03328 0.158333 0.683282 0.441667 0.449948C0.725 0.208281 1.09167 0.108281 1.45833 0.166615Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0">
<rect width="20" height="20" fill="white"/>
</clipPath>
</defs>
</svg>
;
