import React from "react";

export const FontQuicksand = (props: React.PropsWithChildren<{}>): JSX.Element => (
    <span
        style={{
            fontFamily: "Quicksand, sans-serif",
            display: "inline-flex",
        }}
    >
        {props.children}
    </span>
);
