import { inherit, TCodec } from "../codec";
import { string, TAnyStringCodec } from "./string";
import { option } from "fp-ts/lib";
import { errorConstants } from "../errors";
import * as regexes from "../../regexes";

export type TAnyUuidCodec = TCodec<"UuidCodec", { codec: TAnyStringCodec }, string, string>;

export type TUuidCodec = TCodec<
    "UuidCodec",
    { codec: TAnyStringCodec },
    string,
    string
>;

export const uuid = (): TAnyUuidCodec => inherit(
    "UuidCodec",
    {codec: string()},
    (input) => typeof input === "string"
        && regexes.constants.UUID_REGEX.test(input)
        ? option.none
        : option.some([[errorConstants.UUID_VALIDATION, ""]]),
    () => option.none
);
