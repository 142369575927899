import { TCodec } from "../codec";
import { either } from "fp-ts/lib";
import { TError, errorConstants } from "../errors";

export type TAnyFalseCodec = TCodec<
    "FalseCodec",
    undefined,
    false,
    false
>;

export type TFalseCodec = TCodec<
    "FalseCodec",
    undefined,
    false,
    false
>;

const decode = (input: unknown): either.Either<TError, false> =>
    typeof input === "boolean" && input === false
        ? either.right(input)
        : either.left([[errorConstants.FALSE_VALIDATION, ""]]);

export const falseCodec = (): TAnyFalseCodec => ({
    type: "FalseCodec",
    payload: undefined,
    decode,
    decodeNewDefault: decode,
    newDefault: () => false,
});
