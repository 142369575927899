import React from "react";

export default (props: React.SVGAttributes<SVGElement>) => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g clipPath="url(#clip0)">
<path d="M19 4H17V6H19V4Z" fill="black"/>
<path d="M19 8H17V10H19V8Z" fill="black"/>
<path d="M19 12H17V14H19V12Z" fill="black"/>
<path d="M1 8V18H7V13H9V18H15V8L8 3L1 8ZM13 16H11V11H5V16H3V9L8 5.5L13 9V16Z" fill="black"/>
<path d="M10 0V1.97L12 3.4V2H21V16H17V18H23V0H10Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>
;
