import { TTypeOfCodec, TTypeOfNewDefault } from "../../shared/src/codecs/codec";
import { required } from "../../shared/src/codecs/types/required";
import { string } from "../../shared/src/codecs/types/string";
import { LegalEmailStatus1 } from "./LegalEmailStatus";
import { EmailDirection1 } from "./EmailDirection";
import { uuid } from "../../shared/src/codecs/types/uuid";
import { boolean } from "../../shared/src/codecs/types/boolean";
import { array } from "../../shared/src/codecs/types/array";
import { intersection } from "../../shared/src/codecs/types/intersection";
import { pipe } from "fp-ts/lib/function";
import { array as fptsArray } from "fp-ts/lib";
import * as util from "../../shared/src/util";
import { CaseIdAndAddresses } from "./Cases";
import { overload } from "../../shared/src/codecs/types/overload";
import { Pagination2 } from "./Pagination";
import { nullCodec } from "../../shared/src/codecs/types/nullCodec";
import { union } from "../../shared/src/codecs/types/union";
import { requiredFlatOverloaded } from "../../shared/src/codecs/types/requiredFlatOverloaded";
import { literal } from "../../shared/src/codecs/types/literal";
import { InternalScope } from "./InternalScope";
import { partial } from "../../shared/src/codecs/types/partial";
import { EmailCompositionForm } from "./form/EmailCompositionForm";
import { dateTime } from "../../shared/src/codecs/types/dateTime";
import { positiveInteger } from "../../shared/src/codecs/types/positiveInteger";
import { CasesDocumentType } from "./CasesDocumentTypes";
import { toDisplayString } from "../../domain/models/CaseDocumentType1";

const spoofFailCases = [
    "dkim=fail",
    "dmarc=fail",
    "spf=fail",
    "spf=softfail",
    "iprev=fail",
    "auth=fail"
]

const LegalEmail1 = intersection([
    required({
        id: uuid(),
        gmail_message_ids: array(union([string(), nullCodec()])),
        direction: EmailDirection1,
        case_status: LegalEmailStatus1,
        from_address: string(),
        to_addresses: array(string()),
        subject: string(),
        sent_at: dateTime(),
        created_at: dateTime(),
        cc_addresses: array(string()),
        bcc_addresses: array(string()),
        potentially_spam: boolean(),
        read_status: boolean(),
    }),
    requiredFlatOverloaded({
        possibly_spoofed: overload(
            boolean(),
            required({ arc_authentication_results: union([string(), nullCodec()]) }),
            (r) => spoofFailCases.some((v) => (r.arc_authentication_results ?? "").toLowerCase().includes(v)),
        ),
    }),
]);
export type TLegalEmail1 = TTypeOfCodec<typeof LegalEmail1>;

export const LegalEmailCase = required({
    case_id: uuid(),
    legal_email_id: uuid(),
});
export type TLegalEmailCaseCodec = typeof LegalEmailCase;

export const LegalEmailAddresses = required({
    email_addresses: array(string())
});
export type TLegalEmailAddressesCodec = typeof LegalEmailAddresses;
export type TLegalEmailAddresses = TTypeOfCodec<typeof LegalEmailAddresses>;

export const LegalEmailEncrypted1 = intersection([
    LegalEmail1,
    required({
        encrypted_snippet: string(),
        from_address_user_verification_status: union([literal("external"), literal("verified"), literal("unverified")]),
    }),
])
type TLegalEmailEncrypted1 = TTypeOfCodec<typeof LegalEmailEncrypted1>;

export const LegalEmailAttachment1 = intersection([
    required({
        name: string(),
        download_token: string(),
        virus_detected: boolean(),
        file_size_bytes: union([positiveInteger(), nullCodec()]),
        mime_type: string(),
        is_related_to_case: boolean(),
    }),
    requiredFlatOverloaded({
        related_to_case_file_type: overload(
            string(),
            required({
                related_case_document_file_type: union([CasesDocumentType, literal("")]),
                related_additional_name: string(),
            }),
            ({related_case_document_file_type, related_additional_name}) =>
                related_case_document_file_type && related_case_document_file_type !== "other"
                    ? toDisplayString(related_case_document_file_type)
                    : related_additional_name,
        ),
    })
]);
export type TLegalEmailAttachment1 = TTypeOfCodec<typeof LegalEmailAttachment1>;

export const LegalEmailEncryptedWithBody = intersection([
    LegalEmailEncrypted1,
    required({
        encrypted_body_html_or_text: string(),
        attachments: array(LegalEmailAttachment1)
    }),
]);
export type TLegalEmailEncryptedWithBody = TTypeOfCodec<typeof LegalEmailEncryptedWithBody>;

export const LegalEmailEncrypted2 = intersection([
    LegalEmailEncrypted1,
    required({
        cases: array(CaseIdAndAddresses),
        from_address_user_verification_status: union([literal("external"), literal("verified"), literal("unverified")]),
    }),
]);

export const LegalEmailEncrypted3 = intersection([
    LegalEmailEncrypted1,
    requiredFlatOverloaded({
        is_selected_for_bulk_action: overload(
            boolean(),
            required({ id: union([string(), nullCodec()]) }),
            () => false,
        ),
    }),
    required({
        cases: array(CaseIdAndAddresses),
    }),
    partial({
        reply_email_composition_form: EmailCompositionForm,
    }),
]);
export type TLegalEmailEncrypted3 = TTypeOfCodec<typeof LegalEmailEncrypted3>;

export const LegalEmailDecrypted1 = intersection([
    LegalEmail1,
    required({
        snippet: overload(
            string(),
            required({ encrypted_snippet: string() }),
            (t) => t.encrypted_snippet
        ),
        from_address_user_verification_status: union([literal("external"), literal("verified"), literal("unverified")]),
    }),
]);
export type TLegalEmailDecrypted1Codec = typeof LegalEmailDecrypted1;
export type TLegalEmailDecrypted1 = TTypeOfCodec<typeof LegalEmailDecrypted1>;

export const LegalEmailDecryptedWithBody = intersection([
    LegalEmail1,
    required({
        snippet: overload(
            string(),
            required({ encrypted_snippet: string() }),
            (t) => t.encrypted_snippet
        ),
        body_html_or_text: overload(
            string(),
            required({ encrypted_body_html_or_text: string() }),
            (t) => t.encrypted_body_html_or_text
        ),
        from_address_user_verification_status: union([literal("external"), literal("verified"), literal("unverified")]),
        attachments: array(LegalEmailAttachment1)
    })
]);
export type TLegalEmailDecryptedWithBodyCodec = typeof LegalEmailDecryptedWithBody;
export type TLegalEmailDecryptedWithBody = TTypeOfCodec<typeof LegalEmailDecryptedWithBody>;

export const LegalEmailDecrypted2 = intersection([
    LegalEmailDecrypted1,
    requiredFlatOverloaded({
        is_selected_for_bulk_action: overload(
            boolean(),
            required({ id: union([string(), nullCodec()]) }),
            () => false,
        ),
    }),
    required({
        cases: array(CaseIdAndAddresses),
    }),
    partial({
        reply_email_composition_form: EmailCompositionForm
    }),
]);
export type TLegalEmailDecrypted2 = TTypeOfCodec<typeof LegalEmailDecrypted2>;

export const LegalEmailDecrypted3 = intersection([
    LegalEmailDecrypted1,
    requiredFlatOverloaded({
        is_selected_for_bulk_action: overload(
            boolean(),
            required({ id: union([string(), nullCodec()]) }),
            () => false,
        ),
    }),
]);
export type TLegalEmailDecrypted3 = TTypeOfCodec<typeof LegalEmailDecrypted3>;

export const LegalEmailsDecrypted3 = intersection([
    required({
        emails: array(LegalEmailDecrypted3),
    }),
    required({
        pagination: Pagination2,
    }),
]);
export type TLegalEmailsDecrypted3Codec = typeof LegalEmailsDecrypted3;
export type TLegalEmailsDecrypted3 = TTypeOfCodec<typeof LegalEmailsDecrypted3>;

export const LegalUserMailboxAccess = required({
    id: uuid(),
    user_role: InternalScope,
    first_name: string(),
    last_name: string(),
    mailbox_access_granted: boolean(),
});
export type TLegalUserMailboxAccessCodec = typeof LegalUserMailboxAccess;
export type TLegalUserMailboxAccess = TTypeOfCodec<typeof LegalUserMailboxAccess>;

export const DetailedLegalEmailForTriage = intersection([
    required({
        id: uuid(),
        sent_at: dateTime(),
        from_address: string(),
        to_addresses: array(string()),
        cc_addresses: array(string()),
        bcc_addresses: array(string()),
        subject: string(),
        body_html_or_text: string(),
        direction: EmailDirection1,
        attachments: array(LegalEmailAttachment1),
        read_status: boolean(),
        from_address_user_verification_status: union([literal("external"), literal("verified"), literal("unverified")]),
        html_or_text: union([literal("html"), literal("text")]),
        eml_file_virus_detected: boolean(),
    }),
    requiredFlatOverloaded({
        possibly_spoofed: overload(
            boolean(),
            required({ arc_authentication_results: union([string(), nullCodec()]) }),
            (r) => spoofFailCases.some((v) => (r.arc_authentication_results ?? "").toLowerCase().includes(v)),
        ),
    }),
]);
export type TDetailedLegalEmailForTriageCodec = typeof DetailedLegalEmailForTriage;
export type TDetailedLegalEmailForTriage = TTypeOfCodec<TDetailedLegalEmailForTriageCodec>;

export const EmailPersonTitle = union([
    literal("client"),
    literal("conveyancer"),
    literal("other"),
    literal("unknown"),
    literal("sail_staff"),
])
export type TEmailPersonTitle = TTypeOfCodec<typeof EmailPersonTitle>;
export type TEmailPersonTitleNewDefault = TTypeOfNewDefault<typeof EmailPersonTitle>;


export const SimpleLegalEmailPerson = required({
    address: string(),
    title: EmailPersonTitle,
    first_name: string(),
    last_name: string(),
});
export type TSimpleLegalEmailPerson = TTypeOfCodec<typeof SimpleLegalEmailPerson>;
export type TSimpleLegalEmailPersonNewDefault = TTypeOfNewDefault<typeof SimpleLegalEmailPerson>;


export const SimpleLegalEmailBaseForTriage = required({
    id: uuid(),
    sent_at: dateTime(),
    from_address: string(),
    to_addresses: array(string()),
    subject: string(),
    direction: EmailDirection1,
    snippet: string(),
    resolved_at: union([dateTime(), nullCodec()]),
});
export type TSimpleLegalEmailBaseForTriageCodec = typeof SimpleLegalEmailBaseForTriage;
export type TSimpleLegalEmailBaseForTriage = TTypeOfCodec<TSimpleLegalEmailBaseForTriageCodec>;


export const SimpleLegalEmailForTriage = intersection([
    SimpleLegalEmailBaseForTriage,
    partial({
        from: SimpleLegalEmailPerson,
        to: array(SimpleLegalEmailPerson),
    })
]);
export type TSimpleLegalEmailForTriageCodec = typeof SimpleLegalEmailForTriage;
export type TSimpleLegalEmailForTriage = TTypeOfCodec<TSimpleLegalEmailForTriageCodec>;


export const SimpleLegalEmailWithPersonsForTriage = intersection([
    SimpleLegalEmailBaseForTriage,
    required({
        from: SimpleLegalEmailPerson,
        to: array(SimpleLegalEmailPerson),
    })
])
export type TSimpleLegalEmailWithPersonsForTriageCodec = typeof SimpleLegalEmailWithPersonsForTriage;
export type TSimpleLegalEmailWithPersonsForTriage = TTypeOfCodec<TSimpleLegalEmailWithPersonsForTriageCodec>;


const decryptLegalEmailSnippet = <T extends TLegalEmailEncrypted1>(encryptedEmail: T, decryptionFunction: (s: string) => string) => {
    var { encrypted_snippet, ...email} = encryptedEmail;
    return {
        ...email,
        snippet: decryptionFunction(encrypted_snippet),
    };
};

export const decryptLegalEmailWithBody = <T extends TLegalEmailEncryptedWithBody>(encryptedEmail: T, decryptionFunction: (s: string) => string) => {
    var { encrypted_snippet, encrypted_body_html_or_text, ...email} = encryptedEmail;
    return {
        ...email,
        snippet: decryptionFunction(encrypted_snippet),
        body_html_or_text: decryptionFunction(encrypted_body_html_or_text),
    };
};

export const decryptLegalEmailsArray = <T extends TLegalEmailEncrypted1>(encryptedEmailArray: Array<T>, decryptionFunction: (s: string) => string) =>
    pipe(
        encryptedEmailArray,
        fptsArray.map((encryptedEmail) => decryptLegalEmailSnippet(encryptedEmail, decryptionFunction))
    );

export const getNameIfExists = (emailAddress: string): string =>
    // if the string starts with anything other than < and then later contains '<'
    // then it has a name before an email address
    // e.g. Bart Simpson<bartfarts@simpsons.com> would return 'Bart Simpson'
    new RegExp(/(^[^<]+)<+/).test(emailAddress)
        ? emailAddress.substring(0, emailAddress.indexOf("<"))
        : emailAddress;

export const getEmailAddressFromFromAddress = (s: string): string =>
    pipe(
        RegExp(/<?(\S+@[^>]+)>?/).exec(s),
        (result) =>
            result ? result[1] : s,
    );

export const getToOrFromShortText = <T extends TLegalEmail1>(email: T): string =>
    email.direction === "inbound" ? getNameIfExists(email.from_address)
        : email.direction === "outbound" ? getToCcOrBccShortText(email.to_addresses)
        : util.requireExhaustive(email.direction);

export const getToOrFromText = <T extends TLegalEmail1>(email: T): string =>
    email.direction === "inbound" ? email.from_address
        : email.direction === "outbound" ? getToCcOrBccText(email.to_addresses)
        : util.requireExhaustive(email.direction);

export const getToCcOrBccText = (addressesArray: Array<string>): string =>
    pipe(
        addressesArray,
        (addresses) => addresses.join(", ")
    );

export const getToCcOrBccShortText = (addressesArray: Array<string>): string =>
    pipe(
        addressesArray,
        fptsArray.map((a) => getNameIfExists(a)),
        (addresses) => addresses.join(", ")
    );