import React from "react";
import { CRMFontSizes } from "../../../models/CRMFontSizes";
import { CRMColors } from "../../../models/CRMColors";
import { FontQuicksand } from "../../BuildingBlocks/FontQuicksand";
import { TextColor } from "../../BuildingBlocks/TextColor";
import { FontSize } from "../../BuildingBlocks/FontSize";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMIcon } from "../../CRMIcon/CRMIcon";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { CursorPointer } from "../../CursorPointer/CursorPointer";
import { WeightRegular } from "../../WeightRegular/WeightRegular";
import { Padding } from "../../BuildingBlocks/Padding";
import { BackgroundColour } from "../../BuildingBlocks/BackgroundColour";
import { useHover } from "../../../hooks/useHover";
import { MouseEventHandler } from "react"; 

type TCheckboxWithLabelProps = {
    onClick: MouseEventHandler;
    isSelected: boolean;
    hasCheckbox?: boolean;
    label: JSX.Element | string;
};

export const CRMDropdownOption = (props: TCheckboxWithLabelProps): JSX.Element => {

    const [ref, isHovered] = useHover<HTMLDivElement>({});

    return (
        <div ref={ref} className="crm-dropdown-option">
            <CursorPointer onClick={props.onClick}>
                <BackgroundColour colour={isHovered ? CRMColors.NEUTRAL_12 : "transparent"}>
                    <Padding type="custom" spacing={`${CRMSpacing.TINY} ${CRMSpacing.MEDIUM}`}>
                        <SpacingRow
                            spacing={CRMSpacing.TINY}
                            alignItems="flex-start"
                            minHeight="24px"
                        >
                            {props.hasCheckbox && 
                                <CRMIcon
                                    iconName={props.isSelected ? "checkbox-checked" : "checkbox-unchecked"}
                                    colour="neutral-ink"
                                />
                            }
                            <Padding type="top" spacing="4px">
                                <FontQuicksand>
                                    <FontSize size={CRMFontSizes.SMALL} lineHeight="1.2">
                                        <WeightRegular>
                                            <TextColor color={CRMColors.NEUTRAL_INK}>
                                                {props.label}
                                            </TextColor>
                                        </WeightRegular>
                                    </FontSize>
                                </FontQuicksand>
                            </Padding>
                        </SpacingRow>
                    </Padding>
                </BackgroundColour>
            </CursorPointer>
        </div>
    );
};
