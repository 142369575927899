import * as t from "io-ts";

const codec =
    t.type({
        sellers_party_id: t.string,
    });

export type T = t.TypeOf<typeof codec>;

export const newDefault = (): T => ({
    sellers_party_id: "",
});
