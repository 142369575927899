import * as t from "io-ts";
import * as CaseDocumentType1 from "./CaseDocumentType1";
import * as CaseDocumentFile4 from "./CaseDocumentFile4";

export const codec = t.type({
    id: t.string,
    cases_enquiry_id: t.string,
    case_id: t.string,
    type: CaseDocumentType1.codec,
    due_date: t.string,
    received_date: t.union([t.string, t.null]),
    additional_name: t.string,
    from_external_source: t.boolean,
    files: t.array(CaseDocumentFile4.codec),
    all_files_checked_for_enquiries: t.boolean,
    valid_until: t.union([t.string, t.null]),
    summary: t.string,
    date: t.string,
});

export type T = t.TypeOf<typeof codec>;

export const newDefault = (): T => ({
    id: "",
    case_id: "",
    cases_enquiry_id: "",
    type: "ta6",
    due_date: "2021-01-01T01:02:03",
    received_date: null,
    additional_name: "",
    from_external_source: false,
    files: [],
    all_files_checked_for_enquiries: false,
    valid_until: null,
    summary: "",
    date: "",
});
