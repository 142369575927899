import React, { CSSProperties } from "react";
import { TJustificationOptions } from "./SpacingColumn";
import { TPercentageSize, TViewHeight } from "../../models/StringLiterals";

export const Flex = (props: React.PropsWithChildren<{
    justifyContent?: TJustificationOptions,
    alignItems?: CSSProperties["alignItems"],
    flexWrap?: CSSProperties["flexWrap"],
    gap?: CSSProperties["gap"],
    direction?: CSSProperties["flexDirection"],
    minHeight?: TViewHeight | TPercentageSize,
}>): JSX.Element => {
    return (
        <div style={{
            display: "flex",
            flexDirection: props.direction,
            minHeight: props.minHeight,
            justifyContent: props.justifyContent,
            alignItems: props.alignItems,
            flexWrap: props.flexWrap,
            gap: props.gap,
        }}>
            {props.children}
        </div>
    );
};
