import React from "react";

export default (props: React.SVGAttributes<SVGElement>) => 
<svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g clipPath="url(#clip0)">
<path d="M25.375 35H2.625C1.17759 35 0 33.8225 0 32.375V4.375C0 2.92759 1.17759 1.75 2.625 1.75H25.375C26.8225 1.75 28 2.92759 28 4.375V7.875C28 8.35822 27.6083 8.75 27.125 8.75C26.6417 8.75 26.25 8.35822 26.25 7.875V4.375C26.25 3.89255 25.8575 3.5 25.375 3.5H2.625C2.14255 3.5 1.75 3.89255 1.75 4.375V32.375C1.75 32.8574 2.14255 33.25 2.625 33.25H25.375C25.8575 33.25 26.25 32.8574 26.25 32.375V25.375C26.25 24.8917 26.6417 24.5 27.125 24.5C27.6083 24.5 28 24.8917 28 25.375V32.375C28 33.8225 26.8225 35 25.375 35Z" fill="none"/>
<path d="M18.3751 8.75H6.12509C5.64187 8.75 5.25009 8.35822 5.25009 7.875C5.25009 7.39178 5.64187 7 6.12509 7H18.3751C18.8584 7 19.2501 7.39178 19.2501 7.875C19.2501 8.35822 18.8584 8.75 18.3751 8.75Z" fill="none"/>
<path d="M21.8751 12.25H6.12509C5.64187 12.25 5.25009 11.8582 5.25009 11.375C5.25009 10.8918 5.64187 10.5 6.12509 10.5H21.8751C22.3584 10.5 22.7501 10.8918 22.7501 11.375C22.7501 11.8582 22.3584 12.25 21.8751 12.25Z" fill="none"/>
<path d="M20.1251 15.75H6.12509C5.64187 15.75 5.25009 15.3582 5.25009 14.875C5.25009 14.3918 5.64187 14 6.12509 14H20.1251C20.6084 14 21.0001 14.3918 21.0001 14.875C21.0001 15.3582 20.6084 15.75 20.1251 15.75Z" fill="none"/>
<path d="M14.8751 19.25H6.12509C5.64187 19.25 5.25009 18.8583 5.25009 18.375C5.25009 17.8917 5.64187 17.5 6.12509 17.5H14.8751C15.3583 17.5 15.7501 17.8917 15.7501 18.375C15.7501 18.8583 15.3583 19.25 14.8751 19.25Z" fill="none"/>
<path d="M14.8747 29.75C14.6399 29.75 14.4114 29.6554 14.2445 29.482C14.0281 29.2574 13.9477 28.9345 14.0334 28.6346L15.7834 22.5096C15.8242 22.3667 15.9009 22.2365 16.006 22.1313L29.131 9.00633C29.4726 8.66464 30.0267 8.66464 30.3684 9.00633L34.7434 13.3813C35.0829 13.7208 35.0854 14.2704 34.749 14.613L21.6241 27.9841C21.5102 28.1001 21.3662 28.1821 21.2083 28.2209L15.0833 29.7248C15.0142 29.7417 14.9442 29.75 14.8747 29.75ZM17.4038 23.2084L16.1302 27.6657L20.5495 26.5806L32.893 14.0057L29.7497 10.8624L17.4038 23.2084Z" fill="none"/>
<path d="M11.3751 29.75H6.12509C5.64187 29.75 5.25009 29.3583 5.25009 28.875C5.25009 28.3917 5.64187 28 6.12509 28H11.3751C11.8583 28 12.2501 28.3917 12.2501 28.875C12.2501 29.3583 11.8583 29.75 11.3751 29.75Z" fill="none"/>
</g>
<defs>
<clipPath id="clip0">
<rect width="35" height="35" fill="none"/>
</clipPath>
</defs>
</svg>
;
