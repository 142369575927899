import { overload } from "../../../shared/src/codecs/types/overload";
import { required } from "../../../shared/src/codecs/types/required";
import { string } from "../../../shared/src/codecs/types/string";
import { uuid } from "../../../shared/src/codecs/types/uuid";

export const CaseOnboardingUrl = overload(
    string(),
    required({
        id: uuid(),
    }),
    ({id}) =>
        `https://portal.${process.env.SAIL_LEGAL_DOMAIN_NAME}/client/cases/${id}`,
);
export type TCaseOnboardingUrlCodec = typeof CaseOnboardingUrl;
