import * as t from "io-ts";
import * as EnquiryPurchasingIntent1 from "./EnquiryPurchasingIntent1";
import * as EnquiryPaymentMethod1 from "./EnquiryPaymentMethod1";
import * as EnquiryRenovationWorkBy1 from "./EnquiryRenovationWorkBy1";

export const codec = t.type({
    id: t.string,
    listing_id: t.string,
    party_id: t.string,
    purchasing_intent: EnquiryPurchasingIntent1.codec,
    payment_method: EnquiryPaymentMethod1.codec,
    renovation_work_by: EnquiryRenovationWorkBy1.codec,
    amount_required_when_reselling: t.union([t.null, t.number]),
    notes: t.string,
    closed: t.boolean,
    conveyancer_organisation_name: t.string,
    conveyancer_name: t.string,
    conveyancer_phone_number: t.union([t.string, t.null]),
    conveyancer_address: t.string,
    conveyancer_email_address: t.union([t.string, t.null]),
    mortgage_broker_organisation_name: t.string,
    mortgage_broker_name: t.string,
    mortgage_broker_email_address: t.union([t.string, t.null]),
    mortgage_broker_phone_number: t.union([t.string, t.null]),
    mortgage_broker_has_vouched: t.boolean,
    funds_cash_verified_upto: t.union([t.number, t.null]),
    funds_dip_verified_upto: t.union([t.number, t.null]),
    id_check_complete: t.boolean,
    final_offer_made: t.boolean,
    funds_ltv_percentage: t.union([t.number, t.null]),
    purchasers_company_name: t.string,
    purchasers_address: t.string,
});

export type T = t.TypeOf<typeof codec>;

export const newDefault = (): T => ({
    id: "",
    listing_id: "",
    party_id: "",
    purchasing_intent: "unknown",
    payment_method: "unknown",
    renovation_work_by: "unknown",
    amount_required_when_reselling: null,
    notes: "",
    closed: false,
    conveyancer_organisation_name: "",
    conveyancer_name: "",
    conveyancer_phone_number: null,
    conveyancer_address: "",
    conveyancer_email_address: null,
    mortgage_broker_organisation_name: "",
    mortgage_broker_name: "",
    mortgage_broker_email_address: null,
    mortgage_broker_phone_number: null,
    mortgage_broker_has_vouched: false,
    funds_cash_verified_upto: null,
    funds_dip_verified_upto: null,
    id_check_complete: false,
    final_offer_made: false,
    funds_ltv_percentage: null,
    purchasers_company_name: "",
    purchasers_address: "",
});
