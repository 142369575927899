import React from "react";

export default (props: React.SVGAttributes<SVGElement>) => <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g clipPath="url(#clip0_7616_37807)">
<path d="M43.7923 31.2569C43.5856 30.9701 43.2536 30.8001 42.9 30.8001H24.2V28.6001H38.5C38.9531 28.6001 39.3595 28.3221 39.5244 27.9001C39.6891 27.4781 39.5782 26.9981 39.2451 26.6909C39.2208 26.6685 36.7762 24.4004 33.7033 20.3473C30.8751 16.6173 26.9191 10.5665 24.1335 2.92332C23.9507 2.42186 23.4343 2.1241 22.9087 2.21678C22.3831 2.30959 22 2.76636 22 3.30007V30.8001H1.1C0.746417 30.8001 0.414355 30.9701 0.207623 31.2569C0.000892144 31.5439 -0.0553454 31.9125 0.0564421 32.2479C1.63941 36.9969 3.34194 40.301 6.93302 42.0965C10.1515 43.7057 14.5322 44.0001 22 44.0001C29.4678 44.0001 33.8485 43.7057 37.0669 42.0965C40.6579 40.301 42.3605 36.9969 43.9434 32.2479C44.0554 31.9125 43.9991 31.5437 43.7923 31.2569ZM24.2 8.8231C26.7616 14.3291 29.7228 18.7439 31.9866 21.7244C33.5071 23.7263 34.867 25.2959 35.8842 26.4001H24.2V8.8231ZM36.0831 40.1287C33.2731 41.5337 28.9656 41.8001 22 41.8001C15.0344 41.8001 10.727 41.5337 7.9169 40.1287C5.62849 38.9845 4.13359 36.9949 2.65162 33.0001H41.3484C39.8664 36.9949 38.3715 38.9845 36.0831 40.1287Z" fill="black"/>
<path d="M18.7 28.5999H5.49996C5.03005 28.5999 4.61198 28.3014 4.45949 27.8569C4.30701 27.4124 4.45372 26.9201 4.82463 26.6316C4.84855 26.613 7.28416 24.7052 10.0789 21.6391C12.631 18.8393 16.0173 14.4696 17.6564 9.55207C17.827 9.04023 18.3438 8.72769 18.8762 8.81411C19.4087 8.90053 19.8 9.36047 19.8 9.89995V27.4999C19.8 28.1075 19.3074 28.5999 18.7 28.5999ZM8.45091 26.3999H17.6V15.0543C15.7878 18.32 13.5235 21.1294 11.6748 23.1541C10.483 24.4595 9.36426 25.5551 8.45091 26.3999Z" fill="black"/>
<path d="M9.8998 37.4C9.61037 37.4 9.32643 37.2824 9.12224 37.0775C8.91737 36.8733 8.7998 36.5894 8.7998 36.3C8.7998 36.0105 8.91737 35.7266 9.12224 35.5223C9.32712 35.3175 9.61037 35.2 9.8998 35.2C10.1892 35.2 10.4725 35.3175 10.6774 35.5223C10.8822 35.7266 10.9998 36.0105 10.9998 36.3C10.9998 36.5894 10.8822 36.8733 10.6774 37.0775C10.4732 37.2824 10.1892 37.4 9.8998 37.4Z" fill="black"/>
<path d="M14.3002 37.4C14.0108 37.4 13.7268 37.2824 13.5226 37.0775C13.3178 36.8733 13.2002 36.5894 13.2002 36.3C13.2002 36.0105 13.3178 35.7266 13.5226 35.5223C13.7268 35.3175 14.0108 35.2 14.3002 35.2C14.5896 35.2 14.8736 35.3175 15.0778 35.5223C15.2826 35.7266 15.4002 36.0105 15.4002 36.3C15.4002 36.5894 15.2826 36.8733 15.0778 37.0775C14.8736 37.2824 14.5896 37.4 14.3002 37.4Z" fill="black"/>
<path d="M18.7001 37.4C18.4107 37.4 18.1267 37.2824 17.9225 37.0775C17.7177 36.8733 17.6001 36.5894 17.6001 36.3C17.6001 36.0105 17.7177 35.7266 17.9225 35.5223C18.1267 35.3175 18.4107 35.2 18.7001 35.2C18.9895 35.2 19.2735 35.3175 19.4777 35.5223C19.6825 35.7266 19.8001 36.0105 19.8001 36.3C19.8001 36.5894 19.6825 36.8733 19.4777 37.0775C19.2735 37.2824 18.9895 37.4 18.7001 37.4Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_7616_37807">
<rect width="44" height="44" fill="white"/>
</clipPath>
</defs>
</svg>
;
