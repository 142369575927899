import { TTypeOfCodec } from "../../../../shared/src/codecs/codec";
import { literal } from "../../../../shared/src/codecs/types/literal";
import { partial } from "../../../../shared/src/codecs/types/partial";
import { required } from "../../../../shared/src/codecs/types/required";
import { string } from "../../../../shared/src/codecs/types/string";

export const Redirect = required({
    redirect_url: string()
})

export const noQueryParamsCodec = required({});

export const CaseRoadblocksBlockRouteParamsCodec = required({
    type: literal("case_roadblocks"),
    meta: partial({
        openRoadblockID: string()
    }),
});
export type TCaseRoadblocksBlockRouteParams = TTypeOfCodec<typeof CaseRoadblocksBlockRouteParamsCodec>;