import React from "react";
import Icon from "../IconComponent/Icon";

interface ICheckboxProps {
    name?: string;
    label?: string;
    isChecked: boolean;
    onChange: (isChecked: boolean) => void;
}

class Checkbox extends React.Component<React.PropsWithChildren<ICheckboxProps>> {
    public render (): JSX.Element {
        return (
            <div className="checkboxComponent">
                <input
                    id={this.props.name}
                    name={this.props.name}
                    type="checkbox"
                    value={this.props.isChecked === true ? "checked" : ""}
                />
                <span
                    className={"checkboxComponent__checked-icon-container" +
                        " checkboxComponent__checked-icon-container" +
                        (this.props.isChecked === true ? "--checked" : "--unchecked")
                    }
                    onClick={() => this.props.onChange(!this.props.isChecked)}
                >
                    <Icon
                        iconName="tick"
                        height={2.2}
                        width={2.2}
                        type="sloth"
                    />
                </span>
                <label
                    htmlFor={this.props.name}
                    onClick={() => this.props.onChange(!this.props.isChecked)}
                >
                        {this.props.label}
                </label>
            </div>
        );
    }
}

export default Checkbox;
