import React from "react";
import { CRMSpacer } from "../CRMSpacer/CRMSpacer";
import { FrontIcon } from "../Front/Simple/FrontIcon/FrontIcon";
import FrontParagraphRegular from "../Front/Simple/FrontParagraphRegular/FrontParagraphRegular";

type TFrontProbateWhatsIncludedProps = {
    obtainingVals: boolean;
    payingInheritanceTax: boolean;
    gopApplication: boolean;
    probateRegistryApplication: boolean;
    postingGop: boolean;
    contactFinInstToCloseAccounts: boolean;
    collectingEstateFunds: boolean;
    payingDebts: boolean;
    payingIncomeCapitalGainsTax: boolean;
    preparingEstateAccounts: boolean;
    distributingFunds: boolean;
    sellingProperties: boolean;
};

const getIconType = (bool: boolean): JSX.Element => 
    bool ? 
    <FrontIcon iconName="tick" colour="highlights-positive-apple-4" />
    :<FrontIcon iconName="cross" colour="highlights-error-firebrick-10" />

export const FrontProbateWhatsIncluded = (props: React.PropsWithChildren<TFrontProbateWhatsIncludedProps>): JSX.Element => {

    return (
        <>
            <FrontParagraphRegular>{getIconType(props.obtainingVals)}&nbsp; Obtaining valuations for all assets and liabilities (debts)</FrontParagraphRegular>
            <CRMSpacer size="tiny"></CRMSpacer>
            <FrontParagraphRegular>{getIconType(props.payingInheritanceTax)}&nbsp; Paying Inheritance Tax and liaising with HMRC, if applicable</FrontParagraphRegular>
            <CRMSpacer size="tiny"></CRMSpacer>
            <FrontParagraphRegular>{getIconType(props.gopApplication)}&nbsp; Completing Grant of Probate application paperwork</FrontParagraphRegular>
            <CRMSpacer size="tiny"></CRMSpacer>
            <FrontParagraphRegular>{getIconType(props.probateRegistryApplication)}&nbsp; Submitting your application to the Probate Registry</FrontParagraphRegular>
            <CRMSpacer size="tiny"></CRMSpacer>
            <FrontParagraphRegular>{getIconType(props.postingGop)}&nbsp; Posting you the grant of probate</FrontParagraphRegular>
            <CRMSpacer size="tiny"></CRMSpacer>
            <FrontParagraphRegular>{getIconType(props.contactFinInstToCloseAccounts)}&nbsp; Speaking to financial institutions to close accounts</FrontParagraphRegular>
            <CRMSpacer size="tiny"></CRMSpacer>
            <FrontParagraphRegular>{getIconType(props.collectingEstateFunds)}&nbsp; Collecting in all Estate Funds</FrontParagraphRegular>
            <CRMSpacer size="tiny"></CRMSpacer>
            <FrontParagraphRegular>{getIconType(props.payingDebts)}&nbsp; Paying any debts</FrontParagraphRegular>
            <CRMSpacer size="tiny"></CRMSpacer>
            <FrontParagraphRegular>{getIconType(props.payingIncomeCapitalGainsTax)}&nbsp; Paying any Income Tax and Capital Gains Tax, if applicable</FrontParagraphRegular>
            <CRMSpacer size="tiny"></CRMSpacer>
            <FrontParagraphRegular>{getIconType(props.preparingEstateAccounts)}&nbsp; Preparing Estate Accounts</FrontParagraphRegular>
            <CRMSpacer size="tiny"></CRMSpacer>
            <FrontParagraphRegular>{getIconType(props.distributingFunds)}&nbsp; Distributing funds to beneficiaries</FrontParagraphRegular>
            <CRMSpacer size="tiny"></CRMSpacer>
            <FrontParagraphRegular>{getIconType(props.sellingProperties)}&nbsp; Selling any properties (Estate agent fees not included)</FrontParagraphRegular>
            <CRMSpacer size="tiny"></CRMSpacer>
        </>
    );
};
