import React from "react";

export default (props: React.SVGAttributes<SVGElement>) => <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g clipPath="url(#clip0_5216:24803)">
<path d="M43.6781 19.0222C43.2485 18.5926 42.552 18.5926 42.1225 19.0222L39.5946 21.5501C39.4815 17.0175 37.6632 12.7729 34.4454 9.55497C31.1213 6.2307 26.7015 4.40002 22.0003 4.40002C15.457 4.40002 9.4912 7.99647 6.43093 13.7859C6.14706 14.323 6.35228 14.9886 6.88936 15.2725C7.42636 15.5564 8.092 15.3511 8.37594 14.8141C11.0542 9.74747 16.2747 6.60002 22.0003 6.60002C30.3369 6.60002 37.1468 13.2587 37.3928 21.5368L34.8782 19.0222C34.4486 18.5926 33.7521 18.5926 33.3226 19.0222C32.893 19.4517 32.8929 20.1482 33.3226 20.5779L37.7226 24.9779C37.9373 25.1926 38.2188 25.3 38.5003 25.3C38.7819 25.3 39.0633 25.1926 39.2781 24.9778L43.6781 20.5778C44.1077 20.1482 44.1077 19.4518 43.6781 19.0222Z" fill="black"/>
<path d="M37.111 28.7274C36.5738 28.4437 35.9083 28.6487 35.6244 29.1858C32.9462 34.2525 27.7256 37.4 22 37.4C13.6634 37.4 6.85341 30.7412 6.60756 22.4631L9.12216 24.9777C9.33701 25.1926 9.61847 25.3 9.9 25.3C10.1815 25.3 10.463 25.1926 10.6778 24.9777C11.1074 24.5482 11.1074 23.8517 10.6778 23.4221L6.27784 19.0221C5.84822 18.5926 5.15178 18.5926 4.72216 19.0221L0.322163 23.4221C-0.107388 23.8517 -0.107388 24.5482 0.322163 24.9777C0.751713 25.4073 1.44822 25.4073 1.87784 24.9777L4.40571 22.4498C4.51873 26.9825 6.33696 31.2271 9.55488 34.445C12.8791 37.7692 17.2989 39.5999 22 39.5999C28.5433 39.5999 34.5091 36.0035 37.5694 30.2141C37.8533 29.6769 37.648 29.0114 37.111 28.7274Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_5216:24803">
<rect width="44" height="44" fill="white"/>
</clipPath>
</defs>
</svg>
;
