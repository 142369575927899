import { TTypeOfCodec } from "../../shared/src/codecs/codec";
import { required } from "../../shared/src/codecs/types/required";
import { uuid } from "../../shared/src/codecs/types/uuid";
 
export const UuidObject = required({
    id: uuid(),
});

export type TUuidObjectCodec = typeof UuidObject;
export type TUuidObject = TTypeOfCodec<typeof UuidObject>;

