import * as t from "io-ts";
import * as PaginationMeta1 from "./PaginationMeta1";
import * as JsonResponseMeta1 from "./JsonResponseMeta1";

export type T<
    D,
    M extends JsonResponseMeta1.T,
> = {
    data: D;
    meta: M;
};

export const createCodec = <
    D extends t.Any,
    M extends t.Any
>(dataCodec: D, metaCodec: M): t.TypeC<{
    data: D;
    meta: M;
}> =>
    t.type({
        data: dataCodec,
        meta: metaCodec,
    });

export const create = <D extends Record<string, unknown> | Array<Record<string, unknown>>>(data: D): T<D, {}> => (
    { data, meta: {} }
);

export const createWithPaginationMeta = <D extends Array<Record<string, unknown>>>(data: D, meta: PaginationMeta1.T): T<D, PaginationMeta1.T> => (
    { data, meta }
);

export const is = (v: Record<string, unknown>): v is T<unknown, {}> => "data" in v && "meta" in v;
