import React from "react";

interface ICRMParagraphProps {
    fontSize?: "small" | "x-small";
}

export class CRMParagraphInline extends React.Component<React.PropsWithChildren<ICRMParagraphProps>> {
    public render (): JSX.Element {
        return (
            <span className={`
                crm-paragraph-inline
                ${this.props.fontSize === "x-small" ? "crm-paragraph--size-x-small" : ""}
            `}>
                {this.props.children}
            </span>
        );
    }
}
