import React from "react";
import { sailHomesBrandConstants } from "../../../../../../domain/constants";
import { FrontIcon } from "./../../Simple/FrontIcon/FrontIcon";
import { WeightMedium } from "../../../WeightMedium/WeightMedium";
import { requireExhaustive } from "../../../../../../shared/src/util";

type TFrontMenu = {
    showLogout: boolean;
    links: TLinks;
    logoUrl: string;
    branding: "sailhomes" | "saillegal" | "sailprobate";
    phoneUrlAndText?: [string, string];
};

type TFrontMenuState = {
    mobileMenuIsOpen: boolean;
};

type TLinks = Array<{
    text: string;
    url: string;
    button?: boolean;
}>;

export class FrontMenu extends React.Component<React.PropsWithChildren<TFrontMenu>, TFrontMenuState> {
    public constructor (props: TFrontMenu) {
        super(props);
        this.state = {
            mobileMenuIsOpen: false,
        };
    }

    private backgroundColor =
        this.props.branding === "sailhomes" ? "#2D3D64"
        : this.props.branding === "saillegal" ? "#0f6e15"
        : this.props.branding === "sailprobate" ? "#650f6e"
        : requireExhaustive(this.props.branding);

    private mobileMenuBackgroundColor =
        this.props.branding === "sailhomes" ? "#0e1e47"
        : this.props.branding === "saillegal" ? "#004204"
        : this.props.branding === "sailprobate" ? "#4a0e51"
        : requireExhaustive(this.props.branding);

    private links = (): TLinks => {
        return this.props.links.concat(this.props.showLogout ? [{
            text: "Logout",
            url: sailHomesBrandConstants("web").logoutUrl,
        }] : []);
    };

    private generateButtons = (): Array<JSX.Element> =>
        this.links().map((link, index) => (
            <a  key={index}
                className={`
                    front-menu__button
                    front-menu__button--inactive
                    ${link.button ? "front-menu__button--button" : ""}
                `}
                href={link.url}
            >
                <WeightMedium>
                    {link.text}
                </WeightMedium>
            </a>
        ));

    public render (): JSX.Element {
        return (
            <div className="front-menu" style={{
                backgroundColor: this.backgroundColor
            }}>
                <div className="front-menu__body">
                    {/* LOGO */}
                    <div className="front-menu__logo-wrap">
                        <a href={env.REACT_APP_HOME_URL} className="front-menu__logo">
                            <img src={this.props.logoUrl} alt="Logo" style={{maxHeight: "75px"}} />
                        </a>
                        {
                            this.props.phoneUrlAndText && <>
                                <a href={this.props.phoneUrlAndText[0]} className="front-menu__logo-text">
                                    <WeightMedium>
                                        Call us on {this.props.phoneUrlAndText[1]}
                                    </WeightMedium>
                                </a>
                            </>
                        }
                    </div>

                    {/* BUTTONS */}
                    <div className="front-menu__buttons">
                        {this.generateButtons()}
                    </div>

                    {/* MOBILE BURGER BUTTON */}
                    { this.props.links.length > 0 || this.props.showLogout && 
                    <div
                        className={`front-menu__mobile-menu-button ${this.state.mobileMenuIsOpen ? "front-menu__mobile-menu-button--open" : ""}`}
                        onClick={() =>
                            this.setState({
                                mobileMenuIsOpen: !this.state.mobileMenuIsOpen,
                            })
                        }
                    >
                        <FrontIcon
                            size="large"
                            iconName="burger-menu"
                            colour={this.state.mobileMenuIsOpen ? "neutral-10" : "neutral-16"}
                        />
                    </div>
                    }
                </div>

                {/* MOBILE MENU */}
                <div className={`front-menu__mobile-menu-container front-menu__mobile-menu-container--${this.state.mobileMenuIsOpen ? "shown" : "hidden"}`}>
                    <div className={`front-menu__mobile-menu front-menu__mobile-menu--${this.state.mobileMenuIsOpen ? "shown" : "hidden"}`}
                        style={{
                            backgroundColor: this.mobileMenuBackgroundColor
                        }}
                    >
                        {this.generateButtons()}
                    </div>
                </div>
            </div>
        );
    }
}
