import { TTypeOfCodec } from "../../shared/src/codecs/codec";
import { literal } from "../../shared/src/codecs/types/literal";
import { union } from "../../shared/src/codecs/types/union";

export const CaseEnquiryStatus = union([
    literal("unsubmitted"),
    literal("pending_approval"),
    literal("approved_unsent"),
    literal("approved_sent"),
    literal("resolved"),
    literal("not_relevant"),
]);
export type TCaseEnquiryStatusCodec = typeof CaseEnquiryStatus;
export type TCaseEnquiryStatus = TTypeOfCodec<TCaseEnquiryStatusCodec>;