import { ListingDocument2, ListingDocument3, TListingDocument2Codec, TListingDocument3Codec } from "../ListingDocument";
import { partial, TPartialCodec } from "../../../shared/src/codecs/types/partial";
import { TTypeOfCodec } from "../../../shared/src/codecs/codec";
import { fileIO, TFileIOCodec } from "../../../shared/src/codecs/types/fileIO";
import { intersection, TIntersectionCodec } from "../../../shared/src/codecs/types/intersection";
import { string, TAnyStringCodec } from "../../../shared/src/codecs/types/string";

export const ListingDocumentFileIO: TFileIOCodec<
    TIntersectionCodec<[
        TListingDocument3Codec,
        TPartialCodec<{
            request_ref: TAnyStringCodec;
        }>
    ]>,
    TListingDocument2Codec
> = fileIO(
    intersection([
        ListingDocument3,
        partial({
            request_ref: string(),
        }),
    ]),
    ListingDocument2,
);

export type TListingDocumentFileIOCodec = typeof ListingDocumentFileIO;

export type TListingDocumentFileIO = TTypeOfCodec<TListingDocumentFileIOCodec>;
