import * as Function from "../Function";
import * as Error from "../Error";
import * as ErrorCode from "../ErrorCode";
import { option } from "fp-ts/lib";

export const integerPositive: Function.TValidationFunction = (value: unknown): option.Option<Error.TValidationError> =>
    typeof value !== "undefined" && (
        typeof value !== "number"
        || (! Number.isInteger(value) || value < 0)
    ) ? option.some([[ErrorCode.validationErrorCodeConstants.INTEGER_POSITIVE_VALIDATION]])
    : option.none;
