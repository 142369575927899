import * as t from "io-ts";
import * as util from "../../shared/src/util";

/**
 * @deprecated Try not to use this type and instead used the shared one
 * IMPORTANT If you CHANGE this codec make sure to update the new codec @see {@link app/domain/codecs/HomesAndLegalPropertyType.ts}
 */
export const codec = t.union([
    t.literal("detached_house"),
    t.literal("semi_detached_house"),
    t.literal("terraced_house"),
    t.literal("flat"),
    t.literal("bungalow"),
    t.literal("semi_detached_bungalow"),
    t.literal("detached_bungalow"),
    t.literal("maisonette"),
    t.literal("commercial"),
    t.literal("mixed_use"),
    t.literal("studio"),
    t.literal("garage"),
    t.literal("land"),
    t.literal("other"),
    t.literal("unknown"),
]);

type T = t.TypeOf<typeof codec>;

export const values = util.typeValuesFromCodec(codec.types as Array<t.LiteralC<T>>);

export const toDisplayName = (v: T): string => {
    switch (v) {
        case "bungalow":
            return "Bungalow";
        case "detached_house":
            return "Detached house";
        case "flat":
            return "Flat";
        case "semi_detached_house":
            return "Semi-detached house";
        case "terraced_house":
            return "Terraced house";
        case "semi_detached_bungalow":
            return "Semi-detached bungalow";
        case "detached_bungalow":
            return "Detached bungalow";
        case "maisonette":
            return "Maisonette";
        case "commercial":
            return "Commercial";
        case "mixed_use":
            return "Mixed use";
        case "studio":
            return "Studio";
        case "garage":
            return "Garage";
        case "land":
            return "Land";
        case "other":
            return "Other";
        case "unknown":
            return "Unknown";
        default:
            return util.requireExhaustive(v);
    }
};
