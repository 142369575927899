import { TTypeOfCodec, TTypeOfNewDefault } from "../../../shared/src/codecs/codec";
import { required, TRequiredCodec } from "../../../shared/src/codecs/types/required";
import { CaseLedgerForm, TCaseLedgerFormCodec } from "../form/CaseLedgerForm";
import { CaseLedgerMarkLedgerAsFinalForm, CaseLedgerRollbackForm, TCaseLedgerMarkLedgerAsFinalFormCodec, TCaseLedgerRollbackFormCodec } from "../form/CaseLedgerMarkLedgerAsFinalForm";
import { CaseNewLedgerEntryForm, TCaseNewLedgerEntryFormCodec } from "../form/CaseNewLedgerEntryForm";

export const LegalCaseLedgerPagePartial: TRequiredCodec<{
    case_ledger: TCaseLedgerFormCodec;
    create_case_ledger_item: TCaseNewLedgerEntryFormCodec;
    mark_case_ledger_as_final: TCaseLedgerMarkLedgerAsFinalFormCodec;
    rollback_ledger: TCaseLedgerRollbackFormCodec;
}> = required({
    case_ledger: CaseLedgerForm,
    create_case_ledger_item: CaseNewLedgerEntryForm,
    mark_case_ledger_as_final: CaseLedgerMarkLedgerAsFinalForm,
    rollback_ledger: CaseLedgerRollbackForm,
});

export type TLegalCaseLedgerPagePartialCodec = typeof LegalCaseLedgerPagePartial;
export type TLegalCaseLedgerPagePartial = TTypeOfCodec<TLegalCaseLedgerPagePartialCodec>;
export type TLegalCaseLedgerPagePartialNewDefault = TTypeOfNewDefault<TLegalCaseLedgerPagePartialCodec>;