import * as TForm from "../../models/TForm";
import { TSetState } from "../../state/TSetState";
import { TState } from "./lensBaseTypes";
import { reduceDataToStateUpdate } from "./reduceDataToStateUpdate";
import { set } from "./set";

interface IResetFormAfterCompletion<S> {
    // 5 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1],
        K3 extends keyof S[K1][K2],
        K4 extends keyof S[K1][K2][K3],
        K5 extends keyof S[K1][K2][K3][K4]
    >
    (
        path: [K1, K2, K3, K4, K5],
    ): (data: unknown) => void;
    // 4 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1],
        K3 extends keyof S[K1][K2],
        K4 extends keyof S[K1][K2][K3]
    >
    (
        path: [K1, K2, K3, K4],
    ): (data: unknown) => void;
    // 3 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1],
        K3 extends keyof S[K1][K2]
    >
    (
        path: [K1, K2, K3],
    ): (data: unknown) => void;
    // 2 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1]
    >
    (
        path: [K1, K2],
    ): (data: unknown) => void;
    // 1 level lens path guard
    <K1 extends keyof S>
    (
        path: [K1],
    ): (data: unknown) => void;
}

export const resetFormAfterCompletion = <T>(setState: TSetState): IResetFormAfterCompletion<TState> =>
    // There is no way to create paramater overloads here so we set to any
    (lensPath: any) => // eslint-disable-line
        (data: unknown) => {
            reduceDataToStateUpdate<unknown>(setState)(
                set<unknown>()(
                    lensPath,
                    /* eslint-disable */
                    // The type is always expected to be never for the form, since we have abstraced the lens scoping,
                    // which is untrue
                    // @ts-ignore
                    TForm.resetCompleted<T>()
                    /* eslint-enable */
                ),
            )(data);
        };
