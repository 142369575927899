import * as t from "io-ts";
import * as CaseEnquiryDocument2 from "./CaseEnquiryDocument2";
import * as User19 from "./User19";
import * as CaseEnquiryTimeline1 from "./CaseEnquiryTimeline1";
import * as CaseEnquiryStatusType from "./CaseEnquiryStatusType";

export const codec = t.type({
    id: t.string,
    message: t.string,
    status: CaseEnquiryStatusType.codec,
    documents: t.array(CaseEnquiryDocument2.codec),
    reference_id: t.union([t.string, t.null]),
    created_by_user: User19.codec,
    timeline: t.array(CaseEnquiryTimeline1.codec),
    created_at: t.string,
});

export type T = t.TypeOf<typeof codec>;

export const newDefault = (): T => ({
    id: "",
    message: "",
    status: "unsubmitted",
    documents: [],
    reference_id: null,
    created_by_user: User19.newDefault(),
    timeline: [],
    created_at: "",
});
