import { Lens } from "monocle-ts";
import { TState, TLensPack } from "./lensBaseTypes";

interface ISet<D, S> {
    // 5 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1],
        K3 extends keyof S[K1][K2],
        K4 extends keyof S[K1][K2][K3],
        K5 extends keyof S[K1][K2][K3][K4]
    >
    (
        path: [K1, K2, K3, K4, K5],
        callback: (value: S[K1][K2][K3][K4][K5], data: D, state: S) => S[K1][K2][K3][K4][K5]
    ): (pack: TLensPack<D>) => TLensPack<D>;
    // 4 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1],
        K3 extends keyof S[K1][K2],
        K4 extends keyof S[K1][K2][K3]
    >
    (
        path: [K1, K2, K3, K4],
        callback: (value: S[K1][K2][K3][K4], data: D, state: S) => S[K1][K2][K3][K4]
    ): (pack: TLensPack<D>) => TLensPack<D>;
    // 3 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1],
        K3 extends keyof S[K1][K2]
    >
    (
        path: [K1, K2, K3],
        callback: (value: S[K1][K2][K3], data: D, state: S) => S[K1][K2][K3]
    ): (pack: TLensPack<D>) => TLensPack<D>;
    // 2 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1]
    >
    (
        path: [K1, K2],
        callback: (value: S[K1][K2], data: D, state: S) => S[K1][K2]
    ): (pack: TLensPack<D>) => TLensPack<D>;
    // 1 level lens path guard
    <K1 extends keyof S>
    (
        path: [K1],
        callback: (value: S[K1], data: D, state: S) => S[K1]
    ): (pack: TLensPack<D>) => TLensPack<D>;
}

export const set = <T>(): ISet<T, TState> =>
    // There is no way to create paramater overloads here so we set to any
    (lens: any, callback: any) => // eslint-disable-line
        (pack: TLensPack<T>) => {
            const focusedLens = Lens.fromPath<TState>()(lens);
             /* eslint-disable */
            // The type is always expected to be never for the form, since we have abstraced the lens scoping,
            // which is untrue
            // @ts-ignore
            const stateModificationFunc = focusedLens.modify((value) => callback(value, pack.data, pack.state));
            /* eslint-enable */
            pack.state = stateModificationFunc(pack.state);
            return pack;
        };
