import * as t from "io-ts";
import { nonEmptyString } from "../../shared/src/validation/basic/nonEmptyString";
import { required } from "../../shared/src/validation/basic/required";
import { string } from "../../shared/src/validation/basic/string";
import { inOrder } from "../../shared/src/validation/compose/inOrder";
import { object } from "../../shared/src/validation/create/object";

export const codec = t.type({
    message: t.string,
});

export type T = t.TypeOf<typeof codec>;

export const validator = object({
    "message": inOrder(
        required,
        string,
        nonEmptyString,
    ),
});

export const newDefault = (): T => ({
    message: "",
});
