import * as t from "io-ts";
import * as User10 from "./User10";
import * as Party4 from "./Party4";
import * as Party5 from "./Party5";
import * as Party6 from "./Party6";

export const codec = t.type({
    ...{
        id: t.string,
        created_at: t.string,
    },
    ...Party4.codec.props,
    ...Party5.codec.props,
    ...Party6.codec.props,
    users: t.array(User10.codec),
});

export type T = t.TypeOf<typeof codec>;

export const newDefault = (): T => ({
    ...{
        id: "",
        created_at: "",
        users: [],
    },
    ...Party4.newDefault(),
    ...Party5.newDefault(),
    ...Party6.newDefault(),
});
