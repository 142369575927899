import { identity } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import * as TSetState from "../../state/TSetState";
import { TLensPack, TLensPipeFunction } from "./lensBaseTypes";

export const reduceDataToStateUpdate = <T>(setState: TSetState.TSetState) =>
    (...pipeFunctions: Array<TLensPipeFunction<T>> ) =>
        (data: T) =>
            setState(({...updateState}) => {
                const pack: TLensPack<T> =  pipe(
                    identity.flatten({ data, state: updateState, meta: {} }),
                    (p) => recursiveCreatePipe(p, pipeFunctions),
                );
                return pack.state;
            });

// fp-ts's  pipe can only take up to 9 arguments, with reduceDataToStateUpdate it's easy to exceed this so to prevent that this function
// will nest pipes, producing
//  pipe(
//     a,
//     b,
//     c,
//     ...
//      pipe(
//         d,
//         e,
//         f,
//     )
// )
const recursiveCreatePipe = <T>(pack: TLensPack<T>, pipeFunctions: Array<TLensPipeFunction<T>>): TLensPack<T> => {
    const p =  pipe(
        pack,
        ...(pipeFunctions.slice(0, 7) as [TLensPipeFunction<T>]),
    );
    return pipeFunctions.slice(7).length ? recursiveCreatePipe(p, pipeFunctions.slice(7)) : p;
};
