import * as t from "io-ts";
import { nonEmptyString } from "../../shared/src/validation/basic/nonEmptyString";
import { required } from "../../shared/src/validation/basic/required";
import { all } from "../../shared/src/validation/compose/all";
import { array } from "../../shared/src/validation/create/array";
import { object } from "../../shared/src/validation/create/object";
import { TValidationFunction } from "../../shared/src/validation/Function";
import * as CaseEnquiryDocument1 from "./CaseEnquiryDocument1";

export const codec = t.type({
    reference_id: t.string,
    documents: t.array(CaseEnquiryDocument1.codec),
    message: t.string,
});

export type T = t.TypeOf<typeof codec>;

export const validator: TValidationFunction = object({
    reference_id: all(
        required,
        nonEmptyString,
    ),
    documents: all(
        required,
        array(
            CaseEnquiryDocument1.validator,
        )
    ),
    message: all(
        required,
        nonEmptyString,
    ),
});

export const newDefault = (): T => ({
    reference_id: "",
    documents: [],
    message: "",
});
