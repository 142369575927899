import { union } from "../../shared/src/codecs/types/union";
import { literal } from "../../shared/src/codecs/types/literal";
import { TTypeOfCodec } from "../../shared/src/codecs/codec";

export const ListingReasonForSale1 = union([
    literal("unknown"),
    literal("owner_initiated"),
    literal("repossession"),
    literal("probate"),
]);
export type TListingReasonForSale1 = TTypeOfCodec<typeof ListingReasonForSale1>;
