import { TTypeOfCodec } from "../../../shared/src/codecs/codec";
import { form, TFormCodec } from "../../../shared/src/codecs/types/form";
import { EmptyObject, TEmptyObjectCodec } from "../EmptyObject";
import { ListingId } from "../ListingId";
import { TListingIdCodec } from "../ListingId";

export const ListingSellerUserCreateForm: TFormCodec<TListingIdCodec, TEmptyObjectCodec> = form(
    ListingId,
    EmptyObject
);

export type TListingSellerUserCreateFormCodec = typeof ListingSellerUserCreateForm;

export type TListingSellerUserCreateForm = TTypeOfCodec<TListingSellerUserCreateFormCodec>;