import * as t from "io-ts";
import * as EmailDirection1 from "./EmailDirection1";
import * as EmailStatus1 from "./EmailStatus1";

export const codec = t.type({
    id: t.string,
    cases_enquiry_id: t.string,
    gmail_message_ids: t.array(t.union([t.string, t.null])),
    direction: EmailDirection1.codec,
    case_status: EmailStatus1.codec,
    from_address: t.string,
    to_addresses: t.array(t.string),
    subject: t.string,
    snippet: t.string,
    date: t.string,
    created_at: t.string,
    cc_addresses: t.array(t.string),
    bcc_addresses: t.array(t.string),
    body_html_or_text: t.string,
});

type T = t.TypeOf<typeof codec>;

export const newDefault = (): T => ({
    id: "",
    cases_enquiry_id: "",
    gmail_message_ids: [],
    direction: "inbound",
    case_status: "unassigned",
    from_address: "",
    to_addresses: [],
    subject: "",
    snippet: "",
    date: "",
    created_at: "",
    cc_addresses: [],
    bcc_addresses: [],
    body_html_or_text: "",
});
