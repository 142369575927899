import React from "react";

export default (props: React.SVGAttributes<SVGElement>) => <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g clipPath="url(#clip0_8591_43105)">
<path d="M4.58398 15C5.27434 15 5.83398 14.4404 5.83398 13.75C5.83398 13.0596 5.27434 12.5 4.58398 12.5C3.89363 12.5 3.33398 13.0596 3.33398 13.75C3.33398 14.4404 3.89363 15 4.58398 15Z" fill="black"/>
<path d="M10.416 15C11.1064 15 11.666 14.4404 11.666 13.75C11.666 13.0596 11.1064 12.5 10.416 12.5C9.72566 12.5 9.16602 13.0596 9.16602 13.75C9.16602 14.4404 9.72566 15 10.416 15Z" fill="black"/>
<path d="M13.334 0.833342C11.3423 0.833343 9.59232 1.83334 8.54232 3.35001C8.20065 3.34168 7.85898 3.33334 7.50065 3.33334C3.81732 3.33334 0.833985 3.75001 0.833985 6.66668L0.833986 15C0.833986 15.7333 1.15899 16.3917 1.66732 16.85L1.66732 18.3333C1.66732 18.7917 2.04232 19.1667 2.50065 19.1667L3.33399 19.1667C3.79232 19.1667 4.16732 18.7917 4.16732 18.3333L4.16732 17.5L10.834 17.5L10.834 18.3333C10.834 18.7917 11.209 19.1667 11.6673 19.1667L12.5007 19.1667C12.959 19.1667 13.334 18.7917 13.334 18.3333L13.334 16.85C13.8423 16.3917 14.1673 15.7333 14.1673 15L14.1673 12.4333C16.9923 12.025 19.1673 9.60834 19.1673 6.66667C19.1673 3.44168 16.559 0.833342 13.334 0.833342ZM7.50065 5.00001L7.74232 5.00001C7.66732 5.26668 7.60898 5.55001 7.56732 5.82501L2.78398 5.82501C3.24232 5.38334 4.42565 5.00001 7.50065 5.00001ZM2.50065 7.49168L7.56732 7.49168C7.70065 8.41668 8.04232 9.26668 8.55065 10L2.50065 10L2.50065 7.49168ZM12.5007 15C12.5007 15.3083 12.3257 15.5167 12.2173 15.6083L11.9757 15.8333L3.02565 15.8333L2.78399 15.6083C2.67565 15.5167 2.50065 15.3083 2.50065 15L2.50065 11.6667L10.3423 11.6667C10.9923 12.0583 11.7173 12.325 12.5007 12.4333L12.5007 15ZM13.334 10.8333C11.034 10.8333 9.16732 8.96668 9.16732 6.66668C9.16732 4.36668 11.034 2.50001 13.334 2.50001C15.634 2.50001 17.5006 4.36668 17.5006 6.66668C17.5006 8.96667 15.634 10.8333 13.334 10.8333ZM13.7506 3.33334L12.5007 3.33334L12.5007 7.50001L15.5173 9.30001L16.1423 8.27501L13.7506 6.87501L13.7506 3.33334Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_8591_43105">
<rect width="20" height="20" fill="white" transform="translate(20 20) rotate(180)"/>
</clipPath>
</defs>
</svg>
;
