import { array, option, either } from "fp-ts/lib";
import { TCodec } from "../codec";
import { errorConstants, errorValues, TError, TErrorCode } from "../errors";
import { pipe } from "fp-ts/lib/function";

export type TAnyErrorCodeCodec = TCodec<"ErrorCodeCodec", undefined, TErrorCode, TErrorCode>;

const decode = (input: unknown): either.Either<TError, TErrorCode> =>  pipe(
    errorValues,
    array.findFirst((v) => v === input),
    option.fold(
        () => either.left([[errorConstants.ERROR_TUPLE_VALIDATION, ""]]),
        () => either.right(input as TErrorCode)
    )
);

export const errorCode = (): TAnyErrorCodeCodec => ({
    type: "ErrorCodeCodec",
    payload: undefined,
    decode,
    decodeNewDefault: decode,
    newDefault: () => "RequiredValidation",
});
