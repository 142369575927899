import { literal } from "../../shared/src/codecs/types/literal";
import { nonEmptyString } from "../../shared/src/codecs/types/nonEmptyString";
import { overload } from "../../shared/src/codecs/types/overload";
import { required } from "../../shared/src/codecs/types/required";
import { union } from "../../shared/src/codecs/types/union";
import * as util from "../../shared/src/util";

export const OfferStatus1 = union([
    literal("pending"),
    literal("accepted"),
    literal("rejected"),
    literal("gazumped"),
    literal("withdrawn"),
]);

export const OfferStatus2 = overload(
    nonEmptyString(),
    required({
        status: OfferStatus1,
    }),
    ({status}) => {
        switch (status) {
            case "pending":
                return "Pending";
            case "accepted":
                return "Accepted";
            case "rejected":
                return "Rejected";
            case "gazumped":
                return "Gazumped";
            case "withdrawn":
                return "Withdrawn";
            default:
                return util.requireExhaustive(status);
        }
    }
);