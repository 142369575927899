import { TAnyCodec, TCodec, TTypeOfCodec } from "../codec";
import { either } from "fp-ts/lib";
import { decodeArray } from "./array";
import { pipe } from "fp-ts/lib/function";
import { TError } from "../errors";

export type TArrayCodec<A extends TAnyCodec> = TCodec<"ArrayCodec", { codec: A }, Array<TTypeOfCodec<A>>, Array<TTypeOfCodec<A>>>;

export const wrapArray = <
    A extends TAnyCodec,
    D extends Array<TTypeOfCodec<A>>
>(payload: A): TCodec<"ArrayCodec", { codec: A }, D, D> => ({
    type: "ArrayCodec",
    payload: { codec: payload },
    decode: decode("decode", payload),
    decodeNewDefault: decode("decodeNewDefault", payload),
    newDefault: (): D => [] as unknown as D,
});

const decode = <
    A extends TAnyCodec,
    D extends Array<TTypeOfCodec<A>>
>(method: "decode" | "decodeNewDefault", payload: A) =>
    (input): either.Either<TError, D> =>
        Array.isArray(input)
            ? decodeArray(input, payload, method)
            : pipe(
                payload[method](input),
                either.map((res) => [res] as D),
            );
