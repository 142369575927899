import { TAnyCodec, TCodec, TTypeOfCodec, TTypeOfNewDefault, TUnionCodecExtension } from "../codec";
import { either, array } from "fp-ts/lib";
import { TError, errorConstants, errorTupleEq } from "../errors";
import { NonEmptyArray } from "fp-ts/lib/NonEmptyArray";
import { pipe } from "fp-ts/lib/function";
import { TAnyLiteralCodec } from "./literal";
import { TAnyFormCodec } from "./form";

export type TAnyUnionCodec = TCodec<
    "UnionCodec",
    NonEmptyArray<TAnyCodec>,
    unknown,
    unknown
>;

export type TUnionOfLiteralsCodec = TCodec<
    "UnionCodec",
    NonEmptyArray<TAnyLiteralCodec>,
    unknown,
    unknown
>;

export type TAnyUnionOfFormsCodec = TCodec<
    "UnionCodec",
    NonEmptyArray<TAnyFormCodec>,
    unknown,
    unknown
>;

export type TUnionCodec<P extends NonEmptyArray<TAnyCodec>> = TCodec<
"UnionCodec",
    P,
    TTypeOfCodec<P[number]>,
    TTypeOfNewDefault<P[number]>
>;

const decode = <D>(payload: NonEmptyArray<TAnyCodec>, decodeFunction: "decode" | "decodeNewDefault") => (input: unknown): either.Either<TError, D> =>
    pipe(
        payload,
        array.reduceRight(
            either.left([[errorConstants.UNION_VALIDATION, ""]]) as either.Either<TError, D>,
            (codec, acc) => pipe(
                acc,
                either.fold(
                    (accErrors) => pipe(
                        codec[decodeFunction](input),
                        either.fold(
                            (decodeErrors) => pipe(
                                [...accErrors, ...decodeErrors],
                                array.uniq(errorTupleEq),
                                either.left,
                            ),
                            (res) => either.right(res)
                        )
                    ),
                    (res) => either.right(res)
                )
            ) as either.Either<TError, D>
        )
    );

export const union = <
    P extends NonEmptyArray<TAnyCodec>,
    D extends TTypeOfCodec<P[number]>,
    N extends TTypeOfNewDefault<P[number]>,
>(payload: P): TUnionCodecExtension<P, D, N> => ({
    type: "UnionCodec",
    payload,
    decode: decode(payload, "decode"),
    decodeNewDefault: decode(payload, "decodeNewDefault"),
    newDefault: () =>
        payload.filter((c) => c.type === "NullCodec").length > 0 ? null as N
        : payload[0].newDefault() as N,
    values: pipe(
        payload,
        array.map((c) => c.newDefault()),
    ) as NonEmptyArray<D>,
});

export const isUnionOfLiterals = (codec: TAnyUnionCodec): codec is TUnionOfLiteralsCodec => {
    const nonLiteralCodecs = codec.payload.filter((c) => c.type !== "LiteralCodec");
    return nonLiteralCodecs.length ? false : true;
}

export const isUnionOfNullAndDecimal = (codec: TAnyUnionCodec): boolean => {
    if (codec.payload.length !== 2) {
        return false;
    }

    const nullCodecs = codec.payload.filter((c) => c.type === "NullCodec");

    if (nullCodecs.length !== 1) {
        return false;
    }

    const notNullCodec = codec.payload.filter((c) => c.type !== "NullCodec")[0];

    if (notNullCodec.type === "DecimalCodec" || notNullCodec.type === "PositiveDecimalCodec") {
        return true;
    }

    return false;
}

export const isUnionOfNullAndInteger = (codec: TAnyUnionCodec): boolean => {
    if (codec.payload.length !== 2) {
        return false;
    }

    const nullCodecs = codec.payload.filter((c) => c.type === "NullCodec");

    if (nullCodecs.length !== 1) {
        return false;
    }

    const notNullCodec = codec.payload.filter((c) => c.type !== "NullCodec")[0];

    if (notNullCodec.type === "IntegerCodec" || notNullCodec.type === "PositiveIntegerCodec") {
        return true;
    }

    return false;
}

export const isUnionOfNullAndCurrencyInteger = (codec: TAnyUnionCodec): boolean => {
    if (codec.payload.length !== 2) {
        return false;
    }

    const nullCodecs = codec.payload.filter((c) => c.type === "NullCodec");

    if (nullCodecs.length !== 1) {
        return false;
    }

    const notNullCodec = codec.payload.filter((c) => c.type !== "NullCodec")[0];

    if (notNullCodec.type === "CurrencyIntegerCodec") {
        return true;
    }

    return false;
}

export const isUnionOfNullAndBoolean = (codec: TAnyUnionCodec): boolean => {
    if (codec.payload.length !== 2) {
        return false;
    }

    const nullCodecs = codec.payload.filter((c) => c.type === "NullCodec");

    if (nullCodecs.length !== 1) {
        return false;
    }

    const notNullCodec = codec.payload.filter((c) => c.type !== "NullCodec")[0];

    if (notNullCodec.type === "BooleanCodec") {
        return true;
    }

    return false;
}

export const isUnionOfNullAndDeferDateTime = (codec: TAnyUnionCodec): boolean => {
    if (codec.payload.length !== 2) {
        return false;
    }

    const nullCodecs = codec.payload.filter((c) => c.type === "NullCodec");

    if (nullCodecs.length !== 1) {
        return false;
    }

    const notNullCodec = codec.payload.filter((c) => c.type !== "NullCodec")[0];

    if (notNullCodec.type === "DeferDateTimeCodec") {
        return true;
    }

    return false;
}

export const isUnionOfNullAndDate = (codec: TAnyUnionCodec): boolean => {
    if (codec.payload.length !== 2) {
        return false;
    }

    const nullCodecs = codec.payload.filter((c) => c.type === "NullCodec");

    if (nullCodecs.length !== 1) {
        return false;
    }

    const notNullCodec = codec.payload.filter((c) => c.type !== "NullCodec")[0];

    if (notNullCodec.type === "DateCodec") {
        return true;
    }

    return false;
}

export const isUnionOfNullAndDateTime = (codec: TAnyUnionCodec): boolean => {
    if (codec.payload.length !== 2) {
        return false;
    }

    const nullCodecs = codec.payload.filter((c) => c.type === "NullCodec");

    if (nullCodecs.length !== 1) {
        return false;
    }

    const notNullCodec = codec.payload.filter((c) => c.type !== "NullCodec")[0];

    if (notNullCodec.type === "DateTimeCodec") {
        return true;
    }

    return false;
}

export const isUnionOfNullAndOneOtherType = (codec: TAnyUnionCodec): boolean => {
    if (codec.payload.length !== 2) {
        return false;
    }

    const nullCodecs = codec.payload.filter((c) => c.type === "NullCodec");

    if (nullCodecs.length !== 1) {
        return false;
    }

    return true;
}
