import React from "react";

type TDropdownOptionBoxProps = {

};

export const CRMDropdownOptionBox = (props: React.PropsWithChildren<TDropdownOptionBoxProps>): JSX.Element => {
    return (
        <div className={`crm-dropdown-option-box`}>
            {props.children}
        </div>
    );
};