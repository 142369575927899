import { required } from "../../shared/src/codecs/types/required";
import { uuid } from "../../shared/src/codecs/types/uuid";
import { string } from "../../shared/src/codecs/types/string";
import { dateTime } from "../../shared/src/codecs/types/dateTime";
import { overload } from "../../shared/src/codecs/types/overload";
import * as S3BucketUrl from "../../domain/models/S3BucketUrl";
import { union } from "../../shared/src/codecs/types/union";
import { nullCodec } from "../../shared/src/codecs/types/nullCodec";
import { ListingPhotoFileExtensionTypes, TListingPhotoFileExtensionTypes } from "./ListingPhotoFileExtensionTypes";
import { ListingPhotoFileMimeTypes } from "./ListingPhotoFileMimeTypes";
import { requiredFlatOverloaded } from "../../shared/src/codecs/types/requiredFlatOverloaded";
import { intersection } from "../../shared/src/codecs/types/intersection";
import { DeleteListingPhotoFormIO } from "./formIO/DeleteListingPhotoFormIO";

const getListingImageUrl = (id: string, file_extension: TListingPhotoFileExtensionTypes) => `${S3BucketUrl.fromAccountAndBucketNames(process.env.ACCOUNT_NAME ?? "", S3BucketUrl.bucketNames.LISTINGS_IMAGES_BUCKET)}/${id}/${id}.${file_extension}`;

const ListingImage1 = required({
    id: uuid(),
    file_extension: ListingPhotoFileExtensionTypes,
});


export const ListingImageUrl = overload(
    string(),
    required({
        image: union([ListingImage1, nullCodec()])
    }),
    (t) => t.image
        ? getListingImageUrl(t.image.id, t.image.file_extension)
        : "",
);

const ListingImageUrl2 = overload(
    string(),
    required({
        id: uuid(),
        file_extension: ListingPhotoFileExtensionTypes,
    }),
    (t) => t
        ? getListingImageUrl(t.id, t.file_extension)
        : "",
);

export const ListingImage2 = intersection([
    requiredFlatOverloaded({
        url: ListingImageUrl2,
    }),
    required({
        id: uuid(),
        listing_id: uuid(),
        name: string(),
        file_extension: ListingPhotoFileExtensionTypes,
        mime_type: ListingPhotoFileMimeTypes,
        created_at: dateTime(),
    }),
    requiredFlatOverloaded({
        delete_form: DeleteListingPhotoFormIO,
    })
]);
export type TListingImage2Codec = typeof ListingImage2;

export const ListingImage3 = required({
    listing_id: uuid(),
    name: string(),
    file_extension: ListingPhotoFileExtensionTypes,
    mime_type: ListingPhotoFileMimeTypes,
});
export type ListingImage3Codec = typeof ListingImage3;
