import * as t from "io-ts";
import * as regexes from "../../shared/src/regexes";
import { option } from "fp-ts";
import { TValidationError } from "../../shared/src/validation/Error";
import { all } from "../../shared/src/validation/compose/all";
import { inOrder } from "../../shared/src/validation/compose/inOrder";
import { object } from "../../shared/src/validation/create/object";
import { objectCallback } from "../../shared/src/validation/create/objectCallback";
import { regex } from "../../shared/src/validation/create/regex";
import { string } from "../../shared/src/validation/basic/string";
import { required } from "../../shared/src/validation/basic/required";
import { nonEmptyString } from "../../shared/src/validation/basic/nonEmptyString";
import { validationErrorCodeConstants } from "../../shared/src/validation/ErrorCode";

const codec = t.type({
    postcode: t.string,
    building_name: t.string,
    building_number: t.string,
});

export type T = t.TypeOf<typeof codec>;

export const validator = (userInput: unknown): option.Option<TValidationError> => all(
    object({
        building_name: string, // This is required if building_number is not provided, check is performed lower down
        building_number: string, // This is required if building_name is not provided, check is performed lower down
        postcode: inOrder(
            required,
            nonEmptyString,
            regex(regexes.constants.POSTCODE_REGEX)),
    }),
    // Check that either building_name or building_number must always exist
    objectCallback((val: Record<string, unknown>): option.Option<TValidationError> => {
        if (
            (val.building_name === undefined || val.building_name === "") &&
            (val.building_number === undefined || val.building_number === "")
        ) {
            return option.some([
                [validationErrorCodeConstants.REQUIRED_CONDITIONALLY_VALIDATION, "building_name"],
                [validationErrorCodeConstants.REQUIRED_CONDITIONALLY_VALIDATION, "building_number"],
            ]);
        }
        return option.none;
    }),
)(userInput);
export const newDefault = (): T => ({
    postcode: "",
    building_name: "",
    building_number: "",
});
