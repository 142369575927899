import { nullCodec } from "../../../shared/src/codecs/types/nullCodec";
import { overload } from "../../../shared/src/codecs/types/overload";
import { required } from "../../../shared/src/codecs/types/required";
import { string } from "../../../shared/src/codecs/types/string";
import { union } from "../../../shared/src/codecs/types/union";
import { CasesDocumentFileDownloadToken } from "../CasesDocument";

export const UsersIdCheckUploadedProofOfAddressUrl = overload(
    string(),
    required({
        uploaded_proof_of_address: union([nullCodec(), CasesDocumentFileDownloadToken]),
    }),
    ({uploaded_proof_of_address}) =>
        uploaded_proof_of_address
            ? `https://api.${process.env.DOMAIN_NAME}/web/case-document-file-download?fileToken=${encodeURIComponent(uploaded_proof_of_address.download_token)}`
            : "",
);
