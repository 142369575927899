import { TTypeOfCodec } from "../../shared/src/codecs/codec";
import { required } from "../../shared/src/codecs/types/required";
import { positiveInteger } from "../../shared/src/codecs/types/positiveInteger";
import { form, TFormCodec } from "../../shared/src/codecs/types/form";
import { intersection } from "../../shared/src/codecs/types/intersection";

export const Pagination1 = required({
    limit: positiveInteger(),
    offset: positiveInteger(),
});
export type TPagination1Codec = typeof Pagination1;
export type TPagination1 = TTypeOfCodec<typeof Pagination1>;

export const Pagination3 = required({
    available_count: positiveInteger(),
    returned_count: positiveInteger(),
});
export type TPagination3Codec = typeof Pagination3;

export const Pagination2 = intersection([
    Pagination1,
    Pagination3,
]);
export type TPagination2 = TTypeOfCodec<typeof Pagination2>;
