import * as t from "io-ts";
import * as JsonResponse1 from "./JsonResponse1";
import * as Party9 from "./Party9";

export const codec = JsonResponse1.createCodec(
    Party9.codec,
    t.type({}),
);

export type T = t.TypeOf<typeof codec>;

export const newDefault = (): T => ({
    data: Party9.newDefault(),
    meta: {},
});
