import * as t from "io-ts";
import * as JsonResponse1 from "./JsonResponse1";
import * as Listing3 from "./Listing3";
import * as PaginationMeta1 from "./PaginationMeta1";

export const codec = JsonResponse1.createCodec(
    t.array(Listing3.codec),
    PaginationMeta1.codec,
);

export type T = t.TypeOf<typeof codec>;

export const newDefault = (): T => ({
    data: [],
    meta: PaginationMeta1.newDefault(),
});
