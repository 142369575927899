import { TCodec } from "../codec";
import { either } from "fp-ts/lib";
import { TError, errorConstants } from "../errors";

export type TAnyLiteralCodec = TCodec<
    "LiteralCodec",
    string,
    string,
    string
>;

export type TLiteralCodec<T extends string> = TCodec<
    "LiteralCodec",
    T,
    T,
    T
>;

const decode = <L extends string>(literalString: L) => (input: unknown): either.Either<TError, L> =>
    typeof input === "string" && input === literalString
        ? either.right(literalString)
        : either.left([[errorConstants.LITERAL_VALIDATION, ""]]);

export const literal = <L extends string>(literalString: L): TCodec<"LiteralCodec", L, L, L> => ({
    type: "LiteralCodec",
    payload: literalString,
    decode: decode(literalString),
    decodeNewDefault: decode(literalString),
    newDefault: (): L => literalString,
});
