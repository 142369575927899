import * as JsonInnerErrorCode1 from "../models/JsonInnerErrorCode1";
import * as ErrorCode from "../../shared/src/validation/ErrorCode";
import * as RequestSource1 from "../models/RequestSource1";
import { array, nonEmptyArray, record, option } from "fp-ts/lib";
import * as t from "io-ts";
import { TErrorTuple } from "../../shared/src/codecs/errors";
import { pipe } from "fp-ts/lib/function";
import { TValidationError } from "../../shared/src/validation/Error";
import { TValidationErrorTuple } from "../../shared/src/validation/ErrorTuple";

export const codec = t.type({
    error_code: t.union([JsonInnerErrorCode1.codec, ErrorCode.codec]),
    error_target: t.type({
        source: RequestSource1.codec,
        keys: t.array(t.string),
    }),
});

export type T = t.TypeOf<typeof codec>;

export const create = (errorCode: T["error_code"], source: RequestSource1.T, keys: Array<string> = []): T => (
    {
        error_code: errorCode,
        error_target: {
            source,
            keys,
        },
    }
);

export const arrayFromValidationErrors = (source: RequestSource1.T, validationErrors: TValidationError): Array<T> =>
     pipe(
        nonEmptyArray.groupBy(
            ([code]: TValidationErrorTuple) => code
        )(validationErrors),
        (v) => record.toArray<T["error_code"], nonEmptyArray.NonEmptyArray<TValidationErrorTuple>>(v),
        array.map(
            ([code, errorTuples]) =>
                create(
                    code,
                    source,
                    array.filterMap<TValidationErrorTuple, string>(
                        ([, path]: TValidationErrorTuple) => path ? option.some(path) : option.none
                    )(errorTuples)
                )
        )
    );

export type TJsonInnerErrorCodeTranslationRecord = Partial<Record<T["error_code"], string>>;

export const toTErrorTuple = (innerError: T): TErrorTuple => [
    innerError.error_code as ErrorCode.T,
    `edited.${innerError.error_target.keys[0]}`
];
