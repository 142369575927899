import * as TFormStatus from "../models/TFormStatus";
import { defaultTFormV2, defaultTListForm } from "../models/TForm";
import * as UserPhoneNumber2 from "../../../domain/models/UserPhoneNumber2";
import * as UserPhoneNumber4 from "../../../domain/models/UserPhoneNumber4";
import * as User4 from "../../../domain/models/User4";
import * as User8 from "../../../domain/models/User8";
import * as Party2 from "../../../domain/models/Party2";
import * as Enquiry2 from "../../../domain/models/Enquiry2";
import * as Party7 from "../../../domain/models/Party7";
import * as Listing3 from "../../../domain/models/Listing3";
import * as ListingEnquiriesViewing2 from "../../../domain/models/ListingEnquiriesViewing2";
import * as ListingEnquiriesViewingForm1 from "../../../domain/models/ListingEnquiriesViewingForm1";
import * as ListingEnquiriesOffer2 from "../../../domain/models/ListingEnquiriesOffer2";
import * as ListingEnquiriesOffer1 from "../../../domain/models/ListingEnquiriesOffer1";
import * as ListingForm4 from "../../../domain/models/ListingForm4";
import * as EnquirySendFiles1 from "../../../domain/models/EnquirySendFiles1";
import * as ListingForm3 from "../../../domain/models/ListingForm3";
import * as Party9 from "../../../domain/models/Party9";
import {
    TUser4ReadOnlyForm,
    TUserPhoneNumber5AndC4FormList,
    TUser15AndC8FormList,
    TListing3ReadOnlyForm,
    TListingEnquiriesOffer2ReadOnlyFormList,
    TParty2AndC7Form,
    TEnquiryC2ReadOnlyFormList,
    TParty2ReadOnlyFormList,
    TListingEnquiriesViewing2ReadOnlyForm,
    TListingEnquiriesViewing2ReadOnlyFormList,
    TListingEnquiriesViewing2AndListingEnquiriesViewingForm1FormList,
    TListingEnquiriesOffer2AndC1Form,
    TListing3AndListingForm3Form,
    TListing3AndListingForm4FormList,
    TEnquirySendFiles1Form,
    TUserPhoneNumber2ReadOnlyForm,
    TParty2FormList,
} from "./../models/TFormModels";

export class C {
    enquiriesRelatedToSearchedPartyFormList: TEnquiryC2ReadOnlyFormList = defaultTListForm({}, {});
    createPhoneNumberForm: TUserPhoneNumber2ReadOnlyForm = defaultTFormV2(UserPhoneNumber2.newDefault(), UserPhoneNumber2.newDefault(), {}, TFormStatus.constants.REQUIRES_SUBMISSION);
    peoplePhoneNumbersList: TUserPhoneNumber5AndC4FormList = defaultTListForm(UserPhoneNumber4.newDefault(), {});
    associatePersonForm: TUser4ReadOnlyForm = defaultTFormV2(User4.newDefault(), {}, {}, TFormStatus.constants.REQUIRES_SUBMISSION);
    createPersonForm: TUser4ReadOnlyForm = defaultTFormV2(User4.newDefault(), {}, {}, TFormStatus.constants.REQUIRES_SUBMISSION);
    peopleFormList: TUser15AndC8FormList = defaultTListForm(User8.newDefault(), {});
    matchingPartiesFormList: TParty2FormList = defaultTListForm(Party2.newDefault(), {});
    enquiryForm: TEnquiryC2ReadOnlyFormList["forms"][number] = defaultTFormV2(Enquiry2.newDefault(), {}, {});
    partyForm: TParty2AndC7Form = defaultTFormV2(Party9.newDefault(), Party7.newDefault(), {});
    listingForm: TListing3ReadOnlyForm = defaultTFormV2(Listing3.newDefault(), {}, {});
    highestOffersForms: TListingEnquiriesOffer2ReadOnlyFormList = defaultTListForm({}, {});
    enquiriesRelatedToOffersForms: TEnquiryC2ReadOnlyFormList = defaultTListForm({}, {});
    partiesRelatedToOffersForms: TParty2ReadOnlyFormList = defaultTListForm({}, {});
    createViewingForm: TListingEnquiriesViewing2ReadOnlyForm = defaultTFormV2(ListingEnquiriesViewing2.newDefault(), {}, {}, TFormStatus.constants.REQUIRES_SUBMISSION);
    otherViewingsForListing: TListingEnquiriesViewing2ReadOnlyFormList = defaultTListForm({}, {});
    viewingForms: TListingEnquiriesViewing2AndListingEnquiriesViewingForm1FormList = defaultTListForm(ListingEnquiriesViewingForm1.newDefault(), {});
    createOfferFormVisible: boolean = false;
    createOfferForm: TListingEnquiriesOffer2AndC1Form = {
        ...defaultTFormV2(ListingEnquiriesOffer2.newDefault(), ListingEnquiriesOffer1.newDefault(), {}),
        status: TFormStatus.constants.UNTOUCHED,
    };
    createListingForm: TListing3AndListingForm3Form = defaultTFormV2(Listing3.newDefault(), ListingForm3.newDefault(), {}, TFormStatus.constants.REQUIRES_SUBMISSION);
    partiesListings: TListing3AndListingForm4FormList = defaultTListForm(ListingForm4.newDefault(), {
        collapsibleSectionIsOpen: false,
    });
    sendFilesForm: TEnquirySendFiles1Form = {
        ...defaultTFormV2({}, EnquirySendFiles1.newDefault(), {}),
        status: TFormStatus.constants.UNTOUCHED,
    };
}
