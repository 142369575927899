import { TTypeOfCodec } from "../../../shared/src/codecs/codec";
import { array, TArrayCodec } from "../../../shared/src/codecs/types/array";
import { form, TFormCodec } from "../../../shared/src/codecs/types/form";
import { required, TRequiredCodec } from "../../../shared/src/codecs/types/required";
import { EmptyObject, TEmptyObjectCodec } from "../EmptyObject";
import { MarketingCampaign1, MarketingCampaignSpend1, TMarketingCampaign1Codec, TMarketingCampaignSpend1Codec } from "../MarketingCampaign";
import { Pagination1, Pagination3, TPagination1Codec, TPagination3Codec } from "../Pagination";

// CAMPAIGN SPEND FORM
export const MarketingCampaignSpendForm: TFormCodec<
    TMarketingCampaignSpend1Codec,
    TEmptyObjectCodec
> = form(
    MarketingCampaignSpend1,
    EmptyObject
);
export type TMarketingCampaignSpendFormCodec = typeof MarketingCampaignSpendForm;
export type TMarketingCampaignSpendForm = TTypeOfCodec<TMarketingCampaignSpendFormCodec>;

// CAMPAIGN FORM
export const MarketingCampaignForm: TFormCodec<
    TMarketingCampaign1Codec,
    TRequiredCodec<{
        spend_forms: TArrayCodec<TMarketingCampaignSpendFormCodec>,
        spend_forms_to_be_deleted: TArrayCodec<TMarketingCampaignSpendFormCodec>,
    }>
> = form(
    MarketingCampaign1,
    required({
        spend_forms: array(MarketingCampaignSpendForm),
        spend_forms_to_be_deleted: array(MarketingCampaignSpendForm),
    })
);
export type TMarketingCampaignFormCodec = typeof MarketingCampaignForm;
export type TMarketingCampaignForm = TTypeOfCodec<TMarketingCampaignFormCodec>;

// CREATE CAMPAIGN FORM
export const MarketingCampaignCreateForm: TFormCodec<TEmptyObjectCodec, TEmptyObjectCodec> = form(EmptyObject, EmptyObject);
export type TMarketingCampaignCreateFormCodec = typeof MarketingCampaignCreateForm;
export type TMarketingCampaignCreateForm = TTypeOfCodec<TMarketingCampaignCreateFormCodec>;

// CAMPAIGN FORM CHILDREN
const MarketingCampaignsFormChildren: TRequiredCodec<{
    marketing_campaigns: TArrayCodec<TMarketingCampaignFormCodec>,
    counts: TPagination3Codec,
    create_marketing_campaign_form: TMarketingCampaignCreateFormCodec,
}> = required({
    marketing_campaigns: array(MarketingCampaignForm),
    counts: Pagination3,
    create_marketing_campaign_form: MarketingCampaignCreateForm,
});
export type TMarketingCampaignsFormChildrenCodec = typeof MarketingCampaignsFormChildren;
export type TMarketingCampaignsFormChildren = TTypeOfCodec<TMarketingCampaignsFormChildrenCodec>;

// CAMPAIGN FORM
export const MarketingCampaignsForm: TFormCodec<
    TPagination1Codec,
    TMarketingCampaignsFormChildrenCodec
> = form(
    Pagination1,
    MarketingCampaignsFormChildren,
);
export type TMarketingCampaignsFormCodec = typeof MarketingCampaignsForm;
export type TMarketingCampaignsForm = TTypeOfCodec<TMarketingCampaignsFormCodec>;
