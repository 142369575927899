import * as t from "io-ts";
import * as util from "../../shared/src/util";
import { TGetAddressIOResponseCounty } from "../models/GetAddressIOResponse";

export const codec = t.union([
    t.literal("england"),
    t.literal("scotland"),
    t.literal("wales"),
    t.literal("northern_ireland"),
]);

export const values = util.typeValuesFromCodec(codec.types as Array<t.LiteralC<T>>);

type T = t.TypeOf<typeof codec>;

export const fromGetAddressIOApiCountryValueToCountryValue = (value: TGetAddressIOResponseCounty): T => {
    switch (value) {
        case "England":
            return "england";
        case "Scotland":
            return "scotland";
        case "Wales":
            return "wales";
        case "Northern Ireland":
            return "northern_ireland";
        default:
            return util.requireExhaustive(value);
    }
};
