import { TCodec } from "../codec";
import { either } from "fp-ts/lib";
import { TError, errorConstants } from "../errors";

export type TAnyUndefinedCodec = TCodec<
    "UndefinedCodec",
    undefined,
    undefined,
    undefined
>;

const decode = (input: unknown): either.Either<TError, undefined> =>
    typeof input === "undefined"
        ? either.right(undefined)
        : either.left([[errorConstants.UNDEFINED_VALIDATION, ""]]);

export const undefinedCodec = (): TAnyUndefinedCodec => ({
    type: "UndefinedCodec",
    payload: undefined,
    decode,
    decodeNewDefault: decode,
    newDefault: () => undefined,
});
