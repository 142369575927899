import React from "react";

export default (props: React.SVGAttributes<SVGElement>) => <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g clipPath="url(#clip0)">
<path d="M14.1188 13.8983C13.764 13.1693 12 9.3945 12 6.37501C12 3.687 9.81299 1.5 7.12498 1.5C4.43696 1.5 2.25 3.68703 2.25 6.37501C2.25 9.39602 0.484488 13.1723 0.131238 13.8983C0.0442617 14.0753 0 14.2658 0 14.4638C0 15.1733 0.576738 15.75 1.28626 15.75H12.9638C13.6725 15.75 14.25 15.1733 14.25 14.4638C14.25 14.2658 14.2058 14.0753 14.1188 13.8983ZM12.9638 15H1.28626C0.99 15 0.750023 14.7593 0.750023 14.4638C0.750023 14.3813 0.768023 14.3025 0.804762 14.2275C1.32001 13.1708 3.00002 9.48078 3.00002 6.37504C2.99999 4.10026 4.851 2.25002 7.12501 2.25002C9.39902 2.25002 11.25 4.10026 11.25 6.37501C11.25 9.48074 12.93 13.1707 13.4453 14.2275V14.2282C13.482 14.3025 13.5 14.3812 13.5 14.4638C13.5 14.7593 13.26 15 12.9638 15Z" fill="black"/>
<path d="M7.12494 3C5.26345 3 3.74994 4.51425 3.74994 6.375C3.74994 9.0615 2.56869 12.132 1.86444 13.7235C1.78045 13.9125 1.86595 14.1345 2.05569 14.2178C2.10445 14.2395 2.1562 14.25 2.20718 14.25C2.35118 14.25 2.48843 14.1667 2.54992 14.0265C3.27818 12.381 4.49993 9.19952 4.49993 6.375C4.49993 4.92751 5.67742 3.74999 7.12494 3.74999C7.33194 3.74999 7.49995 3.58198 7.49995 3.37498C7.49995 3.16798 7.33194 3 7.12494 3Z" fill="black"/>
<path d="M9.37501 15C9.16801 15 9 15.168 9 15.375C9 16.4085 8.15924 17.25 7.12501 17.25C6.09075 17.25 5.25002 16.4085 5.25002 15.375C5.25002 15.168 5.08201 15 4.87501 15C4.66801 15 4.5 15.168 4.5 15.375C4.5 16.8225 5.67749 18 7.12501 18C8.57254 18 9.75002 16.8225 9.75002 15.375C9.74999 15.168 9.58201 15 9.37501 15Z" fill="black"/>
<path d="M7.12499 0C6.09073 0 5.25 0.8415 5.25 1.87499C5.24996 2.08199 5.41798 2.25 5.62498 2.25C5.83198 2.25 5.99999 2.08199 5.99999 1.87499C5.99999 1.25473 6.50473 0.749988 7.12499 0.749988C7.74525 0.749988 8.24999 1.25473 8.24999 1.87499C8.24999 2.08199 8.418 2.25 8.625 2.25C8.832 2.25 8.99998 2.08199 8.99998 1.87499C8.99998 0.8415 8.15921 0 7.12499 0Z" fill="black"/>
<path d="M17.625 2.25H16.8255L17.937 0.58275C18.0135 0.468 18.021 0.320238 17.955 0.198C17.8905 0.0765 17.7638 0 17.625 0H16.125C15.918 0 15.75 0.168012 15.75 0.375012C15.75 0.582012 15.918 0.750023 16.125 0.750023H16.9238L15.813 2.41727C15.7365 2.53202 15.729 2.67979 15.795 2.80202C15.8595 2.92352 15.9862 3.00002 16.125 3.00002H17.625C17.832 3.00002 18 2.83201 18 2.62501C18 2.41801 17.832 2.25 17.625 2.25Z" fill="black"/>
<path d="M15.375 5.24998H13.155L15.6675 2.10898C15.7582 1.99648 15.7755 1.84197 15.7125 1.71224C15.6502 1.58248 15.519 1.5 15.375 1.5H12.375C12.168 1.5 12 1.66801 12 1.87501C12 2.08201 12.168 2.24999 12.375 2.24999H14.595L12.0825 5.39099C11.9918 5.50349 11.9745 5.65801 12.0375 5.78773C12.0998 5.91749 12.231 5.99997 12.375 5.99997H15.375C15.582 5.99997 15.75 5.83196 15.75 5.62496C15.75 5.41796 15.582 5.24998 15.375 5.24998Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0">
<rect width="18" height="18" fill="white"/>
</clipPath>
</defs>
</svg>
;
