import React from "react";

export const BulletPoint = (props: React.PropsWithChildren<{
    bulletColor: string;
    size: "tiny" | "normal";
}>): JSX.Element =>
    <span 
        className={`
            crm-universal-bullet-points__bullet
            crm-universal-bullet-points__bullet--${props.size}
        `}
        style={{
            backgroundColor: props.bulletColor,
        }}
    />;
