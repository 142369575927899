import React from "react";

export default (props: React.SVGAttributes<SVGElement>) => <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g clipPath="url(#clip0_6841_34225)">
<path d="M16.5165 18.7162C16.2349 18.7162 15.9535 18.6088 15.7387 18.394L11.0717 13.7272C10.6421 13.2975 10.6421 12.6011 11.0717 12.1716C11.5013 11.742 12.1978 11.7419 12.6273 12.1716L17.2943 16.8384C17.7239 17.2681 17.7239 17.9645 17.2943 18.394C17.0794 18.6088 16.798 18.7162 16.5165 18.7162Z" fill="black"/>
<path d="M29.9505 34.3508C29.669 34.3508 29.3875 34.2434 29.1728 34.0285L24.5058 29.3617C24.0762 28.9322 24.0762 28.2357 24.5058 27.8061C24.9354 27.3766 25.6319 27.3764 26.0614 27.8061L30.7284 32.4729C31.158 32.9025 31.158 33.599 30.7284 34.0285C30.5136 34.2434 30.2321 34.3508 29.9505 34.3508Z" fill="black"/>
<path d="M11.8495 34.3506C11.568 34.3506 11.2865 34.2432 11.0717 34.0284C10.6421 33.5988 10.6421 32.9023 11.0717 32.4727L15.7386 27.8059C16.1682 27.3762 16.8647 27.3762 17.2942 27.8059C17.7238 28.2355 17.7238 28.9319 17.2942 29.3615L12.6273 34.0284C12.4126 34.2432 12.131 34.3506 11.8495 34.3506Z" fill="black"/>
<path d="M25.2836 18.7163C25.002 18.7163 24.7206 18.6089 24.5058 18.394C24.0762 17.9644 24.0762 17.268 24.5058 16.8384L29.1728 12.1715C29.6024 11.742 30.2989 11.742 30.7284 12.1715C31.158 12.6011 31.158 13.2976 30.7284 13.7271L26.0614 18.394C25.8466 18.6088 25.565 18.7163 25.2836 18.7163Z" fill="black"/>
<path d="M20.8998 17.6C20.2923 17.6 19.7998 17.1074 19.7998 16.5V3.29995C19.7998 2.69248 20.2923 2.19995 20.8998 2.19995C21.5073 2.19995 21.9998 2.69248 21.9998 3.29995V16.5C21.9998 17.1074 21.5073 17.6 20.8998 17.6Z" fill="black"/>
<path d="M14.3 24.2H1.1C0.492525 24.2 0 23.7075 0 23.1C0 22.4925 0.492525 22 1.1 22H14.3C14.9075 22 15.4 22.4925 15.4 23.1C15.4 23.7075 14.9075 24.2 14.3 24.2Z" fill="black"/>
<path d="M20.8998 44.0001C20.2923 44.0001 19.7998 43.5076 19.7998 42.9001V29.7001C19.7998 29.0926 20.2923 28.6001 20.8998 28.6001C21.5073 28.6001 21.9998 29.0926 21.9998 29.7001V42.9001C21.9998 43.5076 21.5073 44.0001 20.8998 44.0001Z" fill="black"/>
<path d="M40.6999 24.2H27.4999C26.8924 24.2 26.3999 23.7075 26.3999 23.1C26.3999 22.4925 26.8924 22 27.4999 22H40.6999C41.3074 22 41.7999 22.4925 41.7999 23.1C41.7999 23.7075 41.3074 24.2 40.6999 24.2Z" fill="black"/>
<path d="M20.9001 26.4C19.0805 26.4 17.6001 24.9197 17.6001 23.1C17.6001 21.2804 19.0805 19.8 20.9001 19.8C22.7198 19.8 24.2001 21.2804 24.2001 23.1C24.2001 24.9197 22.7198 26.4 20.9001 26.4ZM20.9001 22C20.2936 22 19.8001 22.4935 19.8001 23.1C19.8001 23.7066 20.2936 24.2 20.9001 24.2C21.5066 24.2 22.0001 23.7066 22.0001 23.1C22.0001 22.4935 21.5066 22 20.9001 22Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_6841_34225">
<rect width="44" height="44" fill="white"/>
</clipPath>
</defs>
</svg>
;
