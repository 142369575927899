import { array } from "../../shared/src/codecs/types/array";
import { intersection } from "../../shared/src/codecs/types/intersection";
import { literal } from "../../shared/src/codecs/types/literal";
import { required } from "../../shared/src/codecs/types/required";
import { string } from "../../shared/src/codecs/types/string";
import { union } from "../../shared/src/codecs/types/union";
import { uuid } from "../../shared/src/codecs/types/uuid";

export const UserVideoVerificationCall1 = required({
    video_verification_call_id: uuid(),
    user_id: string(),
});
export type TUserVideoVerificationCall1Codec = typeof UserVideoVerificationCall1;

export const UserVideoVerificationCall3 = required({
    outcome: union([
        literal("unknown"),
        literal("pass"),
        literal("fail"),
        literal("did_not_show"),
    ]),
});

export const UserVideoVerificationCall2 = intersection([
    required({
        id: uuid(),
    }),
    UserVideoVerificationCall3,
]);
export type TUserVideoVerificationCall2Codec = typeof UserVideoVerificationCall2;

export const UserVideoVerificationCall4 = required({
    user_ids: array(uuid()),
});

export const UserVideoVerificationCallPassed = required({
    outcome: literal("pass"),
});
