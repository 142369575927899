import { TTypeOfCodec } from "../../../shared/src/codecs/codec";
import { form, TFormCodec } from "../../../shared/src/codecs/types/form";
import { required, TRequiredCodec } from "../../../shared/src/codecs/types/required";
import { TAnyUuidCodec, uuid } from "../../../shared/src/codecs/types/uuid";
import { TransferRecordType, TTransferRecordTypeCodec } from "../CaseTransferSetup";
import { EmptyObject, TEmptyObjectCodec } from "../EmptyObject";

export const NewTransferSetupRecordForCaseForm: TFormCodec<
    TRequiredCodec<{
        case_id: TAnyUuidCodec,
        record_type: TTransferRecordTypeCodec,
    }>
, TEmptyObjectCodec> = 
    form(
        required({
            case_id: uuid(),
            record_type: TransferRecordType,
        }),
        EmptyObject,
    );

export type TNewTransferSetupRecordForCaseFormCodec = typeof NewTransferSetupRecordForCaseForm;
export type TNewTransferSetupRecordForCaseForm = TTypeOfCodec<TNewTransferSetupRecordForCaseFormCodec>;