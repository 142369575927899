import React from "react";

export default (props: React.SVGAttributes<SVGElement>) => 
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#clip0_2832_18255)">
            <path 
                d="M15 4V11H5.17L4.58 11.59L4 12.17V4H15ZM16 2H3C2.45 2 2 2.45 2 3V17L6 13H16C16.55 13 17 12.55 17 12V3C17 2.45 16.55 2 16 2ZM21 6H19V15H6V17C6 17.55 6.45 18 7 18H18L22 22V7C22 6.45 21.55 6 21 6Z" 
                fill="black"
            />
        </g>
        <defs>
            <clipPath id="clip0_2832_18255">
                <rect width="24" height="24" fill="white"/>
            </clipPath>
        </defs>
    </svg>
;
