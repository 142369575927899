import React from "react";

export default (props: React.SVGAttributes<SVGElement>) => (
    <svg 
        width="50" 
        height="50" 
        viewBox="0 0 50 50" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_9393_43848)">
            <path 
                d="M1.25105 50C1.22895 50 1.20684 49.9995 1.18449 49.9983C0.504882 49.9627 -0.0212905 49.3899 0.000662644 48.7098C0.295429 39.5715 2.74067 31.3607 7.26833 24.3054C10.9863 18.5119 16.0938 13.5028 22.4491 9.41727C28.5134 5.51883 34.5252 3.24703 38.5008 2.02766C43.0673 0.626953 46.9388 0 48.7501 0C49.4405 0 50.0001 0.559687 50.0001 1.25C50.0001 4.26773 47.1156 7.15219 45.8839 8.38391C43.8826 10.3853 40.1964 11.6652 36.6316 12.9029C35.5134 13.2912 34.3828 13.6837 33.3289 14.0914C36.8548 13.7845 40.3434 13.7491 41.7462 13.7491C41.9651 13.7491 42.0841 13.7502 42.0841 13.7502C42.5481 13.7542 42.9717 14.0149 43.1844 14.4273C43.397 14.8396 43.3638 15.3359 43.098 15.7162C40.4217 19.5452 37.1579 22.528 33.1202 24.8353C31.2273 25.9169 29.132 26.4206 26.526 26.4206C24.9851 26.4206 23.4216 26.2465 21.9095 26.0781C20.4279 25.9131 18.8958 25.7425 17.4182 25.7425C14.6969 25.7425 12.623 26.3162 10.6934 27.6025C3.78231 32.2099 2.50879 48.6749 2.49676 48.8406C2.44903 49.4971 1.90176 50 1.25105 50ZM47.1809 2.61719C42.8616 3.14547 33.097 5.54422 23.801 11.5202C19.029 14.5879 13.7554 19.04 9.64552 25.3034C11.8895 23.8988 14.3714 23.2425 17.4181 23.2425C19.0344 23.2425 20.6367 23.4209 22.1862 23.5935C23.6969 23.7617 25.1239 23.9207 26.526 23.9207C28.6791 23.9207 30.3803 23.5216 31.8798 22.6647C34.9059 20.9355 37.3832 18.8739 39.5349 16.2759C36.4129 16.3456 31.067 16.5934 27.8217 17.4579C27.3194 17.5917 26.7866 17.4005 26.4837 16.9779C26.181 16.5552 26.1715 15.9892 26.4599 15.5566C27.994 13.2555 31.7912 11.937 35.8115 10.5412C39.1099 9.39594 42.5204 8.2118 44.1161 6.61609C44.8364 5.89594 46.485 4.24727 47.1809 2.61719Z"
                fill="#674D77"
            />
        </g>
        <defs>
            <clipPath id="clip0_9393_43848">
                <rect 
                    width="50"
                    height="50"
                    fill="white"
                />
            </clipPath>
        </defs>
    </svg>
);