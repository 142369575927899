import { readonlyArray } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import * as t from "io-ts";
import { TTypeOfCodec } from "../../shared/src/codecs/codec";
import { literal } from "../../shared/src/codecs/types/literal";
import { union } from "../../shared/src/codecs/types/union";
import * as util from "../../shared/src/util";

export const CaseStatusTypes = [
    "property_report",
    "quote",
    "instructed",
    "ready_for_exchange",
    "exchanged",
    "completed",
    "closed_created_in_error",
    "closed_unsuccessfully",
    "closed_successfully",
    "closed_awaiting_opening_new_case"
] as const;

export type TCaseStatusType = typeof CaseStatusTypes[number];

export const codec = t.union(pipe(
    CaseStatusTypes,
    readonlyArray.map<TCaseStatusType, t.LiteralC<TCaseStatusType>>((v) => t.literal(v)),
) as [t.LiteralC<TCaseStatusType>, t.LiteralC<TCaseStatusType>, ...t.LiteralC<TCaseStatusType>[]]);

export const values = util.typeValuesFromCodec(codec.types as Array<t.LiteralC<T>>);

type T = t.TypeOf<typeof codec>;

const CaseStatusTypeDisplayString = union([
    literal("Property Report"),
    literal("Quote"),
    literal("Instructed"),
    literal("Ready for Exchange"),
    literal("Exchanged"),
    literal("Completed"),
    literal("Closed Created in Error"),
    literal("Closed Unsuccessfully"),
    literal("Closed Successfully"),
    literal("Closed Awaiting Opening New Case")
]);
type TCaseStatusTypeDisplayString = TTypeOfCodec<typeof CaseStatusTypeDisplayString>;

export const toDisplayString = (p: TCaseStatusType): TCaseStatusTypeDisplayString =>
    p === "property_report" ? "Property Report"
    : p === "quote" ? "Quote"
    : p === "instructed" ? "Instructed"
    : p === "ready_for_exchange" ? "Ready for Exchange"
    : p === "exchanged" ? "Exchanged"
    : p === "completed" ? "Completed"
    : p === "closed_created_in_error" ? "Closed Created in Error"
    : p === "closed_unsuccessfully" ? "Closed Unsuccessfully"
    : p === "closed_successfully" ? "Closed Successfully"
    : p === "closed_awaiting_opening_new_case" ? "Closed Awaiting Opening New Case"
    : util.requireExhaustive(p);