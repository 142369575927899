import { option } from "fp-ts/lib";
import { inherit, TAnyCodec, TValidatorFunction, TAnyInheritedCodec, TInheritedCodec } from "../codec";

export type TAnyCustomCodec = TAnyInheritedCodec<"CustomCodec", { codec: TAnyCodec }>;

export const custom = <P extends TAnyCodec>(
    payload: P,
    validator: TValidatorFunction<P>,
): TInheritedCodec<"CustomCodec", {codec: P}> => inherit(
    "CustomCodec",
    {codec: payload},
    validator,
    () => option.none,
);
