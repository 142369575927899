import { required } from "../../shared/src/codecs/types/required";
import { intersection } from "../../shared/src/codecs/types/intersection";
import { Address1, Address2, Address3 } from "./Address";
import { Tenure1 } from "./Tenure";
import { uuid } from "../../shared/src/codecs/types/uuid";
import { string } from "../../shared/src/codecs/types/string";
import { CasePropertyTitleClass } from "./CasePropertyTitleClass";
import { CasePropertyTitleGuarantee2 } from "./CasePropertyTitleGuarantee";
import { requiredFlatOverloaded } from "../../shared/src/codecs/types/requiredFlatOverloaded";
import { TTypeOfCodec } from "../../shared/src/codecs/codec";
import { date } from "../../shared/src/codecs/types/date";
import { nullCodec } from "../../shared/src/codecs/types/nullCodec";
import { union } from "../../shared/src/codecs/types/union";
import { boolean } from "../../shared/src/codecs/types/boolean";
import { literal } from "../../shared/src/codecs/types/literal";
import { dateTime } from "../../shared/src/codecs/types/dateTime";
import { DateTime } from "luxon";

export const CasePropertyTransferOf = union([
    literal("unknown"),
    literal("whole"),
    literal("part"),
]);

export const CasePropertyTenureAndTitleNumber = required({
    tenure: Tenure1,
    title_number: string(),
});
export type TCasePropertyTenureAndTitleNumberCodec = typeof CasePropertyTenureAndTitleNumber;
export type TCasePropertyTenureAndTitleNumber = TTypeOfCodec<TCasePropertyTenureAndTitleNumberCodec>;

export const dateOnTitleRegisterToLandRegFormat = (date_on_title_register: string | null) => date_on_title_register
? `${DateTime.fromISO(date_on_title_register).setZone("Europe/London").toFormat("dd MMM yyyy").toUpperCase()} at ${DateTime.fromISO(date_on_title_register).setZone("Europe/London").toFormat("HH:mm:ss")}`
: undefined

export const CaseProperty1 = intersection([
    required({
        title_guarantee: CasePropertyTitleGuarantee2,
        title_class: CasePropertyTitleClass,
        affected_by_building_safety_act: union([boolean(), nullCodec()]),
        date_on_title_register: union([nullCodec(), dateTime()]),
        last_transfer_date: union([date(), nullCodec()]),
        is_registered: union([boolean(), nullCodec()]),
        transfer_of: CasePropertyTransferOf,
        recent_last_transfer_date_risk_acknowledged: boolean(),
        root_of_title_document_name: string(),
        root_of_title_parties: string(),
        root_of_title_document_date: union([nullCodec(), date()]),
    }),
    CasePropertyTenureAndTitleNumber,
]);

export const CasePropertyCaseIdOnly = intersection([
    required({
        case_id: uuid(),
    }),
]);

export const CasePropertyFull = intersection([
    required({
        id: uuid(),
    }),
    CaseProperty1,
    Address3,
]);
export type TCasePropertyFullCodec = typeof CasePropertyFull;
export type TCasePropertyFull = TTypeOfCodec<TCasePropertyFullCodec>;

export const CasePropertyFullWithAddressShortString = intersection([
    required({
        id: uuid(),
    }),
    CaseProperty1,
    Address3,
    requiredFlatOverloaded({
        address_short_string: Address2,
    })
]);
export type TCasePropertyFullWithAddressShortStringCodec = typeof CasePropertyFullWithAddressShortString;
export type TCasePropertyFullWithAddressShortString = TTypeOfCodec<TCasePropertyFullWithAddressShortStringCodec>;

export const CasePropertyAddressAndTitleNumber = intersection([
    required({
        title_number: string(),
    }),
    requiredFlatOverloaded({
        address: Address2,
    }),
]);
export type TCasePropertyAddressAndTitleNumberCodec = typeof CasePropertyAddressAndTitleNumber;
export type TCasePropertyAddressAndTitleNumber = TTypeOfCodec<TCasePropertyAddressAndTitleNumberCodec>;

export const CasePropertyIdAddressAndTitleNumber = intersection([
    required({
        id: uuid(),
        title_number: string(),
    }),
    requiredFlatOverloaded({
        address: Address2,
    }),
]);
export type TCasePropertyIdAddressAndTitleNumberCodec = typeof CasePropertyIdAddressAndTitleNumber;
export type TCasePropertyIdAddressAndTitleNumber = TTypeOfCodec<TCasePropertyIdAddressAndTitleNumberCodec>;

export const CasePropertyAddress1TitleNumberRegistered = intersection([
    required({
        cases_properties_id: uuid(),
        title_number: string(),
        is_registered: union([boolean(), nullCodec()]),
    }),
    requiredFlatOverloaded({
        address: Address1,
    }),
]);
export type TCasePropertyAddress1TitleNumberRegisteredCodec = typeof CasePropertyAddress1TitleNumberRegistered;
export type TCasePropertyAddress1TitleNumberRegistered = TTypeOfCodec<TCasePropertyAddress1TitleNumberRegisteredCodec>;