import React from "react";
import { CRMColumns } from "../../components/CRMColumns/CRMColumns";
import { CRMSpacer } from "../../components/CRMSpacer/CRMSpacer";
import FrontParagraphRegular from "../../components/Front/Simple/FrontParagraphRegular/FrontParagraphRegular";
import { FrontProbateWhatsIncluded } from "../../components/FrontProbateWhatsIncluded/FrontProbateWhatsIncluded";
import FrontTitleMinor from "../../components/Front/Simple/FrontTitleMinor/FrontTitleMinor";
import FrontTitleSectionSubTitle from "../../components/Front/Simple/FrontTitleSectionSubTitle/FrontTitleSectionSubTitle";
import { Uppercase } from "../../components/Uppercase/Uppercase";
import { WeightBold } from "../../components/WeightBold/WeightBold";
import { TProbateCase2ReadOnlyFrom } from "../../models/TFormModels";
import { getDynamicQuoteDetails } from "../FrontProbateGOPQuoteContainer/FrontProbateGOPQuoteContainer";

interface IFrontProbateEstateAdministrationQuoteContainerProps {
    fee: string;
    caseForm: TProbateCase2ReadOnlyFrom;
}

class FrontProbateEstateAdministrationQuoteContainer extends React.Component<React.PropsWithChildren<IFrontProbateEstateAdministrationQuoteContainerProps>> {
    public render(): JSX.Element {
        return (
            <div>
                <CRMColumns
                    padding="medium"
                    columns={
                        [
                            {
                                flex: 1.5,
                                content: (
                                    <WeightBold><FrontTitleMinor>Estate Administration</FrontTitleMinor></WeightBold>
                                ),
                            },
                            {
                                flex: 1,
                                content: (
                                    <div style={{display: "flex", justifyContent: "flex-end"}}>
                                        <div style={{background: "#049DCC", color: "white", fontWeight: "bold", padding: "10px", borderRadius: "4px"}}>
                                            £{this.props.fee}
                                        </div>
                                    </div>
                                ),
                            },
                        ]
                    }
                />
                <CRMSpacer size="large"></CRMSpacer>
                <FrontParagraphRegular>
                Get help dealing with the estate of someone who has passed away. Hand it all to a legal expert and avoid the hassle and confusion.
                </FrontParagraphRegular>
                <CRMSpacer size="large"></CRMSpacer>
                <WeightBold>
                    <Uppercase>
                        <FrontTitleSectionSubTitle text="Included services" />
                    </Uppercase>
                </WeightBold>
                <CRMSpacer size="medium"></CRMSpacer>
                <FrontProbateWhatsIncluded 
                    obtainingVals={true}
                    payingInheritanceTax={true}
                    gopApplication={true}
                    probateRegistryApplication={true}
                    postingGop={true}
                    contactFinInstToCloseAccounts={true}
                    collectingEstateFunds={true}
                    payingDebts={true}
                    payingIncomeCapitalGainsTax={true}
                    preparingEstateAccounts={true}
                    distributingFunds={true}
                    sellingProperties={true}
                />
                <hr></hr>
                <WeightBold>
                    <Uppercase>
                        <FrontTitleSectionSubTitle text="This quote is for" />
                    </Uppercase>
                </WeightBold>
                <CRMSpacer size="medium"></CRMSpacer>

                {
                    getDynamicQuoteDetails(this.props.caseForm).map(
                        (s, i) =>
                            (
                                <div key={i}>
                                    <FrontParagraphRegular>{s}</FrontParagraphRegular>
                                </div>
                            )
                    )
                }
            </div>
        );
    }
}

export default FrontProbateEstateAdministrationQuoteContainer;
