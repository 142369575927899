import { TTypeOfCodec } from "../../../shared/src/codecs/codec";
import { form, TFormCodec } from "../../../shared/src/codecs/types/form";
import { EmptyObject, TEmptyObjectCodec } from "../EmptyObject";
import { TUuidObjectCodec, UuidObject } from "../Id";

export const CasePropertyDeleteTitleIssueForm: TFormCodec<TUuidObjectCodec, TEmptyObjectCodec> = 
    form(
        UuidObject,
        EmptyObject,
    );

export type TCasePropertyDeleteTitleIssueFormCodec = typeof CasePropertyDeleteTitleIssueForm;
export type TCasePropertyDeleteTitleIssueForm = TTypeOfCodec<TCasePropertyDeleteTitleIssueFormCodec>;