import React from "react";

export default (props: React.SVGAttributes<SVGElement>) => 
<svg style={{display: "block"}} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
    {/* <!-- Generator: Sketch 61.2 (89653) - https://sketch.com --> */}
    <title>0: Icons / Arrow Right</title>
    <desc>Created with Sketch.</desc>
    <g id="0:-Icons-/-Arrow-Right" stroke="none" strokeWidth="1">
        <path d="M18.72875,11.2325 L6.72175,0.2925 C6.32775,-0.0975 5.68975,-0.0975 5.29475,0.2925 C4.90175,0.6825 4.90175,1.3225 5.29475,1.7125 L16.57775,11.9925 L5.29475,22.2825 C4.90175,22.6725 4.90175,23.3025 5.29475,23.7025 C5.68975,24.0925 6.32775,24.0925 6.72175,23.7025 L18.72875,12.7625 C18.93875,12.5525 19.02875,12.2725 19.01475,11.9925 C19.02875,11.7225 18.93875,11.4425 18.72875,11.2325" id="Color:"></path>
    </g>
</svg>;
