import { TTypeOfCodec } from "../../../shared/src/codecs/codec";
import { form, TFormCodec } from "../../../shared/src/codecs/types/form";
import { required, TRequiredCodec } from "../../../shared/src/codecs/types/required";
import { string, TAnyStringCodec } from "../../../shared/src/codecs/types/string";
import { EmailAttachmentSearchResults, TEmailAttachmentSearchResultsCodec } from "../EmailAttachmentSearchResults";

type TSearchCodec = TRequiredCodec<{
    case_id: TAnyStringCodec,
}>;

export const EmailAttachmentSearchForm: TFormCodec<TSearchCodec, TEmailAttachmentSearchResultsCodec> = 
    form(
        required({
            case_id: string(),
        }),
        EmailAttachmentSearchResults
    );

export type TEmailAttachmentSearchFormCodec = typeof EmailAttachmentSearchForm;
export type TEmailAttachmentSearchForm = TTypeOfCodec<TEmailAttachmentSearchFormCodec>;