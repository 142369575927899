import * as t from "io-ts";
import * as util from "../../shared/src/util";

export const codec = t.union([
    t.literal("no_chain"),
    t.literal("in_chain"),
    t.literal("unknown"),
]);

type T = t.TypeOf<typeof codec>;

export const values = util.typeValuesFromCodec(codec.types as Array<t.LiteralC<T>>);

export const toString = (s: T): string => {
    switch(s) {
        case "in_chain":
            return "In Chain";
        case "no_chain":
            return "No Chain";
        default:
            return "Unknown";
    }
};
