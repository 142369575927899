import { required, TRequiredCodec } from "../../../shared/src/codecs/types/required";
import { BuyerSubmitsNewOfferFormEditable, TBuyerSubmitsNewOfferFormEditableCodec } from "../formEditable/BuyerSubmitsNewOfferFormEditable";
import { PublicOffersPageFormIO, TPublicOffersPageFormIOCodec } from "../formIO/PublicOffersPageFormIO";

export const PublicOffersPagePartial: TRequiredCodec<{
    data: TPublicOffersPageFormIOCodec,
    new_offer: TBuyerSubmitsNewOfferFormEditableCodec
}> = required({
    data: PublicOffersPageFormIO,
    new_offer: BuyerSubmitsNewOfferFormEditable
});

export type TPublicOffersPagePartialCodec = typeof PublicOffersPagePartial;