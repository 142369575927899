import { array } from "../../shared/src/codecs/types/array";
import { boolean } from "../../shared/src/codecs/types/boolean";
import { date } from "../../shared/src/codecs/types/date";
import { dateTime } from "../../shared/src/codecs/types/dateTime";
import { nullCodec } from "../../shared/src/codecs/types/nullCodec";
import { required } from "../../shared/src/codecs/types/required";
import { string } from "../../shared/src/codecs/types/string";
import { union } from "../../shared/src/codecs/types/union";
import { uuid } from "../../shared/src/codecs/types/uuid";
import { CasesDocumentLegalFile3 } from "./CasesDocumentLegalFile3";
import { CasesDocumentType } from "./CasesDocumentTypes";

export const CasesDocument5 = required({
    id: uuid(),
    cases_enquiry_id: uuid(),
    case_id: uuid(),
    type: CasesDocumentType,
    due_date: dateTime(),
    received_date: union([dateTime(), nullCodec()]),
    additional_name: string(),
    from_external_source: boolean(),
    files: array(CasesDocumentLegalFile3),
    all_files_checked_for_enquiries: boolean(),
    valid_until: union([date(), nullCodec()]),
    reference: string(),
    shared_with_client: boolean(),
    shared_with_solicitor: boolean(),
});