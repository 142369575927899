import { TAnyCodec, TCodec, TTypeOfCodec, TTypeOfNewDefault } from "../codec";
import { either } from "fp-ts/lib";
import { pipe } from "fp-ts/lib/function";
import { TError } from "../errors";

export type TAnyOverloadCodec = TCodec<
    "OverloadCodec",
    {overloadCodec: TAnyCodec; codec: TAnyCodec},
    unknown,
    unknown
>;

const decode = <C extends TAnyCodec, C2 extends TAnyCodec>(method: "decode" | "decodeNewDefault", input: unknown, map: (from: TTypeOfNewDefault<C>) => TTypeOfNewDefault<C2>, overloadCodec: C, codec: C2): either.Either<TError, TTypeOfNewDefault<C2>> =>
    pipe(
        overloadCodec[method](input),
        either.map((decoded) => map(decoded as TTypeOfNewDefault<C>)),
        either.chain((decoded) => codec[method](decoded) as either.Either<TError, TTypeOfNewDefault<C2>>),
        either.fold(
            (e1) => pipe(
                codec[method](input) as either.Either<TError, TTypeOfNewDefault<C2>>,
                either.mapLeft((e2) => [...e1, ...e2])
            ),
            (v) => either.right(v),
        ),
    );

export const overload = <C extends TAnyCodec, C2 extends TAnyCodec>(codec: C2, overloadCodec: C, mappingFunc: (from: TTypeOfNewDefault<C>) => TTypeOfNewDefault<C2>): TCodec<"OverloadCodec", {overloadCodec: C; codec: C2}, TTypeOfCodec<C2>, TTypeOfNewDefault<C2>> => ({
    type: "OverloadCodec",
    payload: {overloadCodec, codec},
    decode: (input) => decode("decode", input, mappingFunc, overloadCodec, codec) as either.Either<TError, TTypeOfCodec<C2>>,
    decodeNewDefault: (input) => decode("decodeNewDefault", input, mappingFunc, overloadCodec, codec),
    newDefault: () => codec.newDefault() as TTypeOfNewDefault<C2>,
});
