import { TCodec } from "../codec";
import { TError, errorConstants } from "../errors";
import * as regexes from "../../regexes";
import { either } from "fp-ts/lib";
import * as E164PhoneNumber from "../../util/E164PhoneNumber";

export type TAnyPhoneNumberCodec = TCodec<
    "PhoneNumberCodec",
    undefined,
    string ,
    string
>;

const decode = (input: unknown): either.Either<TError, string> =>
    typeof input === "string"
    && (new RegExp(regexes.constants.MOBILE_PHONE_REGEX)).test(input)
        ? either.right(E164PhoneNumber.fromLocalNumber(input))
        : either.left([[errorConstants.REGEX_VALIDATION, ""]]);

const decodeNewDefault = (input: unknown): either.Either<TError, string> =>
    typeof input === "string"
        ? either.right(E164PhoneNumber.fromLocalNumber(input))
        : either.left([[errorConstants.REGEX_VALIDATION, ""]]);

export const phoneNumber = (): TAnyPhoneNumberCodec => ({
    type: "PhoneNumberCodec",
    payload: undefined,
    decode,
    decodeNewDefault,
    newDefault: () => "",
});
