import * as t from "io-ts";
import { TGetAddressIOResponseCounty } from "../models/GetAddressIOResponse";
import * as util from "../../shared/src/util";
import * as Country1 from "./Country1";

export type TDisplayName =
    "England"
    | "Scotland"
    | "Wales"
    | "Northern Ireland"
    | "Unknown"
;

export const codec = t.union([
    ...Country1.codec.types,
    t.literal("unknown"),
]);

export const values = util.typeValuesFromCodec(codec.types as Array<t.LiteralC<T>>);

type T = t.TypeOf<typeof codec>;

export const fromValueToDisplayName = (value: T): TDisplayName => {
    switch (value) {
        case "england":
            return "England";
        case "scotland":
            return "Scotland";
        case "wales":
            return "Wales";
        case "northern_ireland":
            return "Northern Ireland";
        case "unknown":
            return "Unknown";
        default:
            return util.requireExhaustive(value);
    }
};

export const fromGetAddressIOApiCountryValueToCountryValue = (value: TGetAddressIOResponseCounty): T => {
    switch (value) {
        case "England":
            return "england";
        case "Scotland":
            return "scotland";
        case "Wales":
            return "wales";
        case "Northern Ireland":
            return "northern_ireland";
        default:
            return util.requireExhaustive(value);
    }
};
