import { TAnyCodec, TCodec, TTypeOfCodec, TTypeOfNewDefault, formStatus, TFormStatus } from "../codec";
import { either, array as fptsArray } from "fp-ts/lib";
import { array } from "./array";
import { TError, TErrorTuple } from "../errors";
import { required } from "./required";
import { unknown } from "./unknown";
import { errorCode } from "./errorCode";
import { tuple } from "./tuple";
import { string } from "./string";
import { pipe } from "fp-ts/lib/function";
import clone from "just-clone";

export type TAnyFormCodec = TCodec<
    "FormCodec",
    { codec: TAnyCodec },
    TForm<unknown, unknown, unknown>,
    TForm<unknown, unknown, unknown>
>;

export type TForm<P, E, C> = {
    status: TTypeOfCodec<typeof formStatus>;
    original: P;
    edited: E;
    children: C;
    validationErrors: TError;
};

const validationErrorsCodec = array(tuple([errorCode(), string()]));

const formCodec = required({
    status: formStatus,
    original: unknown(),
    edited: unknown(),
    children: unknown(),
    validationErrors: validationErrorsCodec,
});

const decode = <
    P extends TAnyCodec,
    D extends TForm<TTypeOfNewDefault<P>, TTypeOfCodec<P>, TTypeOfNewDefault<C>>,
    N extends TForm<TTypeOfNewDefault<P>, TTypeOfNewDefault<P>, TTypeOfNewDefault<C>>,
    C extends TAnyCodec,
    M extends "decode" | "decodeNewDefault"
>(codec: P, children: C, decodeMethod: M) => (input: unknown): either.Either<TError, M extends "decode" ? D : N> =>
    pipe(
        formCodec.decode(input),
        either.fold(
            (e) => pipe(
                codec[decodeMethod](input),
                either.fold(
                    (er) => either.left([...er, ...e]),
                    (d) => {
                        const f = formCodec.newDefault();
                        // We deep clone these so that they aren't references, such that editing one does not affect the other
                        f.original = clone(d as object);
                        f.edited = clone(d  as object);
                        return either.right(f as D);
                    },
                ),
                either.chain((d) =>
                    pipe(
                        children.decodeNewDefault(input),
                        either.fold(
                            (er) => either.left([...er, ...e]),
                            (c) => {
                                d.children = clone(c as object) as TTypeOfNewDefault<C>;
                                return either.right(d as M extends "decode" ? D : N);
                            },
                        ),
                    )
                ),
            ),
            (d) => pipe(
                [
                    pipe(
                        codec.decodeNewDefault(d.original),
                        either.mapLeft((e) => e.map(([err, p]) => [err, `original.${p}`] as TErrorTuple))
                    ),
                    pipe(
                        codec[decodeMethod](d.edited),
                        either.mapLeft((e) => e.map(([err, c]) => [err, `edited.${c}`] as TErrorTuple))
                    ),
                ],
                fptsArray.separate,
                (s) =>
                    s.left.length
                    ? either.left(fptsArray.flatten(s.left))
                    : either.right({...d, ...{original: s.right[0]}, ...{edited: s.right[1]}} as M extends "decode" ? D : N),
                either.chain((payload) =>
                    pipe(
                        children.decodeNewDefault(d.children),
                        either.fold(
                            (e) => either.left(e.map(([err, p]) => [err, p ? `children.${p}` : "children"] as TErrorTuple)),
                            (childrenPayload) => either.right({
                                ...payload,
                                children: childrenPayload as TTypeOfNewDefault<C>,
                            }),
                        )
                    )
                ),
            ),
        ),
    );

export const formToRequiredCodec = <A extends TAnyFormCodec, C extends TAnyCodec>(codec: A) => required({
    status: formStatus,
    edited: codec.payload.codec,
    original: codec.payload.codec,
    children: (codec.payload as {codec: A, children: C}).children,
    validationErrors: validationErrorsCodec,
});

type TFormDerivedType<P extends TAnyCodec, C extends TAnyCodec> = TForm<TTypeOfNewDefault<P>, TTypeOfCodec<P>, TTypeOfNewDefault<C>>;
export type TFormDerivedNewDefault<P extends TAnyCodec, C extends TAnyCodec> = TForm<TTypeOfNewDefault<P>, TTypeOfNewDefault<P>, TTypeOfNewDefault<C>>;

export type TFormCodec<
    P extends TAnyCodec,
    C extends TAnyCodec
> = TCodec<
    "FormCodec", 
    { codec: P, children: C },
    TFormDerivedType<P, C>,
    TFormDerivedNewDefault<P, C>
>;

export const form = <
    P extends TAnyCodec,
    D extends TFormDerivedType<P, C>,
    N extends TFormDerivedNewDefault<P, C>,
    C extends TAnyCodec
>(codec: P, children: C): TCodec<"FormCodec", { codec: P, children: C }, D, N> => ({
        type: "FormCodec",
        payload: {codec, children},
        decode: decode(codec, children, "decode"),
        decodeNewDefault: decode(codec, children, "decodeNewDefault"),
        newDefault: (): N =>
            ({
                ...formCodec.newDefault(),
                ...{original: codec.newDefault()},
                ...{edited: codec.newDefault()},
                children: children.newDefault(),
            }) as N,
    });

type TUnpackOriginal<T> = T extends TForm<infer P, any, any> ? P : never; // eslint-disable-line
type TUnpackEditable<T> = T extends TForm<any, infer E, any> ? E : never; // eslint-disable-line
type TUnpackChildren<T> = T extends TForm<any, any, infer C> ? C : never; // eslint-disable-line

export const onChangeForm = <
    T extends TForm<TUnpackOriginal<T>, TUnpackEditable<T>, TUnpackChildren<T>>,
    K extends keyof TUnpackEditable<T>
>(innerForm: T, onChange: (f: T) => void) =>
    (key: K, status?: TFormStatus) => 
        (value: T["edited"][K]) =>
            onChange(editFormValue(innerForm, key, value, status))
;

export const onChangeFormChildren = <
    T extends TForm<TUnpackOriginal<T>, TUnpackEditable<T>, TUnpackChildren<T>>,
    K extends keyof TUnpackChildren<T>
>(innerForm: T, onChange: (f: T) => void) =>
    (key: K, status?: TFormStatus) => 
        (value: T["children"][K]) =>
            onChange(editFormChild(innerForm, key, value, status))
;

export const onChangeFormBulk = 
    <T extends TForm<TUnpackOriginal<T>, TUnpackEditable<T>, TUnpackChildren<T>>>(innerForm: T, onChange: (f: T) => void) =>
        (bulkEdit: Partial<T["edited"]>, status?: TFormStatus) => 
            onChange(editFormBulk(innerForm, bulkEdit, status))
;

const editFormValue = <
    T extends TForm<TUnpackOriginal<T>, TUnpackEditable<T>, TUnpackChildren<T>>,
    K extends keyof TUnpackEditable<T>
>(f: T, key: K, value: T["edited"][K], status?: TFormStatus): T =>
    ({
        ...f,
        status: status || "requiresSubmission",
        edited: {
            ...f.edited,
            [key]: value,
        },
    });

const editFormChild = <
    T extends TForm<TUnpackOriginal<T>, TUnpackEditable<T>, TUnpackChildren<T>>,
    K extends keyof TUnpackChildren<T>
>(f: T, key: K, value: T["children"][K], status?: TFormStatus): T =>
    ({
        ...f,
        status: status || "requiresSubmission",
        children: {
            ...f.children,
            [key]: value
        }
    });

const editFormBulk = <
    T extends TForm<TUnpackOriginal<T>, TUnpackEditable<T>, TUnpackChildren<T>>,
>(innerForm: T, bulkEdit: Partial<T["edited"]>, status?: TFormStatus): T =>
    ({
        ...innerForm,
        status: status || "requiresSubmission",
        edited: {
            ...innerForm.edited,
            ...bulkEdit
        },
    });

export const doFormValuesMatch = <T extends TForm<TUnpackOriginal<T>, TUnpackEditable<T>, TUnpackChildren<T>>, K extends keyof TUnpackOriginal<T>>(leftForm: T, key: K) =>
    (rightForm: T): boolean =>
        leftForm.original[key] === rightForm.original[key]
    ;