import { TTypeOfCodec } from "../../../shared/src/codecs/codec";
import { TArrayCodec, array } from "../../../shared/src/codecs/types/array";
import { TFormCodec, form } from "../../../shared/src/codecs/types/form";
import { TRequiredCodec, required } from "../../../shared/src/codecs/types/required";
import { CaseAutoAssignment, TCaseAutoAssignmentCodec } from "../CaseAutoAssignment";
import { EmptyObject, TEmptyObjectCodec } from "../EmptyObject";
import { TUserIdAndFullNameCodec, UserIdAndFullName } from "../User";

export const CaseAutoAssignmentForm: TFormCodec<
    TCaseAutoAssignmentCodec,
    TEmptyObjectCodec
> = form(
    CaseAutoAssignment,
    EmptyObject,
);
export type TCaseAutoAssignmentFormCodec = typeof CaseAutoAssignmentForm;
export type TCaseAutoAssignmentForm = TTypeOfCodec<TCaseAutoAssignmentFormCodec>;

export const CaseAutoAssignmentPageForm: TFormCodec<
    TEmptyObjectCodec,
    TRequiredCodec<{
        case_auto_assignment_forms: TArrayCodec<TCaseAutoAssignmentFormCodec>,
        case_handlers: TArrayCodec<TUserIdAndFullNameCodec>,
        case_assistants: TArrayCodec<TUserIdAndFullNameCodec>,
    }>
> = form(
    EmptyObject,
    required({
        case_auto_assignment_forms: array(CaseAutoAssignmentForm),
        case_handlers: array(UserIdAndFullName),
        case_assistants: array(UserIdAndFullName),
    }),
);
export type TCaseAutoAssignmentPageFormCodec = typeof CaseAutoAssignmentPageForm;
export type TCaseAutoAssignmentPageForm = TTypeOfCodec<TCaseAutoAssignmentPageFormCodec>;
