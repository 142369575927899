import { TTypeOfCodec } from "../../shared/src/codecs/codec";
import { union } from "../../shared/src/codecs/types/union";
import { literal } from "../../shared/src/codecs/types/literal";
import * as util from "../../shared/src/util";

export const ListingEnquiryRenovationWorkBy1 = union([
    literal("unknown"),
    literal("buyer"),
    literal("buyer_and_outsource"),
    literal("builder"),
    literal("not_renovating"),
]);
type TListingEnquiryRenovationWorkBy1 = TTypeOfCodec<typeof ListingEnquiryRenovationWorkBy1>;

export const ListingEnquiryRenovationWorkBy1_displayName = (p: TListingEnquiryRenovationWorkBy1): string => {
    switch (p) {
        case "buyer":
            return "Buyer";
        case "buyer_and_outsource":
            return "Buyer and outsource";
        case "builder":
            return "Builder";
        case "not_renovating":
            return "Not renovating";
        case "unknown":
            return "Unknown";
        default:
            return util.requireExhaustive(p);
    }
};