import { TTypeOfCodec } from "../../shared/src/codecs/codec";
import { literal } from "../../shared/src/codecs/types/literal";
import { overload } from "../../shared/src/codecs/types/overload";
import { union } from "../../shared/src/codecs/types/union";
import * as util from "../../shared/src/util";

const ListingStatus1 = union([
    literal('lead'),
    literal('prep_valuation'),
    literal('chase_instruction'),
    literal('instructed_needs_market_prep'),
    literal('instructed_on_the_market'),
    literal('sstc'),
    literal('completed'),
    literal('lost'),
]);

export const ListingStatusDisplayString = overload(
    union([
        literal('Lead'),
        literal('Prep valuation'),
        literal('Chase instruction'),
        literal('Instructed: needs market prep'),
        literal('Instructed: on the market'),
        literal('SSTC'),
        literal('Completed'),
        literal('Lost'),
    ]),
    ListingStatus1,
    (status) => {
        switch (status) {
            case "lead":
                return "Lead";
            case "prep_valuation":
                return "Prep valuation";
            case "chase_instruction":
                return "Chase instruction";
            case "instructed_needs_market_prep":
                return "Instructed: needs market prep";
            case "instructed_on_the_market":
                return "Instructed: on the market";
            case "sstc":
                return "SSTC";
            case "completed":
                return "Completed";
            case "lost":
                return "Lost";
            default:
                return util.requireExhaustive(status);
        }
    }
);
export type TListingStatusDisplayString = TTypeOfCodec<typeof ListingStatusDisplayString>;
