import React from "react";
import { onKeyPress } from "../../functions/keypress/onKeyPress";

export interface IInputGeneralComponentProps {
    inputType: 
        "text"
        | "tel"
        | "numericString"
        | "email"
        | "url"
        | "password";
    required?: boolean;
    displayError: boolean;
    name?: string;
    value?: string;
    placeholder?: string;
    disabled?: boolean;
    pattern?: string;
    maxLength?: number;
    onChange: (value: string) => void;
    onPressEnterKey?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    onPressTabKey?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    onPressEscape?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    onPressBackspace?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    onFocus?: () => void;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
    autoFocus?: boolean;
    inputRef?: React.RefObject<HTMLInputElement>;
}

type TUnstyledInputGeneralComponentProps = {
    className?: string;
    style?: React.CSSProperties;
} & IInputGeneralComponentProps;

class UnstyledInputGeneralComponent extends React.Component<React.PropsWithChildren<TUnstyledInputGeneralComponentProps>> {

    onChange (event: React.ChangeEvent<HTMLInputElement>): void {
        if (this.props.inputType === "numericString") {
            if (
                new RegExp(/^[0-9]*$/).test(event.currentTarget.value)
                && (typeof parseInt(event.currentTarget.value) === "number" && parseInt(event.currentTarget.value) >= 0)
                && (event.currentTarget.value.length <= 16) // maximum length before it goes weird
                || (event.currentTarget.value === "")
            ) {
                this.props.onChange(event.currentTarget.value)
            } else {
                event.preventDefault() // Does not emit when any characters are entered other than [0-9], or below a defined minimum number
            }

        } else {
            this.props.onChange(event.currentTarget.value);
        }
    }

    public render (): JSX.Element {
        return (
            <input
                ref={this.props.inputRef}
                className={this.props.className}
                type={this.props.inputType === "numericString" ? "text" : this.props.inputType }
                inputMode={
                    this.props.inputType === "numericString" ? "numeric"
                    : this.props.inputType === "password" ? "text"
                    : this.props.inputType 
                }
                required={this.props.required || false}
                name={this.props.name}
                id={this.props.name}
                value={this.props.value}
                placeholder={this.props.placeholder}
                disabled={this.props.disabled}
                onChange={(event) => this.onChange(event)}
                onKeyDown={onKeyPress({
                    onEnter: this.props.onPressEnterKey,
                    onTab: this.props.onPressTabKey,
                    onBackspace: this.props.onPressBackspace,
                    onEscape: this.props.onPressEscape
                })}
                maxLength={this.props.maxLength}
                onFocus={this.props.onFocus}
                onBlur={this.props.onBlur}
                autoFocus={this.props.autoFocus}
                style={this.props.style}
                autoComplete="off"
            />
        );
    }
}

export default UnstyledInputGeneralComponent;
