import { TCodec } from "../codec";
import { either } from "fp-ts/lib";
import { TError, errorConstants } from "../errors";

export type TAnyPositiveDecimalCodec = TCodec<
    "PositiveDecimalCodec",
    undefined,
    number,
    number
>;

const decode = (input: unknown): either.Either<TError, number> =>
    typeof input === "number" && input >= 0
        ? either.right(input)
        : either.left([[errorConstants.POSITIVE_DECIMAL_VALIDATION, ""]]);

export const positiveDecimal = (): TAnyPositiveDecimalCodec => ({
    type: "PositiveDecimalCodec",
    payload: undefined,
    decode,
    decodeNewDefault: decode,
    newDefault: () => 0,
});
