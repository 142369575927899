import React from "react";

export default (props: React.SVGAttributes<SVGElement>) => <svg  style={{ display: "block" }} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
    {/* <!-- Generator: Sketch 61.2 (89653) - https://sketch.com --> */}
    <title>0: Icons / Arrow Left</title>
    <desc>Created with Sketch.</desc>
    <g id="0:-Icons-/-Arrow-Left" stroke="none" strokeWidth="1">
        <path d="M7.43260208,11.9925 L18.6896021,1.7125 C19.0826021,1.3225 19.0826021,0.6825 18.6896021,0.2925 C18.2966021,-0.0975 17.6586021,-0.0975 17.2666021,0.2925 L5.28560208,11.2325 C5.07660208,11.4425 4.98660208,11.7225 5.00160208,11.9925 C4.98660208,12.2725 5.07660208,12.5525 5.28560208,12.7625 L17.2666021,23.7025 C17.6586021,24.0925 18.2966021,24.0925 18.6896021,23.7025 C19.0826021,23.3025 19.0826021,22.6725 18.6896021,22.2825 L7.43260208,11.9925" id="Color:"></path>
    </g>
</svg>;
