import { pipe } from "fp-ts/lib/function";
import { overload } from "../../../shared/src/codecs/types/overload";
import { required } from "../../../shared/src/codecs/types/required";
import { string } from "../../../shared/src/codecs/types/string";
import * as regexes from "../../../shared/src/regexes";

export const ConfidentialLegalEmailOverload = overload(
    string(),
    required({ email: string() }),
    (codec) => 
        !!codec.email.match(regexes.constants.SAIL_LEGAL_EMAIL) ?
            codec.email.replace(regexes.constants.SAIL_LEGAL_EMAIL, regexes.constants.SAIL_LEGAL_CONFIDENTIAL_EMAIL)
            : ""
);

// Convert John Smith <johnsmith@some.com> to just "John Smith" where possible
export const EmailSimpleDisplayString = overload(
    string(),
    string(),
    (email) =>
        pipe(
            new RegExp(/(^[^<]*)/).exec(email),
            (result) =>
                result
                    ? result[0]
                    : "",
            (emailString) => emailString.trim(),
        )
    );

// Convert John Smith <johnsmith@some.com> to just "johnsmith@some.com"
export const ExtractedEmailAddress = overload(
    string(),
    string(),
    (email) =>
        pipe(
            new RegExp(/<([^>]*)|(^[^<]*$)/).exec(email),
            (result) =>
                result
                    ? result[1] || result[2] // There are two capture groups in the above regex that can not both be true, use the one that isn't undefined
                    : "",
            (emailString) => emailString.trim(),
        )
    );

