import { overload } from "../../../shared/src/codecs/types/overload";
import { required } from "../../../shared/src/codecs/types/required";
import { dateTime } from "../../../shared/src/codecs/types/dateTime";
import { boolean } from "../../../shared/src/codecs/types/boolean";
import { DateTime } from "luxon";

export const UsersVideoVerificationCallHasExpired = overload(
    boolean(),
    required({
        outcome_expires_at: dateTime(),
    }),
    ({outcome_expires_at}) => DateTime.fromISO(outcome_expires_at).toSeconds() <= DateTime.utc().toSeconds(),
);
export type TUsersVideoVerificationCallHasExpiredCodec = typeof UsersVideoVerificationCallHasExpired;
