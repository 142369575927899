import { overload } from "../../../shared/src/codecs/types/overload";
import { required } from "../../../shared/src/codecs/types/required";
import { string } from "../../../shared/src/codecs/types/string";
import { CaseLedgerType, CaseLedgerType_displayString } from "../CaseLedger";

export const CaseLedgerTypeDisplayName = overload(
    string(),
    required({
        rate_type: CaseLedgerType,
    }),
    ({rate_type}) => CaseLedgerType_displayString(rate_type),
);
