import { requireExhaustive } from "../../../shared/src/util";

export const CRMSpacing = {
    TINY: "6px",
    SMALL: "10px",
    MEDIUM: "16px",
    LARGE: "24px",
    X_LARGE: "40px",
} as const;
export type TCRMSpacing = typeof CRMSpacing[keyof typeof CRMSpacing];

export const CRMSpacingSizeEnumToPxValueNumber = (spacingSize: TCRMSpacing): number => {
    switch (spacingSize) {
        case CRMSpacing.TINY:
            return 6;
        case CRMSpacing.SMALL:
            return 10;
        case CRMSpacing.MEDIUM:
            return 16;
        case CRMSpacing.LARGE:
            return 24
        case CRMSpacing.X_LARGE:
            return 40;
        default:
            return requireExhaustive(spacingSize);
    }
};