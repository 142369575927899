import { required } from "../../shared/src/codecs/types/required";
import { uuid } from "../../shared/src/codecs/types/uuid";
import { union } from "../../shared/src/codecs/types/union";
import { literal } from "../../shared/src/codecs/types/literal";
import { deferDateTime } from "../../shared/src/codecs/types/deferDateTime";
import { dateTime } from "../../shared/src/codecs/types/dateTime";
import { nullCodec } from "../../shared/src/codecs/types/nullCodec";
import { intersection } from "../../shared/src/codecs/types/intersection";
import { date } from "../../shared/src/codecs/types/date";
import { UserCaseUserType } from "./UserCaseUserType";
import { boolean } from "../../shared/src/codecs/types/boolean";

const UserCaseBankruptcySearchResult = union([
    literal("pass"),
    literal("fail"),
    literal("not_applicable"),
    literal("unknown"),
]);
export const UserCaseBankruptcySearch = required({
    bankruptcy_search_result: UserCaseBankruptcySearchResult,
    bankruptcy_search_expiry_date: union([date(), nullCodec()]),
});

export const UserCaseBankruptcySearchRequired = required({
    bankruptcy_search_required: boolean(),
    bankruptcy_search_has_expired_and_requires_renewal: boolean(),
});

export const UserCaseIsCompanyDirector = required({
    is_company_director: boolean(),
});

export const UserCase1 = intersection([
    required({
        user_id: uuid(),
        case_id: uuid(),
        user_type: UserCaseUserType,
        id_check_deferred: union([deferDateTime(), nullCodec()]),
    }),
    UserCaseBankruptcySearch,
    UserCaseBankruptcySearchRequired,
    UserCaseIsCompanyDirector,
]);
export type TUserCase1Codec = typeof UserCase1;

export const UserCase3 = required({
    instructed_email_last_sent: union([dateTime(), nullCodec()]),
});
export type TUserCase3Codec = typeof UserCase3;

export const UserCase4 = required({
    users__cases_id: uuid(),
});
export type TUserCase4Codec = typeof UserCase4;
