import React from "react";
import { IOption, useDropdown } from "../../hooks/UseDropdown";

interface ICRMDropdownComponent<A extends string> {
    FaceComponent: (props: TDropdownFaceProps<A>) => JSX.Element; 
    OptionsComponent: (props: TDropdownOptionsProps<A>) => JSX.Element;

    options: Array<IOption<A>>;
    
    value: A | Array<A>;
    valuePrefix?: string;
    displayError?: boolean;
    disabled?: boolean;
    placeholder?: string;
};

export type TDropdownFaceProps<A extends string> = {
    isOpen: boolean;
    faceText: JSX.Element;
    toggleOpen: () => void;

    value: A | Array<A>;
    valuePrefix?: string;
    displayError?: boolean;
    disabled?: boolean;
    placeholder?: string;
};

export type TDropdownOptionsProps<A extends string> = {
    options: Array<IOption<A>>;
    isOptionSelected: (value: A) => boolean;
    toggleSelectedValue: (value: A) => A[];
    onCloseDropdown: () => void;
}

export const CRMDropdownBase = <A extends string>(props: ICRMDropdownComponent<A>): JSX.Element => {

    const {
        ref,
        isOpen,
        setIsOpen,
        isOptionSelected,
        toggleSelectedValue,
        getFaceText,
     } = useDropdown<A, IOption<A>>(props.value, props.options);

    return (
        <div
            ref={ref}
            className="crm-dropdown-base"
        >
            <props.FaceComponent
                isOpen={isOpen}
                toggleOpen={() => setIsOpen(!isOpen)}
                faceText={getFaceText()}
                {...props}
            />

            {/* OPTIONS */}
            {isOpen &&
                <props.OptionsComponent
                    isOptionSelected={isOptionSelected}
                    onCloseDropdown={() => setIsOpen(false)}
                    toggleSelectedValue={toggleSelectedValue}
                    {...props}
                />
            }
        </div>
    );
}
