import * as t from "io-ts";
import { nonEmptyArray } from "../../shared/src/validation/basic/nonEmptyArray";
import { nonEmptyString } from "../../shared/src/validation/basic/nonEmptyString";
import { required } from "../../shared/src/validation/basic/required";
import { uuid } from "../../shared/src/validation/basic/uuid";
import { all } from "../../shared/src/validation/compose/all";
import { array } from "../../shared/src/validation/create/array";
import { isEnum } from "../../shared/src/validation/create/isEnum";
import { object } from "../../shared/src/validation/create/object";
import { TValidationFunction } from "../../shared/src/validation/Function";
import * as CaseEnquiryAttachmentsType from "./CaseEnquiryAttachmentsType";

export const codec = t.type({
    type: CaseEnquiryAttachmentsType.codec,
    ids: t.array(t.string),
    summary: t.string,
});

export type T = t.TypeOf<typeof codec>;

export const validator: TValidationFunction = object({
    type: isEnum(CaseEnquiryAttachmentsType.values),
    ids: all(
        required,
        nonEmptyArray,
        array(
            all(
                required,
                uuid,
            )
        )
    ),
});

export const newDefault = (): T => ({
    type: "documents",
    ids: [],
    summary: "",
});
