import React from "react";

export default (props: React.SVGAttributes<SVGElement>) => <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g clipPath="url(#clip0_4084:18674)">
<path d="M23.5558 23.5001L35.978 11.0779C36.4077 10.6483 36.4077 9.95185 35.978 9.52224C35.5485 9.09269 34.852 9.09269 34.4224 9.52224L22.0002 21.9445L9.57803 9.52224C9.14841 9.09269 8.45198 9.09269 8.02236 9.52224C7.59281 9.95185 7.59281 10.6483 8.02236 11.0779L20.4446 23.5001L8.02236 35.9223C7.59281 36.3519 7.59281 37.0484 8.02236 37.4779C8.2372 37.6927 8.51866 37.8001 8.8002 37.8001C9.08173 37.8001 9.36319 37.6927 9.57803 37.4778L22.0002 25.0557L34.4224 37.4779C34.6372 37.6927 34.9187 37.8001 35.2002 37.8001C35.4817 37.8001 35.7632 37.6927 35.978 37.4778C36.4076 37.0483 36.4076 36.3518 35.978 35.9222L23.5558 23.5001Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_4084:18674">
<rect width="44" height="44" fill="white"/>
</clipPath>
</defs>
</svg>
;
