import * as t from "io-ts";

const getAddressIOResponseCountyCodec = t.union([
    t.literal("England"),
    t.literal("Wales"),
    t.literal("Scotland"),
    t.literal("Northern Ireland"),
]);

export type TGetAddressIOResponseCounty = t.TypeOf<typeof getAddressIOResponseCountyCodec>;

export const codec = t.type({
    postcode: t.string,
    latitude: t.number,
    longitude: t.number,
    addresses: t.array(t.type({
        formatted_address: t.array(t.string),
        thoroughfare: t.string,
        building_name: t.string,
        sub_building_name: t.string,
        sub_building_number: t.string,
        building_number: t.string,
        line_1: t.string,
        line_2: t.string,
        line_3: t.string,
        line_4: t.string,
        locality: t.string,
        town_or_city: t.string,
        county: t.string,
        district: t.string,
        country: getAddressIOResponseCountyCodec,
    })),
});

export type T = t.TypeOf<typeof codec>;

export const newDefault = (): T => ({
    postcode: "",
    latitude: 0,
    longitude: 0,
    addresses: [],
});
