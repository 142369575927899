import * as t from "io-ts";
import * as CaseEnquiryNote5 from "./CaseEnquiryNote5";
import * as CaseEnquiryDocument3 from "./CaseEnquiryDocument3";
import * as LegalEmail4 from "./LegalEmail4"; // NB for refactoring, see codecModels/LegalEmail
import * as util from "../../shared/src/util";

export const codec = t.union([
    t.type({
        _tag: t.literal("document"),
        payload: CaseEnquiryDocument3.codec,
    }),
    t.type({
        _tag: t.literal("email"),
        payload: LegalEmail4.codec,
    }),
    t.type({
        _tag: t.literal("note"),
        payload: CaseEnquiryNote5.codec,
    }),
]);

export type T = t.TypeOf<typeof codec>;
export type TCaseEnquiryDocument3 = t.TypeOf<typeof codec["types"][0]>;
export type TLegalEmail4 = t.TypeOf<typeof codec["types"][1]>;
export type TCaseEnquiryNote5 = t.TypeOf<typeof codec["types"][2]>;

type TFoldParams<R> = {
    onCaseEnquiryDocument3: (p: CaseEnquiryDocument3.T) => R;
    onLegalEmail4: (p: LegalEmail4.T) => R;
    onCaseEnquiryNote5: (p: CaseEnquiryNote5.T) => R;
};

export const fold = <R>(fp: TFoldParams<R>) => (p: T): R =>
    p._tag === "document" ? fp.onCaseEnquiryDocument3(p.payload)
    : p._tag === "email" ? fp.onLegalEmail4(p.payload)
    : p._tag === "note" ? fp.onCaseEnquiryNote5(p.payload)
    : util.requireExhaustive(p);
