import * as Function from "../Function";
import * as Error from "../Error";
import * as ErrorCode from "../ErrorCode";
import { option } from "fp-ts/lib";
import * as regexes from "../../regexes";

export const uuid: Function.TValidationFunction = (value: unknown): option.Option<Error.TValidationError> =>
    typeof value === "undefined"
        || (
            typeof value === "string"
            && regexes.constants.UUID_REGEX.test(value)
        ) ? option.none
        : option.some([[ErrorCode.validationErrorCodeConstants.UUID_VALIDATION]]);
