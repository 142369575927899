import React from "react";

export default (props: React.SVGAttributes<SVGElement>) => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g clipPath="url(#clip0_6962_34241)">
<path d="M20 19L20 5C20 3.9 19.1 3 18 3L6 3C4.9 3 4 3.9 4 5L4 19C4 20.1 4.9 21 6 21L18 21C19.1 21 20 20.1 20 19ZM6 19L6 5L18 5L18 19L6 19Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_6962_34241">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>
;
