import { Any } from "io-ts";
import { task, taskEither } from "fp-ts/lib";
import { pipe } from "fp-ts/lib/function";
import * as codec from "./codec";
import { option } from "fp-ts/lib";
import * as FirstPartyFetchResponse from "../../../domain/models/FirstPartyFetchResponse";
import * as JsonResponse1 from "../../../domain/models/JsonResponse1";
import * as JsonError1 from "../../../domain/models/JsonError1";
import * as JsonResponseMeta1 from "../../../domain/models/JsonResponseMeta1";
import * as t from "io-ts";

export type TFetchMethods =
    "GET"
    | "PUT"
    | "POST"
    | "PATCH"
    | "DELETE"
;

type TFetchRequestParams = {
    url: string;
    method: TFetchMethods;
    body: option.Option<string>;
};

type TFetchRequestFileParams = {
    url: string;
    method: TFetchMethods;
    body: File;
    headers?: Record<string, string>
};

export const json = <T extends JsonResponse1.T<T["data"], JsonResponseMeta1.T>>(params: {
    requestParams: TFetchRequestParams;
    expectedTypeCodec: Any;
    defaultResponse: T;
}): task.Task<FirstPartyFetchResponse.T<T>> =>
     pipe(
        taskEither.tryCatch<undefined, Response>(() => fetch(params.requestParams.url,  pipe(
            {
                credentials: "include" as "include",
                method: params.requestParams.method,
                headers: {
                    "content-type": "application/json",
                    "accept": "application/json",
                } as Record<string, string>,
                body: undefined as undefined | string,
            },
            (r) => option.fold<string, RequestInit>(
                () => r,
                (body) => {
                    r.body = body;
                    return r;
                },
            )(params.requestParams.body),
        )), (e) => {
            console.error(e);
            return undefined;
        }),
        taskEither.chain((r) =>
             pipe(
                taskEither.tryCatch<undefined, unknown>(() => r.json(), (e) => {
                    console.error(e);
                    return undefined;
                }),
                taskEither.chain((responseJson) =>  pipe(
                    t.union([params.expectedTypeCodec, JsonError1.codec]),
                    (responseUnionCodec) => codec.decode<T | JsonError1.T>(responseUnionCodec, responseJson),
                    taskEither.mapLeft(() => undefined),
                )),
                taskEither.map((decodedResponseUnion) =>
                    r.status >= 200 && r.status <= 299 && JsonResponse1.is(decodedResponseUnion) ? FirstPartyFetchResponse.create2XX(decodedResponseUnion)
                    : r.status === 401 && JsonError1.is(decodedResponseUnion) ? FirstPartyFetchResponse.create401(params.defaultResponse, decodedResponseUnion.inner_errors)
                    : r.status === 404 && JsonError1.is(decodedResponseUnion) ? FirstPartyFetchResponse.create404(params.defaultResponse)
                    : r.status === 422 && JsonError1.is(decodedResponseUnion) ? FirstPartyFetchResponse.create422(params.defaultResponse, decodedResponseUnion.inner_errors)
                    : FirstPartyFetchResponse.createUnexpected(params.defaultResponse)
                ),
            ),
        ),
        taskEither.fold(
            () => task.of(FirstPartyFetchResponse.createUnexpected(params.defaultResponse)),
            (response) => task.of(response),
        ),
    );

export const noContent = (params: {
    requestParams: TFetchRequestParams;
}): task.Task<FirstPartyFetchResponse.T<void>> =>
     pipe(
        taskEither.tryCatch<undefined, Response>(() => fetch(params.requestParams.url,  pipe(
            {
                credentials: "include" as "include",
                method: params.requestParams.method,
                headers: {
                    "content-type": "application/json",
                    "accept": "application/json",
                } as Record<string, string>,
                body: undefined as string | undefined,
            },
            (r) => option.fold<string, RequestInit>(
                () => r,
                (body) => {
                    r.body = body;
                    return r;
                },
            )(params.requestParams.body),
        )), (e) => {
            console.error(e);
            return undefined;
        }),
        taskEither.chain((r) =>
            r.status === 422
                ?  pipe(
                    taskEither.tryCatch<undefined, unknown>(() => r.json(), (e) => {
                        console.error(e);
                        return undefined;
                    }),
                    taskEither.chain((responseJson) =>
                         pipe(
                            codec.decode<JsonError1.T>(JsonError1.codec, responseJson),
                            taskEither.mapLeft(() => undefined),
                            taskEither.map((decodedResponse) =>
                                JsonError1.is(decodedResponse)
                                    ? FirstPartyFetchResponse.create422(undefined, decodedResponse.inner_errors)
                                    : FirstPartyFetchResponse.createUnexpected(undefined),
                            ),
                        )
                    ),
                )
                :
                    r.status === 204 ? taskEither.right(FirstPartyFetchResponse.create204(undefined))
                    : r.status === 401 ? taskEither.right(FirstPartyFetchResponse.create401(undefined))
                    : r.status === 404 ? taskEither.right(FirstPartyFetchResponse.create404(undefined))
                    : taskEither.right(FirstPartyFetchResponse.createUnexpected(undefined)),
        ),
        taskEither.fold(
            () => task.of(FirstPartyFetchResponse.createUnexpected(undefined)),
            (response) => task.of(response),
        ),
    );

export const file = (params: {
    requestParams: TFetchRequestFileParams;
}): task.Task<FirstPartyFetchResponse.T<void>> => {
    return  pipe(
        taskEither.tryCatch<undefined, Response>(() => fetch(params.requestParams.url, {
            credentials: "include" as "include",
            method: params.requestParams.method,
            body: params.requestParams.body,
            headers: params.requestParams.headers
        }), (e) => {
            console.error(e);
            return undefined;
        }),
        taskEither.chain((r) =>
            r.status === 422
                ?  pipe(
                    taskEither.tryCatch<undefined, unknown>(() => r.json(), (e) => {
                        console.error(e);
                        return undefined;
                    }),
                    taskEither.chain((responseJson) =>
                         pipe(
                            codec.decode<JsonError1.T>(JsonError1.codec, responseJson),
                            taskEither.mapLeft(() => undefined),
                            taskEither.map((decodedResponse) =>
                                JsonError1.is(decodedResponse)
                                    ? FirstPartyFetchResponse.create422(undefined, decodedResponse.inner_errors)
                                    : FirstPartyFetchResponse.createUnexpected(undefined),
                            ),
                        )
                    ),
                )
                :
                    r.status === 204 ? taskEither.right(FirstPartyFetchResponse.create204(undefined))
                    : r.status === 401 ? taskEither.right(FirstPartyFetchResponse.create401(undefined))
                    : r.status === 404 ? taskEither.right(FirstPartyFetchResponse.create404(undefined))
                    : taskEither.right(FirstPartyFetchResponse.createUnexpected(undefined)),
        ),
        taskEither.fold(
            () => task.of(FirstPartyFetchResponse.createUnexpected(undefined)),
            (response) => task.of(response),
        ),
    );
};

const logErrorReturnUndefined = (error: unknown): undefined => {
    console.error(error);
    return undefined;
};

export const thirdPartyJSON = <T>(
    params: {
        requestParams: TFetchRequestParams;
        expectedTypeCodec: Any;
        defaultResponse: T;
    }
): task.Task<T> =>
     pipe(
        taskEither.tryCatch<undefined, Response>(
            () => fetch(
                params.requestParams.url,
                 pipe(
                    {
                        method: params.requestParams.method,
                        headers: {
                            "content-type": "application/json",
                            "accept": "application/json",
                        } as Record<string, string>,
                        body: undefined as string | undefined,
                    },
                    (r) => option.fold<string, RequestInit>(
                        () => r,
                        (body) => {
                            r.body = body;
                            return r;
                        },
                    )(params.requestParams.body),
                )
            ),
            logErrorReturnUndefined
        ),
        taskEither.chain((response) =>
             pipe(
                taskEither.tryCatch<undefined, unknown>(
                    () => response.json(),
                    logErrorReturnUndefined
                ),
                taskEither.map((decodedResponse) => decodedResponse as T),
            )
        ),
        taskEither.fold(
            () => task.of(params.defaultResponse),
            (response) => task.of(response),
        ),
    );
