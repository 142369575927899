import * as t from "io-ts";
import * as util from "../../shared/src/util";

export const codec = t.union([
    t.literal("cash"),
    t.literal("mortgage"),
    t.literal("bridging_loan"),
    t.literal("other"),
    t.literal("unknown"),
]);

export type T = t.TypeOf<typeof codec>;

export const values = util.typeValuesFromCodec(codec.types as Array<t.LiteralC<T>>);
