import * as t from "io-ts";
import * as ViewingType from "./ViewingType";
import * as ListingEnquiriesViewing5 from "./ListingEnquiriesViewing5";
import { array } from "fp-ts/lib";

export const codec = t.type({
    id: t.string,
    enquiry_id: t.string,
    viewing_type: ViewingType.codec,
    requested_times: t.array(t.string),
    confirmed_time: t.union([t.string, t.null]),
});

export type T = t.TypeOf<typeof codec>;

export const newDefault = (): T => ({
    id: "",
    enquiry_id: "",
    viewing_type: "in_person",
    requested_times: [],
    confirmed_time: null,
});

export const fromListingEnquiriesViewing5 = (m: ListingEnquiriesViewing5.T): T => (
    {
        id: m.id,
        enquiry_id: m.enquiry_id,
        viewing_type: m.viewing_type,
        requested_times: array.map<Date, string>((d) => d.toISOString())(m.requested_times),
        confirmed_time: m.confirmed_time,
    }
);
