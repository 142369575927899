import { TCodec } from "../codec";
import { either } from "fp-ts/lib";
import { decode as dateDecode } from "./date";
import { TError } from "../errors";

export type TAnyDateOrNullAsBooleanCodec = TCodec<"DateOrNullAsBooleanCodec", undefined, string | null, string | null>;

export const dateOrNullAsBoolean = (): TAnyDateOrNullAsBooleanCodec => ({
    type: "DateOrNullAsBooleanCodec",
    payload: undefined,
    decode: (input: unknown): either.Either<TError, string | null> =>
        input === null ? either.right(null)
        : dateDecode(input),
    decodeNewDefault: (input: unknown): either.Either<TError, string | null> =>
        input === null ? either.right(null)
        : dateDecode(input),
    newDefault: () => null,
});