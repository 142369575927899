import { TTypeOfCodec } from "../../shared/src/codecs/codec";
import { boolean } from "../../shared/src/codecs/types/boolean";
import { email } from "../../shared/src/codecs/types/email";
import { intersection } from "../../shared/src/codecs/types/intersection";
import { literal } from "../../shared/src/codecs/types/literal";
import { nullCodec } from "../../shared/src/codecs/types/nullCodec";
import { phoneNumber } from "../../shared/src/codecs/types/phoneNumber";
import { required } from "../../shared/src/codecs/types/required";
import { string } from "../../shared/src/codecs/types/string";
import { union } from "../../shared/src/codecs/types/union";
import { uuid } from "../../shared/src/codecs/types/uuid";
import { Address3 } from "./Address";

export const CasesRelatedTransactionConveyancerDetails = required({
    conveyancer_organisation_name: string(),
    conveyancer_name: string(),
    conveyancer_phone_number: union([phoneNumber(), nullCodec()]),
    conveyancer_email_address: union([email(), nullCodec()]),
});

export const CasesRelatedTransactionSaleIsOnMarket = union([
    literal("unknown"),
    literal("yes"),
    literal("no"),
]);

export const CasesRelatedTransaction1 = intersection([
    union([
        required({
            purchase_case_id: uuid(),
        }),
        required({
            sale_case_id: uuid(),
        }),
    ]),
    Address3,
    required({
        sale_is_on_market: CasesRelatedTransactionSaleIsOnMarket,
        wants_quote: union([
            literal("unknown"),
            literal("yes"),
            literal("no"),
        ]),
    }),
    CasesRelatedTransactionConveyancerDetails,
]);
export type TCasesRelatedTransaction1Codec = typeof CasesRelatedTransaction1;
export type TCasesRelatedTransaction1 = TTypeOfCodec<TCasesRelatedTransaction1Codec>;

export const CasesRelatedTransaction2 = intersection([
    CasesRelatedTransaction1,
    required({
        from_case_id: uuid(),
        sale_referred_to_sail_homes: boolean(),
    })
]);
export type TCasesRelatedTransaction2Codec = typeof CasesRelatedTransaction2;
export type TCasesRelatedTransaction2 = TTypeOfCodec<TCasesRelatedTransaction2Codec>;

export const CasesRelatedTransaction3 = intersection([
    CasesRelatedTransaction1,
    required({
        quote_recipient_user_id: union([uuid(), nullCodec()]),
    })
]);
export type TCasesRelatedTransaction3Codec = typeof CasesRelatedTransaction3;
export type TCasesRelatedTransaction3 = TTypeOfCodec<TCasesRelatedTransaction3Codec>;
