import { overload } from "../../../shared/src/codecs/types/overload";
import { required } from "../../../shared/src/codecs/types/required";
import { array as arrayCodec } from "../../../shared/src/codecs/types/array";
import { union } from "../../../shared/src/codecs/types/union";
import { literal } from "../../../shared/src/codecs/types/literal";
import { Address3 } from "../Address";
import { pipe } from "fp-ts/lib/function";
import { array } from "fp-ts";

export const CaseSdltOrLtt = overload(
    union([
        literal("sdlt"),
        literal("ltt"),
    ]),
    required({
        properties: arrayCodec(Address3),
    }),
    ({properties}) =>
        pipe(
            properties,
            array.map(({country}) => country),
        ).includes("wales")
            ? "ltt"
            : "sdlt",
);
export type TCaseSdltOrLttCodec = typeof CaseSdltOrLtt;
