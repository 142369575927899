import React from "react";

type TCRMGrid = {
    padding: "tiny" | "medium" | "large" | "x-large";
    columns: Array<{
        flex: number;
        content: JSX.Element;
        allowTruncateOverflow?: boolean;
    }>;
    mobileDirection?: "forwards" | "reverse";
    wrapOnMobile?: boolean;
    alignItems?: "flex-start" | "flex-end" | "center" | "baseline" | "stretch";
};

const isFirstElement = (index: number): boolean => index === 0;

const isLastElement = (index: number, array: Array<unknown>): boolean => index === array.length - 1;

const getMobileDirectionToUse = (mobileDirection: TCRMGrid["mobileDirection"]): TCRMGrid["mobileDirection"] =>
    ! mobileDirection ? "forwards" : mobileDirection;

const getWrapOnMobile = (wrapOnMobile: TCRMGrid["wrapOnMobile"]): boolean =>
    typeof wrapOnMobile === "undefined" ? true : wrapOnMobile;

export class CRMColumns extends React.Component<React.PropsWithChildren<TCRMGrid>> {
    public render (): JSX.Element {
        return (
            <div
                className={`
                    crm-columns 
                    crm-columns--padding-${this.props.padding} 
                    ${
                        getWrapOnMobile(this.props.wrapOnMobile) && getMobileDirectionToUse(this.props.mobileDirection) === "forwards" ? " crm-columns--mobile"
                        : getWrapOnMobile(this.props.wrapOnMobile) && getMobileDirectionToUse(this.props.mobileDirection) === "reverse" ? " crm-columns--mobile-reverse"
                        : ""
                    }
                `}
                style={{
                    alignItems: this.props.alignItems || undefined,
                }}
            >
                {this.props.columns.map((c, i) => (
                    <div className={`
                        crm-columns__col
                        ${ getWrapOnMobile(this.props.wrapOnMobile) ? "crm-columns__col--mobile" : ""} 
                        ${ ! isFirstElement(i) && `crm-columns__col--desktop-padding-${this.props.padding}`}
                        ${
                            this.props.mobileDirection === "reverse"
                            ? ! isLastElement(i, this.props.columns) && getWrapOnMobile(this.props.wrapOnMobile) && ` crm-columns__col--mobile-padding-${this.props.padding}`
                            : ! isFirstElement(i) && getWrapOnMobile(this.props.wrapOnMobile) && ` crm-columns__col--mobile-padding-${this.props.padding}`
                        }
                        ${c.allowTruncateOverflow && ` crm-columns__col--allow-truncate-overflow`}
                    `} style={{flex: c.flex}} key={i}>
                        {c.content}
                    </div>
                ))}
            </div>
        );
    }
}
