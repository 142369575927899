import React from "react";

export default (props: React.SVGAttributes<SVGElement>) => <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g clipPath="url(#clip0_5280_25134)">
<path d="M10.9999 41.8C10.7184 41.8 10.4369 41.6927 10.2221 41.4778C9.7925 41.0483 9.7925 40.3518 10.2221 39.9222L29.2443 20.9L10.2221 1.87782C9.7925 1.44827 9.7925 0.751759 10.2221 0.322208C10.6517 -0.107342 11.3482 -0.10741 11.7777 0.322208L31.5777 20.1222C32.0073 20.5518 32.0073 21.2483 31.5777 21.6778L11.7777 41.4778C11.5629 41.6927 11.2814 41.8 10.9999 41.8V41.8Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_5280_25134">
<rect width="44" height="44" fill="white" transform="translate(44) rotate(90)"/>
</clipPath>
</defs>
</svg>
;
