import { formStatus, TAnyCodec, TCodec, TTypeOfNewDefault } from "../codecs/codec";
import { TAnyArrayCodec } from "../codecs/types/array";
import { TAnyFormEditableCodec, formEditableToRequiredCodec } from "../codecs/types/formEditable";
import { formIOToRequiredCodec, TAnyFormIOCodec } from "../codecs/types/formIO";
import { TAnyIntersectionCodec, TUnionToIntersection } from "../codecs/types/intersection";
import { TAnyOverloadCodec } from "../codecs/types/overload";
import { TAnyRequiredCodec } from "../codecs/types/required";
import { TAnyRequiredFlatOverloadedCodec } from "./types/requiredFlatOverloaded";
import * as util from "../util";
import { pipe } from "fp-ts/lib/function";
import { record, array as fptsArray, option } from "fp-ts/lib";
import { TAnyCustomCodec } from "../codecs/types/custom";
import { TAnyFixedLengthArrayCodec } from "../codecs/types/fixedLengthArray";
import { TAnyNonEmptyArrayCodec } from "../codecs/types/nonEmptyArray";
import { TAnyUniqueArrayCodec } from "../codecs/types/uniqueArray";
import { TAnyPartialCodec } from "./types/partial";
import { fileIOToRequiredCodec, TAnyFileIOCodec } from "./types/fileIO";
import { formToRequiredCodec, TAnyFormCodec } from "./types/form";

type TAnyRecordCodec = TAnyRequiredCodec | TAnyPartialCodec | TAnyRequiredFlatOverloadedCodec;
type TAnyPassthroughCodec = TAnyCustomCodec | TAnyOverloadCodec;

export type TCodecLensUtils<C extends TAnyCodec, R extends TAnyCodec> = {
    get: () => (r: TTypeOfNewDefault<R>) => TTypeOfNewDefault<C>;
    set: (s: TTypeOfNewDefault<C>) => (r: TTypeOfNewDefault<R>) => TTypeOfNewDefault<R>;
};

export type TCodecLens<C extends TAnyCodec, R extends TAnyCodec> =
    C extends
        TAnyRequiredCodec
        | TAnyPartialCodec
        | TAnyRequiredFlatOverloadedCodec
    ? { [K in keyof C["payload"]]: TCodecLens<C["payload"][K], R> } & TCodecLensUtils<C, R>
    : C extends
        TAnyArrayCodec
        | TAnyFixedLengthArrayCodec
        | TAnyNonEmptyArrayCodec
        | TAnyUniqueArrayCodec
    ? TCodecLensUtils<C, R> & TCodecLensArrayUtils<C["payload"]["codec"], R>
    : C extends TAnyCustomCodec | TAnyOverloadCodec
    ? TCodecLens<C["payload"]["codec"], R>
    : C extends TCodec<"IntersectionCodec", infer A, unknown, unknown>
        ? A extends Array<TAnyCodec>
            ? Omit<TUnionToIntersection<{
                [K in keyof A & number]: TCodecLens<A[K], R>
            }[number]>, "set" | "get" | "where"> & TCodecLensUtils<C, R>
            : never
        : C extends TCodec<"FormEditableCodec", infer I, unknown, unknown>
            ? I extends { codec: TAnyCodec }
                ? TCodecLensUtils<C, R> & { status: TCodecLens<typeof formStatus, R>; original: TCodecLens<I["codec"], R>; edited: TCodecLens<I["codec"], R>}
                : never
            : C extends TCodec<"FormIOCodec", infer I, unknown, unknown>
                ? I extends { input: TAnyCodec; output: TAnyCodec }
                    ? TCodecLensUtils<C, R> & { status: TCodecLens<typeof formStatus, R>; input: TCodecLens<I["input"], R>; output: TCodecLens<I["output"], R>}
                    : never
                : C extends TCodec<"FormCodec", infer I, unknown, unknown>
                    ? I extends { codec: TAnyCodec, children: TAnyCodec }
                        ? TCodecLensUtils<C, R> & { status: TCodecLens<typeof formStatus, R>; original: TCodecLens<I["codec"], R>; edited: TCodecLens<I["codec"], R>; children: TCodecLens<I["children"], R>}
                        : never
                    : C extends TCodec<"FileIOCodec", infer I, unknown, unknown>
                        ? I extends { input: TAnyCodec; output: TAnyCodec }
                            ? TCodecLensUtils<C, R> & { status: TCodecLens<typeof formStatus, R>; input: TCodecLens<I["input"], R>; output: TCodecLens<I["output"], R>;}
                            : never
                        : TCodecLensUtils<C, R>;

export type TCodecLensArrayUtils<C extends TAnyCodec, R extends TAnyCodec> = {
    where: (predicate: (item: TTypeOfNewDefault<C>) => boolean) => TCodecLens<C, R>;
};

const createNewDefaultLensRecursive = <
    Codec extends TAnyCodec,
    RootCodec extends TAnyCodec,
    Set extends (s: TTypeOfNewDefault<Codec>) => (r: TTypeOfNewDefault<RootCodec>) => TTypeOfNewDefault<RootCodec>,
    Get extends () => (r: TTypeOfNewDefault<RootCodec>) => TTypeOfNewDefault<Codec>
    /* eslint-disable-next-line */
>(codec: Codec, rootCodec: RootCodec, set: Set, get: Get): any => {
    const createDefaultUtils = () =>
        ({
            ...{set, get: () => (r: TTypeOfNewDefault<RootCodec>) => {
                const prev = get()(r);
                return prev === undefined ? codec.newDefault() : prev;
            }},
        });

    const createFormEditableUtils = (editableCodec: TAnyFormEditableCodec) =>
        createNewDefaultLensRecursive(
            formEditableToRequiredCodec(editableCodec),
            rootCodec,
            set as (s: unknown) => (r: TTypeOfNewDefault<RootCodec>) => TTypeOfNewDefault<RootCodec>,
            /* eslint-disable-next-line */
            get as any
        );

    const createFormIOUtils = (ioCodec: TAnyFormIOCodec) =>
        createNewDefaultLensRecursive(
            formIOToRequiredCodec(ioCodec),
            rootCodec,
            set as (s: unknown) => (r: TTypeOfNewDefault<RootCodec>) => TTypeOfNewDefault<RootCodec>,
            /* eslint-disable-next-line */
            get as any
        );

    const createFormUtils = (formCodec: TAnyFormCodec) =>
        createNewDefaultLensRecursive(
            formToRequiredCodec(formCodec),
            rootCodec,
            set as (s: unknown) => (r: TTypeOfNewDefault<RootCodec>) => TTypeOfNewDefault<RootCodec>,
            /* eslint-disable-next-line */
            get as any
        );
    
    const createFileIOUtils = (ioCodec: TAnyFileIOCodec) =>
        createNewDefaultLensRecursive(
            fileIOToRequiredCodec(ioCodec),
            rootCodec,
            set as (s: unknown) => (r: TTypeOfNewDefault<RootCodec>) => TTypeOfNewDefault<RootCodec>,
            /* eslint-disable-next-line */
            get as any
        );

    const createRecordTypeUtils = (recordCodec: TAnyRecordCodec) => ({
        ...{set, get},
        ...pipe(
            recordCodec.payload,
            record.mapWithIndex((key, innerCodec) =>
                createNewDefaultLensRecursive(
                    innerCodec,
                    rootCodec,
                    (inner: TTypeOfNewDefault<typeof innerCodec>) => (r: TTypeOfNewDefault<RootCodec>) => {
                        const getOuter = get()(r) as Record<string, unknown> | undefined;
                        const outer = getOuter === undefined
                            ? {}
                            : {...getOuter};
                        (outer as Record<string, unknown>)[key] = inner;
                        return set(outer as TTypeOfNewDefault<Codec>)(r);
                    },
                    () => (r: TTypeOfNewDefault<RootCodec>) => {
                        const prev = get()(r) as Record<string, unknown> | undefined;
                        return prev === undefined ? innerCodec.newDefault() : prev[key];
                    }
                )
            )
        ),
    });

    const createArrayCodecTypeUtils = (c: Codec) => {
        const innerCodec = (c as TAnyArrayCodec).payload.codec;
        return ({
            ...{
                set,
                get,
                where: (predicate: (val: TTypeOfNewDefault<typeof innerCodec>) => boolean) =>
                    createNewDefaultLensRecursive(
                        innerCodec,
                        rootCodec,
                        (s: TTypeOfNewDefault<typeof innerCodec>) => (r) => pipe(
                            get()(r) as Array<TTypeOfNewDefault<typeof innerCodec>> | undefined,
                            (getResult) => getResult ? getResult : [],
                            fptsArray.map((val) => {
                                if (predicate(val)) {
                                    return s;
                                }
                                return val;
                            }),
                            (arr: unknown) => set(arr as TTypeOfNewDefault<typeof c>)(r)
                        ),
                        () => (r) =>
                            pipe(
                                get()(r) as Array<TTypeOfNewDefault<typeof innerCodec>> | undefined,
                                (getResult) => getResult ? getResult : [],
                                fptsArray.findFirst(predicate),
                                option.fold(
                                    () => innerCodec.newDefault(),
                                    (val) => val
                                )
                            )
                    ),
            },
        });
    };

    const createPassthroughCodecTypeUtils = (c: TAnyPassthroughCodec) => createNewDefaultLensRecursive(
        c.payload.codec,
        rootCodec,
        set as (s: unknown) => (r: TTypeOfNewDefault<RootCodec>) => TTypeOfNewDefault<RootCodec>,
        get
    );

    const createIntersectionUtils = (c: TAnyIntersectionCodec) => ({
        ...{set, get},
        ...pipe(
            c.payload,
            fptsArray.map((cod) => createNewDefaultLensRecursive(cod, rootCodec, set as (s: unknown) => (r: TTypeOfNewDefault<RootCodec>) => TTypeOfNewDefault<RootCodec>, get)),
            fptsArray.map((lens) => {
                /* eslint-disable-next-line */
                const l = (lens as any);
                delete l.set;
                delete l.get;
                delete l.where;
                return lens;
            }),
            fptsArray.reduceRight({}, (val, acc) => ({
                ...acc,
                ...val,
            }))
        ),
    });

    switch (codec.type) {
        case "RequiredCodec":
            return createRecordTypeUtils(codec as TAnyRecordCodec);
        case "PartialCodec":
            return createRecordTypeUtils(codec as TAnyRecordCodec);
        case "RequiredFlatOverloadedCodec":
            return createRecordTypeUtils(codec as TAnyRecordCodec);
        case "ArrayCodec":
            return createArrayCodecTypeUtils(codec);
        case "NonEmptyArrayCodec":
            return createArrayCodecTypeUtils(codec);
        case "FixedLengthArrayCodec":
            return createArrayCodecTypeUtils(codec);
        case "UniqueArrayCodec":
            return createArrayCodecTypeUtils(codec);
        case "CustomCodec":
            return createPassthroughCodecTypeUtils(codec as TAnyPassthroughCodec);
        case "OverloadCodec":
            return createPassthroughCodecTypeUtils(codec as TAnyPassthroughCodec);
        case "IntersectionCodec":
            /* eslint-disable-next-line */
            return createIntersectionUtils(codec as TAnyIntersectionCodec);
        case "FormEditableCodec":
            /* eslint-disable-next-line */
            return createFormEditableUtils(codec as TAnyFormEditableCodec);
        case "FormIOCodec":
            /* eslint-disable-next-line */
            return createFormIOUtils(codec as TAnyFormIOCodec);
        case "FormCodec":
            /* eslint-disable-next-line */
            return createFormUtils(codec as TAnyFormCodec);
        case "FileIOCodec":
            /* eslint-disable-next-line */
            return createFileIOUtils(codec as TAnyFileIOCodec);
        default:
            return createDefaultUtils();
    }
};

export const createNewDefaultLens = <C extends TAnyCodec>(codec: C): TCodecLens<C, C> => createNewDefaultLensRecursive(codec, codec, (s) => () => util.isObject(s) ? ({...(s as Record<string, unknown>)}) as TTypeOfNewDefault<C> : s, () => (r) => r);
