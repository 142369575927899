import React from "react";

export default (props: React.SVGAttributes<SVGElement>) => <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g clipPath="url(#clip0_5216:25530)">
<g clipPath="url(#clip1_5216:25530)">
<path d="M1.25155 44C0.964724 44 0.683536 43.8878 0.473574 43.6778C0.171349 43.3755 0.0715926 42.9257 0.217618 42.5241L4.61762 30.4241C4.67255 30.273 4.75993 30.1359 4.87357 30.0222L34.5736 0.322163C35.0031 -0.107388 35.6996 -0.107388 36.1292 0.322163L43.8292 8.02216C44.2588 8.45178 44.2588 9.14822 43.8292 9.57784L14.1292 39.2778C14.0155 39.3915 13.8783 39.4789 13.7273 39.5339L1.62727 43.9339C1.50476 43.9784 1.37757 44 1.25155 44ZM6.60181 31.4053L3.09075 41.0607L12.7462 37.5497L41.4957 8.8L35.3514 2.65561L6.60181 31.4053Z" fill="black"/>
</g>
</g>
<defs>
<clipPath id="clip0_5216:25530">
<rect width="44" height="44" fill="white"/>
</clipPath>
<clipPath id="clip1_5216:25530">
<rect width="44" height="44" fill="white" transform="translate(0.151855)"/>
</clipPath>
</defs>
</svg>
;
