import React from "react";
import UnstyledInputGeneralComponent, { IInputGeneralComponentProps } from "../../UnstyledInputGeneralComponent/UnstyledInputGeneralComponent";

type TCRMInputGeneralComponent = {
    style?: React.CSSProperties;
} & IInputGeneralComponentProps;

export class CRMInputGeneralComponent extends React.Component<React.PropsWithChildren<TCRMInputGeneralComponent>> {
    public render (): JSX.Element {
        return (
            <UnstyledInputGeneralComponent
                {...this.props}
                className={`
                    crm-input-general
                    crm-input-general--${this.props.disabled ? 'disabled' : 'enabled'}
                    crm-input-general--${this.props.displayError ? 'error' : 'no-error'}
                `}
            />
        );
    }
}

export default CRMInputGeneralComponent;
