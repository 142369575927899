import React from "react";

export default (props: React.SVGAttributes<SVGElement>) => <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g clipPath="url(#clip0_10233_45249)">
<path d="M23.0998 44H20.8998C20.2923 44 19.7998 43.5076 19.7998 42.9C19.7998 42.2925 20.2923 41.8 20.8998 41.8H23.0998C23.7073 41.8 24.1998 42.2925 24.1998 42.9C24.1998 43.5076 23.7073 44 23.0998 44Z" fill="black"/>
<path d="M21.9998 0C14.7213 0 8.7998 5.9215 8.7998 13.2C8.7998 17.8512 11.0298 21.6212 11.1247 21.7794C11.7103 22.7555 12.5426 24.4804 12.9423 25.5462L14.6973 30.2263C14.9634 30.9357 15.4743 31.5746 16.1086 32.0602C15.6653 32.6221 15.3998 33.3304 15.3998 34.1C15.3998 34.9445 15.7191 35.7157 16.2428 36.3C15.7191 36.8843 15.3998 37.6555 15.3998 38.5C15.3998 40.3197 16.8802 41.8 18.6998 41.8H25.2998C27.1195 41.8 28.5998 40.3197 28.5998 38.5C28.5998 37.6555 28.2805 36.8843 27.7569 36.3C28.2805 35.7157 28.5998 34.9445 28.5998 34.1C28.5998 33.3304 28.3343 32.622 27.8909 32.0601C28.5251 31.5745 29.036 30.9356 29.3018 30.2259L31.0557 25.546C31.4551 24.4804 32.2874 22.7558 32.8732 21.7801C32.9683 21.6218 35.1998 17.8513 35.1998 13.2C35.1998 5.9215 29.2783 0 21.9998 0ZM25.2998 39.6H18.6998C18.0933 39.6 17.5998 39.1065 17.5998 38.5C17.5998 37.8935 18.0933 37.4 18.6998 37.4H25.2998C25.9063 37.4 26.3998 37.8935 26.3998 38.5C26.3998 39.1065 25.9063 39.6 25.2998 39.6ZM26.3998 34.1C26.3998 34.7065 25.9063 35.2 25.2998 35.2H18.6998C18.0933 35.2 17.5998 34.7065 17.5998 34.1C17.5998 33.4935 18.0933 33 18.6998 33H25.2998C25.9063 33 26.3998 33.4935 26.3998 34.1ZM30.9871 20.6476C30.3341 21.7352 29.4408 23.5863 28.9957 24.7741L27.2418 29.4539C26.9778 30.1584 26.0521 30.8 25.2998 30.8H18.6998C17.9473 30.8 17.0215 30.1583 16.7573 29.4538L15.0023 24.7737C14.5569 23.5863 13.6638 21.7352 13.0112 20.6475C12.991 20.6141 10.9998 17.2485 10.9998 13.2C10.9998 7.1346 15.9344 2.2 21.9998 2.2C28.0653 2.2 32.9998 7.1346 32.9998 13.2C32.9998 17.224 31.0063 20.6154 30.9871 20.6476Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_10233_45249">
<rect width="44" height="44" fill="white" transform="matrix(0 1 -1 0 44 0)"/>
</clipPath>
</defs>
</svg>
;
