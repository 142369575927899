import { inherit, TAnyInheritedCodec, TInheritedCodec } from "../codec";
import { option } from "fp-ts/lib";
import { dateTime, TAnyDateTimeCodec } from "./dateTime";

export type TAnyDeferDateTimeCodec = TAnyInheritedCodec<"DeferDateTimeCodec", { codec: TAnyDateTimeCodec }>;

export const deferDateTime = (): TInheritedCodec<"DeferDateTimeCodec", {codec: TAnyDateTimeCodec}> => inherit(
    "DeferDateTimeCodec",
    {codec: dateTime()},
    () => option.none,
    () => option.none,
);
