import { TTypeOfCodec } from "../../shared/src/codecs/codec";
import { union } from "../../shared/src/codecs/types/union";
import { literal } from "../../shared/src/codecs/types/literal";
import * as util from "../../shared/src/util";

export const ListingEnquiryPurchasingIntent1 = union([
    literal("unknown"),
    literal("to_rent"),
    literal("to_live"),
    literal("to_live_sometimes"),
    literal("to_sell"),
    literal("for_someone_else"),
]);
type TListingEnquiryPurchasingIntent1 = TTypeOfCodec<typeof ListingEnquiryPurchasingIntent1>;

export const ListingEnquiryPurchasingIntent1_displayName = (p: TListingEnquiryPurchasingIntent1): string => {
    switch (p) {
        case "to_rent":
            return "To rent";
        case "to_live":
            return "To live";
        case "to_live_sometimes":
            return "To live sometimes";
        case "to_sell":
            return "To sell";
        case "for_someone_else":
            return "For someone else";
        case "unknown":
            return "Unknown";
        default:
            return util.requireExhaustive(p);
    }
};
