import { TBlocksParams, TParamsObject, TQueryParams, TRouteActionType } from "./routerTypes";

export class Routes {
    active: TRouteActionType = "VIEW_LISTING";
    params: TParamsObject<TRouteActionType> = {};
    queryParams: TQueryParams = {
        ["VIEW_ADMIN"]: {},
        ["VIEW_LISTING"]: {},
        ["VIEW_NOT_FOUND"]: {},
        ["VIEW_LISTING_BOOK_VIEWING"]: {},
        ["VIEW_LISTING_BOOK_VIEWING_ADDITIONAL_QUESTIONS"]: {},
        ["VIEW_LISTING_BOOK_VIEWING_SUCCESS"]: {},
        ["VIEW_CRM_INDEX"]: {},
        ["VIEW_CRM_SEARCH"]: {},
        ["VIEW_CRM_LISTING"]: {
            visible_sections: "",
            visible_tab: "",
        },
        ["VIEW_CRM_LISTING_PERFORMANCE"]: {},
        ["VIEW_CRM_LISTINGS"]: {},
        ["VIEW_CRM_PARTY"]: {},
        ["VIEW_CRM_ENQUIRY"]: {},
        ["VIEW_CRM_ADD_ENQUIRY"]: {},
        ["VIEW_CRM_LEGAL_CASE"]: {
            visible_tab: "",
        },
        ["VIEW_CRM_LEGAL_NEW_CASES"]: {},
        ["VIEW_CRM_LEGAL_TITLE_CHECKS"]: {},
        ["VIEW_CRM_LEGAL_INBOX"]: {},
        ["VIEW_CRM_LEGAL_INTRODUCERS"]: {},
        ["VIEW_CRM_LEGAL_CASE_AUTO_ASSIGNMENT"]: {},
        ["VIEW_CRM_LEGAL_CLIENT_COMPANY_PARTNERS"]: {},
        ["VIEW_CRM_LEGAL_REPORTS"]: {},
        ["VIEW_PROBATE_PANEL_ENQUIRY_PAGE"]: {},
        ["VIEW_PROBATE_PROPERTY_REPORT_PAGE"]: {
            introducer_id: "",
        },
        ["VIEW_PROBATE_QUOTE_PAGE"]: {},
        ["VIEW_PROBATE_INTERESTED_PAGE"]: {},
        ["VIEW_PROBATE_GDPR_PAGE"]: {},
        ["VIEW_PROBATE_TNCS_PAGE"]: {},
        ["VIEW_BUYER_ENQUIRY"]: {},
        ["VIEW_CLIENT_CASE"]: {
            errorCode: "",
            userCaseId: "",
        },
        ["VIEW_AUTH_2FA"]: {
            redirect_url: "",
        },
        ["VIEW_BUYER_EMAIL_VERIFICATION_CONFIRMATION"]: {},
        ["VIEW_MAGIC_LINK_RESEND"]: {
            redirect_url: "",
        },
        ["VIEW_MAGIC_LINK_RESENT"]: {
            redirect_url: "",
        },
        ["VIEW_CRM_TRIAGE"]: {},
        ["VIEW_CRM_PROBATE_HELPLINE_CALLBACKS"]: {},
        ["VIEW_CRM_LEGAL_QUOTE_RATES"]: {},
        ["VIEW_CRM_LEGAL_MARKETING_CAMPAIGNS"]: {},
        ["VIEW_PUBLIC_LEGAL_QUOTE"]: {
            introducer_id: "",
            view: "",
        },
        ["VIEW_CRM_BLOCKS_TEST"]: {},
    };
    blockParams: TBlocksParams = {
        VIEW_CRM_LEGAL_CASE: [
            {
                type: "case_details",
                meta: {},
            },
            {
                type: "case_overview",
                meta: {},
            },
            {
                type: "case_roadblocks",
                meta: {},
            },
        ]
    };
}