import { TTypeOfCodec } from "../../../shared/src/codecs/codec";
import { form, TFormCodec } from "../../../shared/src/codecs/types/form";
import { required } from "../../../shared/src/codecs/types/required";
import { CaseLedgerEntry1, TCaseLedgerEntry1Codec } from "../CaseLedger";
import { TEmptyObjectCodec } from "../EmptyObject";

export const CaseLedgerEntryForm: TFormCodec<
    TCaseLedgerEntry1Codec,
    TEmptyObjectCodec
> = form(
    CaseLedgerEntry1,
    required({}),
);
export type TCaseLedgerEntryFormCodec = typeof CaseLedgerEntryForm;
export type TCaseLedgerEntryForm = TTypeOfCodec<TCaseLedgerEntryFormCodec>;
