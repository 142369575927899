import * as t from "io-ts";
import { nonEmptyString } from "../../shared/src/validation/basic/nonEmptyString";
import { object } from "../../shared/src/validation/create/object";

export const codec = t.partial({
    name: t.string,
});

export type T = t.TypeOf<typeof codec>;

export const validator = object({
    name: nonEmptyString,
});
