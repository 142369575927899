import React from "react";

type TFontSizeProps = {
    size: string | number;
    lineHeight?: string | number;
};

/**
 * @deprecated Use @see Text
 */
export const FontSize = (props: React.PropsWithChildren<TFontSizeProps>): JSX.Element => (
    <span
        style={{
            fontSize: props.size,
            lineHeight: props.lineHeight || "1"
        }}
    >
        {props.children}
    </span>
);
