import { union } from "../../shared/src/codecs/types/union";
import { literal } from "../../shared/src/codecs/types/literal";

export const ListingWindowType = union([
    literal("single_glazing"),
    literal("double_glazing"),
    literal("triple_glazing"),
    literal("sash"),
    literal("velux"),
]);
