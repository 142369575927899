import { TCodec } from "../codec";
import { TError, errorConstants } from "../errors";
import { either } from "fp-ts/lib";
import { DateTime } from "luxon";
import { pipe } from "fp-ts/lib/function";

export type TAnyDateTimeCodec = TCodec<"DateTimeCodec", undefined, string, string>;

export const decode = (input: unknown): either.Either<TError, string> => {
    const luxon =  DateTime.fromISO(typeof input === "string" ? input : "").toUTC();
    const formatted = `${luxon.setZone("UTC").toFormat("yyyy-MM-dd'T'HH:mm:ss.SSS")}Z`;
    return ! luxon.isValid
        ? either.left([[errorConstants.UTC_DATETIME_VALIDATION, ""]])
        : either.right(formatted);
};

export const dateTime = (): TAnyDateTimeCodec => ({
    type: "DateTimeCodec",
    payload: undefined,
    decode,
    decodeNewDefault: (input: unknown): either.Either<TError, string> =>
         pipe(
            input === ""
            ? either.right("")
            : decode(input)
        ),
    newDefault: () => "",
});
