export const getQueryParams = (searchString: string): Record<string, string> => {
    const qd: Record<string, string> = {};
    if (searchString) {
        searchString
            .substr(1)
            .split("&")
            .forEach(
                (item) => {
                    const s = item.split("="),
                        k = s[0],
                        v = s[1] && decodeURIComponent(s[1]);

                    qd[k] = v ? v : "";
                }
            );
    }
    return qd;
};