import { TTypeOfCodec } from "../../shared/src/codecs/codec";
import { date } from "../../shared/src/codecs/types/date";
import { dateTime } from "../../shared/src/codecs/types/dateTime";
import { required } from "../../shared/src/codecs/types/required";
import { string } from "../../shared/src/codecs/types/string";
import { uuid } from "../../shared/src/codecs/types/uuid";

export const CaseChargeLegalFile1 = required({
    id: uuid(),
    cases_charges_id: uuid(),
    download_token: string(),
    expires_at: date(),
    requested_at: dateTime(),
});
export type TCaseChargeLegalFile1Codec = typeof CaseChargeLegalFile1;

export const CaseChargeLegalFile2 = required({
    id: uuid(),
    beneficiary: string(),
    download_token: string(),
    expires_at: date(),
});
export type TCaseChargeLegalFile2Codec = typeof CaseChargeLegalFile2;
export type TCaseChargeLegalFile2 = TTypeOfCodec<TCaseChargeLegalFile2Codec>;
