import React from "react";

export default (props: React.SVGAttributes<SVGElement>) => 
    <svg 
        width="24" 
        height="24"
        viewBox="0 -960 960 960"
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="m356-160-56-56 180-180 180 180-56 56-124-124-124 124Zm124-404L300-744l56-56 124 124 124-124 56 56-180 180Z"/>
    </svg>
;
