import { TAnyCodec, TCodec, TTypeOfCodec, TTypeOfNewDefault } from "../codec";
import { either, array } from "fp-ts/lib";
import { pipe } from "fp-ts/lib/function";
import { TError } from "../errors";
import { NonEmptyArray } from "fp-ts/lib/NonEmptyArray";
import { isObject } from "../../util";

export type TUnionToIntersection<U> =
  (U extends unknown ? (k: U) => void : never) extends ((k: infer I) => void) ? I : never;

export type TAnyIntersectionCodec = TCodec<
    "IntersectionCodec",
    NonEmptyArray<TAnyCodec>,
    unknown,
    unknown
>;

export type TIntersectionCodec<P extends NonEmptyArray<TAnyCodec>> = TCodec<
    "IntersectionCodec",
    P,
    TUnionToIntersection<TTypeOfCodec<P[number]>>,
    TUnionToIntersection<TTypeOfNewDefault<P[number]>>
>;

export const intersection = <
    P extends NonEmptyArray<TAnyCodec>,
    D extends TUnionToIntersection<TTypeOfCodec<P[number]>>,
    N extends TUnionToIntersection<TTypeOfNewDefault<P[number]>>
>(payload: P): TCodec<"IntersectionCodec", P, D, N> => ({
    type: "IntersectionCodec",
    payload,
    decode: (input: unknown): either.Either<TError, D> =>
        pipe(
            payload,
            array.map((codec) => codec.decode(input)),
            array.separate,
            (s) =>
                s.left.length
                ? either.left(array.flatten(s.left)) as either.Either<TError, D>
                : pipe(
                    mergeDecoded(s.right),
                    either.right
                ) as either.Either<TError, D>
        ),
    decodeNewDefault: (input: unknown): either.Either<TError, N> =>
        pipe(
            payload,
            array.map((codec) => codec.decodeNewDefault(input)),
            array.separate,
            (s) =>
                s.left.length
                ? either.left(array.flatten(s.left)) as either.Either<TError, N>
                : pipe(
                    mergeDecoded(s.right),
                    either.right
                ) as either.Either<TError, N>
        ),
    newDefault: (): N =>
        pipe(
            payload,
            array.reduceRight(
                {},
                (item, acc) => ({
                    ...item.newDefault() as Record<string, unknown>,
                    ...acc,
                })
            )
        ) as N,
});

const mergeDecoded = (items: Array<unknown>): unknown => { // NonEmptyArray of unknown, but garunteed to all be the same primitive type
    if (isObject(items[0])) {
        return pipe(
            items,
            array.reduceRight({}, (item, acc) =>
                ({ ...item as {}, ...acc })
            ),
        );
    }

    if (Array.isArray(items[0])) {
        const arrayDepth = getArrayDepth(items[0]);
        const flattenedArray = pipe(
            array.range(1, arrayDepth),
            array.reduceRight(items as Array<Array<unknown>>, (i, acc: Array<Array<unknown>>) =>
                array.flatten(acc) as Array<Array<unknown>>
            )
        );
        const argCount = flattenedArray.length / items.length;
        const groupedArray = pipe(
            array.range(1, argCount),
            array.map((val) =>
                flattenedArray.filter((item, i) => (i + val) % argCount === 0)
            ),
            array.reverse
        );
        const mergedValues = groupedArray.map((val) => mergeDecoded(val));
        const renested = arrayDepth - 1 > 0
        ? pipe(
            array.range(1, arrayDepth - 1),
            array.reduceRight(mergedValues, (i, acc) => acc.map((v) => [v]))
        )
        : mergedValues;
        return renested;
    }

    return items[0]; // If it isn't an object or an array, then the decoded items must all be the same, so just take the first one
};

const getArrayDepth = (a: unknown): number => Array.isArray(a) && a[0] ? Math.max(...a.map(getArrayDepth)) + 1 : 0;
