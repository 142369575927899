import React from "react";

export default (props: React.SVGAttributes<SVGElement>) => <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g clipPath="url(#clip0_5817_26134)">
<path d="M0.0996448 26.3999C-0.16353 26.3999 -0.42753 26.3061 -0.637974 26.1156C-1.08849 25.708 -1.12328 25.0124 -0.715661 24.5619L18.7083 3.09331C19.3121 2.42596 20.126 2.05835 21 2.05835C21.8739 2.05835 22.6878 2.42589 23.2916 3.09331L29.8143 10.3026L29.8 7.70596C29.7984 7.41315 29.9136 7.13183 30.12 6.9242C30.3265 6.71665 30.6072 6.59991 30.9 6.59991H35.3C35.9075 6.59991 36.4 7.09243 36.4 7.69991V17.5814L42.7156 24.562C43.1232 25.0125 43.0885 25.7081 42.6379 26.1156C42.1874 26.5233 41.4918 26.4885 41.0843 26.0379L34.4843 18.7433C34.3012 18.541 34.1999 18.278 34.1999 18.0053V8.79991H32.006L32.0302 13.1695C32.0327 13.6255 31.7536 14.0358 31.3285 14.2009C30.9033 14.3659 30.4204 14.2517 30.1146 13.9135L21.6603 4.56924C21.4788 4.36869 21.2443 4.25835 21 4.25835C20.7556 4.25835 20.5211 4.36876 20.3396 4.56931L0.915707 26.0379C0.698595 26.278 0.39967 26.3999 0.0996448 26.3999V26.3999Z" fill="black"/>
<path d="M33.1001 44H24.3001C23.6926 44 23.2001 43.5075 23.2001 42.9V35.2H18.8001V42.9C18.8001 43.5075 18.3076 44 17.7001 44H8.9001C7.08049 44 5.6001 42.5196 5.6001 40.7V23.1C5.6001 22.4925 6.09262 22 6.7001 22C7.30757 22 7.8001 22.4925 7.8001 23.1V40.7C7.8001 41.3065 8.29358 41.8 8.9001 41.8H16.6001V34.1C16.6001 33.4925 17.0926 33 17.7001 33H24.3001C24.9076 33 25.4001 33.4925 25.4001 34.1V41.8H33.1001C33.7066 41.8 34.2001 41.3065 34.2001 40.7V23.1C34.2001 22.4925 34.6926 22 35.3001 22C35.9076 22 36.4001 22.4925 36.4001 23.1V40.7C36.4001 42.5196 34.9197 44 33.1001 44Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_5817_26134">
<rect width="44" height="44" fill="white"/>
</clipPath>
</defs>
</svg>
;
