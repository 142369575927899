import { filterableWithIndex, function as fptsFunction, array as fptsArray, field, eq, identity, option } from "fp-ts";
import { pipe } from "fp-ts/lib/pipeable";
import { record as extRecord } from "./record";
import { TTuple, tuple as extTuple } from "./tuple";

export const updateWhere = <T>(predicate: fptsFunction.Predicate<T>, update: fptsFunction.Endomorphism<T>) =>
    (a: Array<T>): Array<T> =>
         pipe(
            a,
            fptsArray.map((item) => predicate(item) ? update(item) : item)
        );

const updateWhereWithIndex = <T>(predicate: filterableWithIndex.PredicateWithIndex<number, T>, update: fptsFunction.Endomorphism<T>) =>
    (a: Array<T>): Array<T> =>
         pipe(
            a,
            fptsArray.mapWithIndex((index, item) => predicate(index, item) ? update(item) : item)
        );

const getHighestNumber = (numbers: Array<number>): number =>
    numbers.reduce((old, incoming) => Math.max(old, incoming));

const deleteValue = <A>(value: A, array: Array<A>): Array<A> => pipe(
    array,
    deleteWhere((member) => member === value)
);

export const deleteWhere = <A>(predicate: (member: A) => boolean) => 
    (array: Array<A>): Array<A> =>
        pipe(
            array,
            fptsArray.findIndex<A>(predicate),
            option.fold<number, number>(
                () => -1,
                identity.flatten,
            ),
            (index) => fptsArray.deleteAt(index)(array),
            option.fold(
                () => array,
                identity.flatten
            )
        );

export const updateWhereOr = <A>(
    predicate: (member: A) => boolean,
    onSome: fptsFunction.Endomorphism<A>,
    onNone: (set: Array<A>) => Array<A>,  
) => 
    (array: Array<A>): Array<A> =>
        pipe(
            array,
            fptsArray.findFirst<A>(predicate),
            option.fold(
                () => onNone(array),
                () => updateWhere(predicate, onSome)(array)
            )
        );

type TSplitTuple<A> = TTuple<Array<A>, Array<A>>;
const splitWhere = <A>(predicate: (member: A) => boolean) =>
    (array: Array<A>): TSplitTuple<A> =>
         pipe(
            array,
            fptsArray.reduce<A, TSplitTuple<A>>(
                [[], []],
                (result, member) =>
                    predicate(member) ?
                        extTuple.updateHead<TSplitTuple<A>, Array<A>>(result[0].concat(member))(result)
                        : extTuple.updateTail<TSplitTuple<A>, Array<A>>(result[1].concat(member))(result)
            )
        );

const indexOfHighestValueMatch =
    <T extends Record<string, unknown>>(
        comparingValues: Array<keyof T>,
        matchingObject: T,
        searchArray: Array<T>,
    ): number =>
         pipe(
            searchArray,
            fptsArray.map(extRecord.compareRecordFieldsAndGetMatchScores(comparingValues, matchingObject)),
            fptsArray.map(fptsArray.reduce<number, number>(0, field.fieldNumber.add)),
            (matchScores) => fptsArray.findIndex<number>((score) => eq.eqNumber.equals(score, getHighestNumber(matchScores)))(matchScores),
            option.fold(
                () => -1,
                identity.flatten,
            ),
        );

const highestValueMatch =
    <T extends Record<string, unknown>>(
        comparingValues: Array<keyof T>,
        matchingObject: T,
        searchArray: Array<T>,
    ): T =>
         pipe(
            indexOfHighestValueMatch<T>(comparingValues, matchingObject, searchArray),
            option.fromPredicate<number>((index) => index > -1 ),
            option.fold(
                () => matchingObject,
                (index) => searchArray[index],
            )
        );

const allButhighestValueMatch =
    <T extends Record<string, unknown>>(
        comparingValues: Array<keyof T>,
        matchingObject: T,
        searchArray: Array<T>,
    ): Array<T> =>
         pipe(
            indexOfHighestValueMatch<T>(comparingValues, matchingObject, searchArray),
            option.fromPredicate<number>((index) => index > -1 ),
            option.fold(
                () => searchArray,
                (matchingIndex) => searchArray.filter((object, index) => index !== matchingIndex),
            )
        );

export const join = (joiner?: string) => (givenArray: Array<unknown>): string => givenArray.join(joiner || "");

export const allMatchPredicate = <V extends unknown>(predicate: (value: V) => boolean) =>
    (value: Array<V>): boolean => {
        if (value.length === 0) {
            return false;
        }
        return pipe(
            value,
            fptsArray.filter((member) => !predicate(member)),
            (filteredArray) => filteredArray.length === 0
        )
    }
;

export const anyMatchPredicate = <T extends unknown>(predicate: (value: T) => boolean) =>
    (value: Array<T>): boolean =>
        pipe(
            value,
            fptsArray.filter((member) => predicate(member)),
            (filteredArray) => filteredArray.length > 0
        )
;

const includesAny = <T>(matchingArray: Array<T>) => (givenArray: Array<T>): boolean =>
    pipe(
        givenArray,
        fptsArray.filter((member) => matchingArray.includes(member)),
        (filteredArray) => filteredArray.length > 0
    )
;

const includesAll = <T>(matchingArray: Array<T>) => (givenArray: Array<T>): boolean =>
    pipe(
        givenArray,
        includesAny,
        (filteredArray) => filteredArray.length === matchingArray.length
    )
;

const findFirstIndex = <T extends unknown>(predicate: (member: T) => boolean ) =>
    (array: Array<T>): number =>
        pipe(
            array,
            fptsArray.reduceWithIndex<T, number>(
                -1,
                (index, reduction, member) =>
                    reduction === -1 && predicate(member) ? index : reduction
            )
        )
;

const mergeOnTop = <T extends unknown>(mergeBase: Array<T>) =>
    (mergingArray: Array<T>): Array<T> =>
        pipe(
            mergeBase,
            fptsArray.mapWithIndex((index, value) =>
                mergingArray[index] ?
                    mergingArray[index] :
                    value
            )
        )
;

const mapFirst = <T extends unknown>(predicate: (member: T) => boolean, map: (member: T) => T) => 
    (mappingArray: Array<T>): Array<T> =>
        pipe(
            mappingArray,
            fptsArray.reduce<T, { hasRun: boolean, result: Array<T> }>(
                {
                    hasRun: false,
                    result: [],
                },
                (sum, member) => {
                    if (predicate(member) && !sum.hasRun) {
                        return {
                            hasRun: true,
                            result: sum.result.concat(map(member))
                        };
                    }

                    return {
                        ...sum,
                        result: sum.result.concat(member)
                    };
                }
            ),
            (sum) => sum.result 
        )
;

export const ifTrueInject = <T extends unknown>(predicate: () => boolean, value: T) => 
    (injectingArray: Array<T>): Array<T> => {
        if (predicate() && value["constructor"] === Array) {
            return injectingArray.concat([value]);
        }
        if (predicate() && value["constructor"] !== Array) {
            return injectingArray.concat(value);
        }
        return injectingArray;
    };

export const inject = <T extends unknown>(value: T) => 
    (injectingArray: Array<T>): Array<T> => injectingArray.concat(value)
;

export const contains = <T extends unknown>(matchingValue: T) => 
    (comparedArray: Array<T>) => 
        fptsArray.some((comparedValue) => comparedValue === matchingValue)(comparedArray)
;

export const concat = <T extends unknown>(arrayA: Array<T>) =>
    (arrayB: Array<T>): Array<T> => 
        arrayA.concat(arrayB)
;

export const array = {
    updateWhere,
    updateWhereWithIndex,
    indexOfHighestValueMatch,
    highestValueMatch,
    allButhighestValueMatch,
    deleteValue,
    deleteWhere,
    join,
    splitWhere,
    allMatchPredicate,
    includesAny,
    includesAll,
    findFirstIndex,
    mergeOnTop,
    mapFirst,
    anyMatchPredicate,
    ifTrueInject,
    inject,
    contains,
    updateWhereOr,
    concat
};
