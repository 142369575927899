import { form, TFormCodec } from "../../../shared/src/codecs/types/form";
import { TTypeOfCodec } from "../../../shared/src/codecs/codec";
import { TTitleCheckEditable, TTitleCheckEditableCodec, TTitleCheckReadOnly, TTitleCheckReadOnlyCodec, TitleCheckEditable, TitleCheckReadOnly } from "../TitleCheck";
import { required, TRequiredCodec } from "../../../shared/src/codecs/types/required";
import { CasePropertyNewTitleIssueForm, TCasePropertyNewTitleIssueFormCodec } from "./CasePropertyNewTitleIssueForm";
import { CasePropertyTitleIssue, TCasePropertyTitleIssueCodec } from "../CasePropertyTitleIssue";
import { array, TArrayCodec } from "../../../shared/src/codecs/types/array";
import { CasePropertyDeleteTitleIssueForm, TCasePropertyDeleteTitleIssueFormCodec } from "./CasePropertyDeleteTitleIssueForm";
import { alwaysFalse, alwaysTrue, notEmptyString, notUnknown } from "../../../shared/src/predicates";
import { DateTime } from "luxon";
import { pipe } from "fp-ts/lib/function";
import { record } from "fp-ts";
import { union } from "../../../shared/src/codecs/types/union";
import { literal } from "../../../shared/src/codecs/types/literal";

export const CaseTitleCheckForm: TFormCodec<
    TTitleCheckEditableCodec,
    TRequiredCodec<{
        read_only: TTitleCheckReadOnlyCodec,
        title_check_issues: TArrayCodec<TCasePropertyTitleIssueCodec>,
        new_title_check_issue_form: TCasePropertyNewTitleIssueFormCodec,
        delete_title_check_issue_form: TCasePropertyDeleteTitleIssueFormCodec,
    }>
> = form(
    TitleCheckEditable,
    required({
        read_only: TitleCheckReadOnly,
        title_check_issues: array(CasePropertyTitleIssue),
        new_title_check_issue_form: CasePropertyNewTitleIssueForm,
        delete_title_check_issue_form: CasePropertyDeleteTitleIssueForm,
    }),
);
export type TCaseTitleCheckFormCodec = typeof CaseTitleCheckForm;
export type TCaseTitleCheckForm = TTypeOfCodec<TCaseTitleCheckFormCodec>;

type EditableCaseTitleCheckFieldsMeta = {
    [K in keyof TCaseTitleCheckForm["edited"]]: {
        shouldShow: (f: TTitleCheckEditable & TTitleCheckReadOnly) => boolean;
        isComplete: (val: TCaseTitleCheckForm["edited"][K]) => boolean;
    };
};


const isLeaseholdOrCommonhold = (p: TTitleCheckReadOnly) => p.tenure === "leasehold" || p.tenure === "commonhold"

export const editableCaseTitleCheckFieldsConfig: EditableCaseTitleCheckFieldsMeta = {
    id: {
        shouldShow: alwaysFalse,
        isComplete: alwaysFalse,
    },
    last_transfer_date: {
        shouldShow: (f) => f.is_registered === true,
        isComplete: (v) => v !== null,
    },
    title_or_deed_obtained: {
        shouldShow: alwaysTrue,
        isComplete: notUnknown,
    },
    all_documents_referred_to_in_titles_and_deeds_obtained: {
        shouldShow: alwaysTrue,
        isComplete: notUnknown
    },
    missing_deed_or_title_documents_description: {
        shouldShow: (f) => f.all_documents_referred_to_in_titles_and_deeds_obtained === "some_missing",
        isComplete: notEmptyString,
    },
    can_epitome_of_title_be_drafted: {
        shouldShow: (f) => f.is_registered === false,
        isComplete: notUnknown, // reconsititue if false
    },
    reconstituting_title: {
        shouldShow: (f) => f.title_or_deed_obtained === "no" || (f.is_registered === false && f.can_epitome_of_title_be_drafted === "no"),
        isComplete: notUnknown, 
    },
    title_defects_that_require_indemnification: {
        shouldShow: alwaysTrue,
        isComplete: notUnknown,
    },
    title_defects_description: {
        shouldShow: (f) => f.title_defects_that_require_indemnification === "yes",
        isComplete: notEmptyString,
    },
    deed_of_variation_needed: {
        shouldShow: alwaysTrue,
        isComplete: notUnknown,
    },
    client_and_lender_has_approved_title_owned_less_than_6_months: {
        shouldShow: (f) => f.last_transfer_date !== null && DateTime.utc().minus({months: 6}).toSeconds() < DateTime.fromISO(f.last_transfer_date).toUTC().toSeconds(), // LATER
        isComplete: notUnknown, // LATER allow no but make sure another roadblock takes it
    },
    registered_proprietors_names_match_sellers: {
        shouldShow: alwaysTrue,
        isComplete: notUnknown,
    },
    initial_reason_proprietors_names_do_not_match_sellers: {
        shouldShow: (f) => f.registered_proprietors_names_match_sellers === "no",
        isComplete: notEmptyString,
    },
    properties_more_than_10_years_old: {
        shouldShow: alwaysTrue,
        isComplete: notUnknown,
    },
    new_build_warranties_provided: {
        shouldShow: (f) => f.properties_more_than_10_years_old === "no",
        isComplete: notUnknown // LATER another roadblock
    },
    right_of_way_acceptable: {
        shouldShow: alwaysTrue,
        isComplete: notUnknown,
    },
    right_of_way_not_acceptable_reason: {
        shouldShow: (f) => f.right_of_way_acceptable === "no",
        isComplete: notEmptyString,
    },
    is_flying_freehold: {
        shouldShow: (f) => f.tenure === "freehold",
        isComplete: notUnknown,
    },
    flying_freehold_sufficient_rights_or_indemnity: {
        shouldShow: (f) => f.is_flying_freehold === "yes",
        isComplete: notUnknown
    },
    mines_minerals_excepted: {
        shouldShow: alwaysTrue,
        isComplete: notUnknown,
    },
    mines_minerals_excepted_relief: {
        shouldShow: (f) => f.mines_minerals_excepted === "yes",
        isComplete: notUnknown,
    },
    leasehold_terms_sufficient_with_lender_and_client: {
        shouldShow: isLeaseholdOrCommonhold,
        isComplete: notUnknown,
    },
    annual_ground_rent_below_250_or_1000_for_london: {
        shouldShow: isLeaseholdOrCommonhold,
        isComplete: notUnknown,
    },
    clause_cap_ground_rent_below_250_or_1000_for_london:{
        shouldShow: (f) => isLeaseholdOrCommonhold(f) && f.annual_ground_rent_below_250_or_1000_for_london === "no",
        isComplete: notUnknown, // LATER another roadblock
    },
    doubling_ground_rent: {
        shouldShow: isLeaseholdOrCommonhold,
        isComplete: notUnknown,
    },
    tenant_becomes_owner_of_fh_or_mc_member: {
        shouldShow: isLeaseholdOrCommonhold,
        isComplete: notUnknown,
    },
    share_cert_obtained: {
        shouldShow: (f) => f.tenant_becomes_owner_of_fh_or_mc_member === "management_company_member" || f.tenant_becomes_owner_of_fh_or_mc_member === "freehold_owner",
        isComplete: notUnknown // LATER another roadblock
    },
    sufficient_repair_covenants: {
        shouldShow: isLeaseholdOrCommonhold,
        isComplete: notUnknown // LATER another roadblock
    },
    lease_contains_provision_for_forfeiture_on_bankruptcy: {
        shouldShow: isLeaseholdOrCommonhold,
        isComplete: notUnknown
    },
    sufficient_rights_of_support: {
        shouldShow: isLeaseholdOrCommonhold,
        isComplete: notUnknown // LATER another roadblock
    },
    sufficient_rights_of_access: {
        shouldShow: isLeaseholdOrCommonhold,
        isComplete: notUnknown // LATER another roadblock
    },
    landlord_insurers_building: {
        shouldShow: isLeaseholdOrCommonhold,
        isComplete: notUnknown // LATER another roadblock
    },
    deed_of_covenant_required: {
        shouldShow: isLeaseholdOrCommonhold,
        isComplete: notUnknown
    },
    license_to_assign_required: {
        shouldShow: isLeaseholdOrCommonhold,
        isComplete: notUnknown
    },
    building_regs_planning_structural_guarantee_required: {
        shouldShow: alwaysTrue,
        isComplete: notUnknown,
    },
    planning_regs_require_consent_or_transfer: {
        shouldShow: (f) => f.building_regs_planning_structural_guarantee_required === "yes",
        isComplete: notUnknown,
    },
    property_type: {
        shouldShow: alwaysTrue,
        isComplete: notUnknown,
    },
    insurance_sufficient_for_maisonette: {
        shouldShow: (f) => isLeaseholdOrCommonhold(f) && f.landlord_insurers_building === "yes" && f.property_type === "maisonette",
        isComplete: notUnknown,
    },
    all_charges_and_restrictions_for_title_added: {
        shouldShow: alwaysTrue,
        isComplete: (v) => v !== null,
    },
    has_other_title_issues: {
        shouldShow: alwaysTrue,
        isComplete: notUnknown,
    },
    all_other_title_issues_added: {
        shouldShow: (f) => f.has_other_title_issues === "yes",
        isComplete: (v) => v !== null,
    },
};

export const getCaseTitleCheckFieldsThatShouldBeHidden = (p: TTitleCheckEditable & TTitleCheckReadOnly): (keyof TCaseTitleCheckForm["edited"])[] => pipe(
    editableCaseTitleCheckFieldsConfig,
    record.reduceWithIndex([], (index, acc: (keyof TCaseTitleCheckForm["edited"])[], funcs) => {
        const shouldShow = funcs.shouldShow(p);
        if (!shouldShow) {
            return [...acc, index];
        }
        return acc;
    })
);

export const CaseTitleCheckFormStatus = union([literal("pending"), literal("done")]);
export type TCaseTitleCheckFormStatusCodec = typeof CaseTitleCheckFormStatus;
export type TCaseTitleCheckFormStatus = TTypeOfCodec<TCaseTitleCheckFormStatusCodec>;

export type TCaseTitleCheckFormState = {
    complete: number;
    total: number;
    status: TCaseTitleCheckFormStatus;
};

export const getCompletedStateTupleFromData = (p: TTitleCheckEditable & TTitleCheckReadOnly): TCaseTitleCheckFormState => {
    return pipe(
        editableCaseTitleCheckFieldsConfig,
        record.reduceWithIndex({
            complete: 0,
            total: 0,
            status: "pending"
        } as TCaseTitleCheckFormState, (index, acc, funcs) => {
            const shouldShow = funcs.shouldShow(p);
            const isComplete = funcs.isComplete as (v: unknown) => boolean; // Don't know why I have to do this
            if (!shouldShow) {
                return acc;
            }
            const currentVal = p[index];
            const complete = isComplete(currentVal) ? acc.complete + 1 : acc.complete;
            const total = acc.total + 1;

            return {
                complete,
                total,
                status: complete === total ? "done" : "pending"
            };
        })
    )
} 