import * as t from "io-ts";
import * as OfferStatus from "./OfferStatus";
import * as Enquiry2 from "./Enquiry2";
import * as PartyC8 from "./Party8";

export const codec = t.type({
    id: t.string,
    enquiry_id: t.string,
    status: OfferStatus.codec,
    amount: t.number,
    shared_with_seller_at: t.union([t.string, t.null]),
    conditions: t.string,
    rejected_reason: t.string,
    withdrawn_reason: t.string,
    created_at: t.string,
    enquiry: Enquiry2.codec,
    party: PartyC8.codec,
});

export type T = t.TypeOf<typeof codec>;

export const newDefault = (): T => ({
    id: "",
    enquiry_id: "",
    status: "pending",
    amount: 0,
    shared_with_seller_at: null,
    conditions: "",
    rejected_reason: "",
    withdrawn_reason: "",
    created_at: "",
    enquiry: Enquiry2.newDefault(),
    party: PartyC8.newDefault(),
});
