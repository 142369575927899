import { TAnyCodec, TCodec, TTypeOfCodec, TTypeOfNewDefault } from "../codec";
import { either } from "fp-ts/lib";
import { pipe } from "fp-ts/lib/function";
import { TError, errorConstants } from "../errors";
import { array } from "fp-ts/lib";

export type TAnyTupleCodec = TCodec<
    "TupleCodec",
    { codec: Array<TAnyCodec> },
    Array<unknown>,
    Array<unknown>
>;

interface ITupleCodecConstructor {
    <
        P extends [TAnyCodec, TAnyCodec],
        D extends [TTypeOfCodec<P[0]>, TTypeOfCodec<P[1]>],
        N extends [TTypeOfNewDefault<P[0]>, TTypeOfNewDefault<P[1]>]
    >(payload: P): TCodec<"TupleCodec", { codec: P }, D, N>;
    <
        P extends [TAnyCodec, TAnyCodec, TAnyCodec],
        D extends [TTypeOfCodec<P[0]>, TTypeOfCodec<P[1]>, TTypeOfCodec<P[2]>],
        N extends [TTypeOfNewDefault<P[0]>, TTypeOfNewDefault<P[1]>, TTypeOfNewDefault<P[2]>]
    >(payload: P): TCodec<"TupleCodec", { codec: P }, D, N>;
    <
        P extends [TAnyCodec, TAnyCodec, TAnyCodec, TAnyCodec],
        D extends [TTypeOfCodec<P[0]>, TTypeOfCodec<P[1]>, TTypeOfCodec<P[2]>, TTypeOfCodec<P[3]>],
        N extends [TTypeOfNewDefault<P[0]>, TTypeOfNewDefault<P[1]>, TTypeOfNewDefault<P[2]>, TTypeOfNewDefault<P[3]>]
    >(payload: P): TCodec<"TupleCodec", { codec: P }, D, N>;
    <
        P extends [TAnyCodec, TAnyCodec, TAnyCodec, TAnyCodec, TAnyCodec],
        D extends [TTypeOfCodec<P[0]>, TTypeOfCodec<P[1]>, TTypeOfCodec<P[2]>, TTypeOfCodec<P[3]>, TTypeOfCodec<P[4]>],
        N extends [TTypeOfNewDefault<P[0]>, TTypeOfNewDefault<P[1]>, TTypeOfNewDefault<P[2]>, TTypeOfNewDefault<P[3]>, TTypeOfNewDefault<P[4]>]
    >(payload: P): TCodec<"TupleCodec", { codec: P }, D, N>;
}

export const tuple: ITupleCodecConstructor = (payload: Array<TAnyCodec>) => ({
    type: "TupleCodec" as const,
    payload: { codec: payload },
    decode: decode(payload, "decode"),
    decodeNewDefault: decode(payload, "decodeNewDefault"),
    newDefault: () => payload.map((c) => c.newDefault()),
});

const decode = (payload: Array<TAnyCodec>, method: "decode" | "decodeNewDefault") => (input: unknown): either.Either<TError, unknown> => {
    if (! Array.isArray(input)) {
        return either.left([[errorConstants.TUPLE_VALIDATION, ""]]);
    }

    if (input.length !== payload.length) {
        return either.left([[errorConstants.TUPLE_VALIDATION, ""]]);
    }

    return  pipe(
        payload,
        array.mapWithIndex((i, v) =>  pipe(
            v[method](input[i]),
            either.mapLeft(([[code, path]]) => [[
                code,
                path ? `${i}.${path}` : `${i}`,
            ]])
        )),
        array.separate,
        (s) =>
            s.left.length
            ? either.left(array.flatten(s.left)) as either.Either<TError, unknown>
            : either.right(s.right)
    );
};
