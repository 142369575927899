import { TAnyCodec, TCodec, TTypeOfCodec } from "../codec";
import { either, array as fptsArray } from "fp-ts/lib";
import { pipe } from "fp-ts/lib/function";
import { TError, errorConstants } from "../errors";

export type TAnyArrayCodec = TCodec<
    "ArrayCodec",
    { codec: TAnyCodec },
    Array<unknown>,
    Array<unknown>
>;

export type TArrayCodec<A extends TAnyCodec> = TCodec<"ArrayCodec", { codec: A }, Array<TTypeOfCodec<A>>, Array<TTypeOfCodec<A>>>;

export const array = <
    A extends TAnyCodec,
    D extends Array<TTypeOfCodec<A>>
>(payload: A): TCodec<"ArrayCodec", { codec: A }, D, D> => ({
    type: "ArrayCodec",
    payload: { codec: payload },
    decode: decode(payload, "decode"),
    decodeNewDefault: decode(payload, "decodeNewDefault"),
    newDefault: (): D => [] as unknown as D,
});

export const decode = <D extends Array<TTypeOfCodec<TAnyCodec>>>(payload: TAnyCodec, method: "decode" | "decodeNewDefault") => (input: unknown): either.Either<TError, D> => {
    if (! Array.isArray(input)) {
        return either.left([[errorConstants.ARRAY_VALIDATION, ""]]);
    }

    return decodeArray(input, payload, method);
};

export const decodeArray = <
    A extends TAnyCodec,
    D extends Array<TTypeOfCodec<A>>
>(input: Array<unknown>, payload: TAnyCodec, method: "decode" | "decodeNewDefault"): either.Either<TError, D> => pipe(
    input,
    fptsArray.mapWithIndex((i, item) =>
        pipe(
            payload[method](item),
            either.mapLeft((e) => e.map(([code, path]) => [
                code,
                path ? `${i}.${path}` : `${i}`,
            ]))
        )
    ),
    fptsArray.separate,
    (s) => s.left.length
        ? either.left(fptsArray.flatten(s.left)) as either.Either<TError, D>
        : either.right(s.right as D)
);
