import React from "react";

export default (props: React.SVGAttributes<SVGElement>) =>
    <svg 
        width="24" 
        height="24" 
        viewBox="0 0 24 24" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path 
            d="M19.4102 17.11C18.7102 17.82 18.0102 18.46 17.7002 18.33C17.2002 18.13 17.7002 17.3 18.0002 16.81C18.2502 16.39 20.8602 12.92 20.8602 10.5C20.8602 9.22 20.3802 8.16 19.5202 7.52C18.7702 6.96 17.7802 6.79 16.8802 7.06C15.8102 7.37 14.9302 8.46 13.8202 9.83C12.6102 11.32 10.9902 13.27 9.74016 13.27C8.11016 13.27 8.09016 12.26 7.98016 11.48C11.7602 10.84 13.3602 7.81 13.3602 6.11C13.3602 4.41 11.9202 3.02 10.1502 3.02C8.52016 3.02 5.86016 4.35 5.46016 9.12H3.00016V11.62H5.47016C5.62016 13.27 6.56016 15.82 9.50016 15.82C11.7502 15.82 13.6802 13.91 14.4402 12.98C15.0202 12.25 16.5002 10.5 16.7302 10.26C16.9802 9.96 17.4102 9.42 17.8402 9.42C18.2902 9.42 18.5602 10.25 18.2002 11.34C17.8502 12.43 16.8002 14.2 16.3502 14.86C15.5702 16 15.0502 16.78 15.0502 18.14C15.0502 20.31 16.6902 21 17.5602 21C18.8802 21 20.0302 20 20.2802 19.75C20.6402 19.39 20.9402 19.09 21.1602 18.82L19.4102 17.11ZM10.1202 5.45C10.4302 5.45 10.8602 5.71 10.8602 6.17C10.8602 6.77 10.1302 8.37 7.99016 8.93C8.29016 6.24 9.42016 5.45 10.1202 5.45Z"
            fill="#606060"
        />
    </svg>
;
