import { array, readonlyArray } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import { Eq } from "fp-ts/lib/string";
import * as t from "io-ts";
import * as util from "../../shared/src/util";

// Documents that can have enquiries raised against them should also be put into this array
export const CasesDocumentTypesCanHaveEnquiries: Array<typeof CasesDocumentTypes[number]> = [
    "apportionment_statement",
    "auction_pack",
    "building_regulations",
    "death_certificate",
    "deed_of_transfer",
    "deed_of_variation",
    "electrical_certificate",
    "enquiries_responses",
    "epitome_of_title",
    "gas_safety_certificate",
    "ground_rent_statement",
    "guarantee",
    "lawyer_check",
    "letter_to/from_solicitors",
    "listed_building_entry",
    "lpa",
    "new_build_warranty",
    "overriding_interests_form",
    "planning_documentation",
    "poa",
    "property_particulars",
    "radon_test",
    "reconciliation_statement",
    "rx4",
    "section_104_agreement",
    "source_of_wealth_report",
    "stamp_duty_questionnaire",
    "tenancy_agreement",
    "treatment_plan",
    "tree_preservation_order",
    "warranty",
    "wayleave_agreement",
    "will",
    "freehold_title_register",
    "freehold_title_plan",
    "leasehold_title_register",
    "leasehold_title_plan",
    "local_authority_search",
    "ta6",
    "ta7",
    "ta10",
    "environment_search",
    "drainage_search",
    "coal_mining_search",
    "chancel_search",
    "cheshire_salt_search",
    "lease",
    "lpe1",
    "mos",
    "sail_legal_client_questionnaire",
    "epc",
    "draft_contract",
    "grant_of_probate",
    "lasting_power_of_attorney",
    "draft_tr1",
    "signed_tr1",
    "signed_mortgage_deed",
    "signed_contract",
    "survey",
    "os1",
    "search_report",
    "mortgage_offer",
    "signed_ta13",
    "transfer",
    "conveyance",
    "deed",
    "indemnity_policy",
    "other",
    "draft_tr2",
    "signed_tr2",
    "draft_tp1",
    "signed_tp1",
    "draft_tp2",
    "signed_tp2",
    "draft_as1",
    "signed_as1",
    "draft_as3",
    "signed_as3",
    "land_registry_document",
    "requisition",
    "letter",
    "draft_lease_extension",
    "signed_lease_extension",
    "buildings_insurance",
    "marriage_certificate",
    "letters_of_admin",
    "statement_of_truth",
    "deed_of_covenant",
    "joint_ownership_form",
    "ast",
    "license_to_assign",
    "ltt",
    "change_of_name_deed",
    "signed_occupiers_consent_form",
    "rx3",
    "septic_tank_enquiries",
    "conservatory_questionnaire",
    "cn1",
    "certificate_of_consent",
    "draft_ds1",
    "signed_ds1",
    "share_certificate_indemnity",
    "fme1",
    "landlord_certificate",
    "leaseholder_certificate",
    "fire_risk_assessment",
    "esw1",
    "asbestos_report",
    "valuation_report",
    "deed_of_postponement",
    "searches_disclaimer",
    "memorandum_of_staircasing",
    "deed_of_release",
    "index_map_search_result",
    "land_charges_search_result",
    "pep_declaration",
    "k18_declaration",
    "declaration_of_solvency",
    "signed_title_plan",
    "newbuild_warranty",
    "contract_rider"
];

export const CasesDocumentTypes = [
    "freehold_title_register",
    "freehold_title_plan",
    "leasehold_title_register",
    "leasehold_title_plan",
    "local_authority_search",
    "ta6",
    "ta7",
    "ta10",
    "environment_search",
    "drainage_search",
    "coal_mining_search",
    "chancel_search",
    "cheshire_salt_search",
    "lease",
    "lpe1",
    "other",
    "mos",
    "sail_legal_client_questionnaire",
    "epc",
    "floorplan",
    "chancel_insurance",
    "sail_legal_quote",
    "draft_contract",
    "grant_of_probate",
    "lasting_power_of_attorney",
    "draft_tr1",
    "signed_tr1",
    "draft_mortgage_deed",
    "signed_mortgage_deed",
    "signed_contract",
    "exchanged_contract",
    "survey",
    "os1",
    "title_report",
    "search_report",
    "mortgage_offer_report",
    "replies_to_enquiries_report",
    "leasehold_pack_report",
    "mortgage_offer",
    "draft_ta13",
    "signed_ta13",
    "welcome_pack",
    "transfer",
    "conveyance",
    "deed",
    "indemnity_policy",
    "additional_fees_declaration",
    "aml_search",
    "ap1",
    "apportionment_statement",
    "auction_pack",
    "building_regulations",
    "checklist",
    "client_bank_check",
    "completion_statement",
    "completion_statement_draft",
    "completion_statement_final",
    "death_certificate",
    "decision_in_principle",
    "deed_of_transfer",
    "deed_of_variation",
    "electrical_certificate",
    "enquiries_responses",
    "epitome_of_title",
    "equity_release_statement",
    "gas_safety_certificate",
    "gift_declaration",
    "ground_rent_statement",
    "guarantee",
    "identification_document",
    "instruction_form",
    "invoice_estate_agent",
    "invoice_sail_legal",
    "invoice_third_party",
    "k16",
    "lawyer_check",
    "letter_to/from_solicitors",
    "listed_building_entry",
    "lpa",
    "map_search",
    "new_build_warranty",
    "overriding_interests_form",
    "planning_documentation",
    "poa",
    "proof_of_residency",
    "property_particulars",
    "radon_test",
    "reconciliation_statement",
    "rx4",
    "sdlt5",
    "section_104_agreement",
    "source_of_wealth_report",
    "stamp_duty_questionnaire",
    "tenancy_agreement",
    "treatment_plan",
    "tree_preservation_order",
    "utility_bill",
    "warranty",
    "wayleave_agreement",
    "will",
    "draft_sdlt_return",
    "signed_sdlt_return",
    "draft_tr2",
    "signed_tr2",
    "draft_tp1",
    "signed_tp1",
    "draft_tp2",
    "signed_tp2",
    "draft_as1",
    "signed_as1",
    "draft_as3",
    "signed_as3",
    "land_registry_document",
    "requisition",
    "stock_transfer_form",
    "share_certificate",
    "occupier_consent_form",
    "letter",
    "notice_of_transfer",
    "notice_of_charge",
    "completed_registration",
    "draft_lease_extension",
    "signed_lease_extension",
    "buildings_insurance",
    "marriage_certificate",
    "letters_of_admin",
    "statement_of_truth",
    "deed_of_covenant",
    "joint_ownership_form",
    "ast",
    "bsl_post_completion_docs",
    "license_to_assign",
    "ltt",
    "fr1",
    "change_of_name_deed",
    "signed_occupiers_consent_form",
    "rx3",
    "septic_tank_enquiries",
    "conservatory_questionnaire",
    "cn1",
    "certificate_of_consent",
    "transfer_disclaimer",
    "esign_cert",
    "draft_ds1",
    "signed_ds1",
    "lisa_dec",
    "htb_authority_to_exchange",
    "htb_authority_to_complete",
    "os2",
    "council_tax_bill",
    "share_certificate_indemnity",
    "fme1",
    "landlord_certificate",
    "leaseholder_certificate",
    "fire_risk_assessment",
    "esw1",
    "asbestos_report",
    "valuation_report",
    "deed_of_postponement",
    "searches_disclaimer",
    "memorandum_of_staircasing",
    "deed_of_release",
    "index_map_search_result",
    "land_charges_search_result",
    "pep_declaration",
    "k18_declaration",
    "declaration_of_solvency",
    "signed_title_plan",
    "newbuild_warranty",
    "contract_rider",
    "separation_agreement",
    "ila_waiver",
    "nhbc",
    "court_order",
    "decree_absolute"
] as const;

export type TCasesDocumentType = typeof CasesDocumentTypes[number];

export const DocumentTypesThatRequiredAnExpiry: Array<TCasesDocumentType> = [
    "local_authority_search",
    "environment_search",
    "drainage_search",
    "coal_mining_search",
    "chancel_search",
    "cheshire_salt_search",
    "os1",
    "mortgage_offer",
];

// This is useful for any document types that used to exist but are now depreciated, adding them here is optional but doing so will mean that if a user goes to upload
// this type from the case documents page they will see a notice saying that uploads from this location are no longer possible for this document type.
export const DepreciatedDocumentTypes = [
    "redemption_statement",
    "sail_legal_quote",
] as const;
export type TDepreciatedDocumentTypes = typeof DepreciatedDocumentTypes[number];

export const codec = t.union(pipe(
    CasesDocumentTypes,
    readonlyArray.map<TCasesDocumentType, t.LiteralC<TCasesDocumentType>>((v) => t.literal(v)),
) as [t.LiteralC<TCasesDocumentType>, t.LiteralC<TCasesDocumentType>, ...t.LiteralC<TCasesDocumentType>[]]);

export const values = util.typeValuesFromCodec(codec.types as Array<t.LiteralC<T>>);

export const valuesWithDepreciated = pipe(
    [
        ...values,
        ...DepreciatedDocumentTypes,
    ],
    array.uniq<T | TDepreciatedDocumentTypes>(Eq),
);

export type T = t.TypeOf<typeof codec>;

export const toDisplayString = (p: T | TDepreciatedDocumentTypes): string => {
    switch (p) {
        case "freehold_title_register":
            return "Freehold title register";
        case "freehold_title_plan":
            return "Freehold title plan";
        case "leasehold_title_register":
            return "Leasehold title register";
        case "leasehold_title_plan":
            return "Leasehold title plan";
        case "local_authority_search":
            return "Local authority search";
        case "ta6":
            return "TA6 - Property information form";
        case "ta7":
            return "TA7 - Leasehold information form";
        case "ta10":
            return "TA10 - Fittings & contents";
        case "environment_search":
            return "Environment search";
        case "drainage_search":
            return "Drainage search";
        case "coal_mining_search":
            return "Coal mining search";
        case "chancel_search":
            return "Chancel search";
        case "cheshire_salt_search":
            return "Cheshire Salt Search";
        case "lease":
            return "Lease";
        case "lpe1":
            return "LPE1";
        case "mos":
            return "Memorandum of sale";
        case "other":
            return "Other";
        case "sail_legal_client_questionnaire":
            return "Sail Legal Client Questionnaire"
        case "epc":
            return "EPC"
        case "floorplan":
            return "Floorplan"
        case "chancel_insurance":
            return "Chancel Insurance"
        case "sail_legal_quote":
            return "Sail Legal Quote"
        case "draft_contract":
            return "Draft contract"
        case "grant_of_probate":
            return "Grant of probate";
        case "lasting_power_of_attorney":
            return "Lasting power of attorney";
        case "draft_tr1":
            return "Draft TR1";
        case "signed_tr1":
            return "Signed TR1";
        case "draft_mortgage_deed":
            return "Unsigned mortgage deed";
        case "signed_mortgage_deed":
            return "Signed mortgage deed";
        case "signed_contract":
            return "Signed contract";
        case "survey":
            return "Survey";
        case "os1":
            return "OS1";
        case "title_report":
            return "Title report";
        case "search_report":
            return "Search report";
        case "mortgage_offer_report":
            return "Mortgage offer report";
        case "replies_to_enquiries_report":
            return "Replies to enquiries report";
        case "leasehold_pack_report":
            return "Leasehold pack report";
        case "mortgage_offer":
            return "Mortgage offer";
        case "draft_ta13":
            return "Draft TA13";
        case "signed_ta13":
            return "Signed TA13";
        case "welcome_pack":
            return "Welcome pack";
        case "transfer":
            return "Transfer";
        case "conveyance":
            return "Conveyance";
        case "deed":
            return "Deed";
        case "indemnity_policy":
            return "Indemnity policy"
        case "additional_fees_declaration":
            return "Additional Fees Declaration";
        case "aml_search":
            return "AML Search";
        case "ap1":
            return "AP1";
        case "apportionment_statement":
            return "Apportionment statement";
        case "auction_pack":
            return "Auction Pack";
        case "building_regulations":
            return "Building Regulations";
        case "checklist":
            return "Checklist";
        case "client_bank_check":
            return "Client Bank Check";
        case "completion_statement":
            return "Completion statement";
        case "completion_statement_draft":
            return "Completion Statement - Draft";
        case "completion_statement_final":
            return "Completion Statement - Final";
        case "death_certificate":
            return "Death Certificate";
        case "decision_in_principle":
            return "Decision in Principle";
        case "deed_of_transfer":
            return "Deed of Transfer";
        case "deed_of_variation":
            return "Deed of Variation";
        case "electrical_certificate":
            return "Electrical Certificate";
        case "enquiries_responses":
            return "Enquiries Responses";
        case "epitome_of_title":
            return "Epitome of Title";
        case "equity_release_statement":
            return "Equity Release Statement";
        case "gas_safety_certificate":
            return "Gas Safety Certificate";
        case "gift_declaration":
            return "Gift Declaration";
        case "ground_rent_statement":
            return "Ground rent statement";
        case "guarantee":
            return "Guarantee";
        case "identification_document":
            return "Identification Document";
        case "instruction_form":
            return "Instruction Form";
        case "invoice_estate_agent":
            return "Invoice - Estate Agent";
        case "invoice_sail_legal":
            return "Invoice - Sail Legal";
        case "invoice_third_party":
            return "Invoice - Third Party";
        case "k16":
            return "K16";
        case "lawyer_check":
            return "Lawyer Check";
        case "letter_to/from_solicitors":
            return "Letter To/From Solicitors";
        case "listed_building_entry":
            return "Listed Building Entry";
        case "lpa":
            return "LPA";
        case "map_search":
            return "Map Search";
        case "new_build_warranty":
            return "New Build Warranty";
        case "overriding_interests_form":
            return "Overriding Interests Form";
        case "planning_documentation":
            return "Planning Documentation";
        case "poa":
            return "POA";
        case "proof_of_residency":
            return "Proof Of Residency";
        case "property_particulars":
            return "Property Particulars";
        case "radon_test":
            return "Radon Test";
        case "reconciliation_statement":
            return "Reconciliation Statement";
        case "rx4":
            return "RX4";
        case "sdlt5":
            return "SDLT5";
        case "section_104_agreement":
            return "Section 104 Agreement";
        case "source_of_wealth_report":
            return "Source of Wealth Report";
        case "stamp_duty_questionnaire":
            return "Stamp Duty Questionnaire";
        case "tenancy_agreement":
            return "Tenancy Agreement";
        case "treatment_plan":
            return "Treatment Plan";
        case "tree_preservation_order":
            return "Tree Preservation Order";
        case "utility_bill":
            return "Utility Bill";
        case "warranty":
            return "Warranty";
        case "wayleave_agreement":
            return "Wayleave Agreement";
        case "will":
            return "Will";
        case "draft_sdlt_return":
            return "Draft SDLT Return";
        case "signed_sdlt_return":
            return "Signed SDLT Return";
        case "redemption_statement":
            return "Redemption statement";
        case "draft_tr2":
            return "Draft TR2";
        case "signed_tr2":
            return "Signed TR2";
        case "draft_tp1":
            return "Draft TP1";
        case "signed_tp1":
            return "Signed TP1";
        case "draft_tp2":
            return "Draft TP2";
        case "signed_tp2":
            return "Signed TP2";
        case "draft_as1":
            return "Draft AS1";
        case "signed_as1":
            return "Signed AS1";
        case "draft_as3":
            return "Draft AS3";
        case "signed_as3":
            return "Signed AS3";
        case "land_registry_document":
            return "Land Registry Document";
        case "requisition":
            return "Requisition";
        case "exchanged_contract":
            return "Exchanged contract";
        case "stock_transfer_form":
            return "Stock transfer form";
        case "share_certificate":
            return "Share certificate";
        case "occupier_consent_form":
            return "Occupier Consent Form";
        case "letter":
            return "Letter";
        case "notice_of_transfer":
            return "Notice of Transfer";
        case "notice_of_charge":
            return "Notice of Charge";
        case "completed_registration":
            return "Completed Registration";
        case "draft_lease_extension":
            return "Draft Lease Extension";
        case "signed_lease_extension":
            return "Signed Lease Extension";
        case "buildings_insurance":
            return "Buildings Insurance";
        case "marriage_certificate":
            return "Marriage Certificate";
        case "letters_of_admin":
            return "Letters of Admin";
        case "statement_of_truth":
            return "Statement of Truth";
        case "deed_of_covenant":
            return "Deed of Covenant";
        case "joint_ownership_form":
            return "Joint Ownership Form";
        case "ast":
            return "AST";
        case "bsl_post_completion_docs":
            return "BSL Post Completion Docs";
        case "license_to_assign":
            return "License to Assign";
        case "ltt":
            return "LTT";
        case "fr1":
            return "FR1";
        case "signed_occupiers_consent_form":
            return "Signed Occupiers Consent Form";
        case "rx3":
            return "RX3";
        case "septic_tank_enquiries":
            return "Septic Tank Enquiries";
        case "conservatory_questionnaire":
            return "Conservatory Questionnaire";
        case "cn1":
            return "CN1";
        case "certificate_of_consent":
            return "Certificate of Consent";
        case "transfer_disclaimer": 
            return "Transfer Disclaimer"
        case "esign_cert":
            return "E-Signature Certificate";
        case "draft_ds1":
            return "Draft DS1";
        case "signed_ds1":
            return "Signed DS1";
        case "change_of_name_deed":
            return "Change of Name Deed";
        case "share_certificate_indemnity":
            return "Share Certificate Indemnity";
        case "lisa_dec":
            return "LISA Dec";
        case "fme1":
            return "FME1";
        case "landlord_certificate":
            return "Landlord Certificate";
        case "leaseholder_certificate":
            return "Leaseholder certificate";
        case "fire_risk_assessment":
            return "Fire Risk Assessment";
        case "esw1":
            return "ESW1";
        case "asbestos_report":
            return "Asbestos Report";
        case "valuation_report":
            return "Valuation Report";
        case "deed_of_postponement":
            return "Deed of Postponement";
        case "searches_disclaimer":
            return "Searches Disclaimer";
        case "memorandum_of_staircasing":
            return "Memorandum of Staircasing";
        case "deed_of_release":
            return "Deed of Release";
        case "index_map_search_result":
            return "Index Map Search Result";
        case "land_charges_search_result":
            return "Land Charges Search Result";
        case "pep_declaration":
            return "PEP Declaration";
        case "k18_declaration":
            return "K18 Declaration";
        case "declaration_of_solvency":
            return "Declaration of Solvency";
        case "signed_title_plan":
            return "Signed Title Plan";
        case "newbuild_warranty":
            return "Newbuild Warranty";
        case "htb_authority_to_exchange":
            return "HTB Authority to Exchange";
        case "htb_authority_to_complete":
            return "HTB Authority to Complete";
        case "os2":
            return "OS2";
        case "council_tax_bill":
            return "Council Tax Bill";
        case "contract_rider":
            return "Contract Rider";
        case "separation_agreement":
            return "Separation Agreement";
        case "ila_waiver":
            return "ILA Waiver";
        case "nhbc":
            return "NHBC";
        case "court_order":
            return "Court Order";
        case "decree_absolute":
            return "Decree Absolute";
        default:
            return util.requireExhaustive(p);
    }
};
