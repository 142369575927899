import * as t from "io-ts";
import { string } from "../../shared/src/validation/basic/string";
import { object } from "../../shared/src/validation/create/object";

export const codec = t.partial({
    notes: t.string,
});

export const validator = object({
    notes: string,
});

export type T = t.TypeOf<typeof codec>;

export const newDefault = (): Required<T> => ({
    notes: "",
});
