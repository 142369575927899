import { TTypeOfCodec, TTypeOfNewDefault } from "../../shared/src/codecs/codec";
import { dateTime } from "../../shared/src/codecs/types/dateTime";
import { required } from "../../shared/src/codecs/types/required";
import { string } from "../../shared/src/codecs/types/string";
import { uuid } from "../../shared/src/codecs/types/uuid";

export const CaseEnquiryNote1 = required({
    id: uuid(),
    cases_enquiry_id: uuid(),
    message: string(),
    date: dateTime(),
});

export type TCaseEnquiryNote1 = TTypeOfCodec<typeof CaseEnquiryNote1>;
export type TCaseEnquiryNote1NewDefault = TTypeOfNewDefault<typeof CaseEnquiryNote1>;