import { overload } from "../../../shared/src/codecs/types/overload";
import { required } from "../../../shared/src/codecs/types/required";
import { literal } from "../../../shared/src/codecs/types/literal";
import { union } from "../../../shared/src/codecs/types/union";
import { boolean } from "../../../shared/src/codecs/types/boolean";
import { UsersIdCheckAddressStatus, UsersIdCheckIdentityDocumentStatus, UsersIdCheckPepsSanctionsStatus } from "../UsersIdCheck";
import { TTypeOfCodec } from "../../../shared/src/codecs/codec";
import { dateTime } from "../../../shared/src/codecs/types/dateTime";
import { nullCodec } from "../../../shared/src/codecs/types/nullCodec";
import { DateTime } from "luxon";

export const ClientIdentityVerificationStatus = overload(
    union([
        literal("not_complete"),
        literal("failed"),
        literal("pending"),
        literal("complete"),
        literal("expired"),
        literal("needs_address_document"),
    ]),
    required({
        id_check_awaiting_third_party_responses: boolean(),
        id_check_result_identity_document_status: UsersIdCheckIdentityDocumentStatus,
        id_check_result_address_status: UsersIdCheckAddressStatus,
        id_check_result_peps_sanctions_status: UsersIdCheckPepsSanctionsStatus,
        id_check_expires_at: union([dateTime(), nullCodec()]),
        id_check_user_has_uploaded_proof_of_address: boolean(),
    }),
    (p) =>
        p.id_check_awaiting_third_party_responses || (
            p.id_check_user_has_uploaded_proof_of_address
            && p.id_check_result_address_status === "more_proof_required"
        ) ? "pending"
        : p.id_check_expires_at && DateTime.fromISO(p.id_check_expires_at).toSeconds() <= DateTime.utc().toSeconds() ? "expired"
        : (
            p.id_check_result_identity_document_status === "pass"
            && p.id_check_result_address_status === "more_proof_required"
        ) ? "needs_address_document"
        : (
            p.id_check_result_identity_document_status === "pass"
            && p.id_check_result_address_status !== "unknown"
            && p.id_check_result_peps_sanctions_status !== "unknown"
        ) ? "complete"
        : p.id_check_result_identity_document_status === "fail" ? "failed"
        : "not_complete"
);
export type TClientIdentityVerificationStatus = TTypeOfCodec<typeof ClientIdentityVerificationStatus>;
