import * as t from "io-ts";

export const constants = {
    BAD_REQUEST: "BadRequest" as const,
    UNAUTHORIZED: "Unauthorized" as const,
    FORBIDDEN: "Forbidden" as const,
    INTERNAL_SERVER_ERROR: "InternalServerError" as const,
    UNPROCESSABLE_ENTITY: "UnprocessableEntity" as const,
    UNSUPPORTED_MEDIA_TYPE: "UnsupportedMediaType" as const,
    NOT_FOUND: "NotFound" as const,
    IN_USE: "InUse" as const,
};

export const codec = t.union([
    t.literal(constants.BAD_REQUEST),
    t.literal(constants.UNAUTHORIZED),
    t.literal(constants.FORBIDDEN),
    t.literal(constants.INTERNAL_SERVER_ERROR),
    t.literal(constants.UNPROCESSABLE_ENTITY),
    t.literal(constants.UNSUPPORTED_MEDIA_TYPE),
    t.literal(constants.NOT_FOUND),
    t.literal(constants.IN_USE),
]);

export type T = t.TypeOf<typeof codec>;
