import { literal } from "../../../shared/src/codecs/types/literal";
import { union } from "../../../shared/src/codecs/types/union";
import { TTypeOfCodec } from "../../../shared/src/codecs/codec";

export const ListingServiceStatus = union([
    literal("Not interested"),
    literal("Complete"),
    literal("Invoice collection"),
    literal("Work completion"),
    literal("Client agreement"),
    literal("Quotation"),
    literal("Gauging interest")
]);

export type TListingServiceStatusCodec = typeof ListingServiceStatus;
export type TListingServiceStatus = TTypeOfCodec<TListingServiceStatusCodec>;