import Cookies from "js-cookie";
import * as FullStory from '@fullstory/browser';
import { array, record } from "fp-ts";
import { record as extRecord } from "../../shared/src/utilsByDomain/record";
import * as FirstPartyFetchResponse from "./../../domain/models/FirstPartyFetchResponse";
import * as JsonInnerError1 from "./../../domain/models/JsonInnerError1";
import { TJsonInnerErrorCodeTranslationRecord } from "./../../domain/models/JsonInnerError1";
import { TFormStatus } from "./../../shared/src/codecs/codec";
import * as TLegacyFormStatus from "./models/TFormStatus";
import { pipe } from "fp-ts/lib/function";
import { none, Option, some } from "fp-ts/lib/Option";
import { cookiePreferenceCookieName, TCookiePreference } from "./../../domain/constants";

export const defaultCRMRequestErrorHandler = <J>(r: FirstPartyFetchResponse.T<J>): void =>
     pipe(
        r,
        FirstPartyFetchResponse.fold({
            onNotRequested: () => undefined,
            onPending: () => undefined,
            on2XX: () => undefined,
            on204: () => undefined,
            on401: (innerErrors) => {
                window.location.href =
                    pipe(
                        innerErrors,
                        array.map((innerError) => innerError.error_code),
                    ).includes("TwoFactorAuthenticationRequired")
                        ? `${env.REACT_APP_CRM_URL}/auth/two-factor?redirect_url=${encodeURIComponent(window.location.href)}`
                        : window.location.href.includes(`${env.REACT_APP_CRM_URL}/crm`) ? `${env.REACT_APP_AUTH_URL}/web/login?redirect_url=${encodeURIComponent(window.location.href)}`
                        : `${env.REACT_APP_CRM_URL}/auth/resend-link?redirect_url=${encodeURIComponent(window.location.href)}`;
            },
            on404: () => alert("Not Found"),
            on422: () => undefined,
            onUnexpected: () => undefined,
        }),
    );

// This is user agent parsing which is generally bad but since iOS 13 implments some features we use
// (input type datetime-local) but out of spec
export const getiOSVersionByUAParsing = (): Option<[number, number, number]> => {
    if (/iP(hone|od|ad)/.test(navigator.platform)) {
        // eslint-disable-next-line @typescript-eslint/prefer-regexp-exec
        const v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
        if (v) {
            return some([parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || "0", 10)]);
        }
    }
    return none;
};

const errorCodeToDisplayText = (code: JsonInnerError1.T["error_code"]): string => {
    if (code === "ListingAlreadyHasAnOfferAcceptedValidation") {
        return "This listing already has an accepted offer. That offer must be undone before you can accept another one.";
    }
    if (code === "NotUnique") {
        return "This value needs to be unique and is not.";
    }
    return `NO ERROR CODE TRANSLATION EXISTS FOR THIS (${code}) ERROR. ONE NEEDS TO BE MADE!`;
};

export const validationErrorsToDisplayText = (
    validationErrors: Array<JsonInnerError1.T>,
    customErrorsMap?: TJsonInnerErrorCodeTranslationRecord,
): Array<string> =>
    validationErrors.map((error) => {
        if (!!customErrorsMap && Object.prototype.hasOwnProperty.call(customErrorsMap, error.error_code)) {
            // the code above asserts beyond a shadow of a doubt that "customErrorsMap"
            // is 1. an object and 2. has the error_code property on itself
            // typescript is unsable to infer that certainty so we cast it to "string".
            return customErrorsMap[error.error_code] as string;
        }
        return errorCodeToDisplayText(error.error_code);
    });

export const castNewFormStatusToLegacy = (status: TFormStatus): TLegacyFormStatus.T => {
    switch (status) {
        case "loading":
        case "untouched":
        case "requiresSubmission":
        case "validationError":
        case "submitting":
        case "success":
        case "failure":
            return status;
        default:
            return "untouched";
    }
}

export const isPartialtyScrolledIntoView = (el: HTMLDivElement) => {
    var rect = el.getBoundingClientRect();
    var elemTop = rect.top;
    var elemBottom = rect.bottom;
    var isVisible = elemTop < window.innerHeight && elemBottom >= 0;

    return isVisible;
}

export const isFullyScrolledIntoView = (el: HTMLDivElement) => {
    var rect = el.getBoundingClientRect();
    var elemTop = rect.top;
    var elemBottom = rect.bottom;
    var isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight);

    return isVisible;
}

export const getCookiePreference = (): TCookiePreference | undefined => Cookies.get(cookiePreferenceCookieName);

export const initFullStory = () => 
    FullStory.init({
        orgId: env.REACT_APP_FULLSTORY_ORG_ID,
        devMode: env.REACT_APP_ENVIRONMENT === "development" || env.REACT_APP_ENVIRONMENT === "scratch"
    });

export const elipseText = (text: string, elipsePast: number) => {
    if (text.length+3 > elipsePast) {
        return `${text.slice(0, elipsePast)}...`;
    }
    return text;
}

export const scrollElementIntoViewById = (id: string) => {
    const element = document.getElementById(id);
    if (element !== null ) {
        element.scrollIntoView();
    } else {
        console.warn(`scrollElementIntoViewById: Can not find element with ID of "${id}" to scroll into view`);
    }
}

export const isEveryRecordPropertyFilled = <K extends string>(fieldsToExclude: Array<K>, object: Record<K, string | null | boolean>): boolean => 
    numberOfPropertiesFilledIn(fieldsToExclude, object) === numberOfPropertiesToFillIn(fieldsToExclude, object)
;

export const numberOfPropertiesFilledIn = <K extends string>(fieldsToExclude: Array<K>, object: Record<K, string | null | boolean>): number => 
    pipe(
        extRecord.omit(fieldsToExclude, object),
        record.reduce(
            0,
            (sum, value) => value ? sum + 1 : sum 
        ),
    )
;

export const numberOfPropertiesToFillIn = <K extends string>(fieldsToExclude: Array<K>, object: Record<K, string | null | boolean>): number => 
    pipe(
        extRecord.omit(fieldsToExclude, object),
        record.reduce(0, (sum) => sum + 1),
    )
;