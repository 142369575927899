import { required, TRequiredCodec } from "../../../shared/src/codecs/types/required";
import { ListingSignMovementCreateForm, TListingSignMovementCreateFormCodec } from "../form/ListingSignMovementCreateForm";
import { ListingDataFormIO, TListingDataFormIOCodec } from "../formIO/ListingDataFormIO";
import { NewListingAdHocTaskFormIO, TNewListingAdHocTaskFormIOCodec } from "../formIO/NewListingAdHocTaskFormIO";

export const ListingPagePartial: TRequiredCodec<{
    data: TListingDataFormIOCodec;
    create_sign_movement: TListingSignMovementCreateFormCodec;
    create_ad_hoc_task: TNewListingAdHocTaskFormIOCodec;
}> = required({
    data: ListingDataFormIO,
    create_sign_movement: ListingSignMovementCreateForm,
    create_ad_hoc_task: NewListingAdHocTaskFormIO,
});

export type TListingPagePartialCodec = typeof ListingPagePartial;