import { pipe } from "fp-ts/lib/function";
import { TTypeOfCodec } from "../../shared/src/codecs/codec";
import { array } from "../../shared/src/codecs/types/array";
import { nullCodec } from "../../shared/src/codecs/types/nullCodec";
import { overload } from "../../shared/src/codecs/types/overload";
import { postcode } from "../../shared/src/codecs/types/postcode";
import { required } from "../../shared/src/codecs/types/required";
import { string } from "../../shared/src/codecs/types/string";
import { union } from "../../shared/src/codecs/types/union";
import { Country2, Country1, Country4, Country4ToDisplayName } from "./Country";
import { Country1ToCopyText } from "./overloads/Address";
import { intersection } from "../../shared/src/codecs/types/intersection";
import { decimal } from "../../shared/src/codecs/types/decimal";
import { standardise } from "../models/Postcode1";

export const Address1 = required({
    building_name: string(),
    building_number: string(),
    sub_building_name: string(),
    sub_building_number: string(),
    street_name: string(),
    city_town: string(),
    locality: string(),
    county: string(),
    country: Country2,
    postcode: union([postcode(), nullCodec()]),
});
export type TAddress1 = TTypeOfCodec<typeof Address1>;

// This returns a standard address string regardless of which country type is used
export const StandardAddressFormatter = (t: {
    sub_building_name: string,
    sub_building_number: string,
    building_name: string,
    building_number: string,
    street_name: string,
    locality: string,
    city_town: string,
    postcode: string | null,
}): string => {
    const formattedAddress: Array<string> = [];
    if (t.sub_building_name) {
        formattedAddress.push(t.sub_building_name);
    } else if (t.sub_building_number) {
        formattedAddress.push(t.sub_building_number);
    }
    if (t.building_number) {
        formattedAddress.push(`${t.building_number} ${t.street_name}`);
    }
    else {
        formattedAddress.push(t.building_name);
        formattedAddress.push(t.street_name);
    }
    if (t.locality) {
        formattedAddress.push(t.locality);
    }
    formattedAddress.push(t.city_town);
    if (t.postcode) {
        formattedAddress.push(standardise(t.postcode));
    }
    return formattedAddress
        .filter((value) => value) // Remove empty strings
        .join(", ");
};

export const Address2 = overload(
    string(),
    Address1,
    StandardAddressFormatter,
);

export type TAddress2Codec = typeof Address2;

export const Address3 = required({
    postcode: union([postcode(), nullCodec()]),
    county: string(),
    city_town: string(),
    country: Country1,
    building_name: string(),
    building_number: string(),
    sub_building_name: string(),
    sub_building_number: string(),
    street_name: string(),
    locality: string(),
    district: string(),
});
export type TAddress3Codec = typeof Address3;
export type TAddress3 = TTypeOfCodec<typeof Address3>;

export const Address4 = intersection([
    Address3,
    required({
        latitude: decimal(),
        longitude: decimal(),
    }),
]);
export type TAddress4 = TTypeOfCodec<typeof Address4>;

export const Address3_displayString = (address: TAddress3): string =>
    pipe(
        address,
        StandardAddressFormatter,
        // Because StandardAddressFormatter intentionally doesn't handle country we now need to append the formatted country
        // to the returned string, if it is set.
        (addressString) => [
            addressString,
            ...(address.country !== "unknown" ? [Country1ToCopyText(address.country)] : []),
        ],
        (a) => a.join(", "),
    );

export const Address5 = overload(
    string(),
    array(Address2),
    (address_short_strings) => address_short_strings.length === 0
        ? "No address provided"
        : address_short_strings.sort().join(" & ")
);
export type TAddress5Codec = typeof Address5;
export type TAddress5 = TTypeOfCodec<TAddress5Codec>;

export const Address6 = required({
    building_name: string(),
    building_number: string(),
    sub_building_name: string(),
    sub_building_number: string(),
    street_name: string(),
    city_town: string(),
    locality: string(),
    county: string(),
    country: Country4,
    postcode: string(),
});
export type TAddress6Codec = typeof Address6;
export type TTAddress6 = TTypeOfCodec<TAddress6Codec>;

export const Address6_displayString = (address: TTAddress6): string =>
    pipe(
        address,
        StandardAddressFormatter,
        // Because StandardAddressFormatter intentionally doesn't handle country we now need to append the formatted country
        // to the returned string, if it is set.
        (addressString) => [
            addressString,
            ...(address.country !== "unknown" ? [Country4ToDisplayName(address.country)] : []),
        ],
        (a) => a.join(", "),
    );

export const Address7 = overload(
    string(),
    array(Address2),
    (address_short_strings) => address_short_strings.length === 0
        ? ""
        : address_short_strings.join(" & "),
);
export type TAddress7Codec = typeof Address7;

export const Address8 = overload(
    union([string(), nullCodec()]),
    array(Address2),
    (address_short_strings) => address_short_strings.length === 0
        ? null
        : address_short_strings.join(" & "),
);
export type TAddress8Codec = typeof Address8;