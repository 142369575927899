import { required } from "../../shared/src/codecs/types/required";
import { TTypeOfCodec } from "../../shared/src/codecs/codec";
import { uuid } from "../../shared/src/codecs/types/uuid";
import { PaymentMethod } from "./PaymentMethod";
import { string } from "../../shared/src/codecs/types/string";
import { nonEmptyString } from "../../shared/src/codecs/types/nonEmptyString";
import { phoneNumber } from "../../shared/src/codecs/types/phoneNumber";
import { email } from "../../shared/src/codecs/types/email";
import { nullCodec } from "../../shared/src/codecs/types/nullCodec";
import { union } from "../../shared/src/codecs/types/union";
import { dateTime } from "../../shared/src/codecs/types/dateTime";
import { boolean } from "../../shared/src/codecs/types/boolean";
import { integer } from "../../shared/src/codecs/types/integer";
import { intersection } from "../../shared/src/codecs/types/intersection";
import { requiredFlatOverloaded } from "../../shared/src/codecs/types/requiredFlatOverloaded";
import { HasProvidedConveyancerDetails } from "./HasProvidedConveyancerDetails";
import { HasProvidedMortgageBrokerDetails } from "./HasProvidedMortgageBrokerDetails";
import { HasProvidedProofOfFunds } from "./HasProvidedProofOfFunds";
import { HasProvidedProofOfFundsForOffer } from "./HasProvidedProofOfFundsForOffer";
import { ListingEnquiryPurchasingIntent1 } from "./ListingEnquiryPurchasingIntent";
import { ListingEnquiryRenovationWorkBy1 } from "./ListingEnquiryRenovationWorkBy";

export const ListingEnquiry1 = required({
    id: uuid(),
    conveyancer_organisation_name: nonEmptyString(),
    conveyancer_name: nonEmptyString(),
    conveyancer_phone_number: phoneNumber(),
    conveyancer_email_address: email(),
    conveyancer_address: nonEmptyString(),
    purchasers_company_name: string(),
    purchasers_address: nonEmptyString(),
    payment_method: PaymentMethod,
});
export type TListingEnquiry1 = TTypeOfCodec<typeof ListingEnquiry1>;

export const ListingEnquiry2 = required({
    id: uuid(),
    listing_id: uuid(),
    conveyancer_organisation_name: string(),
    conveyancer_name: string(),
    conveyancer_phone_number: union([phoneNumber(), nullCodec()]),
    conveyancer_email_address: union([email(), nullCodec()]),
    conveyancer_address: string(),
    purchasers_company_name: string(),
    purchasers_address: string(),
    payment_method: PaymentMethod,
});
export type TListingEnquiry2 = TTypeOfCodec<typeof ListingEnquiry2>;

export const ListingEnquiry3 = required({
    id: uuid(),
    viewings_deferred: union([dateTime(), nullCodec()]),
    wants_to_view: boolean(),
    get_legal_buyers_defer: union([dateTime(), nullCodec()]),
    payment_method: PaymentMethod,
    id_check_complete: boolean(),
    purchasers_address: string(),
    funds_cash_verified_upto: union([integer(), nullCodec()]),
    funds_dip_verified_upto: union([integer(), nullCodec()]),
    funds_ltv_percentage: union([integer(), nullCodec()]),
    mortgage_broker_has_vouched: boolean(),
    mortgage_broker_organisation_name: string(),
    mortgage_broker_phone_number: union([phoneNumber(), nullCodec()]),
    mortgage_broker_name: string(),
    mortgage_broker_email_address: union([email(), nullCodec()]),
    conveyancer_organisation_name: string(),conveyancer_name: string(),
    conveyancer_phone_number: union([phoneNumber(), nullCodec()]),
    conveyancer_email_address: union([email(), nullCodec()]),
    conveyancer_address: string(),
    purchasers_company_name: string(),
    final_offer_made: boolean(),
    closed: boolean(),
    purchasing_intent: ListingEnquiryPurchasingIntent1,
    renovation_work_by: ListingEnquiryRenovationWorkBy1,
    amount_required_when_reselling: union([integer(), nullCodec()]),
    notes: string(),
});
export type TListingEnquiry3Codec = typeof ListingEnquiry3;

export const ListingEnquiry4 = intersection([
    required({
        has_made_offer: boolean(),
    }),
    requiredFlatOverloaded({
        has_provided_solicitor_details: HasProvidedConveyancerDetails,
    }),
    requiredFlatOverloaded({
        has_provided_mortgage_broker_details: HasProvidedMortgageBrokerDetails,
    }),
    requiredFlatOverloaded({
        has_provided_proof_of_funds: HasProvidedProofOfFunds,
    }),
    requiredFlatOverloaded({
        has_provided_proof_of_funds_for_offer: HasProvidedProofOfFundsForOffer,
    }),
]);
export type TListingEnquiry4Codec = typeof ListingEnquiry4;
