import { overload } from "../../../shared/src/codecs/types/overload";
import { required } from "../../../shared/src/codecs/types/required";
import { string } from "../../../shared/src/codecs/types/string";
import { array as codecArray } from "../../../shared/src/codecs/types/array";
import { array } from "fp-ts";
import { pipe } from "fp-ts/lib/function";

export const CaseClientNames = overload(
    string(),
    required({
        client_users: codecArray(
            required({
                first_name: string(),
                last_name: string(),
            }),
        )
    }),
    ({client_users}) => pipe(
        client_users,
        array.map((user) =>
            pipe(
                [
                    ...(user.first_name ? [user.first_name] : []),
                    ...(user.last_name ? [user.last_name] : []),
                ],
                (a) => a.length === 0
                    ? "Client name not given"
                    : a.join(" "),
            )
        ),
        (a) => a.length === 0
            ? "No clients set"
            : a.join(" & "),
    )
);
export type TCaseClientNames = typeof CaseClientNames;
