import React from "react";

export default (props: React.SVGAttributes<SVGElement>) => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g clipPath="url(#clip0_10584_32112)">
<path d="M5 21V4H14L14.4 6H20V16H13L12.6 14H7V21H5ZM14.65 14H18V8H12.75L12.35 6H7V12H14.25L14.65 14Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_10584_32112">
<rect width="24" height="24" fill="white" transform="matrix(-1 0 0 -1 24 24)"/>
</clipPath>
</defs>
</svg>
;
