import * as Function from "../Function";
import * as Error from "../Error";
import * as ErrorCode from "../ErrorCode";
import { option } from "fp-ts/lib";

export const isStrictlyEqualTo = ( valueToAssertAgainst: unknown ): Function.TValidationFunction =>
    (value: unknown): option.Option<Error.TValidationError> =>
        typeof(value) === typeof(valueToAssertAgainst)
        ? value === valueToAssertAgainst
            ? option.none
            : option.some([[ErrorCode.validationErrorCodeConstants.IS_STRICTLY_EQUAL_TO_VALUE_VALIDATION]])
        : option.some([[ErrorCode.validationErrorCodeConstants.IS_STRICTLY_EQUAL_TO_TYPE_VALIDATION]])
    ;
