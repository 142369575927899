import * as TCRMParty from "./TCRMParty";
import * as TCRMSearch from "./TCRMSearch";
import * as TCRMUser from "./TCRMUser";
import * as TCRMListingPerformance from "./TCRMListingPerformance";
import * as TCRMListings from "./TCRMListings";
import * as TCRMEnquiry from "./TCRMEnquiry";
import * as TProbate from "./TProbate";
import * as FirstPartyFetchResponse from "../../../domain/models/FirstPartyFetchResponse";
import * as PublicListingSuccessResponse1 from "../../../domain/models/PublicListingSuccessResponse1";
import * as ListingEnquiriesViewingSuccessResponse1 from "../../../domain/models/ListingEnquiriesViewingSuccessResponse1";
import * as UserPhoneNumber2 from "../../../domain/models/UserPhoneNumber2";
import * as UserSuccessResponse3 from "../../../domain/models/UserSuccessResponse3";
import * as ViewingType from "../../../domain/models/ViewingType";
import * as TCRMAddNewEnquiry from "./TCRMAddNewEnquiry";
import * as TBuyerEnquiry from "./TBuyerEnquiry";
import * as TAuthResendLink from "./TAuthResendLink";
import * as TCRMLegalCaseEnquiries from "./TCRMLegalCaseEnquiries";
import * as TCRMLegalCaseDocuments from "./TCRMLegalCaseDocuments";
import { state, TState } from "../../../domain/codecs/state";
import { SessionUser, TSessionUserNewDefault } from "../../../domain/codecs/User";
import { RoadblocksForm } from "../../../domain/codecs/form/RoadblockForm";



export class C <T> {
    constructor(r: T) {
        this.routes = r;
    }
    routes: T;
    global: GlobalState = new GlobalState();
    activeData: ActiveData = new ActiveData();
    probate: TProbate.C = new TProbate.C();
    forms: TState = {
        ...state.newDefault(),
        overlay: {
            roadblocks: {
                ...RoadblocksForm.newDefault(),
                edited: {
                    ...RoadblocksForm.newDefault().edited,
                    assignedToUserId: typeof sessionUser === "undefined"? null : sessionUser.id
                }
            }
        },
        jobs: {
            ...state.newDefault().jobs,
            case_filtered_by_user_id:
                typeof sessionUser === "undefined"
                    ? 'anyone'
                : sessionUser.user_role === "sail_legal_authoriser_staff"
                    ? sessionUser.id
                : 'anyone'
            ,
            filter: {
                ...state.newDefault().jobs.filter,
                filter_by_handler: typeof sessionUser === "undefined"
                        ? null
                    : sessionUser.user_role === "sail_legal_authoriser_staff"
                        ? sessionUser.id
                    : null
                ,
                default_user_filter: typeof sessionUser === "undefined" ? "" : sessionUser.id
            },
            data: {
                ...state.newDefault().jobs.data,
                input: {
                    user_id: typeof sessionUser === "undefined" ? "" : sessionUser.id,
                }
            }
        },
    };
}
class ActiveData {
    public listing: ListingState = new ListingState();
    public bookViewing: BookViewingState = new BookViewingState();
    public crm: CRM = new CRM();
    public buyer: Buyer = new Buyer();
    public authResendLink = new TAuthResendLink.C();
}
export class GlobalState {
    public user: TSessionUserNewDefault = typeof sessionUser === "undefined" ? SessionUser.newDefault() : sessionUser;
}
class ListingState {
    public listingResponse: FirstPartyFetchResponse.T<PublicListingSuccessResponse1.T> = FirstPartyFetchResponse.createPending(PublicListingSuccessResponse1.newDefault());
}

export class BookViewingState {
    public viewingType: ViewingType.T | "" = "";
    public requestedTimes: [string, string, string] = ["", "", ""];
    public firstName: string = "";
    public lastName: string = "";
    public contactPhoneNumber: string = "";
    public contactEmail: string = "";
    public bookViewingResponse: FirstPartyFetchResponse.T<ListingEnquiriesViewingSuccessResponse1.T> = FirstPartyFetchResponse.createNotRequested(ListingEnquiriesViewingSuccessResponse1.newDefault());
}

export class CRM {
    public search: TCRMSearch.C = new TCRMSearch.C();
    public user: TCRMUser.C = new TCRMUser.C();
    public listingPerformance: TCRMListingPerformance.C = new TCRMListingPerformance.C();
    public listings: TCRMListings.C = new TCRMListings.C();
    public incomingCall: CRMIncomingCall = new CRMIncomingCall();
    public party: TCRMParty.C = new TCRMParty.C();
    public enquiry: TCRMEnquiry.C = new TCRMEnquiry.C();
    public addNewEnquiry: TCRMAddNewEnquiry.C = new TCRMAddNewEnquiry.C();
    public legalCaseEnquiries: TCRMLegalCaseEnquiries.C = new TCRMLegalCaseEnquiries.C();
    public legalCaseDocuments: TCRMLegalCaseDocuments.C = new TCRMLegalCaseDocuments.C();
}

class CRMIncomingCall {
    public request: UserPhoneNumber2.T = UserPhoneNumber2.newDefault();
    public response: FirstPartyFetchResponse.T<UserSuccessResponse3.T> = FirstPartyFetchResponse.createNotRequested(UserSuccessResponse3.newDefault());
}

class Buyer {
    public enquiry: TBuyerEnquiry.C = new TBuyerEnquiry.C();
}
