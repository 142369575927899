import React from "react";

export default (props: React.SVGAttributes<SVGElement>) => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g clipPath="url(#clip0_5387:25161)">
<path d="M20 5H4C2.9 5 2.01 5.9 2.01 7L2 19C2 20.1 2.9 21 4 21H12V19H4V9L12 14L20 9V14.5H22V7C22 5.9 21.1 5 20 5ZM12 12L4 7H20L12 12Z" fill="black"/>
<path d="M18 21H16V23H18V21Z" fill="black"/>
<path d="M18 13H16V19H18V13Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_5387:25161">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>
;
