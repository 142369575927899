import { TAnyCodec, TCodec, TTypeOfCodec, TTypeOfNewDefault } from "../codec";
import { either, array, record } from "fp-ts/lib";
import { pipe } from "fp-ts/lib/function";
import { TError, errorConstants, TErrorTuple } from "../errors";
import { isObject } from "../../util";

export type TAnyPartialCodec = TCodec<
    "PartialCodec",
    {[K: string]: TAnyCodec},
    unknown,
    unknown
>;

const decode = <
    P extends Record<string, TAnyCodec>,
    D extends {[K in keyof P]?: TTypeOfCodec<P[K]>;},
    N extends {[K in keyof P]?: TTypeOfNewDefault<P[K]>;},
    M extends "decode" | "decodeNewDefault"
>(payload: P, decodeMethod: M) => (input: unknown): either.Either<TError, M extends "decode" ? D : N> => {
    if (! isObject(input)) {
        return either.left([[errorConstants.OBJECT_VALIDATION, ""]]);
    }

    const validationErrors =  pipe(
        payload,
        record.filterWithIndex((key) => typeof input[key] !== "undefined"),
        record.reduceRightWithIndex(
            [] as Array<TErrorTuple>,
            (key, valueCodec, acc) =>
                Object.prototype.hasOwnProperty.call(input, key)
                    ?  pipe(
                        valueCodec[decodeMethod](input[key]),
                        either.fold(
                            (p) => [...acc, ...array.map<TErrorTuple, TErrorTuple>(([code, path]) => [
                                code,
                                path ? `${key}.${path}` : `${key}`,
                            ])(p)],
                            () => acc
                        )
                    )
                    : acc,
        ),
    );

    if (validationErrors.length) {
        return either.left(validationErrors as TError);
    }

    const inputWithExtraPropsRemoved =  pipe(
        input,
        record.filterWithIndex((i, value) =>
            Object.prototype.hasOwnProperty.call(payload, i)
            && typeof value !== "undefined"
        ),
    );

    return either.right(inputWithExtraPropsRemoved as M extends "decode" ? D : N);
};

export type TPartialCodec<P extends Record<string, TAnyCodec>> = TCodec<"PartialCodec", P, {[K in keyof P]?: TTypeOfCodec<P[K]>;}, {[K in keyof P]?: TTypeOfNewDefault<P[K]>;}>;

export const partial = <
    P extends Record<string, TAnyCodec>,
    D extends {[K in keyof P]?: TTypeOfCodec<P[K]>;},
    N extends {[K in keyof P]?: TTypeOfNewDefault<P[K]>;}
>(payload: P): TCodec<"PartialCodec", P, D, N> => ({
    type: "PartialCodec",
    payload,
    decode: decode(payload, "decode"),
    decodeNewDefault: decode(payload, "decodeNewDefault"),
    newDefault: () =>
        ({} as N),
});
