import * as t from "io-ts";
import * as BuyerEnquiry1 from "./BuyerEnquiry1";
import * as BuyerParty1 from "./BuyerParty1";

export const codec = t.type({
    id: t.string,
    amount: t.number,
    enquiry: BuyerEnquiry1.codec,
    party: BuyerParty1.codec,
});

export type T = t.TypeOf<typeof codec>;

export const newDefault = (): T => ({
    id: "",
    amount: 0,
    enquiry: BuyerEnquiry1.newDefault(),
    party: BuyerParty1.newDefault(),
});
