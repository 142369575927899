import React from "react";
import { TCRMMainColours } from "../../models/TCRMMainColours";
import { CRMIcon, TIcon } from "../CRMIcon/CRMIcon";

export type TIconBadge = {
    icon: TIcon;
    iconColour?: TCRMMainColours;
    iconTitle?: string;
    badgeColour?: TCRMMainColours;
    onClick?: (e: React.MouseEvent) => void;
    size?: "tiny" | "small";
    flat?: boolean;
};

export class CRMIconBadge extends React.Component<React.PropsWithChildren<TIconBadge>> {
    public render (): JSX.Element {
        return (
            <div
                className={`
                    crm-icon-badge
                    crm-icon-badge--size-${this.props.size || "tiny"}
                    crm-icon-badge--boxshadow-${this.props.flat ? "none" : "full"}
                    crm-icon-badge--colour-${this.props.badgeColour ?? "neutral-paper"}
                    ${this.props.onClick !== undefined ? "crm-icon-badge--clickable" : ""}
                `}
                onClick={this.props.onClick}
            >
                <CRMIcon
                    iconName={this.props.icon}
                    colour={this.props.iconColour ?? "neutral-ink"}
                    size={this.props.size || "tiny"}
                    title={this.props.iconTitle}
                />
            </div>
        );
    }
}
