import React from "react";
import { CRMCardOutside } from "../../components/CRMCardOutside/CRMCardOutside";
import { CRMColumns } from "../../components/CRMColumns/CRMColumns";
import { CRMPadding } from "../../components/Simple/CRMPadding/CRMPadding";
import { CRMPageBackground } from "../../components/Simple/CRMPageBackground/CRMPageBackground";
import { CRMSpacer } from "../../components/CRMSpacer/CRMSpacer";
import FrontParagraphRegular from "../../components/Front/Simple/FrontParagraphRegular/FrontParagraphRegular";
import { FrontSpacer } from "../../components/FrontSpacer/FrontSpacer";
import FrontParagraphLarge from "../../components/Front/Simple/FrontParagraphLarge/FrontParagraphLarge";
import FrontProbateEstateAdministrationQuoteContainer from "../../containers/FrontProbateEstateAdministrationQuoteContainer/FrontProbateEstateAdministrationQuoteContainer";
import FrontProbateGOPQuoteContainer from "../../containers/FrontProbateGOPQuoteContainer/FrontProbateGOPQuoteContainer";
import FrontTitleMinor from "../../components/Front/Simple/FrontTitleMinor/FrontTitleMinor";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import TagManager from "react-gtm-module";

class ProbatePanelInterestedInPage extends React.Component<React.PropsWithChildren<TContainerStateProps>> {
    public componentDidMount() {
        TagManager.initialize({
            gtmId: "GTM-5J4P8R5",
        });
    }
    
    public render(): JSX.Element {
        return (
            <div className="probate-panel-enquiry-page">
                <CRMPageBackground>
                    <CRMPadding size="large">
                        <CRMColumns
                            padding="large"
                            columns={[
                                {
                                    flex: 0.5,
                                    content: (<div></div>),
                                },
                                {
                                    flex: 1.1887,
                                    content: (
                                        <>
                                            <CRMCardOutside borderRounding="right" shadow={true}>
                                                <CRMPadding size="medium">
                                                <img style={{display: "block", height: "100px"}} src="/static/img/SAIL_PROBATE_LOGO_2021.png" />
                                                <FrontSpacer size="small-2"></FrontSpacer>
                                                <FrontParagraphLarge>
                                                    Call us: &nbsp;<a style={{textDecoration: "none", color: "#049DCC", fontWeight: "bold"}} href="tel:0117 457 8232">0117 457 8232</a>
                                                </FrontParagraphLarge>
                                                <FrontSpacer size="medium-2"></FrontSpacer>
                                                <FrontTitleMinor>Thank you!</FrontTitleMinor>
                                                <CRMSpacer size="large" />
                                                <FrontParagraphRegular>We’ll be in contact soon (within the next few hours if in operating hours) with our recommended solicitor. There is no obligation to instruct them.</FrontParagraphRegular>
                                                <CRMSpacer size="large" />
                                                <FrontParagraphRegular>Need to know sooner? Call us now on 0808 196 5200.</FrontParagraphRegular>
                                                <CRMSpacer size="large" />
                                                </CRMPadding>
                                            </CRMCardOutside>
                                            <CRMSpacer size="medium" />

                                            {this.props.state.probate.viewCaseForm.view.interested_in_state === "estate_admin" ?
                                                <CRMCardOutside borderRounding="right" shadow={true}>
                                                    <CRMPadding size="large">
                                                        <FrontProbateEstateAdministrationQuoteContainer
                                                            fee={this.props.state.probate.viewCaseForm.view.estate_administration_fee.toString()}
                                                            caseForm={this.props.state.probate.viewCaseForm}
                                                        />
                                                    </CRMPadding>
                                                </CRMCardOutside>
                                            : this.props.state.probate.viewCaseForm.view.interested_in_state === "gop" ?
                                                <CRMCardOutside borderRounding="right" shadow={true}>
                                                    <CRMPadding size="large">
                                                        <FrontProbateGOPQuoteContainer
                                                            fee={this.props.state.probate.viewCaseForm.view.gop_fee.toString()}
                                                            caseForm={this.props.state.probate.viewCaseForm}
                                                        />
                                                    </CRMPadding>
                                                </CRMCardOutside>
                                            : ""
                                            }
                                        </>
                                    ),
                                },
                                {
                                    flex: 0.5,
                                    content: (<div></div>),
                                },
                            ]}
                        />
                    </CRMPadding>
                </CRMPageBackground>
            </div>
        );
    }
}

export default ProbatePanelInterestedInPage;
