import { TPaths } from "./routerPaths";
import { C as State } from "../../state/State";
import { is } from "../../../../shared/src/codecs/codec";
import { TChangeRouteAction, TQueryParams, TRouteActionType } from "./routerTypes";
import { Routes } from "./routerRoutes";

export const updateState = (p: TPaths, action: TChangeRouteAction<TRouteActionType>) => (s: State<Routes>): State<Routes> => {
    s.routes.active = action.type;
    s.routes.params = {...s.routes.params, ...action.params};

    // We have no choice but to go via any here because typescript can't verify that `action.queryStringParams` matches `action.type`
    // even though they are already garunteed to match by the existing types
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const routeQueryParams: any = s.routes.queryParams;

    if (is(p[action.type].queryParamsCodec, action.queryStringParams)) {
        routeQueryParams[action.type] = {
            ...routeQueryParams[action.type], 
            ...action.queryStringParams 
        };
        s.routes.queryParams = routeQueryParams as TQueryParams;
    }
    
    if (action.type in s.routes.blockParams && action.blockParams !== undefined) {
        s.routes.blockParams = {...s.routes.blockParams, ...{
            [action.type]: action.blockParams
        }}
    }

    return s;
};