import { TTypeOfCodec } from "../../../shared/src/codecs/codec";
import { formIO, TFormIOCodec } from "../../../shared/src/codecs/types/formIO";
import { required, TRequiredCodec } from "../../../shared/src/codecs/types/required";
import { array, TArrayCodec } from "../../../shared/src/codecs/types/array";
import { CaseForm, TCaseFormCodec } from "../form/CaseForm";
import { StaffUserIncludingDeleted, TStaffUserIncludingDeletedCodec } from "../User";
import { CaseId, TCaseIdCodec } from "../CaseId";
import { CaseConflictOfInterestForm, TCaseConflictOfInterestFormCodec } from "../form/CaseConflictsOfInterestForms";
import { TCaseAdHocJobFormCodec, CaseAdHocJobForm, TCaseAdHocJobNewFormCodec, CaseAdHocJobNewForm } from "../form/CaseAdHocJobForms";
import { intersection, TIntersectionCodec } from "../../../shared/src/codecs/types/intersection";
import { CasePropertyFullWithAddressShortString, TCasePropertyFullWithAddressShortStringCodec } from "../CaseProperty";
import { TUuidCodec, uuid } from "../../../shared/src/codecs/types/uuid";
import { TUnionCodec, union } from "../../../shared/src/codecs/types/union";
import { nullCodec, TNullCodec } from "../../../shared/src/codecs/types/nullCodec";

export const LegalCaseFormIO: TFormIOCodec<
    TIntersectionCodec<[
        TCaseIdCodec,
    ]>,
    TRequiredCodec<{
        details: TCaseFormCodec;
        properties: TArrayCodec<TCasePropertyFullWithAddressShortStringCodec>,
        sail_homes_case_id: TUnionCodec<[TNullCodec, TUuidCodec]>,
        sail_legal_case_handlers: TArrayCodec<TStaffUserIncludingDeletedCodec>;
        sail_legal_non_authorisers: TArrayCodec<TStaffUserIncludingDeletedCodec>;
        conflicts_of_interest: TArrayCodec<TCaseConflictOfInterestFormCodec>;
        ad_hoc_jobs: TArrayCodec<TCaseAdHocJobFormCodec>;
        create_new_ad_hoc_job: TCaseAdHocJobNewFormCodec;
    }>
> = formIO(
    intersection([
        CaseId,
    ]),
    required({
        details: CaseForm,
        properties: array(CasePropertyFullWithAddressShortString),
        sail_homes_case_id: union([nullCodec(), uuid()]),
        sail_legal_case_handlers: array(StaffUserIncludingDeleted),
        sail_legal_non_authorisers: array(StaffUserIncludingDeleted),
        conflicts_of_interest: array(CaseConflictOfInterestForm),
        ad_hoc_jobs: array(CaseAdHocJobForm),
        create_new_ad_hoc_job: CaseAdHocJobNewForm,
    }),
);

export type TLegalCaseFormIOCodec = typeof LegalCaseFormIO;
export type TLegalCaseFormIO = TTypeOfCodec<TLegalCaseFormIOCodec>;
