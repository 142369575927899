import P from "pino";
import { TTypeOfCodec, TTypeOfNewDefault } from "../../../shared/src/codecs/codec";
import { array, TArrayCodec } from "../../../shared/src/codecs/types/array";
import { boolean, TBooleanCodec } from "../../../shared/src/codecs/types/boolean";
import { form, TFormCodec } from "../../../shared/src/codecs/types/form";
import { intersection, TIntersectionCodec } from "../../../shared/src/codecs/types/intersection";
import { literal, TLiteralCodec } from "../../../shared/src/codecs/types/literal";
import { TNullCodec, nullCodec } from "../../../shared/src/codecs/types/nullCodec";
import { partial, TPartialCodec } from "../../../shared/src/codecs/types/partial";
import { required, TRequiredCodec } from "../../../shared/src/codecs/types/required";
import { TUnionCodec, union } from "../../../shared/src/codecs/types/union";
import { TAnyUuidCodec, TUuidCodec, uuid } from "../../../shared/src/codecs/types/uuid";
import { requireExhaustive } from "../../../shared/src/util";
import { EmptyObject, TEmptyObjectCodec } from "../EmptyObject";
import { QuoteRate1, QuoteRate2, TQuoteRate1Codec, TQuoteRate2Codec } from "../QuoteRate";
import { QuoteRateFeePropertyValue, QuoteRateFeeTransferValue, TQuoteRateFeePropertyValueCodec, TQuoteRateFeeTransferValueCodec } from "../QuoteRateFee";
import { TUuidFormCodec, UuidForm } from "./UuidForm";

export const QuoteRateLiveRate = intersection([
    QuoteRate1,
    QuoteRate2,
    required({
        tag: literal("live"),
        transfer_value_fees: array(QuoteRateFeeTransferValue),
        property_value_fees: array(QuoteRateFeePropertyValue),
        set_will_be_deleted_on_next_publish_form: UuidForm,
        set_will_be_deleted_on_next_publish_and_replace_form: UuidForm,
    }),
]);
export type TQuoteRateLiveRateCodec = typeof QuoteRateLiveRate;
export type TQuoteRateLiveRate = TTypeOfCodec<TQuoteRateLiveRateCodec>;
export const isQuoteRateLiveRate = (p: Record<string, unknown>): p is TQuoteRateLiveRate =>
    "tag" in p && p.tag === "live";


type TQuoteRateCreatedFormEditedCodec = TIntersectionCodec<[
    TQuoteRate1Codec,
    TRequiredCodec<{
        transfer_value_fees: TArrayCodec<TQuoteRateFeeTransferValueCodec>,
        property_value_fees: TArrayCodec<TQuoteRateFeePropertyValueCodec>,
    }>
]>;
const QuoteRateCreatedFormEdited: TQuoteRateCreatedFormEditedCodec = intersection([
    QuoteRate1,
    required({
        transfer_value_fees: array(QuoteRateFeeTransferValue),
        property_value_fees: array(QuoteRateFeePropertyValue),
    })
]);

type TQuoteRateCreatedFormChildrenCodec = TIntersectionCodec<[
    TRequiredCodec<{
        tag: TLiteralCodec<"created">,
        delete_form: TUuidFormCodec,
        missing_fees_preventing_publish: TBooleanCodec,
        introducer_id: TUnionCodec<[TNullCodec, TUuidCodec]>,
    }>,
    TQuoteRate2Codec
]>;
const QuoteRateCreatedFormChildren: TQuoteRateCreatedFormChildrenCodec = intersection([
    required({
        tag: literal("created"),
        delete_form: UuidForm,
        missing_fees_preventing_publish: boolean(),
        introducer_id: union([nullCodec(), uuid()]),
    }),
    QuoteRate2,
]);

export const QuoteRateCreatedOverlappingForm = form(
    QuoteRateCreatedFormEdited,
    QuoteRateCreatedFormChildren,
);
export type TQuoteRateCreatedOverlappingForm = TTypeOfCodec<typeof QuoteRateCreatedOverlappingForm>;

export const QuoteRateOverlappingForm = union([
    QuoteRateLiveRate,
    QuoteRateCreatedOverlappingForm,
]);
export type TQuoteRateOverlappingFormCodec = typeof QuoteRateOverlappingForm;
export type TQuoteRateOverlappingForm = TTypeOfCodec<typeof QuoteRateOverlappingForm>;
export const QuoteRateOverlappingForm_fold = <T>(p: {
    onQuoteRateLiveRate: (form: TQuoteRateLiveRate) => T,
    onQuoteRateCreatedOverlappingForm: (form: TQuoteRateCreatedOverlappingForm) => T,
}) => (f: TQuoteRateOverlappingForm): T =>
    isQuoteRateLiveRate(f)
        ? p.onQuoteRateLiveRate(f)
        : p.onQuoteRateCreatedOverlappingForm(f);


type TQuoteRatedCreateFormCodec = TFormCodec<
    TQuoteRateCreatedFormEditedCodec,
    TIntersectionCodec<[
        TQuoteRateCreatedFormChildrenCodec,
        TRequiredCodec<{
            overlapping_rates_preventing_publish: TArrayCodec<TQuoteRateOverlappingFormCodec>
        }>
    ]>
>;

export const QuoteRateCreatedForm: TQuoteRatedCreateFormCodec = form(
    QuoteRateCreatedFormEdited,
    intersection([
        QuoteRateCreatedFormChildren,
        required({
            overlapping_rates_preventing_publish: array(QuoteRateOverlappingForm)
        })
    ]),
);
export type TQuoteRateCreatedFormCodec = typeof QuoteRateCreatedForm;
export type TQuoteRateCreatedForm = TTypeOfCodec<TQuoteRateCreatedFormCodec>;

export const QuoteRateDeleteOnNextPublishForm: TFormCodec<
    TIntersectionCodec<[
        TQuoteRate1Codec,
        TRequiredCodec<{
            transfer_value_fees: TArrayCodec<TQuoteRateFeeTransferValueCodec>,
            property_value_fees: TArrayCodec<TQuoteRateFeePropertyValueCodec>,
        }>
    ]>,
    TIntersectionCodec<[
        TRequiredCodec<{
            tag: TLiteralCodec<"delete_on_next_publish">,
            undo_delete_on_next_publish_form: TUuidFormCodec,
        }>,
        TQuoteRate2Codec,
    ]>
> = form(
    intersection([
        QuoteRate1,
        required({
            transfer_value_fees: array(QuoteRateFeeTransferValue),
            property_value_fees: array(QuoteRateFeePropertyValue),
        })
    ]),
    intersection([
        required({
            tag: literal("delete_on_next_publish"),
            undo_delete_on_next_publish_form: UuidForm,
        }),
        QuoteRate2,
    ]),
);
export type TQuoteRateDeleteOnNextPublishFormCodec = typeof QuoteRateDeleteOnNextPublishForm;
export type TQuoteRateDeleteOnNextPublishForm = TTypeOfCodec<TQuoteRateDeleteOnNextPublishFormCodec>;

export const QuoteRateForm: TUnionCodec<[
    TQuoteRateCreatedFormCodec,
    TQuoteRateDeleteOnNextPublishFormCodec,
]> = union([
    QuoteRateCreatedForm,
    QuoteRateDeleteOnNextPublishForm,
]);
export type TQuoteRateFormCodec = typeof QuoteRateForm;
export type TQuoteRateForm = TTypeOfCodec<TQuoteRateFormCodec>;

export const fold = <T>(p: {
    onCreatedForm: (f: TQuoteRateCreatedForm) => T,
    onDeleteOnNextPublishForm: (f: TQuoteRateDeleteOnNextPublishForm) => T,
}) => (q: TQuoteRateForm): T =>
    q.children.tag === "created" ? p.onCreatedForm(q as TQuoteRateCreatedForm)
    : q.children.tag === "delete_on_next_publish" ? p.onDeleteOnNextPublishForm(q as TQuoteRateDeleteOnNextPublishForm)
    : requireExhaustive(q.children);    

export const QuoteRateIntroducerIdForm: TFormCodec<
    TPartialCodec<{
        introducer_id: TUuidCodec,
    }>,
    TEmptyObjectCodec
> = form(
    partial({
        introducer_id: uuid(),
    }),
    EmptyObject,
);
export type TQuoteRateIntroducerIdFormCodec = typeof QuoteRateIntroducerIdForm;
export type TQuoteRateIntroducerIdForm = TTypeOfCodec<TQuoteRateIntroducerIdFormCodec>;

export const QuoteRatePageForm: TFormCodec<
    TPartialCodec<{
        introducer_id: TUuidCodec,
    }>,
    TRequiredCodec<{
        live_rates: TArrayCodec<TQuoteRateLiveRateCodec>,
        rate_changes: TArrayCodec<TQuoteRateFormCodec>,
        create_rate_form: TQuoteRateIntroducerIdFormCodec,
        can_publish_rates: TBooleanCodec,
        publish_rates_form: TQuoteRateIntroducerIdFormCodec,
    }>
> = form(
    partial({
        introducer_id: uuid(),
    }),
    required({
        live_rates: array(QuoteRateLiveRate),
        rate_changes: array(QuoteRateForm),
        create_rate_form: QuoteRateIntroducerIdForm,
        can_publish_rates: boolean(),
        publish_rates_form: QuoteRateIntroducerIdForm,
    }),
);
export type TQuoteRatePageFormCodec = typeof QuoteRatePageForm;
export type TQuoteRatePageForm = TTypeOfCodec<TQuoteRatePageFormCodec>;
export type TQuoteRatePageFormNewDefault = TTypeOfNewDefault<TQuoteRatePageFormCodec>;

export const QuoteRateActiveTypeVisible = union([
    literal("LIVE_RATES"),
    literal("RATE_CHANGES"),
]);
export type TQuoteRateActiveTypeVisibleCodec = typeof QuoteRateActiveTypeVisible;
export type TQuoteRateActiveTypeVisible = TTypeOfCodec<TQuoteRateActiveTypeVisibleCodec>;
