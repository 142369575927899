import React from "react";

export default (props: React.SVGAttributes<SVGElement>) => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g clipPath="url(#clip0_6479_32387)">
<path d="M9 5L9 9L3 9L3 15L9 15L9 19L16 12L9 5ZM11 13L5 13L5 11L11 11L11 9.83L13.17 12L11 14.17L11 13ZM18 19L18 5L20 5L20 19L18 19Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_6479_32387">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>
;
