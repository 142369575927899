import { intersection, TIntersectionCodec } from "../../shared/src/codecs/types/intersection";
import { literal, TLiteralCodec } from "../../shared/src/codecs/types/literal";
import { required, TRequiredCodec } from "../../shared/src/codecs/types/required";
import { requiredFlatOverloaded, TRequiredFlatOverloadedCodec } from "../../shared/src/codecs/types/requiredFlatOverloaded";
import { TUnionCodec, union } from "../../shared/src/codecs/types/union";
import { TAnyUuidCodec, uuid } from "../../shared/src/codecs/types/uuid";
import { string, TAnyStringCodec } from "../../shared/src/codecs/types/string";
import { Address2, Address5, TAddress2Codec, TAddress5Codec } from "./Address";
import { ListingAdHocTaskOverload, ListingAdHocTaskSectionOverload, TListingAdHocTaskOverloadCodec, TListingAdHocTaskSectionOverloadCodec } from "./ListingAdHocTask";
import { boolean, TBooleanCodec } from "../../shared/src/codecs/types/boolean";
import { NonEmptyArray } from "fp-ts/lib/NonEmptyArray";
import { TTypeOfCodec } from "../../shared/src/codecs/codec";
import { TUser10Codec, User10 } from "./User10";
import { BuyerEnquiriesJobs, JobTypes, KeyManagementJobs, LegalCaseBasicJobs, ListingPrepJobs, ListingProgressionJobs, ListingValuationJobs, MarketingJobs, TBuyerEnquiriesJobType, TJobCaseAdHocJobType, TJobLegalCaseBasicJobType, TJobListingEnquiryAdHocJobType, TJobListingEnquiryOffersShareFeedbackWithBuyerJobType, TJobListingPublicNoticeJobType, TJobType, TListingGeneralJobType, TJobLegalAdminJobType, LegalAdminJobs, SailProbateHelplineJobs, TSailProbateHelplineJobType, ListingServiceJobs } from "../JobType";
import { nullCodec, TNullCodec } from "../../shared/src/codecs/types/nullCodec";
import { Introducer3, TIntroducer3Codec } from "./Introducer";
import { TArrayCodec, array as arrayCodec } from "../../shared/src/codecs/types/array";
import { ListingServiceStatus, TListingServiceStatusCodec } from "./retool/ListingService";
import { ListingServiceType, TListingServiceTypeCodec } from "./ListingServiceType";
import { CarSaleStatus, TCarSaleStatusCodec } from "./retool/CarSale";



type TJobListingAdHocCodec = TIntersectionCodec<[
    TRequiredCodec<{
        type: TLiteralCodec<TListingGeneralJobType>;
        listing_id: TAnyUuidCodec;
    }>,
    TRequiredFlatOverloadedCodec<{
        address: TAddress2Codec;
        section: TListingAdHocTaskSectionOverloadCodec;
        task: TListingAdHocTaskOverloadCodec;
    }>
]>;

export type TJobListingAdHoc = TTypeOfCodec<TJobListingAdHocCodec>;

const JobListingAdHocCodecs: NonEmptyArray<TJobListingAdHocCodec> = [
    intersection([
        required({
            type: literal("sh_listing_ad_hoc"),
            listing_id: uuid(),
        }),
        requiredFlatOverloaded({
            address: Address2,
            section: ListingAdHocTaskSectionOverload,
            task: ListingAdHocTaskOverload,
        })
    ])
];



type TJobListingPublicNoticeCodec = TIntersectionCodec<[
    TRequiredCodec<{
        type: TLiteralCodec<TJobListingPublicNoticeJobType>;
        listing_id: TAnyUuidCodec;
        public_notice_task: TUnionCodec<[TLiteralCodec<"add_or_update">, TLiteralCodec<"remove">]>
    }>,
    TRequiredFlatOverloadedCodec<{
        address: TAddress2Codec;
    }>
]>;

export type TJobListingPublicNotice = TTypeOfCodec<TJobListingPublicNoticeCodec>;

const JobListingPublicNoticeCodecs: NonEmptyArray<TJobListingPublicNoticeCodec> = [
    intersection([
        required({
            type: literal("sh_rightmove_public_notice"),
            listing_id: uuid(),
            public_notice_task: union([literal("add_or_update"), literal("remove")]),
        }),
        requiredFlatOverloaded({
            address: Address2,
        })
    ])
];


type TJobListingEnquiryAdHocCodec = TIntersectionCodec<[
    TRequiredCodec<{
        type: TLiteralCodec<TJobListingEnquiryAdHocJobType>;
        listing_id: TAnyUuidCodec;
        listings_enquiry_id: TAnyUuidCodec;
        task: TAnyStringCodec;
    }>,
    TRequiredFlatOverloadedCodec<{
        users: TUser10Codec;
    }>,
    TRequiredFlatOverloadedCodec<{
        address: TAddress2Codec;
    }>,
]>;

export type TJobListingEnquiryAdHoc = TTypeOfCodec<TJobListingEnquiryAdHocCodec>;

const JobListingEnquiryAdHocCodecs: NonEmptyArray<TJobListingEnquiryAdHocCodec> = [
    intersection([
        required({
            type: literal("sh_listing_enquiry_ad_hoc"),
            listings_enquiry_id: uuid(),
            listing_id: uuid(),
            task: string(),
        }),
        requiredFlatOverloaded({
            users: User10,
        }),
        requiredFlatOverloaded({
            address: Address2
        }),
    ])
];


type TJobListingEnquiryOffersShareFeedbackWithBuyerCodec = TIntersectionCodec<[
    TRequiredCodec<{
        type: TLiteralCodec<TJobListingEnquiryOffersShareFeedbackWithBuyerJobType>;
        listing_id: TAnyUuidCodec;
        listings_enquiry_id: TAnyUuidCodec;
        is_gazumping: TBooleanCodec;
    }>,
    TRequiredFlatOverloadedCodec<{
        users: TUser10Codec;
    }>,
    TRequiredFlatOverloadedCodec<{
        address: TAddress2Codec;
    }>,
]>;

export type TJobListingEnquiryOffersShareFeedbackWithBuyer = TTypeOfCodec<TJobListingEnquiryOffersShareFeedbackWithBuyerCodec>;

const JobListingEnquiryOffersShareFeedbackWithBuyerCodecs: NonEmptyArray<TJobListingEnquiryOffersShareFeedbackWithBuyerCodec> = [
    intersection([
        required({
            type: literal("sh_offers_share_feedback_with_buyer"),
            listings_enquiry_id: uuid(),
            listing_id: uuid(),
            is_gazumping: boolean(),
        }),
        requiredFlatOverloaded({
            users: User10,
        }),
        requiredFlatOverloaded({
            address: Address2
        }),
    ])
];

type TJobListingBasicJobType =
    typeof ListingPrepJobs[number]
    | typeof ListingValuationJobs[number]
    | typeof ListingProgressionJobs[number]
    | typeof KeyManagementJobs[number]
    | typeof MarketingJobs[number]
;

type TJobListingBasicCodec = TIntersectionCodec<[
    TRequiredCodec<{
        type: TLiteralCodec<TJobListingBasicJobType>;
        listing_id: TAnyUuidCodec;
    }>,
    TRequiredFlatOverloadedCodec<{
        address: TAddress2Codec;
    }>
]>;

export type TJobListingBasic = TTypeOfCodec<TJobListingBasicCodec>;

const createListingJob = (type: TJobListingBasicJobType): TJobListingBasicCodec => intersection([
    required({
        type: literal(type),
        listing_id: uuid()
    }),
    requiredFlatOverloaded({
        address: Address2
    })
]);

const JobListingBasicCodecs: NonEmptyArray<TJobListingBasicCodec> = [
    ...ListingPrepJobs.map((jobType) => createListingJob(jobType)) as NonEmptyArray<TJobListingBasicCodec>,
    ...ListingValuationJobs.map((jobType) => createListingJob(jobType)) as NonEmptyArray<TJobListingBasicCodec>,
    ...ListingProgressionJobs.map((jobType) => createListingJob(jobType)) as NonEmptyArray<TJobListingBasicCodec>,
    ...KeyManagementJobs.map((jobType) => createListingJob(jobType)) as NonEmptyArray<TJobListingBasicCodec>,
    ...MarketingJobs.map((jobType) => createListingJob(jobType)) as NonEmptyArray<TJobListingBasicCodec>
] as NonEmptyArray<TJobListingBasicCodec>;


type TListingEnquiryJobCodec = TIntersectionCodec<[
    TRequiredCodec<{
        type: TLiteralCodec<TBuyerEnquiriesJobType>;
        listings_enquiry_id: TAnyUuidCodec;
        listing_id: TAnyUuidCodec;
    }>,
    TRequiredFlatOverloadedCodec<{
        users: TUser10Codec;
    }>,
    TRequiredFlatOverloadedCodec<{
        address: TAddress2Codec;
    }>,
]>;

export type TJobListingEnquiryBasic = TTypeOfCodec<TListingEnquiryJobCodec>;

const createListingEnquiryJob = (type: TBuyerEnquiriesJobType): TListingEnquiryJobCodec => intersection([
    required({
        type: literal(type),
        listings_enquiry_id: uuid(),
        listing_id: uuid(),
    }),
    requiredFlatOverloaded({
        users: User10,
    }),
    requiredFlatOverloaded({
        address: Address2
    }),
]);

const JobListingEnquiryBasicCodecs: NonEmptyArray<TListingEnquiryJobCodec> =
    BuyerEnquiriesJobs.map((jobType) => createListingEnquiryJob(jobType)) as NonEmptyArray<TListingEnquiryJobCodec>;

export type TJobListingServiceCodec = TIntersectionCodec<[
    TRequiredCodec<{
        type: TLiteralCodec<"sh_progress_additional_service">;
        listing_id: TAnyUuidCodec;
        listing_service_id: TAnyUuidCodec;
        status: TListingServiceStatusCodec,
        service_type: TListingServiceTypeCodec
    }>,
    TRequiredFlatOverloadedCodec<{
        address: TAddress2Codec;
    }>
]>;

export type TJobListingService = TTypeOfCodec<TJobListingServiceCodec>;

const createListingServiceJob = (): TJobListingServiceCodec => intersection([
    required({
        type: literal("sh_progress_additional_service"),
        listing_id: uuid(),
        listing_service_id: uuid(),
        status: ListingServiceStatus,
        service_type: ListingServiceType
    }),
    requiredFlatOverloaded({
        address: Address2
    })
]);

const JobListingServiceCodecs: NonEmptyArray<TJobListingServiceCodec> =
    ListingServiceJobs.map(() => createListingServiceJob()) as NonEmptyArray<TJobListingServiceCodec>;

export type TJobListingCarSaleCodec = TIntersectionCodec<[
    TRequiredCodec<{
        type: TLiteralCodec<"sh_progress_car_sale">;
        listing_id: TAnyUuidCodec;
        car_sale_id: TAnyUuidCodec;
        status: TCarSaleStatusCodec
    }>,
    TRequiredFlatOverloadedCodec<{
        address: TAddress2Codec;
    }>
]>;

export type TJobListingCarSale = TTypeOfCodec<TJobListingCarSaleCodec>;

const createListingCarSaleJob = (): TJobListingCarSaleCodec => intersection([
    required({
        type: literal("sh_progress_car_sale"),
        listing_id: uuid(),
        car_sale_id: uuid(),
        status: CarSaleStatus,
    }),
    requiredFlatOverloaded({
        address: Address2
    })
]);

const JobListingCarSaleCodecs: NonEmptyArray<TJobListingCarSaleCodec> =
    ListingServiceJobs.map(() => createListingCarSaleJob()) as NonEmptyArray<TJobListingCarSaleCodec>;

type TLegalCaseJobCodec = TIntersectionCodec<[
    TRequiredCodec<{
        type: TLiteralCodec<TJobLegalCaseBasicJobType>;
        case_id: TAnyUuidCodec;
        case_handler_id: TUnionCodec<[TAnyUuidCodec, TNullCodec]>;
        case_assistant_id: TUnionCodec<[TAnyUuidCodec, TNullCodec]>;
    }>,
    TRequiredFlatOverloadedCodec<{
        users: TUser10Codec;
    }>,
    TRequiredFlatOverloadedCodec<{
        address: TAddress5Codec;
    }>,
]>;

export type TJobLegalCaseBasic = TTypeOfCodec<TLegalCaseJobCodec>;

const createLegalCaseJob = (type: TJobLegalCaseBasicJobType): TLegalCaseJobCodec => intersection([
    required({
        type: literal(type),
        case_id: uuid(),
        case_handler_id: union([uuid(), nullCodec()]),
        case_assistant_id: union([uuid(), nullCodec()]),
    }),
    requiredFlatOverloaded({
        users: User10,
    }),
    requiredFlatOverloaded({
        address: Address5,
    }),
]);

const JobLegalCaseBasicCodecs: NonEmptyArray<TLegalCaseJobCodec> = 
    LegalCaseBasicJobs.map((jobType) => createLegalCaseJob(jobType)) as NonEmptyArray<TLegalCaseJobCodec>;

export type TLegalAdminJobCodec = TRequiredCodec<{
    type: TLiteralCodec<TJobLegalAdminJobType>;
    introducers: TArrayCodec<TIntroducer3Codec>;
}>;

export type TSailProbateHelplineJobCodec = TRequiredCodec<{
    type: TLiteralCodec<TSailProbateHelplineJobType>;
}>;

const createLegalAdminJob = (type: TJobLegalAdminJobType): TLegalAdminJobCodec => required({
    type: literal(type),
    introducers: arrayCodec(Introducer3),
});

const createSailProbateHelplineJob = (type: TSailProbateHelplineJobType): TSailProbateHelplineJobCodec => required({
    type: literal(type),
});

const JobLegalAdminCodecs: NonEmptyArray<TLegalAdminJobCodec> = 
    LegalAdminJobs.map((jobType) => createLegalAdminJob(jobType)) as NonEmptyArray<TLegalAdminJobCodec>;
    
export type TJobLegalAdminJob = TTypeOfCodec<TLegalAdminJobCodec>;

const JobSailProbateHelplineCodecs: NonEmptyArray<TSailProbateHelplineJobCodec> = 
    SailProbateHelplineJobs.map((jobType) => createSailProbateHelplineJob(jobType)) as NonEmptyArray<TSailProbateHelplineJobCodec>;
    
export type TJobSailProbateHelplineJob = TTypeOfCodec<TSailProbateHelplineJobCodec>;

type TJobCaseAdHocCodec = TIntersectionCodec<[
    TRequiredCodec<{
        type: TLiteralCodec<TJobCaseAdHocJobType>;
        case_id: TAnyUuidCodec;
        job: TAnyStringCodec;
        case_handler_id: TUnionCodec<[TAnyUuidCodec, TNullCodec]>;
        case_assistant_id: TUnionCodec<[TAnyUuidCodec, TNullCodec]>;
    }>,
    TRequiredFlatOverloadedCodec<{
        users: TUser10Codec;
    }>,
    TRequiredFlatOverloadedCodec<{
        address: TAddress5Codec;
    }>,
]>;

export type TJobCaseAdHoc = TTypeOfCodec<TJobCaseAdHocCodec>;

const JobCaseAdHocCodecs: NonEmptyArray<TJobCaseAdHocCodec> = [
    intersection([
        required({
            type: literal("sl_case_ad_hoc"),
            case_id: uuid(),
            job: string(),
            case_handler_id: union([uuid(), nullCodec()]),
            case_assistant_id: union([uuid(), nullCodec()]),
        }),
        requiredFlatOverloaded({
            users: User10,
        }),
        requiredFlatOverloaded({
            address: Address5
        }),
    ])
];



type TAllJobs = NonEmptyArray<
    TJobListingBasicCodec
    | TListingEnquiryJobCodec
    | TLegalCaseJobCodec
    | TJobListingEnquiryOffersShareFeedbackWithBuyerCodec
    | TJobListingEnquiryAdHocCodec
    | TJobCaseAdHocCodec
    | TJobListingPublicNoticeCodec
    | TJobListingAdHocCodec
    | TLegalAdminJobCodec
    | TSailProbateHelplineJobCodec
    | TJobListingServiceCodec
    | TJobListingCarSaleCodec
>;


export const JobTypeCodec: TUnionCodec<NonEmptyArray<TLiteralCodec<TJobType>>> = union(
    JobTypes.map((type) => literal(type)) as NonEmptyArray<TLiteralCodec<TJobType>>
);
export type TJobTypeCodec = typeof JobTypeCodec;

export const Job1: TUnionCodec<TAllJobs> = union([
    ...JobListingBasicCodecs,
    ...JobListingEnquiryBasicCodecs,
    ...JobLegalCaseBasicCodecs,
    ...JobListingEnquiryOffersShareFeedbackWithBuyerCodecs,
    ...JobListingEnquiryAdHocCodecs,
    ...JobCaseAdHocCodecs,
    ...JobListingPublicNoticeCodecs,
    ...JobListingAdHocCodecs,
    ...JobLegalAdminCodecs,
    ...JobSailProbateHelplineCodecs,
    ...JobListingServiceCodecs,
    ...JobListingCarSaleCodecs
] as TAllJobs);

export type TJob1Codec = typeof Job1;

export type TJob1 = TTypeOfCodec<TJob1Codec>;

type TAllListingJobs =
    TJobListingBasicCodec
    | TJobListingAdHocCodec
    | TJobListingPublicNoticeCodec
;

export const AllListingJobs: TUnionCodec<NonEmptyArray<TAllListingJobs>> = union([
    ...JobListingBasicCodecs,
    ...JobListingAdHocCodecs,
    ...JobListingPublicNoticeCodecs
] as NonEmptyArray<TAllListingJobs>);

type TAllListingEnquiryJobs =
    TJobListingEnquiryAdHocCodec
    | TJobListingEnquiryOffersShareFeedbackWithBuyerCodec
;

export const AllListingEnquiryJobs: TUnionCodec<NonEmptyArray<TAllListingEnquiryJobs>> = union([
    ...JobListingEnquiryBasicCodecs,
    ...JobListingEnquiryAdHocCodecs,
    ...JobListingEnquiryOffersShareFeedbackWithBuyerCodecs
] as NonEmptyArray<TAllListingEnquiryJobs>);

type TAllLegalCaseJobs =
    TLegalCaseJobCodec
    | TJobCaseAdHocCodec
;

export const AllLegalCaseJobs: TUnionCodec<NonEmptyArray<TAllLegalCaseJobs>> = union([
    ...JobLegalCaseBasicCodecs,
    ...JobCaseAdHocCodecs,
] as NonEmptyArray<TAllLegalCaseJobs>);