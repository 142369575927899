import { required } from "../../shared/src/codecs/types/required";
import { TTypeOfCodec, TTypeOfNewDefault } from "../../shared/src/codecs/codec";
import { uuid } from "../../shared/src/codecs/types/uuid";
import { TUnionCodec, union } from "../../shared/src/codecs/types/union";
import { intersection } from "../../shared/src/codecs/types/intersection";
import { nullCodec } from "../../shared/src/codecs/types/nullCodec";
import { ActionType1 } from "./ActionType";
import { TransactionType1 } from "./TransactionType";
import { Address5 } from "./Address";
import { requiredFlatOverloaded } from "../../shared/src/codecs/types/requiredFlatOverloaded";
import { boolean } from "../../shared/src/codecs/types/boolean";
import { deferDateTime } from "../../shared/src/codecs/types/deferDateTime";
import { string } from "../../shared/src/codecs/types/string";
import { longString } from "../../shared/src/codecs/types/longString";
import { currencyInteger } from "../../shared/src/codecs/types/currencyInteger";
import { dateTimeOrNullAsBoolean } from "../../shared/src/codecs/types/dateTimeOrNullAsBoolean";
import { date } from "../../shared/src/codecs/types/date";
import { dateTime } from "../../shared/src/codecs/types/dateTime";
import { LegalCaseKeyDateEvent1 } from "./LegalCaseKeyDateEvent";
import { overload } from "../../shared/src/codecs/types/overload";
import * as util from "../../shared/src/util";
import { CasesClientBankAccountChangesLocked } from "./overloads/CasesClientBankAccountChangesLocked";
import { literal, TLiteralCodec } from "../../shared/src/codecs/types/literal";
import { CaseClientNames } from "./overloads/CaseClientNames";
import { integer } from "../../shared/src/codecs/types/integer";
import { decimal } from "../../shared/src/codecs/types/decimal";
import { Country1 } from "./Country";
import { postcode } from "../../shared/src/codecs/types/postcode";
import { CaseStatusTypes, TCaseStatusType } from "../../domain/models/CaseStatus1";
import { NonEmptyArray } from "fp-ts/lib/NonEmptyArray";
import { AddressFirstLines } from "./overloads/AddressFirstLine";
import { positiveInteger } from "../../shared/src/codecs/types/positiveInteger";
import { CasesPurchasePaymentMethod1 } from "./CasesPurchasePaymentMethod";
import { CaseSellerReasonForSale1 } from "./CaseSellerReasonForSale";
import { email } from "../../shared/src/codecs/types/email";
import { phoneNumber } from "../../shared/src/codecs/types/phoneNumber";
import { array } from "../../shared/src/codecs/types/array";

export const CaseRefHandlerAndAddressCodec = intersection([
    required({
        klyant_matter_id: string(),
        case_handler_email_address: union([nullCodec(), string()]),
        ledger_finalised_at: union([nullCodec(), dateTime()]),
    }),
    requiredFlatOverloaded({
        address: Address5,
    }),
]);
export type TCaseRefHandlerAndAddressCodec = TTypeOfCodec<typeof CaseRefHandlerAndAddressCodec>;

export const Cases1 = intersection([
    required({
        id: uuid(),
        documents_status: ActionType1,
        raise_enquiries_status: ActionType1,
        approve_enquiries_status: ActionType1,
        send_enquiries_status: ActionType1,
        resolve_enquiries_status: ActionType1,
    }),
    requiredFlatOverloaded({
        address: Address5,
    }),
]);
export type TCases1Codec = typeof Cases1;
export type TCases1 = TTypeOfCodec<typeof Cases1>;

const CasesChaseInstructionDefer = required({
    chase_instruction_defer: union([deferDateTime(), nullCodec()]),
});

export const Cases9 =
    required({
        is_offer_accepted: dateTimeOrNullAsBoolean(),
        offer_accepted_deferred: union([deferDateTime(), nullCodec()]),
        oes_ordered: dateTimeOrNullAsBoolean(),
    });

export const CasesQuoteSentToClient = required({
    quote_sent_to_client: dateTimeOrNullAsBoolean(),
});

export const CasesMilestonesReportsPartial = required({
    reports_sent_to_client: dateTimeOrNullAsBoolean(),
});

export const CasesMilestonesSaleOnlyPartial = required({
    enquiries_received_from_buyers: dateTimeOrNullAsBoolean(),
});

export const CasesMilestonesSearches = required({
    searches_ordered: dateTimeOrNullAsBoolean(),
    searches_ordered_deferred: union([deferDateTime(), nullCodec()]),
});

export const CasesIsClosedBeforeCompletion =
    required({
        is_closed_before_completion: dateTimeOrNullAsBoolean(),
    });
export type TCasesIsClosedBeforeCompletionCodec = typeof CasesIsClosedBeforeCompletion;
export type TCasesIsClosedBeforeCompletion = TTypeOfCodec<TCasesIsClosedBeforeCompletionCodec>;

export const CasesIsCreatedInError =
    required({
        created_in_error: dateTimeOrNullAsBoolean(),
    });
export type TCasesIsCreatedInErrorCodec = typeof CasesIsCreatedInError;
export type TCasesIsCreatedInError = TTypeOfCodec<TCasesIsCreatedInErrorCodec>;

export const Cases4 = required({
    all_clients_added_to_case_deferred: union([deferDateTime(), nullCodec()]),
    all_clients_added_and_right_to_buy_or_sell_checked: boolean(),
});

export const CasesIsTransactingAsCompanyStatus = union([
    literal("unknown"),
    literal("yes"),
    literal("no"),
]);
export type TCasesIsTransactingAsCompanyStatusCodec = typeof CasesIsTransactingAsCompanyStatus;
export const CasesIsTransactingAsCompany = required({
    is_transacting_as_company: CasesIsTransactingAsCompanyStatus,
});

export const CasesCompany = required({
    company_name: string(),
    company_number: string(),
});

export const CasesCompanyAddress = required({
    company_building_number: string(),
    company_building_name: string(),
    company_sub_building_number: string(),
    company_sub_building_name: string(),
    company_street_name: string(),
    company_city_town: string(),
    company_postcode: union([postcode(), nullCodec()]),
    company_county: string(),
    company_country: Country1,
    company_locality: string(),
    company_district: string(),
});

export const CaseCompanyIsOverseas = required({
    company_is_overseas_for_transfer: dateTimeOrNullAsBoolean(),
});

export const CaseCompanyOverseasDetails = required({
    company_overseas_incorporation_territory: string(),
    company_overseas_companies_house_entity_id: string(),
    company_overseas_companies_house_uk_registered_id: string(),
});

export const CaseCompanyFull = intersection([
    CasesIsTransactingAsCompany,
    CasesCompany,
    CasesCompanyAddress,
    CaseCompanyIsOverseas,
    CaseCompanyOverseasDetails,
]);
export type TCaseCompanyFullCodec = typeof CaseCompanyFull;
export type TCaseCompanyFull = TTypeOfCodec<TCaseCompanyFullCodec>;

const Cases7 = required({
    raise_enquiries_job_defer: union([deferDateTime(), nullCodec()]),
    approve_enquiries_job_defer: union([deferDateTime(), nullCodec()]),
    send_enquiries_job_defer: union([deferDateTime(), nullCodec()]),
    resolve_enquiries_job_defer: union([deferDateTime(), nullCodec()]),
});

export const CasesEmailPhoneVerification = required({
    email_and_phone_number_validation_check_passed: dateTimeOrNullAsBoolean(),
});

export const CasesPurchaseMortgageLenderNameKnown = union([
    literal("unknown"),
    literal("yes"),
    literal("not_yet"),
]);
export type TCasesPurchaseMortgageLenderNameKnownCodec = typeof CasesPurchaseMortgageLenderNameKnown;
export type TCasesPurchaseMortgageLenderNameKnown = TTypeOfCodec<TCasesPurchaseMortgageLenderNameKnownCodec>;

export const CasesMortgageLenderNameKnownQuestion = required({
    mortgage_lender_name_known: CasesPurchaseMortgageLenderNameKnown,
});
export type TCasesMortgageLenderNameKnownQuestion = typeof CasesMortgageLenderNameKnownQuestion;

export const Cases10 = required({
    klyant_client_id: string(),
    klyant_matter_id: string(),
    set_up_case_in_klyant_job_defer: union([deferDateTime(), nullCodec()]),
});

export const CasesClientFunds = required({
    funds_requested_from_client: dateTimeOrNullAsBoolean(),
    funds_requested_from_client_defer: union([deferDateTime(), nullCodec()]),
    funds_expected_from_client_at: union([date(), nullCodec()]),
    funds_received_from_client: dateTimeOrNullAsBoolean(),
    funds_from_client_match_completion_statement: boolean(),
});

export const CasesProceedsOfSaleReceived = required({
    proceeds_of_sale_received_from_buyer_solicitor: dateTimeOrNullAsBoolean(),
    proceeds_of_sale_match_completion_statement: boolean(),
    proceeds_of_sale_defer: union([deferDateTime(), nullCodec()]),
});

export const CaseExpectedExchange = required({
    is_ready_for_exchange_review: dateTimeOrNullAsBoolean(),
    defer_exchange_review_actions: union([deferDateTime(), nullCodec()]),
    ready_for_exchange_review_notes: longString(),
    defer_exchange_and_complete_jobs: union([deferDateTime(), nullCodec()]),
    expected_exchange_date: union([nullCodec(), date()]),
});

export const CaseExchange = required({
    contracts_released_until: union([dateTime(), nullCodec()]),
    exchanged_with: string(),
    time_of_exchange: union([dateTime(), nullCodec()]),
    client_notified_of_exchange: dateTimeOrNullAsBoolean(),
    estate_agent_notified_of_exchange: dateTimeOrNullAsBoolean(),
});

export const CaseExpectedCompletion = required({
    expected_completion_date: union([nullCodec(), date()]),
});

export const CaseCompletion = required({
    has_completed: dateTimeOrNullAsBoolean(),
});

export const CaseExchangeAndComplete = intersection([
    CaseExpectedExchange,
    CaseExchange,
    CaseExpectedCompletion,
    CaseCompletion
]);

export const CasePostCompletion = required({
    final_closing_review_completed: dateTimeOrNullAsBoolean(),
    all_ledgers_are_clear: dateTimeOrNullAsBoolean(),
});

const CaseHandlerAndAssistant = required({
    case_handler_id: union([uuid(), nullCodec()]),
    case_assistant_id: union([uuid(), nullCodec()]),
});

export const CaseNotes = required({
    staff_notes: longString(),
});

export const CaseStatus: TUnionCodec<NonEmptyArray<TLiteralCodec<TCaseStatusType>>> = union(
    CaseStatusTypes.map((type) => literal(type)) as NonEmptyArray<TLiteralCodec<TCaseStatusType>>
);
export type TCaseStatusCodec = typeof CaseStatus;
export type TCaseStatus = TTypeOfCodec<TCaseStatusCodec>;

// NB if adding any additional chase reasons which are abeyance - ensure you update
// the filter in Cases.ts and GetCasesCaseViewController.ts
export const ChaseReasonsWithoutAwaitingOpenNewCase = union([
    literal("abeyance_property_not_yet_on_market"),
    literal("abeyance_awaiting_mos"),
    literal("abeyance_awaiting_gop_or_loa"),
    literal("abeyance_client_asked_to_pause_work"),
    literal("abeyance_on_market_awaiting_offer"),
    literal("abeyance_awaiting_death_certificate"),
    literal("other"),
    literal("awaiting_enquiries"),
    literal("awaiting_replies_to_enquiries"),
    literal("awaiting_replies_to_enquiries_management_co"),
    literal("awaiting_replies_to_enquiries_client"),
    literal("awaiting_contract_pack"),
    literal("awaiting_lpe1"),
    literal("awaiting_management_co_to_send_something"),
    literal("awaiting_redemption_statement"),
    literal("awaiting_client_post"),
    literal("awaiting_client_doc_signing"),
    literal("awaiting_money_from_client"),
    literal("awaiting_client_completing_protocol_forms"),
    literal("awaiting_other_side_related_transaction_to_catch_up"),
    literal("awaiting_response_from_local_authority"),
    literal("awaiting_response_from_other_side"),
    literal("awaiting_response_from_client"),
    literal("awaiting_response_from_help_to_buy"),
    literal("awaiting_completion_date_from_other_side"),
    literal("awaiting_searches"),
    literal("awaiting_client_mortgage_offer"),
]);
export type TChaseReasonsWithoutAwaitingOpenNewCaseCodec = typeof ChaseReasonsWithoutAwaitingOpenNewCase;
export type TChaseReasonsWithoutAwaitingOpenNewCase = TTypeOfCodec<typeof ChaseReasonsWithoutAwaitingOpenNewCase>;

export const ChaseReasons = union([
    ChaseReasonsWithoutAwaitingOpenNewCase,
    literal("awaiting_opening_new_case"),
]);
export type TChaseReasonsCodec = typeof ChaseReasons;
export type TChaseReasons = TTypeOfCodec<typeof ChaseReasons>;


export const CaseChaseReasons = required({
    chase_reasons: array(ChaseReasons),
});
export type TCaseChaseReasonsCodec = typeof CaseChaseReasons;
export type TCaseChaseReasons = TTypeOfCodec<TCaseChaseReasonsCodec>;

export const CaseRootReadOnlyProps = required({
    id: uuid(),
    introducer_id: union([uuid(), nullCodec()]),
    introducer_referral_fee_gbp_pence: union([positiveInteger(), nullCodec()]),
    introducer_external_reference_id: string(),
    introducer_notes: string(),
    status: CaseStatus,
    is_instructed: union([nullCodec(), dateTime()]),
    is_signed_off_by_authoriser_as_ready_for_exchange: union([nullCodec(), dateTime()]),
    expected_exchange_date_updated_at: union([nullCodec(), dateTime()]),
    can_forward_or_shred_post: boolean(),
    transaction_type: TransactionType1,
    searches_required: union([nullCodec(), boolean()]),
    seller_to_purchase_searches: dateTimeOrNullAsBoolean(),
    purchase_payment_method: CasesPurchasePaymentMethod1,
    price_pence: union([currencyInteger(), nullCodec()]),
    seller_reason_for_sale: CaseSellerReasonForSale1,
    has_management_company: union([boolean(), nullCodec()]),
    other_side_conveyancer_name: string(),
    other_side_conveyancer_email_address: union([email(), nullCodec()]),
});
export type TCaseRootReadOnlyPropsCodec = typeof CaseRootReadOnlyProps;
export type TCaseRootReadOnlyPropsNewDefault = TTypeOfNewDefault<typeof CaseRootReadOnlyProps>;

export const CaseStatusOnly = required({
    status: CaseStatus,
});
export type TCaseStatusOnlyCodec = typeof CaseStatusOnly;
export type TCaseStatusOnlyNewDefault = TTypeOfNewDefault<typeof CaseStatusOnly>;

export const CaseEnquiryTimestamps = required({
    all_enquiries_approved_timestamp: union([nullCodec(), dateTime()]),
    all_enquiries_sent_timestamp: union([nullCodec(), dateTime()]),
    all_enquiries_resolved_timestamp: union([nullCodec(), dateTime()]),
});
export type TCaseEnquiryTimestampsCodec = typeof CaseEnquiryTimestamps;
export type TCaseEnquiryTimestamps = TTypeOfCodec<typeof CaseEnquiryTimestamps>;

const CaseClientBankAccount = required({
    client_changes_to_bank_accounts_locked: dateTimeOrNullAsBoolean(),
    bank_account_verification_defer: union([deferDateTime(), nullCodec()]),
});

const CaseSurveyRequested = union([
    literal("yes"),
    literal("no"),
    literal("unknown"),
]);
export const CaseSurvey = required({
    survey_requested: CaseSurveyRequested,
    survey_requested_defer: union([deferDateTime(), nullCodec()]),
});

const CaseMortgageOfferEarlyRedemptionPenalty = union([
    literal("yes"),
    literal("no"),
    literal("unknown"),
]);
export const CaseMortgageOfferDates = required({
    mortgage_offer_date: union([date(), nullCodec()]),
    mortgage_offer_details_defer: union([deferDateTime(), nullCodec()]),
    mortgage_offer_expiry_date: union([date(), nullCodec()]),
});

export const CaseMortgageOfferDetails = required({
    mortgage_offer_advance_amount: union([currencyInteger(), nullCodec()]),
    mortgage_offer_retention_amount: union([currencyInteger(), nullCodec()]),
    mortgage_offer_term_years: union([integer(), nullCodec()]),
    mortgage_offer_interest_rate: union([decimal(), nullCodec()]),
    mortgage_offer_early_redemption_penalty: CaseMortgageOfferEarlyRedemptionPenalty,
    mortgage_offer_all_client_names_correct: boolean(),
    mortgage_offer_address_correct: boolean(),
    mortgage_offer_amount_matches_purchase_price: boolean(),
    mortgage_offer_special_conditions: longString(),
    mortgage_offer_tenure_matches_contract_tenure: boolean(),
    mortgage_offer_lender_requirements_checked_and_case_can_proceed: boolean(),  
    mortgage_offer_we_require_buildings_insurance: union([boolean(), nullCodec()]),
    mortgage_offer_an_occupier_consent_form_is_required: union([boolean(), nullCodec()]),
});

export const CaseMortgageOffer = intersection([
    CaseMortgageOfferDates,
    CaseMortgageOfferDetails
])

export const CaseMortgageLenderAndBrokerContactInfo = required({
    mortgage_offer_broker_email: union([email(), nullCodec()]),
    mortgage_offer_broker_phone_number: union([phoneNumber(), nullCodec()]),
    mortgage_offer_lender_email: union([email(), nullCodec()]),
    mortgage_offer_lender_phone_number: union([phoneNumber(), nullCodec()]),
})

export const CaseMortgageOfferLeaseholdRequirementsChecked = required({
    mortgage_offer_we_have_checked_leasehold_requirements: boolean(),
});

export const CaseMortgageOfferDetailsWithLeaseholdRequirementsChecked = intersection([
    CaseMortgageOfferDetails,
    CaseMortgageOfferLeaseholdRequirementsChecked,
]);

export const CaseMortgageOfferMattersToRefer = required({
    mortgage_offer_there_are_matters_we_need_to_refer: union([boolean(), nullCodec()]),
});

export const CaseMortgageOfferMattersToReferWithDetails = intersection([
    CaseMortgageOfferMattersToRefer,
    required({
        mortgage_offer_matters_to_refer_details: longString(),
    })
]);

export const CaseMortgageOfferRetentionExplainedToClient = required({
    mortgage_offer_retention_explained_to_client: boolean(),
    mortgage_offer_retention_explained_to_client_defer: union([deferDateTime(), nullCodec()]),
});

export const CaseMortgageOfferFundsRequestAndReceive = required({
    mortgage_funds_requested_from_lender: dateTimeOrNullAsBoolean(),
    mortgage_request_and_receive_funds_defer: union([deferDateTime(), nullCodec()]),
    mortgage_funds_expected_from_lender_by: union([date(), nullCodec()]),
    mortgage_funds_received_from_lender: dateTimeOrNullAsBoolean(),
    mortgage_funds_match_completion_statement_and_mortgage_offer: boolean(),
});

export const CaseOutgoingPaymentsSellerSolicitor = required({
    seller_solicitor_paid: dateTimeOrNullAsBoolean(),
    seller_solicitor_payments_defer: union([deferDateTime(), nullCodec()]),
    seller_solicitor_received_payment: dateTimeOrNullAsBoolean(),
});

export const CaseOutgoingPaymentsSailLegalInvoice = required({
    funds_transferred_to_office_account_for_sail_legal_invoice: dateTimeOrNullAsBoolean(),
});

const CasesSellerConveyancerBankAccountVerificationStatus = union([
    literal("pass"),
    literal("fail"),
    literal("unknown"),
]);
export const CasesSellerConveyancerBankAccount = required({
    seller_conveyancer_bank_account_lawyer_checker_url: string(),
    seller_conveyancer_bank_account_verification_status: CasesSellerConveyancerBankAccountVerificationStatus,
    seller_conveyancer_bank_account_defer: union([deferDateTime(), nullCodec()]),
});

export const CaseLedgerFinaliseJobDefer =
    required({
        finalise_case_ledger_defer: union([dateTime(), nullCodec()]),
    });

export const CaseChasePaymentsDueJobDefer =
    required({
        chase_payments_due_defer: union([dateTime(), nullCodec()]),
    });

export const PayoutLedgerItemsDefer = 
    required({
        payout_ledger_items_defer: union([dateTime(), nullCodec()]),
    });

export const CaseRegistrationSubmitted =
    required({
        registration_submitted: dateTimeOrNullAsBoolean(),
    });

export const CaseRegistrationSubmissionRequisitionsAndComplete =
    required({
        registration_land_reg_link: string(),
        submit_registration_defer: union([deferDateTime(), nullCodec()]),
        registration_requisitions_exist: dateTimeOrNullAsBoolean(),
        registration_requisitions_details: longString(),
        registration_requisitions_resolved: dateTimeOrNullAsBoolean(),
        resolve_requisition_defer: union([deferDateTime(), nullCodec()]),
        registration_submission_cancelled: dateTimeOrNullAsBoolean(),
        registration_cancellation_details: longString(),
        registration_resubmitted: dateTimeOrNullAsBoolean(),
        registration_resubmission_defer: union([deferDateTime(), nullCodec()]),
        registration_complete: dateTimeOrNullAsBoolean(),
        registration_complete_defer: union([deferDateTime(), nullCodec()]),
        post_forwarded_or_shredded: dateTimeOrNullAsBoolean(),
    });
export type TCaseRegistrationSubmissionRequisitionsAndCompleteCodec = typeof CaseRegistrationSubmissionRequisitionsAndComplete;
export type TCaseRegistrationSubmissionRequisitionsAndComplete = TTypeOfCodec<TCaseRegistrationSubmissionRequisitionsAndCompleteCodec>;

export const CaseIntroducerLastUpdatedAt = 
    required({
        introducer_last_updated_at: union([dateTime(), nullCodec()]),
    });

export const CasesRelatedTransactionNeededBeforeCompletionType = union([
    literal("unknown"),
    literal("yes"),
    literal("no"),
]);
export const CasesRelatedPurchaseAddressKnownType = union([
    literal("unknown"),
    literal("yes"),
    literal("not_yet"),
]);
export const CasesRelatedTransactionQuestion = required({
    related_transaction_needed_before_completion: CasesRelatedTransactionNeededBeforeCompletionType,
    related_purchase_address_known: CasesRelatedPurchaseAddressKnownType,
});
export type TCasesRelatedTransactionQuestion = typeof CasesRelatedTransactionQuestion;

export const CasesSdltAnswersReviewOutcome = union([
    literal("unknown"),
    literal("referred_to_compass"),
    literal("compass_referral_not_required"),
]);
export type TCasesSdltAnswersReviewOutcome = TTypeOfCodec<typeof CasesSdltAnswersReviewOutcome>;
export const CasesSdltAnswersReview = required({
    sdlt_answers_reviewed_outcome: CasesSdltAnswersReviewOutcome,
    sdlt_answers_review_defer: union([deferDateTime(), nullCodec()]),
});

export const CaseManagementToggles = required({
    is_property_report: boolean(),
    using_esigning: boolean(),
});

export const CaseInstructed = required({
    is_instructed: dateTimeOrNullAsBoolean(),
});

export const CaseIsSignedOffAsReadyForExchange = required({
    is_signed_off_by_authoriser_as_ready_for_exchange: dateTimeOrNullAsBoolean(),
    signed_off_by_authoriser_as_ready_for_exchange_user: union([uuid(), nullCodec()]),
});

export const CaseTitleRestriction = required({
    add_charges_and_title_restrictions_job_done_at: union([dateTime(), nullCodec()]),
    add_charges_and_title_restrictions_job_defer: union([deferDateTime(), nullCodec()]),
});

export const CompanyType = union([
    literal("unknown"),
    literal("uk_ltd"),
    literal("uk_unlisted"),
    literal("uk_llp_or_scottish_partnership"),
    literal("companies_on_other_regulated_markets_with_similar_controls"),
    literal("companies_on_other_markets_without_similar_controls"),
    literal("public_sector_or_government"),
    literal("trusts"),
]);
export type TCompanyType = TTypeOfCodec<typeof CompanyType>;

export const CompanyType_displayString = (companyType: TCompanyType): string =>
    companyType === "unknown" ? "Not set"
    : companyType === "uk_ltd" ? "UK LTD"
    : companyType === "uk_unlisted" ? "UK Unlisted"
    : companyType === "uk_llp_or_scottish_partnership" ? "UK LLP / Scottish Partnership"
    : companyType === "companies_on_other_markets_without_similar_controls" ? "Private and unlisted overseas companies (Non UK, EEA country without equivalent controls)"
    : companyType === "companies_on_other_regulated_markets_with_similar_controls" ? "Companies listed on other regulated markets (EEA or a country with equivalent controls)"
    : companyType === "public_sector_or_government" ? "Public sector bodies or government agencies"
    : companyType === "trusts" ? "Trusts (settlors, trustees and beneficiaries)"
    : util.requireExhaustive(companyType);

export const CaseCompanyTypeInfo = required({
    company_type: CompanyType,
    company_sub_type_listing_on_any_stock_exchange: union([boolean(), nullCodec()]),
    company_sub_type_aim_listed: union([boolean(), nullCodec()]),
    company_sub_type_majority_owned_or_consolidated_subsidiary: union([boolean(), nullCodec()]),
    company_sub_type_fca_regulated: union([boolean(), nullCodec()]),
    company_sub_type_other_non_uk_credit_or_financial_institutions: union([boolean(), nullCodec()]),
    company_sub_type_uk_or_eea_public_authorities: union([boolean(), nullCodec()]),
    company_sub_type_professionals_instructing: union([boolean(), nullCodec()]),
    company_sub_type_foundation: union([boolean(), nullCodec()]),
    company_sub_type_uk_charity: union([boolean(), nullCodec()]),
    company_sub_type_place_of_worship: union([boolean(), nullCodec()]),
    company_sub_type_state_school_or_college: union([boolean(), nullCodec()]),
    company_sub_type_club_or_association: union([boolean(), nullCodec()]),
    company_sub_type_pension_scheme: union([boolean(), nullCodec()]),
    company_sub_type_sovereign_wealth_funds: union([boolean(), nullCodec()]),
});
export type TCaseCompanyTypeInfo = TTypeOfCodec<typeof CaseCompanyTypeInfo>;

export const CasesManagementCompanyDetails = required({
    management_company_name: string(),
    management_company_contact_name: string(),
    management_company_phone_number: union([phoneNumber(), nullCodec()]),
    management_company_email: union([email(), nullCodec()]),
    requires_apportionment_statement: union([boolean(), nullCodec()]),
    client_has_share_of_the_freehold: union([boolean(), nullCodec()]),
    management_company_job_defer: union([deferDateTime(), nullCodec()]),
    lpe1_ordered: dateTimeOrNullAsBoolean(),
    lpe1_ordered_deferred: union([deferDateTime(), nullCodec()]),
});

export const CaseEditableFull = intersection([
    required({
        id: uuid(),
    }),
    Cases9,
    CasesChaseInstructionDefer,
    Cases4,
    CasesIsTransactingAsCompany,
    CasesCompany,
    CasesCompanyAddress,
    CaseCompanyIsOverseas,
    CaseCompanyOverseasDetails,
    Cases7,
    CasesEmailPhoneVerification,
    CasesMortgageLenderNameKnownQuestion,
    Cases10,
    CaseExchangeAndComplete,
    CasePostCompletion,
    CasesClientFunds,
    CasesProceedsOfSaleReceived,
    CaseHandlerAndAssistant,
    CaseNotes,
    CaseClientBankAccount,
    CasesIsClosedBeforeCompletion,
    CasesIsCreatedInError,
    CaseSurvey,
    CaseMortgageOffer,
    CaseMortgageOfferLeaseholdRequirementsChecked,
    CaseMortgageOfferMattersToReferWithDetails,
    CaseMortgageOfferRetentionExplainedToClient,
    CaseMortgageOfferFundsRequestAndReceive,
    CaseOutgoingPaymentsSellerSolicitor,
    CaseOutgoingPaymentsSailLegalInvoice,
    CasesSellerConveyancerBankAccount,
    CaseManagementToggles,
    CaseLedgerFinaliseJobDefer,
    CaseChasePaymentsDueJobDefer,
    PayoutLedgerItemsDefer,
    CaseRegistrationSubmitted,
    CaseRegistrationSubmissionRequisitionsAndComplete,
    CaseIntroducerLastUpdatedAt,
    CasesMilestonesReportsPartial,
    CasesQuoteSentToClient,
    CasesMilestonesSaleOnlyPartial,
    CasesMilestonesSearches,
    CasesRelatedTransactionQuestion,
    CasesSdltAnswersReview,
    CaseTitleRestriction,
    CaseCompanyTypeInfo,
    CaseInstructed,
    CaseIsSignedOffAsReadyForExchange,
    CasesManagementCompanyDetails,
    required({
        video_verification_call_defer: union([deferDateTime(), nullCodec()]),
        clients_authorise_exchange_and_complete: union([dateTime(), nullCodec()]),
    }),
    CaseMortgageLenderAndBrokerContactInfo
]);
export type TCaseEditableFullCodec = typeof CaseEditableFull;
export type TCaseEditableFull = TTypeOfCodec<typeof CaseEditableFull>;

export const CaseIdAndAddresses = intersection([
    required({
        id: union([nullCodec(), uuid()]),
    }),
    requiredFlatOverloaded({
        address: Address5,
    })
]);
export type TCaseIdAndAddressesCodec = typeof CaseIdAndAddresses;
export type TCaseIdAndAddresses = TTypeOfCodec<typeof CaseIdAndAddresses>;


export const CasesKeyDateEvent = intersection([
    required({
        case_id: uuid(),
        event_date: date(),
        event_type: overload(
            string(),
            LegalCaseKeyDateEvent1,
            (event_type) => {
                switch (event_type) {
                    case "expected_exchange":
                        return "Expected Exchange";
                    case "expected_completion":
                        return "Expected Completion";
                    case "completion_deadline":
                        return "Completion Deadline";
                    case "exchange_deadline":
                        return "Exchange Deadline";
                    default:
                        return util.requireExhaustive(event_type);
                }
            }
        )
    }),
    requiredFlatOverloaded({
        address: Address5,
    })
]);
export type TCasesKeyDateEventCodec = typeof CasesKeyDateEvent;
export type TCasesKeyDateEvent = TTypeOfCodec<typeof CasesKeyDateEvent>;

export const ClientCase = intersection([
    required({
        id: uuid(),
        transaction_type: TransactionType1,
    }),
    requiredFlatOverloaded({
        changes_to_bank_accounts_locked: CasesClientBankAccountChangesLocked,
    }),
    requiredFlatOverloaded({
        address: AddressFirstLines,
    }),
])
export type TClientCaseCodec = typeof ClientCase;

export const CaseSummaryForAllCases = intersection([
    required({
        id: uuid(),
        klyant_matter_id: string(),
        case_handler_id: union([nullCodec(), uuid()]),
        is_dual_rep: boolean(),
    }),
    requiredFlatOverloaded({
        address: Address5,
    }),
    requiredFlatOverloaded({
        client_names: CaseClientNames,
    }),
]);
export type TCaseSummaryForAllCasesCodec = typeof CaseSummaryForAllCases;
export type TCaseSummaryForAllCases = TTypeOfCodec<typeof CaseSummaryForAllCases>;

export const Cases14 = required({
    id: uuid(),
});
export type TCases14Codec = typeof Cases14;
export type TCases14 = TTypeOfCodec<TCases14Codec>;

export const CasesSdlt = required({
    id: uuid(),
    sdlt_transaction_results_in_owning_two_or_more: union([literal("unknown"), literal("yes"), literal("no"), literal("dont_know")]),
    sdlt_spouse_or_partner_owning_other_property: union([literal("unknown"), literal("yes"), literal("no"), literal("dont_know")]),
    sdlt_joint_buyers_owning_other_property: union([literal("unknown"), literal("yes"), literal("no"), literal("dont_know")]),
    sdlt_child_owns_other_property: union([literal("unknown"), literal("yes"), literal("no"), literal("dont_know")]),
    sdlt_other_properties_value_supporting_notes: longString(),
    sdlt_transaction_replaces_main_residence: union([literal("unknown"), literal("yes"), literal("no"), literal("dont_know")]),
    sdlt_in_partnership_business_owning_other_property: union([literal("unknown"), literal("yes"), literal("no"), literal("dont_know")]),
    sdlt_is_shared_ownership: union([literal("unknown"), literal("yes"), literal("no"), literal("dont_know")]),
    sdlt_all_first_time_buyers: union([literal("unknown"), literal("yes"), literal("no"), literal("dont_know")]),
    sdlt_will_be_main_residence: union([literal("unknown"), literal("yes"), literal("no"), literal("dont_know")]),
    sdlt_any_other_properties_on_land: union([literal("unknown"), literal("yes"), literal("no"), literal("dont_know")]),
    sdlt_everyone_resides_in_uk: union([literal("unknown"), literal("yes"), literal("no"), literal("dont_know")]),
});
export type TCasesSdltCodec = typeof CasesSdlt;
export type TCasesSdlt = TTypeOfCodec<TCasesSdltCodec>;
