import React from "react";
import { CRMIcon } from "../../CRMIcon/CRMIcon";

type TDropdownFaceProps = {
    isOpen: boolean;
    toggleOpen: () => void;
    displayError?: boolean;
    disabled?: boolean;
};

export const CRMDropdownFace = (props: React.PropsWithChildren<TDropdownFaceProps>): JSX.Element => {

    return (
        <div
            className={`
                crm-dropdown-face
                crm-dropdown-face--${props.isOpen ? 'open' : 'closed'}-${props.displayError ? "error" : "no-error"}
                crm-dropdown-face--${props.disabled ? "disabled" : "enabled"}
            `}
            onClick={() => {
                if (!props.disabled) {
                    props.toggleOpen();
                }
            }}
        >
            {props.children}

            {/* ARROW */}
            <div
                className={`
                    crm-dropdown-face__icon
                    crm-dropdown-face__icon--${props.isOpen ? 'open' : 'closed'}
                `}
            >
                <CRMIcon
                    iconName="down-arrow" 
                    colour="neutral-ink" 
                />
            </div>
        </div>
    );
};
