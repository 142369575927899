import React from "react";
import { CRMColumns } from "../../components/CRMColumns/CRMColumns";
import { CRMSpacer } from "../../components/CRMSpacer/CRMSpacer";
import FrontParagraphRegular from "../../components/Front/Simple/FrontParagraphRegular/FrontParagraphRegular";
import FrontTitleMinor from "../../components/Front/Simple/FrontTitleMinor/FrontTitleMinor";
import FrontTitleSectionSubTitle from "../../components/Front/Simple/FrontTitleSectionSubTitle/FrontTitleSectionSubTitle";
import { Uppercase } from "../../components/Uppercase/Uppercase";
import { WeightBold } from "../../components/WeightBold/WeightBold";
import { TProbateCase2ReadOnlyFrom } from "../../models/TFormModels";
import * as ProbateCase1 from "../../../../domain/models/ProbateCase1";
import { FrontProbateWhatsIncluded } from "../../components/FrontProbateWhatsIncluded/FrontProbateWhatsIncluded";

interface IFrontProbateGOPQuoteContainerProps {
    fee: string;
    caseForm: TProbateCase2ReadOnlyFrom;
}

// Used in both GOP and Estate admin
export const getDynamicQuoteDetails = (caseForm: TProbateCase2ReadOnlyFrom): Array<string> => {
    const strings = [
        `${ProbateCase1.totalAssetCountExclProperty(caseForm.view)} assets (excluding property)`,
        `${ProbateCase1.totalLiabilitiesCount(caseForm.view)} liabilities`,
        `${caseForm.view.number_of_properties_owned ?? 0} properties${ caseForm.view.foreign_properties_owned ? " (including one or more foreign properties)" : ""}`,
    ];

    if (caseForm.view.estate_is_estimated_to_be_taxable) {
        strings.push("Estate tax caluculations");
    }

    if (caseForm.view.assets_and_liabilities_includes_foreign) {
        strings.push("Calculations for foreign assets/liabilities");
    }

    if (!caseForm.view.will_exists) {
        strings.push("Letters of Administration application paperwork");
    }

    return strings;
};

class FrontProbateGOPQuoteContainer extends React.Component<React.PropsWithChildren<IFrontProbateGOPQuoteContainerProps>> {
    public render(): JSX.Element {
        return (
            <div>
                <CRMColumns
                    padding="medium"
                    columns={
                        [
                            {
                                flex: 1.5,
                                content: (
                                    <WeightBold><FrontTitleMinor>Grant of Probate only</FrontTitleMinor> </WeightBold>
                                ),
                            },
                            {
                                flex: 1,
                                content: (
                                    <div style={{display: "flex", justifyContent: "flex-end"}}>
                                        <div style={{background: "#049DCC", color: "white", fontWeight: "bold", padding: "10px", borderRadius: "4px"}}>
                                            £{this.props.fee}
                                        </div>
                                    </div>
                                ),
                            },
                        ]
                    }
                />
                <CRMSpacer size="large"></CRMSpacer>
                <FrontParagraphRegular>
                    Obtain a grant of representation enabling you to deal with an estate. This then gives you control to adminster and distrubute the assets.
                </FrontParagraphRegular>
                <CRMSpacer size="large"></CRMSpacer>
                <WeightBold>
                    <Uppercase>
                        <FrontTitleSectionSubTitle text="Included services" />
                    </Uppercase>
                </WeightBold>
                <CRMSpacer size="medium"></CRMSpacer>
                <FrontProbateWhatsIncluded 
                    obtainingVals={false}
                    payingInheritanceTax={true}
                    gopApplication={true}
                    probateRegistryApplication={true}
                    postingGop={true}
                    contactFinInstToCloseAccounts={false}
                    collectingEstateFunds={false}
                    payingDebts={false}
                    payingIncomeCapitalGainsTax={false}
                    preparingEstateAccounts={false}
                    distributingFunds={false}
                    sellingProperties={true}
                />
                <hr></hr>
                <WeightBold>
                    <Uppercase>
                        <FrontTitleSectionSubTitle text="This quote is for" />
                    </Uppercase>
                </WeightBold>
                <CRMSpacer size="medium"></CRMSpacer>

                {
                    getDynamicQuoteDetails(this.props.caseForm).map(
                        (s, i) =>
                            (
                                <div key={i}>
                                    <FrontParagraphRegular>{s}</FrontParagraphRegular>
                                </div>
                            )
                    )
                }
            </div>
        );
    }
}

export default FrontProbateGOPQuoteContainer;
