export const constants = {
    CODEC_DECODE: "CODEC_DECODE" as const,
    REGEX_DOES_NOT_MATCH: "REGEX_DOES_NOT_MATCH" as const,
    CODEC_MAPPING_TO_ACCEPTABLE_RESPONSE: "CODEC_MAPPING_TO_ACCEPTABLE_RESPONSE" as const,
    FETCH_REQUEST_ERROR: "FETCH_REQUEST_ERROR" as const,
    FETCH_REQUEST_BODY_ERROR: "FETCH_REQUEST_BODY_ERROR" as const,
    PATH_PARAMETER_INVALID: "PATH_PARAMETER_INVALID" as const,
};

export type T = typeof constants[keyof typeof constants];
