import React from "react";
import { CRMIcon } from "../CRMIcon/CRMIcon";
import { TButtonIcon } from "../../models/TButton";
import { TCRMMainColours } from "../../models/TCRMMainColours";

export interface ICRMButtonIcon extends TButtonIcon {
    variant: "primary" | "secondary" | "tertiary" | "quaternary" | "quaternary-active";
    colour?: TCRMMainColours;
    disabledColour?: TCRMMainColours;
}

const getCrmIconColour = (props: ICRMButtonIcon): TCRMMainColours => {
    
    if (props.disabled && props.variant === "quaternary-active") {
        return props.disabledColour || "neutral-10";
    }

    if (props.variant === "quaternary-active") {
        return "neutral-paper";
    }

    if (props.disabled) {
        return props.disabledColour || "neutral-2";
    }
    
    return props.colour || "neutral-ink";
}

export class CRMButtonIcon extends React.Component<React.PropsWithChildren<ICRMButtonIcon>> {
    public render (): JSX.Element {
        return (
            <a
                title={this.props.title}
                href={!this.props.disabled ? this.props.href : undefined}
                className={`
                    crm-button-icon 
                    crm-button-icon--${this.props.variant}
                    crm-button-icon--${this.props.variant}-${this.props.disabled ? 'disabled' : 'enabled'}
                `}
                onClick={!this.props.disabled ? this.props.onClick : undefined}
                download={this.props.download}
                role="button"
            >
                <CRMIcon
                    iconName={this.props.icon}
                    colour={getCrmIconColour(this.props)}
                />
            </a>
        );
    }
}
