import { required, TRequiredCodec } from "../../../shared/src/codecs/types/required";
import { AdminPageFormIO, TAdminPageFormIOCodec } from "../formIO/AdminPageFormIO";

export const HomesAdminPagePartial: TRequiredCodec<{
    data: TAdminPageFormIOCodec;
}> = required({
    data: AdminPageFormIO
});

export type THomesAdminPagePartialCodec = typeof HomesAdminPagePartial;
