import React from "react";
import { TCRMMainColours  } from "../../models/TCRMMainColours";

interface ICRMTextHighlightProps {
    colour: TCRMMainColours;
    // If you are just using text inside of this component then you probably dont need to useInlineFlex, but if you are using the highlight to
    // nest something else and you can't get the alignment quite right then switch this on
    useInlineFlex?: boolean;
}

export class CRMTextHighlight extends React.Component<React.PropsWithChildren<ICRMTextHighlightProps>> {
    public render (): JSX.Element {
        return (
            <span className={`
                crm-text-highlight
                crm-text-highlight--colour-${this.props.colour}
                ${this.props.useInlineFlex ? "crm-text-highlight--inline-flex" : ""}
            `}>
                {this.props.children}
            </span>
        );
    }
}
