import React from "react";

export default (props: React.SVGAttributes<SVGElement>) => 
    <svg 
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clip-path="url(#clip0_11626_53443)">
            <path
                d="M12.9368 13.314L12.8998 13.5L14.9998 15.5V23H12.9998V17L11.0768 15.169L12.9368 13.314ZM2.3248 21.9L20.7248 3.55L19.3248 2.1L0.924805 20.5L2.3248 21.9ZM9.8998 19.4L7.3528 18.883L10.7978 15.447L9.8998 19.4ZM16.1428 10.117C16.2458 10.189 16.3528 10.258 16.4628 10.325C17.2038 10.775 18.0498 11 18.9998 11V13C17.8998 13 16.8708 12.771 15.9128 12.313C15.4668 12.1 15.0548 11.855 14.6768 11.579L16.1428 10.117ZM6.4248 13H5.9998V8.3L9.9498 6.6C10.5328 6.35 10.9628 6.188 11.2378 6.113C11.5128 6.037 11.7668 6 11.9998 6C12.3498 6 12.6748 6.092 12.9748 6.275C13.0108 6.297 13.0468 6.32 13.0808 6.344L9.6218 9.803L9.7998 8.9L7.9998 9.6V11.425L6.4248 13ZM13.9788 5.446C13.8268 5.482 13.6668 5.5 13.4998 5.5C12.9498 5.5 12.4788 5.304 12.0878 4.912C11.6958 4.521 11.4998 4.05 11.4998 3.5C11.4998 2.95 11.6958 2.479 12.0878 2.087C12.4788 1.696 12.9498 1.5 13.4998 1.5C14.0498 1.5 14.5208 1.696 14.9128 2.087C15.3038 2.479 15.4998 2.95 15.4998 3.5C15.4998 3.667 15.4818 3.827 15.4458 3.979L13.9788 5.446Z"
                fill="black"
            />
        </g>
        <defs>
            <clipPath id="clip0_11626_53443">
                <rect width="24" height="24" fill="white"/>
            </clipPath>
        </defs>
    </svg>
;
