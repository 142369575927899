import React, { CSSProperties } from "react";

type TTextColorProps = {
    color: string;
    display?: CSSProperties["display"];
};

/**
 * @deprecated Use @see Text
 */
export const TextColor = (props: React.PropsWithChildren<TTextColorProps>): JSX.Element => {
    return (
        <div style={{
            display: props.display,
            color: props.color
        }}>
            {props.children}
        </div>
    );
};
