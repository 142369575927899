import React from "react";

export class FrontTitlePageSection extends React.Component<React.PropsWithChildren<{}>> {
    public render (): JSX.Element {
        return (
            <div className={`front-title-page-section`}>
                {this.props.children}
            </div>
        );
    }
}
