import { TTypeOfCodec } from "../../../shared/src/codecs/codec";
import { array, TArrayCodec } from "../../../shared/src/codecs/types/array";
import { formIO, TFormIOCodec } from "../../../shared/src/codecs/types/formIO";
import { required, TRequiredCodec } from "../../../shared/src/codecs/types/required";
import { CasesKeyDateEvent, TCasesKeyDateEventCodec } from "../Cases";
import { EmptyObject, TEmptyObjectCodec } from "../EmptyObject";

export const LegalCasesKeyDatesFormIO: TFormIOCodec<TEmptyObjectCodec, TRequiredCodec<{
    events: TArrayCodec<TCasesKeyDateEventCodec>;
}>> = formIO(
    EmptyObject,
    required({
        events: array(CasesKeyDateEvent),
    }),
);

export type TLegalCasesKeyDatesFormIOCodec = typeof LegalCasesKeyDatesFormIO;

export type TLegalCasesKeyDatesFormIO = TTypeOfCodec<TLegalCasesKeyDatesFormIOCodec>;
