import * as t from "io-ts";
import * as CaseDocumentFile2 from "./CaseDocumentFile2";

const codec = t.type({
    ...CaseDocumentFile2.codec.props,
});

export type T = t.TypeOf<typeof codec>;

export const newDefault = (): T => ({
    name: "",
});
