import {  defaultTFormV2, defaultTListForm } from "../models/TForm";
import * as CaseDocument7 from "../../../domain/models/CaseDocument7";
import * as CaseDocumentForm1 from "../../../domain/models/CaseDocumentForm1";
import * as CaseDocumentForm2 from "../../../domain/models/CaseDocumentForm2";
import * as CaseDocumentFileForm1 from "../../../domain/models/CaseDocumentFileForm1";
import * as Case2 from "../../../domain/models/Case2";
import * as TFormModels from "../models/TFormModels";

export class C {
    caseForm: TFormModels.TCase2ReadOnlyForm = defaultTFormV2(Case2.newDefault(), {}, {});
    documentFormList: TFormModels.TCaseDocument6AndCaseDocumentForm1FormList = defaultTListForm(CaseDocumentForm1.newDefault(), {});
    documentFilesFormList: TFormModels.TCaseDocumentFile4AndCaseDocumentFileForm1FormList = defaultTListForm(CaseDocumentFileForm1.newDefault(), {});
    newDocumentForm: TFormModels.TCaseDocument7AndCaseDocumentForm2Form = defaultTFormV2(CaseDocument7.newDefault(), CaseDocumentForm2.newDefault(), {
        isShowingForm: false,
    });
    justFlaggedDocumentIds: Array<string> = [];
    activeUploads: Record<string, Record<string, "in_progress" | "complete" | "error">> = {};
}
