import React from "react";

export default (props: React.SVGAttributes<SVGElement>) => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g clipPath="url(#clip0_7215_35338)">
<path d="M6.99 11L3 15L6.99 19V16H14V14H6.99V11ZM21 9L17.01 5V8H10V10H17.01V13L21 9Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_7215_35338">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>
;
