import { intersection } from "io-ts";
import { TTypeOfCodec } from "../../../shared/src/codecs/codec";
import { array } from "../../../shared/src/codecs/types/array";
import { dateTime } from "../../../shared/src/codecs/types/dateTime";
import { form } from "../../../shared/src/codecs/types/form";
import { literal } from "../../../shared/src/codecs/types/literal";
import { nullCodec } from "../../../shared/src/codecs/types/nullCodec";
import { required } from "../../../shared/src/codecs/types/required";
import { union } from "../../../shared/src/codecs/types/union";
import { uuid } from "../../../shared/src/codecs/types/uuid";
import { ChaseReasons, ChaseReasonsWithoutAwaitingOpenNewCase } from "../Cases";
import { EmptyObject } from "../EmptyObject";

export const ChaseStatus = union([
    literal("workable"),
    literal("chase"),
    literal("workable_rejected_chase_request"),
]);
export type TChaseStatusTypeCodec = typeof ChaseStatus;
export type TChaseStatusType = TTypeOfCodec<TChaseStatusTypeCodec>;

export const ChaseAcknowledgementType = union([
    literal("acknowledge"),
    literal("accept"),
    literal("reject")
]);

export const CaseChaseStatus = required({
    status: ChaseStatus,
});
export type TCaseChaseStatusCodec = typeof CaseChaseStatus;
export type TCaseChaseStatus = TTypeOfCodec<TCaseChaseStatusCodec>;

export const ChaseModeUpdateForm = form(
    required({
        id: uuid()
    }),
    EmptyObject,
);
export type TChaseModeUpdateFormCodec = typeof ChaseModeUpdateForm;
export type TChaseModeUpdateForm = TTypeOfCodec<TChaseModeUpdateFormCodec>;

export const ChaseModeUpdateFormWithChaseReasons = form(
    required({
        id: uuid(),
        selected_chase_reasons: array(ChaseReasons)
    }),
    EmptyObject,
);
export type TChaseModeUpdateFormWithChaseReasonsCodec = typeof ChaseModeUpdateFormWithChaseReasons;
export type TChaseModeUpdateFormWithChaseReasons = TTypeOfCodec<TChaseModeUpdateFormWithChaseReasonsCodec>;

export const CaseChaseMode = required({
    id: uuid(),
});

export const CaseChaseModeForm = 
    form(
        CaseChaseMode,
        required({
            accept_chase_transition_form: ChaseModeUpdateForm,
            acknowledge_workable_transition_form: ChaseModeUpdateForm,
            reject_from_chase_mode_form: ChaseModeUpdateForm,
            submit_to_workable_mode_form: ChaseModeUpdateForm,
            submit_to_chase_mode_form: ChaseModeUpdateFormWithChaseReasons,
            submit_to_closed_awaiting_new_case_form: ChaseModeUpdateForm,
            submit_to_closed_unsuccessfully_form: ChaseModeUpdateForm,
            submit_to_closed_opened_in_error_form: ChaseModeUpdateForm,
            submit_to_reopen_form: ChaseModeUpdateForm,
            latest_chase: required(
                {
                    status: ChaseStatus,
                    acknowledged_at: union([nullCodec(), dateTime()]),
                    acknowledgement_type: union([ChaseAcknowledgementType, nullCodec()])
                }
            ),
            current_chase_reasons: array(ChaseReasons),
            available_chase_reasons: array(ChaseReasonsWithoutAwaitingOpenNewCase),
        })
    ); 
 
export type TCaseChaseModeFormCodec = typeof CaseChaseModeForm;
export type TCaseChaseModeForm = TTypeOfCodec<TCaseChaseModeFormCodec>;

export const CaseUpdateChaseModeForm = 
    form(
        required({
            case_id: uuid(),
            status: ChaseStatus,
            selected_chase_reasons: array(ChaseReasons),
        }),
        required({
            available_chase_reasons: array(ChaseReasonsWithoutAwaitingOpenNewCase),
        })
    ); 

export type TCaseUpdateChaseModeFormCodec = typeof CaseUpdateChaseModeForm;
export type TCaseUpdateChaseModeForm = TTypeOfCodec<TCaseUpdateChaseModeFormCodec>;