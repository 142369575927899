import * as t from "io-ts";
import * as JsonResponse1 from "./JsonResponse1";
import * as Listing3 from "./Listing3";

export const codec = JsonResponse1.createCodec(
    Listing3.codec,
    t.type({}),
);

export type T = t.TypeOf<typeof codec>;

export const newDefault = (): T => ({
    data: Listing3.newDefault(),
    meta: {},
});
