import * as Function from "../Function";
import * as Error from "../Error";
import * as ErrorCode from "../ErrorCode";
import { option } from "fp-ts/lib";

export const regex = (regexString: string | RegExp): Function.TValidationFunction =>
    (value: unknown): option.Option<Error.TValidationError> =>
        typeof value === "undefined" || (
            typeof value === "string" &&
            (
                typeof regexString === "object"
                    ? regexString.test(value)
                    : RegExp(regexString).test(value)
            )
        ) ? option.none : option.some([[ErrorCode.validationErrorCodeConstants.REGEX_VALIDATION]]);
