import * as t from "io-ts";

export const codec = t.type({
    available_count: t.number,
    returned_count: t.number,
    offset: t.number,
    limit: t.number,
});

export type T = t.TypeOf<typeof codec>;

export const newDefault = (): T => ({
    available_count: 0,
    returned_count: 0,
    offset: 0,
    limit: 0,
});
