import { TForm, defaultTListForm, dataToForm } from "../models/TForm";
import * as TFormStatus from "../models/TFormStatus";
import * as FirstPartyFetchResponse from "../../../domain/models/FirstPartyFetchResponse";
import * as PartyResponse1 from "../../../domain/models/PartyResponse1";
import * as Party7 from "../../../domain/models/Party7";
import * as Party2 from "../../../domain/models/Party2";
import * as Party5 from "../../../domain/models/Party5";
import * as Party6 from "../../../domain/models/Party6";
import * as User6 from "../../../domain/models/User6";
import * as UserSuccessResponse2 from "../../../domain/models/UserSuccessResponse2";
import * as User5 from "../../../domain/models/User5";
import * as UserForm1 from "../../../domain/models/UserForm1";
import * as User4 from "../../../domain/models/User4";
import * as UserPhoneNumber2 from "../../../domain/models/UserPhoneNumber2";
import * as UserPhoneNumberForm1 from "../../../domain/models/UserPhoneNumberForm1";
import * as Listing3 from "../../../domain/models/Listing3";
import * as Enquiry3 from "../../../domain/models/Enquiry3";
import * as UserPhoneNumberSuccessResponse1 from "../../../domain/models/UserPhoneNumberSuccessResponse1";
import * as UserPhoneNumber1 from "../../../domain/models/UserPhoneNumber1";
import * as ListingEnquiriesViewing3 from "../../../domain/models/ListingEnquiriesViewing3";
import * as ListingEnquiriesViewing2 from "../../../domain/models/ListingEnquiriesViewing2";
import * as ListingSuccessResponse2 from "../../../domain/models/ListingSuccessResponse2";
import * as GetAddressIOResponse from "../../../domain/models/GetAddressIOResponse";
import * as JsonInnerError1 from "../../../domain/models/JsonInnerError1";
import * as Listing7 from "../../../domain/models/Listing7";
import * as ListingSuccessResponse1 from "../../../domain/models/ListingSuccessResponse1";
import * as UserSuccessResponse1 from "../../../domain/models/UserSuccessResponse1";
import * as Listing8 from "../../../domain/models/Listing8";
import { array, option, function as fptsFunction, ord } from "fp-ts/lib";
import * as fptsExtensions from "../../../shared/src/utilByDomainGroupExport";
import { pipe } from "fp-ts/lib/function";
import {
    TParty2AndC5Form,
    TParty2AndC7Form,
    TEnquiryC2AndC3FormList,
    TListing3FormList,
    TListingEnquiriesViewing2AndC3FormList,
    TListingEnquiriesViewing2FormList,
} from "./../models/TFormModels";
import { Ord } from "fp-ts/lib/boolean";
import { TValidationError } from "../../../shared/src/validation/Error";

export class C {
    partyResponse: FirstPartyFetchResponse.T<PartyResponse1.T> = FirstPartyFetchResponse.createNotRequested(PartyResponse1.newDefault());
    situationForm: TParty2AndC7Form = dataToForm<TParty2AndC7Form>(Party7.newDefault(), {})(Party2.newDefault());
    notesForm: TParty2AndC5Form = dataToForm<TParty2AndC5Form>(Party5.newDefault(), {})(Party2.newDefault());
    partySearchCriteriaForm: TForm<Party6.T, PartyResponse1.T, {}> = {
        status: "untouched",
        form: Party6.newDefault(),
        response: PartyResponse1.newDefault(),
        meta: {},
    };
    addPerson: {
        newUserForm: TForm<
            User6.T,
            FirstPartyFetchResponse.T<UserSuccessResponse2.T>,
            {
                partyId: string;
            }
        >;
        existingUser: {
            searchTerm: string;
            searchResults: Array<
                TForm<
                    User5.T,
                    FirstPartyFetchResponse.T<UserSuccessResponse2.T>,
                    User4.T
                >
            >;
        };
    } = {
        newUserForm: {
            status: TFormStatus.constants.REQUIRES_SUBMISSION,
            form: User6.newDefault(),
            response: FirstPartyFetchResponse.createNotRequested(UserSuccessResponse2.newDefault()),
            meta: {
                partyId: "",
            },
        },
        existingUser: {
            searchTerm: "",
            searchResults: [],
        },
    };
    users: Array<{
        userForm: TForm<
            UserForm1.T,
            FirstPartyFetchResponse.T<UserSuccessResponse2.T>,
            User4.T
        >;
        newPhoneNumber: {
            isVisible: boolean;
            form: TForm<
                UserPhoneNumber2.T,
                FirstPartyFetchResponse.T<UserSuccessResponse2.T>,
                User4.T
            >;
        };
        existingPhoneNumbers: Array<
            TForm<
                UserPhoneNumberForm1.T,
                FirstPartyFetchResponse.T<UserPhoneNumberSuccessResponse1.T>,
                UserPhoneNumber1.T
            >
        >;
    }> = [];

    enquiryListings: TListing3FormList = defaultTListForm(Listing3.newDefault(), {});
    enquiryForms: TEnquiryC2AndC3FormList = defaultTListForm(Enquiry3.newDefault(), {});
    viewingForms: TListingEnquiriesViewing2AndC3FormList = defaultTListForm(ListingEnquiriesViewing3.newDefault(), {});
    upcomingViewingForms: TListingEnquiriesViewing2FormList = defaultTListForm(ListingEnquiriesViewing2.newDefault(), {});
    newEnquiry: {
        listingSearchTerm: string;
        listingResponse: FirstPartyFetchResponse.T<ListingSuccessResponse2.T>;
    } = {
        listingSearchTerm: "",
        listingResponse: FirstPartyFetchResponse.createNotRequested(ListingSuccessResponse2.newDefault()),
    };
    listings: FirstPartyFetchResponse.T<ListingSuccessResponse2.T> = FirstPartyFetchResponse.createNotRequested(ListingSuccessResponse2.newDefault());
    addListingGetAddressForm: TForm<
        Listing8.T,
        GetAddressIOResponse.T,
        {
            validationInnerErrors: Array<JsonInnerError1.T>;
            errorMessage: string;
        }
    > = {
        status: "untouched",
        form: Listing8.newDefault(),
        response: GetAddressIOResponse.newDefault(),
        meta: {
            validationInnerErrors: [],
            errorMessage: "",
        },
    };
    addListingForm: TForm<NonNullable<Required<Listing7.T>>, FirstPartyFetchResponse.T<ListingSuccessResponse1.T>, {}> = {
        status: "untouched",
        form: Listing7.newDefault(),
        response: FirstPartyFetchResponse.createNotRequested(ListingSuccessResponse1.newDefault()),
        meta: {},
    };
}

export const reduceFromExistingUserSearchResults = (s: C, usersResponse: FirstPartyFetchResponse.T<UserSuccessResponse1.T>): C =>
     pipe(
        usersResponse.response.data,
        array.map((user) => ({
            status: TFormStatus.constants.UNTOUCHED,
            form: {
                user_id: user.id,
            },
            response: FirstPartyFetchResponse.createNotRequested(UserSuccessResponse2.newDefault()),
            meta: user,
        })),
        (searchResults) => ({
            ...s,
            addPerson: {
                ...s.addPerson,
                existingUser: {
                    ...s.addPerson.existingUser,
                    searchResults,
                },
            },
        })
    );

export const reduceFromPartyResponse = ({...s}: C, partyResponse: FirstPartyFetchResponse.T<PartyResponse1.T>): C => {
    s.partyResponse = partyResponse;
    s.users =  pipe(
        partyResponse.response.data.users,
        array.map((user) => ({
            userForm: {
                form: {
                    first_name: user.first_name,
                    last_name: user.last_name,
                    email: user.email,
                    is_legal_buyer: user.is_legal_buyer,
                    homes_first_legal_name: user.homes_first_legal_name,
                    homes_middle_legal_name: user.homes_middle_legal_name,
                    homes_last_legal_name: user.homes_last_legal_name,
                    homes_thirdfort_id_check_url: user.homes_thirdfort_id_check_url,
                    description_of_user: user.description_of_user,
                },
                status: TFormStatus.constants.UNTOUCHED,
                response: FirstPartyFetchResponse.createNotRequested(UserSuccessResponse2.newDefault()),
                meta: user,
            },
            newPhoneNumber: {
                isVisible: false,
                form: {
                    form: {
                        phone_number: "",
                    },
                    status: TFormStatus.constants.UNTOUCHED,
                    response: FirstPartyFetchResponse.createNotRequested(UserSuccessResponse2.newDefault()),
                    meta: user,
                },
            },
            existingPhoneNumbers:  pipe(
                user.phone_numbers,
                array.sortBy([ord.contramap((userPhoneNumber: UserPhoneNumber1.T) => !userPhoneNumber.primary_number)(Ord)]),
                array.map((userPhoneNumber) => ({
                    form: {
                        phone_number: userPhoneNumber.phone_number || "",
                        primary_number: userPhoneNumber.primary_number,
                    },
                    status: TFormStatus.constants.UNTOUCHED,
                    response: FirstPartyFetchResponse.createNotRequested(UserPhoneNumberSuccessResponse1.newDefault()),
                    meta: userPhoneNumber,
                })),
            ),
        })),
    );
    s.partySearchCriteriaForm.form = {
        search_criteria_area: partyResponse.response.data.search_criteria_area,
        search_criteria_min_beds: partyResponse.response.data.search_criteria_min_beds,
        search_criteria_max_price: partyResponse.response.data.search_criteria_max_price,
        search_criteria_preferences: partyResponse.response.data.search_criteria_preferences,
    };
    s.addPerson.newUserForm.meta.partyId = partyResponse.response.data.id;
    return s;
};

export const updateUserForm = (
    {...s}: C,
    userId: string,
    endomorphism: fptsFunction.Endomorphism<C["users"][number]["userForm"]>
): C => {
    s.users =  pipe(
        s.users,
        fptsExtensions.array.updateWhere(
            (user) => user.userForm.meta.id === userId,
            ({...u}) => {
                u.userForm = endomorphism(u.userForm);
                return u;
            },
        ),
    );
    return s;
};

export const updateUserNewPhoneNumberForm = (
    {...s}: C,
    userId: string,
    endomorphism: fptsFunction.Endomorphism<C["users"][number]["newPhoneNumber"]>
): C => {
    s.users =  pipe(
        s.users,
        fptsExtensions.array.updateWhere(
            (user) => user.newPhoneNumber.form.meta.id === userId,
            ({...u}) => {
                u.newPhoneNumber = endomorphism(u.newPhoneNumber);
                return u;
            },
        ),
    );
    return s;
};

export const updateUserPhoneNumberFormWhereId = (
    {...s}: C,
    userId: string,
    phoneNumberId: string,
    endomorphism: fptsFunction.Endomorphism<C["users"][number]["existingPhoneNumbers"][number]>
): C => {
    s.users =  pipe(
        s.users,
        fptsExtensions.array.updateWhere(
            (user) => user.userForm.meta.id === userId,
            ({...u}) => {
                u.existingPhoneNumbers =  pipe(
                    u.existingPhoneNumbers,
                    fptsExtensions.array.updateWhere(
                        (userPhoneNumber) => userPhoneNumber.meta.id === phoneNumberId,
                        ({...p}) => {
                            p = endomorphism(p);
                            return p;
                        },
                    ),
                );
                return u;
            }
        ),
    );
    return s;
};

export const updateUserPhoneNumberFormWhereIdNot = (
    {...s}: C,
    userId: string,
    excludePhoneNumberId: string,
    endomorphism: fptsFunction.Endomorphism<C["users"][number]["existingPhoneNumbers"][number]>
): C => {
    s.users =  pipe(
        s.users,
        fptsExtensions.array.updateWhere(
            (user) => user.userForm.meta.id === userId,
            ({...u}) => {
                u.existingPhoneNumbers =  pipe(
                    u.existingPhoneNumbers,
                    fptsExtensions.array.updateWhere(
                        (userPhoneNumber) => userPhoneNumber.meta.id !== excludePhoneNumberId,
                        ({...p}) => {
                            p = endomorphism(p);
                            return p;
                        },
                    ),
                );
                return u;
            }
        ),
    );
    return s;
};

export const updateUser = (
    {...s}: C,
    userId: string,
    endomorphism: fptsFunction.Endomorphism<C["users"][number]>
): C => {
    s.users =  pipe(
        s.users,
        fptsExtensions.array.updateWhere(
            (user) => user.userForm.meta.id === userId,
            endomorphism,
        ),
    );
    return s;
};

export type TUpdateUsersIndexedValidationResults = {
    validationFor: "userForm" | "userNewPhoneNumberForm";
    validationOption: option.Option<TValidationError>;
} | {
    validationFor: "userExistingPhoneNumberForm";
    phoneNumberId: string;
    validationOption: option.Option<TValidationError>;
};

type TUpdateUsersIndexedValidationResultsWithErrors = {
    validationFor: "userForm" | "userNewPhoneNumberForm";
    errors: TValidationError;
} | {
    validationFor: "userExistingPhoneNumberForm";
    phoneNumberId: string;
    errors: TValidationError;
};

export const updateUserFromValidationResultsWithErrors = (
    {...s}: C,
    userId: string,
    validationResultsWithErrors: TUpdateUsersIndexedValidationResultsWithErrors
): C =>
    updateUser(s, userId, (u) => {
        if (validationResultsWithErrors.validationFor === "userForm") {
            u.userForm.status = TFormStatus.constants.VALIDATION_ERROR;
            u.userForm.response = FirstPartyFetchResponse.create422(
                u.userForm.response.response,
                JsonInnerError1.arrayFromValidationErrors("Body", validationResultsWithErrors.errors),
            );
        } else if (validationResultsWithErrors.validationFor === "userNewPhoneNumberForm") {
            u.newPhoneNumber.form.status = TFormStatus.constants.VALIDATION_ERROR;
            u.newPhoneNumber.form.response = FirstPartyFetchResponse.create422(
                u.newPhoneNumber.form.response.response,
                JsonInnerError1.arrayFromValidationErrors("Body", validationResultsWithErrors.errors),
            );
        } else if (validationResultsWithErrors.validationFor === "userExistingPhoneNumberForm") {
            u.existingPhoneNumbers =  pipe(
                u.existingPhoneNumbers,
                array.findIndex((userPhoneNumber) => userPhoneNumber.meta.id === validationResultsWithErrors.phoneNumberId),
                option.fold(
                    () => u.existingPhoneNumbers,
                    (index) => {
                        u.existingPhoneNumbers[index].status = TFormStatus.constants.VALIDATION_ERROR;
                        u.existingPhoneNumbers[index].response = FirstPartyFetchResponse.create422(
                            u.existingPhoneNumbers[index].response.response,
                            JsonInnerError1.arrayFromValidationErrors("Body", validationResultsWithErrors.errors),
                        );
                        return u.existingPhoneNumbers;
                    }
                )
            );
        }
        return u;
    });
