import React from "react";

export default (props: React.SVGAttributes<SVGElement>) => <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g clipPath="url(#clip0_4107_18455)">
<path d="M31.8503 17.6H30.7503V14.3C30.7503 8.84114 26.3092 4.40002 20.8503 4.40002C15.3914 4.40002 10.9503 8.84114 10.9503 14.3V17.6H9.85029C8.03069 17.6 6.55029 19.0804 6.55029 20.9V38.5C6.55029 40.3196 8.03069 41.8 9.85029 41.8H31.8503C33.6699 41.8 35.1503 40.3196 35.1503 38.5V20.9C35.1503 19.0804 33.6699 17.6 31.8503 17.6ZM13.1503 14.3C13.1503 10.0542 16.6045 6.60002 20.8503 6.60002C25.0961 6.60002 28.5503 10.0542 28.5503 14.3V17.6H13.1503V14.3ZM32.9503 38.5C32.9503 39.1065 32.4568 39.6 31.8503 39.6H9.85029C9.24378 39.6 8.75029 39.1065 8.75029 38.5V20.9C8.75029 20.2935 9.24378 19.8 9.85029 19.8H31.8503C32.4568 19.8 32.9503 20.2935 32.9503 20.9V38.5Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_4107_18455">
<rect width="44" height="44" fill="white"/>
</clipPath>
</defs>
</svg>
;
