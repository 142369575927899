import { TTypeOfCodec } from "../../shared/src/codecs/codec";
import { literal } from "../../shared/src/codecs/types/literal";
import { union } from "../../shared/src/codecs/types/union";

export const InternalScope =  union([
    literal("user"),
    literal("sail_homes_staff"),
    literal("sail_legal_non_authoriser_staff"),
    literal("sail_legal_authoriser_staff"),
    literal("admin"),
]);

export const InternalScopeIsSailStaff: Array<TInternalScope> = [
    "sail_homes_staff",
    "sail_legal_non_authoriser_staff",
    "sail_legal_authoriser_staff",
    "admin",
];

export type TInternalScope = TTypeOfCodec<typeof InternalScope>;