import * as t from "io-ts";

const codec = t.type({
    all_files_checked_for_enquiries: t.boolean,
});

export type T = t.TypeOf<typeof codec>;

export const newDefault = (): T => ({
    all_files_checked_for_enquiries: false,
});
