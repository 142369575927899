import React from "react";
interface IFrontTitleSectionSubTitle {
    text: string;
}

class FrontTitleSectionSubTitle extends React.Component<React.PropsWithChildren<IFrontTitleSectionSubTitle>> {
    public render (): JSX.Element {
        return (
            <div className="front-title-section-sub-title">{this.props.text}</div>
        );
    }
}

export default FrontTitleSectionSubTitle;
