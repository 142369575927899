import React from "react";

export default (props: React.SVGAttributes<SVGElement>) => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g clipPath="url(#clip0_8130_38783)">
<path d="M12.875 11.0746V5.14961H14.375V8.52461L20.3 2.59961L21.375 3.67461L15.475 9.57461H18.8V11.0746H12.875ZM19.875 20.9996C17.8417 20.9996 15.8208 20.4996 13.8125 19.4996C11.8042 18.4996 10 17.1996 8.4 15.5996C6.8 13.9996 5.5 12.1954 4.5 10.1871C3.5 8.17878 3 6.15794 3 4.12461C3 3.80794 3.10833 3.54128 3.325 3.32461C3.54167 3.10794 3.80833 2.99961 4.125 2.99961H7.625C7.85833 2.99961 8.0625 3.07878 8.2375 3.23711C8.4125 3.39544 8.525 3.60794 8.575 3.87461L9.25 7.02461C9.28333 7.25794 9.27917 7.47044 9.2375 7.66211C9.19583 7.85378 9.10833 8.01628 8.975 8.14961L6.475 10.6746C7.40833 12.2246 8.45417 13.5746 9.6125 14.7246C10.7708 15.8746 12.0833 16.8496 13.55 17.6496L15.925 15.1996C16.0917 15.0163 16.2833 14.8871 16.5 14.8121C16.7167 14.7371 16.9333 14.7246 17.15 14.7746L20.125 15.4246C20.375 15.4746 20.5833 15.5996 20.75 15.7996C20.9167 15.9996 21 16.2329 21 16.4996V19.8746C21 20.1913 20.8917 20.4579 20.675 20.6746C20.4583 20.8913 20.1917 20.9996 19.875 20.9996ZM5.725 9.29961L7.75 7.24961L7.175 4.49961H4.5C4.5 5.14961 4.6 5.86211 4.8 6.63711C5 7.41211 5.30833 8.29961 5.725 9.29961ZM14.95 18.3746C15.6333 18.6913 16.375 18.9496 17.175 19.1496C17.975 19.3496 18.75 19.4663 19.5 19.4996V16.8246L16.925 16.2996L14.95 18.3746Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_8130_38783">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>
;
