import { TTypeOfCodec } from "../../../shared/src/codecs/codec";
import { form, TFormCodec } from "../../../shared/src/codecs/types/form";
import { CasePropertyNewTitleIssue, TCasePropertyNewTitleIssueCodec } from "../CasePropertyTitleIssue";
import { EmptyObject, TEmptyObjectCodec } from "../EmptyObject";

export const CasePropertyNewTitleIssueForm: TFormCodec<TCasePropertyNewTitleIssueCodec, TEmptyObjectCodec> = 
    form(
        CasePropertyNewTitleIssue,
        EmptyObject,
    );

export type TCasePropertyNewTitleIssueFormCodec = typeof CasePropertyNewTitleIssueForm;
export type TCasePropertyNewTitleIssueForm = TTypeOfCodec<TCasePropertyNewTitleIssueFormCodec>;