import { TTypeOfCodec } from "../../shared/src/codecs/codec";
import { boolean } from "../../shared/src/codecs/types/boolean";
import { currencyInteger } from "../../shared/src/codecs/types/currencyInteger";
import { date } from "../../shared/src/codecs/types/date";
import { dateTime } from "../../shared/src/codecs/types/dateTime";
import { dateTimeOrNullAsBoolean } from "../../shared/src/codecs/types/dateTimeOrNullAsBoolean";
import { deferDateTime } from "../../shared/src/codecs/types/deferDateTime";
import { email } from "../../shared/src/codecs/types/email";
import { intersection } from "../../shared/src/codecs/types/intersection";
import { literal } from "../../shared/src/codecs/types/literal";
import { longString } from "../../shared/src/codecs/types/longString";
import { nullCodec } from "../../shared/src/codecs/types/nullCodec";
import { phoneNumber } from "../../shared/src/codecs/types/phoneNumber";
import { positiveInteger } from "../../shared/src/codecs/types/positiveInteger";
import { postcode } from "../../shared/src/codecs/types/postcode";
import { required } from "../../shared/src/codecs/types/required";
import { string } from "../../shared/src/codecs/types/string";
import { union } from "../../shared/src/codecs/types/union";
import { uuid } from "../../shared/src/codecs/types/uuid";
import { CaseAuctionStatusType } from "./CaseAuctionStatusType";
import { CaseSellerReasonForSale1 } from "./CaseSellerReasonForSale";
import { CaseStatus, CasesIsTransactingAsCompanyStatus } from "./Cases";
import { CasesLenderNameOnPanel1 } from "./CasesLenderNameOnPanel";
import { CasesPurchasePaymentMethod1 } from "./CasesPurchasePaymentMethod";
import { Country1 } from "./Country";
import { TransactionType1 } from "./TransactionType";

const CaseTransactionSection = intersection([
    required({
        id: uuid(),
        transaction_type: TransactionType1,
        get_property_details_job_defer: union([deferDateTime(), nullCodec()]),
    }),
]);

const CaseOtherSideConveyancerDetailsDefer = required({
    get_other_side_conveyancer_details_defer: union([deferDateTime(), nullCodec()]),
});

export const CaseOtherSideConveyancerDetails = required({
    other_side_conveyancer_organisation_name: string(),
    other_side_conveyancer_name: string(),
    other_side_conveyancer_phone_number: union([phoneNumber(), nullCodec()]),
    other_side_conveyancer_address: longString(),
    other_side_conveyancer_email_address: union([email(), nullCodec()]),
    other_side_conveyancer_sra_or_clc_number: string(),
    verify_other_sides_conveyancer_with_sra_or_clc_defer: union([deferDateTime(), nullCodec()]),
});

const CaseIntroducer = required({
    introducer_id: union([uuid(), nullCodec()]),
    has_introducer: boolean(),
    introducer_referral_fee_gbp_pence: union([positiveInteger(), nullCodec()]),
    sub_introducer: string(),
    introducer_external_reference_id: string(),
    referral_fee_paid: union([dateTime(), nullCodec()]),
    referral_fee_paid_defer: union([deferDateTime(), nullCodec()]),
});

const CaseMarketingCampaign = required({
    marketing_campaign_id: union([uuid(), nullCodec()]),
    has_marketing_campaign: boolean(),
});

export const CaseConsiderationType = union([
    literal("unknown"),
    literal("monetary_value"),
    literal("no_monetary_value"),
    literal("other"),
])

export const CaseConditionsAndFixtureAndFittingsNotes = required({
    fixture_and_fittings_notes: longString(),
    conditions: longString(),
})

export const CaseVacantPossessionOrAstOrNeitherType = union([
    literal("unknown"),
    literal("vacant_possession"),
    literal("ast"),
    literal("neither"),
])

export const CaseContractOccupierDetails = required({
    property_has_other_occupiers: union([nullCodec(), boolean()]),
    vacant_possession_or_ast: CaseVacantPossessionOrAstOrNeitherType
});

export const CaseContractASTDetails = required({
    ast_date: union([nullCodec(), date()]),
    ast_party_names: longString(),
});

export const CaseContractDetails = required({
    exchange_deadline: union([nullCodec(), date()]),
    completion_deadline: union([nullCodec(), date()]),
    seller_reason_for_sale: CaseSellerReasonForSale1,
    grant_application_date: union([nullCodec(), date()]),
    auction_status: CaseAuctionStatusType,
});

export const CaseConsiderationTypeDetails = required({
    consideration_type: CaseConsiderationType,
});

export const CaseConsiderationTypeOtherDetails = required({
    other_consideration_details_for_transfer: longString(),
})

export const CaseNonStandardUndertakingsExist = required({
    has_non_standard_undertakings: union([boolean(), nullCodec()]),
});

export const CaseNonStandardUndertakingsDetails = required({
    non_standard_undertakings_notes: longString(),
    non_standard_undertakings_approved: dateTimeOrNullAsBoolean(),
});

const CasesGetContractDetailsJobDefer = required({
    get_contract_details_job_defer: union([deferDateTime(), nullCodec()]),
});

export const CasesMortgageLenderName = required({
    mortgage_lender_name: CasesLenderNameOnPanel1,
    get_mortgage_lender_job_defer: union([deferDateTime(), nullCodec()]),
});

export const CasesExpectedMortgageAmount = required({
    expected_mortgage_amount_pence: union([currencyInteger(), nullCodec()]),
});
export type TCasesExpectedMortgageAmount = typeof CasesExpectedMortgageAmount;

export const CasesRemortgageMortgageDetails = intersection([
    CasesMortgageLenderName,
    CasesExpectedMortgageAmount,
]);
export type TCasesRemortgageMortgageDetails = typeof CasesRemortgageMortgageDetails;

export const CasesPurchasePaymentMethodDetails = required({
    purchase_payment_method: CasesPurchasePaymentMethod1,
});

export const CasesSearchesRequired = required({
    searches_required: union([nullCodec(), boolean()]),
    searches_required_deferred: union([deferDateTime(), nullCodec()]),
});

export const CaseOtherSideClientDetails = required({
    all_other_side_clients_added_and_right_to_buy_or_sell_checked: boolean(),
    get_other_side_client_details_job_defer: union([deferDateTime(), nullCodec()]),
});

export const CaseOtherSideCompany = required({
    other_side_company_name: string(),
    other_side_company_number: string(),
});

export const CaseOtherSideCompanyIsOverseas = required({
    other_side_company_is_overseas_for_transfer: dateTimeOrNullAsBoolean(),
});

export const CaseOtherSideCompanyOverseasDetails = required({
    other_side_company_overseas_incorporation_territory: string(),
    other_side_company_overseas_companies_house_entity_id: string(),
    other_side_company_overseas_companies_house_uk_registered_id: string(),
});

export const CasesOtherSideCompanyAddess = required({
    other_side_company_building_number: string(),
    other_side_company_building_name: string(),
    other_side_company_sub_building_number: string(),
    other_side_company_sub_building_name: string(),
    other_side_company_street_name: string(),
    other_side_company_city_town: string(),
    other_side_company_postcode: union([postcode(), nullCodec()]),
    other_side_company_county: string(),
    other_side_company_country: Country1,
    other_side_company_locality: string(),
    other_side_company_district: string(),
});

export const CasesManagementCompanyContacted = required({
    management_company_contacted: union([boolean(), nullCodec()]),
});

const CasesCheckIfAffectedByBSAJobDefer = required({
    check_if_affected_by_bsa_job_defer: union([deferDateTime(), nullCodec()]),
});

export const CaseEstateAgentDetails = required({
    estate_agent_organisation_name: string(),
    estate_agent_contact_name: string(),
    estate_agent_phone_number: union([phoneNumber(), nullCodec()]),
    estate_agent_email_address: union([email(), nullCodec()]),
    estate_agent_address: longString(),
    estate_agent_details_defer: union([deferDateTime(), nullCodec()]),
    no_estate_agent: boolean(),
});

export const JointOwnershipHeldAsAnsweredType = union([
    literal("joint_tenants"),
    literal("tenants_in_common_in_equal_shares"),
    literal("on_trust"),
]);
export type TJointOwnershipHeldAsAnsweredType = TTypeOfCodec<typeof JointOwnershipHeldAsAnsweredType>;

export const JointOwnershipHeldAsType = union([
    literal("unknown"),
    ...JointOwnershipHeldAsAnsweredType.payload,
]);
export type TJointOwnershipHeldAsTypeCodec = typeof JointOwnershipHeldAsType;
export type TJointOwnershipHeldAsType = TTypeOfCodec<TJointOwnershipHeldAsTypeCodec>;

export const CaseTransferDetails = required({
    joint_ownership_held_as: JointOwnershipHeldAsType,
    indemnity_clause_should_be_added: union([nullCodec(), boolean()]),
});

export const CaseJointOwnershipOtherDetails = required({
    other_dot_details_for_transfer: longString(),
})

export const CaseCompanyTransferExecutionType = union([
    literal("two_directors"),
    literal("director_with_witness"),
]);
export type TCaseCompanyTransferExecutionTypeCodec = typeof CaseCompanyTransferExecutionType;
export type TCaseCompanyTransferExecutionType = TTypeOfCodec<TCaseCompanyTransferExecutionTypeCodec>;

export const CaseOurSideCompanyTransferExecutionType = required({
    our_side_company_transfer_execution_type: CaseCompanyTransferExecutionType,
});

export const CaseOtherSideCompanyTransferExecutionType = required({
    other_side_company_transfer_execution_type: CaseCompanyTransferExecutionType,
});

export const CaseTransferIntendedAddressAndAdditionalProvisions = required({
    transferees_intended_address_for_register: string(),
    additional_provisions_for_transfer: longString(),
});

export const CaseAdditionalSpecialConditionsForContract = required({
    additional_special_conditions_for_contract: longString(),
})

const CaseContractPricing = required({
    price_pence: union([currencyInteger(), nullCodec()]),
    deposit_according_to_contract_pence: union([currencyInteger(), nullCodec()]),
    balance_for_contract_pence: union([currencyInteger(), nullCodec()]),
    chattels_price_for_contract_pence: union([currencyInteger(), nullCodec()]),
});

const CaseHasManagementCompany = required({
    has_management_company: union([boolean(), nullCodec()]),
});

export const CasesSellerPurchasingSearches = required({
    seller_to_purchase_searches: dateTimeOrNullAsBoolean(),
});

export const CasesBuyerNotPayingForSearches = required({
    buyer_not_paying_for_searches: dateTimeOrNullAsBoolean(),
})

export const CasesPurchaseLenderDuelRepConflictDetails = required({
    dual_rep_lender_conflict_notes: longString(),
    dual_rep_lender_conflict_defer: union([deferDateTime(), nullCodec()]),
    dual_rep_lender_conflict_approved_to_continue: dateTimeOrNullAsBoolean(),
});

export const CasesDraftContractDetails = required({
    sent_draft_contract: dateTimeOrNullAsBoolean(),
    sent_draft_contract_defer: union([deferDateTime(), nullCodec()]),
    draft_contract_deferred: union([deferDateTime(), nullCodec()]),
});

export const CaseKeyPlansType = union([
    literal("left_with_agents"),
    literal("left_with_seller_solicitor"),
    literal("clients_to_make_own_arrangements"),
]);
export type TCaseKeyPlansTypeCodec = typeof CaseKeyPlansType;
export type TCaseKeyPlansType = TTypeOfCodec<TCaseKeyPlansTypeCodec>;

export const CaseDeedPlansType = union([
    literal("will_be_posted"),
    literal("already_supplied"),
    literal("will_be_posted_land_reg_to_keep_lease"),
]);
export type TCaseDeedPlansTypeCodec = typeof CaseDeedPlansType;
export type TCaseDeedPlansType = TTypeOfCodec<TCaseDeedPlansTypeCodec>;

export const CaseKeyPlans = required({
    key_arrangements: CaseKeyPlansType,
})

export const CaseDeedPlans = required({
    deed_arrangements: CaseDeedPlansType,
})

export const CaseContractReadOnly = required({
    status: CaseStatus,
    is_instructed: union([nullCodec(), dateTime()]),
    transaction_type: TransactionType1,
    company_name: string(),
    is_transacting_as_company: CasesIsTransactingAsCompanyStatus
})

export const CaseContractAs1 = required({
    using_as1: dateTimeOrNullAsBoolean(),
})

export const CaseContractNoTA10AndWetInkTransfer = required({
    no_ta10: dateTimeOrNullAsBoolean(),
    wet_ink_transfer: dateTimeOrNullAsBoolean(),
})

export const CaseContractEsignConfigBooleans = required({
    add_transfer_to_esign_cert: boolean(),
    add_mortgage_deed_to_esign_cert: boolean(),
    add_statement_of_truth_to_esign_cert: boolean(),
    add_deed_of_covenant_to_esign_cert: boolean(),
    add_tp1_to_esign_cert: boolean(),
})

export const CaseContractEsignExtraDocs = required({
    other_documents_to_add_to_esign_cert_comma_separated: string(),
})

export const CaseContractTa13ConfigBooleans = required({
    completion_statement_will_be_sent_with_ta13: boolean(),
})

export const CasesContractBlock = intersection([
    required({
        id: uuid(),
    }),
    CaseTransactionSection,
    CaseOtherSideConveyancerDetailsDefer,
    CaseOtherSideConveyancerDetails,
    CaseIntroducer,
    CaseMarketingCampaign,
    CaseContractOccupierDetails,
    CaseContractASTDetails,
    CaseContractDetails,
    CaseConditionsAndFixtureAndFittingsNotes,
    CaseConsiderationTypeDetails,
    CaseConsiderationTypeOtherDetails,
    CaseNonStandardUndertakingsExist,
    CaseNonStandardUndertakingsDetails,
    CasesGetContractDetailsJobDefer,
    CasesMortgageLenderName,
    CasesExpectedMortgageAmount,
    CasesPurchasePaymentMethodDetails,
    CasesSearchesRequired,
    CaseOtherSideClientDetails,
    CaseOtherSideCompany,
    CaseOtherSideCompanyIsOverseas,
    CaseOtherSideCompanyOverseasDetails,
    CasesOtherSideCompanyAddess,
    CasesManagementCompanyContacted,
    CasesCheckIfAffectedByBSAJobDefer,
    CaseEstateAgentDetails,
    CaseTransferDetails,
    CaseJointOwnershipOtherDetails,
    CaseTransferIntendedAddressAndAdditionalProvisions,
    CaseContractPricing,
    CaseHasManagementCompany,
    CasesSellerPurchasingSearches,
    CasesBuyerNotPayingForSearches,
    CasesPurchaseLenderDuelRepConflictDetails,
    CasesDraftContractDetails,
    CaseContractAs1,
    CaseOurSideCompanyTransferExecutionType,
    CaseOtherSideCompanyTransferExecutionType,
    CaseKeyPlans,
    CaseDeedPlans,
    CaseContractNoTA10AndWetInkTransfer,
    CaseContractEsignConfigBooleans,
    CaseContractEsignExtraDocs,
    CaseContractTa13ConfigBooleans,
    CaseAdditionalSpecialConditionsForContract,
]);

export type TCasesContractBlockCodec = typeof CasesContractBlock;
export type TCasesContractBlock = TTypeOfCodec<typeof CasesContractBlock>;
