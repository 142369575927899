import { TCodec } from "../codec";
import { either } from "fp-ts/lib";
import { TError, errorConstants } from "../errors";

export type TAnyDecimalCodec = TCodec<
    "DecimalCodec",
    undefined,
    number,
    number
>;

const decode = (input: unknown): either.Either<TError, number> =>
    typeof input === "number"
        ? either.right(input)
        : either.left([[errorConstants.DECIMAL_VALIDATION, ""]]);

export const decimal = (): TAnyDecimalCodec => ({
    type: "DecimalCodec",
    payload: undefined,
    decode,
    decodeNewDefault: decode,
    newDefault: () => 0,
});
