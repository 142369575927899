import { getUrlStringForRoute } from "./getUrlStringsForRoute";
import { C as State } from "../../state/State";
import { TPaths } from "./routerPaths";
import { Routes } from "./routerRoutes";

export const updateUrlWithPushState = (p: TPaths, state: State<Routes>, pushState: History["pushState"]): void => {
    pushState(
        undefined, 
        "Sail Homes", 
        getUrlStringForRoute(
            p,
            state.routes.active,
            state.routes.params,
            state.routes.queryParams[state.routes.active],
            state.routes.blockParams[state.routes.active]
        )
    );
}