import * as t from "io-ts";
import * as AmenityCategory from "../models/AmenityCategory";
import * as ListingAmenity3 from "../models/ListingAmenity3";

export const codec = t.type({
    id: t.string,
    name: t.string,
    type: t.string,
    category: AmenityCategory.codec,
    distance_miles: t.number,
});

type T = t.TypeOf<typeof codec>;

export const fromListingAmenity3 = (m: ListingAmenity3.T): T => (
    {
        id: m.id,
        name: m.name,
        type: m.type,
        category: m.category,
        distance_miles: m.distance_miles,
    }
);
