import * as t from "io-ts";
import * as util from "../../shared/src/util";
import { isEnum } from "../../shared/src/validation/create/isEnum";

export const codec = t.union([
    t.literal("pending"),
    t.literal("accepted"),
    t.literal("rejected"),
    t.literal("gazumped"),
    t.literal("withdrawn"),
]);

const values = util.typeValuesFromCodec(codec.types as Array<t.LiteralC<T>>);

export type T = t.TypeOf<typeof codec>;

export const validator = isEnum(values);

export const toDisplayName = (p: T): string => {
    switch (p) {
        case "pending":
            return "Pending";
        case "accepted":
            return "Accepted";
        case "rejected":
            return "Rejected";
        case "gazumped":
            return "Gazumped";
        case "withdrawn":
            return "Withdrawn";
        default:
            return util.requireExhaustive(p);
    }
};
