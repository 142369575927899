import { TTypeOfCodec } from "../../../shared/src/codecs/codec";
import { form, TFormCodec } from "../../../shared/src/codecs/types/form";
import { CaseId, TCaseIdCodec } from "../CaseId";
import { EmptyObject, TEmptyObjectCodec } from "../EmptyObject";

export const CaseNewOtherSideMemberForm: TFormCodec<TCaseIdCodec, TEmptyObjectCodec> = 
    form(
        CaseId,
        EmptyObject,
    );

export type TCaseNewOtherSideMemberFormCodec = typeof CaseNewOtherSideMemberForm;
export type TCaseNewOtherSideMemberForm = TTypeOfCodec<TCaseNewOtherSideMemberFormCodec>;