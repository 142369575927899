import { TTypeOfCodec } from "../../shared/src/codecs/codec";
import { required } from "../../shared/src/codecs/types/required";
import { string } from "../../shared/src/codecs/types/string";
import { uuid } from "../../shared/src/codecs/types/uuid";

export const CaseSearchResultClient = 
    required({
        id: uuid(),
        first_name: string(),
        last_name: string(),
        legal_first_legal_name: string(),
        legal_middle_legal_name: string(),
        legal_last_legal_name: string(),
    })
;

export type TCaseSearchResultClientCodec = typeof CaseSearchResultClient;
export type TCaseSearchResultClient = TTypeOfCodec<typeof CaseSearchResultClient>;


export const doesClientHaveALegalName = (client: TCaseSearchResultClient): boolean =>
    !!client.legal_first_legal_name &&
    !!client.legal_last_legal_name
; 

export const getClientLegalName = (client: TCaseSearchResultClient): string => {
    if (doesClientHaveALegalName(client)) {
        return `(${client.legal_first_legal_name} ${client.legal_middle_legal_name} ${client.legal_last_legal_name})`
            .replace(/\s\s/, " ");
    }
    return "";
}
