import * as t from "io-ts";
import * as UserPhoneNumber1 from "./UserPhoneNumber1";

export const codec = t.type({
    id: t.string,
    email: t.union([t.string, t.null]),
    first_name: t.string,
    last_name: t.string,
    phone_numbers: t.array(UserPhoneNumber1.codec),
    is_legal_buyer: t.boolean,
    homes_first_legal_name: t.string,
    homes_middle_legal_name: t.string,
    homes_last_legal_name: t.string,
    homes_thirdfort_id_check_url: t.string,
    description_of_user: t.string,
});

type T = t.TypeOf<typeof codec>;

export const isSailHomesLegalNameFilled = (p: T): boolean =>
    p.homes_first_legal_name && p.homes_last_legal_name ? true : false;
