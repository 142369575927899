import React from "react";

export default (props: React.SVGAttributes<SVGElement>) => 
    <svg 
        width="24" 
        height="24" 
        viewBox="0 0 24 24" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path 
            d="M19 22V16H18.99L19 15.99L15 12L19 8L18.99 7.99H19L19 2H7V7.99H7.01L7 8L11 12L7 15.99L7.01 16H7V22H19ZM9 7.5V4H17L17 7.5L13 11.5L9 7.5ZM13 12.5L17 16.5V20H9V16.5L13 12.5Z" 
            fill="#37373C"
        />
    </svg>
