import { TCodec } from "../codec";
import { either } from "fp-ts/lib";
import { TError, errorConstants } from "../errors";

export type TAnyStringCodec = TCodec<
    "StringCodec",
    undefined,
    string ,
    string
>;

const decode = (input: unknown): either.Either<TError, string> =>
    typeof input === "string"
        ? either.right(input)
        : either.left([[errorConstants.STRING_VALIDATION, ""]]);

export const string = (): TAnyStringCodec => ({
    type: "StringCodec",
    payload: undefined,
    decode,
    decodeNewDefault: decode,
    newDefault: () => "",
});
