import React from "react";

export default (props: React.SVGAttributes<SVGElement>) => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M12 19C12.83 19 13.5 18.33 13.5 17.5H10.5C10.5 18.33 11.17 19 12 19Z" fill="black"/>
<path d="M15 15H9V16.5H15V15Z" fill="black"/>
<path d="M12 5C9.24 5 7 7.24 7 10C7 11.64 7.8 13.09 9.03 14H14.98C16.2 13.09 17 11.64 17 10C17 7.24 14.76 5 12 5ZM14.43 12.5H9.57C8.89 11.84 8.5 10.95 8.5 10C8.5 8.07 10.07 6.5 12 6.5C13.93 6.5 15.5 8.07 15.5 10C15.5 10.95 15.11 11.84 14.43 12.5Z" fill="black"/>
<path d="M21 14.999H19V17.999H16V19.999H19V22.999H21V19.999H24V17.999H21V14.999Z" fill="#00011B"/>
<path d="M14 21.8C13.354 21.931 12.685 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 12.341 21.983 12.678 21.95 13.01L21 12.999H19.938C19.979 12.672 20 12.338 20 12C20 7.59 16.41 4 12 4C7.59 4 4 7.59 4 12C4 16.41 7.59 20 12 20C12.69 20 13.36 19.912 14 19.747V21.8Z" fill="black"/>
</svg>
;
