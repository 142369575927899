import React from "react";
import { TPixelSize } from "../../models/StringLiterals";

export const FontSignikaNegative = (props: React.PropsWithChildren<{
    lineHeight?: TPixelSize;
}>): JSX.Element => (
    <span
        style={{
            fontFamily: "Signika Negative, sans-serif",
            display: "inline-flex",
            lineHeight: props.lineHeight || "inherit",
        }}
    >
        {props.children}
    </span>
);
