import { TCaseEnquiry1Form, TCaseEnquiry5FormList, TCaseEnquiryStatusFilterForm, TCaseEnquiry5And1Form, TCase2ReadOnlyForm, TCaseEnquiryAttachmentFormList, TCaseEnquiryAttachmentFilterForm, TCaseEnquiryNote1FormList, TCaseEnquiryTimeline1ReadOnlyFormList, TCaseDocument6AndCaseDocumentForm3FormList } from "../models/TFormModels";
import {  defaultTFormV2, defaultTListForm, TModal } from "../models/TForm";
import * as CaseEnquiry1 from "../../../domain/models/CaseEnquiry1";
import * as CaseEnquiry5 from "../../../domain/models/CaseEnquiry5";
import * as Case2 from "../../../domain/models/Case2";
import * as CaseEnquiryAttachments1 from "../../../domain/models/CaseEnquiryAttachments1";
import * as TempCaseAttachment2 from "../../../domain/models/TempCaseAttachment2";
import * as CaseEnquiryNote1 from "../../../domain/models/CaseEnquiryNote1";
import * as CaseDocumentForm3 from "../../../domain/models/CaseDocumentForm3";

export class C {
    caseForm: TCase2ReadOnlyForm = defaultTFormV2(Case2.newDefault(), {}, {});
    enquiryFilter: TCaseEnquiryStatusFilterForm = defaultTFormV2({}, {
        filter: "all",
    }, {});
    enquiries: TCaseEnquiry5FormList = defaultTListForm({}, {});
    documents: TCaseDocument6AndCaseDocumentForm3FormList = defaultTListForm(CaseDocumentForm3.newDefault(), {});
    addEnquiry: TCaseEnquiry1Form = defaultTFormV2({}, CaseEnquiry1.newDefault(), {});
    addEnquiryModal: TModal = "closed";
    submitEnquiriesForApprovalModal: TModal = "closed";
    editEnquiry: TCaseEnquiry5And1Form = defaultTFormV2(CaseEnquiry5.newDefault(), CaseEnquiry1.newDefault(), {});
    editEnquiryModal: TModal = "closed";
    enquiryAttachments: TCaseEnquiryAttachmentFormList = defaultTListForm(CaseEnquiryAttachments1.newDefault(), {
        enquiryId: "",
        isModalOpen: false,
    });
    enquiryNewNotes: TCaseEnquiryNote1FormList = defaultTListForm(CaseEnquiryNote1.newDefault(), {
        enquiryId: "",
        isModalOpen: false,
    });
    enquiryAttachmentFilter: TCaseEnquiryAttachmentFilterForm = defaultTFormV2(TempCaseAttachment2.newDefault(), TempCaseAttachment2.newDefault(), {});
    enquiryTimelines: TCaseEnquiryTimeline1ReadOnlyFormList = defaultTListForm({}, {
        enquiryId: "",
        isDeleteModalOpen: false,
    });
}
