import * as Function from "../Function";
import * as Error from "../Error";
import { array, option, nonEmptyArray } from "fp-ts/lib";

export const all = (...validators: Array<Function.TValidationFunction>): Function.TValidationFunction =>
    (value: unknown): option.Option<Error.TValidationError> =>
        array.reduce<Function.TValidationFunction, option.Option<Error.TValidationError>>(option.none, (validationResult, f) =>
            {
                if (option.isNone(validationResult)) {
                    return f(value);
                }
                else {
                    const a = f(value);
                    if (option.isNone(a)) {
                        return validationResult;
                    }
                    return option.some(nonEmptyArray.flatten([a.value, validationResult.value]));
                }
            }
        )(validators);
