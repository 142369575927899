import { TTypeOfCodec } from "../../shared/src/codecs/codec";
import { decimal } from "../../shared/src/codecs/types/decimal";
import { nullCodec } from "../../shared/src/codecs/types/nullCodec";
import { positiveInteger } from "../../shared/src/codecs/types/positiveInteger";
import { required } from "../../shared/src/codecs/types/required";
import { union } from "../../shared/src/codecs/types/union";

export const QuoteRateFeeTransferValue = required({
    when_greater_than_or_equal_to_property_price_pence: union([nullCodec(), positiveInteger()]),
    when_less_than_property_price_pence: union([nullCodec(), positiveInteger()]),
    fee_pence: positiveInteger(),
    fee_progressive_percentage_of_property_price: decimal(),
});
export type TQuoteRateFeeTransferValueCodec = typeof QuoteRateFeeTransferValue;
export type TQuoteRateFeeTransferValue = TTypeOfCodec<TQuoteRateFeeTransferValueCodec>;

export const QuoteRateFeePropertyValue = required({
    when_greater_than_or_equal_to_property_price_pence: union([nullCodec(), positiveInteger()]),
    when_less_than_property_price_pence: union([nullCodec(), positiveInteger()]),
    fee_pence: positiveInteger(),
});
export type TQuoteRateFeePropertyValueCodec = typeof QuoteRateFeePropertyValue;
export type TQuoteRateFeePropertyValue = TTypeOfCodec<TQuoteRateFeePropertyValueCodec>;
