import React from "react";

export default (props: React.SVGAttributes<SVGElement>) => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g clipPath="url(#clip0_8322_41794)">
<g clipPath="url(#clip1_8322_41794)">
<path d="M7 19V17.5H14.225C15.3917 17.5 16.3958 17.1125 17.2375 16.3375C18.0792 15.5625 18.5 14.6 18.5 13.45C18.5 12.3 18.0792 11.3375 17.2375 10.5625C16.3958 9.7875 15.3917 9.4 14.225 9.4H6.85L9.7 12.25L8.65 13.3L4 8.65L8.65 4L9.7 5.05L6.85 7.9H14.2C15.7833 7.9 17.1458 8.43333 18.2875 9.5C19.4292 10.5667 20 11.8833 20 13.45C20 15.0167 19.4292 16.3333 18.2875 17.4C17.1458 18.4667 15.7833 19 14.2 19H7Z" fill="black"/>
</g>
</g>
<defs>
<clipPath id="clip0_8322_41794">
<rect width="24" height="24" fill="white"/>
</clipPath>
<clipPath id="clip1_8322_41794">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>
;
