import { overload } from "../../../shared/src/codecs/types/overload";
import { required } from "../../../shared/src/codecs/types/required";
import { string } from "../../../shared/src/codecs/types/string";
import { stringCapped } from "../../../shared/src/codecs/types/stringCapped";
import { array } from "fp-ts";
import { pipe } from "fp-ts/lib/function";

export const CasesBankAccountSortCodeHyphenated = overload(
    string(),
    required({
        sort_code: stringCapped(6),
    }),
    ({sort_code}) => pipe(
        sort_code.split(""),
        array.chunksOf(2),
        array.map((a) => a.join("")),
        (a) => a.join("-"),
    )
);
