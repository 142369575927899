import { TTypeOfCodec } from "../../../shared/src/codecs/codec";
import { formIO, TFormIOCodec } from "../../../shared/src/codecs/types/formIO";
import { EmptyObject, TEmptyObjectCodec } from "../EmptyObject";
import { TUuidObjectCodec, UuidObject } from "../Id";
 
export const DeleteListingDocumentFormIO: TFormIOCodec<TUuidObjectCodec, TEmptyObjectCodec> = formIO(
    UuidObject,
    EmptyObject,
);

export type TDeleteListingDocumentFormIOCodec = typeof DeleteListingDocumentFormIO;

export type TDeleteListingDocumentFormIO = TTypeOfCodec<TDeleteListingDocumentFormIOCodec>;