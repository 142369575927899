import * as t from "io-ts";
import * as regexes from "../../shared/src/regexes";
import { boolean } from "../../shared/src/validation/basic/boolean";
import { nullable } from "../../shared/src/validation/basic/nullable";
import { string } from "../../shared/src/validation/basic/string";
import { or } from "../../shared/src/validation/compose/or";
import { object } from "../../shared/src/validation/create/object";
import { regex } from "../../shared/src/validation/create/regex";
import { TValidationFunction } from "../../shared/src/validation/Function";

export const codec = t.partial({
    first_name: t.string,
    last_name: t.string,
    email: t.union([t.string, t.null]),
    is_legal_buyer: t.boolean,
    homes_first_legal_name: t.string,
    homes_middle_legal_name: t.string,
    homes_last_legal_name: t.string,
    homes_thirdfort_id_check_url: t.string,
    description_of_user: t.string,
});

export type T = t.TypeOf<typeof codec>;

export const validator: TValidationFunction =
    object({
        first_name:string,
        last_name:string,
        email: or(
           nullable,
            regex(regexes.constants.EMAIL_REGEX),
        ),
        is_legal_buyer: boolean,
        homes_first_legal_name:string,
        homes_middle_legal_name:string,
        homes_last_legal_name:string,
        homes_thirdfort_id_check_url:string,
        description_of_user:string,
    });

export const newDefault = (): Required<T> => ({
    email: null,
    first_name: "",
    last_name: "",
    is_legal_buyer: false,
    homes_first_legal_name: "",
    homes_middle_legal_name: "",
    homes_last_legal_name: "",
    homes_thirdfort_id_check_url: "",
    description_of_user: "",
});
