import { TTypeOfCodec } from "../../shared/src/codecs/codec";
import { boolean } from "../../shared/src/codecs/types/boolean";
import { date } from "../../shared/src/codecs/types/date";
import { decimal } from "../../shared/src/codecs/types/decimal";
import { integer } from "../../shared/src/codecs/types/integer";
import { intersection } from "../../shared/src/codecs/types/intersection";
import { literal } from "../../shared/src/codecs/types/literal";
import { nullCodec } from "../../shared/src/codecs/types/nullCodec";
import { overload } from "../../shared/src/codecs/types/overload";
import { positiveInteger } from "../../shared/src/codecs/types/positiveInteger";
import { required } from "../../shared/src/codecs/types/required";
import { requiredFlatOverloaded } from "../../shared/src/codecs/types/requiredFlatOverloaded";
import { string } from "../../shared/src/codecs/types/string";
import { union } from "../../shared/src/codecs/types/union";
import { uuid } from "../../shared/src/codecs/types/uuid";
import { defaultSearchProviderFullName, defaultSearchProviderPortalUrl } from "../defaultSearchProvider";

export const Introducer1 = intersection([
    required({
        id: uuid(),
        name: string(),
        is_paid_referral_fee: boolean(),
        custom_search_ordering_notes: string(),
    }),
    requiredFlatOverloaded({
        search_provider_name: overload(
            string(),
            required({ custom_search_provider_name: string() }),
            ({custom_search_provider_name}) =>
                custom_search_provider_name || defaultSearchProviderFullName,
        ),
    }),
    requiredFlatOverloaded({
        search_provider_url: overload(
            string(),
            required({ custom_search_provider_url: string() }),
            ({custom_search_provider_url}) =>
                custom_search_provider_url || defaultSearchProviderPortalUrl,
        ),
    }),
]);
export type TIntroducer1Codec = typeof Introducer1;

export const IntroducerReferralFees = required({
    "default_referral_fee_pence--purchase": positiveInteger(),
    "default_referral_fee_pence--sale": positiveInteger(),
    "default_referral_fee_pence--transfer": positiveInteger(),
    "default_referral_fee_pence--remortgage": positiveInteger(),
});
export type TIntroducerReferralFeesCodec = typeof IntroducerReferralFees;
export type TIntroducerReferralFees = TTypeOfCodec<TIntroducerReferralFeesCodec>;

export const Introducer2 = intersection([
    required({
        id: uuid(),
        is_paid_referral_fee: boolean(),
        name: string(),
        sail_homes_sale_referral_percentage: decimal(),
        initial_payment_purchase_amount_pence: integer(),
        initial_payment_transfer_amount_pence: integer(),
        initial_payment_remortgage_amount_pence: integer(),
        initial_payment_sale_with_leasehold_amount_pence: integer(),
        initial_payment_sale_without_leasehold_amount_pence: integer(),
        initial_payment_additional_seller_searches_amount_pence: integer(),
        referral_agreement_next_review_date: union([date(), nullCodec()]),
        search_provider: union([
            literal("default"),
            literal("somewhere_else"),
    
        ]),
        custom_search_provider_name: string(),
        custom_search_provider_url: string(),
        custom_search_ordering_notes: string(),
        klyant_client_id: string(),
        will_charge_abortive_costs: boolean(),
    }),
    IntroducerReferralFees,
]);
export type TIntroducer2Codec = typeof Introducer2;
export type TIntroducer2 = TTypeOfCodec<TIntroducer2Codec>;

export const Introducer3 = required({
    id: uuid(),
    is_paid_referral_fee: boolean(),
    name: string(),
    referral_agreement_next_review_date: union([date(), nullCodec()]),
});
export type TIntroducer3Codec = typeof Introducer3;
export type TIntroducer3 = TTypeOfCodec<TIntroducer3Codec>;

export const Introducer4 = required({
    id: uuid(),
    name: string(),
});
export type TIntroducer4Codec = typeof Introducer4;
export type TIntroducer4 = TTypeOfCodec<TIntroducer4Codec>;
