import { TTypeOfCodec } from "../../../shared/src/codecs/codec";
import { form, TFormCodec } from "../../../shared/src/codecs/types/form";
import { required, TRequiredCodec } from "../../../shared/src/codecs/types/required";
import { EmailComposition, TEmailCompositionCodec } from "../EmailComposition";
import { CasesSearchForm, TCasesSearchFormCodec } from "./CasesSearchForm";
import { EmailAttachmentSearchForm, TEmailAttachmentSearchFormCodec } from "./EmailAttachmentSearchForm";

export const EmailCompositionForm: TFormCodec<TEmailCompositionCodec, TRequiredCodec<{
    cases_search_form: TCasesSearchFormCodec,
    email_attachments_search_form: TEmailAttachmentSearchFormCodec,
}>> = form(
    EmailComposition,
    required({
        cases_search_form: CasesSearchForm,
        email_attachments_search_form: EmailAttachmentSearchForm,
    })
);
export type TEmailCompositionFormCodec = typeof EmailCompositionForm;
export type TEmailCompositionForm = TTypeOfCodec<TEmailCompositionFormCodec>;
