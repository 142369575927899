import * as Function from "../Function";
import * as Error from "../Error";
import * as ErrorCode from "../ErrorCode";
import { option } from "fp-ts/lib";
import { DateTime } from "luxon";

export const stringIsoUtcDateTime: Function.TValidationFunction = (value: unknown): option.Option<Error.TValidationError> =>
    typeof value === "undefined" || (
        typeof value === "string"
        && /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(.\d{3})?(Z|(\+00:00)|(\-00:00)|)$/.test(value)
        && DateTime.fromISO(value).isValid
    )
        ? option.none
        : option.some([[ErrorCode.validationErrorCodeConstants.UTC_DATETIME_VALIDATION]]);
