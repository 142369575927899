import React from "react";
import { TPercentageSize, TPixelSize } from "../../models/StringLiterals";

export type TJustificationOptions = "start" | "end" | "center" | "space-around" | "space-between" | "space-evenly";

type TSpacingRowProps = {
    spacing?: string;
    justifyContent?: TJustificationOptions;
    alignContent?: TJustificationOptions;
    alignItems?: "center" | "flex-start" | "flex-end" | "self-end";
    height?: "auto" | TPixelSize | TPercentageSize | `${number}vh` | string;
    childSize?: "auto" | "even-fill" | string;
    maxWidth?: TPixelSize | TPercentageSize | `${number}vh`;
    onClick?: () => void;
};

export const SpacingColumn = (props: React.PropsWithChildren<TSpacingRowProps>): JSX.Element => {
    const childSize =
        props.childSize || "auto";

    return (
        <div
            style={{
                width: "100%",
                height: props.height || "auto",
                display: "grid",
                gridTemplateColumns: "100%",
                justifyContent: props.justifyContent || "start",
                gridRowGap: props.spacing || "0px",
                gridTemplateRows: childSize,
                alignItems: props.alignItems || "flex-start",
                alignContent: props.alignContent,
                maxWidth: props.maxWidth
            }}
            onClick={props.onClick}
        >
            {props.children}
        </div>
    );
};
