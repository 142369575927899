import * as t from "io-ts";
import * as util from "../../shared/src/util";
import { isEnum } from "../../shared/src/validation/create/isEnum";

export const codec = t.union([
    t.literal("pending"),
    t.literal("confirmed"),
    t.literal("cancelled"),
]);

export const values = util.typeValuesFromCodec(codec.types as Array<t.LiteralC<T>>);

export type T = t.TypeOf<typeof codec>;

export const validator = isEnum(values);

export const toDisplayName = (p: T): string => {
    switch (p) {
        case "pending":
            return "Pending";
        case "confirmed":
            return "Confirmed";
        case "cancelled":
            return "Cancelled";
        default:
            return util.requireExhaustive(p);
    }
};
