import { literal } from "../../../shared/src/codecs/types/literal";
import { union } from "../../../shared/src/codecs/types/union";
import { TTypeOfCodec } from "../../../shared/src/codecs/codec";

export const CarSaleStatus = union([
    literal("Not interested"),
    literal("Complete"),
    literal("Transfer log book"),
    literal("Invoicing"),
    literal("Get offers"),
    literal("Client agreement"),
    literal("Valuation")
]);

export type TCarSaleStatusCodec = typeof CarSaleStatus;
export type TCarSaleStatus = TTypeOfCodec<TCarSaleStatusCodec>;