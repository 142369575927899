import { TTypeOfCodec } from "../../shared/src/codecs/codec";
import { array } from "../../shared/src/codecs/types/array";
import { required } from "../../shared/src/codecs/types/required";
import { CaseEnquiry1 } from "./CaseEnquiry";
import { CasesDocumentFile1 } from "./CasesDocument";
import { CaseChargeLegalFile2 } from "./CaseChargeLegalFile";
import { QuoteEmailAttachment } from "./Quote";

export const EmailAttachmentSearchResults = 
    required({
        enquiries: array(CaseEnquiry1),
        files: array(CasesDocumentFile1),
        redemption_statements: array(CaseChargeLegalFile2),
        quotes: array(QuoteEmailAttachment),
    });

export type TEmailAttachmentSearchResultsCodec = typeof EmailAttachmentSearchResults;
export type TEmailAttachmentSearchResults = TTypeOfCodec<typeof EmailAttachmentSearchResults>;

