import { TTypeOfCodec } from "../../shared/src/codecs/codec";
import { literal } from "../../shared/src/codecs/types/literal";
import { union } from "../../shared/src/codecs/types/union";

export const CaseSellerReasonForSale1 = union([
    literal("normal"),
    literal("repossession"),
    literal("probate"),
    literal("deputyship"),
    literal("divorce"),
    literal("unknown"),
]);
export type TCaseSellerReasonForSale1Codec = typeof CaseSellerReasonForSale1;
export type TCaseSellerReasonForSale1 = TTypeOfCodec<typeof CaseSellerReasonForSale1>;
