import { defaultTFormV2, defaultTListForm, TFormV2 } from "../models/TForm";
import * as Listing3 from "../../../domain/models/Listing3";
import * as Party2 from "../../../domain/models/Party2";
import * as Party7 from "../../../domain/models/Party7";
import * as ListingEnquiriesOfferForm2 from "../../../domain/models/ListingEnquiriesOfferForm2";
import * as ListingEnquiriesViewingSuccessResponse3 from "../../../domain/models/ListingEnquiriesViewingSuccessResponse3";
import {
    TListing3ReadOnlyForm,
    TParty2AndC7Form,
    TEnquiry5ReadOnlyFormList,
    TListingEnquiriesOffer2AndListingEnquiriesOfferForm2FormList,
    TListingEnquiriesViewing2ReadOnlyFormList,
} from "../models/TFormModels";

export class C {
    listingForm: TListing3ReadOnlyForm = defaultTFormV2(Listing3.newDefault(), {}, {});
    partyForm: TParty2AndC7Form = defaultTFormV2(Party2.newDefault(), Party7.newDefault(), {});
    interestedEnquiriesWithoutOffer: TEnquiry5ReadOnlyFormList = defaultTListForm({}, {});
    pendingOrAcceptedOffers: TListingEnquiriesOffer2AndListingEnquiriesOfferForm2FormList = defaultTListForm(ListingEnquiriesOfferForm2.newDefault(), {});
    withdrawnOrRejectedOffers: TListingEnquiriesOffer2AndListingEnquiriesOfferForm2FormList = defaultTListForm(ListingEnquiriesOfferForm2.newDefault(), {});
    sendPerformanceUpdateForm: TFormV2<{}, {}, {}> = {
        ...defaultTFormV2({}, {}, {}, "requiresSubmission"),
        status: "requiresSubmission",
    };
    upcomingViewings: TListingEnquiriesViewing2ReadOnlyFormList = defaultTListForm(ListingEnquiriesViewingSuccessResponse3.newDefault(), {});
    pastViewings: TListingEnquiriesViewing2ReadOnlyFormList = defaultTListForm(ListingEnquiriesViewingSuccessResponse3.newDefault(), {});
}
