import { required } from "../../shared/src/codecs/types/required";
import { TTypeOfCodec } from "../../shared/src/codecs/codec";
import { uuid } from "../../shared/src/codecs/types/uuid";
import { union } from "../../shared/src/codecs/types/union";
import { intersection } from "../../shared/src/codecs/types/intersection";
import { nullCodec } from "../../shared/src/codecs/types/nullCodec";
import { Address2 } from "./Address";
import { requiredFlatOverloaded } from "../../shared/src/codecs/types/requiredFlatOverloaded";
import { Tenure1, Tenure3 } from "./Tenure";
import { boolean } from "../../shared/src/codecs/types/boolean";
import { string } from "../../shared/src/codecs/types/string";
import { nonEmptyString } from "../../shared/src/codecs/types/nonEmptyString";
import { email } from "../../shared/src/codecs/types/email";
import { phoneNumber } from "../../shared/src/codecs/types/phoneNumber";
import { postcode } from "../../shared/src/codecs/types/postcode";
import { Country1 } from "./Country";
import { decimal } from "../../shared/src/codecs/types/decimal";
import { array } from "../../shared/src/codecs/types/array";
import { positiveInteger } from "../../shared/src/codecs/types/positiveInteger";
import { positiveDecimal } from "../../shared/src/codecs/types/positiveDecimal";
import { dateTime } from "../../shared/src/codecs/types/dateTime";
import { date } from "../../shared/src/codecs/types/date";
import { dateOrNullAsBoolean } from "../../shared/src/codecs/types/dateOrNullAsBoolean";
import { HomesAndLegalPropertyType } from "./HomesAndLegalPropertyType";
import { ListingCondition1 } from "./ListingCondition";
import { ListingPriceQualifier } from "./ListingPriceQualifier";
import { ListingReasonForSale1 } from "./ListingReasonForSale";
import { ListingChainStatus1 } from "./ListingChainStatus";
import { ListingValuationType1 } from "./ListingValuationType";
import { ListingViewingsConductedBy1 } from "./ListingViewingsConductedBy";
import { ListingStatusDisplayString } from "./ListingStatus";
import { longString } from "../../shared/src/codecs/types/longString";
import { User6, User8 } from "./User";
import { integer } from "../../shared/src/codecs/types/integer";
import { PaymentMethod } from "./PaymentMethod";
import { deferDateTime } from "../../shared/src/codecs/types/deferDateTime";
import { ListingBuyerSurveyRequired } from "./ListingBuyerSurveyRequired";
import { ListingImageUrl } from "./ListingImage";
import { ListingPhotoFileIO } from "./fileIO/ListingPhotoFileIO";
import { dateTimeOrNullAsBoolean } from "../../shared/src/codecs/types/dateTimeOrNullAsBoolean";
import { ListingDocumentFileIO } from "./fileIO/ListingDocumentFileIO";
import { literal } from "../../shared/src/codecs/types/literal";
import { ListingDocumentType } from "./ListingDocument";
import { ListingFloorAreaUnits } from "./ListingFloorAreaUnits";
import { ListingWindowType } from "./ListingWindowType";
import { ListingParkingType } from "./ListingParkingType";
import { ListingOutdoorSpaceType } from "./ListingOutdoorSpaceType";
import { ListingHeatingType } from "./ListingHeatingType";
import { Introducer4 } from "./Introducer";
 
export const Listing1 = intersection([
    required({
        id: uuid(),
        tenure: Tenure3,
        seller_conveyancer_organisation_name: nonEmptyString(),
        seller_conveyancer_name: nonEmptyString(),
        seller_conveyancer_phone_number: phoneNumber(),
        seller_conveyancer_email_address: email(),
        seller_conveyancer_address: nonEmptyString(),
        seller_company_name: string(),
    }),
    requiredFlatOverloaded({
        address_short_string: Address2,
    }),
]);
export type TListing1 = TTypeOfCodec<typeof Listing1>;

export const Listing2 = intersection([
    required({
        id: uuid(),
        tenure: Tenure1,
        seller_conveyancer_organisation_name: string(),
        seller_conveyancer_name: string(),
        seller_conveyancer_phone_number: union([phoneNumber(), nullCodec()]),
        seller_conveyancer_email_address: union([email(), nullCodec()]),
        seller_conveyancer_address: string(),
        seller_company_name: string(),
        aventria_reference: string(),
    }),
    requiredFlatOverloaded({
        address_short_string: Address2,
    }),
]);
export type TListing2 = TTypeOfCodec<typeof Listing2>;

export const Listing3 = intersection([
    required({
        id: uuid(),
        aventria_reference: string(),
    }),
    requiredFlatOverloaded({
        address_short_string: Address2,
    }),
]);
export type TListing3 = TTypeOfCodec<typeof Listing3>;

// Below used for Listing Prep forms
const ListingId = required({
    id: uuid(),
});

export const ListingAddress = required({
    building_name: string(),
    building_number: string(),
    sub_building_name: string(),
    sub_building_number: string(),
    street_name: string(),
    postcode: union([postcode(), nullCodec()]),
    county: string(),
    locality: string(),
    district: string(),
    city_town: string(),
    country: Country1,
    uprn: string(),
    latitude: union([decimal(), nullCodec()]),
    longitude: union([decimal(), nullCodec()]),
})

export const ListingPropertySpecificsRequired = required({
    tenure: Tenure1,
    property_type: HomesAndLegalPropertyType,
    bedroom_count: union([positiveInteger(), nullCodec()]),
})

export const ListingPropertySpecificsOptional1 = required({
    bathroom_count: union([positiveInteger(), nullCodec()]),
    reception_room_count: union([positiveInteger(), nullCodec()]),
    property_summary: longString(),
    property_advantages: array(string()),
    property_disadvantages: array(string()),
    significant_renovations_made: longString(),
    condition: ListingCondition1,
    main_entrance_floor_level: union([positiveInteger(), nullCodec()]),
    above_ground_floors_count: union([positiveInteger(), nullCodec()]),
    bellow_ground_floors_count: union([positiveInteger(), nullCodec()]),
    attics_count: union([positiveInteger(), nullCodec()]),
    property_occupied: union([boolean(), nullCodec()]),
})

export const ListingPropertySpecificsOptional2 = required({
    is_new_build: union([boolean(), nullCodec()]),
    year_built: union([positiveInteger(), nullCodec()]),
    approximate_floor_area: union([positiveDecimal(), nullCodec()]),
    approximate_floor_area_units: ListingFloorAreaUnits,
    window_types: array(ListingWindowType),
    parking_types: array(ListingParkingType),
    heating_types: array(ListingHeatingType),
    outdoor_space_types: array(ListingOutdoorSpaceType),
    specifics_for_marketing_signed_off: dateTimeOrNullAsBoolean(),
    specifics_for_marketing_sign_off_deferred: union([deferDateTime(), nullCodec()]),
})

const ListingPropertySpecifics2 = intersection([
    required({
        occupier_user_id: union([uuid(), nullCodec()]),
    }),
    ListingPropertySpecificsRequired,
    ListingPropertySpecificsOptional1,
    ListingPropertySpecificsOptional2,
]);

export const ListingPropertyMarketing = required({
    rightmove_admin_url: string(),
    zoopla_admin_url: string(),
    description_expected_return_date: union([nullCodec(), date()]),
    description_deffered: union([deferDateTime(), nullCodec()]),
    description: longString(),
    description_proofread_and_ready: dateTimeOrNullAsBoolean(),
    video_tour_expected_return_date: union([nullCodec(), date()]),
    video_tour_deffered: union([deferDateTime(), nullCodec()]),
    no_video_tour_needed: boolean(),
    video_tour_url: string(),
    photography_expected_return_date: union([nullCodec(), date()]),
    photography_deffered: union([deferDateTime(), nullCodec()]),
    all_photos_uploaded: dateTimeOrNullAsBoolean(),
    public_notice_up_on_rightmove: boolean(),
    public_notice_up_on_rightmove_deferred: union([deferDateTime(), nullCodec()]),
})

export const ListingPropertySignsControls = required({
    sign_movements_deferred: union([deferDateTime(), nullCodec()]),
    sign_not_being_installed: boolean(),
})

export const ListingDocumentManagementGenericControls = required({
    excluded_documents: array(ListingDocumentType),
    send_documents_to_solictors_deferred: union([deferDateTime(), nullCodec()]),
    order_documents_deferred: union([deferDateTime(), nullCodec()]),
})

export const ListingDocumentManagementGenericDates = required({
    freehold_title_plan_expected_date: union([nullCodec(), date()]),
    freehold_title_register_expected_date: union([nullCodec(), date()]),
    local_authority_search_expected_date: union([nullCodec(), date()]),
    drainage_and_water_search_expected_date: union([nullCodec(), date()]),
    chancel_search_expected_date: union([nullCodec(), date()]),
    environment_search_expected_date: union([nullCodec(), date()]),
    epc_expected_date: union([nullCodec(), date()]),
    floorplan_expected_date: union([nullCodec(), date()]),
})

export const ListingDocumentManagementLeaseholdDates = required({
    leasehold_title_plan_expected_date: union([nullCodec(), date()]),
    leasehold_title_register_expected_date: union([nullCodec(), date()]),
    leasehold_lease_expected_date: union([nullCodec(), date()]),
})

export const ListingPriceAndCosts = required({
    price_main_currency: union([positiveInteger(), nullCodec()]),
    price_qualifier: union([ListingPriceQualifier, nullCodec()]),
    lease_length_remaining_years: union([positiveInteger(), nullCodec()]),
    lease_service_charge_pcy_full_currency: union([positiveDecimal(), nullCodec()]),
    lease_ground_rent_pcy_full_currency: union([positiveDecimal(), nullCodec()]),
    renovation_cost_main_currency: union([positiveInteger(), nullCodec()]),
    estimated_resale_price_main_currency: union([positiveInteger(), nullCodec()]),
    estimated_rental_price_pcm_main_currency: union([positiveInteger(), nullCodec()]),
})

export const ListingSellingProcess1 = required({
    is_lost: boolean(),
    admin_notes: longString(),
    title_deed_number: string(),
    seller_latest_desired_sell_date: union([date(), nullCodec()]),
    seller_minimum_desired_sell_value: union([positiveInteger(), nullCodec()]),
    seller_specfic_agent_needs: longString(),
    video_tour_required_before_viewing: union([boolean(), nullCodec()]),
    reason_for_sale: ListingReasonForSale1,
    has_introducer: boolean(),
    introducer_id: union([nullCodec(), uuid()]),
    introducer_external_reference_id: string()
});

// if viewings_conducted_by = viewber
export const ListingSellingProcess2 = required({
    viewber_details_updated: dateTimeOrNullAsBoolean(),
});

export const ListingSellingProcess3 = required({
    defer_viewings_management: union([deferDateTime(), nullCodec()]),
});

export const ListingSellingProcess5 = required({
    referral_fee_paid: dateTimeOrNullAsBoolean(),
    aventria_reference: string(),
    sellers_view_on_offers: longString(),
    share_offers_deffered: union([deferDateTime(), nullCodec()]),
    chain_status: ListingChainStatus1,
    valuation_type: ListingValuationType1,
    valuation_date: union([dateTime(), nullCodec()]),
    valuation_date_deferred: union([deferDateTime(), nullCodec()]),
    valuation_sent: dateTimeOrNullAsBoolean(),
    valuation_sent_deferred: union([deferDateTime(), nullCodec()]),
    instructed_date: union([date(), nullCodec()]),
    chase_instruction_deferred: union([deferDateTime(), nullCodec()]),
    live_date: union([date(), nullCodec()]),
    go_live_on_portals_deferred: union([deferDateTime(), nullCodec()]),
    viewings_can_be_booked_from: union([dateTime(), nullCodec()]),
    preferred_viewing_times: longString(),
    viewings_conducted_by: ListingViewingsConductedBy1,
    introducer_referral_fee_percentage: decimal(),
    skip_sail_legal_referral_fee_subtraction: boolean()
});

export const ListingSellingProcess6 = required({
    referral_fee_pence: union([positiveInteger(), nullCodec()]),
});

const ListingSellingProcess4 = intersection([
    required({
        viewings_conducted_by_user_id: union([uuid(), nullCodec()]),
        occupier_user_id: union([uuid(), nullCodec()]),
    }),
    ListingSellingProcess1,
    ListingSellingProcess2,
    ListingSellingProcess3,
    ListingSellingProcess5,
    ListingSellingProcess6
]);



export const ListingCompetingAgent = required({
    competitor_agent_instructed_at: union([date(), nullCodec()]),
    competitor_agent_name: string(),
    competitor_agent_market_price: union([positiveInteger(), nullCodec()]),
    competitor_agent_listing_status: string(),
})

export const ListingConveyancing = required({
    date_of_death: union([date(), nullCodec()]),
    property_information_forms_sent_to_seller: dateTimeOrNullAsBoolean(),
    property_information_forms_sent_to_seller_deferred: union([deferDateTime(), nullCodec()]),
    seller_conveyancer_organisation_name: string(),
    seller_conveyancer_name: string(),
    seller_conveyancer_phone_number: union([phoneNumber(), nullCodec()]),
    seller_conveyancer_email_address: union([email(), nullCodec()]),
    seller_conveyancer_address: longString(),
})

export const ListingSellerCompany = required({
    seller_company_name: string(),
    set_legal_seller_or_company_name_deferred: union([deferDateTime(), nullCodec()]),
});

export const ListingProgressionSellerSolicitorKickOff = required({
    seller_solicitor_kick_off_email_sent: dateTimeOrNullAsBoolean(),
    seller_solicitor_kick_off_deferred: union([deferDateTime(), nullCodec()]),
})

export const ListingProgressionBuyerSolicitorKickOff = required({
    buyer_solicitor_kick_off_email_sent: dateTimeOrNullAsBoolean(),
    buyer_solicitor_kick_off_deferred: union([deferDateTime(), nullCodec()]),
})

export const ListingProgressionMortgageBrokerKickOff = required({
    mortgage_broker_kick_off_email_sent: dateTimeOrNullAsBoolean(),
    mortgage_broker_kick_off_deferred: union([deferDateTime(), nullCodec()]),
})

export const ListingProgressionBuyerOwnSurvey = required({
    buyer_own_survey_required: ListingBuyerSurveyRequired,
    buyer_own_survey_datetime: union([dateTime(), nullCodec()]),
    buyer_own_survey_deferred: union([deferDateTime(), nullCodec()]),
})

export const ListingProgressionMortgageSurvey = required({
    mortgage_survey_datetime: union([dateTime(), nullCodec()]),
})

export const ListingProgressionBuyerConveyancerHasEverythingRequiredToStart = required({
    buyer_conveyancer_has_everything_required_to_start: dateTimeOrNullAsBoolean(),
    buyer_conveyancer_has_everything_required_to_start_deferred: union([deferDateTime(), nullCodec()]),
});

export const ListingProgressionMortgageOfferReceived = required({
    mortgage_offer_received: dateTimeOrNullAsBoolean(),
    mortgage_offer_received_deferred: union([deferDateTime(), nullCodec()]),
});

export const ListingProgressionBuyerConveyancerReceivedContracts = required({
    buyer_conveyancer_received_contracts: dateTimeOrNullAsBoolean(),
    buyer_conveyancer_received_contracts_deferred: union([deferDateTime(), nullCodec()]),
});

export const ListingProgressionBuyerConveyancerRaisedEnquiries = required({
    buyer_conveyancer_raised_enquiries: dateTimeOrNullAsBoolean(),
    buyer_conveyancer_raised_enquiries_deferred: union([deferDateTime(), nullCodec()]),
});

export const ListingProgressionAllEnquiriesResolved = required({
    all_enquiries_resolved: dateTimeOrNullAsBoolean(),
    all_enquiries_resolved_deferred: union([deferDateTime(), nullCodec()]),
});

export const ListingProgressionExchangeDate = required({
    estimated_exchange_date: union([date(), nullCodec()]),
    exchange_date_deferred: union([deferDateTime(), nullCodec()]),
});

export const ListingProgressionCompletedDate = required({
    estimated_completion_date: union([date(), nullCodec()]),
    has_completed: dateOrNullAsBoolean(),
    completed_date_deferred: union([deferDateTime(), nullCodec()]),
});

export const ListingProgressionKeysReleasedToBuyer = required({
    keys_released_to_buyer: boolean(),
    keys_released_to_buyer_deferred: union([deferDateTime(), nullCodec()]),
});

export const ListingProgressionMortgageApplicationSubmitted = required({
    mortgage_application_submitted: dateTimeOrNullAsBoolean(),
    mortgage_application_submitted_deferred: union([deferDateTime(), nullCodec()]),
});
// Above used for Listing Prep forms

const ListingKeyHolder = union([
    literal("unknown"),
    literal("keysafe"),
    literal("keynest"),
    literal("occupier"),
    literal("designated_person"),
])

export const ListingProgressionNotes = required({
    case_progression_notes: longString(),
});

export const ListingKeysafeDetails = required({
    keysafe_code: string(),
    keysafe_being_fitted_on: union([nullCodec(), date()]),
    keysafe_has_been_fitted: dateOrNullAsBoolean(),
    keysafe_code_has_been_set: dateOrNullAsBoolean(),
    keysafe_location: string(),
});

export const ListingKeynestDetails = required({
    keynest_has_been_setup: dateOrNullAsBoolean(),
});

export const ListingKeyManagement1 = required({
    defer_key_management: union([deferDateTime(), nullCodec()]),
});

export const ListingKeyManagement2 = required({
    key_collection_details: longString(),
});

export const ListingKeyManagement3 = required({
    key_notes: longString(),
    keys_being_delivered_on: union([dateTime(), nullCodec()]),
    keys_delivered: dateOrNullAsBoolean(),
    key_holder: ListingKeyHolder,
});

const ListingKeyManagement4 = intersection([
    required({
        occupier_user_id: union([uuid(), nullCodec()]),
        key_holder_user_id: union([uuid(), nullCodec()]),
    }),
    ListingKeyManagement1,
    ListingKeyManagement2,
    ListingKeyManagement3,
    ListingKeysafeDetails,
    ListingKeynestDetails
]);

export const ListingFull1 = intersection([
    ListingId,
    ListingAddress,
    ListingPropertySpecifics2,
    ListingPropertyMarketing,
    ListingPropertySignsControls,
    ListingKeyManagement4,
    ListingPriceAndCosts,
    ListingSellingProcess4,
    ListingCompetingAgent,
    ListingConveyancing,
    ListingDocumentManagementGenericControls,
    ListingDocumentManagementGenericDates,
    ListingDocumentManagementLeaseholdDates,
    ListingProgressionNotes,
    ListingProgressionSellerSolicitorKickOff,
    ListingProgressionBuyerSolicitorKickOff,
    ListingProgressionMortgageBrokerKickOff,
    ListingProgressionBuyerOwnSurvey,
    ListingProgressionMortgageSurvey,
    ListingProgressionBuyerConveyancerHasEverythingRequiredToStart,
    ListingProgressionMortgageOfferReceived,
    ListingProgressionBuyerConveyancerReceivedContracts,
    ListingProgressionBuyerConveyancerRaisedEnquiries,
    ListingProgressionAllEnquiriesResolved,
    ListingProgressionExchangeDate,
    ListingProgressionCompletedDate,
    ListingProgressionKeysReleasedToBuyer,
    ListingProgressionMortgageApplicationSubmitted,
    ListingSellerCompany,
]);
export type TListingFull1Codec = typeof ListingFull1;
export type TListingFull1 = TTypeOfCodec<typeof ListingFull1>;

export const ListingCRMReadOnlyData1 = intersection([
    requiredFlatOverloaded({
        address_short_string: Address2,
    }),
    requiredFlatOverloaded({
        image_url: ListingImageUrl,
    }),
    required({
        status: ListingStatusDisplayString,
        listing_has_offer_accepted: boolean(),
        buyer_conveyancer_name: string(),
        buyer_conveyancer_email_address: union([email(), nullCodec()]),
        buyer_conveyancer_organisation_name: string(),
        buyer_conveyancer_phone_number: union([phoneNumber(), nullCodec()]),
        buyer_users: array(User6),
        seller_users: array(User6),
        purchasers_company_name: string(),
        mortgage_broker_name: string(),
        mortgage_broker_email_address: union([email(), nullCodec()]),
        mortgage_broker_phone_number: union([phoneNumber(), nullCodec()]),
        accepted_offer_amount: integer(),
        buyer_payment_method: PaymentMethod,
        images: array(ListingPhotoFileIO),
        documents: array(ListingDocumentFileIO),
        all_seller_party_users_dropdown: array(User8),
        sellers_party_id: uuid(),
        accepted_offer_accepted_at: union([ dateTime(), nullCodec() ]),
        introducers: array(Introducer4),
        introducer_external_reference_id: string(),
        introducer_notes: string(),
    }),
]);
export type TListingCRMReadOnlyData1Codec = typeof ListingCRMReadOnlyData1;
export type TListingCRMReadOnlyData1 = TTypeOfCodec<typeof ListingCRMReadOnlyData1>;
