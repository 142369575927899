import * as Function from "../Function";
import * as Error from "../Error";
import * as ErrorCode from "../ErrorCode";

import { Option, some, none } from "fp-ts/lib/Option";

export const boolean: Function.TValidationFunction = (value: unknown): Option<Error.TValidationError> =>
    typeof value === "undefined" || typeof value === "boolean"
        ? none
        : some([[ErrorCode.validationErrorCodeConstants.BOOLEAN_VALIDATION]]);
