import React from "react";
import Page from "../../components/PageComponent/Page";
import { FrontTitlePageSection } from "../../components/Front/Simple/FrontPageSectionTitle/FrontTitlePageSection";
import { FrontTextColour } from "../../components/FrontTextColour/FrontTextColour";
import { WeightBold } from "../../components/WeightBold/WeightBold";
import { FrontSpacer } from "../../components/FrontSpacer/FrontSpacer";
import FrontTitleMinor from "../../components/Front/Simple/FrontTitleMinor/FrontTitleMinor";
import { TContainerStateProps } from "../../state/TContainerStateProps";

class NotFoundPage extends React.Component<React.PropsWithChildren<TContainerStateProps>> {
    public render(): JSX.Element {
        return (
            <Page
                showLogout={false}
            >
                <div className="not-found-page">
                    <FrontTitlePageSection>
                        <FrontTextColour colour={"neutral-2"}>
                            <WeightBold>
                                404 Not Found
                            </WeightBold>
                        </FrontTextColour>
                    </FrontTitlePageSection>
                    <FrontSpacer size="medium-1" />
                    <FrontTitleMinor>
                        <FrontTextColour colour={"neutral-2"}>
                            The page you were looking for does not exist.
                        </FrontTextColour>
                    </FrontTitleMinor>
                </div>
            </Page>
        );
    }
}

export default NotFoundPage;
