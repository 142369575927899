import { array as fptsArray } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import { array } from "../../../shared/src/codecs/types/array";
import { overload } from "../../../shared/src/codecs/types/overload";
import { CaseAutoAssignmentForm, TCaseAutoAssignmentForm } from "../form/CaseAutoAssignmentForm";
import { TransactionType5 } from "../TransactionType";
import { fromEquals } from "fp-ts/lib/Eq";
import { contramap } from "fp-ts/lib/Ord";
import { Ord } from "fp-ts/lib/number";
import { requireExhaustive } from "../../../shared/src/util";

export const CaseAutoAssignmentFormWithPlaceholders = overload(
    array(CaseAutoAssignmentForm),
    array(CaseAutoAssignmentForm),
    (forms) => {
        const placeholderForms =
            pipe(
                TransactionType5.values,
                fptsArray.map((transaction_type) => ({
                    ...CaseAutoAssignmentForm.newDefault(),
                    edited: {
                        ...CaseAutoAssignmentForm.newDefault().edited,
                        transaction_type,
                    },
                    original: {
                        ...CaseAutoAssignmentForm.newDefault().original,
                        transaction_type,
                    },
                }))
            );

        const uniqueTransactionType =
            fromEquals((a: TCaseAutoAssignmentForm, b: TCaseAutoAssignmentForm) => a.edited.transaction_type === b.edited.transaction_type);

        const sortTransactionType =
            contramap((form: TCaseAutoAssignmentForm) =>
                form.edited.transaction_type === "purchase" ? 1
                : form.edited.transaction_type === "sale" ? 2
                : form.edited.transaction_type === "transfer" ? 3
                : form.edited.transaction_type === "remortgage" ? 4
                : requireExhaustive(form.edited.transaction_type),
            )(Ord)

        return pipe(
            [
                ...forms,
                ...placeholderForms,
            ],
            fptsArray.uniq(uniqueTransactionType),
            fptsArray.sortBy([sortTransactionType]),
        )
    },
);
export type TCaseAutoAssignmentFormWithPlaceholdersCodec = typeof CaseAutoAssignmentFormWithPlaceholders;
