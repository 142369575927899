import { TProbateCase1AndC2From, TProbateCase2ReadOnlyFrom } from "../../../models/TFormModels";
import * as rxjs from "rxjs";
import * as TProbate from "./../../TProbate";
import * as ProbateCase1 from "../../../../../domain/models/ProbateCase1";
import * as ProbateCase2 from "../../../../../domain/models/ProbateCase2";
import * as FirstPartyFetchResponse from "../../../../../domain/models/FirstPartyFetchResponse";
import * as rxjsOperators from "rxjs/operators";
import { option } from "fp-ts/lib";
import * as TForm from "../../../models/TForm";
import * as fetchWrapper from "../../../wrappers/fetch";
import * as ProbateCaseResponse1 from "../../../../../domain/models/ProbateCaseResponse1";
import * as ProbateInterestedInStateNoUnknown from "../../../../../domain/models/ProbateInterestedInStateNoUnknown";
import * as util from "../../../util";
import { TGetState } from "../../TGetState";
import { TSetState } from "../../TSetState";
import { TDispatch } from "../../TDispatch";
import { TActionPayload, TActionsDefinitionsList } from "../TAction";
import { setFormSubscribed } from "../../../functions/lens/setFormSubscribed";
import { submitForm } from "../../../functions/lens/submitForm";
import { reduceDataToStateUpdate } from "../../../functions/lens/reduceDataToStateUpdate";
import { set } from "../../../functions/lens/set";
import { createChangeRouteAction } from "../../router/createChangeRouteAction";
import { TChangeRouteAction } from "../../router/routerTypes";

export const actions: TActionsDefinitionsList = [
    {
        type: "PROBATE_CASE_INPUT_CHANGED" as const,
        run: setFormSubscribed<"PROBATE_CASE_INPUT_CHANGED", TProbateCase1AndC2From>()(
            ["probate", "caseForm"]
        ),
    },
    {
        type: "PROBATE_GET_MY_QUOTE_BUTTON_CLICKED" as const,
        run: (
            obs$: rxjs.Observable<TActionPayload<"PROBATE_GET_MY_QUOTE_BUTTON_CLICKED", undefined>>,
            getState: TGetState,
            setState: TSetState,
            dispatch: TDispatch,
        ) =>
            submitForm<"PROBATE_GET_MY_QUOTE_BUTTON_CLICKED", undefined, TProbate.C["caseForm"]["view"], TProbate.C["caseForm"]["edit"], TProbate.C["caseForm"]["ui"]>()(
                ["probate", "caseForm"],
                () => "/v1/probate-quotes",
                "POST",
                ProbateCase1.validator,
                ProbateCase2,
                (a, r) => {
                    dispatch(
                        createChangeRouteAction(
                            "VIEW_PROBATE_QUOTE_PAGE",
                            {quoteId: r?.id ?? ""},
                            {}
                        )
                    );
                }
            )(obs$, getState, setState).subscribe(),
    },
    {
        type: "VIEW_PROBATE_QUOTE_PAGE",
        run: (
            obs$: rxjs.Observable<TChangeRouteAction<"VIEW_PROBATE_QUOTE_PAGE">>,
            getState: TGetState,
            setState: TSetState,
        ): void => {
            obs$.pipe(
                rxjsOperators.mergeMap((action) =>
                    makeGetProbateQuoteRequest(action.params.quoteId),
                ),
                rxjsOperators.tap((res) =>
                    reduceDataToStateUpdate(setState)(
                        set()(
                            ["probate", "viewCaseForm"],
                            () => TForm.dataToForm<TProbateCase2ReadOnlyFrom>({}, {})(res.response.data),
                        ),
                    )(res),
                )
            ).subscribe();
        },
    },
    {
        type: "VIEW_PROBATE_INTERESTED_PAGE",
        run: (
            obs$: rxjs.Observable<TChangeRouteAction<"VIEW_PROBATE_INTERESTED_PAGE">>,
            getState: TGetState,
            setState: TSetState,
        ): void => {
            obs$.pipe(
                rxjsOperators.mergeMap((action) =>
                    makeGetProbateQuoteRequest(action.params.quoteId),
                ),
                rxjsOperators.tap((res) =>
                    reduceDataToStateUpdate(setState)(
                        set()(
                            ["probate", "viewCaseForm"],
                            () => TForm.dataToForm<TProbateCase2ReadOnlyFrom>({}, {})(res.response.data),
                        ),
                    )(res),
                )
            ).subscribe();
        },
    },
    {
        type: "SELECT_INTEREST_IN_QUOTE_TYPE",
        run: (
            obs$: rxjs.Observable<{ type: "SELECT_INTEREST_IN_QUOTE_TYPE"; payload: ProbateInterestedInStateNoUnknown.T }>,
            getState: TGetState,
            setState: TSetState,
            dispatch: TDispatch,
        ): void => {
            obs$.pipe(
                rxjsOperators.mergeMap((action) =>
                    makeInterestedInQuoteRequest(getState().probate.viewCaseForm.view.id, action.payload),
                ),
                rxjsOperators.tap(() =>
                    dispatch(
                        createChangeRouteAction(
                            "VIEW_PROBATE_INTERESTED_PAGE",
                            {quoteId: getState().probate.viewCaseForm.view.id},
                            {}
                        )
                    )
                )
            ).subscribe();
        },
    },
];

const makeGetProbateQuoteRequest = (quoteId: string): rxjs.Observable<FirstPartyFetchResponse.T<ProbateCaseResponse1.T>> =>
    rxjs.from(
        fetchWrapper.json<ProbateCaseResponse1.T>({
            requestParams: {
                url: `${env.REACT_APP_API_URL}/v1/probate-quotes/${quoteId}`,
                method: "GET",
                body: option.none,
            },
            expectedTypeCodec: ProbateCaseResponse1.codec,
            defaultResponse: ProbateCaseResponse1.newDefault(),
        })(),
    ).pipe(rxjsOperators.tap(util.defaultCRMRequestErrorHandler));

const makeInterestedInQuoteRequest = (quoteId: string, interestedIn: ProbateInterestedInStateNoUnknown.T): rxjs.Observable<FirstPartyFetchResponse.T<ProbateCaseResponse1.T>> =>
    rxjs.from(
        fetchWrapper.json<ProbateCaseResponse1.T>({
            requestParams: {
                url: `${env.REACT_APP_API_URL}/v1/probate-quotes/${quoteId}/interest`,
                method: "POST",
                body: option.some(JSON.stringify({interested_in_state: interestedIn})),
            },
            expectedTypeCodec: ProbateCaseResponse1.codec,
            defaultResponse: ProbateCaseResponse1.newDefault(),
        })(),
    ).pipe(rxjsOperators.tap(util.defaultCRMRequestErrorHandler));
