import * as rxjs from "rxjs";
import { TGetState } from "./TGetState";
import { TSetState } from "./TSetState";
import { TDispatch } from "./TDispatch";
import { actions } from "./actions";
import { TActionsSet, applyActions } from "./applyActions";
import { TAction } from "./actions/TAction";

export const registerActions = (
    actions: TActionsSet,
    actions$: rxjs.Observable<TAction>,
    setState: TSetState,
    getState: TGetState,
    dispatch: TDispatch,
): void => {
    applyActions(actions, getState, setState, dispatch)(actions$);
};
