import React from "react";

export default (props: React.SVGAttributes<SVGElement>) => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g clipPath="url(#clip0_8307_40859)">
<path d="M18.25 12.75V11.25H22V12.75H18.25ZM19.5 20L16.475 17.75L17.375 16.55L20.4 18.8L19.5 20ZM17.45 7.425L16.55 6.225L19.5 4L20.4 5.2L17.45 7.425ZM5.25 19V15H3.5C3.08333 15 2.72917 14.8542 2.4375 14.5625C2.14583 14.2708 2 13.9167 2 13.5V10.5C2 10.0833 2.14583 9.72917 2.4375 9.4375C2.72917 9.14583 3.08333 9 3.5 9H8L13 6V18L8 15H6.75V19H5.25ZM14 15.35V8.65C14.45 9.05 14.8125 9.5375 15.0875 10.1125C15.3625 10.6875 15.5 11.3167 15.5 12C15.5 12.6833 15.3625 13.3125 15.0875 13.8875C14.8125 14.4625 14.45 14.95 14 15.35ZM3.5 10.5V13.5H8.4L11.5 15.35V8.65L8.4 10.5H3.5Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_8307_40859">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>
;
