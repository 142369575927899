import { pipe } from "fp-ts/lib/function";

type T = string;

export const standardise = (nonStandardPostcode: T): T =>
     pipe(
        removeWhitespace(nonStandardPostcode),
        uppercase,
        addWhitespace,
    );

const removeWhitespace = (s: T): T =>
    s.replace(/\s/g, "");

const uppercase = (s: T): T => s.toUpperCase();

const addWhitespace = (s: T): T =>
    s.length >= 5
        ?`${s.slice(0, -3)} ${s.slice(-3)}`
        : s;
