import * as t from "io-ts";
import * as util from "../../shared/src/util";

export const codec = t.union([
    t.literal("purchase"),
    t.literal("sale"),
    t.literal("transfer"),
    t.literal("remortgage"),
    t.literal("unknown"),
]);

export const values = util.typeValuesFromCodec(codec.types as Array<t.LiteralC<T>>);

type T = t.TypeOf<typeof codec>;

export const TransactionType2 = [
    "Purchase",
    "Sale",
    "Transfer",
    "Remortgage",
    "Transaction type not set",
] as const;

export type TTransactionType2 = typeof TransactionType2[number];
