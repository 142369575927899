import * as t from "io-ts";
import * as regexes from "../../shared/src/regexes";
import { boolean } from "../../shared/src/validation/basic/boolean";
import { integer } from "../../shared/src/validation/basic/integer";
import { nonEmptyString } from "../../shared/src/validation/basic/nonEmptyString";
import { required } from "../../shared/src/validation/basic/required";
import { string } from "../../shared/src/validation/basic/string";
import { inOrder } from "../../shared/src/validation/compose/inOrder";
import { isStrictlyEqualTo } from "../../shared/src/validation/create/isStrictlyEqualTo";
import { nullable } from "../../shared/src/validation/create/nullable";
import { object } from "../../shared/src/validation/create/object";
import { regex } from "../../shared/src/validation/create/regex";
import { TValidationFunction } from "../../shared/src/validation/Function";

export const codec = t.type({
    full_name: t.string,
    phone_number: t.string,
    email: t.string,
    owns_property: t.boolean,
    number_of_properties_owned: t.union([t.null, t.number]),
    foreign_properties_owned: t.boolean,
    banking_institutes_with_assets_count: t.union([t.null, t.number]),
    organizations_with_shares_owned_count: t.union([t.null, t.number]),
    pensions_count: t.union([t.null, t.number]),
    vehicles_owned_count: t.union([t.null, t.number]),
    life_protection_policies_count: t.union([t.null, t.number]),
    government_financial_schemes_count: t.union([t.null, t.number]),
    credit_cards_count: t.union([t.null, t.number]),
    loans_outstanding_count: t.union([t.null, t.number]),
    mortgages_count: t.union([t.null, t.number]),
    assets_and_liabilities_includes_foreign: t.boolean,
    estate_is_estimated_to_be_taxable: t.boolean,
    will_exists: t.boolean,
    gdpr_agreed: t.boolean,
});

export type T = t.TypeOf<typeof codec>;

export const newDefault = (): T => ({
    full_name: "",
    phone_number: "",
    email: "",
    owns_property: false,
    number_of_properties_owned: 0,
    foreign_properties_owned: false,
    banking_institutes_with_assets_count: null,
    organizations_with_shares_owned_count: null,
    pensions_count: null,
    vehicles_owned_count: null,
    life_protection_policies_count: null,
    government_financial_schemes_count: null,
    credit_cards_count: null,
    loans_outstanding_count: null,
    mortgages_count: null,
    assets_and_liabilities_includes_foreign: false,
    estate_is_estimated_to_be_taxable: false,
    will_exists: false,
    gdpr_agreed: false,
});

export const totalAssetCountExclProperty = (m: Omit<T, "gdpr_agreed">): number =>
    (m.banking_institutes_with_assets_count ?? 0)
    + (m.organizations_with_shares_owned_count ?? 0)
    + (m.pensions_count ?? 0)
    + (m.vehicles_owned_count ?? 0)
    + (m.life_protection_policies_count ?? 0)
    + (m.government_financial_schemes_count ?? 0);

export const totalLiabilitiesCount = (m: Omit<T, "gdpr_agreed">): number =>
    + (m.credit_cards_count ?? 0)
    + (m.loans_outstanding_count ?? 0)
    + (m.mortgages_count ?? 0);

export const validator: TValidationFunction =
    object({
        full_name: inOrder(
            required,
            string,
        ),
        phone_number: nullable(
            inOrder(
                nonEmptyString,
                regex(regexes.constants.MOBILE_PHONE_REGEX)
            )
        ),
        email: nullable(
            inOrder(
                nonEmptyString,
                regex(regexes.constants.EMAIL_REGEX)
            )
        ),
        owns_property: inOrder(
            required,
            boolean,
        ),
        number_of_properties_owned: inOrder(
            required,
            integer,
        ),
        foreign_properties_owned: inOrder(
            required,
            boolean,
        ),
        banking_institutes_with_assets_count: inOrder(
            required,
            integer,
        ),
        organizations_with_shares_owned_count: inOrder(
            required,
            integer,
        ),
        pensions_count: inOrder(
            required,
            integer,
        ),
        vehicles_owned_count: inOrder(
            required,
            integer,
        ),
        life_protection_policies_count: inOrder(
            required,
            integer,
        ),
        government_financial_schemes_count: inOrder(
            required,
            integer,
        ),
        credit_cards_count: inOrder(
            required,
            integer,
        ),
        loans_outstanding_count: inOrder(
            required,
            integer,
        ),
        mortgages_count: inOrder(
            required,
            integer,
        ),
        will_exists: inOrder(
            required,
            boolean,
        ),
        assets_and_liabilities_includes_foreign: inOrder(
            required,
            boolean,
        ),
        estate_is_estimated_to_be_taxable: inOrder(
            required,
            boolean,
        ),
        gdpr_agreed: isStrictlyEqualTo(true),
    });
