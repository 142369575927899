import { TTypeOfCodec } from "../../../shared/src/codecs/codec";
import { array, TArrayCodec } from "../../../shared/src/codecs/types/array";
import { boolean, TBooleanCodec } from "../../../shared/src/codecs/types/boolean";
import { dateTime, TAnyDateTimeCodec } from "../../../shared/src/codecs/types/dateTime";
import { TFormCodec, form } from "../../../shared/src/codecs/types/form";
import { literal } from "../../../shared/src/codecs/types/literal";
import { nullCodec, TNullCodec } from "../../../shared/src/codecs/types/nullCodec";
import { TRequiredCodec, required } from "../../../shared/src/codecs/types/required";
import { string, TAnyStringCodec } from "../../../shared/src/codecs/types/string";
import { TUnionCodec, union } from "../../../shared/src/codecs/types/union";
import { TUuidCodec, uuid } from "../../../shared/src/codecs/types/uuid";
import { TEmptyObjectCodec, EmptyObject } from "../EmptyObject";
import { TUuidObjectCodec, UuidObject } from "../Id";
import { Pagination1, Pagination3, TPagination1Codec, TPagination3Codec } from "../Pagination";

export const ProbateHelplineCallbackMarkCompleteForm: TFormCodec<TUuidObjectCodec, TEmptyObjectCodec> = form(UuidObject, EmptyObject);
export type TProbateHelplineCallbackMarkCompleteFormCodec = typeof ProbateHelplineCallbackMarkCompleteForm;
export type TProbateHelplineCallbackMarkCompleteForm = TTypeOfCodec<TProbateHelplineCallbackMarkCompleteFormCodec>;

export const ProbateHelplineCallbackForm: TFormCodec<TRequiredCodec<{
    id: TUuidCodec,
    notes: TAnyStringCodec,
    defer_timestamp: TUnionCodec<[TNullCodec, TAnyDateTimeCodec]>,
}>, TRequiredCodec<{
    email: TAnyStringCodec,
    first_name: TAnyStringCodec,
    last_name: TAnyStringCodec,
    phone_number: TAnyStringCodec,
    user_has_consented_to_shared_data: TBooleanCodec,
    created_at: TAnyDateTimeCodec,
    mark_callback_complete_form: TProbateHelplineCallbackMarkCompleteFormCodec,
}>> = form(
    required({
        id: uuid(),
        notes: string(),
        defer_timestamp: union([nullCodec(), dateTime()]),
    }),
    required({
        email: string(),
        first_name: string(),
        last_name: string(),
        phone_number: string(),
        user_has_consented_to_shared_data: boolean(),
        created_at: dateTime(),
        mark_callback_complete_form: ProbateHelplineCallbackMarkCompleteForm,
    }),
);
export type TProbateHelplineCallbackFormCodec = typeof ProbateHelplineCallbackForm;
export type TProbateHelplineCallbackForm = TTypeOfCodec<TProbateHelplineCallbackFormCodec>;

export const ProbateHelplineCallbackTypeForm: TFormCodec<TPagination1Codec, TRequiredCodec<{
    counts: TPagination3Codec,
    callbacks: TArrayCodec<TProbateHelplineCallbackFormCodec>,
}>> = form(
    Pagination1,
    required({
        counts: Pagination3,
        callbacks: array(ProbateHelplineCallbackForm),
    }),
);
export type TProbateHelplineCallbackTypeFormCodec = typeof ProbateHelplineCallbackTypeForm;
export type TProbateHelplineCallbackTypeForm = TTypeOfCodec<TProbateHelplineCallbackTypeFormCodec>;

export const ProbateHelplineCallbackTypeVisible = union([
    literal("NOW"),
    literal("COMPLETED"),
]);
export type TProbateHelplineCallbackTypeVisibleCodec = typeof ProbateHelplineCallbackTypeVisible;
export type TProbateHelplineCallbackTypeVisible = TTypeOfCodec<TProbateHelplineCallbackTypeVisibleCodec>;
