import React from "react";
import { TCRMMainColours } from "../../models/TCRMMainColours";
import { TIcon } from "../CRMIcon/CRMIcon";
import { CRMIconBadge } from "../CRMIconBadge/CRMIconBadge";
import * as TFormStatus from "./../../models/TFormStatus";

type TFormStatusBadge = {
    formStatus: TFormStatus.T;
};

const FormStatusToIconName = (status: TFormStatus.T): TIcon => {
    switch (status) {
        case "submitting":
            return "pending";
        case "success":
            return "done-outline";
        case "failure":
            return "error-outline";
        case "validationError":
            return "warning-bubble";
        default:
            return "inputs-touched";
    }
};

const FormStatusToIconColour = (status: TFormStatus.T): TCRMMainColours => {
    switch (status) {
        case "success":
            return "highlights-positive-green-2";
        case "failure":
            return "highlights-instructional-brick-2";
        case "validationError":
            return "highlights-instructional-brick-2";
        default:
            return "neutral-ink";
    }
};

const FormStatusToBadgeColour = (status: TFormStatus.T): TCRMMainColours => {
    switch (status) {
        case "untouched":
            return "neutral-paper";
        case "requiresSubmission":
            return "neutral-paper";
        case "submitting":
            return "neutral-12";
        case "success":
            return "highlights-positive-green-12";
        case "failure":
            return "highlights-instructional-brick-12";
        case "validationError":
            return "highlights-pertinent-yellow-10";
        default:
            return "neutral-paper";
    }
};

export class CRMFormStatusBadge extends React.Component<React.PropsWithChildren<TFormStatusBadge>> {
    public render (): JSX.Element {
        return (
            <CRMIconBadge
                icon={FormStatusToIconName(this.props.formStatus)}
                iconColour={FormStatusToIconColour(this.props.formStatus)}
                badgeColour={FormStatusToBadgeColour(this.props.formStatus)}
            />
        );
    }
}
