import { union } from "../../shared/src/codecs/types/union";
import { literal } from "../../shared/src/codecs/types/literal";

export const ListingOutdoorSpaceType = union([
    literal("back_garden"),
    literal("communal_garden"),
    literal("enclosed_garden"),
    literal("front_garden"),
    literal("private_garden"),
    literal("rear_garden"),
    literal("terrace"),
    literal("patio"),
    literal("none"),
    literal("balcony"),
    literal("juliette_balcony"),
]);
