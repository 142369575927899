import * as t from "io-ts";
import * as PartySellingPropertyState from "./PartySellingPropertyState";
import * as PartyPurchasingSituation from "./PartyPurchasingSituation";
import * as PartyMovingReason from "./PartyMovingReason";
import { object } from "../../shared/src/validation/create/object";
import { isEnum } from "../../shared/src/validation/create/isEnum";
import { string } from "../../shared/src/validation/basic/string";

export const codec = t.partial({
    situation_notes: t.string,
    moving_reason: PartyMovingReason.codec,
    selling_property_state: PartySellingPropertyState.codec,
    purchasing_situation: PartyPurchasingSituation.codec,
});

export const validator = object({
    situation_notes: string,
    moving_reason: isEnum(PartyMovingReason.values),
    selling_property_state: isEnum(PartySellingPropertyState.values),
    purchasing_situation: isEnum(PartyPurchasingSituation.values),
});

export type T = t.TypeOf<typeof codec>;

export const newDefault = (): Required<T> => ({
    situation_notes: "",
    moving_reason: "unknown",
    selling_property_state: "unknown",
    purchasing_situation: "unknown",
});
