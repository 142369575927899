import * as t from "io-ts";
import * as CaseDocumentType1 from "./CaseDocumentType1";

const codec = t.type({
    type: CaseDocumentType1.codec,
    due_date: t.string,
    valid_until: t.union([t.string, t.null]),
    received_date: t.union([t.string, t.null]),
    additional_name: t.string,
    from_external_source: t.boolean,
    shared_with_client: t.boolean,
    shared_with_solicitor: t.boolean,
});

export type T = t.TypeOf<typeof codec>;

export const newDefault = (): T => ({
    type: "ta6",
    due_date: "2021-01-01T01:02:03",
    valid_until: null,
    received_date: null,
    additional_name: "",
    from_external_source: false,
    shared_with_client: false,
    shared_with_solicitor: false,
});
