import { TTypeOfCodec } from "../../../shared/src/codecs/codec";
import { form, TFormCodec } from "../../../shared/src/codecs/types/form";
import { CaseNewLedgerEntry, TCaseNewLedgerEntryCodec } from "../CaseNewLedgerEntry";
import { EmptyObject, TEmptyObjectCodec } from "../EmptyObject";

export const CaseNewLedgerEntryForm: TFormCodec<TCaseNewLedgerEntryCodec, TEmptyObjectCodec> = 
    form(
        CaseNewLedgerEntry,
        EmptyObject,
    );

export type TCaseNewLedgerEntryFormCodec = typeof CaseNewLedgerEntryForm;
export type TCaseNewLedgerEntryForm = TTypeOfCodec<TCaseNewLedgerEntryFormCodec>;