import * as t from "io-ts";
import * as ViewingType from "./ViewingType";
import * as ViewingStatus1 from "./ViewingStatus1";

const codec = t.type({
    status: ViewingStatus1.codec,
    viewing_type: ViewingType.codec,
    requested_times: t.array(t.string),
    confirmed_time: t.union([t.string, t.null]),
    agent: t.string,
    feedback: t.string,
});

export type T = t.TypeOf<typeof codec>;

export const newDefault = (): T => ({
    status: "pending",
    viewing_type: "in_person",
    requested_times: [],
    confirmed_time: null,
    agent: "",
    feedback: "",
});
