import * as t from "io-ts";
import * as Country2 from "./Country2";
import * as ListingReasonForSale1 from "./ListingReasonForSale1";
import * as GetAddressIOResponse from "./GetAddressIOResponse";

const codec = t.intersection([
    t.type({
        street_name: t.string,
        county: t.string,
        city_town: t.string,
        country: Country2.codec,
    }),
    t.partial({
        sub_building_name: t.string,
        sub_building_number: t.string,
        locality: t.string,
        district: t.string,
        latitude: t.number,
        longitude: t.number,
        reason_for_sale: ListingReasonForSale1.codec,
        admin_notes: t.string,
    }),
]);

export type T = t.TypeOf<typeof codec>;

export const newDefault = (): Required<T> => ({
    street_name: "",
    county: "",
    city_town: "",
    country: "england",
    sub_building_name: "",
    sub_building_number: "",
    locality: "",
    district: "",
    latitude: 0,
    longitude: 0,
    reason_for_sale: "owner_initiated",
    admin_notes: "",
});

export const fromOwnTypeAndGetAddressIOResponse = (listing: Required<T>, response: GetAddressIOResponse.T,): Required<T> => {
    if (response.addresses.length > 0) {
        const firstAddress = response.addresses[0];
        return {
            latitude: response.latitude,
            longitude: response.longitude,
            country: Country2.fromGetAddressIOApiCountryValueToCountryValue(firstAddress.country),
            county: firstAddress.county,
            district: firstAddress.district,
            locality: firstAddress.locality,
            city_town: firstAddress.town_or_city,
            street_name: firstAddress.thoroughfare,
            sub_building_name: firstAddress.sub_building_name,
            sub_building_number: firstAddress.sub_building_number,
            admin_notes: listing.admin_notes,
            reason_for_sale: listing.reason_for_sale,
        };
    }
    return listing;
};
