import { TTypeOfCodec } from "../../../shared/src/codecs/codec";
import { form, TFormCodec } from "../../../shared/src/codecs/types/form";
import { required, TRequiredCodec } from "../../../shared/src/codecs/types/required";
import { string, TAnyStringCodec } from "../../../shared/src/codecs/types/string";
import { TAnyUuidCodec, uuid } from "../../../shared/src/codecs/types/uuid";
import { EmptyObject, TEmptyObjectCodec } from "../EmptyObject";

export const ChatsMessageForm: TFormCodec<
    TRequiredCodec<{
        chats_id: TAnyUuidCodec,
        roadblock_id: TAnyUuidCodec,
        note_body: TAnyStringCodec
    }>,
    TEmptyObjectCodec
> = form(
    required({
        chats_id: uuid(),
        roadblock_id: uuid(),
        note_body: string()
    }),
    EmptyObject
);
export type TChatsMessageFormCodec = typeof ChatsMessageForm;
export type TChatsMessageForm = TTypeOfCodec<TChatsMessageFormCodec>;


export const ChatCommandActions = [
    "assign" as const,
    "await" as const,
    "add-check-item" as const,
];

export type TChatCommandAction = typeof ChatCommandActions[number];
export type TChatCommandString = `#${TChatCommandAction}:${string}` | `#${TChatCommandAction}`;
export const isAValidChatCommandAction = (action: string) => ChatCommandActions.includes(action as TChatCommandAction);

export type TChatCommand = {
    action: TChatCommandAction;
    target?: string;
}