import * as t from "io-ts";
import * as Party4 from "./Party4";
import * as Party6 from "./Party6";

const codec = t.partial({
    ...Party4.codec.props,
    ...Party6.codec.props,
});

export type T = t.TypeOf<typeof codec>;

export const newDefault = (): Required<T> => ({
    ...Party4.newDefault(),
    ...Party6.newDefault(),
});
