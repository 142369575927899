import { TCodec } from "../codec";
import { TError, errorConstants } from "../errors";
import { either } from "fp-ts/lib";

export type TAnyRegexCodec = TCodec<
    "RegexCodec",
    undefined,
    string ,
    string
>;


export const regex = (expression: RegExp): TAnyRegexCodec => {
    const decode = (input: unknown): either.Either<TError, string> =>
        typeof input === "string"
        && expression.test(input)
            ? either.right(input)
            : either.left([[errorConstants.REGEX_VALIDATION, ""]]);
    
    const decodeNewDefault = (input: unknown): either.Either<TError, string> =>
        typeof input === "string"
            ? either.right(input)
            : either.left([[errorConstants.REGEX_VALIDATION, ""]]);
    

    return ({
        type: "RegexCodec",
        payload: undefined,
        decode,
        decodeNewDefault,
        newDefault: () => "",
    });
};
