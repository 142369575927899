import React from "react";
export class FrontParagraphRegular extends React.Component<React.PropsWithChildren<{}>> {
    public render (): JSX.Element {
        return (
            <div className="front-paragraph-regular">{this.props.children}</div>
        ); 
    }
}

export default FrontParagraphRegular;
