export const constants = {
    LOADING: "loading" as const,
    UNTOUCHED: "untouched" as const,
    REQUIRES_SUBMISSION: "requiresSubmission" as const,
    VALIDATION_ERROR: "validationError" as const,
    SUBMITTING: "submitting" as const,
    SUCCESS: "success" as const,
    FAILURE: "failure" as const,
    HIDDEN: "hidden" as const,
};

export type T = typeof constants[keyof typeof constants];

export const hasUnsavedChanged = (s: T): boolean =>
    s === constants.REQUIRES_SUBMISSION
    || s === constants.SUBMITTING
    || s === constants.FAILURE
    || s === constants.VALIDATION_ERROR;

export const reduceToHighestPriority = (sa: Array<T>): T =>
    sa.includes(constants.LOADING) ? constants.LOADING
    : sa.includes(constants.SUBMITTING) ? constants.SUBMITTING
    : sa.includes(constants.FAILURE) ? constants.FAILURE
    : sa.includes(constants.VALIDATION_ERROR) ? constants.VALIDATION_ERROR
    : sa.includes(constants.REQUIRES_SUBMISSION) ? constants.REQUIRES_SUBMISSION
    : sa.includes(constants.SUCCESS) ? constants.SUCCESS
    : sa.includes(constants.UNTOUCHED) ? constants.UNTOUCHED
    : sa.length > 0 ? sa[0]
    : constants.UNTOUCHED;

export const UsualFormStatusToDisabledState = (status: T): boolean =>
    status === "untouched" || status === "submitting";
