import { union } from "../../shared/src/codecs/types/union";
import { literal } from "../../shared/src/codecs/types/literal";
import { TTypeOfCodec } from "../../shared/src/codecs/codec";

export const CasePropertyTitleGuarantee1 = union([
    literal("full"),
    literal("limited"),
]);
export const CasePropertyTitleGuaranteeWithoutUnknown = CasePropertyTitleGuarantee1;
export type TCasePropertyTitleGuaranteeWithoutUnknown = TTypeOfCodec<typeof CasePropertyTitleGuarantee1>;

export const CasePropertyTitleGuarantee2 = union([
    literal("unknown"),
    ...CasePropertyTitleGuarantee1.payload,
]);
export const CasePropertyTitleGuaranteeWithUnknown = CasePropertyTitleGuarantee2;
export type TCasePropertyTitleGuaranteeWithUnknown = TTypeOfCodec<typeof CasePropertyTitleGuarantee2>;