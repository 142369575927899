import { array, option } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import * as JsonInnerError1 from "./../../../domain/models/JsonInnerError1";
import * as PaginationMeta1 from "./../../../domain/models/PaginationMeta1";
import * as JsonResponseMeta1 from "./../../../domain/models/JsonResponseMeta1";
import * as FirstPartyFetchResponse from "./../../../domain/models/FirstPartyFetchResponse";
import * as JsonResponse1 from "./../../../domain/models/JsonResponse1";
import * as TFormStatus from "./TFormStatus";
import * as fptsExt from "../../../shared/src/utilByDomainGroupExport";
import { IFormButton } from "../components/FormButtonComponent/FormButton";

export type TForm<F, R, I> = {
    status: TFormStatus.T;
    form: F;
    response: R;
    meta: I;
};

export type TFormV2<V, E, U extends Record<string, unknown>> = {
    status: TFormStatus.T;
    defaultEdit: Required<E>;
    defaultStatus: TFormStatus.T;
    edit: Required<E>;
    view: Omit<V, keyof E>;
    validationErrors: Array<JsonInnerError1.T>;
    ui: U;
};

export type TFormList<V, E, U extends Record<string, unknown>> = {
    status: TFormStatus.T;
    defaultEdit: E;
    defaultUi: U;
    forms: Array<TFormV2<V, E, U>>;
    meta: PaginationMeta1.T | JsonResponseMeta1.T;
};

export type TModal = "open" | "closed";

export const defaultTFormV2 = <V, E, U extends Record<string, unknown>>(view: V, edit: Required<E>, ui: U, defaultStatus?: TFormStatus.T): TFormV2<V, E, U> => ({
    status: "loading",
    defaultEdit: edit,
    defaultStatus: defaultStatus || TFormStatus.constants.UNTOUCHED,
    edit,
    view,
    validationErrors: [],
    ui,
});

export const defaultTListForm = <A, E, U extends Record<string, unknown>>(defaultEdit: E, defaultUi: U): TFormList<A, E, U> => ({
    status: "loading",
    defaultEdit,
    defaultUi,
    forms: [],
    meta: {},
});

export const isFormButtonDisabled = (formButtonProps: IFormButton): boolean =>
    formButtonProps.disabled
    || formButtonProps.status === "submitting"
    || formButtonProps.status === TFormStatus.constants.UNTOUCHED
;

export const updateFromAction = <
    T extends TForm<T["form"], T["response"], T["meta"]>,
    K extends keyof T["form"],
    A extends {
        key: K;
        value: T["form"][K];
    }
    >(form: T, action: A): T => {
        form.form[action.key] = action.value;
        form.status = TFormStatus.constants.REQUIRES_SUBMISSION;
        return form;
    };

export type TUnpackViewAndEditFormType<T> = T extends TFormV2<infer A, any, any> ? A extends Record<string, unknown> ? A : never : never; // eslint-disable-line
export type TUnpackEditFormType<T> = T extends TFormV2<any, infer B, any> ? B extends Record<string, unknown> ? B : never : never; // eslint-disable-line
export type TUnpackUiType<T> = T extends TFormV2<any, any, infer C> ? C extends Record<string, unknown> ? C : never : never; // eslint-disable-line
type TUnpackToFormList<T> = TFormList<TUnpackViewAndEditFormType<T>, TUnpackEditFormType<T>, TUnpackUiType<T>> // eslint-disable-line
type TUnpackToFormListForm<T> = TFormList<TUnpackViewAndEditFormType<T>, TUnpackEditFormType<T>, TUnpackUiType<T>>["forms"][number] // eslint-disable-line

type TUpdateFormAction<E> = {
    key: keyof E;
    value: E[keyof E];
    resourceId?: string;
};

export const updateFromAction2 = <T>(
    form: TFormV2<TUnpackViewAndEditFormType<T>, TUnpackEditFormType<T>, TUnpackUiType<T>>,
    action: TUpdateFormAction<TUnpackEditFormType<T>>
): TFormV2<TUnpackViewAndEditFormType<T>, TUnpackEditFormType<T>, TUnpackUiType<T>> => {
    form.edit[action.key] = action.value;
    form.status = TFormStatus.constants.REQUIRES_SUBMISSION;
    return form;
};

export const responseToForm =
    <T>(
        form: TFormV2<TUnpackViewAndEditFormType<T>, TUnpackEditFormType<T>, TUnpackUiType<T>>,
        request: FirstPartyFetchResponse.T<JsonResponse1.T<TUnpackViewAndEditFormType<T>, {}>>,
    ): TFormV2<TUnpackViewAndEditFormType<T>, TUnpackEditFormType<T>, TUnpackUiType<T>> => ({
        status: TFormStatus.constants.UNTOUCHED,
        defaultEdit: form.defaultEdit,
        defaultStatus: form.defaultStatus,
        validationErrors: [],
        edit: fptsExt.record.intersectionByKey(request.response.data, form.edit),
        view: fptsExt.record.differenceByKey(request.response.data, form.edit),
        ui: form.ui,
    });

export const dataToForm =
    <T>(codec: TUnpackEditFormType<T>, ui: TUnpackUiType<T>) =>
        (response: TUnpackViewAndEditFormType<T>): TFormV2<TUnpackViewAndEditFormType<T>, TUnpackEditFormType<T>, TUnpackUiType<T>> => ({
            status: TFormStatus.constants.UNTOUCHED,
            defaultEdit: codec,
            defaultStatus: TFormStatus.constants.UNTOUCHED,
            validationErrors: [],
            edit: fptsExt.record.intersectionByKey(response, codec),
            view: fptsExt.record.differenceByKey(response, codec),
            ui: {...ui},
        });

export const requestToFormList = <T>(
    formList: TUnpackToFormList<T>,
    request: FirstPartyFetchResponse.T<JsonResponse1.T<Array<TUnpackViewAndEditFormType<T>>, PaginationMeta1.T | JsonResponseMeta1.T>>
): TUnpackToFormList<T> => ({
        status: TFormStatus.constants.UNTOUCHED,
        defaultEdit: formList.defaultEdit,
        defaultUi: formList.defaultUi,
        forms: request.response.data.map(dataToForm<T>(formList.defaultEdit, formList.defaultUi)),
        meta: request.response.meta,
    });

export const dataToFormsPreserveOld = <T>(
    formList: TUnpackToFormList<T>,
    data: Array<TUnpackViewAndEditFormType<T>>,
    dataExists: (existingForm: TUnpackToFormListForm<T>, newData: TUnpackViewAndEditFormType<T>) => boolean,
): TUnpackToFormList<T>["forms"] =>
    data.map((formData) =>
         pipe(
            formList.forms,
            array.findFirst<TUnpackToFormListForm<T>>((existingForm) => dataExists(existingForm, formData)),
            option.fold<TUnpackToFormListForm<T>, TUnpackToFormListForm<T>>(
                () => dataToForm<T>(formList.defaultEdit, formList.defaultUi)(formData),
                (existingForm) => existingForm
            )
        )
    )
;

const dataToFormsPreserveSpecific = <T>(
    formList: TUnpackToFormList<T>,
    data: Array<TUnpackViewAndEditFormType<T>>,
    dataExists: (currentForm: TUnpackToFormListForm<T>, incoming: TUnpackViewAndEditFormType<T>) => boolean,
    specificDataMap: (currentForm: TUnpackToFormListForm<T>, incoming: TUnpackToFormListForm<T>) => TUnpackToFormListForm<T>,
): TUnpackToFormList<T>["forms"] =>
    data.map((formData) =>
         pipe(
            formList.forms,
            array.findFirst<TUnpackToFormListForm<T>>((currentForm) => dataExists(currentForm, formData)),
            option.fold<TUnpackToFormListForm<T>, TUnpackToFormListForm<T>>(
                () => dataToForm<T>(formList.defaultEdit, formList.defaultUi)(formData),
                (currentForm) => specificDataMap(
                    currentForm,
                    dataToForm<T>(formList.defaultEdit, formList.defaultUi)(formData)
                )
            )
        )
    )
;

export const requestToFormListPreserveOld = <T>(
    formList: TUnpackToFormList<T>,
    request: FirstPartyFetchResponse.T<JsonResponse1.T<Array<TUnpackViewAndEditFormType<T>>, PaginationMeta1.T | JsonResponseMeta1.T>>,
    dataExists: (existingForm: TUnpackToFormListForm<T>, newData: TUnpackViewAndEditFormType<T>) => boolean,
): TUnpackToFormList<T> => ({
        status: formList.status,
        defaultEdit: formList.defaultEdit,
        defaultUi: formList.defaultUi,
        forms: dataToFormsPreserveOld<T>(formList, request.response.data, dataExists),
        meta: request.response.meta,
    });

export const requestToFormListPreserveSpecific = <T>(
    formList: TUnpackToFormList<T>,
    request: FirstPartyFetchResponse.T<JsonResponse1.T<Array<TUnpackViewAndEditFormType<T>>, PaginationMeta1.T | JsonResponseMeta1.T>>,
    dataExists: (existingForm: TUnpackToFormListForm<T>, newData: TUnpackViewAndEditFormType<T>) => boolean,
    specificDataMap: (currentForm: TUnpackToFormListForm<T>, incoming: TUnpackToFormListForm<T>) => TUnpackToFormListForm<T>,
): TUnpackToFormList<T> => ({
        status: formList.status,
        defaultEdit: formList.defaultEdit,
        defaultUi: formList.defaultUi,
        forms: dataToFormsPreserveSpecific<T>(formList, request.response.data, dataExists, specificDataMap),
        meta: request.response.meta,
    });

export const setFormToSubmitting = <T>() => (form: TFormV2<TUnpackViewAndEditFormType<T>, TUnpackEditFormType<T>, TUnpackUiType<T>>): TFormV2<TUnpackViewAndEditFormType<T>, TUnpackEditFormType<T>, TUnpackUiType<T>> => {
    form.status = TFormStatus.constants.REQUIRES_SUBMISSION;
    form.validationErrors = [];
    return form;
};

export const resolveStatus = <T>() => (
    form: TFormV2<TUnpackViewAndEditFormType<T>, TUnpackEditFormType<T>, TUnpackUiType<T>>,
    response: FirstPartyFetchResponse.T<JsonResponse1.T<unknown, {}>>,
): TFormV2<TUnpackViewAndEditFormType<T>, TUnpackEditFormType<T>, TUnpackUiType<T>> => {

    form.status =
        response.tag === FirstPartyFetchResponse.constants.STATUS_2XX
        || response.tag === FirstPartyFetchResponse.constants.STATUS_204
            ? TFormStatus.constants.SUCCESS
            : TFormStatus.constants.FAILURE;
    return form;
};

export const resetCompleted = <T>() => (
    form: TFormV2<TUnpackViewAndEditFormType<T>, TUnpackEditFormType<T>, TUnpackUiType<T>>,
): TFormV2<TUnpackViewAndEditFormType<T>, TUnpackEditFormType<T>, TUnpackUiType<T>> => {
    form.status = form.status === TFormStatus.constants.SUCCESS ?
        form.defaultStatus :
        form.status
    ;
    return form;
};

export const resolveErrors = <T>() => (
    form: TFormV2<TUnpackViewAndEditFormType<T>, TUnpackEditFormType<T>, TUnpackUiType<T>>,
    response: FirstPartyFetchResponse.T<JsonResponse1.T<unknown, {}>>,
): TFormV2<TUnpackViewAndEditFormType<T>, TUnpackEditFormType<T>, TUnpackUiType<T>> => {
    form.validationErrors = response.validationInnerErrors;
    return form;
};

export const reduceFormListStatusToHighestPriority = (formList: TFormList<unknown, unknown, {}>): TFormList<unknown, unknown, {}> => {
    formList.status =  pipe(
        formList.forms,
        array.map((form) => form.status),
        TFormStatus.reduceToHighestPriority,
    );
    return formList;
};
