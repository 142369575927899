import React from "react";
import { isAFunction } from "../../functions/functions";

type TBackgroundColourProps = {
    colour: string;
    borderRadius?: string;
    onClick?: () => void;
    title?: string;
};

export const BackgroundColour: React.FunctionComponent<React.PropsWithChildren<TBackgroundColourProps>> = (props): JSX.Element => {
    return (
        <div
            title={props.title}
            style={{
                backgroundColor: props.colour,
                borderRadius: props.borderRadius,
                width: "100%",
                cursor: isAFunction(props.onClick) ? "pointer" : "inherit",
            }}
            onClick={props.onClick}
        >
            {props.children}
        </div>
    );
};
