import * as Function from "../Function";
import * as Error from "../Error";
import * as ErrorCode from "../ErrorCode";
import { isObject } from "../../util";
import { option } from "fp-ts/lib";

export const objectCallback =
    (callback: (val: Record<string, unknown>) => option.Option<Error.TValidationError>): Function.TValidationFunction =>
        (val: unknown): option.Option<Error.TValidationError> => {
            if (isObject(val)) {
                return callback(val);
            }
            return option.some([[ErrorCode.validationErrorCodeConstants.OBJECT_VALIDATION]]);
        };
