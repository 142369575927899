import React from "react";

type TCRMSpacer = {
    size: "tiny" | "medium" | "large" | "x-large";
};

export class CRMSpacer extends React.Component<React.PropsWithChildren<TCRMSpacer>> {
    public render (): JSX.Element {
        return (
            <div className={`crm-spacer crm-spacer--${this.props.size}`}></div>
        );
    }
}
