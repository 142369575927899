import { requireExhaustive } from "../../shared/src/util";
import * as InternalScope from "../../domain/models/InternalScope";

export type T = "web" | "api";

export const getDomainNameFromEnv = (p: T): string =>
    p === "web" ? env.REACT_APP_ROOT_DOMAIN || ""
    : p === "api" ? process.env.DOMAIN_NAME || ""
    : requireExhaustive(p);

export const getSailLegalDomainNameFromEnv = (p: T): string =>
    p === "web" ? env.REACT_APP_SAIL_LEGAL_DOMAIN_NAME || ""
    : p === "api" ? process.env.SAIL_LEGAL_DOMAIN_NAME || ""
    : requireExhaustive(p);

export const getSailProbateDomainNameFromEnv = (p: T): string =>
    p === "web" ? env.REACT_APP_SAIL_PROBATE_DOMAIN_NAME || ""
    : p === "api" ? process.env.SAIL_PROBATE_DOMAIN_NAME || ""
    : requireExhaustive(p);

export const getCrmLinkFromHostnameAndEnv = (p: T, hostname: string): string => {
    if ( hostname.endsWith(getDomainNameFromEnv(p)) ) {
        return `https://listings.${getDomainNameFromEnv(p)}`;
    }
    else if (hostname.endsWith(getSailLegalDomainNameFromEnv(p)) ) {
        return `https://portal.${getSailLegalDomainNameFromEnv(p)}`;
    }
    else if (hostname.startsWith("quotes.sailprobate") && hostname.endsWith(getSailProbateDomainNameFromEnv(p)) ) {
        return `https://quotes.${getSailProbateDomainNameFromEnv(p)}`;
    }
    else if (hostname.startsWith("quotes.saillegal") && hostname.endsWith(getSailLegalDomainNameFromEnv(p)) ) {
        return `https://quotes.${getSailLegalDomainNameFromEnv(p)}`;
    }
    else if (hostname.endsWith(getSailProbateDomainNameFromEnv(p)) ) {
        return `https://app.${getSailProbateDomainNameFromEnv(p)}`;
    }
    else {
        return `https://listings.${getDomainNameFromEnv(p)}`;
    }
}

export const getAuthLinkFromHostnameAndEnv = (p: T, hostname: string): string => {
    if ( hostname.endsWith(getDomainNameFromEnv(p)) ) {
        return `https://auth.${getDomainNameFromEnv(p)}`;
    }
    else if (hostname.endsWith(getSailLegalDomainNameFromEnv(p)) ) {
        return `https://auth.${getSailLegalDomainNameFromEnv(p)}`;
    }
    else if (hostname.endsWith(getSailProbateDomainNameFromEnv(p)) ) {
        return `https://auth.${getSailProbateDomainNameFromEnv(p)}`;
    }
    else {
        return `https://auth.${getDomainNameFromEnv(p)}`;
    }
}

export const getApiLinkFromHostnameAndEnv = (p: T, hostname: string): string => {
    if ( hostname.endsWith(getDomainNameFromEnv(p)) ) {
        return `https://api.${getDomainNameFromEnv(p)}`;
    }
    else if (hostname.endsWith(getSailLegalDomainNameFromEnv(p)) ) {
        return `https://api.${getSailLegalDomainNameFromEnv(p)}`;
    }
    else if (hostname.endsWith(getSailProbateDomainNameFromEnv(p)) ) {
        return `https://api.${getSailProbateDomainNameFromEnv(p)}`;
    }
    else {
        return `https://api.${getDomainNameFromEnv(p)}`;
    }
}

export const getWebsocketsEndpointFromHostnameAndEnv = (p: T, hostname: string): string => {
    if ( hostname.endsWith(getDomainNameFromEnv(p)) ) {
        return `wss://api.${getDomainNameFromEnv(p)}:7071`;
    }
    else if (hostname.endsWith(getSailLegalDomainNameFromEnv(p)) ) {
        return `wss://api.${getSailLegalDomainNameFromEnv(p)}:7071`;
    }
    else if (hostname.endsWith(getSailProbateDomainNameFromEnv(p)) ) {
        return `wss://api.${getSailProbateDomainNameFromEnv(p)}:7071`;
    }
    else {
        return `wss://api.${getDomainNameFromEnv(p)}:7071`;
    }
}

export const getHomeLinkFromHostnameAndEnv = (p: T, hostname: string): string => {
    if ( hostname.endsWith(getDomainNameFromEnv(p)) ) {
        return `https://www.${getDomainNameFromEnv(p)}`;
    }
    else if (hostname.endsWith(getSailLegalDomainNameFromEnv(p)) ) {
        return `https://www.${getSailLegalDomainNameFromEnv(p)}`;
    }
    else if (hostname.endsWith(getSailProbateDomainNameFromEnv(p)) ) {
        return `https://www.${getSailProbateDomainNameFromEnv(p)}`;
    }
    else {
        return `https://www.${getDomainNameFromEnv(p)}`;
    }
}

export const getAtEmailPartFromHostnameAndEnv = (p: T, hostname: string): string => {
    if ( hostname.endsWith(getDomainNameFromEnv(p)) ) {
        return `@${getDomainNameFromEnv(p)}`;
    }
    else if (hostname.endsWith(getSailLegalDomainNameFromEnv(p)) ) {
        return `@${getSailLegalDomainNameFromEnv(p)}`;
    }
    else if (hostname.endsWith(getSailProbateDomainNameFromEnv(p)) ) {
        return `@${getSailProbateDomainNameFromEnv(p)}`;
    }
    else {
        return `@${getDomainNameFromEnv(p)}`;
    }
}

export type envCurrentBrand = "sailhomes" | "saillegal" | "sailprobate";

export const getCurrentBrandHostnameAndEnv = (p: T, hostname: string): envCurrentBrand => {
    if ( hostname.endsWith(getDomainNameFromEnv(p)) ) {
        return "sailhomes";
    }
    else if (hostname.endsWith(getSailLegalDomainNameFromEnv(p)) ) {
        return "saillegal";
    }
    else if (hostname.endsWith(getSailProbateDomainNameFromEnv(p)) ) {
        return "sailprobate";
    }
    else {
        return "sailhomes";
    }
}

export type TBrandPrefix = "sl" | "sh" | "sp";

export const getCurrentBrandPrefix = (p: T, hostname: string): TBrandPrefix => {
    let envName = getCurrentBrandHostnameAndEnv(p, hostname);
    if (envName === "sailhomes") {
        return "sh"
    }
    else if (envName === "saillegal") {
        return "sl"
    }
    return "sp";
}

export const getCompanyNameForCurrentBrand = (p: T, hostname: string) => {
    let envName = getCurrentBrandHostnameAndEnv(p, hostname);
    if (envName === "sailhomes") {
        return "sail_homes";
    }
    else if (envName === "saillegal") {
        return "sail_legal";
    }
    return "sail_probate";
}

type envCurrentBrandTitle = "Sail Homes" | "Sail Legal" | "Sail Probate";

export const getCurrentBrandTitleHostnameAndEnv = (p: T, hostname: string): envCurrentBrandTitle => {
    if ( hostname.endsWith(getDomainNameFromEnv(p)) ) {
        return "Sail Homes";
    }
    else if (hostname.endsWith(getSailLegalDomainNameFromEnv(p)) ) {
        return "Sail Legal";
    }
    else if (hostname.endsWith(getSailProbateDomainNameFromEnv(p)) ) {
        return "Sail Probate";
    }
    else {
        return "Sail Homes";
    }
}

export const getFavIconUrlHostnameAndEnv = (p: T, hostname: string): string => {
    if ( hostname.endsWith(getDomainNameFromEnv(p)) ) {
        return `https://listings.${getDomainNameFromEnv(p)}/static/favicons/Sail_Homes_Favicon_256.png`;
    }
    else if (hostname.endsWith(getSailLegalDomainNameFromEnv(p)) ) {
        return `https://portal.${getSailLegalDomainNameFromEnv(p)}/static/favicons/Sail_Legal_Favicon_256.png`;
    }
    else if (hostname.startsWith("quotes.sailprobate") && hostname.endsWith(getSailProbateDomainNameFromEnv(p)) ) {
        return `https://quotes.${getSailProbateDomainNameFromEnv(p)}/static/favicons/Sail_Probate_Favicon_256.png`;
    }
    else if (hostname.startsWith("quotes.saillegal") && hostname.endsWith(getSailLegalDomainNameFromEnv(p)) ) {
        return `https://quotes.${getSailLegalDomainNameFromEnv(p)}/static/favicons/Sail_Legal_Favicon_256.png`;
    }
    else if (hostname.endsWith(getSailProbateDomainNameFromEnv(p)) ) {
        return `https://app.${getSailProbateDomainNameFromEnv(p)}/static/favicons/Sail_Probate_Favicon_256.png`;
    }
    else {
        return `https://listings.${getDomainNameFromEnv(p)}/static/favicons/Sail_Homes_Favicon_256.png`;
    }
}