import React from "react";
import { CRMCardOutside } from "../../components/CRMCardOutside/CRMCardOutside";
import { CRMColumns } from "../../components/CRMColumns/CRMColumns";
import { CRMPadding } from "../../components/Simple/CRMPadding/CRMPadding";
import { CRMPageBackground } from "../../components/Simple/CRMPageBackground/CRMPageBackground";
import { CRMSpacer } from "../../components/CRMSpacer/CRMSpacer";
import { CRMTitleSection } from "../../components/CRMTitleSection/CRMTitleSection";
import { CRMParagraph } from "../../components/Simple/CRMParagraph/CRMParagraph";
import { CRMUniversalBulletPoints } from "../../components/CRMUniversalBulletPoints/CRMUniversalBulletPoints";
import { CRMParagraphInline } from "../../components/Simple/CRMParagraphInline/CRMParagraphInline";
import { CRMTitleSubSection } from "../../components/CRMTitleSubSection/CRMTitleSubSection";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import TagManager from "react-gtm-module";

class ProbatePanelGDPRPage extends React.Component<React.PropsWithChildren<TContainerStateProps>> {
    public componentDidMount() {
        TagManager.initialize({
            gtmId: "GTM-5J4P8R5",
        });
    }
    
    public render(): JSX.Element {
        return (
            <div className="probate-panel-enquiry-page">
                <CRMPageBackground>
                    <CRMPadding size="large">
                        <CRMColumns
                            padding="large"
                            columns={[
                                {
                                    flex: 0.5,
                                    content: (<div></div>),
                                },
                                {
                                    flex: 1.1887,
                                    content: (
                                        <CRMCardOutside borderRounding="right" shadow={true}>
                                            <CRMPadding size="medium">
                                                <img style={{display: "block", height: "100px"}} src="/static/img/SAIL_PROBATE_LOGO_2021.png" />
                                                <CRMSpacer size="medium" />
                                                <CRMTitleSection>GDPR Policy 2021-06-25</CRMTitleSection>
                                                <CRMSpacer size="medium" />
                                                <CRMTitleSubSection>The type of personal information we collect:</CRMTitleSubSection>
                                                <CRMParagraph>
                                                    We currently collect and process the following information:
                                                </CRMParagraph>
                                                <CRMUniversalBulletPoints
                                                    points={[
                                                        <CRMParagraphInline key="1">Personal identifiers, contacts and characteristics (for example, name and contact details)</CRMParagraphInline>,
                                                        <CRMParagraphInline key="2">Details about the deceased and their estate</CRMParagraphInline>,
                                                    ]}
                                                />
                                                <CRMTitleSubSection>How we get the personal information and why we have it:</CRMTitleSubSection>
                                                <CRMParagraph>
                                                    Most of the personal information we process is provided to us directly by you for one of the following reasons:
                                                </CRMParagraph>
                                                <CRMUniversalBulletPoints
                                                    points={[
                                                        <CRMParagraphInline key="1">You entered it into our form</CRMParagraphInline>,
                                                    ]}
                                                />
                                                <CRMParagraph>
                                                    We use the information that you have given us in order to give you a quote, determine the best service for you and contact you about your quote.
                                                </CRMParagraph>
                                                <CRMParagraph>
                                                    We may share this information with relevant solictors to provide you the service.
                                                </CRMParagraph>
                                                <CRMParagraph>
                                                    Under the General Data Protection Regulation (GDPR), the lawful bases we rely on for processing this information are:
                                                </CRMParagraph>
                                                <CRMUniversalBulletPoints
                                                    points={[
                                                        <CRMParagraphInline key="1">Your consent. You are able to remove your consent at any time. You can do this by contacting hello@sailprobate.co.uk</CRMParagraphInline>,
                                                        <CRMParagraphInline key="2">We have a legitimate interest. (We require this data to provide our service to you)</CRMParagraphInline>,
                                                    ]}
                                                />
                                                <CRMTitleSubSection>How we store your personal information:</CRMTitleSubSection>
                                                <CRMParagraph>
                                                    Your information is securely stored and encrypted on our servers.
                                                </CRMParagraph>
                                                <CRMTitleSubSection>Your data protection rights:</CRMTitleSubSection>
                                                <CRMParagraph>
                                                    Under data protection law, you have rights including
                                                </CRMParagraph>
                                                <CRMUniversalBulletPoints
                                                    points={[
                                                        <CRMParagraphInline key="1">Your right of access - You have the right to ask us for copies of your personal information.</CRMParagraphInline>,
                                                        <CRMParagraphInline key="2">Your right to rectification - You have the right to ask us to rectify personal information you think is inaccurate. You also have the right to ask us to complete information you think is incomplete.</CRMParagraphInline>,
                                                        <CRMParagraphInline key="2">Your right to erasure - You have the right to ask us to erase your personal information in certain circumstances.</CRMParagraphInline>,
                                                        <CRMParagraphInline key="2">Your right to restriction of processing - You have the right to ask us to restrict the processing of your personal information in certain circumstances.</CRMParagraphInline>,
                                                        <CRMParagraphInline key="2">Your right to object to processing - You have the the right to object to the processing of your personal information in certain circumstances.</CRMParagraphInline>,
                                                        <CRMParagraphInline key="2">Your right to data portability - You have the right to ask that we transfer the personal information you gave us to another organisation, or to you, in certain circumstances.</CRMParagraphInline>,
                                                    ]}
                                                />
                                                <CRMParagraph>
                                                You are not required to pay any charge for exercising your rights. If you make a request, we have one month to respond to you. Please contact us at hello@sailprobate.co.uk if you wish to make a request.
                                                </CRMParagraph>
                                            </CRMPadding>
                                        </CRMCardOutside>
                                    ),
                                },
                                {
                                    flex: 0.5,
                                    content: (<div></div>),
                                },
                            ]}
                        />
                    </CRMPadding>
                </CRMPageBackground>
            </div>
        );
    }
}

export default ProbatePanelGDPRPage;
