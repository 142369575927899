import * as t from "io-ts";
import { option } from "fp-ts";
import { all } from "../../shared/src/validation/compose/all";
import { inOrder } from "../../shared/src/validation/compose/inOrder";
import { required } from "../../shared/src/validation/basic/required";
import { boolean } from "../../shared/src/validation/basic/boolean";
import { arrayUniqueBy } from "../../shared/src/validation/create/arrayUniqueBy";
import { array } from "../../shared/src/validation/create/array";
import { object } from "../../shared/src/validation/create/object";
import { uuid } from "../../shared/src/validation/basic/uuid";
import { objectCallback } from "../../shared/src/validation/create/objectCallback";
import { TValidationError } from "../../shared/src/validation/Error";
import { validationErrorCodeConstants } from "../../shared/src/validation/ErrorCode";

export const codec = t.type({
    send_video_tour: t.boolean,
    document_ids: t.array(t.string),
});

export type T = t.TypeOf<typeof codec>;

export const validator = all(
    object({
        send_video_tour: inOrder(
            required,
            boolean,
        ),
        document_ids: inOrder(
            required,
            arrayUniqueBy((v) => typeof v === "string" ? v : ""),
            array(uuid),
        ),
    }),
    // Check either send_video_tour is true or document_ids contains a document
    objectCallback((val: Record<string, unknown>): option.Option<TValidationError> =>
        typeof val.send_video_tour === "boolean"
        && Array.isArray(val.document_ids)
        && val.send_video_tour === false
        && val.document_ids.length === 0
            ? option.some([
                [validationErrorCodeConstants.NOTHING_TO_SEND_VALIDATION, "send_video_tour"],
                [validationErrorCodeConstants.NOTHING_TO_SEND_VALIDATION, "document_ids"],
            ])
            : option.none
    ),
);

export const newDefault = (): T => ({
    send_video_tour: false,
    document_ids: [],
});
