import React from "react";
import IconSearch from "../../../../../src/assets/frontIcons/search";
import IconDownArrow from "../../../../../src/assets/frontIcons/down_arrow";
import IconLeftArrow from "../../../../../src/assets/frontIcons/left_arrow";
import IconRightArrow from "../../../../../src/assets/frontIcons/right_arrow";
import IconCamera from "../../../../../src/assets/frontIcons/camera";
import IconTick from "../../../../../src/assets/frontIcons/tick";
import IconShop from "../../../../../src/assets/frontIcons/shops";
import IconPark from "../../../../../src/assets/frontIcons/parks";
import IconHealth from "../../../../../src/assets/frontIcons/health";
import IconEducation from "../../../../../src/assets/frontIcons/education";
import IconDownload from "../../../../../src/assets/frontIcons/download";
import IconCross from "../../../../../src/assets/frontIcons/cross";
import IconEdit from "../../../../../src/assets/frontIcons/edit";
import IconSquareSelected from "../../../../../src/assets/frontIcons/checkbox_square_selected";
import IconPlus from "../../../../../src/assets/frontIcons/plus";
import IconPhone from "../../../../../src/assets/frontIcons/phone";
import IconClock from "../../../../../src/assets/frontIcons/clock";
import IconSnooze from "../../../../../src/assets/frontIcons/snooze";
import IconBin from "../../../../../src/assets/frontIcons/bin";
import IconWarning from "../../../../../src/assets/frontIcons/warning";
import IconAddUser from "../../../../../src/assets/frontIcons/add_user";
import IconBuildings from "../../../../../src/assets/frontIcons/buildings";

const iconTypeArray = [
    "search",
    "downArrow",
    "leftArrow",
    "rightArrow",
    "camera",
    "tick",
    "shop",
    "leisure",
    "healthcare",
    "education",
    "download",
    "cross",
    "edit",
    "square_selected",
    "plus",
    "phone",
    "clock",
    "snooze",
    "bin",
    "warning",
    "addUser",
    "buildings",
] as const;

type TIconType = typeof iconTypeArray[number];

interface IIcon {
    iconName: TIconType;
    height: number;
    width: number;
    type: "mouse" | "sloth" | "dog" | "possum";
    disabled?: boolean;
}

export class Icon extends React.Component<React.PropsWithChildren<IIcon>> {

    returnIconSVGIfTypeMatches = (
        iconType: TIconType,
        IconElement: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
    ): JSX.Element | undefined => {
        if (this.props.iconName === iconType) {
            return (
                <IconElement
                    className={`
                        iconComponent__icon
                        iconComponent__icon--${this.props.iconName}
                        iconComponent__icon--${this.props.type}
                        iconComponent__icon--${(this.props.disabled ? 'disabled' : 'enabled')}
                    `}
                />
            );
        } else {
            return undefined;
        }
    };

    public render (): JSX.Element {
        return (
            <div
                className="iconComponent"
                style={{
                    height: this.props.height + "rem",
                    width: this.props.width + "rem",
                }}
            >
                {this.returnIconSVGIfTypeMatches("search", IconSearch)}
                {this.returnIconSVGIfTypeMatches("downArrow", IconDownArrow)}
                {this.returnIconSVGIfTypeMatches("leftArrow", IconLeftArrow)}
                {this.returnIconSVGIfTypeMatches("rightArrow", IconRightArrow)}
                {this.returnIconSVGIfTypeMatches("camera", IconCamera)}
                {this.returnIconSVGIfTypeMatches("tick", IconTick)}
                {this.returnIconSVGIfTypeMatches("shop", IconShop)}
                {this.returnIconSVGIfTypeMatches("healthcare", IconHealth)}
                {this.returnIconSVGIfTypeMatches("leisure", IconPark)}
                {this.returnIconSVGIfTypeMatches("education", IconEducation)}
                {this.returnIconSVGIfTypeMatches("download", IconDownload)}
                {this.returnIconSVGIfTypeMatches("cross", IconCross)}
                {this.returnIconSVGIfTypeMatches("edit", IconEdit)}
                {this.returnIconSVGIfTypeMatches("square_selected", IconSquareSelected)}
                {this.returnIconSVGIfTypeMatches("plus", IconPlus)}
                {this.returnIconSVGIfTypeMatches("phone", IconPhone)}
                {this.returnIconSVGIfTypeMatches("clock", IconClock)}
                {this.returnIconSVGIfTypeMatches("snooze", IconSnooze)}
                {this.returnIconSVGIfTypeMatches("bin", IconBin)}
                {this.returnIconSVGIfTypeMatches("warning", IconWarning )}
                {this.returnIconSVGIfTypeMatches("addUser", IconAddUser )}
                {this.returnIconSVGIfTypeMatches("buildings", IconBuildings )}
            </div>
        );
    }
}

export default Icon;
