import React, { ReactFragment } from "react";
import { FrontIcon, TIcon } from "../FrontIcon/FrontIcon";
interface IFrontButtonPrimary {
    label: string | ReactFragment;
    fullWidth?: boolean;
    disabled?: boolean;
    iconName?: TIcon;
    onClick: () => void;
}

class FrontButtonPrimary extends React.Component<React.PropsWithChildren<IFrontButtonPrimary>> {
    public render (): JSX.Element {
        return (
            <button
                className={
                    `front-button-primary` +
                    (this.props.fullWidth ? " front-button-primary--full-width" : "") +
                    (this.props.disabled ? ` front-button-primary--disabled` : "")
                }
                onClick={() => this.props.disabled ? undefined : this.props.onClick()}
                onKeyDown={(e) => (e.keyCode === 13 && !this.props.disabled) && this.props.onClick()}
            >
                {/* ICON */}
                {this.props.iconName &&
                    <FrontIcon
                        iconName={this.props.iconName}
                        colour="neutral-18"
                    />
                }

                {/* LABEL */}
                {this.props.label}
            </button>
        );
    }
}

export default FrontButtonPrimary;
