import React from "react";

export default (props: React.SVGAttributes<SVGElement>) =>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clip-path="url(#clip0_11721_8218)">
            <path d="M12.3762 14.5L11.5895 15.7913L10.9 15L9.9 19.4L3 18L3.4 16L8.2 17L9.8 8.9L8 9.6V13H6V8.3L9.95 6.6C10.5333 6.35 10.9625 6.1875 11.2375 6.1125C11.5125 6.0375 11.7667 6 12 6C12.35 6 12.675 6.09167 12.975 6.275C13.275 6.45833 13.5167 6.7 13.7 7L14.7 8.6C15.1333 9.3 15.7208 9.875 16.4625 10.325C17.2042 10.775 18.05 11 19 11V12.1739C17.8369 12.0086 16.8226 12.0349 15.9125 12.3125C14.9542 11.8542 14.15 11.25 13.5 10.5L12.9 13.5L12.975 13.8337L12.6554 14.1663L12.3762 14.5ZM13.5 5.5C12.95 5.5 12.4792 5.30417 12.0875 4.9125C11.6958 4.52083 11.5 4.05 11.5 3.5C11.5 2.95 11.6958 2.47917 12.0875 2.0875C12.4792 1.69583 12.95 1.5 13.5 1.5C14.05 1.5 14.5208 1.69583 14.9125 2.0875C15.3042 2.47917 15.5 2.95 15.5 3.5C15.5 4.05 15.3042 4.52083 14.9125 4.9125C14.5208 5.30417 14.05 5.5 13.5 5.5Z" fill="black" />
            <path d="M19.2872 21.0455L20.0455 20.2872L18.0413 18.283V15.7913H16.958V18.7163L19.2872 21.0455ZM17.4997 23.9163C16.7504 23.9163 16.0462 23.7742 15.3872 23.4898C14.7281 23.2054 14.1549 22.8195 13.6674 22.332C13.1799 21.8445 12.7939 21.2712 12.5096 20.6122C12.2252 19.9531 12.083 19.249 12.083 18.4997C12.083 17.7504 12.2252 17.0462 12.5096 16.3872C12.7939 15.7281 13.1799 15.1549 13.6674 14.6674C14.1549 14.1799 14.7281 13.7939 15.3872 13.5096C16.0462 13.2252 16.7504 13.083 17.4997 13.083C18.249 13.083 18.9531 13.2252 19.6122 13.5096C20.2712 13.7939 20.8445 14.1799 21.332 14.6674C21.8195 15.1549 22.2054 15.7281 22.4898 16.3872C22.7742 17.0462 22.9163 17.7504 22.9163 18.4997C22.9163 19.249 22.7742 19.9531 22.4898 20.6122C22.2054 21.2712 21.8195 21.8445 21.332 22.332C20.8445 22.8195 20.2712 23.2054 19.6122 23.4898C18.9531 23.7742 18.249 23.9163 17.4997 23.9163ZM17.4997 22.833C18.7004 22.833 19.7228 22.411 20.5669 21.5669C21.411 20.7228 21.833 19.7004 21.833 18.4997C21.833 17.299 21.411 16.2766 20.5669 15.4325C19.7228 14.5884 18.7004 14.1663 17.4997 14.1663C16.299 14.1663 15.2766 14.5884 14.4325 15.4325C13.5884 16.2766 13.1663 17.299 13.1663 18.4997C13.1663 19.7004 13.5884 20.7228 14.4325 21.5669C15.2766 22.411 16.299 22.833 17.4997 22.833Z" fill="black" />
        </g>
        <defs>
            <clipPath id="clip0_11721_8218">
                <rect width="24" height="24" fill="white" transform="matrix(-1 0 0 -1 24 24)" />
            </clipPath>
        </defs>
    </svg>
    ;
