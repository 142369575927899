import * as t from "io-ts";

export const codec = t.type({
    id: t.string,
    primary_number: t.boolean,
    phone_number: t.union([
        t.string,
        t.null,
    ]),
});

export type T = t.TypeOf<typeof codec>;
