import React from "react";

export default (props: React.SVGAttributes<SVGElement>) => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g clipPath="url(#clip0_7257_35166)">
<g clipPath="url(#clip1_7257_35166)">
<path d="M21 6.5V14H19V7.5L14 4L9 7.5V9H7V6.5L14 1.5L21 6.5ZM15.5 7H14.5V8H15.5V7ZM13.5 7H12.5V8H13.5V7ZM15.5 9H14.5V10H15.5V9ZM13.5 9H12.5V10H13.5V9ZM19 16H17C17 14.8 16.25 13.72 15.13 13.3L8.97 11H1V22H7V20.56L14 22.5L22 20V19C22 17.34 20.66 16 19 16ZM3 20V13H5V20H3ZM13.97 20.41L7 18.48V13H8.61L14.43 15.17C14.77 15.3 15 15.63 15 16C15 16 13.01 15.95 12.7 15.85L10.32 15.06L9.69 16.96L12.07 17.75C12.58 17.92 13.11 18.01 13.65 18.01H19C19.39 18.01 19.74 18.24 19.9 18.57L13.97 20.41Z" fill="black"/>
</g>
</g>
<defs>
<clipPath id="clip0_7257_35166">
<rect width="24" height="24" fill="white"/>
</clipPath>
<clipPath id="clip1_7257_35166">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>
;
