import { TTypeOfCodec, TTypeOfNewDefault } from "../../../shared/src/codecs/codec";
import { required, TRequiredCodec } from "../../../shared/src/codecs/types/required";
import { TAnyUuidCodec, uuid } from "../../../shared/src/codecs/types/uuid";
import { form, TFormCodec } from "../../../shared/src/codecs/types/form";
import { EmptyObject, TEmptyObjectCodec } from "../EmptyObject";

export const ListingSellerUserDeleteForm: TFormCodec<
    TRequiredCodec<{
        party_id: TAnyUuidCodec,
        id: TAnyUuidCodec
    }>,
    TEmptyObjectCodec
> = form(
    required({
        party_id: uuid(),
        id: uuid()
    }),
    EmptyObject
);

export type TListingSellerUserDeleteFormCodec = typeof ListingSellerUserDeleteForm;
export type TListingSellerUserDeleteForm = TTypeOfCodec<TListingSellerUserDeleteFormCodec>;
export type TListingSellerUserDeleteFormNewDefault = TTypeOfNewDefault<TListingSellerUserDeleteFormCodec>;