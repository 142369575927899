import { TAnyCodec, TCodec, TTypeOfCodec, TTypeOfNewDefault, formStatus, TFormStatus } from "../codec";
import { either, array as fptsArray } from "fp-ts/lib";
import { array } from "./array";
import { TError, TErrorTuple } from "../errors";
import { required } from "./required";
import { unknown } from "./unknown";
import { errorCode } from "./errorCode";
import { tuple } from "./tuple";
import { string } from "./string";
import { pipe } from "fp-ts/lib/function";
import clone from "just-clone";

export type TAnyFormEditableCodec = TCodec<
    "FormEditableCodec",
    { codec: TAnyCodec },
    TFormEditable<unknown, unknown>,
    TFormEditable<unknown, unknown>
>;

export type TFormEditable<P, E> = {
    status: TTypeOfCodec<typeof formStatus>;
    original: P;
    edited: E;
    validationErrors: TError;
};

const validationErrorsCodec = array(tuple([errorCode(), string()]));

export const formCodec = required({
    status: formStatus,
    original: unknown(),
    edited: unknown(),
    validationErrors: validationErrorsCodec,
});

export const formEditableToRequiredCodec = <C extends TAnyFormEditableCodec>(codec: C) => required({
    status: formStatus,
    original: codec.payload.codec,
    edited: codec.payload.codec,
    validationErrors: validationErrorsCodec,
});

export type TFormEditableCodec<P extends TAnyCodec> = TCodec<
    "FormEditableCodec",
    { codec: P },
    TFormEditable<TTypeOfNewDefault<P>, TTypeOfCodec<P>>,
    TFormEditable<TTypeOfNewDefault<P>, TTypeOfNewDefault<P>>
>;

export const formEditable = <
    P extends TAnyCodec,
    D extends TFormEditable<TTypeOfNewDefault<P>, TTypeOfCodec<P>>,
    N extends TFormEditable<TTypeOfNewDefault<P>, TTypeOfNewDefault<P>>
>(codec: P): TCodec<"FormEditableCodec", { codec: P }, D, N> => ({
        type: "FormEditableCodec",
        payload: {codec},
        decode: (input: unknown): either.Either<TError, D> =>
            pipe(
                formCodec.decode(input),
                either.fold(
                    (e) => pipe(
                        codec.decode(input),
                        either.fold(
                            (er) => either.left([...er, ...e]),
                            (d) => {
                                const f = formCodec.newDefault();
                                // We deep clone these so that they aren't references, such that editing one does not affect the other
                                f.original = clone(d as object);
                                f.edited = clone(d as object);
                                return either.right(f as D);
                            },
                        )
                    ),
                    (d) => pipe(
                        [
                            pipe(
                                codec.decodeNewDefault(d.original),
                                either.mapLeft((e) => e.map(([err, p]) => [err, `original.${p}`] as TErrorTuple))
                            ),
                            pipe(
                                codec.decode(d.edited),
                                either.mapLeft((e) => e.map(([err, c]) => [err, `edited.${c}`] as TErrorTuple))
                            ),
                        ],
                        fptsArray.separate,
                        (s) =>
                            s.left.length
                            ? either.left(fptsArray.flatten(s.left))
                            : either.right({...d, ...{original: s.right[0]}, ...{edited: s.right[1]}} as D)
                    )
                )
            ),
        decodeNewDefault: (input: unknown): either.Either<TError, N> => {
            const decoded = formCodec.decodeNewDefault(input);
            return pipe(
                decoded,
                either.fold(
                    (e) => pipe(
                        codec.decodeNewDefault(input),
                        either.fold(
                            () => either.left(e),
                            (d) => {
                                const f = formCodec.newDefault();
                                // We deep clone these so that they aren't references, such that editing one does not affect the other
                                f.original = clone(d as object);
                                f.edited = clone(d as object);
                                return either.right(f as N);
                            },
                        )
                    ),
                    (d) => pipe(
                        [
                            pipe(
                                codec.decodeNewDefault(d.original),
                                either.mapLeft((e) => e.map(([err, p]) => [err, p ? `original.${p}` : "original"] as TErrorTuple))
                            ),
                            pipe(
                                codec.decodeNewDefault(d.edited),
                                either.mapLeft((e) => e.map(([err, p]) => [err, p ? `edited.${p}` : "edited"] as TErrorTuple))
                            ),
                        ],
                        fptsArray.separate,
                        (s) =>
                            s.left.length
                            ? either.left(fptsArray.flatten(s.left))
                            : either.right({...d, ...{original: s.right[0]}, ...{edited: s.right[1]}} as N)
                    )
                )
            );
        },
        newDefault: (): N =>
            ({...formCodec.newDefault(), ...{original: codec.newDefault()}, ...{edited: codec.newDefault()}}) as N,
    });


export type TUnpackOriginal<T> = T extends TFormEditable<infer P, any> ? P : never; // eslint-disable-line

export type TUnpackEditable<T> = T extends TFormEditable<any, infer E> ? E : never; // eslint-disable-line

export const editFormEditableValue = <T extends TFormEditable<TUnpackOriginal<T>, TUnpackEditable<T>>, K extends keyof TUnpackEditable<T>>(form: T, key: K, value: T["edited"][K], status?: TFormStatus): T =>
    ({
        ...form,
        status: status || "requiresSubmission",
        edited: {
            ...form.edited,
            [key]: value,
        },
    });

export const doFormValuesMatch = <T extends TFormEditable<TUnpackOriginal<T>, TUnpackEditable<T>>, K extends keyof TUnpackOriginal<T>>(leftForm: T, key: K) =>
    (rightForm: T): boolean =>
        leftForm.original[key] === rightForm.original[key]
    ;
