import * as t from "io-ts";
import * as util from "../../shared/src/util";

export const codec = t.union([
    t.literal("unsubmitted"),
    t.literal("pending_approval"),
    t.literal("approved_unsent"),
    t.literal("approved_sent"),
    t.literal("resolved"),
    t.literal("not_relevant"),
]);

export const values = util.typeValuesFromCodec(codec.types as Array<t.LiteralC<T>>);

export type T = t.TypeOf<typeof codec>;

export const toDisplayString = (p: T): string => {
    switch (p) {
        case "unsubmitted":
            return "Unsubmitted";
        case "pending_approval":
            return "Pending approval";
        case "approved_unsent":
            return "Approved (unsent)";
        case "approved_sent":
            return "Approved (sent)";
        case "resolved":
            return "Resolved";
        case "not_relevant":
            return "Not relevant";
        default:
            return util.requireExhaustive(p);
    }
};
