import { TTypeOfCodec } from "../../shared/src/codecs/codec";
import { currencyInteger } from "../../shared/src/codecs/types/currencyInteger";
import { date } from "../../shared/src/codecs/types/date";
import { required } from "../../shared/src/codecs/types/required";
import { string } from "../../shared/src/codecs/types/string";
import { uuid } from "../../shared/src/codecs/types/uuid";

export const MarketingCampaign1 = required({
    id: uuid(),
    name: string(),
    short_code: string(),
});
export type TMarketingCampaign1Codec = typeof MarketingCampaign1;
export type TMarketingCampaign1 = TTypeOfCodec<TMarketingCampaign1Codec>;

export const MarketingCampaignSpend1 = required({
    id: uuid(),
    cost_date: date(),
    cost_pence: currencyInteger(),
});
export type TMarketingCampaignSpend1Codec = typeof MarketingCampaignSpend1;
export type TMarketingCampaignSpend1 = TTypeOfCodec<TMarketingCampaignSpend1Codec>;