import { form } from "../../../shared/src/codecs/types/form";
import { required } from "../../../shared/src/codecs/types/required";
import { TTypeOfCodec, TTypeOfNewDefault } from "../../../shared/src/codecs/codec";
import { TransferSetupRecord } from "../CaseTransferSetup";
import { dateTime } from "../../../shared/src/codecs/types/dateTime";

export const TransferSetupRecordForm = form(
    TransferSetupRecord,
    required({
        created_at: dateTime(),
    }),
);
export type TTransferSetupRecordFormCodec = typeof TransferSetupRecordForm;
export type TTransferSetupRecordForm = TTypeOfCodec<TTransferSetupRecordFormCodec>;
export type TTransferSetupRecordFormNewDefault = TTypeOfNewDefault<typeof TransferSetupRecordForm>;