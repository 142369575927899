import { inherit, TAnyInheritedCodec, TInheritedCodec } from "../codec";
import { string, TAnyStringCodec } from "./string";
import { option } from "fp-ts/lib";
import { errorConstants } from "../errors";

export type TAnyNonEmptyStringCodec = TAnyInheritedCodec<"NonEmptyStringCodec", { codec: TAnyStringCodec }>;

export const nonEmptyString = (): TInheritedCodec<"NonEmptyStringCodec", {codec: TAnyStringCodec}> => inherit(
    "NonEmptyStringCodec",
    {codec: string()},
    (input) => input === ""
        ? option.some([[errorConstants.NON_EMPTY_STRING_VALIDATION, ""]])
        : option.none,
    () => option.none,
);
