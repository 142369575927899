import * as t from "io-ts";
import * as util from "../../shared/src/util";
import { isEnum } from "../../shared/src/validation/create/isEnum";

export const codec = t.union([
    t.literal("keysafe"),
    t.literal("keynest"),
    t.literal("occupier"),
    t.literal("designated_person"),
    t.literal("unknown"),
]);

type T = t.TypeOf<typeof codec>;

const values = util.typeValuesFromCodec(codec.types as Array<t.LiteralC<T>>);

export const validator = isEnum(values);
