import { TCodec } from "../codec";
import { either } from "fp-ts/lib";
import { TError, errorConstants } from "../errors";

export type TAnyLongStringCodec = TCodec<
    "LongStringCodec",
    undefined,
    string ,
    string
>;

const decode = (input: unknown): either.Either<TError, string> =>
    typeof input === "string"
        ? either.right(input)
        : either.left([[errorConstants.STRING_VALIDATION, ""]]);

export const longString = (): TAnyLongStringCodec => ({
    type: "LongStringCodec",
    payload: undefined,
    decode,
    decodeNewDefault: decode,
    newDefault: () => "",
});
