import React from "react";
import { Uppercase } from "../../../Uppercase/Uppercase";
import { WeightSemiBold } from "../../../WeightSemiBold/WeightSemiBold";
import { sailHomesBrandConstants } from "../../../../../../domain/constants";
import { FrontFooterBase } from "../FrontFooterBase/FrontFooterBase";

type TFrontFooter = {
    hiddenLink?: string;
};

export class FrontFooter extends React.Component<React.PropsWithChildren<TFrontFooter>> {
    public render (): JSX.Element {
        return (
            <div className="front-footer">
                {/* MAIN */}
                <div className="front-footer__main-container">
                    <div className="front-footer__main">
                        {/* LOGO */}
                        <div className="front-footer__column front-footer__column--main-logo">
                            <img src="/static/img/SailHomesLogoDarkSquare.svg" alt="Sail Homes Logo" />
                        </div>
                        {/* MAIN LINKS */}
                        <div className="front-footer__column">
                            <div className="front-footer__section-header">
                                <Uppercase>Sail Homes</Uppercase>
                            </div>
                            <a className="front-footer__link" href={sailHomesBrandConstants("web").homeURL}>
                                <WeightSemiBold>Home</WeightSemiBold>
                            </a>
                            <a className="front-footer__link" href={sailHomesBrandConstants("web").valuationURL}>
                                <WeightSemiBold>Valuations</WeightSemiBold>
                            </a>
                            <a className="front-footer__link" href={sailHomesBrandConstants("web").pricingURL}>
                                <WeightSemiBold>Pricing</WeightSemiBold>
                            </a>
                        </div>
                        {/* USEFUL LINKS */}
                        <div className="front-footer__column">
                            <div className="front-footer__section-header">
                                <Uppercase>Useful Links</Uppercase>
                            </div>
                            <a className="front-footer__link" href={sailHomesBrandConstants("web").forSale}>
                                <WeightSemiBold>Properties for sale</WeightSemiBold>
                            </a>
                            <a className="front-footer__link" href={sailHomesBrandConstants("web").contact.emailURL}>
                                <WeightSemiBold>Contact us</WeightSemiBold>
                            </a>
                            <a className="front-footer__link" href={sailHomesBrandConstants("web").faqURL}>
                                <WeightSemiBold>FAQs</WeightSemiBold>
                            </a>
                        </div>
                        {/* THE LEGAL BITS */}
                        <div className="front-footer__column">
                            <div className="front-footer__section-header">
                                <Uppercase>The Legal bits</Uppercase>
                            </div>
                            <a className="front-footer__link" href={sailHomesBrandConstants("web").privacyURL}>
                                <WeightSemiBold>Privacy policy</WeightSemiBold>
                            </a>
                            <a className="front-footer__link" href={sailHomesBrandConstants("web").tncsUrl}>
                                <WeightSemiBold>Terms & conditions</WeightSemiBold>
                            </a>
                            <a className="front-footer__link" href={sailHomesBrandConstants("web").complaintsUrl}>
                                <WeightSemiBold>Complaints procedure</WeightSemiBold>
                            </a>
                        </div>
                        {/* REGULATOR LOGOS */}
                        <div className="front-footer__column front-footer__column--other-logos">
                            {/* PRS */}
                            <div className="front-footer__single-logo-container">
                                <img className="front-footer__logo" src="/static/img/prs_logo.png" alt="PRS"/>
                            </div>
                            {/* TPO TSI - Required to be side by side, not stacked */}
                            <div className="front-footer__dual-logo-container">
                                <img className="front-footer__logo" src="/static/img/tpo-logo.jpeg" alt="TPO"/>
                                <img className="front-footer__logo" src="/static/img/tsi-logo.png" alt="TSI"/>
                            </div>
                        </div>
                    </div>
                    {/*
                        This is kind of a hack to match up to Jack's webflow design as much as possible
                        without complicated grids, and flexbox aranging
                     */}
                    <div className="front-footer__tablet-logos">
                        <div className="front-footer__tablet-logo-container front-footer__tablet-logo-container--main">
                            <img src="/static/img/SailHomesLogoDarkSquare.svg" alt="Sail Homes Logo" />
                        </div>
                        <div className="front-footer__tablet-logo-container">
                            <img className="front-footer__tablet-logo front-footer__tablet-logo--other" src="/static/img/prs_logo.png" alt="PRS"/>
                        </div>
                        <div className="front-footer__tablet-logo-container">
                            <img className="front-footer__tablet-logo front-footer__tablet-logo--other" src="/static/img/tpo-logo.jpeg" alt="TPO"/>
                        </div>
                        <div className="front-footer__tablet-logo-container">
                            <img className="front-footer__tablet-logo front-footer__tablet-logo--other" src="/static/img/tsi-logo.png" alt="TSI"/>
                        </div>
                    </div>
                </div>
                {/* BASE */}
                <FrontFooterBase
                    copyRightString={`Copyright Sail Homes ${new Date().getFullYear().toString()}.`}
                    hiddenLink={this.props.hiddenLink}
                    backgroundColor="#546285"
                />
            </div>
        );
    }
}
