import { TAnyCodec, TCodec, TTypeOfCodec, formStatus, TTypeOfNewDefault } from "../codec";
import { either } from "fp-ts/lib";
import { TError, TErrorTuple } from "../errors";
import { required } from "./required";
import { pipe } from "fp-ts/lib/function";
import { unknown } from "./unknown";
import { array } from "./array";
import { tuple } from "./tuple";
import { errorCode } from "./errorCode";
import { string } from "./string";
import { intersection } from "./intersection";
import { partial } from "./partial";

export type TAnyFormIOCodec = TCodec<
    "FormIOCodec",
    {input: TAnyCodec; output: TAnyCodec},
    TFormIO<unknown, unknown>,
    TFormIO<unknown, unknown>
>;

export type TFormIO<I, O> = {
    status: TTypeOfCodec<typeof formStatus>;
    input: I;
    output: O;
    validationErrors: TError;
};

const validationErrorsCodec = array(tuple([errorCode(), string()]));

export const formIOToRequiredCodec = <C extends TAnyFormIOCodec>(codec: C) => required({
    status: formStatus,
    input: codec.payload.input,
    output: codec.payload.output,
    validationErrors: validationErrorsCodec,
});

export type TFormIOCodec<
    I extends TAnyCodec,
    O extends TAnyCodec
> = TCodec<
    "FormIOCodec",
    { input: I; output: O },
    TFormIO<TTypeOfCodec<I>, TTypeOfCodec<O>>,
    TFormIO<TTypeOfNewDefault<I>, TTypeOfNewDefault<O>>
>;

export const formIO = <
    I extends TAnyCodec,
    O extends TAnyCodec,
    D extends TFormIO<TTypeOfCodec<I>, TTypeOfCodec<O>>,
    N extends TFormIO<TTypeOfNewDefault<I>, TTypeOfNewDefault<O>>
>(input: I, output: O): TCodec<"FormIOCodec", { input: I; output: O }, D, N> => {
    const formCodec = intersection([
        required({
            status: formStatus,
            input: unknown(),
            validationErrors: validationErrorsCodec,
        }),
        partial({
            output: unknown(),
        }),
    ]);

    return ({
        type: "FormIOCodec",
        payload: {input, output},
        decode: (i: unknown): either.Either<TError, D> => {
            const decoded = formCodec.decode(i);
            return pipe(
                decoded,
                either.fold(
                    (e) => // Try to decode the body as both the input and the output if so create a fileIO out of it else left
                        {
                            return pipe(
                                input.decode(i),
                                either.mapLeft(() => e),
                                either.chain((decodedInput) => pipe(
                                    output.decode(i),
                                    either.fold(
                                        () => {
                                            return either.left(e);
                                        },
                                        (decodedOutput) => either.right({ ...formCodec.newDefault(), ...{ input: decodedInput }, ...{ output: decodedOutput } } as D)
                                    )
                                ))
                            );
                        },
                    (d) => pipe(
                        input.decode(d.input),
                        either.mapLeft((e) => e.map(([err, c]) => [err, `input.${c}`] as TErrorTuple)),
                        either.chain((decodedInput) => pipe(
                            output.decode(d.output),
                            either.fold(
                                () => either.right({...d, ...{input: decodedInput}, ...{output: output.newDefault()}} as D),
                                (decodedOutput) => either.right({...d, ...{input: decodedInput}, ...{output: decodedOutput}} as D)
                            )

                        )),
                    ),
                )
            );
        },
        decodeNewDefault: (i: unknown): either.Either<TError, N> => {
            const decoded = formCodec.decodeNewDefault(i);
            return pipe(
                decoded,
                either.fold(
                    (e) => // Try to decode the body as both the input and the output if so create a fileIO out of it else left
                        pipe(
                            input.decodeNewDefault(i),
                            either.mapLeft(() => e),
                            either.chain((decodedInput) => pipe(
                                output.decodeNewDefault(i),
                                either.fold(
                                    () => either.left(e),
                                    (decodedOutput) => either.right({...formCodec.newDefault(), ...{input: decodedInput}, ...{output: decodedOutput}} as N)
                                )
                            ))
                    ),
                    (d) => pipe(
                        input.decodeNewDefault(d.input),
                        either.mapLeft((e) => e.map(([err, c]) => [err, `data.${c}`] as TErrorTuple)),
                        either.chain((decodedInput) => pipe(
                            output.decodeNewDefault(d.output),
                            either.fold(
                                () => either.right({...d, ...{input: decodedInput}, ...{output: output.newDefault()}} as N),
                                (decodedOutput) => either.right({...d, ...{input: decodedInput}, ...{output: decodedOutput}} as N)
                            )

                        )),
                    ),
                )
            );
        },
        newDefault: (): N =>
            ({...formCodec.newDefault(), ...{input: input.newDefault()}, ...{output: output.newDefault()}}) as N,
    });
};
