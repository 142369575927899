import { TAnyCodec, TCodec, TTypeOfCodec, TTypeOfNewDefault } from "../codec";
import { array, either, record } from "fp-ts/lib";
import { TError, TErrorTuple } from "../errors";
import { pipe } from "fp-ts/lib/function";
import { required } from "./required";

export type TAnyRequiredFlatOverloadedCodec = TCodec<
    "RequiredFlatOverloadedCodec",
    {[key: string]: TAnyCodec},
    unknown,
    unknown
>;

export type TRequiredFlatOverloadedCodec<P extends {[key: string]: TAnyCodec}> = TCodec<
    "RequiredFlatOverloadedCodec",
    P,
    {[K in keyof P]: TTypeOfCodec<P[K]>;},
    {[K in keyof P]: TTypeOfNewDefault<P[K]>;}
>;

const decode = <
    F extends "decode" | "decodeNewDefault",
    P extends {[key: string]: TAnyCodec},
    D extends F extends "decode" ? {[K in keyof P]: TTypeOfCodec<P[K]>;} : {[K in keyof P]: TTypeOfNewDefault<P[K]>;}
>(decodeFunction: F, payload: P) => (input): either.Either<TError, D> => {
    const requiredCodec = required(payload);

    return pipe(
        input,
        (i) => requiredCodec[decodeFunction](i) as either.Either<TError, D>,
        either.fold(
            () => pipe(
                payload,
                record.mapWithIndex((key, codec) =>
                    pipe(
                        codec[decodeFunction](input),
                        either.mapLeft((e) => e.map(
                            ([code, path]) => {
                                return [code, `${key}` + (path ? `.${path}` : "")] as TErrorTuple;
                            }))
                    )
                ),
                record.partitionMap((e) => e),
                (separated) =>
                    Object.keys(separated.left).length
                    ? either.left([...array.flatten(Object.values(separated.left))])
                    : either.right(separated.right as D)
            ),
            (d) => either.right(d as D)
        )
    );
};

export const requiredFlatOverloaded = <
    P extends {[key: string]: TAnyCodec},
    D extends {[K in keyof P]: TTypeOfCodec<P[K]>;},
    N extends {[K in keyof P]: TTypeOfNewDefault<P[K]>;}
>(payload: P): TCodec<"RequiredFlatOverloadedCodec", P, D, N> => ({
    type: "RequiredFlatOverloadedCodec",
    payload,
    decode: decode("decode", payload),
    decodeNewDefault: decode("decodeNewDefault", payload),
    newDefault: (): N => pipe(
        payload,
        record.map((codec) => codec.newDefault())
    ) as N,
});
