import { TAnyCodec, TCodec, TTypeOfCodec, TTypeOfNewDefault } from "../codec";
import { either, array, record } from "fp-ts/lib";
import { record as extRecord } from '../../utilsByDomain/record';
import { TError, errorConstants, TErrorTuple, TErrorCode } from "../errors";
import { isObject } from "../../util";
import { pipe } from "fp-ts/lib/function";

export type TAnyRequiredCodec = TCodec<
    "RequiredCodec",
    {[K: string]: TAnyCodec},
    unknown,
    unknown
>;

export type TRequiredCodec<P extends Record<string, TAnyCodec>> = TCodec<
    "RequiredCodec",
    P,
    {[K in keyof P]: TTypeOfCodec<P[K]>;},
    {[K in keyof P]: TTypeOfNewDefault<P[K]>;}
>;

export const required = <
    P extends Record<string, TAnyCodec>,
    D extends {[K in keyof P]: TTypeOfCodec<P[K]>;},
    N extends {[K in keyof P]: TTypeOfNewDefault<P[K]>;}
>(payload: P): TCodec<"RequiredCodec", P, D, N> => ({
    type: "RequiredCodec",
    payload,
    decode: decode(payload, "decode"),
    decodeNewDefault: decode(payload, "decodeNewDefault"),
    newDefault: () =>
        pipe(
            payload,
            record.map((codec) => codec.newDefault())
        ) as N,
});

export const decode = <
    P extends Record<string, TAnyCodec>,
    D extends {[K in keyof P]: TTypeOfCodec<P[K]>;},
    N extends {[K in keyof P]: TTypeOfNewDefault<P[K]>;},
    M extends "decode" | "decodeNewDefault",
>(payload: P, decodeMethod: M) => (input: unknown): either.Either<TError, M extends "decode" ? D : N> => {
    if (!isObject(input)) {
        return either.left([[errorConstants.OBJECT_VALIDATION, ""]]);
    }

    const decodedInput = decodeInput(payload, input, decodeMethod);

    if (decodedInput.errors.length) {
        return either.left(decodedInput.errors);
    }

    return either.right(extRecord.filterByMatchingKeys(payload, decodedInput.result) as unknown as M extends "decode" ? D : N);
}

const decodeInput = <
    P extends Record<string, TAnyCodec>,
    D extends {[K in keyof P]: TTypeOfCodec<P[K]>;},
    M extends "decode" | "decodeNewDefault"
>(payload: P, input: Record<string, unknown>, decodeMethod: M) => 
    pipe(
        payload,
        record.reduceRightWithIndex(
            { 
                result: {} as D, 
                errors: [] as TError 
            },
            (key, valueCodec, accumulated) => pipe(
                valueCodec[decodeMethod](input[key]),
                either.fold(
                    (validationError) => ({
                        ...accumulated,
                        errors: [
                            ...accumulated.errors,
                            ...input[key] === undefined
                                ? [[errorConstants.REQUIRED_VALIDATION, key]] as TErrorTuple[]
                                : array.map<TErrorTuple, TErrorTuple>(makeErrorTuple(key))(validationError)
                        ]
                    }),
                    (decodedValue) => ({
                        ...accumulated,
                        result: {
                            ...accumulated.result,
                            [key]: decodedValue,
                        },
                    })
                )
            )
        ),
    );

const makeErrorTuple = (key: string) =>
    ([code, path]: [TErrorCode, string]): TErrorTuple =>
        [
            code,
            path ? `${key}.${path}` : `${key}`
        ];
