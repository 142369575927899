import { keys as getRecordKeys, map as recordMap, reduceWithIndex as recordReduceWithKey, filterWithIndex as recordFilterWithIndex, toArray } from "fp-ts/lib/Record";
import * as array from "fp-ts/lib/Array";
import { eqString } from "fp-ts/lib/Eq";
import { pipe } from "fp-ts/lib/pipeable";

export const pick = <K extends keyof T, T extends Record<string, unknown>, U extends Pick<T, K>>(
    keys: Array<K>,
    recordToPick: T
): U  => {
	const recordSubSet = {} as U;
	let index = 0;
    while (index < keys.length) {
		if (keys[index] in recordToPick) {
        	recordSubSet[keys[index]] = recordToPick[keys[index]] as unknown as U[K];
      	}
      	index += 1;
    }
    return recordSubSet;
};

const omit = <K extends keyof T, T extends Record<string, unknown>, U extends Omit<T, K>>(
    keys: Array<K>,
    recordToPick: T
): U  => pipe(
	Object.entries(recordToPick),
	array.reduce<[string, unknown], U>(
		{} as U,
		(sum, [key, value]) => {
			if (keys.indexOf(key as K) === -1) {
				// @ts-ignore
				sum[key] = value;
				return sum;
			}
			return sum
		}
	),
)

const replaceNilValuesWith = <K extends string, V>(replacement: V): (record: Record<K, V>) => Record<K, V> =>
  	recordMap<V, V>((value) => {
    	if (value === null || value === undefined) {
			return replacement;
    	}
		return value;
	});

const differenceByKey = <K extends string, T extends Record<string, unknown>>(
    surRecord: T,
    subRecord: T
  ): T =>
    pick(
      	array.difference<K>(eqString)(getRecordKeys<K>(surRecord), getRecordKeys<K>(subRecord)),
      	surRecord
    );

const intersectionByKey = <K extends string, T extends Record<string, unknown>>(
    surRecord: T,
    subRecord: T
): T =>
    pick(
      	array.intersection<K>(eqString)(getRecordKeys<K>(surRecord), getRecordKeys<K>(subRecord)),
      	surRecord
    );

const objectValueToString = (value: unknown): string =>
    JSON.stringify(value).replace(/"/g, "");

const pickValues = <K extends keyof T, T extends Record<string, unknown>>(keys: Array<K>) =>
	(recordToPick: T): Array<T[K]> => pipe(
		pick(keys, recordToPick),
		toArray,
		array.map(([, value]) => value)
	)
;

const compareRecordFieldsAndGetMatchScores =
    <T extends Record<string, unknown>>(
        comparingValues: Array<keyof T>,
        matchingObject: T,
    ) =>
        (comparingObject: T): Array<number> =>
            comparingValues.map((key) => {
                const matchingString = objectValueToString(matchingObject[key]);
                const comparingString = objectValueToString(comparingObject[key]);
                const regex = new RegExp(matchingString, 'g');
                return regex.exec(comparingString) ? 1 : 0;
            });

export const toTuples = <T extends unknown>(givenRecord: Record<string, T>): Array<[string, T]> =>
  pipe(
    	givenRecord,
    	recordReduceWithKey<string, T, Array<[string, T]>>(
      	[],
      	(key, reduction, value) => reduction.concat([[key, value]]),
    	)
	);

export const doesKeyExist = (object: Record<string, unknown>) =>
  	(key: string): boolean => 
    	Object.prototype.hasOwnProperty.call(object, key);

const filterByMatchingKeys = <R extends Record<string, unknown>, F extends Record<string, unknown>>(recordToMatch: R, recordToFilter: F): R =>
  	pipe(
    	recordToFilter,
    	recordFilterWithIndex(doesKeyExist(recordToMatch)),
	) as R

export const record = {
    pick,
	omit,
	pickValues,
    differenceByKey,
    intersectionByKey,
    replaceNilValuesWith,
    compareRecordFieldsAndGetMatchScores,
    toTuples,
    doesKeyExist,
	filterByMatchingKeys,
};
