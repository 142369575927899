import { TTypeOfCodec } from "../../../shared/src/codecs/codec";
import { form, TFormCodec } from "../../../shared/src/codecs/types/form";
import { required, TRequiredCodec } from "../../../shared/src/codecs/types/required";
import { CaseSearchResult, TCaseSearchResultCodec } from "../CaseSearchResult";
import { array, TArrayCodec } from "../../../shared/src/codecs/types/array";
import { string, TAnyStringCodec } from "../../../shared/src/codecs/types/string";

export const CasesSearchForm: TFormCodec<
    TRequiredCodec<{
        searchString: TAnyStringCodec
    }>,
    TArrayCodec<TCaseSearchResultCodec>
> = 
    form(
        required({
            searchString: string(),
        }),
        array(CaseSearchResult)
    );

export type TCasesSearchFormCodec = typeof CasesSearchForm;
export type TCasesSearchForm = TTypeOfCodec<TCasesSearchFormCodec>;