import React from "react";

export default (props: React.SVGAttributes<SVGElement>) => <svg style={{ display: "block" }} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
    <title>Color:</title>
    <g id="Symbols" stroke="none" strokeWidth="1">
        <g id="0:-Icons-/-Cross">
            <path d="M23.3287852,23.3339286 C22.4349752,24.2254527 20.98682,24.2254527 20.091867,23.3339286 L11.9995714,15.241633 L3.90727578,23.3339286 C3.01346573,24.2254527 1.56416759,24.2254527 0.670357538,23.3339286 C-0.223452513,22.4309747 -0.223452513,20.9908204 0.670357538,20.0878666 L8.76265314,11.995571 L0.670357538,3.90327535 C-0.223452513,3.01175126 -0.223452513,1.56016716 0.670357538,0.668643069 C1.56416759,-0.222881023 3.01346573,-0.222881023 3.90727578,0.668643069 L11.9995714,8.76093867 L20.091867,0.668643069 C20.98682,-0.222881023 22.4349752,-0.222881023 23.3287852,0.668643069 C24.2237383,1.56016716 24.2237383,3.01175126 23.3287852,3.90327535 L15.2364896,11.995571 L23.3287852,20.0878666 C24.2237383,20.9908204 24.2237383,22.4309747 23.3287852,23.3339286 L23.3287852,23.3339286 Z" id="Color:"></path>
        </g>
    </g>
</svg>;
