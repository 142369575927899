import { TCodec } from "../codec";
import { either } from "fp-ts/lib";
import { TError } from "../errors";

export type TAnyUnknownCodec = TCodec<
    "UnknownCodec",
    undefined,
    unknown,
    unknown
>;

const decode = (input: unknown): either.Either<TError, unknown> =>
        either.right(input);

export const unknown = (): TAnyUnknownCodec => ({
    type: "UnknownCodec",
    payload: undefined,
    decode,
    decodeNewDefault: decode,
    newDefault: () => undefined,
});
