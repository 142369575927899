import { inherit, TAnyInheritedCodec, TInheritedCodec } from "../codec";
import { string, TAnyStringCodec } from "./string";
import { option } from "fp-ts/lib";
import { errorConstants } from "../errors";
import * as regexes from "../../regexes";

export type TAnyPostcodeCodec = TAnyInheritedCodec<"PostcodeCodec", { codec: TAnyStringCodec }>;

export const postcode = (): TInheritedCodec<"PostcodeCodec", {codec: TAnyStringCodec}> => inherit(
    "PostcodeCodec",
    {codec: string()},
    (input) => typeof input === "string"
        && (new RegExp(regexes.constants.POSTCODE_REGEX)).test(input)
        ? option.none
        : option.some([[errorConstants.REGEX_VALIDATION, ""]]),
    () => option.none,
);
