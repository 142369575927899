import * as t from "io-ts";

export const codec = t.type({
    id: t.string,
    cases_enquiry_id: t.string,
    message: t.string,
    date: t.string,
});

export type T = t.TypeOf<typeof codec>;
