import { THexColor } from "./StringLiterals";

export const CRMColors = {
    PRIMARY_0: "#1D2D54" as THexColor,
    PRIMARY_2: "#2C3C63" as THexColor,
    PRIMARY_4: "#3E5898" as THexColor,
    PRIMARY_6: "#5873B5" as THexColor,
    PRIMARY_8: "#809BDC" as THexColor,
    PRIMARY_10: "#A5B8E9" as THexColor,
    PRIMARY_12: "#CDD8F3" as THexColor,
    PRIMARY_13: "#E5EDFF" as THexColor,
    PRIMARY_14: "#cdd8f354" as THexColor,
    PRIMARY_16: "#EDF2FA" as THexColor,
    NEUTRAL_INK: "#00011B" as THexColor,
    NEUTRAL_0: "#37373C" as THexColor,
    NEUTRAL_2: "#606060" as THexColor,
    NEUTRAL_4: "#838386" as THexColor,
    NEUTRAL_6: "#B0B0B1" as THexColor,
    NEUTRAL_8: "#D6D6D6" as THexColor,
    NEUTRAL_10: "#E7E7E7" as THexColor,
    NEUTRAL_12: "#F1F1F1" as THexColor,
    NEUTRAL_PAPER: "#FBFBFB" as THexColor,
    HIGHLIGHTS_CALMING_PINK_0: "#894CCD" as THexColor,
    HIGHLIGHTS_CALMING_PINK_2: "#9F69DB" as THexColor,
    HIGHLIGHTS_CALMING_PINK_4: "#AF8AD8" as THexColor,
    HIGHLIGHTS_CALMING_PINK_6: "#D3BCED" as THexColor,
    HIGHLIGHTS_CALMING_PINK_8: "#DAC8EF" as THexColor,
    HIGHLIGHTS_CALMING_PINK_10: "#E9E1F2" as THexColor,
    HIGHLIGHTS_CALMING_PINK_11: "#F2ECF9" as THexColor,
    HIGHLIGHTS_CALMING_PINK_12: "#F5F1F9" as THexColor,
    HIGHLIGHTS_INSTRUCTIONAL_BRICK_0: "#E94F37" as THexColor,
    HIGHLIGHTS_INSTRUCTIONAL_BRICK_2: "#E86A57" as THexColor,
    HIGHLIGHTS_INSTRUCTIONAL_BRICK_4: "#F58978" as THexColor,
    HIGHLIGHTS_INSTRUCTIONAL_BRICK_6: "#FFB0A4" as THexColor,
    HIGHLIGHTS_INSTRUCTIONAL_BRICK_8: "#FFC6BD" as THexColor,
    HIGHLIGHTS_INSTRUCTIONAL_BRICK_10: "#FBDFDA" as THexColor,
    HIGHLIGHTS_INSTRUCTIONAL_BRICK_12: "#FDEFED" as THexColor,
    HIGHLIGHTS_PERTINENT_YELLOW_0: "#FFDB20" as THexColor,
    HIGHLIGHTS_PERTINENT_YELLOW_2: "#FCDF50" as THexColor,
    HIGHLIGHTS_PERTINENT_YELLOW_4: "#FFE97C" as THexColor,
    HIGHLIGHTS_PERTINENT_YELLOW_6: "#FFEF9F" as THexColor,
    HIGHLIGHTS_PERTINENT_YELLOW_8: "#FFF5C2" as THexColor,
    HIGHLIGHTS_PERTINENT_YELLOW_10: "#FFF8D6" as THexColor,
    HIGHLIGHTS_PERTINENT_YELLOW_12: "#FFFCEB" as THexColor,
    HIGHLIGHTS_POSITIVE_GREEN_0: "#196664" as THexColor,
    HIGHLIGHTS_POSITIVE_GREEN_2: "#218380" as THexColor,
    HIGHLIGHTS_POSITIVE_GREEN_4: "#37A5A1" as THexColor,
    HIGHLIGHTS_POSITIVE_GREEN_6: "#4ABFBC" as THexColor,
    HIGHLIGHTS_POSITIVE_GREEN_8: "#72D4D1" as THexColor,
    HIGHLIGHTS_POSITIVE_GREEN_10: "#A5E0DF" as THexColor,
    HIGHLIGHTS_POSITIVE_GREEN_12: "#CEF3F2" as THexColor,
    HIGHLIGHTS_EASY_ORANGE_10: "#FFE0C9" as THexColor,
    HIGHLIGHTS_EASY_ORANGE_12: "#FFF0E5" as THexColor,
};

export type TCRMColourValue = typeof CRMColors[keyof typeof CRMColors];