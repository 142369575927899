import { TTypeOfCodec } from "../../../shared/src/codecs/codec";
import { form, TFormCodec } from "../../../shared/src/codecs/types/form";
import { EmptyObject, TEmptyObjectCodec } from "../EmptyObject";
import { ListingSignMovementCreateParts, TListingSignMovementCreatePartsCodec } from "../ListingSignMovement";

export const ListingSignMovementCreateForm: TFormCodec<TListingSignMovementCreatePartsCodec, TEmptyObjectCodec> = form(
    ListingSignMovementCreateParts,
    EmptyObject
);

export type TListingSignMovementCreateFormCodec = typeof ListingSignMovementCreateForm;
export type TListingSignMovementCreateForm = TTypeOfCodec<TListingSignMovementCreateFormCodec>;