import { pipe } from "fp-ts/lib/function";
import { TTypeOfCodec } from "../../../shared/src/codecs/codec";
import { array, TArrayCodec } from "../../../shared/src/codecs/types/array";
import { boolean } from "../../../shared/src/codecs/types/boolean";
import { form, TFormCodec } from "../../../shared/src/codecs/types/form";
import { intersection } from "../../../shared/src/codecs/types/intersection";
import { literal } from "../../../shared/src/codecs/types/literal";
import { nullCodec, TNullCodec } from "../../../shared/src/codecs/types/nullCodec";
import { positiveInteger } from "../../../shared/src/codecs/types/positiveInteger";
import { required, TRequiredCodec } from "../../../shared/src/codecs/types/required";
import { TUnionCodec, union } from "../../../shared/src/codecs/types/union";
import { TUuidCodec, uuid } from "../../../shared/src/codecs/types/uuid";
import { Address3 } from "../Address";
import { EmptyObject } from "../EmptyObject";
import { QuoteFactorsFilled, QuoteFactorsUnfilled, Quotes, TQuotesCodec } from "../Quote";
import { TQuoteRateTransactionType } from "../QuoteRate";
import { CreateUser, TCreateUserCodec } from "../User";
import { array as fptsArray } from "fp-ts";

export const PublicCreateQuoteAddressForm = form(
    intersection([
        required({
            address_not_known: boolean(),
        }),
        Address3,
    ]),
    EmptyObject
);
export type TPublicCreateQuoteAddressFormCodec = typeof PublicCreateQuoteAddressForm;
export type TPublicCreateQuoteAddressForm = TTypeOfCodec<TPublicCreateQuoteAddressFormCodec>;

export const PublicCreateQuoteUnfilledQuote = intersection([
    QuoteFactorsUnfilled,
    required({
        address_form: PublicCreateQuoteAddressForm,
    }),
]);
export type TPublicCreateQuoteUnfilledQuoteCodec = typeof PublicCreateQuoteUnfilledQuote;
export type TPublicCreateQuoteUnfilledQuote = TTypeOfCodec<TPublicCreateQuoteUnfilledQuoteCodec>;

export const PublicCreateQuoteUnfilledForm: TFormCodec<
    TRequiredCodec<{
        introducer_id: TUnionCodec<[TNullCodec, TUuidCodec]>,
        introducer_user:TUnionCodec<[TNullCodec, TCreateUserCodec]>, 
        quotes: TArrayCodec<TPublicCreateQuoteUnfilledQuoteCodec>,
        client: TCreateUserCodec
    }>,
    TQuotesCodec
> = form(
    required({
        introducer_id: union([nullCodec(), uuid()]),
        introducer_user: union([nullCodec(), CreateUser]),
        quotes: array(PublicCreateQuoteUnfilledQuote),
        client: CreateUser,
    }),
    Quotes,
);
export type TPublicCreateQuoteUnfilledFormCodec = typeof PublicCreateQuoteUnfilledForm;
export type TPublicCreateQuoteUnfilledForm = TTypeOfCodec<TPublicCreateQuoteUnfilledFormCodec>;

const PublicCreateQuoteFilledQuote = intersection([
    QuoteFactorsFilled,
    required({
        address_form: PublicCreateQuoteAddressForm,
    }),
]);
export type TPublicCreateQuoteFilledQuoteCodec = typeof PublicCreateQuoteFilledQuote;
export type TPublicCreateQuoteFilledQuote = TTypeOfCodec<TPublicCreateQuoteFilledQuoteCodec>;

export const PublicCreateQuoteFilledForm: TFormCodec<
    TRequiredCodec<{
        introducer_id: TUnionCodec<[TNullCodec, TUuidCodec]>,
        introducer_user:TUnionCodec<[TNullCodec, TCreateUserCodec]>, 
        quotes: TArrayCodec<TPublicCreateQuoteFilledQuoteCodec>,
        client: TCreateUserCodec
    }>,
    TQuotesCodec
> = form(
    required({
        introducer_id: union([nullCodec(), uuid()]),
        introducer_user: union([nullCodec(), CreateUser]),
        quotes: array(PublicCreateQuoteFilledQuote),
        client: CreateUser,
    }),
    Quotes,
);
export type TPublicCreateQuoteFilledFormCodec = typeof PublicCreateQuoteFilledForm;
export type TPublicCreateQuoteFilledForm = TTypeOfCodec<TPublicCreateQuoteFilledFormCodec>;

export const PublicQuoteViewsMobileQuoteStep = union([
    required({
        tag: literal("address"),
        quoteIndex: positiveInteger(),
        active: boolean(),
    }),
    required({
        tag: literal("tenure"),
        quoteIndex: positiveInteger(),
        active: boolean(),
    }),
    required({
        tag: literal("price"),
        quoteIndex: positiveInteger(),
        active: boolean(),
    }),
    required({
        tag: literal("number_of_clients"),
        quoteIndex: positiveInteger(),
        active: boolean(),
    }),
    required({
        tag: literal("clients_ownership"),
        quoteIndex: positiveInteger(),
        active: boolean(),
    }),
    required({
        tag: literal("clients_property_count"),
        quoteIndex: positiveInteger(),
        active: boolean(),
    }),
    required({
        tag: literal("unregistered_property"),
        quoteIndex: positiveInteger(),
        active: boolean(),
    }),
    required({
        tag: literal("new_build_property"),
        quoteIndex: positiveInteger(),
        active: boolean(),
    }),
    required({
        tag: literal("mortgage_involved"),
        quoteIndex: positiveInteger(),
        active: boolean(),
    }),
    required({
        tag: literal("requires_search_pack"),
        quoteIndex: positiveInteger(),
        active: boolean(),
    }),
    required({
        tag: literal("has_gifted_deposit"),
        quoteIndex: positiveInteger(),
        active: boolean(),
    }),
    required({
        tag: literal("has_company"),
        quoteIndex: positiveInteger(),
        active: boolean(),
    }),
    required({
        tag: literal("client_only_using_cash"),
        quoteIndex: positiveInteger(),
        active: boolean(),
    }),
]);
export type TPublicQuoteViewsMobileQuoteStep = TTypeOfCodec<typeof PublicQuoteViewsMobileQuoteStep>;

export const PublicQuoteViewsMobile = union([
    required({
        tag: literal("start"),
        active: boolean(),
    }),
    ...PublicQuoteViewsMobileQuoteStep.payload,
    required({
        tag: literal("introducer_user_details"),
        active: boolean(),
    }),
    required({
        tag: literal("client"),
        active: boolean(),
    }),
    required({
        tag: literal("result"),
        active: boolean(),
    }),
]);
export type TPublicQuoteViewsMobileCodec = typeof PublicQuoteViewsMobile;
export type TPublicQuoteViewsMobile = TTypeOfCodec<TPublicQuoteViewsMobileCodec>;

export const PublicQuotePage: TRequiredCodec<{
    form: TPublicCreateQuoteUnfilledFormCodec,
    views_mobile: TArrayCodec<TPublicQuoteViewsMobileCodec>,
}> = required({
    form: PublicCreateQuoteUnfilledForm,
    views_mobile: array(PublicQuoteViewsMobile),
});
export type TPublicQuotePageCodec = typeof PublicQuotePage;
export type TPublicQuotePage = TTypeOfCodec<TPublicQuotePageCodec>;

export type TPublicQuoteTransactionType = TQuoteRateTransactionType | "sale_and_purchase" | null;

export const PublicCreateQuoteUnfilledForm_PublicCreateQuoteFilledForm = (form: TPublicCreateQuoteUnfilledForm): TPublicCreateQuoteFilledForm => {
    const transformQuote = (quote: TPublicCreateQuoteUnfilledQuote): TPublicCreateQuoteFilledQuote => ({
        ...quote,
        transaction_type: quote.transaction_type || "purchase",
        tenure: quote.tenure || "unknown",
        "property_price_pence--transfer-monetary-value":
            quote["property_price_pence--transfer-monetary-value"] || 0,
        "property_price_pence--property-value":
            quote["property_price_pence--property-value"] || 0,
        number_of_clients: quote.number_of_clients || 0,
    });
    
    return {
        ...form,
        edited: {
            ...form.edited,
            quotes: pipe(
                form.edited.quotes,
                fptsArray.map(transformQuote),
            ),
        },
        original: {
            ...form.original,
            quotes: pipe(
                form.original.quotes,
                fptsArray.map(transformQuote),
            ),
        },
    };
};
