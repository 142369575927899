import * as t from "io-ts";

export const constants = {
    MALFORMED_JSON: "MalformedJson" as const,
    MALFORMED_MULTIPART_FORM_DATA: "MalformedMultipartFormData" as const,
    MALFORMED_URL_ENCODED_DATA: "MalformedUrlEncodedData" as const,
    ACCESS_TOKEN_INVALID: "AccessTokenInvalid" as const,
    TWO_FACTOR_AUTHENTICATION_REQUIRED: "TwoFactorAuthenticationRequired" as const,
    SESSION_INVALID: "SessionInvalid" as const,
    ORIGIN_NOT_ALLOWED: "OriginNotAllowed" as const,
    RESOURCE_NOT_FOUND: "ResourceNotFound" as const,
    USER_DOES_NOT_HAVE_CORRECT_SCOPE: "UserDoesNotHaveCorrectScope" as const,
    NOT_AUTHORIZED_FOR_RESOURCE: "NotAuthorizedForResource" as const,
    ACTION_NOT_ALLOWED: "ActionNotAllowed" as const,
    RECORD_ALREADY_EXISTS: "RecordAlreadyExists" as const,
    RESOURCE_IN_USE: "ResourceInUse" as const,
    FEATURE_NOT_ENABLED_YET: "FeatureNotEnabledYet" as const,
    GMAIL_WATCH_COMMAND_FAILED: "GmailWatchCommandFailed" as const,
    GMAIL_STOP_COMMAND_FAILED: "GmailStopCommandFailed" as const,
    LEGAL_EMAIL_ALREADY_ASSIGNED_TO_CASE: "LegalEmailAlreadyAssignedToCase" as const,
    MISSING_DATA_FOR_DOCUMENT: "MissingDataForDocument" as const,
    LEGAL_EMAIL_STATUS_IS_NOT_UNASSIGNED: "LegalEmailStatusIsNotUnassigned" as const,
    LEGAL_EMAIL_STATUS_IS_ASSIGNED: "LegalEmailStatusIsAssigned" as const,
    UNEXPECTED_LEGAL_EMAIL_STATUS: "UnexpectedLegalEmailStatus" as const,
    CASE_IS_NOT_INSTRUCTED_OR_IS_NOT_OPEN: "CaseIsNotInstructedOrIsNotOpen" as const,
    CASE_HAS_OPEN_ENQUIRIES: "CaseHasOpenEnquiries" as const,
    TWILIO_SEND_SMS_FAILED: "TwilioSendSmsFailed" as const,
    TWILIO_TASK_ROUTER_WORKER_EMAIL_DOES_NOT_MATCH_USER: "TwilioTaskRouterWorkerEmailDoesNotMatchUser" as const,
    TWILIO_GET_TASK_ROUTER_WORKER_FAILED: "TwilioGetTaskRouterWorkerFailed" as const,
    MELISSA_CONTACT_VERIFY_REQUEST_FAILED: "MelissaContactVerifyRequestFailed" as const,
    CASE_LEDGER_IS_NOT_BALANCED: "CaseLedgerIsNotBalanced" as const,
    CASE_LEDGER_HAS_NO_ENTRIES: "CaseLedgerHasNoEntries" as const,
    CASE_LEDGER_HAS_BEEN_FINALISED: "CaseLedgerHasBeenFinalised" as const,
    CASE_LEDGER_HAS_NOT_BEEN_FINALISED: "CaseLedgerHasNotBeenFinalised" as const,
    CASE_LEDGER_ENTRY_INCORRECT_CREDIT_DEBIT_TYPE: "CaseLedgerEntryIncorrectCreditDebitType" as const,
    NOT_ALL_CASE_LEDGER_CREDIT_ENTRIES_MARKED_AS_PAID: "NotAllCaseLedgerCreditEntriesMarkedAsPaid" as const,
    GOOGLE_CALENDAR_API_REQUEST_FAILED: "GoogleCalendarApiRequestFailed" as const,
    GOOGLE_ARCHIVE_API_REQUEST_FAILED: "GoogleArchiveApiRequestFailed" as const,
};

export const codec = t.union([
    t.literal(constants.MALFORMED_JSON),
    t.literal(constants.MALFORMED_MULTIPART_FORM_DATA),
    t.literal(constants.MALFORMED_URL_ENCODED_DATA),
    t.literal(constants.ACCESS_TOKEN_INVALID),
    t.literal(constants.SESSION_INVALID),
    t.literal(constants.ORIGIN_NOT_ALLOWED),
    t.literal(constants.RESOURCE_NOT_FOUND),
    t.literal(constants.USER_DOES_NOT_HAVE_CORRECT_SCOPE),
    t.literal(constants.NOT_AUTHORIZED_FOR_RESOURCE),
    t.literal(constants.ACTION_NOT_ALLOWED),
    t.literal(constants.RECORD_ALREADY_EXISTS),
    t.literal(constants.RESOURCE_IN_USE),
    t.literal(constants.FEATURE_NOT_ENABLED_YET),
    t.literal(constants.GMAIL_WATCH_COMMAND_FAILED),
    t.literal(constants.GMAIL_STOP_COMMAND_FAILED),
    t.literal(constants.LEGAL_EMAIL_ALREADY_ASSIGNED_TO_CASE),
    t.literal(constants.MISSING_DATA_FOR_DOCUMENT),
    t.literal(constants.LEGAL_EMAIL_STATUS_IS_NOT_UNASSIGNED),
    t.literal(constants.LEGAL_EMAIL_STATUS_IS_ASSIGNED),
    t.literal(constants.UNEXPECTED_LEGAL_EMAIL_STATUS),
    t.literal(constants.CASE_IS_NOT_INSTRUCTED_OR_IS_NOT_OPEN),
    t.literal(constants.CASE_HAS_OPEN_ENQUIRIES),
    t.literal(constants.TWILIO_SEND_SMS_FAILED),
    t.literal(constants.TWILIO_TASK_ROUTER_WORKER_EMAIL_DOES_NOT_MATCH_USER),
    t.literal(constants.TWILIO_GET_TASK_ROUTER_WORKER_FAILED),
    t.literal(constants.MELISSA_CONTACT_VERIFY_REQUEST_FAILED),
    t.literal(constants.CASE_LEDGER_IS_NOT_BALANCED),
    t.literal(constants.CASE_LEDGER_HAS_NO_ENTRIES),
    t.literal(constants.CASE_LEDGER_HAS_BEEN_FINALISED),
    t.literal(constants.CASE_LEDGER_HAS_NOT_BEEN_FINALISED),
    t.literal(constants.CASE_LEDGER_ENTRY_INCORRECT_CREDIT_DEBIT_TYPE),
    t.literal(constants.NOT_ALL_CASE_LEDGER_CREDIT_ENTRIES_MARKED_AS_PAID),
    t.literal(constants.GOOGLE_CALENDAR_API_REQUEST_FAILED),
    t.literal(constants.GOOGLE_ARCHIVE_API_REQUEST_FAILED),
    t.literal(constants.TWO_FACTOR_AUTHENTICATION_REQUIRED),
]);

export type T = t.TypeOf<typeof codec>;
