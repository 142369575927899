import { TTypeOfCodec } from "../../../shared/src/codecs/codec";
import { boolean, TAnyBooleanCodec } from "../../../shared/src/codecs/types/boolean";
import { literal, TLiteralCodec } from "../../../shared/src/codecs/types/literal";
import { TNullCodec, nullCodec } from "../../../shared/src/codecs/types/nullCodec";
import { required, TRequiredCodec } from "../../../shared/src/codecs/types/required";
import { string, TAnyStringCodec } from "../../../shared/src/codecs/types/string";
import { TUnionCodec, union } from "../../../shared/src/codecs/types/union";
import { JobTypeCodec, TJobTypeCodec } from "../Job";
import { JobsFormIO, TJobsFormIOCodec } from "../formIO/JobsIO";

export const JobsFilter: TRequiredCodec<{
    filter_by_type: TUnionCodec<[TNullCodec, TJobTypeCodec]>;
    filter_by_user: TUnionCodec<[TNullCodec, TAnyStringCodec]>;
    filter_by_handler: TUnionCodec<[TNullCodec, TAnyStringCodec]>;
    default_user_filter: TAnyStringCodec;
}> = required({
    filter_by_type: union([nullCodec(), JobTypeCodec]),
    filter_by_user: union([nullCodec(), string()]),
    filter_by_handler: union([nullCodec(), string()]),
    default_user_filter: string(),
});
export type TJobsFilterCodec = typeof JobsFilter;
export type TJobsFilter = TTypeOfCodec<TJobsFilterCodec>;

export const JobsPartial: TRequiredCodec<{
    data: TJobsFormIOCodec;
    filter: TJobsFilterCodec;
    case_filtered_by_user_id: TUnionCodec<[TLiteralCodec<"anyone">, TAnyStringCodec]>;
    filter_has_been_changed_by_user: TAnyBooleanCodec;
}> = required({
    data: JobsFormIO,
    filter: JobsFilter,

    case_filtered_by_user_id: union([literal("anyone"), string()]),
    filter_has_been_changed_by_user: boolean(),
});

export type TJobsPartialCodec = typeof JobsPartial;
