import { required } from "../../shared/src/codecs/types/required";
import { TTypeOfCodec } from "../../shared/src/codecs/codec";
import { union } from "../../shared/src/codecs/types/union";
import { intersection } from "../../shared/src/codecs/types/intersection";
import { string } from "../../shared/src/codecs/types/string";
import { literal } from "../../shared/src/codecs/types/literal";
import { UuidObject } from "./Id";
import { uuid } from "../../shared/src/codecs/types/uuid";
import { requireExhaustive } from "../../shared/src/util";
import { dateTime } from "../../shared/src/codecs/types/dateTime";

export const CasesRiskAssessmentGrade = union([
    literal("unknown"),
    literal("red"),
    literal("amber"),
    literal("green"),
]);
export type TCasesRiskAssessmentGrade = TTypeOfCodec<typeof CasesRiskAssessmentGrade>;

export const getCasesRiskAssessmentGradeLabel = (grade: TCasesRiskAssessmentGrade) => {
    switch (grade) {
        case "red":
            return "Red";
        case "amber":
            return "Amber";
        case "green":
            return "Green";
        case "unknown":
            return "Unknown";
        default:
            return requireExhaustive(grade)
    }
}

export const ClientVulnerabilityStatus = union([
    literal("unknown"),
    literal("yes"),
    literal("no")
]);
export type TClientVulnerabilityStatus = TTypeOfCodec<typeof ClientVulnerabilityStatus>;

export const clientVulnerabilityStatusOptions: Array<{value: TClientVulnerabilityStatus, label: string}> = [
    {
        value: "unknown",
        label: "Not set",
    },
    {
        value: "yes",
        label: "Yes",
    },
    {
        value: "no",
        label: "No",
    },
];

export const casesRiskAssessmentGradeOptions: Array<{value: TCasesRiskAssessmentGrade, label: string}> = [
    {
        value: "unknown",
        label: "Not set",
    },
    {
        value: "red",
        label: "Red",
    },
    {
        value: "amber",
        label: "Amber",
    },
    {
        value: "green",
        label: "Green",
    },
];

export const CasesRiskAssessment1 = required({
    case_id: uuid(),
    grade: CasesRiskAssessmentGrade,
    explanation: string(),
    are_any_of_the_clients_vulnerable: ClientVulnerabilityStatus,
    vulnerability_explanation: string()
});
export type TCasesRiskAssessment1Codec = typeof CasesRiskAssessment1;
export type TCasesRiskAssessment1 = TTypeOfCodec<TCasesRiskAssessment1Codec>;

export const CasesRiskAssessment2 = intersection([
    UuidObject,
    CasesRiskAssessment1,
    required({
        created_at: dateTime(),
    }),
])
export type TCasesRiskAssessment2Codec = typeof CasesRiskAssessment2;
export type TCasesRiskAssessment2 = TTypeOfCodec<TCasesRiskAssessment2Codec>;

export const CaseRiskAssessmentProceedableForReadyForExchange = required({
    grade: literal("green"),
});
