import { TTypeOfCodec } from "../../shared/src/codecs/codec";
import { boolean } from "../../shared/src/codecs/types/boolean";
import { deferDateTime } from "../../shared/src/codecs/types/deferDateTime";
import { intersection } from "../../shared/src/codecs/types/intersection";
import { longString } from "../../shared/src/codecs/types/longString";
import { nullCodec } from "../../shared/src/codecs/types/nullCodec";
import { required } from "../../shared/src/codecs/types/required";
import { string } from "../../shared/src/codecs/types/string";
import { union } from "../../shared/src/codecs/types/union";
import { uuid } from "../../shared/src/codecs/types/uuid";

export const ListingEnquiryAdHocTask2 = required({
    listing_enquiry_id: uuid(),
    task: longString(),
});
export type TListingEnquiryAdHocTask2Codec = typeof ListingEnquiryAdHocTask2;
export type TListingEnquiryAdHocTask2 = TTypeOfCodec<typeof ListingEnquiryAdHocTask2>;

export const ListingEnquiryAdHocTask1 = intersection([
    ListingEnquiryAdHocTask2,
    required({
        id: string(),
        done: boolean(),
        deferred_date: union([deferDateTime(), nullCodec()]),
    }),
]);
export type TListingEnquiryAdHocTask1Codec = typeof ListingEnquiryAdHocTask1;
