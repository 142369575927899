import { TTypeOfCodec } from "../../../shared/src/codecs/codec";
import { array, TArrayCodec } from "../../../shared/src/codecs/types/array";
import { string, TAnyStringCodec } from "../../../shared/src/codecs/types/string";
import { formIO, TFormIOCodec } from "../../../shared/src/codecs/types/formIO";
import { required, TRequiredCodec } from "../../../shared/src/codecs/types/required";
import { Job1, TJob1Codec } from "../Job";
import { StaffUser, TStaffUserCodec } from "../User";

export const JobsFormIO: TFormIOCodec<
    TRequiredCodec<{
        user_id: TAnyStringCodec,
    }>,
    TRequiredCodec<{
        filterable_case_users: TArrayCodec<TStaffUserCodec>,
        jobs: TArrayCodec<TJob1Codec>,
        all_staff_users: TArrayCodec<TStaffUserCodec>,
    }>
> = formIO(
    required({
        user_id: string(),
    }),
    required({
        filterable_case_users: array(StaffUser),
        jobs: array(Job1),
        all_staff_users: array(StaffUser),
    })
);

export type TJobsFormIOCodec = typeof JobsFormIO;

export type TJobsFormIO = TTypeOfCodec<TJobsFormIOCodec>;