import { TCodec } from "../codec";
import { either } from "fp-ts/lib";
import { TError, errorConstants } from "../errors";

export type TAnyNullCodec = TCodec<
    "NullCodec",
    undefined,
    null,
    null
>;

export type TNullCodec = TCodec<
    "NullCodec",
    undefined,
    null,
    null
>;

const decode = (input: unknown): either.Either<TError, null> =>
    input === null
        ? either.right(null)
        : either.left([[errorConstants.NULL_VALIDATION, ""]]);

// Can't be called "null" since it is a reserved keyword
export const nullCodec = (): TAnyNullCodec => ({
    type: "NullCodec",
    payload: undefined,
    decode,
    decodeNewDefault: decode,
    newDefault: () => null,
});
