import { option } from "fp-ts";
import { Lens } from "monocle-ts";
import { TState, TUnpackArray } from "./lensBaseTypes";

interface ILensFindFirst<S> {
    // 5 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1],
        K3 extends keyof S[K1][K2],
        K4 extends keyof S[K1][K2][K3],
        K5 extends keyof S[K1][K2][K3][K4]
    >
    (
        path: [K1, K2, K3, K4, K5],
    ): option.Option<TUnpackArray<S[K1][K2][K3][K4][K5]>>;
    // 4 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1],
        K3 extends keyof S[K1][K2],
        K4 extends keyof S[K1][K2][K3]
    >
    (
        path: [K1, K2, K3, K4],
    ): option.Option<TUnpackArray<S[K1][K2][K3][K4]>>;
    // 3 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1],
        K3 extends keyof S[K1][K2]
    >
    (
        path: [K1, K2, K3],
    ): option.Option<TUnpackArray<S[K1][K2][K3]>>;
    // 2 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1]
    >
    (
        path: [K1, K2],
    ): option.Option<TUnpackArray<S[K1][K2]>>;
    // 1 level lens path guard
    <K1 extends keyof S>
    (
        path: [K1],
    ): option.Option<TUnpackArray<S[K1]>>;
}

export const findFirst = (givenState: TState): ILensFindFirst<TState> =>
    // There is no way to create paramater overloads here so we set to any
    (lensPath: any) => // eslint-disable-line
        option.some(Lens.fromPath<TState>()(lensPath).get(givenState));
