import * as t from "io-ts";
import * as EnquiryPaymentMethod1 from "./EnquiryPaymentMethod1";

export const codec = t.type({
    id: t.string,
    listing_id: t.string,
    payment_method: EnquiryPaymentMethod1.codec,
    conveyancer_organisation_name: t.string,
    conveyancer_name: t.string,
    conveyancer_phone_number: t.union([t.string, t.null]),
    conveyancer_address: t.string,
    conveyancer_email_address: t.union([t.string, t.null]),
    mortgage_broker_organisation_name: t.string,
    mortgage_broker_name: t.string,
    mortgage_broker_email_address: t.union([t.string, t.null]),
    mortgage_broker_phone_number: t.union([t.string, t.null]),
    mortgage_broker_has_vouched: t.boolean,
    funds_cash_verified_upto: t.union([t.number, t.null]),
    funds_dip_verified_upto: t.union([t.number, t.null]),
    id_check_complete: t.boolean,
    final_offer_made: t.boolean,
});

export type T = t.TypeOf<typeof codec>;

export const newDefault = (): T => ({
    id: "",
    listing_id: "",
    payment_method: "unknown",
    conveyancer_organisation_name: "",
    conveyancer_name: "",
    conveyancer_phone_number: null,
    conveyancer_address: "",
    conveyancer_email_address: null,
    mortgage_broker_organisation_name: "",
    mortgage_broker_name: "",
    mortgage_broker_email_address: null,
    mortgage_broker_phone_number: null,
    mortgage_broker_has_vouched: false,
    funds_cash_verified_upto: null,
    funds_dip_verified_upto: null,
    id_check_complete: false,
    final_offer_made: false,
});
