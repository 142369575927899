import * as t from "io-ts";
import * as UserPhoneNumber1 from "./UserPhoneNumber1";
import { array, option } from "fp-ts/lib";
import { pipe } from "fp-ts/lib/function";

export const codec = t.type({
    id: t.string,
    email: t.union([t.string, t.null]),
    first_name: t.string,
    last_name: t.string,
    phone_numbers: t.array(UserPhoneNumber1.codec),
});

export type T = t.TypeOf<typeof codec>;

export const newDefault = (): T => ({
    id: "",
    email: "",
    first_name: "",
    last_name: "",
    phone_numbers: [],
});

export const toDisplayName = (user: T): string =>
    user.first_name || user.last_name ? `${user.first_name} ${user.last_name}`
        : user.email ? user.email
        : user.phone_numbers.length ?  pipe(
            user.phone_numbers,
            array.findFirst((phoneNumber) => phoneNumber.primary_number),
            option.fold(
                () => user.phone_numbers[0].phone_number || "",
                (phoneNumberModel) => phoneNumberModel.phone_number || "",
            ),
        )
        : "";
