import React from "react";

export default (props: React.SVGAttributes<SVGElement>) => <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g clipPath="url(#clip0_5817_26126)">
<path d="M39.7 22.0228C39.1974 22.0228 38.721 22.1365 38.2945 22.339L32 16.0444V12.1C32 11.4925 31.5075 11 30.9 11H26.9556L20.6775 4.72189C20.884 4.29103 21 3.80882 21 3.3C21 1.48039 19.5196 0 17.7 0C15.8804 0 14.4 1.48039 14.4 3.3C14.4 5.11961 15.8804 6.6 17.7 6.6C18.2088 6.6 18.691 6.48409 19.1219 6.27749L22.1027 9.25836C19.5484 8.63342 16.7729 8.67439 13.801 9.38699C10.2946 10.2277 7.16516 11.8582 4.91044 13.28C4.78353 13.2288 4.6452 13.2 4.5 13.2H0.1C-0.507475 13.2 -1 13.6925 -1 14.3V18.7C-1 19.3075 -0.507475 19.8 0.1 19.8H4.5C5.10748 19.8 5.6 19.3075 5.6 18.7V15.451C10.1966 12.4383 18.3783 8.56261 25.4 13.0382V16.5C25.4 17.1075 25.8925 17.6 26.5 17.6H29.9613C34.5642 24.8449 30.0885 33.507 27.5482 37.4H24.3C23.6925 37.4 23.2 37.8925 23.2 38.5V42.9C23.2 43.5075 23.6925 44 24.3 44H28.7C29.3075 44 29.8 43.5075 29.8 42.9V38.5C29.8 38.3548 29.7712 38.2165 29.7201 38.0896C31.1419 35.8348 32.7723 32.7055 33.6131 29.199C34.3256 26.2271 34.3666 23.4516 33.7416 20.8973L36.7347 23.8904C36.5275 24.3208 36.4114 24.8028 36.4114 25.3115C36.4114 27.1248 37.8867 28.6 39.7 28.6C41.5134 28.6 42.9886 27.1248 42.9886 25.3114C42.9886 23.4981 41.5134 22.0228 39.7 22.0228ZM3.4 17.6H1.2V15.4H3.4V17.6ZM27.6 41.8H25.4V39.6H27.6V41.8ZM16.6 3.3C16.6 2.69349 17.0935 2.2 17.7 2.2C18.3065 2.2 18.8 2.69349 18.8 3.3C18.8 3.90651 18.3065 4.4 17.7 4.4C17.0935 4.4 16.6 3.90651 16.6 3.3ZM27.6 13.2H29.8V15.4H27.6V13.2ZM39.7 26.4C39.0998 26.4 38.6114 25.9116 38.6114 25.3114C38.6114 24.7112 39.0998 24.2228 39.7 24.2228C40.3002 24.2228 40.7886 24.7112 40.7886 25.3114C40.7886 25.9116 40.3002 26.4 39.7 26.4Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_5817_26126">
<rect width="44" height="44" fill="white"/>
</clipPath>
</defs>
</svg>
;
