import * as TForm from "../../models/TForm";
import { TActionObservable, TFormActionPayload } from "../../state/applyActions";
import * as TSetState from "../../state/TSetState";
import * as TGetState from "../../state/TGetState";
import { TState } from "./lensBaseTypes";
import { reduceDataToStateUpdate } from "./reduceDataToStateUpdate";
import { set } from "./set";
import { TPayloadActionType } from "../../state/actions/TAction";

interface ILensSetFormSubscribed<A extends TPayloadActionType, T, S> {
    // 5 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1],
        K3 extends keyof S[K1][K2],
        K4 extends keyof S[K1][K2][K3],
        K5 extends keyof S[K1][K2][K3][K4]
    >
    (
        path: [K1, K2, K3, K4, K5],
    ): (
        obs$: TActionObservable<A, TFormActionPayload<Required<TForm.TUnpackEditFormType<T>>, keyof TForm.TUnpackEditFormType<T>>>,
        getState: TGetState.TGetState,
        setState: TSetState.TSetState
    ) => void;
    // 4 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1],
        K3 extends keyof S[K1][K2],
        K4 extends keyof S[K1][K2][K3]
    >
    (
        path: [K1, K2, K3, K4],
    ): (
        obs$: TActionObservable<A, TFormActionPayload<Required<TForm.TUnpackEditFormType<T>>, keyof TForm.TUnpackEditFormType<T>>>,
        getState: TGetState.TGetState,
        setState: TSetState.TSetState
    ) => void;
    // 3 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1],
        K3 extends keyof S[K1][K2]
    >
    (
        path: [K1, K2, K3],
    ): (
        obs$: TActionObservable<A, TFormActionPayload<Required<TForm.TUnpackEditFormType<T>>, keyof TForm.TUnpackEditFormType<T>>>,
        getState: TGetState.TGetState,
        setState: TSetState.TSetState
    ) => void;
    // 2 level lens path guard
    <
        K1 extends keyof S,
        K2 extends keyof S[K1]
    >
    (
        path: [K1, K2],
    ): (
        obs$: TActionObservable<A, TFormActionPayload<Required<TForm.TUnpackEditFormType<T>>, keyof TForm.TUnpackEditFormType<T>>>,
        getState: TGetState.TGetState,
        setState: TSetState.TSetState
    ) => void;
    // 1 level lens path guard
    <K1 extends keyof S>
    (
        path: [K1],
    ): (
        obs$: TActionObservable<A, TFormActionPayload<Required<TForm.TUnpackEditFormType<T>>, keyof TForm.TUnpackEditFormType<T>>>,
        getState: TGetState.TGetState,
        setState: TSetState.TSetState
    ) => void;
}

export const setFormSubscribed =
    <A extends TPayloadActionType, T>(): ILensSetFormSubscribed<A, T, TState> =>
        // There is no way to create paramater overloads here so we set to any
        (lens: any) => // eslint-disable-line
            (
                obs$: TActionObservable<A, TFormActionPayload<Required<TForm.TUnpackEditFormType<T>>, keyof TForm.TUnpackEditFormType<T>>>,
                getState: TGetState.TGetState,
                setState: TSetState.TSetState
            ) => {
                obs$.subscribe((action) => {
                    reduceDataToStateUpdate<TFormActionPayload<Required<TForm.TUnpackEditFormType<T>>, keyof TForm.TUnpackEditFormType<T>>>(setState)(
                        set<TFormActionPayload<Required<TForm.TUnpackEditFormType<T>>, keyof TForm.TUnpackEditFormType<T>>>()(
                            lens,
                            /* eslint-disable */
                            // The type is always expected to be never for the form, since we have abstraced the lens scoping,
                            // which is untrue
                            // @ts-ignore
                            (form, payload) => TForm.updateFromAction2<T>(form, payload)
                            /* eslint-enable */
                        )
                    )(action.payload);
                });
            };
