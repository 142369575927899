import { TTypeOfCodec } from "../../../shared/src/codecs/codec";
import { array, TArrayCodec } from "../../../shared/src/codecs/types/array";
import { formIO, TFormIOCodec } from "../../../shared/src/codecs/types/formIO";
import { required, TRequiredCodec } from "../../../shared/src/codecs/types/required";
import { ListingSellerUserCreateForm, TListingSellerUserCreateFormCodec } from "../form/ListingSellerUserCreateForm";
import { ListingSellerUserEditForm, TListingSellerUserEditFormCodec } from "../form/ListingSellerUserEditForm";
import { ListingAdHocTaskEditable, TListingAdHocTaskEditableCodec } from "../formEditable/ListingAdHocTaskEditable";
import { ListingFull1Editable, TListingFull1EditableCodec } from "../formEditable/ListingEditable";
import { ListingSignMovementEditForm, TListingSignMovementEditFormCodec } from "../form/ListingSignMovementEditForm";
import { ListingCRMReadOnlyData1, TListingCRMReadOnlyData1Codec } from "../Listing";
import { NewUserNumberFormIO, TNewUserNumberFormIOCodec } from "./NewUserNumberFormIO";
import { RemoveUserNumberFormIO, TRemoveUserNumberFormIOCodec } from "./RemoveUserNumberFormIO";
import { ListingId, TListingIdCodec } from "../ListingId";

export const ListingDataFormIO: TFormIOCodec<
    TListingIdCodec,
    TRequiredCodec<{
        read_only: TListingCRMReadOnlyData1Codec;
        form: TListingFull1EditableCodec;
        sign_movements: TArrayCodec<TListingSignMovementEditFormCodec>;
        listing_seller_users: TArrayCodec<TListingSellerUserEditFormCodec>;
        listing_seller_user_create: TListingSellerUserCreateFormCodec;
        listing_seller_user_phone_number_create: TNewUserNumberFormIOCodec;
        listing_seller_user_phone_number_delete: TRemoveUserNumberFormIOCodec;
        ad_hoc_tasks: TArrayCodec<TListingAdHocTaskEditableCodec>;
    }>
> = formIO(
    ListingId,
    required({
        read_only: ListingCRMReadOnlyData1,
        form: ListingFull1Editable,
        sign_movements: array(ListingSignMovementEditForm),
        listing_seller_users: array(ListingSellerUserEditForm),
        listing_seller_user_create: ListingSellerUserCreateForm,
        listing_seller_user_phone_number_create: NewUserNumberFormIO,
        listing_seller_user_phone_number_delete: RemoveUserNumberFormIO,
        ad_hoc_tasks: array(ListingAdHocTaskEditable),
    }),
);

export type TListingDataFormIOCodec = typeof ListingDataFormIO;

export type TListingDataFormIO = TTypeOfCodec<TListingDataFormIOCodec>;
